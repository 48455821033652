import { BodyText, Button, Header } from "nhsuk-react-components"
import React from "react"
import { Routes } from "../../../../../enums"

interface ICookieBannerProps {
  isRegistrationPage: boolean
  saveCookieAcceptance: () => void
}

const CookieBanner = (props: ICookieBannerProps) => {
  const { isRegistrationPage, saveCookieAcceptance } = props

  return (
    <div id="nhsuk-cookie-banner">
      <div className="nhsuk-cookie-banner" id="cookiebanner">
        <div className="nhsuk-width-container">
          <Header>Cookies on NHS England applications</Header>
          <BodyText>
            We use cookies on this website in order to make essential features work, to measure how
            many visitors there are, and to understand what those visitors need. Information is kept
            anonymous, as described in our&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              aria-label="link to cookie policy page, this will open in a new tab."
              href={!isRegistrationPage ? Routes.Cookies : Routes.RegistrationCookies}
            >
              cookies policy
            </a>
            .
          </BodyText>
          <br />
          <BodyText>
            By continuing to use this website, you are agreeing to our use of cookies.
          </BodyText>
          <Button onClick={saveCookieAcceptance}>I'm OK with this use of cookies</Button>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner
