import axios, { Canceler } from "axios"
import { makeObservable, action, observable } from "mobx"
import { ISortItem, ITab } from "../../interfaces/admin"
import { INumberIndexableType } from "../../interfaces/dataTypes/interfaces"
import { IOrganisationModel } from "../../interfaces/organisation/interfaces"
import { IPaginationResult, IUserPage } from "../../interfaces/pagination/interfaces"
import { IPaginatedUser } from "../../interfaces/user/interfaces"
import { get } from "../../utils/api"

const CancelToken = axios.CancelToken
let cancel: Canceler

export interface IAdminStore<TTab> {
  availableTabs: TTab[]
  tab: ITab<TTab>
  listTotals: INumberIndexableType<number>
  searchString: string
  selectedOrg: IOrganisationModel | null
  seenUsers: Map<string, IPaginatedUser>
  mainList: IPaginatedUser[]
  filteredList: number
  pages: IPaginationResult
  selectedAll: boolean
  selectedItems: string[]
  selectedRegion: string
  jobRole: string
  sortBy: string
  sortOptions: ISortItem[]
  getMainList: (query: string) => Promise<IUserPage>
  resetPagination: () => void
  setSelectedAll: (value: boolean) => void
  setSelected: (arr: string[]) => void
  toggleSelectAll: () => void
  handleOrgChange: (
    search: IOrganisationModel | null,
    refreshFunction: () => Promise<IUserPage>
  ) => Promise<IUserPage>
  handleSearch: (search: string, refreshFunction: () => Promise<IUserPage>) => Promise<IUserPage>
  handleRegionChange: (
    search: string,
    refreshFunction: () => Promise<IUserPage>
  ) => Promise<IUserPage>
  handleSortMainList: (
    sortVal: string,
    refreshFunction: () => Promise<IUserPage>
  ) => Promise<IUserPage>
  handleFilterJobRole: (
    role: string,
    refreshFunction: () => Promise<IUserPage>
  ) => Promise<IUserPage>
  clearUserSelections: () => void
  setActiveTab: (tabValue: TTab, refreshFunction: () => Promise<IUserPage>) => Promise<IUserPage>
}

abstract class AdminStore<TTab> implements IAdminStore<TTab> {
  availableTabs: TTab[] = []
  tab: ITab<TTab>
  listTotals: INumberIndexableType<number> = {}
  searchString = ""
  selectedOrg: IOrganisationModel | null = null
  seenUsers: Map<string, IPaginatedUser> = new Map<string, IPaginatedUser>()
  mainList: IPaginatedUser[] = []
  filteredList = 0
  pages: IPaginationResult = {
    current: 1,
    total: 1,
    hasNextPage: true,
    hasPreviousPage: false,
  }
  selectedAll = false
  selectedItems: string[] = []
  selectedRegion = "All"
  jobRole = "A"
  sortBy = "date_desc"
  sortOptions: ISortItem[] = [
    { name: "Newest first", value: "date_desc" },
    { name: "Oldest first", value: "date_asc" },
    { name: "Last name A-Z", value: "lastname_asc" },
    { name: "Last name Z-A", value: "lastname_desc" },
    { name: "Organisation A-Z", value: "organisation_asc" },
    { name: "Organisation Z-A", value: "organisation_desc" },
  ]

  constructor(tab: ITab<TTab>) {
    this.tab = tab
    makeObservable(this, {
      setSelected: action,
      getMainList: action,
      handleOrgChange: action,
      handleSearch: action,
      handleRegionChange: action,
      handleSortMainList: action,
      handleFilterJobRole: action,
      setActiveTab: action,
      mainList: observable,
      seenUsers: observable,
      filteredList: observable,
      pages: observable,
      selectedAll: observable,
      setSelectedAll: action,
      toggleSelectAll: action,
      selectedItems: observable,
    })
  }

  resetPagination(): void {
    this.pages = {
      current: 1,
      total: 1,
      hasNextPage: true,
      hasPreviousPage: false,
    }
  }

  setSelectedAll(value: boolean): void {
    this.selectedAll = value
  }

  setSelected(arr: string[]): void {
    this.selectedItems = arr
  }

  toggleSelectAll(): void {
    this.setSelectedAll(!this.selectedAll)
    this.setSelected(this.selectedAll ? this.mainList.map(x => x.id) : [])
  }

  handleOrgChange(
    search: IOrganisationModel | null,
    refreshFunction: () => Promise<IUserPage>
  ): Promise<IUserPage> {
    this.selectedOrg = search
    cancel("Operation cancelled by refresh")
    return refreshFunction()
  }

  handleSearch(search: string, refreshFunction: () => Promise<IUserPage>): Promise<IUserPage> {
    this.searchString = search
    cancel("Operation cancelled by refresh")
    return refreshFunction()
  }

  handleRegionChange(
    search: string,
    refreshFunction: () => Promise<IUserPage>
  ): Promise<IUserPage> {
    this.selectedRegion = search
    cancel("Operation cancelled by refresh")
    return refreshFunction()
  }

  handleSortMainList(
    sortVal: string,
    refreshFunction: () => Promise<IUserPage>
  ): Promise<IUserPage> {
    this.sortBy = sortVal
    cancel("Operation cancelled by refresh")
    return refreshFunction()
  }

  handleFilterJobRole(role: string, refreshFunction: () => Promise<IUserPage>): Promise<IUserPage> {
    this.jobRole = role
    cancel("Operation cancelled by refresh")
    return refreshFunction()
  }

  clearUserSelections(): void {
    this.mainList = []
    this.seenUsers.clear()
    this.selectedAll = false
    this.selectedItems = []
  }

  setActiveTab(tabValue: TTab, refreshFunction: () => Promise<IUserPage>): Promise<IUserPage> {
    this.tab.selected = tabValue
    cancel("Operation cancelled by refresh")
    this.clearUserSelections()
    return refreshFunction()
  }

  getMainList(query: string): Promise<IUserPage> {
    this.mainList = []
    return new Promise<IUserPage>((resolve, reject) => {
      get<IUserPage>(query, {
        cancelToken: new CancelToken(c => (cancel = c)),
      })
        .then(res => {
          if (res.status === 200) {
            this.mainList = [...res.data.users]
            res.data.users.forEach(u => this.seenUsers.set(u.id, u))
            this.pages = {
              current: res.data.pageNumber,
              total: res.data.totalPages,
              hasNextPage: res.data.hasNextPage,
              hasPreviousPage: res.data.hasPreviousPage,
            }
            this.filteredList = res.data.totalUsers
          }
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }
}

export default AdminStore
