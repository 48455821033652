import { observer } from "mobx-react"
import { BodyText, Container, Label } from "nhsuk-react-components"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { withWrapper } from "../../../global/components/HOC"
import { FeatureToggle } from "../../../global/enums/featureToggle/enums"
import { MessageKey } from "../../../global/enums/messageKeys/enums"
import { useFeatureToggles, useMessageKeys } from "../../../global/hooks"
import { CustomiseNotifications, PersonalDetails, VerticalNavigation } from "./components"
import "./styles.scss"
import { Routes } from "../../../global/enums"

interface IProfileViewProps {
  isPersonal: boolean
}

const ProfileView = observer((props: IProfileViewProps) => {
  const { isPersonal } = props

  const navigate = useNavigate()

  const {
    enabledToggles: [profileUpdatingEnabled, notificationCentreEnabled],
  } = useFeatureToggles(FeatureToggle.ProfileUpdatingEnabled, FeatureToggle.NotificationCentre)

  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  const onVerticalTabChanged = (index: number) => {
    if ((isPersonal && index === 0) || (!isPersonal && index === 1)) {
      return
    }

    navigate(index === 0 ? Routes.ProfilePersonalPage : Routes.NotificationPreferences)
  }

  const VerticalNavigationItems = useMemo(() => {
    const verticalNavigationItems = ["Personal details & security settings"]

    if (notificationCentreEnabled) {
      verticalNavigationItems.push("Customise notifications")
    }

    return verticalNavigationItems
  }, [notificationCentreEnabled])

  return (
    <Container className="profile-view profile-update-page">
      <div>
        <Label isPageHeading className="profile-view__my-account-label">
          My account
        </Label>
        <BodyText className="profile-view__profile-description">
          Please help maintain the accuracy of our service by keeping your details up to date.
          {!profileUpdatingEnabled && (
            <>
              {" To make any changes, please contact "}
              <a className="profile-view__profile-description__profile-link" href={itOps}>
                {serviceDesk}
              </a>
            </>
          )}
        </BodyText>

        <VerticalNavigation
          onChange={onVerticalTabChanged}
          items={VerticalNavigationItems}
          selectedIndex={isPersonal ? 0 : 1}
        />

        {isPersonal ? (
          <PersonalDetails profileUpdatingEnabled={profileUpdatingEnabled} />
        ) : (
          <CustomiseNotifications />
        )}
      </div>
    </Container>
  )
})

export default withWrapper(ProfileView)
