export enum UserProfileUpdateChanges {
  None = 0,
  FirstName = 1 << 0,
  LastName = 1 << 1,
  JobRole = 1 << 2,
  EmailLocal = 1 << 3,
  EmailDomain = 1 << 4,
  Organisation = 1 << 5,
  RegionCode = 1 << 6,
}

export enum UserProfileUpdateScreen {
  ManualApprovalRequired,
  DetailsSuccessfullyChanged,
  VerifyNewEmaiLAddress,
  RequestsSent,
}
