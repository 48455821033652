import React from "react"
import { observer } from "mobx-react"
import Tab from "./components/tab"
import { useStores } from "../../../../global/hooks"
import { SheetInfo } from "@tableau/embedding-api"

import "../styles.scss"

const TabSelector = observer(() => {
  const {
    tableauReportViewerStore: { visualizationLoading, tableauVisualization },
  } = useStores()

  if (!tableauVisualization?.workbook || visualizationLoading) {
    return <></>
  }

  const sheetInfos: SheetInfo[] = tableauVisualization.workbook.publishedSheetsInfo

  return (
    <div className="tab-selector">
      {tableauVisualization.workbook &&
        sheetInfos.map((sheetInfo: SheetInfo) => {
          const currentSheetInfoName = sheetInfo.name
          return (
            <Tab
              key={`tab-id-${currentSheetInfoName}`}
              name={currentSheetInfoName}
              dashboardName={currentSheetInfoName}
            />
          )
        })}
    </div>
  )
})

export default TabSelector
