import { Label, Select } from "nhsuk-react-components"
import React, { memo, useEffect, useState } from "react"
import { IRegion } from "../../interfaces/region/interfaces"
import { ErrorsCollection } from "../../types"

interface IRegionProps {
  id: string
  name: string
  errors: ErrorsCollection | null | undefined
  disabled: boolean
  isPrimary: boolean
  regions: IRegion[]
  defaultRegionCode?: string
  onRegionChanged: (region: IRegion | null) => void
}

const errorName = "region"

const RegionComponent = (props: IRegionProps) => {
  const { id, name, errors, disabled, onRegionChanged, isPrimary, regions, defaultRegionCode } =
    props

  const [selectValue, setSelectValue] = useState(defaultRegionCode ? defaultRegionCode : "")

  useEffect(() => {
    if (defaultRegionCode) {
      const region = regions.find(r => r.code === defaultRegionCode)
      if (region) {
        onRegionChanged(region)
      }
    }
  }, [defaultRegionCode])

  return (
    <>
      <Label>Region</Label>
      <Select
        id={id}
        name={name}
        value={selectValue}
        error={errors?.getFirstFieldError(errorName)}
        disabled={disabled}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
          if (isPrimary) {
            const val = (e.target as HTMLSelectElement).value
            setSelectValue(val)
            const region = regions.find(r => r.code === val)
            if (region) {
              onRegionChanged(region)
            }
            errors?.removeWithPredicate(err => err.key === errorName)
          }
        }}
      >
        <Select.Option value="" disabled>
          Select region
        </Select.Option>
        {regions.map(item => (
          <Select.Option value={item.code} key={item.code} id={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default memo(RegionComponent)
