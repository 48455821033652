export enum Routes {
  BaseUrl = "/",
  AnalyticsHubLandingPage = "/analyticshub",
  Admin = "/admin",
  AppAdmin = "/appadmin",
  AToZLandingPage = "/a-z",
  ProfileUrl = "/myaccount",
  ProfileUpdateUrl = "/myaccount/update",
  ProfileUpdateResultUrl = "/myaccount/updateresult",
  ProfileUpdatePasswordUrl = "/myaccount/password",
  ProfileUpdateSecurityQuestionUrl = "/myaccount/securityQuestion",
  TermsAndConditions = "/terms",
  Error = "/error",
  Authentication = "/api/auth/login",
  Logout = "/api/auth/logout",
  PageNotFound = "/pageNotFound",
  Registration = "/register",
  RegistrationTerms = "/reg-terms",
  Login = "/",
  MobileRouter = "/MobileHome",
  ManageAdmins = "/manageadmins",
  Cookies = "/cookies",
  RegistrationCookies = "/reg-cookies",
  Accessibility = "/accessibility",
  Privacy = "/privacy",
  NotificationPreferences = "/myaccount/notificationpreferences",
  ProfilePersonalPage = "/myaccount/personal",
  Notifications = "/notifications",
}
