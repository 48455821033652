const legacyCopyTextToClipboard = async (text: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    textArea.textContent = text
    textArea.style.position = "absolute"
    textArea.style.left = "-624.9375rem"
    textArea.style.zIndex = "1004"
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand("copy")
      resolve()
    } catch (err) {
      console.error(err)
      reject()
    } finally {
      document.body.removeChild(textArea)
    }
  })

export const copyTextToClipboard = async (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    await legacyCopyTextToClipboard(text)
  } else {
    try {
      await navigator.clipboard.writeText(text)
    } catch {
      await legacyCopyTextToClipboard(text)
    }
  }
}
