import { observer } from "mobx-react"
import React, { useContext, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { withWrapper } from "../global/components/HOC"
import { ProgressiveWebApplicationContext } from "../global/context"
import { Routes } from "../global/enums"
import "./styles.scss"

interface ILandingPageProps {
  isPwa?: boolean
}

const LandingPage = observer((props: ILandingPageProps) => {
  const { isPwa } = props

  const { setIsPwa } = useContext(ProgressiveWebApplicationContext)

  useEffect(() => {
    setIsPwa(isPwa ?? false)
  }, [isPwa])

  return (
    <main>
      <section className="landing-page">
        <article className="landing-page__intro">
          <h1 className="landing-page__title">NHS England applications</h1>
          <p className="landing-page__intro-text">
            NHS England provides a range of analytical and digital products to support NHS
            professionals identify opportunities for improvement. The products include analytical
            dashboards and benchmarking tools that review data for NHS provider organisations in
            comparison to peer trusts, trusts within the same integrated care system and trusts
            nationally.
          </p>
          <p className="landing-page__intro-text">
            These cover a range of domains from urgent and emergency care, to patient safety,
            productivity and clinical outcomes.
          </p>
          <p className="landing-page__intro-text">
            Access to some products is solely for NHS staff, whereas others are open to anyone
            working to support systems. If you are NHS staff or support provider trusts,
            Commissioners or systems across the NHS, register or log in below to discover the range
            of improvement products and services available. Once your access is approved, you can
            browse by category and choose from a broad list of topics and care settings from
            workforce to Acute Hospital Services.
          </p>
        </article>
        <article className="landing-page__login-or-register">
          <h2 className="landing-page__sub-heading">Login or register</h2>
          <p>
            Click the buttons below to login or register for access to analytical and digital
            applications published by NHS England
          </p>
          <RouterLink
            data-testid="landing-page__login-or-register__login"
            className="nhsuk-button landing-page__button"
            to={isPwa ? Routes.MobileRouter : Routes.AToZLandingPage}
          >
            Login
          </RouterLink>
          <RouterLink
            data-testid="landing-page__login-or-register__register"
            className="nhsuk-button landing-page__button nhsuk-button--secondary"
            content-description="landing-page__login-or-register__register_android"
            to={Routes.Registration}
          >
            Register
          </RouterLink>
        </article>
      </section>
    </main>
  )
})

export default withWrapper(LandingPage)
