import { useEffect } from "react"
import useStores from "./useStores"
import { postApi } from "../utils/api"
import { reaction } from "mobx"
import useFeatureToggles from "./useFeatureToggles"
import { FeatureToggle } from "../enums"

const usePushNotifications = () => {
  const { profileStore } = useStores()
  const {
    loading,
    enabledToggles: [notificationCentreEnabled],
  } = useFeatureToggles(FeatureToggle.NotificationCentre)
  const serviceWorker = navigator.serviceWorker
  const pushNotificationsEndPoint = "pushNotifications"
  const encodingKey = "p256dh"
  const authenticationKey = "auth"
  const registrationErrorMessage = "Erorr while registering subscription"

  const urlBase64ToUint8Array = (base64String: string) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  const onServiceWorkerReady = (registration: ServiceWorkerRegistration): Promise<void> => {
    return registration.pushManager
      .getSubscription()
      .then(subscription => {
        if (subscription) {
          return
        }

        return registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              `${process.env.REACT_APP_PUSHNOTIFICATIONS_PUBLICKEY}`
            ),
          })
          .then(subscription => {
            const subScriptionJson = subscription.toJSON()
            postApi(pushNotificationsEndPoint, {
              endPoint: subScriptionJson.endpoint,
              p256dh: subScriptionJson.keys && subScriptionJson.keys[encodingKey],
              auth: subScriptionJson.keys && subScriptionJson.keys[authenticationKey],
            })
              .then(() => console.log(`Registered subscription`))
              .catch(error => console.log(`${registrationErrorMessage} ${error} ${error}`))
            return
          })
      })
      .catch(error => console.log(`${registrationErrorMessage} ${error} ${error}`))
  }

  useEffect(() => {
    if (!loading && notificationCentreEnabled) {
      reaction(
        () => profileStore.user,
        currentUser => {
          if (currentUser) {
            serviceWorker.ready.then(onServiceWorkerReady)
          }
        }
      )
    }
  }, [loading])
}

export default usePushNotifications
