import { makeAutoObservable } from "mobx"
import { MessageKey } from "../../global/enums/messageKeys/enums"
import { ICollection, IKeyValuePair } from "../interfaces/dataTypes/interfaces"
import { GenericCollection } from "../types"
import { get } from "../utils/api"

export interface IMessageKeyStore {
  getKeyValues: (...keys: number[]) => Promise<(string | undefined)[]>
  getKeyValue: (key: number) => Promise<string | undefined>
  getAllMessageKeysAndValues: () => Promise<
    ICollection<IKeyValuePair<MessageKey, string | undefined>>
  >
}

const messageKeyControllerPrefix = "/messagekey/"

const storeUrls = {
  getAllMessageKeys: `${messageKeyControllerPrefix}all`,
  getMessageKeyValue: `${messageKeyControllerPrefix}value?key=`,
}

class MessageKeyStore implements IMessageKeyStore {
  constructor() {
    makeAutoObservable(this)
  }
  messages: ICollection<IKeyValuePair<MessageKey, string | undefined>> = new GenericCollection<
    IKeyValuePair<MessageKey, string | undefined>
  >()

  getKeyValues = async (...keys: number[]): Promise<(string | undefined)[]> => {
    const requiredStrings: (string | undefined)[] = []

    for (const key of keys) {
      const val = this.messages.firstWithPredicate(kvp => kvp.key === key)
      if (val) {
        requiredStrings.push(val.value)
      } else {
        const fetched = await this.getMessageKeyValue(key)
        requiredStrings.push(fetched ? fetched : undefined)
      }
    }

    return requiredStrings
  }

  getKeyValue = async (key: number): Promise<string | undefined> => {
    if (!(key in MessageKey)) {
      return undefined
    }

    const val = this.messages.firstWithPredicate(kvp => kvp.key === key)
    if (val) {
      return val.value //Synchronous completion
    }

    const fetchedValue = await this.getMessageKeyValue(key)

    const corrected = fetchedValue ? fetchedValue : undefined

    this.messages.update(
      kvp => kvp.key === key,
      kvp => (kvp.value = corrected)
    )

    return corrected
  }

  getAllMessageKeysAndValues = async (): Promise<
    ICollection<IKeyValuePair<MessageKey, string | undefined>>
  > => {
    return new Promise<ICollection<IKeyValuePair<MessageKey, string | undefined>>>(resolve => {
      get<ICollection<IKeyValuePair<MessageKey, string | undefined>>>(
        storeUrls.getAllMessageKeys
      ).then(res => {
        this.messages = res.data
        resolve(res.data)
      })
    })
  }

  private getMessageKeyValue = async (key: number): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
      get<string | null>(`${storeUrls.getMessageKeyValue}${key}`).then(res => {
        if (res.data) {
          const val = res.data
          this.messages.update(
            kvp => kvp.key === key,
            kvp => (kvp.value = val ? val : undefined)
          )

          resolve(res.data)
        } else {
          reject(res.data)
        }
      })
    })
  }
}

export default new MessageKeyStore()
