import Link from "@govuk-react/link"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Checkboxes,
  ErrorMessage,
  ErrorSummary,
  Form,
  Input,
  Label,
} from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import CategorisedJobRoleSelect from "../../../global/components/categorisedJobRoleSelect"
import { LoaderContext } from "../../../global/components/loaderProvider"
import Organisation from "../../../global/components/organisation/organisation"
import { Routes } from "../../../global/enums"
import { MessageKey } from "../../../global/enums/messageKeys/enums"
import { useMessageKeys, useStores } from "../../../global/hooks"
import { scrollRefIntoView } from "../../../global/utils/dom"
import "./styles.scss"

const RegistrationView = observer(props => {
  const {
    registrationFormStore: {
      form,
      formErrors: errors,
      externalApplicationName,
      handleFormChange,
      getSubOrganisations,
      getApplicationData,
      assignApplicationName,
      getInvitationData,
      getSubOrganisationForTypeWithSearchTerm: handleAsyncOrgSearch,
    },
    organisationStore: { getOrganisationsAndTypes },
    jobRoleStore: { getJobRoles },
    externalRouteStore: { passwordResetUrl },
  } = useStores()

  const { onSubmit } = props

  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )
  const [secondaryOrganisations, setSecondaryOrganisations] = useState([])
  const [jobRoles, setJobRoles] = useState([])
  const [organisations, setOrganisations] = useState([])
  const [invitationExpired, setInvitationExpired] = useState(false)
  const [emailAddress, setEmailAddress] = useState(null)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const errorContainerRef = useRef()
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (form.invitationId) {
      wrapWithLoader(async () => {
        const invitation = await getInvitationData(form.invitationId)
        if (invitation) {
          if (mounted.current) {
            setInvitationExpired(invitation.hasExpired)
          }

          if (!invitation.hasExpired) {
            if (mounted.current) {
              setEmailAddress(invitation.emailAddress)
            }

            form.email = invitation.emailAddress
          }
        }
      })
    }
  }, [form.invitationId, getInvitationData])

  useEffect(() => {
    async function fetchJobRoles() {
      await wrapWithLoader(async () => {
        const roles = await getJobRoles()
        if (mounted.current) {
          setJobRoles(roles)
        }
      })
    }

    fetchJobRoles()
  }, [getJobRoles])

  useEffect(() => {
    async function getApplicationDataLocal() {
      if (form.externalApplicationId) {
        const application = await getApplicationData(form.externalApplicationId)
        assignApplicationName(application)
      }
    }

    getApplicationDataLocal()
  }, [form.externalApplicationId, getApplicationData, assignApplicationName])

  useEffect(() => {
    async function getOrganisations() {
      await wrapWithLoader(async () => {
        const organisations = await getOrganisationsAndTypes("All")
        if (mounted.current) {
          setOrganisations(organisations)
        }
      })
    }
    getOrganisations()
  }, [getOrganisationsAndTypes])

  const removeSecondaryOrganisation = (index, visibleIndex) => {
    const secondaries = [...secondaryOrganisations]
    secondaries[index] = 0
    setSecondaryOrganisations(secondaries)
    handleFormChange("secondaryOrganisations", null, visibleIndex)
  }

  const submitRegistration = async () => {
    await wrapWithLoader(async () => onSubmit())
    if (errors.length > 0) {
      scrollRefIntoView(errorContainerRef)
    }
  }

  const onRegionChanged = region => handleFormChange("region", region)

  const onOrganisationChanged = (org, isPrimary) => {
    if (isPrimary) {
      handleFormChange("primaryOrganisationCode", org ? org.code : "")
      handleFormChange("primaryOrganisationName", org ? org.name : "")
    } else {
      handleFormChange("secondaryOrganisations", org ? org.name : "")
    }
  }

  const emailExistsGuidance = (
    <>
      An account for this email address already exists.
      <br />
      Please <Link href={Routes.AToZLandingPage}>Sign in</Link> with the username and password you
      used for other NHS England applications. Or reset your password{" "}
      <a href={passwordResetUrl}>here</a>.<br />
      Still need help? Contact <Link href={itOps}>{serviceDesk}</Link>
    </>
  )

  return (
    <>
      {invitationExpired ? (
        <>
          <Label isPageHeading style={{ marginTop: "1.875rem" }}>
            This link has expired
          </Label>
          <BodyText>
            Please contact <a href={itOps}>{serviceDesk}</a>.
          </BodyText>
        </>
      ) : (
        <>
          <div ref={errorContainerRef}>
            {errors.length > 0 && (
              <ErrorSummary className="registration-form__error-summary" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
                <ul className="registration-form__error-list">
                  {errors.map((error, index) => {
                    if (error.key === "email" && form.userExists) {
                      //Use guidance instead, if user exists, email must be valid
                      return (
                        <ErrorSummary.Item
                          key={`${error.key}-${index}`}
                          className="registration-form__error-link"
                        >
                          {emailExistsGuidance}
                        </ErrorSummary.Item>
                      )
                    } else {
                      return (
                        <ErrorSummary.Item
                          key={`${error.key}-${index}`}
                          href={`#${error.key}`}
                          className="registration-form__error-link"
                        >
                          {errors.getFirstFieldError(error.key)}
                        </ErrorSummary.Item>
                      )
                    }
                  })}
                </ul>
              </ErrorSummary>
            )}
          </div>
          {form.externalApplicationId && (
            <div className="external-app-box">
              <BodyText>
                You are registering to use {externalApplicationName}. If successful, your username
                and password will give you access to additional products and services managed by NHS
                England.
              </BodyText>
            </div>
          )}
          <Label className="registration-form__title" isPageHeading size="xl">
            Register to access NHS England applications
          </Label>
          <BodyText>
            Some products and services can only be used by employees of certain organisations. We
            may need to verify your details before granting access.
          </BodyText>
          <Label htmlFor="firstName">First name</Label>
          <Input
            className="registration-form__input"
            id="firstName"
            error={errors.getFirstFieldError("firstName")}
            name="firstName"
            autoComplete="off"
            onChange={e => handleFormChange(e.target.name, e.target.value)}
          />
          <Label htmlFor="lastName">Last name</Label>
          <Input
            className="registration-form__input"
            id="lastName"
            error={errors.getFirstFieldError("lastName")}
            name="lastName"
            autoComplete="off"
            onChange={e => handleFormChange(e.target.name, e.target.value)}
          />
          <div className="registration-form__info-box">
            <h2 className="registration-form__info-box-copy">
              Please read before choosing which address to use
            </h2>
            <ul className="registration-form__bullet-list">
              <li className="registration-form__bullet-list-item">
                Use your work rather than personal email, where possible.
              </li>
              <li className="registration-form__bullet-list-item">
                Use the address provided to you by the main organisation you work for, where
                possible.
              </li>
              <li className="registration-form__bullet-list-item">
                Use your own email, not a group email address.
              </li>
            </ul>
          </div>
          <Label htmlFor="email">Email address</Label>
          <BodyText className="input-hint">Enter your main email address.</BodyText>
          {form.userExists && (
            <ErrorMessage className="custom-email-error">{emailExistsGuidance}</ErrorMessage>
          )}
          <Input
            className="registration-form__input"
            id="email"
            error={errors.getFirstFieldError("email")}
            name="email"
            autoComplete="off"
            defaultValue={emailAddress}
            disabled={emailAddress}
            onChange={e => {
              handleFormChange(e.target.name, e.target.value)
              form.userExists = false
            }}
          />
          <Form onChange={e => handleFormChange(e.target.name, e.target.value)} id="job">
            <Label htmlFor="jobRole">Job role (main)</Label>
            <BodyText className="input-hint">
              Select the role you have at the main organisation that you work for.
            </BodyText>
            <CategorisedJobRoleSelect
              className="registration-form__select"
              id="jobRole"
              jobRoles={jobRoles}
              defaultValue=""
              error={errors.getFirstFieldError("job")}
              onChange={e => {
                form.job = e.target.value
              }}
            />
          </Form>

          <Organisation
            isCategorised
            isPrimary
            disabled={!form.job}
            organisationCategories={organisations}
            errors={errors}
            onParentOrganisationChanged={org =>
              onOrganisationChanged({ name: org ? org.name : "", code: org ? org.code : "" }, true)
            }
            onSubOrganisationChanged={org =>
              onOrganisationChanged(
                { name: org ? org.label : "", code: org ? org.value : "" },
                true
              )
            }
            onModalCancellation={() => onOrganisationChanged(null)}
            onRegionChanged={onRegionChanged}
            handleAsyncOrgSearch={handleAsyncOrgSearch}
            getSubOrganisations={getSubOrganisations}
            onSTPChanged={isSTP => handleFormChange("isSTP", isSTP)}
            className="organisation-select"
          />
          {secondaryOrganisations.map((item, index) => {
            if (item === 0) {
              return null
            }
            const visibleIndex =
              secondaryOrganisations.slice(0, index + 1).filter(x => x === 1).length - 1
            return (
              <Organisation
                isPrimary={false}
                key={index}
                index={visibleIndex}
                disabled={!form.job}
                errors={errors}
                remove={() => removeSecondaryOrganisation(index, visibleIndex)}
                getSubOrganisations={getSubOrganisations}
                organisationCategories={organisations}
                onParentOrganisationChanged={org =>
                  onOrganisationChanged(
                    { name: org ? org.name : "", code: org ? org.code : "" },
                    true
                  )
                }
                onSubOrganisationChanged={org =>
                  onOrganisationChanged(
                    { name: org ? org.label : "", code: org ? org.value : "" },
                    true
                  )
                }
                onModalCancellation={() => onOrganisationChanged(null)}
                onSTPChanged={isSTP => handleFormChange("isSTP", isSTP)}
                onRegionChanged={onRegionChanged}
              />
            )
          })}

          <Label htmlFor="terms" className="registration-form__terms-label">
            Terms and conditions
          </Label>
          <Checkboxes
            id="terms"
            name="terms"
            error={errors.getFirstFieldError("terms")}
            onChange={() => handleFormChange("terms", !form.terms)}
          >
            <Checkboxes.Box id="test_terms" name="Terms">
              I agree to the&nbsp;
              <a
                id="termsLink"
                href={`/reg-terms`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="This link will open in a new tab"
              >
                terms and conditions
                <span className="screen-reader-only">(open in new tab)</span>
              </a>
              &nbsp;to access NHS England applications.
            </Checkboxes.Box>
          </Checkboxes>
          <Button
            role="button"
            type="submit"
            id="test_register"
            className="registration-form__submit"
            onClick={submitRegistration}
          >
            Register
          </Button>
        </>
      )}
    </>
  )
})

export default RegistrationView
