import { observer } from "mobx-react"
import { Button, ErrorSummary, Label, WarningCallout } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { ProductSelection, RecipientSelection } from "../"
import ShowGuidanceLink from "../../../global/components/showGuidanceLink"
import TextButton from "../../../global/components/textButton"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { Views } from "../../enums"
import "../../styles.scss"

interface IMainEditViewProps {
  productId: string | undefined
  setView: (view: Views) => void
}

const MainEditView = observer((props: IMainEditViewProps) => {
  const {
    invitationStore,
    productOwnerAdminStore: { myProducts: productList, selectedProduct },
  } = useStores()

  const { productId, setView } = props

  const [redirectingToAdmin, setRedirectingToAdmin] = useState(false)

  const errors = invitationStore.errors

  const [emails, setEmails] = useState(invitationStore.invitation.recipientEmails)

  useEffect(() => {
    if (selectedProduct) {
      invitationStore.invitation.products = [selectedProduct.id]
    }
  }, [selectedProduct])

  const handleContinue = () => {
    if (!invitationStore.validate()) {
      const root = document.getElementById("root")
      if (root) {
        root.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    } else {
      setView(Views.Summary)
    }
  }

  const onProductDeletion = (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    index: number
  ) => {
    if (event) {
      event.preventDefault()
    }

    invitationStore.invitation.products = [
      ...invitationStore.invitation.products.filter((_, idx) => idx !== index),
    ]
    invitationStore.validate()
  }

  const onRecipientDeletion = (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    index: number
  ) => {
    if (event) {
      event.preventDefault()
    }

    setEmails(previous => [...previous.filter((_, i) => i !== index)])

    invitationStore.invitation.recipientEmails = [
      ...invitationStore.invitation.recipientEmails.filter((_r, idx) => idx !== index),
    ]
    invitationStore.validate()
  }

  if (redirectingToAdmin) {
    return <Navigate to={selectedProduct ? Routes.AppAdmin : Routes.Admin} />
  }

  return (
    <>
      {errors.length > 0 && (
        <ErrorSummary role="alert" tabIndex={-1}>
          <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
          <ErrorSummary.List className="error-list">
            {errors.map(error =>
              error.value.map(bucket => (
                <ErrorSummary.Item key={`${error.key}`} href={`#${error.key}`}>
                  {bucket.summaryError}
                </ErrorSummary.Item>
              ))
            )}
          </ErrorSummary.List>
        </ErrorSummary>
      )}
      <Label isPageHeading>
        Invite people to {selectedProduct?.name || "products and services"}
      </Label>
      {selectedProduct ? (
        <p className="selected-product-subheading">
          Invite one or more people to use {selectedProduct.name}.
        </p>
      ) : (
        <p>Send invitations to one or more people to start using NHS England applications.</p>
      )}
      <WarningCallout>
        <p>
          Normally you should only invite people who work in the NHS, or at one of its approved
          partners.
        </p>
        <ShowGuidanceLink linkText="approved NHS partners">
          <ul>
            <li>Local or central government</li>
            <li>Department of Health</li>
            <li>Health Education England</li>
            <li>Care Quality Commission</li>
            <li>National Institute for Heath and Care Excellence</li>
            <li>Public Health England</li>
            <li>Private healthcare provider</li>
            <li>Charity or social enterprise</li>
            <li>University</li>
          </ul>
        </ShowGuidanceLink>
      </WarningCallout>

      <hr />

      {emails.map((email, index) => (
        <RecipientSelection
          index={index}
          emailAddress={email}
          key={`${email}-${index}`}
          onDeletion={onRecipientDeletion}
          invitationStore={invitationStore}
        />
      ))}

      {emails.length < 1000 && (
        <div className="invitation-page__add-more-recipients-container">
          <TextButton
            className="invitation-page__add-more"
            id="add-another-recipient"
            onClick={() => setEmails(previousEmails => [...previousEmails, ""])}
          >
            <div className="invitation-page__cross-container">
              <p className="invitation-page__cross">+</p>
            </div>{" "}
            Add another
          </TextButton>
          <p>or</p>
          <TextButton
            className="invitation-page__add-more"
            id="add-many-recipients"
            onClick={() => setView(Views.MultiEmail)}
          >
            <div className="invitation-page__cross-container">
              <p className="invitation-page__cross">+</p>
            </div>{" "}
            Add many at once
          </TextButton>
        </div>
      )}

      {!selectedProduct &&
        !productId &&
        invitationStore.invitation.products.map((product, index) => {
          return (
            <ProductSelection
              product={product}
              index={index}
              productList={productList}
              key={`${product}-${index}`}
              onDeletion={onProductDeletion}
            />
          )
        })}

      <div className="invitation-page__button-controls">
        <Button className="invitation-page__nhsuk-button" onClick={handleContinue}>
          Continue
        </Button>
        <Button
          className="invitation-page__nhsuk-button invitation-page__nhsuk-button--button-secondary"
          secondary
          onClick={() => setRedirectingToAdmin(true)}
        >
          Cancel
        </Button>
      </div>
    </>
  )
})

export default MainEditView
