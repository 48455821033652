import { Form, Hint, Input, Label } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import TextButton from "../../../global/components/textButton"
import { IKeyValuePair } from "../../../global/interfaces/dataTypes/interfaces"
import { IErrorBucket } from "../../../global/interfaces/error"
import { IInvitationStore } from "../../invitationStore"
import "../../styles.scss"

interface IRecipientSelectionProps {
  index: number
  emailAddress: string
  onDeletion: (event: React.MouseEvent<Element, MouseEvent> | undefined, index: number) => void
  invitationStore: IInvitationStore
}

const RecipientSelection = (props: IRecipientSelectionProps) => {
  const { index, emailAddress, onDeletion, invitationStore } = props
  const [cursorPosition, setCursorPosition] = useState<number>(0)
  const errorField = `invite-email-field-${index}`
  const ref = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    invitationStore.addRecipient(index, emailAddress)
  }, [])

  useEffect(() => {
    const input = ref.current
    if (input) {
      input.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [ref, cursorPosition, emailAddress])

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (invitationStore.errors) {
      invitationStore.errors.removeWithPredicate(
        (kvp: IKeyValuePair<string, IErrorBucket[]>) => kvp.key === errorField
      )
    }

    if (e && e.target) {
      const target = e.currentTarget
      if (target) {
        const position = target?.selectionStart ?? emailAddress.length
        setCursorPosition(position)
        invitationStore.addRecipient(index, target.value)
      }
    }
  }

  return (
    <Form className="invite-form-group" key={index}>
      {index === 0 && (
        <>
          <Label htmlFor={errorField}>Recipient&apos;s email address</Label>
          <Hint>
            Provide a work email address rather than a personal email address, unless it is not
            possible to do so.
          </Hint>
        </>
      )}
      <div className="invite-email-field__wrapper">
        <Input
          inputRef={ref}
          id={errorField}
          autoFocus
          className="invitation-page__input-field"
          defaultValue={emailAddress || ""}
          onChange={handleInputChange}
          error={invitationStore.errors.getFirstFieldError(errorField)}
        />
        {index > 0 && (
          <TextButton
            className="invite-email-field__delete invitation-page__delete"
            onClick={e => onDeletion(e, index)}
          >
            Delete
          </TextButton>
        )}
      </div>
    </Form>
  )
}

export default RecipientSelection
