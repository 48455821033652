export interface IExternalRouteStore {
  passwordResetUrl: string
  analyticalProductsFeedbackURL: string
}

class ExternalRouteStore implements IExternalRouteStore {
  passwordResetUrl = "https://nhsi.okta-emea.com/signin/forgot-password"
  analyticalProductsFeedbackURL = "https://forms.office.com/r/c7ZqW0h0d0"
}

export default new ExternalRouteStore()
