import React from "react"
import NavitemCard from "./navItemCard/NavitemCard"
import PropTypes from "prop-types"
import "./AppsNav.scss"

const AppsNav = props => {
  const { availableApps } = props
  const fullAlphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]

  return (
    <div className="apps-nav__container">
      {fullAlphabet.map(letter => (
        <NavitemCard
          key={fullAlphabet.indexOf(letter)}
          item={letter}
          cssClass={
            availableApps.indexOf(letter) !== -1
              ? "az-page__nav-item__link--active"
              : "az-page__nav-item__link--inactive"
          }
          link={availableApps.indexOf(letter) !== -1 ? `#${letter}` : null}
        />
      ))}
    </div>
  )
}

AppsNav.propTypes = {
  availableApps: PropTypes.array.isRequired,
}

export default AppsNav
