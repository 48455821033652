import { Footer } from "nhsuk-react-components"
import React, { memo } from "react"
import { FooterInternalLink } from ".."
import { Routes } from "../../../../../enums"
import { AdminFeedbackIcon } from "../../../../icons"
import "./styles.scss"

const LocalFooter = () => {
  return (
    <Footer id="nhsi-footer">
      <Footer.List>
        <FooterInternalLink href={Routes.Privacy}>Privacy</FooterInternalLink>
        <FooterInternalLink href={Routes.Cookies}>Cookies</FooterInternalLink>
        <FooterInternalLink href={Routes.TermsAndConditions}>
          Terms and conditions
        </FooterInternalLink>
        <FooterInternalLink href={Routes.Accessibility}>Accessibility</FooterInternalLink>
        <Footer.ListItem target="_blank" href="https://forms.office.com/r/wW70JbWJJr/">
          <AdminFeedbackIcon />
        </Footer.ListItem>
      </Footer.List>
      <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
    </Footer>
  )
}

export default memo(LocalFooter)
