import {
  IValidationResult,
  IValidationRuleBuilder,
  IValidationRuleBuilderBase,
} from "../../interfaces/validation"
import { ValidationRuleBuilder } from "../../types/validation"

export abstract class AbstractValidator<T> {
  private _rules: IValidationRuleBuilderBase<T>[] = []

  ruleFor = <TProp>(
    propertySelectionRule: (target: T) => TProp
  ): IValidationRuleBuilder<T, TProp> => {
    const validationRuleBuilder = new ValidationRuleBuilder<T, TProp>(propertySelectionRule)
    this._rules.push(validationRuleBuilder)
    return validationRuleBuilder
  }

  run = (target: T): IValidationResult[] => {
    return this._rules.map(r => r.build()).map(x => x.execute(target))
  }
}
