import React from "react"
import { SVGIconProps } from "."

const FilterIcon = (props: SVGIconProps) => {
  return (
    <svg
      {...props}
      data-testid="filter-icon"
      className="filter-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.999512C9.89543 0.999512 9 1.89494 9 2.99951L1 2.99951V4.99951H9C9 6.10408 9.89543 6.99951 11 6.99951C12.1046 6.99951 13 6.10408 13 4.99951H23V2.99951H13C13 1.89494 12.1046 0.999512 11 0.999512ZM1 12.9995V10.9995H17C17 9.89494 17.8954 8.99951 19 8.99951C20.1046 8.99951 21 9.89494 21 10.9995H23V12.9995H21C21 14.1041 20.1046 14.9995 19 14.9995C17.8954 14.9995 17 14.1041 17 12.9995H1ZM1 18.9995V20.9995H5C5 22.1041 5.89543 22.9995 7 22.9995C8.10457 22.9995 9 22.1041 9 20.9995H23V18.9995H9C9 17.8949 8.10457 16.9995 7 16.9995C5.89543 16.9995 5 17.8949 5 18.9995H1Z"
        fill="#425563"
      />
    </svg>
  )
}

export default FilterIcon
