import { useEffect, useRef } from "react"

const useDebounce = <T>(variable: T, func: () => void, timeout: number) => {
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(func, timeout)
  }, [variable, timeout])
}

export default useDebounce
