import { AxiosResponse } from "axios"
import _ from "lodash"
import { makeAutoObservable, toJS } from "mobx"
import { HttpStatusCode } from "../enums/api"
import { IAccessPermissions } from "../interfaces/accessPermissions"
import { IOrganisation } from "../interfaces/organisation"
import { IRegion } from "../interfaces/region"
import { cachedGetApi, get, post } from "../utils/api"

const storeURLS = {
  accessPermissionsForApp: "/accesspermissions?applicationId=",
}

const accessPermissionsData: IAccessPermissions = {
  id: null,
  applicationId: "",
  complete: false,
  inviteOnly: false,
  canNonWhiteListUsersBeInvited: false,
  automaticAccess: [],
  requestAccess: [],
  blackList: [],
}

export interface IAccessPermissionsStore {
  accessPermissions: IAccessPermissions
  organisations: { name: string; code: string; isOrgType: boolean }[]
  nhsOrgCodes: string[]
  isNHSOrg: (code: string, type: string) => boolean
  resetState: () => void
  getAccessPermissionsData: (productId: string, checkIsAdmin?: boolean) => Promise<void>
  getAllAccessPermissions: (id: string) => Promise<IAccessPermissions | null>
  saveAccessPermissions: (productId: string) => Promise<void>
  getRegionsAsync: () => Promise<IRegion[]>
  getOrganisationsAsync: (type?: string) => Promise<IOrganisation[]>
  getNHSOrgCodes: () => Promise<void>
  getOrganisations: () => Promise<void>
  getSubOrganisationForTypeWithSearchTerm: (
    type: string,
    searchTerm: string
  ) => Promise<IOrganisation[]>
  getChildOrganisationsAsync: (type: string) => Promise<AxiosResponse<IOrganisation[]>>
}

export class AccessPermissionsStore implements IAccessPermissionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  accessPermissions = _.cloneDeep(accessPermissionsData)

  organisations = [
    {
      name: "All approved organisations",
      code: "ALL",
      isOrgType: true,
    },
    {
      name: "All NHS organisations",
      code: "NHS",
      isOrgType: true,
    },
  ]

  nhsOrgCodes: string[] = []

  isNHSOrg = (code: string, type: string): boolean => {
    return this.nhsOrgCodes.includes(type) || this.nhsOrgCodes.includes(code)
  }

  resetState = (): void => {
    this.accessPermissions = _.cloneDeep(accessPermissionsData)
  }

  getAccessPermissionsData = (productId: string, checkIsAdmin = false) => {
    const convertChildrenToInnerCodes = (org: IOrganisation) => {
      org.isNHSOrg = this.isNHSOrg(org.code, org.organisationType)

      if (
        !org.organisationType ||
        org.organisationType === "" ||
        !org.code ||
        org.code === "" ||
        org.organisationType === "ALB"
      )
        return org

      const { code, organisationType, ...restOfObj } = org

      return {
        ...restOfObj,
        code: organisationType,
        innerCode: code,
        innerName: org.innerName || org.name,
        organisationType: organisationType,
      }
    }
    const accessPermissionsEndpoint = checkIsAdmin
      ? `/accesspermissions/IsAdmin?applicationId=${productId}`
      : `/accesspermissions?applicationId=${productId}`

    return new Promise<void>(resolve => {
      this.resetState()
      get<IAccessPermissions>(accessPermissionsEndpoint)
        .then(res => {
          const data = res.data

          data.automaticAccess = data.automaticAccess.map(convertChildrenToInnerCodes)
          data.requestAccess = data.requestAccess.map(convertChildrenToInnerCodes)
          data.blackList = data.blackList.map(convertChildrenToInnerCodes)

          this.accessPermissions = data
        })
        .catch(res => {
          if (res.status === HttpStatusCode.NotFound) {
            resolve()
          }
        })
        .then(resolve)
    })
  }

  getAllAccessPermissions = (id: string): Promise<IAccessPermissions | null> => {
    return new Promise(resolve => {
      get<IAccessPermissions>(`${storeURLS.accessPermissionsForApp}${id}`)
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            resolve(res.data)
          }
          resolve(null)
        })
        .catch(_ => resolve(null))
    })
  }

  saveAccessPermissions = (productId: string): Promise<void> => {
    this.accessPermissions.applicationId = productId

    const subsituteInnerCode = (item: IOrganisation) => {
      if (item.innerCode && item.innerCode !== "") {
        item.organisationType = toJS(item.code)
        item.code = item.innerCode
        delete item.innerCode
      }
    }

    // We should clear lists on save, to ensure the client is properly sync'd with the backend
    if (this.accessPermissions.inviteOnly) {
      this.accessPermissions.automaticAccess = []
      this.accessPermissions.requestAccess = []
      this.accessPermissions.blackList = []
    }

    const payload = toJS(this.accessPermissions)
    payload.automaticAccess.map(x => subsituteInnerCode(x))
    payload.requestAccess.map(x => subsituteInnerCode(x))
    payload.blackList.map(x => subsituteInnerCode(x))

    return new Promise<void>(resolve => {
      post<IAccessPermissions, AxiosResponse<{ id: string | null }>>(`/accesspermissions`, payload)
        .then(res => {
          if (res.status === HttpStatusCode.OK && res.data.id) {
            this.accessPermissions.id = res.data.id
          }
        })
        .catch(() => {
          this.accessPermissions.complete = false
          debugger
        })
        .finally(resolve)
    })
  }

  getRegionsAsync = async (): Promise<IRegion[]> => {
    const res = await get<IRegion[]>(`/organisation?region`)
    return res.data
  }

  getOrganisationsAsync = async (type = "All"): Promise<IOrganisation[]> => {
    const res = await get<IOrganisation[]>(`/organisation?type=${type}`)
    return res.data
  }

  getNHSOrgCodes = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<string[]>("/organisation/nhsorgs")
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.nhsOrgCodes = res.data
          }

          resolve()
        })
        .catch(() => {
          debugger
        })
    })
  }

  getOrganisations = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<IOrganisation[]>("/organisation?type=All")
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.organisations = [
              {
                name: "All approved organisations",
                code: "ALL",
                isOrgType: true,
              },
              {
                name: "All NHS organisations",
                code: "NHS",
                isOrgType: true,
              },
              ...res.data,
            ]
          }
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          resolve()
        })
    })
  }

  getSubOrganisationForTypeWithSearchTerm = (
    type: string,
    searchTerm: string
  ): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>((resolve, reject) => {
      get<IOrganisation[]>(
        `/organisation/nameTypeSearch?type=${encodeURI(type)}&searchTerm=${encodeURI(searchTerm)}`
      ).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getChildOrganisationsAsync = (type: string): Promise<AxiosResponse<IOrganisation[]>> => {
    return cachedGetApi<IOrganisation[]>(`/organisation/${type}`)
  }
}

export default new AccessPermissionsStore()
