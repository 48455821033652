import Link from "@govuk-react/link"
import moment from "moment"
import React, { memo } from "react"
import { Link as ReactLink } from "react-router-dom"
import {
  BlueInfoIcon,
  CrossIcon,
  PlusIcon,
  SentIcon,
  StarIcon,
  StarSolidIcon,
  TickIcon,
  TrustIcon,
} from "../../../global/components/icons"
import { ApplicationRequestStatus, ApplicationType } from "../../../global/enums"
import { IApplicationTile } from "../../../global/interfaces/application/interfaces"
import "./styles.scss"

interface ICardComponentProps extends IApplicationTile {
  tile: IApplicationTile
  topicId: string
  hasAccess?: boolean
  tableauWebWrapperEnabled: boolean
  icon?: JSX.Element
  requestAppAccess: (id: string, stepTwoRequired: boolean) => Promise<void>
  toggleModal: (tile: IApplicationTile) => void
  toggleFavourite: (topicId: string, tileId: string) => Promise<void>
}

const CardComponent = (props: ICardComponentProps) => {
  const {
    id,
    tile,
    title = "",
    topicId,
    embedPathName = "",
    state,
    type,
    accessGrantedDate,
    hasAccess = state === 0,
    appUrl,
    requestAppAccess,
    toggleModal,
    favourited,
    toggleFavourite,
    stepTwoRequired,
    isEmbedded,
    tableauWebWrapperEnabled,
    icon = <TrustIcon />,
  } = props

  const dateFormat = "DD MMM YYYY"

  const CardTitle = () => {
    if (
      hasAccess &&
      isEmbedded &&
      tableauWebWrapperEnabled &&
      (type === ApplicationType.TableauViewPoint || type === ApplicationType.TableauAzure)
    ) {
      return (
        <ReactLink
          id={`${id}-card-title-link`}
          className="analytics-card-component__card-title"
          to={"/report/" + embedPathName}
        >
          {title}
        </ReactLink>
      )
    } else if (hasAccess && type !== ApplicationType.AzureAd) {
      return (
        <Link
          id={`${id}-card-title-link`}
          className="analytics-card-component__card-title"
          href={appUrl}
          target="_blank"
          aria-label="This link will open in a new tab"
        >
          {title}
        </Link>
      )
    }

    return title
  }

  const AccessIcon = () => {
    switch (state) {
      case ApplicationRequestStatus.Approved:
        return <TickIcon />
      case ApplicationRequestStatus.Pending:
        return <SentIcon />
      case ApplicationRequestStatus.NotRequested:
        return <PlusIcon />
      default:
        return <CrossIcon />
    }
  }

  const AppStatus = () => {
    const date = moment(accessGrantedDate || Date.now()).format(dateFormat)

    switch (state) {
      case ApplicationRequestStatus.Approved:
        return (
          <span className="analytics-card-component__request-sent-approved">
            Access approved, {date}
          </span>
        )
      case ApplicationRequestStatus.Pending:
        return (
          <span className="analytics-card-component__request-sent-declined">
            Request sent, {date}
          </span>
        )
      case ApplicationRequestStatus.NotRequested:
        return (
          <Link
            className="analytics-card-component__request-notsent"
            onClick={() => requestAppAccess(id, stepTwoRequired)}
            secondary="true"
          >
            Request access
          </Link>
        )
      default:
        return (
          <span className="analytics-card-component__request-sent-declined">
            Request declined, {date}
          </span>
        )
    }
  }

  return (
    <div className="analytics-card-component" id="analytics-card-component">
      <div className="analytics-card-component__upper-container">
        <div className="analytics-card-component__upper-container__product-icon-container">
          {icon}
        </div>
        <h4 className="analytics-card-component__upper-container__card-title">{CardTitle()}</h4>
      </div>
      <div className="analytics-card-component__card-content" id={id}>
        <div className="analytics-card-component__card-content__request-access-button">
          <div className="analytics-card-component__card-content__request-access-button__request-container">
            <div className="analytics-card-component__card-content__request-access-button__request-container__icon">
              <AccessIcon />
            </div>
            <AppStatus />
          </div>
          <div className="analytics-card-component__card-content__request-access-button__more-info-container">
            <button
              className="analytics-card-component__card-content__request-access-button__more-info-container__info-button-container"
              onClick={() => toggleModal(tile)}
            >
              <BlueInfoIcon />
              <span className="analytics-card-component__card-content__request-access-button__more-info-container__info-button-container__card-component-link">
                Info
              </span>
            </button>
            {hasAccess && (
              <button
                className="analytics-card-component__card-content__request-access-button__more-info-container__favourite-button-container"
                onClick={() => toggleFavourite(topicId, id)}
              >
                {favourited ? <StarSolidIcon /> : <StarIcon />}
                <span className="analytics-card-component__card-content__request-access-button__more-info-container__favourite-button-container__card-component-link">
                  Favourite
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CardComponent)
