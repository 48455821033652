import React, { memo } from "react"
import "./styles.scss"

const ProductHeaderContainer = () => {
  return (
    <div className="analytical-products-header-container">
      <div className="analytical-products-header-container__sub-container">
        <h1 className="analytical-products-header-container__sub-container__header-title">
          Analytics Hub
        </h1>
        <p className="analytical-products-header-container__sub-container__copy">
          This gallery of data tools, most of which have been built in Tableau data visualisation
          software, covers a wide range of topics. The dashboards available to you are determined by
          the organisation where you work. You may request access from this page and in some cases
          we may request additional information from you, before granting access.
        </p>
      </div>
    </div>
  )
}

export default memo(ProductHeaderContainer)
