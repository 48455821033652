import React, { memo } from "react"
import TextButton from "../../../../../global/components/textButton"
import WarningBox from "../../../../../global/components/warningBox"
import { IProfileChangesShape } from "../../../../../global/stores/profileStore"

interface IWarningsProps {
  payload: IProfileChangesShape
  onCancellationRequested: () => void
}

const Warnings = (props: IWarningsProps) => {
  const { payload, onCancellationRequested } = props

  const GoBack = () => {
    return <TextButton onClick={onCancellationRequested}>Go back and update</TextButton>
  }

  if (payload.emailAddress && payload.organisation) {
    return null
  }

  if (payload.emailAddress && !payload.organisation) {
    return (
      <WarningBox>
        You are updating your email address, but not your organisation. Has your organisation also
        changed? <GoBack />
      </WarningBox>
    )
  }

  if (payload.organisation && !payload.emailAddress) {
    return (
      <WarningBox>
        You are updating your organisation but not your email address. Has your email address also
        changed? <GoBack />
      </WarningBox>
    )
  }

  return null
}

export default memo(Warnings)
