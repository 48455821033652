import { observer } from "mobx-react"
import { Container } from "nhsuk-react-components"
import React, { useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import "url-search-params-polyfill"
import { BreadcrumbContext, withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import { AppAdmin } from "../global/components/staticBreadcrumbs"
import { useStores, useFeatureToggles } from "../global/hooks"
import AccessPermissionsView from "./components/accessPermissionsView"
import AdminInfoView from "./components/adminInfoView/adminInfoView"
import ApprovalQuestionsView from "./components/approvalQuestionsView"
import ContactInformationView from "./components/contactInformationView/contactInformationView"
import CoreProductInformationView from "./components/coreProductInformationView"
import ProductInformationView from "./components/productInformationView/productInformationView"
import PublishView from "./components/publishView"
import TableauAccessView from "./components/tableauAccessView/tableauAccessView"
import TableauHeaderView from "./components/tableauReportViewerView"
import TaskListView from "./components/taskListView/index"
import { ProductEditView } from "./enums/enums"
import "./styles.scss"
import { FeatureToggle } from "../global/enums"

const ProductView = observer(() => {
  const { productStore, applicationStore: app, productOwnerAdminStore: adminStore } = useStores()

  const { wrapWithLoader } = React.useContext(LoaderContext)
  const { setBreadcrumbs, createBreadcrumb } = React.useContext(BreadcrumbContext)
  const [loading, setLoading] = React.useState(true)
  let { productId } = useParams()

  const {
    enabledToggles: [tableauWebWrapperEnabled, favourAtoZ],
  } = useFeatureToggles(FeatureToggle.TableauWebWrapperEnabled, FeatureToggle.FavourAtoZ)

  useEffect(() => {
    wrapWithLoader(async () => {
      await productStore.getTaskList()
      setBreadcrumbs([
        AppAdmin,
        createBreadcrumb(`/product/${productId}/edit`, productStore.product.name),
      ])
      setLoading(false)
    })
  }, [productStore.product.name])

  useEffect(() => {
    if (productId && adminStore.myProducts.length < 1) {
      adminStore.getMyProducts().then(() => {
        const selectedProduct = adminStore.myProducts.find(p => p.id === productId)
        if (selectedProduct) {
          app.selectedProductName = selectedProduct.name
        }
      })
    }

    return () => productStore.resetState()
  }, [])

  productStore.product.id = productId

  return (
    !loading && (
      <Container id="product-view" className="product-view">
        <Routes>
          <Route path="*">
            <Route path="" element={<TaskListView />} />
            <Route path={`${ProductEditView.Product}`} element={<ProductInformationView />} />
            <Route path={`${ProductEditView.Contact}`} element={<ContactInformationView />} />
            <Route
              path={`${ProductEditView.AccessPermissions}`}
              element={<AccessPermissionsView />}
            />
            <Route
              path={`${ProductEditView.TableauHeader}`}
              element={tableauWebWrapperEnabled && <TableauHeaderView />}
            />
            <Route
              path={`${ProductEditView.CoreProductInformation}`}
              element={favourAtoZ && <CoreProductInformationView />}
            />
            <Route
              path={`${ProductEditView.ApprovalQuestions}`}
              element={<ApprovalQuestionsView />}
            />
            <Route path={`${ProductEditView.Publish}`} element={<PublishView />} />
            <Route
              path={`${ProductEditView.TableauAccessGroups}`}
              element={<TableauAccessView />}
            />
            <Route path={`${ProductEditView.Admin}`} element={<AdminInfoView />} />
          </Route>
        </Routes>
      </Container>
    )
  )
})

export const ProductManagementRoutingWrapper = () => {
  return <Outlet />
}

export default withWrapper(ProductView)
