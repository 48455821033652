import "./styles.scss"

import React, { forwardRef } from "react"

type TextAreaProps = Partial<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
>

const TextArea = forwardRef(
  ({ onChange, value, rows }: TextAreaProps, ref: React.LegacyRef<HTMLTextAreaElement>) => {
    return (
      <textarea
        className="nhsuk-textarea"
        onChange={onChange}
        ref={ref}
        value={value}
        rows={rows}
      />
    )
  }
)

export default TextArea
