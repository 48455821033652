import Details from "@govuk-react/details"
import Link from "@govuk-react/link"
import _ from "lodash"
import { BodyText, Container, Label, Radios, Select as NhsSelect } from "nhsuk-react-components"
import { Panel } from "nhsuk-react-components/dist/deprecated"
import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Link as ReactLink, Link as RouterLink, useLocation } from "react-router-dom"
import Select from "react-select"
import { withWrapper } from "../global/components/HOC"
import { UserIcon } from "../global/components/icons"
import { LoaderContext } from "../global/components/loaderProvider"
import Tabs from "../global/components/tabPanel"
import { Routes, FeatureToggle } from "../global/enums"
import { useStores, useFeatureToggles } from "../global/hooks"
import { EditProductIcon, InviteIcon, ProductRefreshIcon } from "./assets"
import JobSortContainer from "./components/jobSortContainer"
import ListContainer from "./components/listContainer"
import NameSearch from "./components/searchControls/nameSearch"
import OrganisationSearch from "./components/searchControls/organisationSearch"
import RegionSearch from "./components/searchControls/regionSearch"
import "./styles.scss"

const searchRadios = {
  organisationRadio: "organisation-radio",
  nameRadio: "name-radio",
  regionRadio: "region-radio",
}

const SystemAdminView = props => {
  const { pathname } = useLocation()

  const {
    organisationStore: orgStore,
    profileStore,
    systemAdminStore,
    productOwnerAdminStore,
    organisationStore,
  } = useStores()

  const currentUser = profileStore.user

  const store = props.view === "systemAdmin" ? systemAdminStore : productOwnerAdminStore

  const {
    enabledToggles: [productInviteEnabled, updateProfileEnabled, manageAdmins],
  } = useFeatureToggles(
    FeatureToggle.ProductInviteEnabled,
    FeatureToggle.UpdateProfileEnabled,
    FeatureToggle.ManageAdmins
  )

  const [noResult, setNoResult] = useState(false)
  const [activeRadio, setActiveRadio] = useState(searchRadios.nameRadio)
  const [searchString, setSearchString] = useState(store.searchString)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [noResultsMessage, setNoResultsMessage] = useState("You need to provide a search term.")
  const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(true)

  const isOpsAdmin = pathname.indexOf(Routes.Admin) !== -1

  useEffect(() => {
    wrapWithLoader(async () => {
      await store.getPageData()
      await orgStore.getRegions().then(res => setRegions(res))
      setLoading(false)
    })
  }, [refresh])

  const search = async term =>
    wrapWithLoader(() =>
      store
        .handleSearch(term)
        .then(() => setSearchString(store.searchString))
        .catch(err => {
          if (err.status === 400) {
            setNoResult(true)
          }
        })
    )

  const onClear = async () => {
    setSearchString("")
    await search("")
  }

  const handleSort = async val => {
    await wrapWithLoader(() => store.handleSortMainList(val))
    setSearchString(store.searchString)
  }

  const handleJobRole = async val => {
    await wrapWithLoader(() => store.handleFilterJobRole(val))
    setSearchString(store.searchString)
  }

  const handleOrganisationSearch = _.debounce((searchTerm, callback) => {
    setNoResultsMessage("No results found, try searching again with a different query.")

    orgStore
      .getAllOrganisationModelsByName(searchTerm)
      .then(result => callback(result))
      .catch(error => callback(error))
  }, 250)

  const handleOrganisationSelection = async val => {
    await wrapWithLoader(() => store.handleOrgChange(val !== null ? val : ""))
    setSearchString(store.searchString)
  }

  const handleRegionChange = async val => wrapWithLoader(() => store.handleRegionChange(val))

  const handleTabChange = async idx => {
    store.searchString = ""
    store.selectedOrg = {}
    setSearchString(store.searchString)
    await wrapWithLoader(() => store.setActiveTab(idx))
  }

  const handlePageNavigation = async previous =>
    wrapWithLoader(() => {
      previous ? store.pages.current-- : store.pages.current++
      return store.getMainList()
    })

  const handleMyProductsRefresh = async () =>
    wrapWithLoader(() => {
      store.refreshApplicationData()
      setRefresh(!refresh)
    })

  const handleProductSelection = async e => wrapWithLoader(() => store.setActiveProduct(e.value))

  const handleExpander = e => {
    e.preventDefault()
    if (e.currentTarget.getElementsByTagName("summary")[0] !== e.target.parentElement) return
    if (e.currentTarget.hasAttribute("open")) {
      e.currentTarget.removeAttribute("open")
      e.currentTarget.lastElementChild.style.display = "none"
    } else {
      e.currentTarget.setAttribute("open", true)
      e.currentTarget.lastElementChild.style.display = "block"
    }
  }

  const getSearchControl = () => {
    switch (activeRadio) {
      case searchRadios.nameRadio:
        return (
          <NameSearch
            search={search}
            searchString={searchString}
            onClear={onClear}
            setSearchString={setSearchString}
          />
        )
      case searchRadios.regionRadio:
        return (
          <RegionSearch
            handleRegionChange={handleRegionChange}
            selectedRegion={store.selectedRegion}
            regions={regions}
          />
        )
      case searchRadios.organisationRadio:
        return (
          <OrganisationSearch
            noResultsMessage={noResultsMessage}
            handleOrganisationSearch={handleOrganisationSearch}
            setNoResultsMessage={setNoResultsMessage}
            handleOrganisationSelection={handleOrganisationSelection}
            selectedOrg={store.selectedOrg}
          />
        )
      default:
        return null
    }
  }

  const ProductSelector = () => {
    const groupedOptions = [
      {
        label: "Published",
        options: store.myProducts
          .filter(x => x.published)
          .map(opt => ({
            label: `${opt.name} (${opt.originalName})`,
            value: opt.id,
          })),
      },
      {
        label: "Unpublished",
        options: store.myProducts
          .filter(x => !x.published)
          .map(opt => ({ label: `${opt.name} (${opt.originalName})`, value: opt.id })),
      },
    ]

    return (
      <Fragment>
        <Select
          id="admin-pages__product-select"
          className="admin-pages__product-select"
          classNamePrefix="admin-pages__product-select"
          isLoading={loading}
          isClearable={false}
          isSearchable
          name="Product"
          options={groupedOptions}
          onChange={handleProductSelection}
        />
      </Fragment>
    )
  }

  const selectedProduct = store.myProducts?.find(p => p.id === store.selectedProductId)

  return (
    <Panel className="admin-pages">
      <Container className="admin-pages__outer-container">
        <div id="sysadmin-view" className="admin_pages__container">
          {props.view === "systemAdmin" ? (
            <Label isPageHeading className="admin-pages__page-heading">
              Manage users
            </Label>
          ) : (
            <Label isPageHeading className="admin-pages__page-heading page-heading__title">
              Manage your product and its users
            </Label>
          )}
          {props.view === "systemAdmin" ? (
            <>
              <BodyText className="admin-pages__body-text">
                Approve or decline requests for registration to NHS England applications.
              </BodyText>
              <BodyText className="admin-pages__body-text">
                We promise to deal with applicants as quickly as possible. You should aim to respond
                within two working days.
              </BodyText>
            </>
          ) : (
            <>
              <BodyText className="admin-pages__body-text">
                Approve or decline requests for access, and view your active users.
              </BodyText>
              <BodyText className="admin-pages__body-text">
                We promise to deal with applicants as quickly as possible. You should aim to respond
                within two working days.
              </BodyText>
              <div className="admin-pages__product-select-controls">
                <Label className="admin-pages__bold-label" htmlFor="admin-pages__product-select">
                  Product
                </Label>
                <div className="admin-pages__control-container">
                  <div className="admin-pages__control-container__product-selection">
                    <ProductSelector />
                    {currentUser.isSystemAdministrator && (
                      <div
                        className="admin-pages__product-refresh"
                        onClick={() => handleMyProductsRefresh()}
                      >
                        <ProductRefreshIcon className="admin-pages__product-refresh__icon" />
                        <Link>Refresh</Link>
                      </div>
                    )}
                  </div>
                  {manageAdmins && (
                    <RouterLink
                      className="admin-pages__control-container__manage-admins"
                      to={Routes.ManageAdmins}
                    >
                      Manage Product Owners
                    </RouterLink>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="admin-pages__mid-container admin-pages__page-break">
            {props.view === "systemAdmin" ? (
              <div className="admin-pages__title-container">
                <Label
                  isPageHeading
                  className="admin-pages__sub-heading admin-pages__sub-heading--no-margin"
                >
                  NHS England applications
                </Label>
                <div className="admin-pages__title-container__user-actions-container">
                  <ReactLink
                    to="/invite"
                    className="admin-pages__title-container__user-actions-container__link"
                  >
                    <InviteIcon />
                    <span className="admin-pages__title-container__user-actions-container__link__text">
                      Invite new users
                    </span>
                  </ReactLink>
                </div>
              </div>
            ) : (
              <div className="admin-pages__flex-sub-heading">
                <div className="admin-pages__flex-sub-heading__left-section">
                  <Label
                    isPageHeading
                    className="admin-pages__flex-sub-heading__left-section__sub-heading"
                  >
                    {selectedProduct ? selectedProduct.name : ""}
                  </Label>
                  <Details
                    className={
                      "admin-pages__contact-details-expander" +
                      (store.selectedProductContactInfo && store.selectedProductContactInfo.complete
                        ? ""
                        : " admin-pages__contact-details-expander--contact-details-hide")
                    }
                    summary="Show contact details"
                    onClick={handleExpander}
                  >
                    {store.selectedProductContactInfo && (
                      <>
                        <div className="admin-pages__contact-details-expander__contact-details-section">
                          <dt className="admin-pages__contact-details-expander__contact-details-section__contact-details-header">
                            Product owner:
                          </dt>
                          <dd className="admin-pages__contact-details-expander__contact-details-section__contact-details-info">
                            <div className="admin-pages__contact-details-expander__contact-details-section__contact-details-info__product-owner-name">
                              {store.selectedProductContactInfo.productOwnerName}
                            </div>
                            <div className="admin-pages__contact-details-expander__contact-details-section__contact-details-info__product-owner-email">
                              {store.selectedProductContactInfo.productOwnerEmail}
                            </div>
                          </dd>
                        </div>

                        <div className="admin-pages__contact-details-expander__contact-details-section">
                          <dt className="admin-pages__contact-details-expander__contact-details-section__contact-details-header">
                            Technical support:
                          </dt>
                          <dd className="admin-pages__contact-details-expander__contact-details-section__contact-details-info">
                            <div>{store.selectedProductContactInfo.technicalSupportName}</div>
                            <div>{store.selectedProductContactInfo.technicalSupportEmail}</div>
                          </dd>
                        </div>

                        <div className="admin-pages__contact-details-expander__contact-details-section">
                          <dt className="admin-pages__contact-details-expander__contact-details-section__contact-details-header">
                            User support:
                          </dt>
                          <dd className="admin-pages__contact-details-expander__contact-details-section__contact-details-info">
                            <div>{store.selectedProductContactInfo.userSupportEmail}</div>
                          </dd>
                        </div>
                      </>
                    )}
                  </Details>
                </div>
                <div className="admin-pages__flex-sub-heading__right-section">
                  {!loading && productInviteEnabled && selectedProduct?.published && (
                    <div className="admin-pages__flex-sub-heading__right-section__invite-users">
                      <UserIcon className="admin-pages__flex-sub-heading__right-section__invite-users__icon" />
                      <RouterLink
                        to={`/invite/${store.selectedProductId}`}
                        className="admin-pages__flex-sub-heading__right-section__invite-users__link"
                      >
                        Invite new users
                      </RouterLink>
                    </div>
                  )}
                  {store.selectedProductPermissions.canModifyApplication && (
                    <div className="admin-pages__flex-sub-heading__right-section__edit-product">
                      <EditProductIcon className="admin-pages__flex-sub-heading__right-section__edit-product__icon" />
                      <RouterLink
                        to={`/product/${store.selectedProductId}/edit`}
                        className="admin-pages__flex-sub-heading__right-section__edit-product__link"
                      >
                        Product settings
                      </RouterLink>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="admin-pages__search-sort-container">
              <div className="admin-pages__search-sort-container__search-container">
                <Label
                  className="admin-pages__bold-label"
                  htmlFor="admin-pages__search-sort-container__search-container__radios"
                >
                  Search for users
                </Label>
                <Radios
                  id="admin-pages__radios"
                  className="admin-pages__search-sort-container__search-container__radios"
                  hint="Search by:"
                  inline
                  onChange={e => setActiveRadio(e.target.value)}
                >
                  <Radios.Radio
                    className="admin-pages__name-search"
                    value={searchRadios.nameRadio}
                    checked={activeRadio === searchRadios.nameRadio}
                  >
                    Name
                  </Radios.Radio>
                  <Radios.Radio
                    className="admin-pages__search-sort-container__search-container__organisation-search"
                    value={searchRadios.organisationRadio}
                    checked={activeRadio === searchRadios.organisationRadio}
                  >
                    Organisation
                  </Radios.Radio>
                  {updateProfileEnabled && !isOpsAdmin && (
                    <Radios.Radio
                      className="admin-pages__search-sort-container__search-container__region-search"
                      value={searchRadios.regionRadio}
                      checked={activeRadio === searchRadios.regionRadio}
                    >
                      Region
                    </Radios.Radio>
                  )}
                </Radios>
                {getSearchControl()}
              </div>
              <div className="admin-pages__search-sort-container__sort-container">
                <Label className="admin-pages__bold-label" htmlFor="admin-pages__sort">
                  Sort by
                </Label>
                <NhsSelect
                  id="admin-pages__sort"
                  className="admin-pages__sort"
                  name="sort"
                  onChange={e => handleSort(e.target.value)}
                >
                  {store.sortOptions.map((s, i) => (
                    <NhsSelect.Option key={i} value={s.value}>
                      {s.name}
                    </NhsSelect.Option>
                  ))}
                </NhsSelect>
              </div>
            </div>
            <JobSortContainer handleJobRole={handleJobRole} handleExpander={handleExpander} />
          </div>

          <Tabs className="admin-pages__tabs">
            <Tabs.List className="admin-pages__tabs__list">
              {store.availableTabs.map(status => {
                const tab = store.tab.list[status]
                return (
                  <Tabs.Tab
                    key={tab.name}
                    onClick={() => handleTabChange(tab.value)}
                    selected={store.tab.selected === tab.value}
                  >
                    <div className={tab.status}>
                      {tab.name} ({store.listTotals[tab.value]})
                    </div>
                  </Tabs.Tab>
                )
              })}
            </Tabs.List>
            {store.availableTabs.map(status => {
              const tab = store.tab.list[status]
              return (
                <Tabs.Panel key={`${tab.name}_panel`} selected={store.tab.selected === tab.value}>
                  <ListContainer
                    store={store}
                    noResult={noResult}
                    listTotal={store.listTotals[tab.value]}
                    mode={props.view}
                    view={store.tab.list[store.tab.selected]}
                    paginationControls={handlePageNavigation}
                    application={selectedProduct}
                  />
                </Tabs.Panel>
              )
            })}
          </Tabs>
        </div>
      </Container>
    </Panel>
  )
}

SystemAdminView.propTypes = {
  view: PropTypes.string.isRequired,
}

export default withWrapper(SystemAdminView)
