import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../../global/enums/api"
import { ApplicationType, TaskName } from "../../global/enums/application"
import { IApplicationTaskList, ITask } from "../../global/interfaces/application/interfaces"
import { IStringIndexableType } from "../../global/interfaces/dataTypes/interfaces"
import { get, postApi } from "../../global/utils/api"
import { FeatureToggle } from "../../global/enums"
import { IJSendResponse } from "../../global/interfaces/api/interfaces"
import { IFeatureToggle } from "../../global/interfaces/featureToggle"

const allTaskNames = [
  TaskName.ProductInformation,
  TaskName.TableauReportViewerConfiguration,
  TaskName.AdminInformation,
  TaskName.ContactInformation,
  TaskName.CoreProductInformation,
  TaskName.AccessPermissions,
  TaskName.ApprovalQuestions,
  TaskName.TableauAccessGroups,
]

const taskNameMappings = {
  [allTaskNames[0]]: "Product information",
  [allTaskNames[1]]: "Tableau header",
  [allTaskNames[2]]: "Admin information",
  [allTaskNames[3]]: "Contact information",
  [allTaskNames[4]]: `About the product's listing`,
  [allTaskNames[5]]: "Access permissions",
  [allTaskNames[6]]: "Approval questions",
  [allTaskNames[7]]: "Tableau access groups",
}

interface ITaskListItem {
  id: string | null
  taskName: TaskName
  name: string
  complete: boolean
}

interface IPartialApplication {
  id?: string
  name?: string
  type?: ApplicationType
}

export interface IProductStore {
  product: IPartialApplication
  published: boolean
  identityProviderId: string
  modifiedTasks: IStringIndexableType<boolean>
  taskList: ITaskListItem[]
  externalAppLink: string
  setTaskModified: (view: string, isCompleted: boolean) => void
  getTaskList: () => Promise<IApplicationTaskList>
}

class ProductStore implements IProductStore {
  product: IPartialApplication = { id: "", name: "" }
  published = false
  identityProviderId = ""
  modifiedTasks: IStringIndexableType<boolean> = {}
  taskList: ITaskListItem[] = []
  externalAppLink = ""

  constructor() {
    makeAutoObservable(this)
  }

  generateTaskList = (tasks: ITask[], featureToggleMap: Map<FeatureToggle, boolean>) => {
    const tableauOnlyTasks = [
      TaskName.TableauReportViewerConfiguration,
      TaskName.TableauAccessGroups,
    ]
    const tasksRemaining = [...allTaskNames]

    const removeTask = (removalTask: TaskName) => {
      const idx = tasksRemaining.findIndex(x => x === removalTask)
      if (idx !== -1) {
        tasksRemaining.splice(idx, 1)
      }
    }

    if (!featureToggleMap.has(FeatureToggle.FavourAtoZ)) {
      removeTask(TaskName.CoreProductInformation)
    }

    if (!featureToggleMap.has(FeatureToggle.TableauWebWrapperEnabled)) {
      removeTask(TaskName.TableauReportViewerConfiguration)
    }

    const list: ITaskListItem[] = []
    for (const task of tasksRemaining) {
      const isTableauTask = tableauOnlyTasks.indexOf(task) !== -1

      if (
        !isTableauTask ||
        (isTableauTask &&
          (this.product.type === ApplicationType.Tableau ||
            this.product.type === ApplicationType.TableauAzure ||
            this.product.type === ApplicationType.TableauViewPoint))
      ) {
        const foundTask = tasks.find(t => t.taskName === task)
        list.push({
          id: foundTask?.taskId || null,
          taskName: task,
          name: taskNameMappings[foundTask ? foundTask.taskName : task],
          complete: foundTask?.complete || false,
        })
      }
    }

    this.taskList = [...list]
  }

  resetState = () => {
    this.product = { id: "", name: "" }
    this.modifiedTasks = {}
    this.taskList = []
    this.published = false
  }

  getTaskList = (): Promise<IApplicationTaskList> => {
    return new Promise<IApplicationTaskList>(resolve => {
      get<IApplicationTaskList>(`/application/${this.product.id}/tasklist`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          this.product = {
            ...this.product,
            name: res.data.applicationName,
            type: res.data.type,
          }

          this.published = res.data.published
          this.identityProviderId = res.data.identityProviderId

          get<IJSendResponse<IFeatureToggle[]>>("/featuretoggle/").then(togglesResponse => {
            if (togglesResponse.status === HttpStatusCode.OK) {
              const map = new Map<FeatureToggle, boolean>(
                togglesResponse.data.data.map(({ id, enabledForAll }) => [id, enabledForAll])
              )
              this.generateTaskList(res.data.tasks, map)
              resolve(res.data)
            }
          })
        }
      })
    })
  }

  setTaskModified = (view: string, isCompleted: boolean) => {
    const task = this.taskList.find(
      x => x && x.name && x.name.replace(/\s+/g, "-").toLowerCase() === view
    )
    if (task) {
      task.complete = isCompleted
      this.modifiedTasks[view] = true
    }
  }

  togglePublish = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.published = !this.published
      postApi(`/application/${this.product.id}/publish`, null)
        .then(() => resolve())
        .catch(() => reject())
    })
  }

  getExternalAppLink = (): void => {
    this.externalAppLink = `https://${window.location.host}/register?app=${this.identityProviderId}`
  }
}

export default new ProductStore()
