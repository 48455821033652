import { BodyText } from "nhsuk-react-components"
import React, { memo } from "react"
import { AsyncSearchableSelect } from "../../../global/components/searchableSelect"
import "../../styles.scss"

const OrganisationSearch = props => {
  const {
    noResultsMessage,
    handleOrganisationSearch,
    setNoResultsMessage,
    handleOrganisationSelection,
    selectedOrg,
  } = props

  return (
    <>
      <BodyText>
        <span>
          Start typing organisation name or code and select the organisation from the dropdown
        </span>
      </BodyText>
      <AsyncSearchableSelect
        className="input-field admin-info-custom-select"
        noOptionsMessage={() => noResultsMessage}
        loadOptions={handleOrganisationSearch}
        onClear={() => setNoResultsMessage("You need to provide a search term.")}
        onChange={handleOrganisationSelection}
        value={selectedOrg}
      ></AsyncSearchableSelect>
    </>
  )
}

export default memo(OrganisationSearch)
