import { BodyText, Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { Routes } from "../global/enums"
import "./styles.scss"

const PageNotFound = () => {
  return (
    <Container className="page-not-found">
      <Col width="three-quarters" className="page-not-found__column">
        <Label isPageHeading className="page-not-found__column__label">
          Page not found
        </Label>
        <BodyText>
          Sorry, this page doesn't exist. Check the link or address is correct, or go back to the{" "}
          <a href={Routes.AToZLandingPage}>homepage</a>.
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(PageNotFound)
