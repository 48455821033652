import { Form, Label, Radios } from "nhsuk-react-components"
import React from "react"
import { TableauSecurityModel } from "../../../../../global/enums"
import { useFeatureToggles, useStores } from "../../../../../global/hooks"
import { ITableauAccessGroup } from "../../../../../global/interfaces/tableau/interfaces"
import { FeatureToggle } from "../../../../../global/enums/featureToggle/enums"

const SecurityModel = () => {
  const { tableauAccessViewStore } = useStores()

  const {
    tableauAccess: { securityModel },
    errors,
  } = tableauAccessViewStore

  const {
    enabledToggles: [customTableauGroups],
  } = useFeatureToggles(FeatureToggle.CustomTableauGroups)

  const handleCheckboxChange = (securityModelId: number) => {
    tableauAccessViewStore.tableauAccess.securityModel = securityModelId

    let newTableauAccessGroups: ITableauAccessGroup[] = []

    switch (securityModelId) {
      case TableauSecurityModel.Regional:
        newTableauAccessGroups = tableauAccessViewStore.tableauAccess.tableauAccessGroups.filter(
          (tag: ITableauAccessGroup) => tag.regionCode
        )
        break
      case TableauSecurityModel.Standard:
        newTableauAccessGroups = tableauAccessViewStore.tableauAccess.tableauAccessGroups.filter(
          (tag: ITableauAccessGroup) => !tag.regionCode
        )
        break
      case TableauSecurityModel.Custom:
        newTableauAccessGroups = tableauAccessViewStore.tableauAccess.tableauAccessGroups.filter(
          (tag: ITableauAccessGroup) => tag.tableauOrganisationGroupId
        )
        break
    }

    tableauAccessViewStore.tableauAccess.tableauAccessGroups = newTableauAccessGroups
  }

  return (
    <Form className="product-info-form-group">
      <Label>Security model</Label>
      <Radios
        error={errors.securityModel}
        onChange={(e: React.FormEvent<HTMLDivElement>) => {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          handleCheckboxChange(parseInt((e.target as any).value))
        }}
      >
        <Radios.Radio
          checked={securityModel === TableauSecurityModel.Regional}
          value={TableauSecurityModel.Regional}
        >
          Regional
        </Radios.Radio>
        <Radios.Radio
          checked={securityModel === TableauSecurityModel.Standard}
          value={TableauSecurityModel.Standard}
        >
          Standard
        </Radios.Radio>
        {customTableauGroups && (
          <Radios.Radio
            checked={securityModel === TableauSecurityModel.Custom}
            value={TableauSecurityModel.Custom}
          >
            Custom
          </Radios.Radio>
        )}
      </Radios>
    </Form>
  )
}

export default SecurityModel
