import { observer } from "mobx-react"
import { BodyText, Input, Label, Select } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { IUpdateSecurityData } from "../../../global/stores/profileStore"
import { ErrorsCollection } from "../../../global/types"

interface ISetSecurityQuestionProps {
  form: IUpdateSecurityData
  error: ErrorsCollection
  onChange: (e: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => void
  isUpdate: boolean
}

const SetSecurityQuestion = observer((props: ISetSecurityQuestionProps) => {
  const { form, error, onChange, isUpdate } = props
  const [recoveryQuestion, setRecoveryQuestion] = useState((form && form.recoveryQuestion) || "")
  const [recoveryAnswer, setRecoveryAnswer] = useState((form && form.recoveryAnswer) || "")

  useEffect(() => {
    if (form.recoveryQuestion !== recoveryQuestion) {
      setRecoveryQuestion(form.recoveryQuestion || "")
    }
  }, [form.recoveryQuestion])

  useEffect(() => {
    if (form.recoveryAnswer !== recoveryAnswer) {
      setRecoveryAnswer(form.recoveryAnswer || "")
    }
  }, [form.recoveryAnswer])

  return (
    <>
      <Label>{isUpdate ? "Select your new security question" : "Security question"}</Label>
      <BodyText>
        Select a security question from the list.
        <br />
        If you forget your password, we will ask you for the answer, in order to provide a new
        password.
      </BodyText>
      <Select
        id="recoveryQuestion"
        name="recoveryQuestion"
        error={error.getFirstFieldError("recoveryQuestion")}
        value={recoveryQuestion}
        onChange={onChange}
        className="input-field"
      >
        <Select.Option value="" selected disabled>
          Answer to your security question
        </Select.Option>
        <Select.Option value="What is the name of your first pet?">
          What is the name of your first pet?
        </Select.Option>
        <Select.Option value="In what town or city did your parents meet?">
          In what town or city did your parents meet?
        </Select.Option>
        <Select.Option value="What was the make of your first car?">
          What was the make of your first car?
        </Select.Option>
        <Select.Option value="What is the first name of your first school teacher?">
          What is the first name of your first school teacher?
        </Select.Option>
        <Select.Option value="In which town or city did you meet your first girlfriend/boyfriend?">
          In which town or city did you meet your first girlfriend/boyfriend?
        </Select.Option>
        <Select.Option value="What was the first foreign country you have travelled to?">
          What was the first foreign country you have travelled to?
        </Select.Option>
        <Select.Option value="What was the model of your first mobile phone?">
          What was the model of your first mobile phone?
        </Select.Option>
        <Select.Option value="What is the first name of your first best friend?">
          What is the first name of your first best friend?
        </Select.Option>
      </Select>

      <Label>
        {!isUpdate && "Answer to your security question"}
        {isUpdate && "Answer to your new security question"}
      </Label>
      <BodyText>
        {!isUpdate && "Provide the answer to the security question you have selected."}
        {isUpdate && "Provide the answer to the new security question you have selected."}
      </BodyText>
      <Input
        id="recoveryAnswer"
        name="recoveryAnswer"
        value={recoveryAnswer}
        error={error.getFirstFieldError("recoveryAnswer")}
        onChange={onChange}
        autoComplete="off"
        className="input-field"
      />
    </>
  )
})

export default SetSecurityQuestion
