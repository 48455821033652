import { observer } from "mobx-react"
import { BodyText, Button, Label } from "nhsuk-react-components"
import React from "react"
import TextButton from "../../../../../global/components/textButton"
import { useStores } from "../../../../../global/hooks"
import "./styles.scss"

interface IContinuationProps {
  isEditMode: boolean
  completeAndSave: () => void
  saveAndReturn: () => void
}

const Continuation = observer((props: IContinuationProps) => {
  const { isEditMode, completeAndSave, saveAndReturn } = props
  const { productStore } = useStores()

  if (!isEditMode) {
    return null
  }

  const onClick = (e: React.MouseEvent, func: () => void) => {
    e.preventDefault()
    func()
  }

  if (productStore.published) {
    return (
      <div className="continuation-published-save-control">
        <Label className="continuation-published-save-control__input-label">Publish changes</Label>
        <BodyText className="continuation-published-save-control__input-hint">
          By clicking 'Save and publish', you confirm that you are making these changes in line with
          business policies.
        </BodyText>
        <Button id="publish-button" onClick={(e: React.MouseEvent) => onClick(e, completeAndSave)}>
          Save and publish
        </Button>
      </div>
    )
  }

  return (
    <div className="continuation-button-controls">
      <Button
        className="continuation-button-controls__publish-button"
        onClick={(e: React.MouseEvent) => onClick(e, completeAndSave)}
      >
        Save and complete
      </Button>
      <TextButton
        className="continuation-button-controls__return-link"
        onClick={(e: React.MouseEvent) => onClick(e, saveAndReturn)}
      >
        Save and return to task list
      </TextButton>
    </div>
  )
})

export default Continuation
