import { IOrganisation, IOrganisationModel } from "../../interfaces/organisation/interfaces"
import { ISearchResultOrganisation } from "../../interfaces/searchResults/interfaces"
import { OrganisationKeys, OrganisationTypes } from "../../constants/organisation/constants"

export const mapOrganisationToOrganisationModel = (org: IOrganisation): IOrganisationModel => ({
  label: `${org.name} (${org.code})`,
  value: org.code,
  regionCodes: org.regionCodes,
})

export const mapOrganisationModelToOrganisation = (org: IOrganisationModel): IOrganisation => ({
  name: org.label.split(" (")[0],
  code: org.value,
  regionCodes: org.regionCodes,
  isOrgType: false,
  organisationType: "",
  category: "",
})

export const mapSearchResultOrganisationToOrganisation = (
  searchResultOrganisation: ISearchResultOrganisation | null
): IOrganisation | null => {
  if (!searchResultOrganisation) {
    return null
  }

  const { organisationName, code, organisationType } = searchResultOrganisation

  return {
    name: organisationName,
    code: code,
    isOrgType: false,
    organisationType: organisationType ? organisationType : "",
    regionCodes: [],
    category: "",
  }
}

export const isParentOrganisation = (code: string) =>
  Object.values(OrganisationTypes).flat().indexOf(code) !== -1

export const getCorrectedOrgCode = (code: string) => {
  if (code === OrganisationKeys.keyOldNHSI) {
    return OrganisationKeys.keyNHSEI
  }

  return code
}
