import { IUserApplication, IUserProduct } from "../../../../../global/interfaces/user/interfaces"
import React, { Fragment } from "react"
import { Button } from "nhsuk-react-components"
import { IStepTwoInformation } from "../../../../../global/interfaces/stepTwo/interfaces"
import { UserStatus } from "../../../../../global/enums"
import { ITabItem } from "../../../../../global/interfaces/admin"

import "../../styles.scss"

interface IExpanderContent {
  productView: boolean
  product: IUserProduct
  canExpandDueToStepTwoInformationAvailability: boolean
  canExpandToShowDecisionByAdmin: boolean
  emailAddress: string
  jobRole: string
  applicationMapping: IUserApplication
  view: ITabItem<UserStatus>
  toggleModal: (show: boolean, type: string) => void
}

const ExpanderContent = ({
  productView,
  product,
  canExpandDueToStepTwoInformationAvailability,
  canExpandToShowDecisionByAdmin,
  emailAddress,
  jobRole,
  applicationMapping,
  view,
  toggleModal,
}: IExpanderContent) => {
  const getRequestProcessingHeader = () => {
    switch (view.name) {
      case "Requests":
        return ""
      case "Approved users":
        return "Reason provided for approving access to application"
      case "Declined requests":
        return "Reason provided for declining access to application"
      default:
        return ""
    }
  }

  const canExpandFromBoth =
    canExpandDueToStepTwoInformationAvailability && canExpandToShowDecisionByAdmin

  return (
    <div>
      {productView && <dt className="list-item__term">Product:</dt>}
      {productView && (
        <dd className="list-item__description">{product?.productName || "Product"}</dd>
      )}
      <div className="list--items__header-container">
        <div className={`${canExpandFromBoth ? "list-item__header-sub-container__section" : ""}`}>
          {canExpandDueToStepTwoInformationAvailability && (
            <>
              <div className="list-item__term__header">Step two answers</div>
              <dt className="list-item__term">Email address:</dt>
              <dd className="list-item__description">{emailAddress}</dd>
              <dt className="list-item__term">Job role:</dt>
              <dd className="list-item__description">{jobRole}</dd>
              {applicationMapping &&
                applicationMapping.stepTwoInformation.map((info: IStepTwoInformation) => (
                  <Fragment key={info.approvalQuestionId}>
                    <dt className="list-item__term">{info.approvalQuestion.shortDescription}</dt>
                    <dd className="list-item__description">{info.answer}</dd>
                  </Fragment>
                ))}
            </>
          )}
        </div>

        {canExpandDueToStepTwoInformationAvailability && canExpandToShowDecisionByAdmin && <hr />}

        {canExpandToShowDecisionByAdmin && (
          <div className={`${canExpandFromBoth ? "list-item__header-sub-container__section" : ""}`}>
            <div className="list-item__term__header">{getRequestProcessingHeader()}</div>
            <dt className="list-item__description">
              {applicationMapping.requestProcessResultReason}
            </dt>
          </div>
        )}
      </div>

      {view.status === "pending" && (
        <div className="list-item__flex-button-container">
          <Button
            className="list-item__flex-button-container__container-button list-item__flex-button-container__container-button--primary"
            secondary={false}
            onClick={() => toggleModal(true, "approve")}
          >
            Approve
          </Button>
          <Button
            className="list-item__flex-button-container__container-button list-item__flex-button-container__container-button--secondary"
            secondary
            onClick={() => toggleModal(true, "reject")}
          >
            Decline
          </Button>
        </div>
      )}
    </div>
  )
}

export default ExpanderContent
