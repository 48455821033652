import { Form, Radios } from "nhsuk-react-components"
import React, { FormEvent } from "react"
import { IOrganisation } from "../../../interfaces/organisation"
import { ISearchResultOrganisation } from "../../../interfaces/searchResults/interfaces"
import { ErrorsCollection } from "../../../types"
import { mapSearchResultOrganisationToOrganisation } from "../helper"
import "./styles.scss"

interface ISearchResultProps {
  organisations: ISearchResultOrganisation[]
  selectedOrganisation: IOrganisation | null
  setSelectedOrganisation: (organisation: IOrganisation | null) => void
  errors: ErrorsCollection | null | undefined
}

const SearchResults = (props: ISearchResultProps) => {
  const { organisations, selectedOrganisation, setSelectedOrganisation, errors } = props

  const onSelectionChanged = (event: FormEvent<HTMLDivElement>) => {
    event.stopPropagation()
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const foundOrg = organisations.find(org => org.code === (event.target as any).value)
    const mapped = foundOrg ? mapSearchResultOrganisationToOrganisation(foundOrg) : null
    setSelectedOrganisation(mapped)
    if (errors) {
      errors.removeWithPredicate(err => err.key === "primaryOrganisation")
    }
  }

  return (
    <Form>
      <Radios onChange={onSelectionChanged} className="searchResults__radios">
        {organisations.map(organisation => {
          return (
            <Radios.Radio
              className="searchResults__radio"
              id={organisation.id}
              key={`searchresult_${organisation.code}`}
              value={organisation.code}
              checked={
                selectedOrganisation !== null && selectedOrganisation.code === organisation.code
              }
            >
              {organisation.friendlyName || organisation.organisationName}{" "}
              <span style={{ fontWeight: "bold" }}>({organisation.code})</span>
            </Radios.Radio>
          )
        })}
      </Radios>
    </Form>
  )
}

export default SearchResults
