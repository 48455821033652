import React from "react"
import { NavLink } from "react-router-dom"

interface INavInternalLinkProps {
  href: string
  tabIndex: number
  children: React.ReactNode
}

const NavInternalLink = (props: INavInternalLinkProps) => {
  const { href, tabIndex, children } = props

  return (
    <li>
      <NavLink tabIndex={tabIndex} to={href} className="nhsuk-header__navigation-link">
        {children}
      </NavLink>
    </li>
  )
}

export default NavInternalLink
