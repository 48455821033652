import _ from "lodash"
import { makeAutoObservable } from "mobx"
import { IFluentValidationErrorResponse } from "../../../global/interfaces/api/interfaces"
import { getApi, postApi } from "../../../global/utils/api"
import { IDownload, ITableauReportViewerDatabase, IWorkbook } from "./interfaces"

const tableauReportViewerDatabase: ITableauReportViewerDatabase = {
  errors: {},
  workbook: {
    applicationId: "",
    complete: false,
    dataDownloadFileTypes: 0,
    downloads: [],
    embedEnabled: false,
    embedPathName: "",
    id: "",
    productUrl: "",
    views: [],
    selectedDownloadFormats: [],
    selectedDownloadOption: 0,
    availableDownloadFormats: [],
  },
}

export interface ITableauReportViewerConfigurationStore {
  resetState: () => void
  getWorkbook: (productId: string) => void
  saveWorkbook: () => void
  editWorkbookProperty: (func: (workbook: IWorkbook) => void) => void
  addDownload: (download: IDownload) => void
  clearAllWorkbookDownloads: () => void
  registerAllViewsForDownload: () => void
  toggleDownloadFormatOptions: (downloadOption: string) => void
  setSelectedWorkbookDownloadOption: (value: number) => void
  tableauReportViewerData: ITableauReportViewerDatabase
  clearDownloadFormatOptions: () => void
}

class TableauReportViewerConfigurationStore implements ITableauReportViewerConfigurationStore {
  tableauReportViewerData: ITableauReportViewerDatabase = _.cloneDeep(tableauReportViewerDatabase)

  constructor() {
    makeAutoObservable(this)
  }

  originalReportViewerData: ITableauReportViewerDatabase = _.cloneDeep(tableauReportViewerDatabase)

  resetState = (): void => {
    this.tableauReportViewerData = _.cloneDeep(tableauReportViewerDatabase)
  }

  getWorkbook = async (productId: string): Promise<void> => {
    this.resetState()
    const response = await getApi(`/TableauReportViewerConfiguration?applicationId=${productId}`)
    if (response.status === 200) {
      this.tableauReportViewerData.workbook = { ...response.data }
      this.tableauReportViewerData.workbook.availableDownloadFormats =
        response.data.availableDownloadFormats
      this.tableauReportViewerData.workbook.selectedDownloadFormats =
        response.data.selectedDownloadFormats
      this.tableauReportViewerData.workbook.applicationId = productId
      this.originalReportViewerData = this.tableauReportViewerData
      this.postFetch()
    }
  }

  postFetch = (): void => {
    const selectedDownloadFormat = this.tableauReportViewerData.workbook.selectedDownloadOption
    if (selectedDownloadFormat === 2 || selectedDownloadFormat === 0) {
      this.tableauReportViewerData.workbook.downloads = [
        {
          id: null,
          dashboardName: "",
          worksheetName: "",
          title: "",
        },
      ]
    }
  }

  saveWorkbook = (): Promise<void> => {
    this.preSave()
    return new Promise((resolve, reject) => {
      postApi(`/TableauReportViewerConfiguration/`, this.tableauReportViewerData.workbook)
        .then(() => resolve())
        .catch((err: IFluentValidationErrorResponse) => {
          if (this.tableauReportViewerData.workbook.complete) {
            this.tableauReportViewerData.workbook.complete = false
          }
          this.tableauReportViewerData.errors = { ...err.response.data.errors }
          reject()
        })
    })
  }

  editWorkbookProperty = (func: (workbook: IWorkbook) => void): void => {
    const newWorkbook = { ...this.tableauReportViewerData.workbook }
    func(newWorkbook)
    this.tableauReportViewerData.workbook = newWorkbook
  }

  addDownload = (download: IDownload): void => {
    this.tableauReportViewerData.workbook.downloads = [
      ...this.tableauReportViewerData.workbook.downloads,
      download,
    ]
  }

  clearAllWorkbookDownloads = (): void => {
    this.tableauReportViewerData.workbook.downloads = []
  }

  registerAllViewsForDownload = (): void => {
    const missingDownloads = this.originalReportViewerData.workbook.views.filter(
      view =>
        !this.originalReportViewerData.workbook.downloads.some(
          download => download.dashboardName === view
        )
    )

    let newDownloads: IDownload[] = missingDownloads.map(view => ({
      id: null,
      dashboardName: view,
      title: "all data from this tab",
      worksheetName: "",
    }))

    newDownloads = [...newDownloads]
    this.tableauReportViewerData.workbook.downloads = newDownloads
  }

  toggleDownloadFormatOptions = (format: string): void => {
    const values = this.tableauReportViewerData.workbook.selectedDownloadFormats.includes(format)
      ? this.tableauReportViewerData.workbook.selectedDownloadFormats.filter(
          currentFormat => currentFormat !== format
        )
      : [...this.tableauReportViewerData.workbook.selectedDownloadFormats, format]

    this.tableauReportViewerData.workbook.selectedDownloadFormats = values
  }

  clearDownloadFormatOptions = (): void => {
    this.tableauReportViewerData.workbook.selectedDownloadFormats = []
  }

  setSelectedWorkbookDownloadOption = (value: number): void => {
    this.tableauReportViewerData.workbook.selectedDownloadOption = value
  }

  private preSave = (): void => {
    if (this.tableauReportViewerData.workbook.selectedDownloadOption === 0) {
      this.tableauReportViewerData.workbook.selectedDownloadFormats = []
      this.tableauReportViewerData.workbook.downloads = []
    }
    if (this.tableauReportViewerData.workbook.selectedDownloadOption === 2) {
      this.registerAllViewsForDownload()
    }
  }
}

export default new TableauReportViewerConfigurationStore()
