import { useState, useEffect } from "react"
import { get } from "../utils/api"

interface RegistrationHookProps {
  registrationToken: string | null
  existsError?: boolean | null
}

interface RegistrationHookResult {
  tokenExists: boolean
  existCheckCompleted: boolean
}

const useRegistrationHook = ({
  registrationToken,
  existsError,
}: RegistrationHookProps): RegistrationHookResult => {
  const [tokenExists, setTokenExists] = useState<boolean>(true)
  const [existCheckCompleted, setExistCheckCompleted] = useState<boolean>(false)

  useEffect(() => {
    if (existsError) {
      setTokenExists(false)
      setExistCheckCompleted(true)
      return
    }

    // Make the request here to see if already accepted
    function checkExistence() {
      if (registrationToken) {
        get<boolean>(`registration/exists?token=${registrationToken}`)
          .then(res => {
            setTokenExists(res.data)
            setExistCheckCompleted(true)
          })
          .catch(() => {
            setExistCheckCompleted(true)
          })
      } else {
        setExistCheckCompleted(true)
      }
    }

    checkExistence()
  }, [registrationToken, existsError])

  return {
    tokenExists,
    existCheckCompleted,
  }
}

export default useRegistrationHook
