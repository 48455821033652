import { makeAutoObservable } from "mobx"
import { getApi, postApi } from "../../../global/utils/api"
import _ from "lodash"

const coreProductInfoData = {
  errors: {
    coreProductGrouping: null,
    searchable: null,
  },

  coreProductInformation: {
    applicationId: null,
    coreProductTopicId: null,
    coreProductGrouping: undefined,
    complete: false,
    searchable: true,
    id: null,
  },
}

const coreProductCategory = {
  0: "",
  1: "Analytics Hub",
  2: "Data Collections",
}

const urls = {
  coreproductinfobaseUrl: "coreproductinfo",
  fetchCoreProductInfoByApplicationId: "coreproductinfo/application/",
  fetchTopics: "/coreProductTopic",
}

export class CoreProductInformationStore {
  coreproductInformationData = _.cloneDeep(coreProductInfoData)
  coreProductCategories = _.cloneDeep(coreProductCategory)
  topics = []

  constructor() {
    makeAutoObservable(this)
  }

  getCoreProductInformationData = productId => {
    const promises = [this.getCoreProductInformationByProductId(productId), this.getTopics()]
    return Promise.all(promises)
  }

  resetState = () => {
    this.coreproductInformationData = _.cloneDeep(coreProductInfoData)
  }

  getCoreProductInformationByProductId = productId => {
    this.resetState()
    const url = `${urls.fetchCoreProductInfoByApplicationId}${productId}`
    return new Promise((resolve, reject) => {
      getApi(url)
        .then(res => {
          if (res.status === 200) {
            this.coreproductInformationData.coreProductInformation = { ...res.data }
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
        .then(() => {
          this.coreproductInformationData.coreProductInformation.applicationId = productId
          resolve()
        })
    })
  }

  getTopics = () => {
    return new Promise((resolve, reject) => {
      getApi(`${urls.fetchTopics}`)
        .then(res => {
          this.topics = res.data
        })
        .catch(err => {
          reject(err)
        })
        .then(() => {
          resolve()
        })
    })
  }

  saveCoreProductInformation = () => {
    return new Promise((resolve, reject) => {
      postApi(
        `${urls.coreproductinfobaseUrl}`,
        this.coreproductInformationData.coreProductInformation
      )
        .then(() => resolve())
        .catch(res => {
          if (this.coreproductInformationData.coreProductInformation.complete)
            this.coreproductInformationData.coreProductInformation.complete = false
          this.coreproductInformationData.errors = { ...res.response.data.errors }
          reject()
        })
    })
  }
}

export default new CoreProductInformationStore()
