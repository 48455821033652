import React, { useContext, useState } from "react"
import { observer } from "mobx-react"
import { Button } from "nhsuk-react-components"
import { CloseIcon } from "../../../../global/components/icons"
import { LoaderContext } from "../../../../global/components/loaderProvider"
import { useStores } from "../../../../global/hooks"
import { Workbook } from "@tableau/embedding-api"
import ViewRow from "./components/viewRow"

import "./styles.scss"

interface ICustomViewsModalContent {
  closeModal: () => void
}

const CustomViewsModalContent = observer(({ closeModal }: ICustomViewsModalContent) => {
  const {
    tableauReportViewerStore: {
      currentView,
      setCurrentView,
      tableauVisualization,
      loadingCustomViews,
      setCustomViews,
      customViews,
    },
  } = useStores()

  const { wrapWithLoader } = useContext(LoaderContext)
  const [newViewName, setNewViewName] = useState("")

  const createView = (workbook?: Workbook) => {
    if (!workbook) return

    if (newViewName.trim().length === 0) {
      alert("Invalid custom view name")
    } else {
      wrapWithLoader(async () => {
        const newView = await workbook.saveCustomViewAsync(newViewName)
        setNewViewName(newView.name)
        setCurrentView(newView.name)
        await workbook.showCustomViewAsync(newView.name)
        await setCustomViews()
      })
    }
  }

  const setDefaultView = async (viewName: string, workbook?: Workbook) => {
    await wrapWithLoader(async () => {
      if (!workbook) return
      if (currentView !== viewName) {
        setCurrentView(viewName)
      }
      const name = viewName.length === 0 ? (null as unknown as string) : viewName
      try {
        await workbook.showCustomViewAsync(name)
      } finally {
        await workbook.setActiveCustomViewAsDefaultAsync()
        await setCustomViews()
      }
    })
  }

  const deleteView = async (viewName: string, workbook?: Workbook) => {
    await wrapWithLoader(async () => {
      if (!workbook) return
      try {
        await workbook.removeCustomViewAsync(viewName)
        if (currentView === viewName) {
          await workbook.showCustomViewAsync(null as unknown as string)
        }
      } finally {
        setCurrentView("")
        await setCustomViews()
      }
    })
  }

  const renameCustomView = (viewName: string, newName: string, workbook?: Workbook) =>
    wrapWithLoader(async () => {
      if (!workbook) return

      const fetchedCustomViews = await workbook.getCustomViewsAsync()
      const customView = fetchedCustomViews.find(customView => customView.name === viewName)
      if (customView) {
        customView.name = newName
        await customView.saveAsync()
        if (currentView === viewName) {
          setCurrentView(newName)
        }
      }
      await setCustomViews()
      closeModal()
    })

  if (!tableauVisualization || loadingCustomViews) return <></>

  return (
    <div className="modal-content">
      <div className="modal-header">
        <span className="modal-header-title">Create new and manage custom views</span>
        <div className="modal-close-icon-container" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <p className="view-saving-guidance">
        You can save your current view. When you select the view from the list, the filters will be
        set to this view.
      </p>
      <span className="modal-title">Create new view</span>
      <p className="view-name-input-header">View name</p>
      <div id="input-container">
        <div id="input">
          <input
            className="nhsuk-input"
            onChange={event => setNewViewName(event.target.value)}
            value={newViewName}
          />
        </div>
        <Button id="inline-input-button" onClick={() => createView(tableauVisualization.workbook)}>
          Create view
        </Button>
      </div>
      <span className="modal-title">Manage existing views</span>
      {customViews.length > 0 ? (
        <>
          {customViews.map(customView => {
            const name = customView.name
            return (
              <ViewRow
                key={name}
                viewName={name}
                viewValue={name}
                last={false}
                canDelete={true}
                setDefaultView={setDefaultView}
                deleteView={deleteView}
                isDefault={customView.default}
                renameCustomView={renameCustomView}
                renamingEnabled={true}
              />
            )
          })}
          <ViewRow
            key="Original"
            viewName="Original"
            viewValue=""
            setDefaultView={setDefaultView}
            last
            canDelete={false}
            isDefault={!customViews.some(view => view.default)}
            renamingEnabled={false}
            renameCustomView={renameCustomView}
            deleteView={deleteView}
          />
        </>
      ) : (
        <p>You do not currently have any saved views to manage.</p>
      )}
    </div>
  )
})

export default CustomViewsModalContent
