import React from "react"

interface ICrossIconProps {
  height?: string
  width?: string
}

const CrossIcon: React.FC<ICrossIconProps> = props => {
  const { height = "1.5rem", width = "1.5rem" } = props

  return (
    <svg
      className="nhsuk-icon nhsuk-icon__cross"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M17 18.5c-.4 0-.8-.1-1.1-.4l-10-10c-.6-.6-.6-1.6 0-2.1.6-.6 1.5-.6 2.1 0l10 10c.6.6.6 1.5 0 2.1-.3.3-.6.4-1 .4z"></path>
      <path d="M7 18.5c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l10-10c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-10 10c-.3.3-.6.4-1 .4z"></path>
    </svg>
  )
}

export default CrossIcon
