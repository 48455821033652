import React, { useEffect } from "react"
import FixedLengthSpan from "../../../global/components/fixedLengthSpan"
import { NotificationStatus } from "../../../global/enums/notification"

export const DownloadNotificationMessage = ({
  setIsSuccessfulNotification,
  message,
  messageSplitCharacter,
  className,
  maxLength,
  suffix,
  open,
  read,
  status,
}: {
  setIsSuccessfulNotification: React.Dispatch<React.SetStateAction<boolean>>
  message: string
  read: boolean
  messageSplitCharacter: string
  className: string
  maxLength: number
  suffix: string
  open: boolean
  status: NotificationStatus
}) => {
  const messageParts = message.split(messageSplitCharacter)
  const header = messageParts[0]
  const body = messageParts[1]

  const isSuccess = status === NotificationStatus.Succeeded

  useEffect(() => {
    setIsSuccessfulNotification(isSuccess)
  }, [])

  const DownloadMessageHeader = (
    <FixedLengthSpan
      className={className}
      maxLength={maxLength}
      suffix={suffix}
      text={header}
      derestrict={open}
    />
  )

  if (!open && !read) {
    return (
      <>
        <b>{DownloadMessageHeader}</b>
      </>
    )
  }

  return (
    <>
      <div
        className={`notification-event__inner-container__upper-container__downloadevent ${className}`}
      >
        {DownloadMessageHeader}
        {read && open && (
          <>
            <br></br>
            <span dangerouslySetInnerHTML={{ __html: body }} />
          </>
        )}
      </div>
    </>
  )
}

export default DownloadNotificationMessage
