import _ from "lodash"
import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../enums/api"
import { IFluentValidationErrorResponse } from "../interfaces/api/interfaces"
import { IContactInfo } from "../interfaces/contactInfo/interfaces"
import { ErrorsCollection } from "../types"
import { get, post } from "../utils/api"

const contactInformationData: IContactInfo = {
  id: null,
  applicationId: "",
  complete: false,
  productOwnerName: "",
  productOwnerEmail: "",
  technicalSupportName: "",
  technicalSupportEmail: "",
  userSupportEmail: "",
}

export interface IContactInfoStore {
  errors: ErrorsCollection
  resetState: () => void
  getContactInformation: (productId: string) => Promise<IContactInfo>
  saveContactInformation: (contactInfo: IContactInfo) => Promise<IContactInfo>
  contactInformationData: IContactInfo
}

class ContactInfoStore implements IContactInfoStore {
  constructor() {
    makeAutoObservable(this)
  }
  contactInformationData = _.cloneDeep(contactInformationData)
  errors = new ErrorsCollection()

  resetState = () => {
    this.errors = new ErrorsCollection()
  }

  getContactInformation = (productId: string): Promise<IContactInfo> => {
    return new Promise<IContactInfo>((resolve, reject) => {
      get<IContactInfo>(`/contactinfo?applicationId=${productId}`)
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            resolve(res.data)
          }
          reject()
        })
        .catch(err => {
          debugger
        })
        .then(() => {
          this.contactInformationData.applicationId = productId
          resolve(this.contactInformationData)
        })
    })
  }

  saveContactInformation = (contactInfo: IContactInfo): Promise<IContactInfo> => {
    return new Promise<IContactInfo>((resolve, reject) => {
      post<IContactInfo>(`/contactinfo/`, contactInfo)
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            resolve(res.data)
          }
          reject()
        })
        .catch(
          (err: IFluentValidationErrorResponse) =>
            (this.errors = ErrorsCollection.fromFluentValidationErrorResponse(err))
        )
    })
  }
}

export default new ContactInfoStore()
