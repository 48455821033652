import React, { memo } from "react"
import { Category } from "../"
import { ICoreProductTopicWithTilesAndRef } from "../../interfaces"
import "./styles.scss"

interface ICategoriesNameContainerProps {
  categories: ICoreProductTopicWithTilesAndRef[]
  onCategoryClicked: (category: string) => void
  selectedCategoryId: string | null
}

const CategoryNameContainer = (props: ICategoriesNameContainerProps) => {
  const { categories, onCategoryClicked, selectedCategoryId } = props

  return (
    <aside id="category-names-container" className="category-names-container">
      <h3 className="category-names-container__names-header">Categories</h3>
      {categories.map(category => (
        <Category
          id={category.coreProductTopic.id}
          key={`category-name-${category.coreProductTopic.id}`}
          topicName={category.coreProductTopic.topicName}
          linkEnabled={
            selectedCategoryId == null || selectedCategoryId !== category.coreProductTopic.id
          }
          onCategoryClicked={onCategoryClicked}
        />
      ))}
    </aside>
  )
}

export default memo(CategoryNameContainer)
