import { AbstractValidator } from "../../../global/abstract/validation"
import { IProductInfo } from "../../../global/interfaces/productInfo/interfaces"
import { ProductInfoErrorCode } from "./enums"

class ProductInfoValidator extends AbstractValidator<IProductInfo> {
  constructor() {
    super()
    this.ruleFor(x => x.friendlyName)
      .withAttributes(x => x.trim().length === x.length)
      .withFailureMessage("'Name of product' must not contain preceding or trailing space")
      .withFailureCode(ProductInfoErrorCode.FriendlyName)
  }
}

export default ProductInfoValidator
