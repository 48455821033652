import { Select } from "nhsuk-react-components"
import React, { forwardRef } from "react"
import { IOrganisation } from "../../../interfaces/organisation/interfaces"
import CategorisedOrganisationView from "./categorisedOrganisationView"

interface IOrganisationSelectorProps {
  id: string
  isCategorised?: boolean
  selectedParentOrganisation: IOrganisation | null
  selectError: string
  disabled: boolean
  onSelectParentOrganisation: (organisationCode: string, defaultSelection: boolean) => Promise<void>
  organisationCategories: IOrganisation[]
}

const OrganisationSelector = forwardRef(
  (props: IOrganisationSelectorProps, selectRef: React.ForwardedRef<HTMLSelectElement>) => {
    const {
      id,
      isCategorised,
      selectedParentOrganisation,
      selectError,
      disabled,
      onSelectParentOrganisation,
      organisationCategories,
    } = props

    if (isCategorised) {
      return (
        <CategorisedOrganisationView
          selectedOrganisation={selectedParentOrganisation}
          disabled={disabled}
          ref={selectRef}
          organisationCategories={organisationCategories}
          id={id}
          selectError={selectError}
          onOrganisationChange={onSelectParentOrganisation}
        />
      )
    }

    return (
      <Select
        ref={selectRef}
        name={id}
        error={selectError}
        disabled={disabled}
        className="organisationComponent__select"
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          onSelectParentOrganisation((e.target as HTMLSelectElement).value, false)
        }
      >
        <Select.Option value="" disabled selected>
          Select organisation
        </Select.Option>
        {organisationCategories.length > 0 &&
          organisationCategories.map(item => (
            <Select.Option value={item.code} key={item.code} id={item.name}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    )
  }
)

export default OrganisationSelector
