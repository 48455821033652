import React from "react"
import "./styles.scss"
import LoginButton from "../components/buttons/loginButton/loginButton"

const RequestsSent = () => {
  return (
    <>
      <span className="profile-update-result-view__body-label">
        <b>You will receive an email when your request for access has been processed</b>
      </span>
      <div className="profile-update-result-view__options-bottom-container">
        <LoginButton />
      </div>
    </>
  )
}

export default RequestsSent
