import { getCookie } from "../cookies"
import React from "react"
import { Routes } from "../../enums"

export const createCSRFTokenField = () => {
  const input = document.createElement("input")

  input.setAttribute("type", "hidden")
  input.setAttribute("name", "__CSRF-TOKEN")
  input.setAttribute("id", "__CSRF-TOKEN")
  input.setAttribute("value", getCookie("CSRF-TOKEN"))
  return input
}

export const generateId = () =>
  [null, null].reduce(a => a + Math.random().toString(36).substring(2, 15), "")

export const scrollRefIntoView = <T extends HTMLElement>(ref: React.RefObject<T>): void => {
  if (!ref || !ref.current) {
    return
  }

  ref.current.scrollIntoView({
    block: "end",
    behavior: "smooth",
  })
}

export const logout = () => {
  const logoutForm = document.createElement("form")
  logoutForm.setAttribute("method", "post")
  logoutForm.setAttribute("action", Routes.Logout)
  logoutForm.appendChild(createCSRFTokenField())
  document.body.appendChild(logoutForm)
  logoutForm.submit()
}
