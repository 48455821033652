import React, { useEffect } from "react"
import { ReactComponent as HomeIcon } from "../../../admin/assets/home.svg"
import { Routes } from "../../enums"
import "./styles.scss"

interface IAnalyticalHeaderProps {
  sticky: boolean
}

const AnalyticalHeader = (props: IAnalyticalHeaderProps) => {
  const { sticky = false } = props

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const analyticsHeaderNavigationBar = document.getElementById("analytics-header")
      if (!analyticsHeaderNavigationBar) {
        return
      }

      const eventScrollingElement = (event.target as Document).scrollingElement

      if (!eventScrollingElement) {
        return
      }

      if (
        eventScrollingElement.scrollTop >= analyticsHeaderNavigationBar.offsetTop &&
        eventScrollingElement.scrollTop > 0
      ) {
        analyticsHeaderNavigationBar.classList.add("stickyanalyticalHeader")
      } else {
        analyticsHeaderNavigationBar.classList.remove("stickyanalyticalHeader")
      }
    }

    if (sticky) {
      document.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (sticky) {
        document.removeEventListener("scroll", handleScroll)
      }
    }
  })

  return (
    <div id="analytics-header" className="analytics-header">
      <a
        data-testid="analytics-header__anchor"
        href={Routes.AnalyticsHubLandingPage}
        className="analytics-header__link nhsuk-header__transactional-service-name--link"
      >
        <HomeIcon />
        <span className="analytics-header__link-title">Analytics Hub</span>
      </a>
    </div>
  )
}

export default AnalyticalHeader
