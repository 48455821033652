import React from "react"
import { observer } from "mobx-react"
import { useStores } from "../../../../global/hooks"
import TabDropdown from "../tabDropdown/tabDropdown"
import TabSelector from "../tabSelector/tabSelector"

import "../styles.scss"

const FloatingBarFilter = observer(() => {
  const {
    tableauReportViewerStore: {
      currentReport,
      filtersApplied,
      tableauVisualization,
      visualizationLoading,
    },
  } = useStores()

  if (visualizationLoading || !tableauVisualization?.workbook) {
    return <></>
  }

  return (
    <div id="floating-bar-filter">
      {
        <>
          <TabSelector />
          <TabDropdown />
        </>
      }

      {tableauVisualization.workbook &&
      currentReport?.displayTabs === "ShowCurrentTab" &&
      filtersApplied.length > 0
        ? " | "
        : ""}
      {filtersApplied
        .slice()
        .sort((a, b) => a.Rank - b.Rank)
        .map(f => (
          <span key={f.FieldName}>
            <span>
              <b>{f.FieldName}:</b>
            </span>
            <span>{f.Value}</span>
          </span>
        ))}
    </div>
  )
})

export default FloatingBarFilter
