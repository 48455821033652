import React from "react"

interface IBlueInfoIconProps {
  iconClass?: string
}

const BlueInfoIcon = (props: IBlueInfoIconProps) => {
  const { iconClass } = props

  return (
    <svg
      className={`icons__blue-info-icon${iconClass ? ` ${iconClass}` : ""}`}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <circle cx="10.333" cy="10" r="9" stroke="#005EB8" strokeWidth="2" />
      <rect width="2.353" height="7.059" x="9.157" y="8.235" fill="#005EB8" rx="1.176" />
      <circle cx="10.333" cy="5.882" r="1.176" fill="#005EB8" />
    </svg>
  )
}

export default BlueInfoIcon
