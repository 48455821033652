import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../enums/api"
import { IApplication } from "../interfaces/application/interfaces"
import { get } from "../utils/api"

export interface IUserStore {
  getApplicationsForUser: () => Promise<IApplication[]>
}

const controllerPrefix = "user"

class UserStore implements IUserStore {
  constructor() {
    makeAutoObservable(this)
  }

  getApplicationsForUser = (): Promise<IApplication[]> => {
    return new Promise<IApplication[]>((resolve, reject) => {
      get<IApplication[]>(`${controllerPrefix}/applications`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }
}

export default new UserStore()
