import React from "react"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import LoaderProvider from "./app/global/components/loaderProvider"
import {
  ProgressiveWebApplicationProvider,
  RegistrationStoreProvider,
  NotificationHubContextProvider,
  NotificationContextProvider,
} from "./app/global/context"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const container = document.getElementById("root")
const root = createRoot(container as HTMLElement)
root.render(
  <BrowserRouter>
    <LoaderProvider>
      <RegistrationStoreProvider>
        <ProgressiveWebApplicationProvider>
          <NotificationHubContextProvider>
            <NotificationContextProvider>
              <App />
            </NotificationContextProvider>
          </NotificationHubContextProvider>
        </ProgressiveWebApplicationProvider>
      </RegistrationStoreProvider>
    </LoaderProvider>
  </BrowserRouter>
)

serviceWorkerRegistration.register()
