import { BodyText } from "nhsuk-react-components"
import React, { memo } from "react"
import Search from "../../../global/components/searchBox"
import "../../styles.scss"

const NameSearch = props => {
  const { search, searchString, onClear, setSearchString } = props

  return (
    <>
      <BodyText>
        <span>Type three characters or more.</span>
        <br />
        <span>Search a user's name or email address.</span>
      </BodyText>
      <Search
        onSearch={() => search(searchString)}
        onClear={onClear}
        handleChange={e => setSearchString(e.target.value)}
        value={searchString}
      />
    </>
  )
}

export default memo(NameSearch)
