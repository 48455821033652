import React from "react"
import ProductCard from "../../productCard/ProductCard"
import PropTypes from "prop-types"
import "./ProductsList.scss"

const ProductsList = props => {
  const { listOfProducts, toggleApplicationFavourite } = props
  return (
    <div>
      <div className="az-page__products-list__card__container" style={{ minHeight: "62.5rem" }}>
        {listOfProducts &&
          listOfProducts.map(productData => {
            return (
              <ProductCard
                key={productData.id}
                data={productData}
                toggleApplicationFavourite={toggleApplicationFavourite}
              />
            )
          })}
      </div>
    </div>
  )
}

ProductsList.propTypes = {
  listOfProducts: PropTypes.array.isRequired,
  toggleApplicationFavourite: PropTypes.func.isRequired,
}

export default ProductsList
