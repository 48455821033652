import React, { useState } from "react"
import TextButton from "../../../global/components/textButton"
import "./styles.scss"

interface ICollapsableItemsProps {
  children: JSX.Element[]
  limit: number
}

const CollapsableItems = (props: ICollapsableItemsProps) => {
  const { children, limit } = props
  const [shouldCollapseItems, setCollapsedItems] = useState(children.length > limit)
  const [message, setMessage] = useState(`Show all ${children.length} categories`)

  const toggleItems = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    const displayMessage = shouldCollapseItems
      ? "Show fewer categories"
      : `Show all ${children.length} categories`
    setMessage(displayMessage)
    setCollapsedItems(!shouldCollapseItems)
  }

  const visibleItems = children.length > limit ? children.slice(0, limit) : children

  const component =
    children.length <= limit ? (
      children
    ) : (
      <>
        {" "}
        {visibleItems}
        {!shouldCollapseItems ? children.slice(limit) : <></>}
      </>
    )

  const classes = shouldCollapseItems
    ? "collapsableItems"
    : "collapsableItems collapsableItems--open"

  return (
    <>
      {component}
      <TextButton className={classes} onClick={e => toggleItems(e)}>
        {message}
      </TextButton>
    </>
  )
}

export default CollapsableItems
