import React from "react"

const DeselectedNotificationIcon = () => {
  return (
    <svg
      data-testid="deselected-notification-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" stroke="#AEB7BD" strokeWidth="2" />
    </svg>
  )
}

export default DeselectedNotificationIcon
