import _ from "lodash"
import { observer } from "mobx-react"
import { BodyText, Col, ErrorMessage, ErrorSummary, Form, Label } from "nhsuk-react-components"
import React, { useEffect, useMemo, useState } from "react"
import { LoaderContext } from "../../../global/components/loaderProvider"
import TextButton from "../../../global/components/textButton"
import { TableauSecurityModel } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { IRegion } from "../../../global/interfaces/region/interfaces"
import { ITableauAccessGroup } from "../../../global/interfaces/tableau/interfaces"
import { ProductEditView } from "../../enums/enums"
import ProductViewRedirect from "../productViewRedirect"
import {
  Continuation,
  ReadOnlyAccessView,
  RegionalAccessView,
  SecurityModel,
  StandardAccessView,
  CustomAccessView,
} from "./components"
import "./styles.scss"
import { ITableauAccessViewErrorShape } from "./tableauAccessViewStore"
import { Guid } from "../../../global/types"

const TableauAccessView = observer(() => {
  const { tableauAccessViewStore, productStore } = useStores()
  const [isEditMode, setIsEditMode] = useState<boolean>(!productStore.published)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [loading, setLoading] = useState(true)

  const accessGroup = useMemo(() => {
    if (
      tableauAccessViewStore.tableauAccess.securityModel !== TableauSecurityModel.Regional &&
      tableauAccessViewStore.tableauAccess.tableauAccessGroups.length > 0
    ) {
      return tableauAccessViewStore.tableauAccess.tableauAccessGroups[0]
    }

    return null
  }, [
    tableauAccessViewStore.tableauAccess.securityModel,
    tableauAccessViewStore.tableauAccess.tableauAccessGroups,
  ])

  const accessByCode = useMemo(() => {
    const accessByCodeLocal: Record<string, ITableauAccessGroup> = {}

    for (const tag of tableauAccessViewStore.tableauAccess.tableauAccessGroups) {
      if (tag.regionCode) {
        accessByCodeLocal[tag.regionCode] = tag
      }
    }

    return accessByCodeLocal
  }, [tableauAccessViewStore.tableauAccess.tableauAccessGroups])

  useEffect(() => {
    wrapWithLoader(async () => {
      if (!productStore.product.type || !productStore.product.id) {
        setLoading(false)
        return
      }

      await Promise.all([
        tableauAccessViewStore.getTableauGroups(productStore.product.type),
        tableauAccessViewStore.getRegions(),
        tableauAccessViewStore.getTableauAccessGroups(productStore.product.id),
      ])
      setLoading(false)
    })
    return () => tableauAccessViewStore.resetState()
  }, [])

  useEffect(() => setIsEditMode(!productStore.published), [productStore.published])

  const setAccessGroup = (val: string, id: string | null) => {
    const newVal: ITableauAccessGroup = {
      id: id,
      tableauGroupId: val,
      regionCode: "",
      tableauOrganisationGroupId: Guid.EmptyString,
    }

    tableauAccessViewStore.tableauAccess.tableauAccessGroups = [newVal]
  }

  const setRegionalAccessGroup = (region: string, val: string, id: string | null) => {
    const newValue: ITableauAccessGroup = {
      id: id,
      tableauGroupId: val,
      regionCode: region,
      tableauOrganisationGroupId: Guid.EmptyString,
    }
    const tableauAccessGroupsCopy = [...tableauAccessViewStore.tableauAccess.tableauAccessGroups]

    const indexOfRegion = tableauAccessGroupsCopy.findIndex(x => x.regionCode === region)

    if (indexOfRegion === -1) {
      tableauAccessGroupsCopy.push(newValue)
    } else {
      tableauAccessGroupsCopy[indexOfRegion] = newValue
    }

    tableauAccessViewStore.tableauAccess.tableauAccessGroups = tableauAccessGroupsCopy
  }

  const validate = () => {
    const newErrors: ITableauAccessViewErrorShape = {}

    if (tableauAccessViewStore.tableauAccess.securityModel === TableauSecurityModel.Regional) {
      tableauAccessViewStore.regions.forEach((region: IRegion) => {
        const accessGroupForRegion: ITableauAccessGroup | undefined =
          tableauAccessViewStore.tableauAccess?.tableauAccessGroups.find(
            (x: ITableauAccessGroup) => x.regionCode && x.regionCode === region.code
          )
        if (!accessGroupForRegion || !accessGroupForRegion.tableauGroupId) {
          newErrors.regional = {}
          newErrors.regional[region.code] = `Select an access group for ${region.name}`
        }
      })
    } else if (tableauAccessViewStore.tableauAccess.tableauAccessGroups.length === 0) {
      newErrors.standard = "Select an access group for the application"
    }

    const validationResult = _.isEmpty(newErrors)
    tableauAccessViewStore.errors = !validationResult ? newErrors : {}
    return validationResult
  }

  const completeAndSave = () => {
    if (!validate()) {
      tableauAccessViewStore.tableauAccess.complete = false
      return
    }

    tableauAccessViewStore.tableauAccess.complete = true
    save()
  }

  const saveAndReturn = () => {
    tableauAccessViewStore.tableauAccess.complete = false
    save()
  }

  const save = async () => {
    if (!productStore.product.id) {
      return
    }

    await tableauAccessViewStore.saveTableauAccessGroups(productStore.product.id)

    productStore.setTaskModified(
      ProductEditView.TableauAccessGroups,
      tableauAccessViewStore.tableauAccess.complete
    )
    setRedirectingToTaskList(true)
  }

  const AccessView = useMemo(() => {
    switch (tableauAccessViewStore.tableauAccess.securityModel) {
      case TableauSecurityModel.Regional:
        return (
          <RegionalAccessView
            setRegionalAccessGroup={setRegionalAccessGroup}
            accessByCode={accessByCode}
          />
        )
      case TableauSecurityModel.Standard:
        return <StandardAccessView setAccessGroup={setAccessGroup} accessGroup={accessGroup} />
      case TableauSecurityModel.Custom:
        return <CustomAccessView save={save} />
      default:
        return null
    }
  }, [
    tableauAccessViewStore.tableauAccess.securityModel,
    setRegionalAccessGroup,
    accessByCode,
    setAccessGroup,
    accessGroup,
  ])

  if (loading) {
    return null
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <Col className="tableau-access-view" width="full">
      {isEditMode && !_.isEmpty(tableauAccessViewStore.errors) && (
        <ErrorSummary>
          <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
          {tableauAccessViewStore.errors.securityModel && (
            <ErrorMessage>Select a security model to apply to this application</ErrorMessage>
          )}
          {tableauAccessViewStore.errors.standard && (
            <ErrorMessage>Select an access group for the application</ErrorMessage>
          )}
          {tableauAccessViewStore.errors.regional && (
            <ErrorMessage>Select an access group for each region</ErrorMessage>
          )}
        </ErrorSummary>
      )}
      <div className="tableau-access-view__page-heading">
        <Label isPageHeading className="tableau-access-view__page-heading__label">
          Tableau access groups
        </Label>
        {!isEditMode && (
          <TextButton
            className="tableau-access-view__page-heading__change-settings-button"
            onClick={() => setIsEditMode(true)}
          >
            Change settings
          </TextButton>
        )}
      </div>

      {!isEditMode ? (
        <ReadOnlyAccessView accessGroup={accessGroup} accessByCode={accessByCode} />
      ) : (
        <>
          <BodyText>
            Ensure you apply the correct group-level permissions for users of your Tableau
            dashboard.
          </BodyText>
          <SecurityModel />
          <Form className="tableau-access-view__access-form">{AccessView}</Form>
        </>
      )}
      {tableauAccessViewStore.tableauAccess.securityModel !== TableauSecurityModel.Custom && (
        <Continuation
          isEditMode={isEditMode}
          completeAndSave={completeAndSave}
          saveAndReturn={saveAndReturn}
        />
      )}
    </Col>
  )
})

export default TableauAccessView
