export enum FeatureToggle {
  CoreApiEnabled,
  TableauWebWrapperEnabled,
  ProductInviteEnabled,
  FavourAtoZ,
  ProfileUpdatingEnabled,
  CorePlatformSearchEnabled,
  BulkInvitationsEnabled,
  CustomTableauGroups,
  ManageAdmins,
  NotificationCentre,
  MobileLandingPage,
}
