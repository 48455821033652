import React, { useRef } from "react"
import Modal from "../../../global/components/modal"
import WarningBox from "../../../global/components/warningBox"
import TextButton from "../../../global/components/textButton"
import { CloseIcon } from "../../../global/components/icons"
import { Button } from "nhsuk-react-components"
import { copyTextToClipboard } from "../../../global/utils/clipboard"

import { BulkInvitationRecipientState } from "../../enums"
import { IBulkInvitationJob } from "../../interfaces"
import "./styles.scss"

interface IJobModalContentProps {
  job: IBulkInvitationJob
  close: () => void
}

const JobModalContent = (props: IJobModalContentProps) => {
  const {
    job: { recipients },
    close,
  } = props

  const invitations = useRef<HTMLDivElement>(null)

  const CopyEmailList = () => {
    if (invitations.current) {
      const str = invitations.current.innerText
      copyTextToClipboard(str)
    }
  }

  return (
    <Modal className="job-modal-content" close={close}>
      <section className="job-modal-content__container">
        <p>
          <b>Recipients list</b>
        </p>
        <WarningBox>
          Recipients are shown in order of when their invitation was processed (most recent first).
        </WarningBox>
        <div ref={invitations} className="job-modal-content__invitations">
          <div className="job-modal-content__not-sent">
            <p className="job-modal-content__not-sent-title">Invitations not sent</p>
            {recipients
              .filter(x => x.status === BulkInvitationRecipientState.Failed)
              .map(rec => {
                return (
                  <p className="job-modal-content__email" key={rec.emailAddress}>
                    {rec.emailAddress}
                  </p>
                )
              })}
          </div>
          <div className="job-modal-content__sent">
            <p className="job-modal-content__sent-title">Invitations sent</p>
            {recipients
              .filter(x => x.status === BulkInvitationRecipientState.Completed)
              .map(rec => {
                return (
                  <p className="job-modal-content__email" key={rec.emailAddress}>
                    {rec.emailAddress}
                  </p>
                )
              })}
          </div>
        </div>
      </section>
      <footer className="job-modal-content__footer">
        <TextButton className="jobs-modal-content__close-button" onClick={close}>
          close <CloseIcon />
        </TextButton>
        <Button className="job-modal-content__copy-button" onClick={CopyEmailList}>
          Copy email list
        </Button>
      </footer>
    </Modal>
  )
}

export default JobModalContent
