import { observer } from "mobx-react"
import { BodyText, Label } from "nhsuk-react-components"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import "./styles.scss"

const BulkSentView = observer(() => {
  const { productOwnerAdminStore } = useStores()

  const { selectedProduct } = productOwnerAdminStore

  return (
    <>
      <Label className="bulk-sent-view__heading" isPageHeading>
        Your invite list has been submitted
      </Label>

      <BodyText className="bulk-sent-view__copy">
        Your invitations are now being processed. You will receive an email confirming whether all
        invitations were sent, or whether one or more could not be sent.
      </BodyText>

      <RouterLink to={selectedProduct ? Routes.AppAdmin : Routes.Admin}>
        Return to{" "}
        <strong>
          {selectedProduct ? "'Manage your product and its users'" : "'Manage users'"}
        </strong>
      </RouterLink>
    </>
  )
})

export default BulkSentView
