import React from "react"
import ReactDOM from "react-dom"
import { Button } from "nhsuk-react-components"
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg"

import "./dialog.scss"

interface DialogProps {
  title: string
  children: React.ReactNode
  onClose: () => void
}

// Create a Dialog component that uses React Portals
const Dialog: React.FC<DialogProps> = ({ title, children, onClose }) => {
  const dialogRoot = document.getElementById("portal-root")

  if (!dialogRoot) return null

  return ReactDOM.createPortal(
    <div className="dialog-overlay">
      <div className="dialog">
        <div className="dialog-header">
          <span className="dialog-title">
            <strong>{title}</strong>
          </span>
          <CloseIcon onClick={onClose} />
        </div>
        <div className="dialog-content">
          <div className="scrollable-content">{children}</div>
        </div>
        <hr className="dialog-hr__narrow" />
        <div className="dialog-footer">
          <Button className="close-button" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </div>,
    dialogRoot
  )
}

export default Dialog
