import { BodyText, Label } from "nhsuk-react-components"
import React from "react"
import { Helmet } from "react-helmet"
import { Routes } from "../../../global/enums"

const AccountActiveView = () => (
  <>
    <Helmet>
      <title>Registration Complete | NHS England applications</title>
    </Helmet>
    <Label isPageHeading>Your account is already active</Label>
    <BodyText>Please sign in using the link below</BodyText>
    <BodyText>
      <a id="loginLink" href={Routes.AToZLandingPage}>
        Sign in to NHS England applications
      </a>
    </BodyText>
  </>
)

export default AccountActiveView
