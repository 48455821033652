import React from "react"
import { Label, BodyText } from "nhsuk-react-components"

const noResult = () => {
  return (
    <div style={{ paddingLeft: "5.25rem" }}>
      <Label bold style={{ marginTop: "2.5rem", fontSize: "1.375rem" }}>
        No results found
      </Label>
      <BodyText>To improve your search:</BodyText>
      <ul style={{ marginLeft: "1.5rem" }}>
        <li>
          <BodyText>Check that you have spelt any names correctly</BodyText>
        </li>
        <li>
          <BodyText>Check that you have written their email correctly and fully</BodyText>
        </li>
        <li>
          <BodyText>
            If a user has more than two names, search only for their first and last name
          </BodyText>
        </li>
        <li>
          <BodyText>Don't search for both name and email address at the same time</BodyText>
        </li>
      </ul>
    </div>
  )
}

export default noResult
