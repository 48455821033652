import { BodyText, Button, Container, Label, Radios } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Switch from "react-switch"
import { Routes } from "../../../../../global/enums"
import { EmailPreferences } from "../../../../../global/enums/notification"
import { useStores } from "../../../../../global/hooks"
import { IRadiosOnChangeEvent } from "../../../../../global/interfaces/nhs-uk"
import { IUserNotificationPreferences } from "../../../../../global/interfaces/notification"
import DisabledContainer from "../disabledContainer"
import { NotificationFrequencyNameMappings, SwitchStyles } from "./constants"
import "./styles.scss"

const CustomiseNotifications = () => {
  const [notificationPreferences, setNotificationPreferences] =
    useState<IUserNotificationPreferences>({
      applications: [],
      appNotifications: true,
      emailNotifications: true,
      emailPreferences: EmailPreferences.Weekly,
    })

  const navigate = useNavigate(),
    mounted = useRef<boolean>(false)

  const { notificationStore } = useStores()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    notificationStore.getNotificationPreferences().then(prefs => {
      if (mounted.current) {
        setNotificationPreferences(prefs)
      }
    })
  }, [notificationStore.getNotificationPreferences])

  const onFrequencyChange = (e: React.FormEvent<HTMLDivElement> & IRadiosOnChangeEvent) => {
    const parsedRadioValue = parseInt(e.target.value)

    if (isNaN(parsedRadioValue) || notificationPreferences?.emailPreferences === parsedRadioValue) {
      return
    }

    setNotificationPreferences(np => ({ ...np, emailPreferences: parsedRadioValue }))
  }

  const onApplicationToggled = (applicationId: string, checked: boolean) => {
    const applicationPreferencesCopy = [...notificationPreferences.applications]

    const appIdx = applicationPreferencesCopy.findIndex(app => app.applicationId === applicationId)
    if (appIdx !== -1) {
      applicationPreferencesCopy[appIdx].enabled = checked
      setNotificationPreferences(np => ({ ...np, applications: applicationPreferencesCopy }))
    }
  }

  const onPropertyChanged = (func: (prefs: IUserNotificationPreferences) => void) => {
    const prefsCopy = { ...notificationPreferences }
    func(prefsCopy)
    setNotificationPreferences(prefsCopy)
  }

  const onSubmit = () => {
    notificationStore
      .postNotificationPreferences(notificationPreferences)
      .then(() => navigate(Routes.AToZLandingPage))
  }

  const noApplicationsSelected =
    !notificationPreferences || !notificationPreferences.applications.some(app => app.enabled)

  const disableSummaryEmails =
    !noApplicationsSelected &&
    (!notificationPreferences || !notificationPreferences.emailNotifications)

  return (
    <Container className="customise-notifications">
      <Label className="customise-notifications__label">Customise notifications</Label>
      <BodyText className="customise-notifications__description">
        You will always receive urgent system messages, though these will be low in number.
      </BodyText>

      <hr className="customise-notifications__separator" />

      <Label className="customise-notifications__label customise-notifications__applications">
        Applications
      </Label>
      <BodyText className="customise-notifications__description">
        Which applications would you like to receive notifications from?
      </BodyText>
      {notificationPreferences?.applications.map(app => {
        return (
          <div className="customise-notifications__switch-container" key={app.applicationId}>
            <label className="customise-notifications__switch-container__label">
              <Switch
                checked={app.enabled}
                onChange={c => onApplicationToggled(app.applicationId, c)}
                {...SwitchStyles}
              />
              <span className="customise-notifications__switch-container__label__text">
                {app.applicationName}
              </span>
            </label>
          </div>
        )
      })}
      <DisabledContainer disabled={noApplicationsSelected}>
        <Label className="customise-notifications__label customise-notifications__communication-preferences">
          Communication preferences
        </Label>
        <BodyText className="customise-notifications__description">
          Regardless of the selection below, you will still be made aware of new notifications via
          the bell icon.
        </BodyText>
        <div className="customise-notifications__switch-container">
          <label className="customise-notifications__switch-container__label">
            <Switch
              checked={notificationPreferences?.appNotifications}
              disabled={noApplicationsSelected}
              onChange={checked => onPropertyChanged(np => (np.appNotifications = checked))}
              {...SwitchStyles}
            />
            <span className="customise-notifications__switch-container__label__text">
              Mobile app notifications
            </span>
          </label>
        </div>
        <div className="customise-notifications__switch-container">
          <label className="customise-notifications__switch-container__label">
            <Switch
              checked={notificationPreferences?.emailNotifications}
              disabled={noApplicationsSelected}
              onChange={checked => onPropertyChanged(np => (np.emailNotifications = checked))}
              {...SwitchStyles}
            />
            <span className="customise-notifications__switch-container__label__text">
              Summary emails
            </span>
          </label>
        </div>
        <DisabledContainer disabled={disableSummaryEmails}>
          <Label className="customise-notifications__label customise-notifications__email-frequency">
            Summary email frequency
          </Label>
          <BodyText className="customise-notifications__description">
            How frequently would you like to receive an email that summarises your notifications?
          </BodyText>
          <Radios className="customise-notifications__radio" onChange={onFrequencyChange}>
            {Object.values(EmailPreferences).map(notificationFrequency => {
              if (typeof notificationFrequency === "string") {
                return null
              }

              return (
                <Radios.Radio
                  key={EmailPreferences[notificationFrequency]}
                  value={notificationFrequency}
                  checked={notificationPreferences?.emailPreferences === notificationFrequency}
                  disabled={disableSummaryEmails || noApplicationsSelected}
                >
                  {NotificationFrequencyNameMappings[notificationFrequency]}
                </Radios.Radio>
              )
            })}
          </Radios>
        </DisabledContainer>
      </DisabledContainer>
      <div className="customise-notifications__footer">
        <div className="customise-notifications__footer__container">
          <Button onClick={onSubmit}>Update notification preferences</Button>
        </div>
      </div>
    </Container>
  )
}

export default CustomiseNotifications
