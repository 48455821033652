import { observer } from "mobx-react"
import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useFeatureToggles, useStores } from "../../../global/hooks"
import PageNotFound from "../../../pageNotFound"

interface IRouteWithToggleProps {
  catchAll?: boolean
}

const RouteWithToggle = observer((props: IRouteWithToggleProps) => {
  const { catchAll = false } = props
  const { featureToggledRouteStore } = useStores()

  const { pathname } = useLocation()

  const featureToggledRoute = featureToggledRouteStore.getRoute(pathname, catchAll)
  if (!featureToggledRoute) {
    return null
  }

  const {
    enabledToggles: [featureToggledRouteToggle],
    loading,
  } = useFeatureToggles(featureToggledRoute.flag)

  if (loading) {
    return null
  }

  if (featureToggledRouteToggle) {
    return <Outlet />
  }

  if (featureToggledRoute.alternateRoute) {
    window.location.href = featureToggledRoute.alternateRoute
  }

  return <PageNotFound />
})

export default RouteWithToggle
