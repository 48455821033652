import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { IconPlus } from "../../../global/components/icons"
import { isOther } from "../../../global/constants/organisation/constants"
import { generateId } from "../../../global/utils/dom"
import { useStores } from "../../../global/hooks"
import Organisation from "./organisation"

export default observer(
  ({
    section,
    listName,
    organisationReference,
    deleteAvailable,
    errors,
    hasBeenValidated,
    setReferenceErrors,
  }) => {
    const { accessPermissionsStore: store } = useStores()
    const { accessPermissions, isNHSOrg, getSubOrganisationForTypeWithSearchTerm } = store
    const activeList = accessPermissions[listName]
    const blacklist = "blackList"

    useEffect(() => {
      let current = true
      if (current) {
        organisationReference.map(x => {
          x.identifier = x.identifier !== undefined ? x.identifier : generateId()
          return undefined
        })
      }
      return () => (current = false)
    }, [organisationReference])

    const convertReferenceOrgToOrgFilter = org => ({
      name: org.name,
      code: org.code,
      organisationType: org.organisationType,
      regionCode: undefined,
      isOrgType: org.isOrgType,
      isNHSOrg: isNHSOrg(org.innerCode || org.code, org.organisationType),
      ...org,
    })

    const addNewOrganisation = () => {
      const newOrgId = generateId()
      activeList.push(convertReferenceOrgToOrgFilter({ identifier: newOrgId }))
      setReferenceErrors(p => [...p, { section: section, identifier: `${section}-${newOrgId}` }])
    }

    const selectOrganisation = (idx, section, orgCode, previousSelectedCode, identifier) => {
      const previousOrg = organisationReference.find(x => x.code == previousSelectedCode)
      const org = organisationReference.find(x => x.code == orgCode)
      const newOrgId = `${section}-${org?.identifier}`
      const previousOrgId = `${section}-${previousOrg?.identifier}`

      activeList[idx] = convertReferenceOrgToOrgFilter(org)

      if (!isOther(org) || (isOther(org) && listName === blacklist)) {
        const otherErrors = _.filter(
          errors,
          x => ![newOrgId, previousOrgId, identifier].includes(x.identifier)
        )
        setReferenceErrors(otherErrors)
      } else if (isOther(org) && !_.some(errors, error => error.identifier === newOrgId)) {
        const otherErrors = _.filter(
          errors,
          x => ![previousOrgId, identifier].includes(x.identifier)
        )
        const newErrors = [...otherErrors, { section: section, identifier: newOrgId }]
        setReferenceErrors(newErrors)
      }

      return org && org.code
    }

    const selectChildOrganisation = (idx, subItem, identifier, section) => {
      const subItemCode = subItem && subItem.value
      const value = document.getElementById(identifier).value
      const org = organisationReference.find(x => x.code == value)
      const newOrgId = `${section}-${org?.identifier}`

      if (isOther(org) && !subItemCode && !_.some(errors, error => error.identifier === newOrgId)) {
        const newErrors = [...errors, { section: section, identifier: newOrgId }]
        setReferenceErrors(newErrors)
      } else if (isOther(org) && subItemCode) {
        const newErrors = errors.filter(error => error.identifier !== newOrgId)
        setReferenceErrors(newErrors)
      }

      if (!subItemCode) {
        delete activeList[idx].innerCode
        delete activeList[idx].innerName

        // We need to restore the original value of the isOrgCode
        const org = organisationReference.find(x => x.code === activeList[idx].code)
        Object.assign(activeList[idx], org)
        return false
      }

      activeList[idx].innerName = subItem.label
      activeList[idx].innerCode = subItemCode
      activeList[idx].isOrgType = false
      return subItemCode
    }

    const deleteOrganisation = (idx, identifier) => {
      activeList.remove(activeList[idx])
      const newErrors = errors.filter(error => error.identifier !== identifier)
      setReferenceErrors(newErrors)
    }

    return (
      <div
        id={section}
        className={`${section}-organisation-select-container organisation-select-container`}
      >
        <OrgSelectorCollection
          handleAsyncOrgSearch={getSubOrganisationForTypeWithSearchTerm}
          activeList={activeList}
          selectOrganisation={selectOrganisation}
          selectChildOrganisation={selectChildOrganisation}
          deleteOrganisation={deleteOrganisation}
          deleteAvailable={deleteAvailable}
          section={section}
          hasBeenValidated={hasBeenValidated}
          errors={errors}
        />

        <div id="organisation-add-button" onClick={() => addNewOrganisation(listName)}>
          <IconPlus />
          <div className="button-text">Add another organisation</div>
        </div>
      </div>
    )
  }
)

const OrgSelectorCollection = observer(
  ({
    handleAsyncOrgSearch,
    activeList,
    selectOrganisation,
    selectChildOrganisation,
    deleteOrganisation,
    deleteAvailable,
    section,
    hasBeenValidated,
    errors,
  }) => {
    return (
      <>
        {activeList.map((item, idx) => {
          const key = item.identifier ? `${section}-${item.identifier}` : generateId()
          const isFaulted = hasBeenValidated && _.some(errors, error => error.identifier === key)

          return (
            <div
              data-identifier={key}
              className="access-outer-row"
              key={item.code ? `${item.code}${idx}` : idx}
              id={`item-${idx}-in-${section}`}
              style={{ border: "0.3125rem", borderColor: "red" }}
            >
              {/* KEEP THIS LINE - NEEDED TO TRIGGER RE-RENDER */}
              {console.log(JSON.stringify(item))}
              {/* KEEP THIS LINE - NEEDED TO TRIGGER RE-RENDER */}
              <Organisation
                handleAsyncOrgSearch={handleAsyncOrgSearch}
                isFaulted={isFaulted}
                identifier={key}
                section={section}
                item={item}
                selectOrganisation={_.curry(selectOrganisation)(idx)}
                selectChildOrganisation={_.curry(selectChildOrganisation)(idx)}
              >
                {deleteAvailable && (
                  <div
                    data-index={key}
                    className="access-permissions-page__access-permissions-column-link"
                  >
                    <Link
                      className={`access-permissions-page__delete-organisation-link ${
                        isFaulted ? "access-permissions-page__delete-with-error" : ""
                      }`}
                      onClick={() => deleteOrganisation(idx, key)}
                    >
                      Delete
                    </Link>
                  </div>
                )}
              </Organisation>
            </div>
          )
        })}
      </>
    )
  }
)
