import React from "react"
import { button_primary } from "../../../../../global/constants/colours"
import { EmailPreferences } from "../../../../../global/enums/notification"

export const NotificationFrequencyNameMappings: Record<EmailPreferences, string> = {
  [EmailPreferences.Immediately]: "Immediately",
  [EmailPreferences.Daily]: "Daily summary",
  [EmailPreferences.Weekly]: "Weekly summary",
}

export const SwitchStyles = {
  onColor: button_primary,
  offColor: "#DADEE0",
  className: "customise-notifications__switch-container__switch",
  uncheckedIcon: (
    <svg
      className="customise-notifications__switch-container__switch__unchecked-icon"
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.204945 10H2.08095V2.692H2.10895L5.84695 10H8.24095V0.228H6.36495V7.536H6.33695L2.61295 0.228H0.204945V10ZM9.77646 6.444C9.77646 8.516 11.1625 10.168 13.5425 10.168C15.9365 10.168 17.3225 8.516 17.3225 6.444C17.3225 4.064 15.6845 2.622 13.5425 2.622C11.4145 2.622 9.77646 4.064 9.77646 6.444ZM11.7365 6.22C11.7365 5.142 12.3245 4.078 13.5425 4.078C14.7745 4.078 15.3625 5.114 15.3625 6.22C15.3625 7.424 14.9845 8.712 13.5425 8.712C12.1145 8.712 11.7365 7.41 11.7365 6.22Z"
        fill="#6B7783"
      />
    </svg>
  ),
  checkedIcon: (
    <svg
      className="customise-notifications__switch-container__switch__checked-icon"
      width="23"
      height="11"
      viewBox="0 0 23 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19763 10H6.15763V6.136L9.65763 0.228H7.58563L5.23363 4.33L2.92363 0.228H0.627633L4.19763 6.136V10ZM10.8224 5.688C10.9064 4.722 11.4384 3.994 12.4884 3.994C13.5384 3.994 13.9584 4.778 14.0144 5.688H10.8224ZM15.2744 8.068C14.5744 8.46 13.7904 8.796 12.8804 8.796C11.7184 8.796 10.9064 8.138 10.8224 6.976H15.8064C15.8064 4.456 15.0504 2.622 12.3764 2.622C10.1364 2.622 9.03037 4.344 9.03037 6.43C9.03037 8.796 10.4164 10.168 12.7964 10.168C13.8044 10.168 14.5884 9.972 15.2744 9.594V8.068ZM21.6817 2.888C21.0937 2.762 20.5197 2.622 19.6377 2.622C18.1677 2.622 16.8097 3.308 16.8097 4.862C16.8097 7.466 20.0717 6.5 20.0717 7.914C20.0717 8.614 19.3157 8.796 18.8397 8.796C18.1957 8.796 17.5797 8.614 16.9917 8.32L16.8797 9.86C17.5657 10.042 18.2937 10.168 19.0077 10.168C20.5337 10.168 22.0317 9.496 22.0317 7.816C22.0317 5.212 18.7697 5.94 18.7697 4.792C18.7697 4.176 19.3717 3.994 19.8757 3.994C20.5617 3.994 20.9817 4.106 21.5557 4.302L21.6817 2.888Z"
        fill="white"
      />
    </svg>
  ),
}
