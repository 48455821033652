import { Select as NHSSelect } from "nhsuk-react-components"
import React, { useRef, useState } from "react"
import TextButton from "../../global/components/textButton"
import { ApplicationScope } from "../../global/enums/application"
import { IRegion } from "../../global/interfaces/region"
import "../styles.scss"
import { SelectReference } from "../types"
import Application from "../types/classes/application"
import { ACTION, AdminCanChangeConfigOptions, ApplicationState } from "../types/enums"
import { RegionalSelect } from "./regionalSelect"

export const SingleAppConfiguration = ({
  application,
  nhsRegions,
  handleApplicationChange,
}: {
  application: Application
  nhsRegions: IRegion[]
  handleApplicationChange: (application: Application, action: ACTION) => void
}) => {
  const [currentApplication, setCurrentApplication] = useState<Application>(application)

  const regionalSelectionReference = useRef<SelectReference>(null)

  const onScopeChanged = (event: React.FormEvent<HTMLSelectElement>) => {
    const scope = parseInt(event.currentTarget.value)

    const isRegional = scope === ApplicationScope.Regional

    application.setIsRegionalApp(isRegional)

    application.setScope(scope)

    !isRegional && regionalSelectionReference.current?.clearValue()

    const newApplication: Application = {
      ...application,
      isRegional: isRegional,
    } as Application

    setCurrentApplication(newApplication)

    handleApplicationChange(newApplication, ACTION.MODIFIED)
  }

  const onCanChangeConfigChanged = (e: React.FormEvent<HTMLSelectElement>) => {
    const canChangeConfig: boolean = e.currentTarget.value == AdminCanChangeConfigOptions.Yes

    application.adminCanChangeConfig(canChangeConfig)

    const newApplication: Application = { ...application } as Application

    newApplication.adminCanChangeConfig(canChangeConfig)

    setCurrentApplication(newApplication)
  }

  const removeAdmin = (application: Application, action: ACTION) => {
    if (application.originalApplicationState !== ApplicationState.Tracked) return

    application.setApplicationState(ApplicationState.Deleted)

    application.setAppliedAction(action)

    handleApplicationChange(application, action)
  }

  return (
    <div
      className={`adminmanagement__multiAppConfiguration--appConfiguration ${
        application.applicationState === ApplicationState.Tracked
          ? "adminmanagement__multiAppConfiguration--appConfiguration__item__tracked"
          : ""
      }`}
    >
      <div className="adminmanagement__multiAppConfiguration--appConfiguration__item">
        <p className="adminmanagement__mobile-titles">Name</p>
        <span>{currentApplication.name}</span>
      </div>

      <div className="adminmanagement__multiAppConfiguration--appConfiguration__item">
        <p className="adminmanagement__mobile-titles">Can Change Config</p>
        <NHSSelect
          className="adminmanagement__multiAppConfiguration--appConfiguration__item__select"
          onChange={onCanChangeConfigChanged}
          defaultValue={
            currentApplication.adminCanChangeConfiguration
              ? AdminCanChangeConfigOptions.Yes
              : AdminCanChangeConfigOptions.No
          }
        >
          <NHSSelect.Option value={AdminCanChangeConfigOptions.Yes}>
            {AdminCanChangeConfigOptions.Yes}
          </NHSSelect.Option>

          <NHSSelect.Option value={AdminCanChangeConfigOptions.No}>
            {AdminCanChangeConfigOptions.No}
          </NHSSelect.Option>
        </NHSSelect>
      </div>

      <div className="adminmanagement__multiAppConfiguration--appConfiguration__item">
        <p className="adminmanagement__mobile-titles">Scope</p>
        <NHSSelect
          className="adminmanagement__multiAppConfiguration--appConfiguration__item__select"
          onChange={onScopeChanged}
          defaultValue={
            currentApplication.isRegional ? ApplicationScope.Regional : ApplicationScope.All
          }
        >
          <NHSSelect.Option value={ApplicationScope.All}>
            {ApplicationScope[ApplicationScope.All]}
          </NHSSelect.Option>

          <NHSSelect.Option value={ApplicationScope.Organisational}>
            {ApplicationScope[ApplicationScope.Organisational]}
          </NHSSelect.Option>

          <NHSSelect.Option value={ApplicationScope.Regional}>
            {ApplicationScope[ApplicationScope.Regional]}
          </NHSSelect.Option>
        </NHSSelect>
      </div>

      <div className="adminmanagement__multiAppConfiguration--appConfiguration__item">
        <p className="adminmanagement__mobile-titles">Regional (If applicable)</p>
        <RegionalSelect
          ref={regionalSelectionReference}
          application={currentApplication}
          isRegional={currentApplication.isRegional}
          setCurrentApplication={setCurrentApplication}
          handleApplicationChange={handleApplicationChange}
          regionOptions={nhsRegions.map(r => ({ label: r.name, value: r.name }))}
        />
      </div>

      <div
        className={`adminmanagement__multiAppConfiguration--appConfiguration__item adminmanagement__multiAppConfiguration--appConfiguration__item--removeitem ${
          application.originalApplicationState != ApplicationState.Tracked
            ? "adminmanagement__multiAppConfiguration--appConfiguration__item__hidden"
            : ""
        }`}
      >
        <p className="adminmanagement__mobile-titles">Remove Access</p>
        <TextButton
          className="admin-info-remove-link"
          onClick={() => removeAdmin(application, ACTION.DELETE_TRACKED_FROM_DB)}
          disabled={application.originalApplicationState !== ApplicationState.Tracked}
        >
          Delete
        </TextButton>
      </div>
    </div>
  )
}
