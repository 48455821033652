import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useStores } from "../../../global/hooks"
import NoFavourites from "./emptyFavouritesComponent/noFavourites"
import ProductsList from "./favouritesproductsList/ProductsList"

const Favourites = ({ toggleApplicationFavourite }) => {
  const {
    atozStore: { allAtoZProducts },
  } = useStores()

  const [allFavouritedProducts, setAllFavouritedProducts] = useState([])
  const isApproved = x => x.userApplicationMappingState === 0

  useEffect(() => {
    const favouritedApps = allAtoZProducts.filter(x => x.isFavourited && isApproved(x))
    setAllFavouritedProducts(favouritedApps)
  }, [allAtoZProducts])

  return (
    <>
      {allFavouritedProducts.length < 1 ? (
        <NoFavourites />
      ) : (
        <ProductsList
          listOfProducts={allFavouritedProducts}
          toggleApplicationFavourite={toggleApplicationFavourite}
        />
      )}
    </>
  )
}

Favourites.propTypes = {
  products: PropTypes.array,
  toggleApplicationFavourite: PropTypes.func.isRequired,
}

export default Favourites
