export enum ApplicationState {
  Tracked,
  Created,
  Modified,
  Deleted,
}

export enum ACTION {
  DELETE_TRACKED_FROM_DB,
  MODIFIED,
  NONE,
}

export enum AdminCanChangeConfigOptions {
  Yes = "Yes",
  No = "No",
}

export enum SearchRadios {
  Primary,
  Secondary,
}

export enum AdminType {
  Primary,
  Secondary,
}
