import { observer } from "mobx-react"
import React, { useContext, useState } from "react"
import { LoaderContext } from "../../../../../global/components/loaderProvider"
import { IApplication } from "../../../../../global/interfaces/application/interfaces"
import { IUserProfileUpdateResult } from "../../../../../global/interfaces/userProfile/interfaces"
import { useStores } from "../../../../../global/hooks"
import BackToYourAccountLink from "../components/buttons/backToYourAccountLink/backToYourAccountLink"
import LoginButton from "../components/buttons/loginButton/loginButton"
import "./styles.scss"

interface IDetailsSuccessfullyChangedProps {
  result: IUserProfileUpdateResult
  onRequestsSent: () => void
}

const DetailsSuccessfullyChanged = observer((props: IDetailsSuccessfullyChangedProps) => {
  const {
    result: { permissionsResetPerformed, applicationRequestStatusChanges },
    onRequestsSent,
  } = props

  const { approvalQuestionStore } = useStores()

  const { wrapWithLoader } = useContext(LoaderContext)
  const { getApprovalQuestionsRequirementForApplication } = approvalQuestionStore

  const [additionalInfoRequiredApps, setAdditionalInfoRequiredApps] = useState<IApplication[]>([])

  // Removed RequestAccessPanel from this screen for Initial Release of User Profile based on Successor User Story 81112
  // Commenting this code for potential use in changes post release
  //const accessResetChanges = applicationRequestStatusChanges.filter(change => change.previousStatus === ApplicationRequestStatus.Approved && change.newStatus === ApplicationRequestStatus.NotRequested)
  //const requestableApps = accessResetChanges.filter(change => additionalInfoRequiredApps.filter(app => app.id === change.application.id).length === 0)

  // Removed RequestAccessPanel from this screen for Initial Release of User Profile based on Successor User Story 81112
  // Commenting this code for potential use in changes post release
  //useEffect(() => {
  //    wrapWithLoader(async () => {
  //        var additionalInfoAppStatusChanges: IApplicationRequestStatusChange[] = []

  //        //for (var change of accessResetChanges) {
  //        //    var required = await getApprovalQuestionsRequirementForApplication(change.application.id)
  //        //    if (required) {
  //        //        additionalInfoAppStatusChanges.push(change)
  //        //    }
  //        //}

  //        setAdditionalInfoRequiredApps(additionalInfoAppStatusChanges.map(c => c.application))
  //    })
  //}, [])

  const SubHeading = (): JSX.Element => {
    // Removed RequestAccessPanel from this screen for Initial Release of User Profile based on Successor User Story 81112
    // Commenting this code for potential use in changes post release
    //if (permissionsResetPerformed && requestableApps.length > 0) {
    //    return (
    //        <>
    //            <span className="profile-update-result-view__body-label">
    //                <b>Because your organisation and/or email domain has changed, permissions to the following products have been removed.</b>
    //            </span>
    //            <span className="profile-update-result-view__secondary-body-label">
    //                If you still require access to these applications you can select them below and request access again.
    //            </span>
    //        </>
    //    )
    //} else
    if (applicationRequestStatusChanges.length > 0) {
      return (
        <span className="profile-update-result-view__body-label">
          The products and services you have access to may have changed as a result.
        </span>
      )
    }

    return <></>
  }

  const Body = (): JSX.Element => {
    // Removed RequestAccessPanel from this screen for Initial Release of User Profile based on Successor User Story 81112
    // Commenting this code for potential use in changes post release
    //if (permissionsResetPerformed && accessResetChanges.length > 0) {
    //    return (
    //        <RequestAccessPanel
    //            requestableApps={requestableApps}
    //            additionalInfoRequiredApps={additionalInfoRequiredApps}
    //            onRequestsSent={onRequestsSent}
    //        />)
    //} else
    if (applicationRequestStatusChanges.length > 0) {
      return <LoginButton />
    }

    return <></>
  }

  const Submit = (): JSX.Element => {
    // Removed RequestAccessPanel from this screen for Initial Release of User Profile based on Successor User Story 81112
    // Commenting this code for potential use in changes post release
    //if ((permissionsResetPerformed && requestableApps.length > 0) || applicationRequestStatusChanges.length > 0) {
    if (permissionsResetPerformed || applicationRequestStatusChanges.length > 0) {
      return <></>
    }

    return (
      <div className="profile-update-result-view__options-bottom-container">
        <BackToYourAccountLink />
      </div>
    )
  }

  return (
    <>
      <SubHeading />
      <Body />
      <Submit />
    </>
  )
})

export default DetailsSuccessfullyChanged
