import React, { createRef, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import AnalyticalHeader from "../global/components/analyticalHeader"
import { withTermsCheck, withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import Modal from "../global/components/modal"
import { FeatureToggle, Routes } from "../global/enums"
import { useFeatureToggles, useStores } from "../global/hooks"
import { IApplicationTile } from "../global/interfaces/application/interfaces"
import {
  CategoriesContainer,
  CategoryNameContainer,
  ProductHeaderContainer,
  ProductModalContent,
} from "./components"
import { ICoreProductTopicWithTilesAndRef } from "./interfaces"
import "./styles.scss"

const AnalyticsLandingPage = () => {
  const {
    coreProductTopicStore: { getAllTiles },
    appGalleryStore: { requestAppAccess, toggleFavourite, applications },
  } = useStores()

  const {
    enabledToggles: [tableauWebWrapperEnabled],
  } = useFeatureToggles(FeatureToggle.TableauWebWrapperEnabled)

  const { wrapWithLoader } = useContext(LoaderContext)
  const [categories, setCategories] = useState<ICoreProductTopicWithTilesAndRef[]>([])
  const [selectedTile, setSelectedTile] = useState<IApplicationTile | null>(null)
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const categoriesRef = useRef<ICoreProductTopicWithTilesAndRef[]>(categories)

  useEffect(() => {
    wrapWithLoader(async () => {
      await getAllTiles().then(res =>
        setAllCategories(
          res.map(t => {
            return { ...t, reference: createRef() }
          })
        )
      )
      setLoading(false)
    })

    document.addEventListener("scroll", handleScroll)
  }, [applications])

  const handleScroll = (event: Event) => {
    if (!categoriesRef.current || categoriesRef.current.length === 0) return

    const eventTarget = event.target as Document

    if (!eventTarget || !eventTarget.scrollingElement) return

    let max
    if (
      eventTarget.scrollingElement.scrollHeight - eventTarget.scrollingElement.scrollTop ===
      eventTarget.scrollingElement.clientHeight
    ) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      max = categoriesRef.current
        .filter(x => x.reference.current)
        .reduce((a, b) =>
          a.reference.current!.getBoundingClientRect().top >
          b.reference.current!.getBoundingClientRect().top
            ? a
            : b
        )
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    } else {
      const matchedRefs = categoriesRef.current.filter(
        x =>
          x.reference.current &&
          eventTarget.scrollingElement &&
          eventTarget.scrollingElement.scrollTop >
            x.reference.current.offsetTop + x.reference.current.offsetHeight
      )
      if (matchedRefs.length === 0) return

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      max =
        matchedRefs.length > 1
          ? matchedRefs.reduce((a, b) =>
              a.reference.current!.getBoundingClientRect().top >
              b.reference.current!.getBoundingClientRect().top
                ? a
                : b
            )
          : matchedRefs[0]
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }

    if (!max) return

    setSelectedCategoryId(max.coreProductTopic.id)
  }

  const toggleFavouriteForProduct = (topicId: string, tileId: string) =>
    wrapWithLoader(async () => {
      await toggleFavourite(tileId, false)
      const categoriesCopy = [...categories]
      const cIdx = categoriesCopy.findIndex(c => c.coreProductTopic.id === topicId)
      if (cIdx !== -1) {
        const tIdx = categoriesCopy[cIdx].applicationTiles.findIndex(t => t.id === tileId)
        if (tIdx !== -1) {
          categoriesCopy[cIdx].applicationTiles[tIdx].favourited =
            !categoriesCopy[cIdx].applicationTiles[tIdx].favourited
          setAllCategories(categoriesCopy)
        }
      }
    })

  const setAllCategories = (newCategories: ICoreProductTopicWithTilesAndRef[]) => {
    categoriesRef.current = newCategories
    setCategories(newCategories)
  }

  const onCategoryClicked = (id: string) => {
    const foundCategories = categories.filter(x => x.coreProductTopic.id === id)
    if (foundCategories.length > 0) {
      const ref = foundCategories[0].reference.current
      if (!ref) return

      const headerElement = document.getElementById("analytics-header")

      if (!headerElement) return

      ref.scrollIntoView()

      const root = document.getElementById("root")
      if (root) {
        root.scrollTop -= headerElement.offsetHeight
      }
    }
  }

  const requestApplicationAccess = (id: string, stepTwoRequired: boolean) =>
    wrapWithLoader(async () => {
      if (stepTwoRequired) {
        navigate(`/product/${id}/steptwo`)
      } else {
        await requestAppAccess(id)
      }
    })

  const toggleTileModal = (tile: IApplicationTile) => setSelectedTile(tile)

  const onTileModalClosed = () => setSelectedTile(null)

  if (loading) {
    return null
  }

  return (
    <div className="analytics-page">
      {selectedTile && (
        <Modal close={onTileModalClosed}>
          <ProductModalContent product={selectedTile} close={onTileModalClosed} />
        </Modal>
      )}
      <AnalyticalHeader sticky />
      <ProductHeaderContainer />
      <div className="analytical-products-body">
        <CategoryNameContainer
          categories={categories}
          selectedCategoryId={selectedCategoryId}
          onCategoryClicked={onCategoryClicked}
        />
        <CategoriesContainer
          categories={categories}
          requestAppAccess={requestApplicationAccess}
          tableauWebWrapperEnabled={tableauWebWrapperEnabled}
          toggleModal={toggleTileModal}
          toggleFavourite={toggleFavouriteForProduct}
        />
      </div>
    </div>
  )
}

export default withWrapper(withTermsCheck(AnalyticsLandingPage, Routes.AnalyticsHubLandingPage))
