import { getApi, postApi } from "../../../../global/utils/api"

export const getAllAzProductsList = async () => {
  const allAzProductsList = await getApi("/AToZProduct")
  return allAzProductsList.data
}

export const requestAppAccess = async id => {
  return await postApi(`/application/request?id=${id}`, {})
}

export const listAllProductsByAlphabets = listOfProducts => {
  return listOfProducts.map(product => {
    return {
      alphabet: product.title.charAt(0).toUpperCase(),
      product: product,
    }
  })
}

export const alphabetsOfAvailableApps = listOfProducts => {
  const listAlphabetsOfAvailableApps = []
  listOfProducts.map(product => {
    if (!listAlphabetsOfAvailableApps.includes(product.alphabet))
      listAlphabetsOfAvailableApps.push(product.alphabet)
  })
  return listAlphabetsOfAvailableApps
}

export const productsDataWithoutAlphabet = productsArray => {
  const listOfProductsOnly = []
  productsArray.map(productData => {
    listOfProductsOnly.push(productData.product)
  })
  return listOfProductsOnly
}

export const addsTagsFromProductToTagsList = productsList => {
  let tagsList = []
  productsList.map(productData => {
    if (tagsList.length > 0) {
      productData.tags.map(tag => {
        if (!tagsList.includes(tag)) {
          tagsList.unshift(tag)
        }
      })
    }
    if (tagsList.length === 0) {
      productData.tags.map(tag => {
        tagsList.unshift(tag)
      })
    }
  })
  return tagsList
}

export const filterProducts = (productsArray, tagsArray, searchTerm, accessFilteringArray) => {
  let productsFiltered = productsArray
  if (tagsArray.length > 0) {
    productsFiltered = []
    productsArray.forEach(productData => {
      if (
        productData.product.tags?.length > 0 &&
        tagsArray.every(tag => productData.product.tags.includes(tag))
      ) {
        productsFiltered.push(productData)
      }
    })
  }
  if (searchTerm.length > 0) {
    productsFiltered = productsFiltered.filter(productData =>
      productData.product.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }
  if (accessFilteringArray.length > 0) {
    productsFiltered = productsFiltered.filter(productData =>
      accessFilteringArray.includes(productData.product.userApplicationMappingState)
    )
  }
  return productsFiltered
}
