import React from "react"
import { observer } from "mobx-react"
import { useStores } from "../../../../../global/hooks"

import "../../styles.scss"

interface ITabProps {
  dashboardName: string
  name: string
}

const Tab = observer(({ name, dashboardName }: ITabProps) => {
  const {
    tableauReportViewerStore: { tableauVisualization, setCurrentTab, currentTab },
  } = useStores()

  if (!tableauVisualization?.workbook) {
    return <></>
  }

  const selectedTab =
    currentTab.length > 0 ? currentTab : tableauVisualization.workbook.activeSheet.name

  return (
    tableauVisualization.workbook && (
      <>
        <div
          className={selectedTab === dashboardName ? "tab-selected" : "tab"}
          onClick={() => setCurrentTab(name)}
        >
          <span className="tab-title">{name}</span>
        </div>
      </>
    )
  )
})

export default Tab
