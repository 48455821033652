import React from "react"
import "./style.scss"

const spinner = () => {
  return (
    <div className="loading">
      <div className="loading__parent">
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
        <div className="loading__child"></div>
      </div>
    </div>
  )
}

export default spinner
