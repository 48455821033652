import { observer } from "mobx-react"
import { BodyText, Label } from "nhsuk-react-components"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { useStores } from "../../../global/hooks"
import { Routes } from "../../../global/enums"
import "../../styles.scss"

const SentView = observer(() => {
  const { productOwnerAdminStore } = useStores()

  const { selectedProduct } = productOwnerAdminStore

  return (
    <>
      <Label isPageHeading>Your invites have been sent</Label>

      <BodyText>An invitation has been sent to the emails you provided.</BodyText>

      <RouterLink to={selectedProduct ? Routes.AppAdmin : Routes.Admin}>
        Return to {selectedProduct ? "'Manage your product and its users'" : "'Manage users'"}
      </RouterLink>
    </>
  )
})

export default SentView
