import React from "react"

const ViewIcon = () => {
  return (
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 0C8.3335 0 2.64016 4.14667 0.333496 10C2.64016 15.8533 8.3335 20 15.0002 20C21.6668 20 27.3602 15.8533 29.6668 10C27.3602 4.14667 21.6668 0 15.0002 0ZM15.0002 16.6667C11.3202 16.6667 8.3335 13.68 8.3335 10C8.3335 6.32 11.3202 3.33333 15.0002 3.33333C18.6802 3.33333 21.6668 6.32 21.6668 10C21.6668 13.68 18.6802 16.6667 15.0002 16.6667ZM15.0002 6C12.7868 6 11.0002 7.78667 11.0002 10C11.0002 12.2133 12.7868 14 15.0002 14C17.2135 14 19.0002 12.2133 19.0002 10C19.0002 7.78667 17.2135 6 15.0002 6Z"
        fill="#005EB8"
      />
    </svg>
  )
}

export default ViewIcon
