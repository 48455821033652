import React from "react"
import "./styles.scss"

interface IWarningBoxProps {
  className?: string
  children: React.ReactNode
}

const WarningBox = (props: IWarningBoxProps) => {
  const { className, children } = props

  return <p className={`warning-box ${className || ""}`}>{children}</p>
}

export default WarningBox
