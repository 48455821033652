import React from "react"
import "./styles.scss"

interface ITextProps {
  textType: string
  children: React.ReactNode
}

const Text = (props: ITextProps) => {
  const { textType, children } = props

  const cssClass = () => {
    switch (textType) {
      case "heading":
        return "az-page__text__styles__heading"
      case "description":
        return "az-page__text__styles__description"
      case "label":
        return "az-page__text__styles__label"
      case "title-blue":
        return "az-page__text__styles__title--blue"
      default:
        return "az-page__text__styles__title--black"
    }
  }

  return <p className={cssClass()}>{children}</p>
}

export default Text
