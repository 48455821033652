import { Workbox } from "workbox-window"
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

const NEWSERVICEWORKERVERSIONINSTALLMESSAGE = "INSTALLED_NEW_WORKER_VERSION"
const INITIALSERVICEWORKERINSTALLMESSAGE = "INSTALLED_INITIAL_WORKER"

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export function register(config?: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

    isLocalhost && checkValidServiceWorker(swUrl, config)

    !isLocalhost && registerValidSW(swUrl, config)
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  const workbox = new Workbox(swUrl, {})

  workbox.addEventListener("installed", event => {
    if (event.isUpdate) {
      //This is when a new service worker is installed and an old one already exists. Update code goes here maybe clearing caches etc
      //will reload application giving the new service worker control of the app and clearing the preexisting caches

      workbox.messageSW({
        type: NEWSERVICEWORKERVERSIONINSTALLMESSAGE,
        payload: {
          message: "New Service Worker Verison Installed Ready Waiting For Activation",
          worker: { ...event.sw },
        },
      })
    }
    if (!event.isUpdate) {
      workbox.messageSW({
        type: INITIALSERVICEWORKERINSTALLMESSAGE,
        payload: { message: "Initial Service Worker", worker: { ...event.sw } },
      })
      //This is the first service worker ever on our site, first installation code goes here
      //e.g precaching all the assets needed for the site to work offline
    }
  })

  workbox.addEventListener("waiting", event => {
    //THE SERVICE WORKER LIFE CYCLE IS:
    //FOR THE VERY FIRST WORKER IN OUR APPLICATION: INSTALL---->ACTIVATE
    //FOR ANY NEW VERSIONS OF SERVICE WORKERS: INSTALL ---->WAITING---->ACTIVATE
    //N.B THE NEW VERSION ALWAYS WAITS UNTIL THE OLD VERSION NO LONGER HAS ANY CLIENTS (TABS ITS HANDLING).
    //WE CAN FORCE THE NEW ONE TO TAKE OVER BY ISSUING A SKIPWAITING MESSAGE TO THE NEWLY INSTALLED AND WAITING WORKER
    workbox.messageSW({
      type: NEWSERVICEWORKERVERSIONINSTALLMESSAGE,
      payload: {
        message: "New Service Worker Verison Installed Ready Waiting For Activation",
        worker: { ...event.sw },
      },
    })
  })

  workbox.addEventListener("activating", e => {
    //THINGS WE MAY WISH TO DO DURING THE ACTIVATION PROCESS
    console.log(`ACTIVITING NEW SERVICE WORKER`)
  })

  workbox.addEventListener("activated", () => {
    //THINGS WE MAY WISH TO DO ONCE A NEWER SERVICE WORKER IS ACTIVATED E.G SEND A MESSAGE TO THE SERVICE WORKER TO CACHE SOME STUFF

    console.log("ACTIVATED NEW WORKER")
  })

  workbox
    .register()
    .then(serviceWorkerRegistration => {
      if (!serviceWorkerRegistration) return

      serviceWorkerRegistration.onupdatefound = () => {
        const installingWorker = serviceWorkerRegistration.installing

        if (installingWorker == null) {
          return
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(serviceWorkerRegistration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.")

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(serviceWorkerRegistration)
              }
            }
          }
        }
      }
    })
    .catch(error => {
      console.error("Error during service worker registration:", error)
    })
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then(response => {
      console.log(`CALLIGN THE RESPONSE ${JSON.stringify(response)}`)
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type")
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        console.log(`No service worker file found. Please ensure the file isn't missing`)
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        console.log("REGISTERING FROM LOCAL HOST")
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log("No internet connection found. App is running in offline mode.")
    })
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
