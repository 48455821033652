import { ErrorSummary } from "nhsuk-react-components"
import React, { memo } from "react"
import { IValidationError } from "../../interfaces/validation"
import "./styles.scss"
import { scrollRefIntoView } from "../../utils/dom"

interface IValidationErrorsSummaryProps<TRef extends HTMLElement> {
  errors: IValidationError[]
  errorRefCodeMappings: Record<number, React.RefObject<TRef>>
  inputRef?: React.RefObject<HTMLDivElement>
}

const ValidationErrorsSummary = <TRef extends HTMLElement>(
  props: IValidationErrorsSummaryProps<TRef>
) => {
  const { errors, errorRefCodeMappings, inputRef } = props

  if (errors.length === 0) {
    return null
  }

  const handleErrorRefClick = (code: number) => {
    const errorRef = errorRefCodeMappings[code]

    if (errorRef && errorRef.current) {
      errorRef.current.focus()
      scrollRefIntoView(errorRef)
    }
  }

  return (
    <div ref={inputRef}>
      <ErrorSummary role="alert" tabIndex={-1} className="validation-errors-summary">
        <ErrorSummary.Title className="validation-errors-summary__title">
          There is a problem
        </ErrorSummary.Title>
        <p className="validation-errors-summary__body">Please correct the following errors</p>
        <ErrorSummary.List className="validation-errors-summary__list">
          {errors.map(err => {
            return (
              <ErrorSummary.Item
                key={err.code}
                className="validation-errors-summary__list__item"
                onClick={() => handleErrorRefClick(err.code)}
              >
                {err.message}
              </ErrorSummary.Item>
            )
          })}
        </ErrorSummary.List>
      </ErrorSummary>
    </div>
  )
}

export default memo(ValidationErrorsSummary)
