import Application from "../types/classes/application"
import React from "react"
import "../styles.scss"
import { ApplicationState } from "../types/enums"

export const MultiAppConfigurationHeaderSection = ({
  headers,
  applications,
}: {
  headers: string[]
  applications: Application[]
}) => {
  return (
    <>
      {applications.filter(app => app.applicationState !== ApplicationState.Deleted).length > 0 && (
        <div className="multiappconfiguration__headers">
          {headers.map((header, index) => (
            <header
              key={`${header}-${index}`}
              className="multiappconfiguration__headers--header__item"
            >
              {header}
            </header>
          ))}
        </div>
      )}
    </>
  )
}
