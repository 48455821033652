import { BodyText, Label } from "nhsuk-react-components"
import React from "react"
import { Link } from "react-router-dom"
import "./styles.scss"

const TermsAndConditionsText = () => {
  return (
    <>
      <BodyText>
        The NHS England applications website <strong>('this website')</strong> is operated by NHS
        England. This website provides our online identity management solution (provided to us by a
        third party company called OKTA) which manages the access to products, services, restricted
        website content, dashboards or other applications provided by NHS England (hereafter
        collectively referred to 'NHSE applications').
      </BodyText>

      <BodyText>
        This page, taken with the documents and pages it refers to, tells you the terms of use which
        you agree to when you use this website and all NHSE applications that follow on from
        registration with this website.
      </BodyText>

      <BodyText>
        In accessing various NHSE applications you confirm that you accept the terms of use and that
        you agree to comply with them. If you do not agree to these terms of use, you must refrain
        from using this website or access the various NHSE applications that would follow. Please
        read and make sure you understand these terms.
      </BodyText>

      <BodyText>
        Restrictions apply to the use of data provided to you via various NHSE applications. There
        restrictions are outlined generally in these terms of use associated with this website and
        individual NHSE applications may provide supplementary further detail.
      </BodyText>

      <BodyText>
        We may amend these terms and conditions for use from time to time. If we do, you will be
        asked to review them and provide your acceptance again.
      </BodyText>
      <Label size="m">User access and responsibilities</Label>
      <BodyText>This website is maintained for your personal use.</BodyText>

      <BodyText>
        You agree to use this site only for lawful purposes, and in a manner that does not infringe
        the rights of, or restrict or inhibit the use and enjoyment of, this site by any third
        party.
      </BodyText>

      <BodyText>
        We aim to update our site regularly, and may change the content at any time.
      </BodyText>

      <BodyText>
        Access to NHSE applications is determined by a requestor having a required white listed
        email and organisation type. This is outlined as part of the registration process via this
        website and our identity management solution 'OKTA'.
      </BodyText>

      <BodyText>Following registration and approval via this website users must:</BodyText>

      <ul>
        <li className="terms__list-item">
          when registering for an OKTA account- provide accurate registration information and
          register with an individual email address as we do not accept shared or group email
          accounts.
        </li>
        <li className="terms__list-item">
          where required choose a password- which is of sufficient complexity to ensure your account
          is kept safe and secure.
        </li>
        <li className="terms__list-item">
          keep account details safe - users must keep their access username and password
          confidential and must not allow any other individual to access the data using their access
          credentials.
        </li>
        <li className="terms__list-item">
          ensure you have registered for an account with the organisation in which you are
          working/have an employment contract and notify NHS England immediately of any change so
          organisational details can be updated. This is particularly important for staff who leave
          employment in the NHS and may not be entitled to have continued access to various NHSE
          applications.
        </li>
        <li className="terms__list-item">
          In accessing NHSE applications not disclose the outputs to any third parties - users must
          treat all information contained within NHSE applications as confidential and not share it
          with any third parties who are not entitled to access the information, without prior
          written approval of NHS England.
        </li>
      </ul>
      <BodyText>In using information derived from NHSE applications users must:</BodyText>

      <ul>
        <li className="terms__list-item">
          generally, use the data or information for supporting operational and 'system' level
          improvements in quality, efficiency and productivity of patients who use the NHS or other
          healthcare services. This purpose may be delivered at a local trust/provider level, system
          (e.g. across an Integrated Care Board), region or national level. Examples of using data
          at a national level include by NHS England using data to meet its functions, by another
          approved executive non-departmental public body of the Department of Health and Social
          Care, such as NHS England and the Care Quality Commission or by an Executive agency such
          as the UK Health Security Agency (UKHSA).
        </li>
        <li className="terms__list-item">
          read and comply with any supplementary detail provided in the NHSE applications (e.g. apps
          or dashboards) about supplementary uses or restrictions of data.
        </li>

        <li className="terms__list-item">
          where the NHSE application provides national benchmarking and variation data relating to
          multiple trusts/providers. Users should not share national benchmarking and variation data
          externally with any third parties in a format that identifies other trusts/providers, this
          should be suitably anonymised.
        </li>

        <li className="terms__list-item">
          not use data from this website or NHSE applications for commercial purposes or to inform
          development of other commercial products.
        </li>
        <li className="terms__list-item">
          You should not publish data provided to you via registration on this website and from
          accessing NHSE applications.
        </li>
        <li className="terms__list-item">
          not disassemble, reverse engineer, de-compile, copy, adapt, edit or create any derivative
          works from, the source code of the whole or any part of NHSE applications, nor attempt to
          do such things.
        </li>
        <li className="terms__list-item">
          seek NHS England's prior written approval for use of the information for any other
          purposes.
        </li>
        <li className="terms__list-item">
          notify NHS England of any breaches - users must promptly, and in any event within 48
          hours, inform NHS England on{" "}
          <a href="mailto:england.contactus@nhs.net">england.contactus@nhs.net</a> if they become
          aware of <br />
          - a breach of these terms and conditions of use; and / or <br />
          - any unauthorised users accessing NHSE applications and / or <br />
          - the need to de-activate a user's access. <br />
        </li>
      </ul>
      <BodyText>
        If users are found to have breached any of these terms of use, access will be suspended
        immediately.
      </BodyText>

      <BodyText>
        You can consult{" "}
        <a href="https://www.england.nhs.uk/contact-us/privacy-notice/">
          NHS England privacy notice
        </a>{" "}
        if you have any questions on appropriate use of data.
      </BodyText>
      <Label size="m">Registration using this website and access to NHSE applications</Label>
      <BodyText>
        Anyone can apply to register with this website for access to NHSE applications. The system
        will then provide access to various NHSE applications dependant on the user's credentials.
      </BodyText>

      <BodyText>
        Access is generally granted when the applicant is employed by, or has other bona fide
        connection to the work of known organisations whose functions are to provide or support
        healthcare improvements. Some typical types of organisation are as follows:
      </BodyText>

      <ul>
        <li className="terms__list-item">
          Organisations dedicated to the direct provision of healthcare, or the support of such
          provision. Examples could be NHS trusts, ICBs or health-related arm's length bodies.
        </li>
        <li className="terms__list-item">
          Internal departments of organisations with more general responsibilities, where the
          department is dedicated to the provision of healthcare, or the support of such provision.
          Examples could be local governments, prisons or young offender institutions.
        </li>
        <li className="terms__list-item">
          Organisations that have formal partnership arrangements with any of the above, in order to
          provide healthcare, or the support of such provision. Examples could be universities,
          charities or management consultancies.
        </li>
      </ul>
      <Label size="m">Our Privacy Notices</Label>
      <BodyText>
        NHS England may collect and use personal data for the functions that we exercise. Our
        privacy notice explains how we do this.
      </BodyText>

      <BodyText>
        NHS England will continue to publish separate privacy notices explaining how we use personal
        data for our respective purposes.
      </BodyText>

      <BodyText>
        <a href="https://www.england.nhs.uk/contact-us/privacy-notice/">
          NHS England privacy notice
        </a>
      </BodyText>
      <BodyText>
        <Link to="/privacy">NHS England applications privacy notice</Link>
      </BodyText>

      <BodyText>
        Please read the these carefully to understand our views and practices regarding your
        personal data and how we will treat it.
      </BodyText>
      <Label size="m">Privacy statement specific to this website and associated applications</Label>
      <BodyText>
        For the purposes of the General Data Protection Regulation (GDPR) 2016 and Data Protection
        Act 2018, the data controller for user's personal data (collected as part of signing up to
        use this website and associated applications) is NHS England.
      </BodyText>

      <BodyText>
        We collect certain information or data about you when you register with this website and
        access various applications.
      </BodyText>

      <BodyText>This includes:</BodyText>

      <ul>
        <li className="terms__list-item">
          Identity and contact data you provide when you register, including your name, role,
          organisation and email address.
        </li>
        <li className="terms__list-item">
          Identifiable usage data that is automatically collected by the website and applications
          when you visit, such as pages visited and documents opened, how often you use and when.
        </li>
        <li className="terms__list-item">
          Technical information, including your Internet Protocol (IP) address, session ID,
          timestamp and details of which version of web browser you are using.
        </li>
        <li className="terms__list-item">
          Anonymous information on how you use this website, using cookies (data where your identity
          has been removed). Cookies are used to distinguish you from other users. Where we use
          cookies on this website and associated applications this is in accordance to our policy.
          For detailed information on the cookies we use and the purposes for which we use them, see
          our Cookies Policy at{" "}
          <a href="https://www.england.nhs.uk/privacy-policy/ ">
            https://www.england.nhs.uk/privacy-policy/
          </a>
        </li>
      </ul>
      <Label size="m">The legal bases for processing of your personal data</Label>
      <ul>
        <li className="terms__list-item">
          The processing of identity and contact categories of personal data noted above is
          necessary for the performance of a task carried out in the public interest or in the
          exercise of official authority vested in NHS England. This includes to support continuous
          improvement in the NHS and to protect and promote the interests of people who use health
          care services by promoting provision of health care services which are, inter alia,
          economic, efficient and effective, and which maintains or improves the quality of
          services.
        </li>
        <li className="terms__list-item">
          The processing of identity and contact data and identifiable usage data noted above is
          undertaken on the basis of NHS England legitimate interests necessary for providing access
          to this website and associated applications. Any wider sharing of this information will be
          noted on the supplementary terms of use of the individual application being accessed.
        </li>
        <li className="terms__list-item">
          The processing of identity and contact data is undertaken on the basis of NHS England
          legitimate interests necessary for providing users with newsletters, notifications and
          invitations related to this website and associated applications.
        </li>
        <li className="terms__list-item">
          The processing of identifiable feedback or queries data is undertaken on the basis of your
          voluntary consent to send us these.
        </li>
        <li className="terms__list-item">
          The processing and further individual level analysis of how you use this website or the
          associated applications is undertaken on the basis of your explicit consent captured
          separately as part of any supplementary application or dashboard data use preferences
          options (e.g. as available on Model Health System).
        </li>
      </ul>
      <Label size="m">Purposes of processing your personal data</Label>
      <BodyText>
        1. Necessary requirements to provide this website and associated applications to you.
      </BodyText>
      <Label size="s">All data categories will be used:</Label>
      <BodyText>
        for the administration of a user account, audit and as part of our efforts to keep this
        website and associated applications safe and secure;
      </BodyText>
      <BodyText>
        to monitor and improve usage of these applications at national and local trust/provider
        level;
      </BodyText>
      <BodyText>
        to administer this website or associated applications for internal operations, including
        troubleshooting, data analysis, testing, research, statistical and survey purposes;
      </BodyText>
      <BodyText>to improve the performance, design and usefulness.</BodyText>

      <BodyText>2. Sending newsletters, notifications and invitations to you.</BodyText>
      <Label size="s">Identity and contact data will be used:</Label>
      <BodyText>
        to make suggestions and recommendations to you and other users about the information
        available on this website and associated applications and how it may be beneficial to you or
        them (for example as part of a weekly newsletter which is administrated by NHS England or
        our nominated contracted 'data processor');
      </BodyText>
      <BodyText>invite you to participate in completing surveys or other user research;</BodyText>
      <BodyText>
        to measure or understand the effectiveness of our communications and support you and other
        users about the information available that may be beneficial to you or them;
      </BodyText>
      <BodyText>
        to notify you if your account with us has not been accessed for an extended period of time
        and any intention of NHS England to de-activate the account within a specified timeframe.
      </BodyText>
      <BodyText>
        You will receive newsletters, notifications and invitations but can object at any time by
        using the unsubscribe function which will be displayed at the bottom of any update emails.
      </BodyText>

      <BodyText>3. To respond to your feedback or queries.</BodyText>
      <BodyText>
        Identity and contact data linked with your voluntary feedback or query data will be used to
        respond to you and inform you about our applications improvements and future developments.
      </BodyText>

      <BodyText>
        4. To support targeted user engagement and/or inform applications developments and future
        improvements.
      </BodyText>
      <BodyText>
        Anonymous user information will be used to inform this website or associated applications
        developments and future improvements (e.g. such as looking at the highest trust user figures
        or which areas are used most or least).
      </BodyText>
      <BodyText>
        If we would like to further analyse or process your personal data in such a way that could
        identify you personally outside what would be considered our legitimate interests (e.g. for
        purposes of individual level behavioural analysis of use of the different applications), we
        will seek your consent. In so doing we shall comply with the requirements of General Data
        Protection Regulation 2016/679 (known as 'GDPR') and the Data Protection Act 2018.
      </BodyText>
      <BodyText>
        Further analysis of identity and contact data linked with identifiable usage data will be
        used for targeted engagement activities and/or to inform this website or associate
        applications developments and improvements. You do not have to consent to this additional
        processing of your personal data and can still use this website and associated applications
        in the usual way.
      </BodyText>
      <Label size="m">Where we store your personal data</Label>
      <BodyText>
        We store your data on secure servers within the European Economic Area (EEA) and/or other
        locations that meet the safeguards as set out under Data Protection requirements.
      </BodyText>

      <ul>
        <li className="terms__list-item">
          Data provided by you as part of registration using Okta (identify management service) is
          held securely under contract with Okta within the cloud. Okta is used to manage access to
          NHS England's business tools and products.{" "}
        </li>
        <li className="terms__list-item">
          User's names and email addresses used to send out update newsletters are held securely
          under contract with our electronic mail service providers. Email lists are uploaded to
          these service providers are managed securely within the system where NHS England control
          all content uploaded to these services. Where these services are hosted in the United
          States appropriate measures and safeguards have been taken in the form of implementing the
          European Commission's Standard Contractual Clauses to meet European Union Data Protection
          law.{" "}
        </li>
        <li className="terms__list-item">
          Personal data provided by you which is included within usage reports is held securely
          within NHS England's secure network available to the internal teams. Where these are
          shared specific to an application with for example a trust/provider super admin user this
          will be highlighted in the supplementary application's terms of use.{" "}
        </li>
        <li className="terms__list-item">
          Data automatically collected by the this website or associated application back end
          database is stored securely under contract within the Microsoft Azure Cloud.
        </li>
      </ul>
      <BodyText>
        The transmission of information via the internet is not completely secure. We cannot
        guarantee the security of your data transmitted to our site. Any transmission is at your own
        risk. We have procedures and security features in place to try and keep your data secure
        once we receive it.
      </BodyText>
      <Label size="m">Further disclosure of your information to other third parties</Label>
      <BodyText>We may disclose your personal information to third parties:</BodyText>

      <ul>
        <li className="terms__list-item">if we have a legal obligation to do so; or </li>
        <li className="terms__list-item">if we have to enforce or apply our terms of use.</li>
      </ul>
      <BodyText>
        This includes exchanging information with other organisations for legal reasons.
      </BodyText>

      <BodyText>
        We won't share your information with any other organisations for marketing, market research
        or commercial purposes, and we won't pass on your personal identifiable data to other
        websites.
      </BodyText>
      <Label size="m">How long will we use your personal data for?</Label>
      <BodyText>
        We will only retain your personal data for as long as necessary to fulfil the purposes we
        collected it for, including for the purposes of satisfying any legal, accounting or audit
        and monitoring requirements.
      </BodyText>
      <Label size="m">Keeping accurate and up to date information</Label>
      <BodyText>
        It is important that the personal data we hold about you is accurate and current. Please
        keep us informed if your personal data changes (for example your email address) during your
        relationship with us or if you leave or move to another organisation.
      </BodyText>
      <Label size="m">Your rights</Label>
      <BodyText>
        Under certain circumstances, you have rights under data protection laws in relation to your
        personal data. These are set out in our privacy notices.
      </BodyText>

      <BodyText>
        Under certain circumstances, you have rights under data protection laws in relation to your
        personal data.
      </BodyText>

      <ul>
        <li className="terms__list-item">
          the right to be informed- please note the information provided here sets out the
          collection, processing and purposes in which we use personal data;{" "}
        </li>
        <li className="terms__list-item">
          the right of access to personal data held about you and to check that we are lawfully
          processing it;{" "}
        </li>
        <li className="terms__list-item">
          the right to rectification- in regard to any personal data you consider we hold but is
          factually inaccurate;{" "}
        </li>
        <li className="terms__list-item">
          the right to request erasure or deletion of personal data we hold about you;{" "}
        </li>
        <li className="terms__list-item">
          the right to restrict how we are processing and or using your personal data;{" "}
        </li>
        <li className="terms__list-item">
          the right to object to how we are processing your personal data or making contact with
          you;{" "}
        </li>
        <li className="terms__list-item">
          rights in relation to automated decision making and profiling- we do not make use of
          automated decision making in relation to personal data and therefore any right to data
          portability does not apply to the personal data we collect.{" "}
        </li>
        <li className="terms__list-item">
          However we may profile our user database to send targeted emails or engagement and this
          processing is included as part of the consent options which follows this privacy policy.{" "}
        </li>
        <li className="terms__list-item">
          the right to withdraw consent at any time- where we are processing your personal data
          under consent, we provide you can change your mind and withdraw your consent at any time
          by editing your consent preferences within the specific product and service you are
          accessing.
        </li>
      </ul>
      <BodyText>
        You have the right to make a complaint at any time to the Information Commissioner's Office
        (ICO), the UK supervisory authority for data protection issues{" "}
        <a target="_blank" rel="noreferrer" href="https://ico.org.uk">
          ico.org.uk
        </a>
        . We would, however, appreciate the chance to deal with your concerns before you approach
        the ICO, so please contact us in the first instance by emailing{" "}
        <a href="mailto:england.dpo@nhs.net">england.dpo@nhs.net</a>
      </BodyText>
      <BodyText>
        If you would like to exercise or find out more about your rights please contact{" "}
        <a href="mailto:england.dpo@nhs.net">england.dpo@nhs.net</a>
      </BodyText>
      <Label size="m">Changes to our privacy policy</Label>
      <BodyText>
        Any changes we make to our privacy policy in the future will be posted on our corporate
        website privacy notices and also this page where specific to applications and, where
        appropriate, notified to you by email or as part of a request to re-accept the terms of use.
        Please check the policy frequently to see any updates or changes.
      </BodyText>
      <Label size="m">Warranties and liability</Label>
      <BodyText>
        NHS England does not warrant the accuracy or completeness of the data provided via NHSE
        applications. NHS England does not guarantee that the data will be suitable for the user's
        purposes.
      </BodyText>

      <BodyText>
        NHS England excludes all liability arising from use, including any loss of profits, revenue,
        opportunity, contracts, turnover, anticipated savings, goodwill, reputation, business
        opportunity or loss to or corruption of data (regardless of whether any of these losses or
        damages are direct, indirect or consequential).
      </BodyText>

      <BodyText>
        The user warrants that it will not use data provided via NHSE applications or any
        information contained in it for any purpose that is in contravention of any applicable law
        or regulation or in a manner that will infringe the copyright, trademarks, service marks or
        other intellectual property rights of third parties or violate the privacy, publicity or
        other personal rights of others or in any defamatory, obscene, threatening, abusive or
        hateful manner.
      </BodyText>

      <BodyText>
        The user shall indemnify and hold harmless NHS England, its employees and agents, against
        all claims, liability, losses, damages and expenses including, without limitation, legal
        fees and costs arising out of or incurred as a result of any claims made, or limitation
        brought, against NHS England, its employees or agents, as a result of the user's use of data
        provided via NHSE applications or any information contained in it, for any purpose
        whatsoever.
      </BodyText>
      <Label size="m">Intellectual Property Rights (IPR)</Label>
      <BodyText>
        Except where specified or agreed in writing with NHS England, you acknowledge that all IPR
        in NHSE applications and its contents throughout the world belong to NHS England, and that
        you have no IPR in, or to, the NHSE applications and its contents other than the right to
        use them in accordance with these terms and conditions.
      </BodyText>
      <Label size="m">Viruses</Label>
      <BodyText>
        You must not misuse our website or NHSE applications by knowingly introducing viruses,
        trojans, worms, logic bombs or other material that is malicious or technologically harmful.
        You must not attempt to gain unauthorised access to our site, the server on which our site
        is stored or any server, computer or database connected to our site. You must not attack our
        site via a denial-of-service attack or a distributed denial-of-service attack; or otherwise
        interfere, damage or disrupt any part of our site, any equipment or network on which our
        site are stored, any software used in the provision of our site, or any equipment or network
        or software owned or used by any third party.
      </BodyText>

      <BodyText>
        By breaching this provision, you may be committing a criminal offence.? We will report any
        such breach to the relevant law enforcement authorities and we will co-operate with those
        authorities by disclosing your identity to them
      </BodyText>

      <BodyText>
        Every attempt has been made to ensure that this website and NHSE applications are of high
        quality and free from malicious code, but NHS England does not guarantee that these will be
        free from viruses. You should use your own virus protection software and take appropriate
        safeguards before downloading information.
      </BodyText>
      <Label size="m">Uninterrupted access to this website and NHSE applications</Label>
      <BodyText>
        NHS England does not guarantee that this website or NHSE applications, or any content on it,
        will always be available or be uninterrupted. NHS England may suspend or withdraw or
        restrict the availability of all or any part of these without notice for any reason at any
        time.
      </BodyText>
      <Label size="m">Governing law and jurisdictions</Label>
      <BodyText>
        The terms and conditions of use of this website and NHSE applications shall be governed by
        the law of England and Wales and shall be subject to the non-exclusive jurisdiction of the
        courts of England and Wales.
      </BodyText>
      <Label size="m">General</Label>
      <BodyText>
        We accept no liability for any failure to comply with these terms and conditions where such
        failure is due to circumstances beyond our reasonable control.
      </BodyText>

      <BodyText>
        If we waive any rights available to us under these terms and conditions on one occasion,
        this does not mean that those rights will automatically be waived on any other occasion.
      </BodyText>

      <BodyText>
        If any of these terms and conditions are held to be invalid, unenforceable or illegal for
        any reason, the remaining terms and conditions shall nevertheless continue in full force.
      </BodyText>
      <Label size="m">Revisions to these terms</Label>
      <BodyText>
        We may at any time revise these terms and conditions without notice. Please check these
        terms and conditions regularly, as continued use of this website or NHSE applications after
        a change has been made is your acceptance of the change. You can find the date of the most
        recent update at the top of this page.
      </BodyText>
    </>
  )
}

export default TermsAndConditionsText
