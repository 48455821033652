import Link from "@govuk-react/link"
import { observable } from "mobx"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Checkboxes,
  Col,
  ErrorSummary,
  Form,
  Label,
} from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { useStores } from "../../../global/hooks"
import { ProductEditView } from "../../enums/enums"
import ProductViewRedirect from "../productViewRedirect"
import "./styles.scss"

const PublishView = observer(() => {
  const { productStore } = useStores()
  const [confirmed, setConfirmed] = useState(false)
  const [showConfirmError, setShowConfirmError] = useState(false)
  const [showTaskError, setTaskError] = useState(false)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [linkActive, setLinkActive] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)
  const publishText =
    "When you publish your product, it will be visible to the users you have approved to see it."
  const unpublishText =
    "When you unpublish your product, it will no longer be visible to users, and anyone who has been granted access through request or invitation will have to be granted access again if the product is republished."

  useEffect(() => {
    productStore.getExternalAppLink()
  }, [])

  const toggleConfirm = checked => {
    setConfirmed(checked)
    setShowConfirmError(false)
  }

  const handleSubmit = async () => {
    if (!confirmed) {
      setShowConfirmError(true)
    } else {
      const tasklist = await productStore.getTaskList()

      if (tasklist.tasks.filter(x => !x.complete).length === 0) {
        productStore.togglePublish()
        productStore.modifiedTasks = observable({})
        setRedirectingToTaskList(true)
      } else {
        setTaskError(true)
      }
    }
  }

  const handleCopyLink = () => {
    productStore.handleCopyString(productStore.externalAppLink).then(() => setLinkCopied(true))
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <Col id="publish-view" width="full">
      {showConfirmError && (
        <ErrorSummary role="alert" tabIndex={-1}>
          <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
          <ErrorSummary.Item>{`You must confirm that this product can be ${
            productStore.published ? "unpublished" : "published"
          }, by putting a tick in the box`}</ErrorSummary.Item>
        </ErrorSummary>
      )}
      {showTaskError && (
        <ErrorSummary role="alert" tabIndex={-1}>
          <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
          <ErrorSummary.Item>{`Application tasks are incomplete`}</ErrorSummary.Item>
        </ErrorSummary>
      )}
      <Label isPageHeading>{`${productStore.published ? "Unpublish " : "Publish "}${
        productStore.product.name
      }`}</Label>
      <BodyText id="publish-body-text">
        {productStore.published ? unpublishText : publishText}
      </BodyText>
      <Form id="checkbox">
        <Checkboxes onClick={e => toggleConfirm(e.target.checked)} error={showConfirmError}>
          <Checkboxes.Box
            id="confirm-checkbox"
            value="confirm"
          >{`I confirm that this product can be ${
            productStore.published ? "unpublished" : "published"
          }`}</Checkboxes.Box>
        </Checkboxes>
      </Form>
      <BodyText id="publish-link-text">
        If you have a landing page for users to register directly to{" "}
        <span>{productStore.product.name}</span>, you need to direct users to this link.
      </BodyText>
      <BodyText id="publish-link-controls">
        <span id="publish-link" className={linkActive ? "active" : ""}>
          {productStore.externalAppLink.substring(
            productStore.externalAppLink.indexOf("https://") + 8,
            productStore.externalAppLink.length
          ).length > 24
            ? `${productStore.externalAppLink
                .substring(
                  productStore.externalAppLink.indexOf("https://") + 8,
                  productStore.externalAppLink.length
                )
                .substring(0, 22)}...`
            : productStore.externalAppLink.substring(
                productStore.externalAppLink.indexOf("https://") + 8,
                productStore.externalAppLink.length
              )}
        </span>
        <span
          id="copy-control"
          onClick={handleCopyLink}
          onMouseDown={() => setLinkActive(true)}
          onMouseUp={() => setLinkActive(false)}
          onMouseLeave={() => setLinkActive(false)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              fill="#005EB8"
              fillRule="evenodd"
              d="M20.375 3.75H3.75v16.625H2V2h18.375v1.75zm-10.5 7h8.75v-.875h-8.75v.875zm0 2.625h8.75V12.5h-8.75v.875zm0 2.625h4.375v-.875H9.875V16zM23 14.588V5.5H5.5V23h8.915C17.183 23 23 16.68 23 14.588zM7.25 21.25v-14h14v6.6C21.25 17.444 16 16 16 16s1.328 5.25-2.308 5.25H7.25z"
            />
          </svg>
          <span>Copy to clipboard</span>
        </span>
        {linkCopied && (
          <span id="copied-flag">
            <svg
              className="nhsuk-icon nhsuk-icon__tick"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              fill="none"
            >
              <path
                strokeWidth="4"
                strokeLinecap="round"
                stroke="#007f3b"
                d="M18.4 7.8l-8.5 8.4L5.6 12"
              ></path>
            </svg>
            <span>Copied</span>
          </span>
        )}
      </BodyText>
      <div id="button-controls">
        <Button id="publish-button" onClick={handleSubmit}>
          {productStore.published ? "Unpublish" : "Publish"}
        </Button>
        <Link id="return-link" onClick={() => setRedirectingToTaskList(true)}>
          Return to task list
        </Link>
      </div>
    </Col>
  )
})

export default PublishView
