import { makeAutoObservable } from "mobx"
import {
  IOrganisation,
  IOrganisationModel,
  IOrganisationType,
} from "../interfaces/organisation/interfaces"
import { get } from "../../global/utils/api"
import { IRegion } from "../interfaces/region/interfaces"
import { HttpStatusCode } from "../enums/api"

const controllerPrefix = "/organisation/"

const storeUrls = {
  getRegions: `${controllerPrefix}region`,
  getAllOrganisations: `${controllerPrefix}all`,
  getAllOrganisationByCodeAndName: `${controllerPrefix}allOrganisations`,
  getOrganisationsAndTypes: `${controllerPrefix}?type=`,
  getOrganisationsByType: controllerPrefix,
}

export interface IOrganisationStore {
  getRegions: () => Promise<IRegion[]>
  getAllOrganisations: () => Promise<IOrganisation[]>
  getOrganisationsByType: (type: string) => Promise<IOrganisation[]>
  getOrganisationsAndTypes: (type: string) => Promise<IOrganisation[]>
  getOrganisationByCode: (
    code: string,
    excludeSites?: boolean
  ) => Promise<IOrganisation | undefined>
  getAllOrganisationsByName: (name: string, excludeSites?: boolean) => Promise<IOrganisation[]>
  getAllOrganisationModelsByName: (name: string) => Promise<IOrganisationModel[] | undefined>
  getOfficeRegionsForOrganisation: (code: string) => Promise<string[]>
  getSubOrganisations: (type: string) => Promise<IOrganisation[]>
  getOrganisationTypes: () => Promise<IOrganisationType[]>
  getSubOrganisationForTypeWithSearchTerm: (
    type: string,
    searchTerm: string
  ) => Promise<IOrganisation[]>
}

class OrganisationStore implements IOrganisationStore {
  constructor() {
    makeAutoObservable(this)
  }
  getRegions = (): Promise<IRegion[]> => {
    return new Promise<IRegion[]>((resolve, reject) => {
      get<IRegion[]>(storeUrls.getRegions).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(
            res.data.filter(
              region =>
                !["WALES", "SCOTLAND", "CHANNEL ISLANDS", "NORTHERN IRELAND"].includes(region.code)
            )
          )
        }

        reject()
      })
    })
  }

  getOrganisationTypes = (): Promise<IOrganisationType[]> => {
    return new Promise<IOrganisationType[]>((resolve, reject) => {
      get<IOrganisationType[]>(`${controllerPrefix}types`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getAllOrganisations = (): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>((resolve, reject) => {
      get<IOrganisation[]>(storeUrls.getAllOrganisations).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getOrganisationsByType = (type: string): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>((resolve, reject) => {
      get<IOrganisation[]>(`${storeUrls.getOrganisationsByType}${type}`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getOrganisationsAndTypes = (type: string): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>(resolve => {
      get<IOrganisation[]>(`${storeUrls.getOrganisationsAndTypes}${type}`).then(res => {
        resolve(res.data)
      })
    })
  }

  getOrganisationByCode = (
    code: string,
    excludeSites?: boolean
  ): Promise<IOrganisation | undefined> => {
    return new Promise<IOrganisation | undefined>((resolve, reject) => {
      const excludeSiteQueryParameter = excludeSites ? `&excludeSites=${excludeSites}` : ""
      const path = `${storeUrls.getAllOrganisationByCodeAndName}?name=${code}${excludeSiteQueryParameter}`
      get<IOrganisation[]>(path).then(res => {
        if (res.status === 200) {
          const result = res.data.find(x => x.code == code)
          resolve(result)
        }

        reject()
      })
    })
  }

  getAllOrganisationModelsByName = (
    name: string,
    excludeSites = true
  ): Promise<IOrganisationModel[] | undefined> => {
    return new Promise((resolve, reject) => {
      this.getAllOrganisationsByName(name, excludeSites)
        .then(organisations => {
          const orgs: IOrganisationModel[] = organisations.map(org => {
            return {
              label: `${org.name} (${org.code})`,
              value: org.code,
              regionCodes: [],
            }
          })

          resolve(orgs)
        })
        .catch(reject)
    })
  }

  getAllOrganisationsByName = (name: string, excludeSites = true): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>((resolve, reject) => {
      const excludeSiteQueryParameter = excludeSites ? `&excludeSites=${excludeSites}` : ""
      get<IOrganisation[]>(
        `${storeUrls.getAllOrganisationByCodeAndName}?name=${name}${excludeSiteQueryParameter}`
      ).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getOfficeRegionsForOrganisation = (code: string): Promise<string[]> => {
    return new Promise<string[]>(resolve => {
      get<string[]>(
        `${controllerPrefix}regionsForOrganisation/getRegionsForOrganisation?organisationCode=${code}`
      ).then(res => resolve(res.data))
    })
  }

  getSubOrganisations = (type: string): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>(resolve => {
      get<IOrganisation[]>(`${controllerPrefix}${type}`).then(res => resolve(res.data))
    })
  }

  getSubOrganisationForTypeWithSearchTerm = (
    type: string,
    searchTerm: string
  ): Promise<IOrganisation[]> => {
    return new Promise<IOrganisation[]>((resolve, reject) => {
      get<IOrganisation[]>(
        `${controllerPrefix}nameTypeSearch?type=${encodeURI(type)}&searchTerm=${encodeURI(
          searchTerm
        )}`
      )
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            resolve(res.data)
          }

          reject()
        })
        .catch(error => reject(error))
    })
  }
}

export default new OrganisationStore()
