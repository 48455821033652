import React from "react"

const UnreadNotificationIcon = () => {
  return (
    <svg
      data-testid="unread-notification-icon"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.7912" cy="4.20868" r="4.20868" fill="#005EB8" />
    </svg>
  )
}

export default UnreadNotificationIcon
