import { AbstractValidator } from "../../../../../global/abstract/validation"
import { IOrganisationGroup } from "../../../../../global/interfaces/organisation/interfaces"
import { OrganisationGroupValidationFailureCode } from "../enums"
import { failureMessages } from "../messages"

class AddToGroupListValidator extends AbstractValidator<IOrganisationGroup> {
  constructor() {
    super()

    this.ruleFor(x => x.shortName)
      .withAttributes(x => x.trim().length > 0)
      .withFailureCode(OrganisationGroupValidationFailureCode.ShortName)
      .withFailureMessage(failureMessages[OrganisationGroupValidationFailureCode.ShortName])

    this.ruleFor(x => x.description)
      .withAttributes(x => x.trim().length > 0)
      .withFailureCode(OrganisationGroupValidationFailureCode.Description)
      .withFailureMessage(failureMessages[OrganisationGroupValidationFailureCode.Description])
  }
}

export default AddToGroupListValidator
