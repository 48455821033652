import React from "react"
import { MessageKey } from "../../global/enums/messageKeys/enums"
import { useMessageKeys } from "../../global/hooks"
import { Helmet } from "react-helmet"

const RegistrationResultView = props => {
  const { result, email, appName } = props

  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  const Title = () => {
    if (result === "Approved") {
      if (appName) {
        return (
          <h1 className="registration-form__header">Thank you for registering for {appName}</h1>
        )
      } else {
        return (
          <h1 className="registration-form__header">
            Thank you for registering to access NHS England applications
          </h1>
        )
      }
    } else if (result === "Pending") {
      return (
        <h1 className="registration-form__header">
          Thank you for submitting your registration request
        </h1>
      )
    } else {
      return (
        <h1 className="registration-form__header">Your registration has not been successful</h1>
      )
    }
  }

  const Subtitle = () => {
    if (result === "Approved") {
      if (appName) {
        return (
          <>
            <p className="registration-form__paragraph">
              You must activate your account before you can access {appName}.
            </p>
            <p className="registration-form__paragraph">
              Your new account also provides access to NHS England applications, where you will find
              other useful applications and dashboards.
            </p>
          </>
        )
      } else {
        return (
          <p className="registration-form__paragraph">
            You must activate your account before you can access the information.
          </p>
        )
      }
    } else if (result === "Pending") {
      if (appName) {
        return (
          <p className="registration-form__paragraph">
            Your registration request has been submitted to {appName}.
          </p>
        )
      } else {
        return (
          <p className="registration-form__paragraph">
            Your registration request has been submitted to access NHS England applications.
          </p>
        )
      }
    } else {
      if (appName) {
        return (
          <p className="registration-form__paragraph">
            Unfortunately you do not qualify for access to {appName}. This is because you do not
            work for the NHS or one of its approved partners
          </p>
        )
      } else {
        return (
          <p className="registration-form__paragraph">
            Unfortunately you do not qualify for access to NHS England applications. This is because
            you do not work for the NHS or one of its approved partners.
          </p>
        )
      }
    }
  }

  const NextStep = () => {
    if (result === "Approved") {
      if (appName) {
        return (
          <p className="registration-form__paragraph">
            We have sent an activation email to {email}. Click 'Activate my account' in the email to
            begin using {appName} and NHS England applications.
          </p>
        )
      } else {
        return (
          <p className="registration-form__paragraph">
            We have sent an activation email to {email}. Click 'Activate my account' in the email to
            begin using the service.
          </p>
        )
      }
    } else if (result === "Pending") {
      return (
        <p className="registration-form__paragraph">
          You will receive an email in the next two working days with the outcome of your
          application.
        </p>
      )
    } else {
      if (appName) {
        return (
          <p className="registration-form__paragraph">
            If you believe you should have access to {appName}, please contact{" "}
            <a href={itOps}>{serviceDesk}</a>
          </p>
        )
      } else {
        return (
          <p className="registration-form__paragraph">
            If you believe you should have access to NHS England applications, please contact{" "}
            <a href={itOps}>{serviceDesk}</a>
          </p>
        )
      }
    }
  }
  return (
    <>
      <Helmet>
        <title>Registration Requested | NHS England applications</title>
      </Helmet>
      <Title />
      <Subtitle />
      <h2 className="registration-form__sub-header">What happens next?</h2>
      <NextStep />
    </>
  )
}

export default RegistrationResultView
