import axios, { CancelTokenSource } from "axios"
import { observer } from "mobx-react"
import { BodyText } from "nhsuk-react-components"
import React, { useContext, useEffect, useState, useRef } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { Filters } from ".."
import { withWrapper } from "../../../global/components/HOC"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { IIndexItem, ISearchResult } from "../../../global/interfaces/searchResults/interfaces"
import { SearchResultsContainer, SearchResultsFooter, SearchResultsHeader } from "./components"
import { ISearchResultsPagination } from "./interfaces"
import "./styles.scss"

let source: CancelTokenSource

const SearchResults = observer(() => {
  const { search } = useLocation()

  const { searchStore } = useStores()

  const params = new URLSearchParams(search)
  const searchText = params.get("SearchText")
  const page = parseInt(params.get("Page") || "0")
  const navigate = useNavigate()
  const { wrapWithLoader } = useContext(LoaderContext)
  const [results, setResults] = useState<ISearchResult<IIndexItem>[] | null>(null)
  const [facets, setFacets] = useState<Record<string, Record<string, number>>>({})
  const [filters, setFilters] = useState<Record<string, boolean>>({})
  const [total, setTotal] = useState<number | null>(null)
  const [dataUpdated, setDataUpdated] = useState<boolean>(false)
  const [viewFilters, setViewFilters] = useState<boolean>(false)
  const [pagination, setPagination] = useState<ISearchResultsPagination>({
    pageNumber: page,
    nextPage: page + 1,
    previousPage: page - 1,
  })

  const mounted = useRef<boolean>(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      if (source) {
        source.cancel()
      }
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const fetchResults = () => {
      if (source) {
        source.cancel()
      }

      source = axios.CancelToken.source()

      wrapWithLoader(() => {
        searchStore
          .getSearchResults(search, source)
          .then(res => {
            const { results, facets, totalCount } = res

            setResults(results)
            setFacets(facets)
            setTotal(totalCount)

            const initialFilters: Record<string, boolean> = {}
            for (const filter of new URLSearchParams(search).getAll("Filters")) {
              initialFilters[filter] = true
            }

            setFilters(initialFilters)
            setDataUpdated(false)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              setDataUpdated(false)
            } else {
              throw error
            }
          })
      })
    }

    if (results === null || pagination.pageNumber !== page || dataUpdated) {
      fetchResults()
    }

    return () => {
      if (mounted.current) {
        setDataUpdated(false)
      }
    }
  }, [results, pagination.pageNumber, page, search, dataUpdated])

  useEffect(() => {
    if (pagination.pageNumber !== page) {
      setPagination({
        pageNumber: page,
        nextPage: page + 1,
        previousPage: page - 1,
      })
    }
  }, [pagination.pageNumber, page])

  const clearFilters = (filters: Record<string, boolean>) => {
    const clearedFilters: Record<string, boolean> = {}
    let stringToReplace = ""

    for (const filter of Object.keys(filters)) {
      const formattedFilter = `&Filters=${filter}`.replace(/ /g, "%20").replace(/'/g, "%27")

      stringToReplace += formattedFilter
      clearedFilters[`${filter}`] = false
    }

    const changeToFirstPage = `/search${search.replace(stringToReplace, "")}`.replace(
      `&Page=${pagination.pageNumber}`,
      "&Page=1"
    )

    navigate(changeToFirstPage)
    setFilters(clearedFilters)
    setDataUpdated(true)
  }

  const selectFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    const filter = `&Filters=${target.value}`
    const formattedFilter = filter.replace(/ /g, "%20").replace(/'/g, "%27")

    const removed = search.includes(formattedFilter) || search.includes(filter)
    const route = removed
      ? `/search${search.replace(formattedFilter, "")}`.replace(
          `&Page=${pagination.pageNumber}`,
          "&Page=1"
        )
      : `/search${search}${formattedFilter}`.replace(`&Page=${pagination.pageNumber}`, "&Page=1")

    setFilters({
      ...filters,
      [`${target.value}`]: !removed,
    })

    navigate(route)

    setDataUpdated(true)
  }

  return (
    <div id="search-results" className="search-results__panel">
      <div className="search-results__container">
        {total !== null && total > 0
          ? facets && (
              <Filters
                viewFilters={viewFilters}
                facets={facets}
                selectedFilters={filters}
                clearFilters={clearFilters}
                onClose={() => setViewFilters(false)}
                selectToggle={selectFilter}
              />
            )
          : ""}
        <section className="search-results">
          <h1 className="search-results__title">Search results</h1>
          {total === 0 ? (
            <>
              <BodyText>
                We found 0 results for <span className="search-results__bold">{searchText}</span>
              </BodyText>
              <BodyText>You could try:</BodyText>
              <ul>
                <li>Checking your spelling</li>
                <li>Searching again using another word</li>
                <li className="search-results__suggest">
                  Try looking for {searchText} in{" "}
                  <NavLink to={Routes.AToZLandingPage}>NHS England applications A-Z</NavLink>.
                </li>
              </ul>
            </>
          ) : (
            results &&
            results.length > 0 && (
              <>
                <SearchResultsHeader
                  total={total}
                  searchText={searchText}
                  params={params}
                  page={page}
                  pagination={pagination}
                  setViewFilters={setViewFilters}
                  setDataUpdated={setDataUpdated}
                />
                <SearchResultsContainer results={results} setDataUpdated={setDataUpdated} />
                <SearchResultsFooter pagination={pagination} total={total} />
              </>
            )
          )}
        </section>
      </div>
    </div>
  )
})

export default withWrapper(SearchResults)
