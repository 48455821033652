export enum ProductEditView {
  Tasks = "tasks",
  Product = "product-information",
  Admin = "admin-information",
  Contact = "contact-information",
  AccessPermissions = "access-permissions",
  ApprovalQuestions = "approval-questions",
  TableauAccessGroups = "tableau-access-groups",
  TableauHeader = "tableau-header",
  CoreProductInformation = "about-the-product's-listing",
  Publish = "publish",
}
