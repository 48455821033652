import React from "react"

const FeedbackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
      <path
        fill="#F0F4F5"
        d="M25.667 12.459c0 .382-.12.688-.36.956-.238.306-.557.459-.916.535v7.958c-2.185-1.08-4.103-2.773-5.546-3.942-1.992-1.07-4.623-1.683-6.696-1.836v7.203h-5.7V16.13H3.5v-5.507-1.836h8.29c2.272 0 4.505-.535 6.697-1.644C19.45 6.68 23.6 3.892 24.39 3.5v7.467c.359.077.678.268.917.536.24.306.359.611.359.956zM5.413 14.294h6.377v-3.671H5.413v3.671zm2.837 7.614h2.185V16.13H8.25v5.778zm14.228-3.292V6.301c-1.555 1.186-2.277 2.104-3.712 2.754-1.674.765-3.348 1.262-5.062 1.453v3.901c1.714.23 3.388.689 5.062 1.454 1.435.688 2.158 1.606 3.712 2.753z"
      />
    </svg>
  )
}

export default FeedbackIcon
