import { IBreadcrumb } from "../../interfaces/withWrapper"

export const createBreadcrumb = (
  link: string,
  name: string,
  onClick?: () => void
): IBreadcrumb => ({ link, name, onClick })

export const Home = createBreadcrumb("/products", "Home")

export const AppAdmin = createBreadcrumb("/appadmin", "Admin")

export const Profile = createBreadcrumb("/myaccount", "Profile")

export const OpsAdmin = createBreadcrumb("/admin", "Ops admin")

export const Invite = createBreadcrumb("/invite", "Invite users")

export const AccessibilityPolicy = createBreadcrumb("/accessibility", "Accessibility")

export const CPAccessibilityPolicy = createBreadcrumb(
  "/cp-accessibility",
  "Core Platform Accessibility"
)

export const CookiePolicy = createBreadcrumb("/cookies", "Cookies")

export const CPCookiePolicy = createBreadcrumb("/cp-cookies", "Core Platform Cookies")

export const TermsAndConditions = createBreadcrumb("/terms", "Terms and conditions")

export const Privacy = createBreadcrumb("/privacy", "Privacy")

export const AdminManagementCrumb = createBreadcrumb("/appadmin", "Admin")
