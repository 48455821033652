import { makeAutoObservable } from "mobx"
import { IAppApprovalQuestion } from "../global/interfaces/appApprovalQuestion/interfaces"
import { IStepTwoRequest } from "../global/interfaces/stepTwo/interfaces"
import { ErrorsCollection } from "../global/types"
import { get, postApi } from "../global/utils/api"

export interface IStepTwoStore {
  stepTwoRequestData: IStepTwoRequest
  stepTwoQuestionData: IAppApprovalQuestion
  errors: ErrorsCollection
  getStepTwoQuestionsToAsk: (productId: string) => Promise<IAppApprovalQuestion>
  requestAppAccessWithStepTwo: (productId: string) => Promise<void>
}

export class StepTwoStore implements IStepTwoStore {
  stepTwoRequestData: IStepTwoRequest = {
    answers: [],
    customTermsAccepted: null,
  }

  stepTwoQuestionData: IAppApprovalQuestion = {
    id: null,
    applicationId: "",
    additionalInformationRequired: false,
    selectedQuestions: [],
    complete: false,
    productSpecificTerms: false,
    productSpecificTermsLink: "",
    allowMultipleRegions: false,
  }

  errors = new ErrorsCollection()

  constructor() {
    makeAutoObservable(this)
  }

  getStepTwoQuestionsToAsk = (productId: string): Promise<IAppApprovalQuestion> => {
    return new Promise<IAppApprovalQuestion>((resolve, reject) => {
      get<IAppApprovalQuestion>(`/approvalquestion?applicationId=${productId}`)
        .then(res => {
          if (res.status === 200) {
            this.stepTwoQuestionData = { ...res.data }
            resolve(res.data)
          }
          reject()
        })
        .catch(err => reject(err))
    })
  }

  requestAppAccessWithStepTwo = (productId: string): Promise<void> => {
    return new Promise((res, rej) => {
      postApi(`/application/request?id=${productId}`, this.stepTwoRequestData)
        .then(() => res())
        .catch(err => {
          if (err.response.status === 422) {
            const localErrors = err.response.data
            const newErrors = new ErrorsCollection()

            Object.keys(localErrors).forEach(key => {
              const msg = localErrors[key]
              const val = {
                key: key,
                value: [
                  {
                    fieldError: msg,
                    summaryError: msg,
                  },
                ],
              }

              newErrors.add(val)
            })

            this.errors = newErrors
            res()
          } else {
            rej()
          }
        })
    })
  }
}

export default new StepTwoStore()
