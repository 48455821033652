import React from "react"
import { observer } from "mobx-react"
import { useStores } from "../../../../global/hooks"
import { DownloadIcon, DownloadIconDisabledIcon, ShareIcon, ViewIcon } from "./assets"
import { StarSolidIcon, StarIcon } from "../../../../global/components/icons"
import ToolbarButton from "./toolbarButton"
import ViewsDropdown from "./viewsDropdown"
import { TableauViz } from "@tableau/embedding-api"

import "../styles.scss"

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
interface IRightContainer {
  onButtonClicked: (
    id: number,
    tableauVisualization: TableauViz,
    visualizationLoading: boolean
  ) => void
  viewsReference: React.RefObject<HTMLDivElement>
  showViewsDropdown: boolean
  viewsButtonRef: React.RefObject<HTMLDivElement>
  closeModal: () => void
}

const RightContainer = observer(
  ({
    onButtonClicked,
    viewsReference,
    showViewsDropdown,
    viewsButtonRef,
    closeModal,
  }: IRightContainer) => {
    const {
      tableauReportViewerStore: {
        toolbarButtons: { favourite, view, share, download },
        currentView,
        currentReport,
        visualizationLoading,
        tableauVisualization,
      },
    } = useStores()

    const customView = currentView === "" ? "Original" : currentView

    return (
      <div id="right-container" className="tableau-toolbar__right-container">
        <ToolbarButton
          text={favourite.text}
          disabled={visualizationLoading || !tableauVisualization}
          onClickFunction={() =>
            onButtonClicked(favourite.id, tableauVisualization!, visualizationLoading)
          }
        >
          {currentReport?.isUserFavourite ? <StarSolidIcon /> : <StarIcon />}
        </ToolbarButton>
        <ToolbarButton
          text={`${view.text}: ${customView}`}
          disabled={visualizationLoading || !tableauVisualization}
          reference={viewsButtonRef}
        >
          <ViewIcon />
          {showViewsDropdown && (
            <ViewsDropdown
              viewsReference={viewsReference}
              onButtonClicked={onButtonClicked}
              closeModal={closeModal}
            />
          )}
        </ToolbarButton>
        <ToolbarButton
          text={share.text}
          disabled={visualizationLoading || !tableauVisualization}
          onClickFunction={() =>
            onButtonClicked(share.id, tableauVisualization!, visualizationLoading)
          }
        >
          <ShareIcon />
        </ToolbarButton>
        <ToolbarButton
          text={download.text}
          disabled={!currentReport?.showDownload || visualizationLoading || !tableauVisualization}
          onClickFunction={() =>
            onButtonClicked(download.id, tableauVisualization!, visualizationLoading)
          }
        >
          {!currentReport?.showDownload ? <DownloadIconDisabledIcon /> : <DownloadIcon />}
        </ToolbarButton>
      </div>
    )
  }
)

export default RightContainer
