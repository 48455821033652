import { observer } from "mobx-react"
import { Checkboxes, Fieldset } from "nhsuk-react-components"
import React from "react"
import { useStores } from "../../../../../global/hooks"
import "./styles.scss"

interface SelectionOptionsProps extends React.HTMLProps<JSX.Element> {
  indent?: boolean
}

const SelectionOptions = observer(({ indent }: SelectionOptionsProps) => {
  const {
    tableauReportViewerConfigurationStore: {
      tableauReportViewerData: { workbook },
      toggleDownloadFormatOptions,
    },
  } = useStores()

  const addOptionToWorkBookDownLoadOptions = (event: React.FormEvent, option: string) => {
    event?.stopPropagation()
    toggleDownloadFormatOptions(option)
  }

  return (
    <Fieldset className={indent ? "selectionoptions__indent" : ""}>
      <Checkboxes className="selectionoptions__checkboxes">
        {workbook.availableDownloadFormats.map(option => {
          const checked = workbook.selectedDownloadFormats.includes(option)
          return (
            <Checkboxes.Box
              defaultChecked={checked}
              key={option}
              value={option}
              onChange={e => addOptionToWorkBookDownLoadOptions(e, option)}
            >
              {option}
            </Checkboxes.Box>
          )
        })}
      </Checkboxes>
    </Fieldset>
  )
})

export default SelectionOptions
