import React, { createContext } from "react"
import { RegistrationRootStore, IRegistrationRootStore } from "../stores"

const registrationStore = RegistrationRootStore

export const RegistrationStoreContext = createContext<IRegistrationRootStore>(registrationStore)

interface IRegistrationStoreProviderProps {
  children: React.ReactNode
}

const RegistrationStoreProvider = (props: IRegistrationStoreProviderProps) => {
  const { children } = props

  return (
    <RegistrationStoreContext.Provider value={registrationStore}>
      {children}
    </RegistrationStoreContext.Provider>
  )
}

export default RegistrationStoreProvider
