import React from "react"
import { IRegion } from "../../global/interfaces/region"
import Application from "../types/classes/application"
import { ACTION } from "../types/enums"
import { AppsConfiguration } from "./appsConfiguration"
import { MultiAppConfigurationHeaderSection } from "./multiAppConfigurationHeaderSection"
import "../styles.scss"

export const MultiAppConfiguration = ({
  applications,
  nhsRegions,
  handleApplicationChange,
}: {
  applications: Application[]
  nhsRegions: IRegion[]
  handleApplicationChange: (application: Application, action: ACTION) => void
}) => {
  const multiAppConfigurationHeaders = [
    "Name",
    "Change config ?",
    "Scope",
    "Regional (If applicable)",
    "Remove Access ?",
  ]

  return (
    <>
      <MultiAppConfigurationHeaderSection
        headers={multiAppConfigurationHeaders}
        applications={applications}
      />

      <AppsConfiguration
        applications={applications}
        nhsRegions={nhsRegions}
        handleApplicationChange={handleApplicationChange}
      />
    </>
  )
}
