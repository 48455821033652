import React from "react"
import { observer } from "mobx-react"
import { Label } from "nhsuk-react-components"

const ReadOnlyForm = observer(props => {
  const { admin, title, scopes } = props

  return (
    <>
      <div className="read-only-section">
        <Label className="section-title">{title}</Label>
        <div className="read-only-row">
          <div className="field-title">Name</div>
          <div>{(admin && admin.fullName) || "-"}</div>
        </div>
        <div className="read-only-row">
          <div className="field-title">Email address</div>
          <div className="readOnlyForm__email">{(admin && admin.email) || "-"}</div>
        </div>
        <div className="read-only-row">
          <div className="field-title">Scope of permission to grant access</div>
          <div className="field-value-container">
            <div>{(admin && admin.scope > -1 && scopes[admin.scope].scope) || "-"}</div>
            {admin && admin.selectedRegions && admin.selectedRegions.length > 0 && (
              <div className="unordered-list-container">
                <ul>
                  {admin.selectedRegions.map((region, idx) => (
                    <li key={idx}>{region}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="read-only-row">
          <div className="field-title">Permission to change product setup?</div>
          <div>{(admin && admin.canUpdateProduct ? "Yes" : "No") || "-"}</div>
        </div>
      </div>
    </>
  )
})

export default ReadOnlyForm
