import React, { useContext } from "react"
import { observer } from "mobx-react"
import { Button } from "nhsuk-react-components"
import { CloseIcon } from "../../../../global/components/icons"
import { LoaderContext } from "../../../../global/components/loaderProvider"
import { copyTextToClipboard } from "../../../../global/utils/clipboard"
import { useStores } from "../../../../global/hooks"
import { Workbook, CustomView } from "@tableau/embedding-api"
import "./styles.scss"

interface IShareModalContent {
  close: () => void
}

const ShareModalContent = observer(({ close }: IShareModalContent) => {
  const { wrapWithLoader } = useContext(LoaderContext)

  const {
    tableauReportViewerStore: {
      reportViewChanged,
      currentReport,
      currentView,
      tableauVisualization,
    },
    accessPermissionsStore: {
      accessPermissions: { automaticAccess, requestAccess, blackList },
    },
    profileStore: { user },
  } = useStores()

  const copyDashboardURL = async (workbook: Workbook) =>
    wrapWithLoader(async () => {
      if (!workbook || !user || !currentReport) return

      let finalUrl = `https://${window.location.host}/report/${currentReport.embedPathName}`
      if (currentView) {
        const fetchedCustomViews: CustomView[] = await workbook.getCustomViewsAsync()
        const currentCustomView = fetchedCustomViews.find(v => v.name === currentView)
        if (currentCustomView) {
          finalUrl += `?customViewUrl=${currentCustomView.url}&customViewName=${currentCustomView.name}`
        }
      } else if (reportViewChanged) {
        const newCustomViewName = `${user.firstName}${user.lastName}${Date.now()}`
        await workbook.saveCustomViewAsync(newCustomViewName)

        const customViews = await workbook.getCustomViewsAsync()
        const fetchedView = customViews.find(v => v.name === newCustomViewName)
        if (fetchedView) {
          finalUrl += `?customViewUrl=${fetchedView.url}&customViewName=${fetchedView.name}`
        } else {
          alert("Failure to retrieve saved custom view")
        }
      }

      await copyTextToClipboard(encodeURI(finalUrl)).then(() => close())
    })

  if (!tableauVisualization || !user || !currentReport) return <></>

  return (
    <div className="modal-content">
      <span className="modal-title">Share dashboard view</span>
      <div className="modal-close-icon-container" onClick={close}>
        <CloseIcon />
      </div>
      <p>Share this view of the {currentReport?.title}.</p>
      <p>
        You can only share with colleagues from selected organisations. If they do not have access
        to the dashboard, they may need to request access before they can use it.
      </p>

      {(automaticAccess.length > 0 || requestAccess.length > 0) && (
        <div>
          <p>Users from the following organisations can access the dashboard:</p>
          <ul id="access-permissions-list">
            {automaticAccess.concat(requestAccess).map(a => (
              <li key={a.name}>{a.name}</li>
            ))}
          </ul>
        </div>
      )}

      {blackList.length > 0 && (
        <div>
          <p>
            In selected cases, users from the following organisations may also be able to get
            access:
          </p>
          <ul id="access-permissions-list">
            {blackList.map(b => (
              <li key={b.name}>{b.name}</li>
            ))}
          </ul>
        </div>
      )}

      <div id="input-container">
        <Button
          id="inline-input-button"
          onClick={() => copyDashboardURL(tableauVisualization.workbook)}
        >
          Copy link
        </Button>
      </div>
    </div>
  )
})

export default ShareModalContent
