import React from "react"
import { observer } from "mobx-react"
import { Select } from "nhsuk-react-components"
import { useStores } from "../../../../global/hooks"
import { SheetInfo } from "@tableau/embedding-api"

const TabDropdown = observer(() => {
  const {
    tableauReportViewerStore: {
      tableauVisualization,
      visualizationLoading,
      currentTab,
      setCurrentTab,
    },
  } = useStores()

  if (!tableauVisualization?.workbook || visualizationLoading) {
    return null
  }

  return (
    <Select
      id="tab-select"
      onChange={e => setCurrentTab(e.currentTarget.value)}
      className="tab-dropdown"
    >
      {tableauVisualization.workbook &&
        tableauVisualization.workbook.publishedSheetsInfo.map((sheetInfo: SheetInfo) => {
          const currentSheetInfoName = sheetInfo.name
          return (
            <Select.Option
              key={`select-tab-id-${currentSheetInfoName}`}
              value={currentSheetInfoName}
              name={currentSheetInfoName}
              default={currentSheetInfoName === currentTab}
              selected={currentSheetInfoName == currentTab}
            >
              {currentSheetInfoName}
            </Select.Option>
          )
        })}
    </Select>
  )
})

export default TabDropdown
