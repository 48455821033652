import { Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import TermsAndConditionsText from "../acceptTermsAndConditions/components/termsAndConditionsText/termsAndConditionsText"

import "./styles.scss"

const TermsAndConditions = () => {
  return (
    <Container className="terms" id="terms">
      <Col width="three-quarters">
        <Label isPageHeading>Terms and conditions</Label>
        <TermsAndConditionsText />
      </Col>
    </Container>
  )
}

export default withWrapper(TermsAndConditions)
