import { makeAutoObservable } from "mobx"
import {
  ICoreProductTopic,
  ICoreProductTopicWithTiles,
} from "../../global/interfaces/coreProductTopic"
import { get } from "../utils/api"

const storeURLS = {
  allTopics: "/coreproducttopic/",
  allTiles: "/coreproducttopic/tiles",
}

export interface ICoreProductTopicStore {
  getAllTopics: () => Promise<ICoreProductTopic[]>
  getAllTiles: () => Promise<ICoreProductTopicWithTiles[]>
}

class CoreProductTopicStore implements ICoreProductTopicStore {
  constructor() {
    makeAutoObservable(this)
  }
  getAllTopics = (): Promise<ICoreProductTopic[]> => {
    return new Promise<ICoreProductTopic[]>((resolve, reject) => {
      get<ICoreProductTopic[]>(storeURLS.allTopics).then(res => {
        if (res.status === 200) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getAllTiles = (): Promise<ICoreProductTopicWithTiles[]> => {
    return new Promise<ICoreProductTopicWithTiles[]>((resolve, reject) => {
      get<ICoreProductTopicWithTiles[]>(storeURLS.allTiles).then(res => {
        if (res.status === 200) {
          resolve(res.data)
        }

        reject()
      })
    })
  }
}

export default new CoreProductTopicStore()
