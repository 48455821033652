import Axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import _ from "lodash"
import { HttpStatusCode } from "../../enums/api"
import { getCookie } from "../cookies"
import { history } from "../history"

// Remove once all usages of non-generic functions are removed
/* eslint-disable @typescript-eslint/no-explicit-any */

const base = "/api"
const coreApiBase = `${process.env.REACT_APP_SEARCH_API}`

const checkPath = (path: string) => {
  path = path.replace(/[/]?api[/]/, "/")
  if (path.length !== 0 && !path.startsWith("/")) {
    path = `/${path}`
  }
  return path
}

Axios.interceptors.response.use(undefined, error => {
  if (error && error.response) {
    if (error.response.status === HttpStatusCode.Unauthorized) {
      window.location.href = "/api/auth/login"
    }

    if (
      error.response.status.toString().startsWith(5) ||
      error.response.status === HttpStatusCode.Forbidden
    ) {
      if (process.env.NODE_ENV === "production") {
        history.replace("/error")
      }
    }
  }

  return Promise.reject(error)
})

export const deleteApi = (
  path: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any, any>> => Axios.delete(getCorrectedPath(path), config)

export const get = <T>(
  path: string,
  axiosOpts = {},
  useCore: boolean | null = null
): Promise<AxiosResponse<T>> => Axios.get<T>(getCorrectedPath(path, useCore), axiosOpts)

export const post = <TBody, TResponse = any>(
  path: string,
  body: TBody,
  axiosOpts: AxiosRequestConfig = {},
  core: boolean | null = null
) =>
  Axios.post<TBody, TResponse>(getCorrectedPath(path, core), body, {
    ...axiosOpts,
    headers: {
      ...axiosOpts.headers,
      "X-CSRF-TOKEN": getCookie("CSRF-TOKEN"),
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("jwtcore")}`,
    },
  })

export const getApi = (
  path: string,
  axiosOpts = {},
  useCore: boolean | null = null
): Promise<AxiosResponse<any>> => Axios.get(getCorrectedPath(path, useCore), axiosOpts)

export const cachedGetApi = _.memoize(
  <T>(path: string, axiosOpts: AxiosRequestConfig = {}, useCore: boolean | null = null) =>
    get<T>(path, axiosOpts, useCore)
)

export const postApi = (
  path: string,
  body: any,
  axiosOpts: AxiosRequestConfig = {},
  useCore: boolean | null = null
) =>
  Axios.post(getCorrectedPath(path, useCore), body, {
    ...axiosOpts,
    headers: {
      ...axiosOpts.headers,
      "X-CSRF-TOKEN": getCookie("CSRF-TOKEN"),
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("jwtcore")}`,
    },
  })

const getCorrectedPath = (path: string, core: boolean | null = null) =>
  (core ? coreApiBase : base) + checkPath(path)
