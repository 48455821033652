import { UserProfileUpdateChanges } from "../global/enums/profile/enums"

export const hasAnExclusiveFlag = (
  enumeration: UserProfileUpdateChanges,
  exclusiveFlags: UserProfileUpdateChanges[]
): boolean => {
  for (const flag of exclusiveFlags) {
    if (enumeration === flag) {
      return true
    }
  }

  return false
}

export const anyCombinationOfFlagsExclusive = (
  enumeration: UserProfileUpdateChanges,
  combinationFlags: UserProfileUpdateChanges[]
): boolean => {
  let result = enumeration

  for (const flag of combinationFlags) {
    if (hasFlag(result, flag)) {
      result ^= flag
    }
  }

  return result === 0
}

export const hasFlag = (enumeration: UserProfileUpdateChanges, flag: UserProfileUpdateChanges) =>
  (enumeration & flag) > 0

export const hasAnyFlag = (
  enumeration: UserProfileUpdateChanges,
  ...flags: UserProfileUpdateChanges[]
) => {
  for (const flag of flags) {
    if (hasFlag(enumeration, flag)) {
      return true
    }
  }

  return false
}
