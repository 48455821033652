import { observer } from "mobx-react"
import { Col, Label, Row } from "nhsuk-react-components"
import React from "react"
import { useStores } from "../../../../../global/hooks"
import { IProfileChangesShape } from "../../../../../global/stores/profileStore"
import "./styles.scss"

interface IDifferenceRowsProps {
  initialProfileData?: IProfileChangesShape
  profileUpdateData: IProfileChangesShape
  showInitialDetails: boolean
}

const DifferenceRows = observer((props: IDifferenceRowsProps) => {
  const { initialProfileData, profileUpdateData, showInitialDetails } = props
  const { profileStore } = useStores()

  const { getDisplayValue, getDisplayName } = profileStore

  return (
    <>
      {Object.entries(profileUpdateData).map(validEntry => {
        const key = validEntry[0]
        return (
          <div className="difference-rows" key={`previous-details-${key}`}>
            <Row>
              <Col width="one-third">
                <Label className="property-label">{getDisplayName(key)}</Label>
              </Col>
              <Col width="one-third">
                <Label className="property-value-label">
                  {getDisplayValue(
                    key,
                    showInitialDetails && initialProfileData
                      ? initialProfileData
                      : profileUpdateData
                  )}
                </Label>
              </Col>
              <Col width="one-third" />
            </Row>
          </div>
        )
      })}
    </>
  )
})

export default DifferenceRows
