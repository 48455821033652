import React from "react"

const BinIcon = () => {
  return (
    <svg
      data-testid="bin-icon"
      className="bin-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7775 2.361V3.8885H2.5V2.361H6.86167C7.54917 2.361 8.1075 1.521 8.1075 0.833496H12.1633C12.1633 1.521 12.7208 2.361 13.4083 2.361H17.7775ZM14.7225 16.111C14.7225 16.531 14.3783 16.8752 13.9583 16.8752C13.5383 16.8752 13.1942 16.531 13.1942 16.111V8.47183C13.1942 8.05183 13.5383 7.7085 13.9583 7.7085C14.3783 7.7085 14.7225 8.05183 14.7225 8.47183V16.111ZM10.9025 16.111C10.9025 16.531 10.5592 16.8752 10.1383 16.8752C9.71917 16.8752 9.375 16.531 9.375 16.111V8.47183C9.375 8.05183 9.71917 7.7085 10.1383 7.7085C10.5592 7.7085 10.9025 8.05183 10.9025 8.47183V16.111ZM7.08333 16.111C7.08333 16.531 6.73917 16.8752 6.31917 16.8752C5.89917 16.8752 5.555 16.531 5.555 16.111V8.47183C5.555 8.05183 5.89917 7.7085 6.31917 7.7085C6.73917 7.7085 7.08333 8.05183 7.08333 8.47183V16.111ZM3.26333 19.1668H17.0133V5.41683H3.26333V19.1668Z"
        fill="#425563"
      />
    </svg>
  )
}

export default BinIcon
