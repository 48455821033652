import { observer } from "mobx-react"
import React from "react"
import { Link } from "react-router-dom"
import { Routes } from "../../../../../../../global/enums"
import "./styles.scss"

const BackToYourAccountLink = observer(() => {
  return (
    <Link to={Routes.ProfileUrl} className="back-link">
      Back to your account
    </Link>
  )
})

export default BackToYourAccountLink
