import { useEffect, useState } from "react"
import MessageKeyStore from "../stores/messageKeyStore"

export default function useMessageKeys(...keys: number[]): (string | undefined)[] {
  const [strings, setStrings] = useState<(string | undefined)[]>([])

  useEffect(() => {
    let mounted = true

    async function fetchStrings() {
      const fetchedStrings = await MessageKeyStore.getKeyValues(...keys)
      if (mounted) {
        setStrings(fetchedStrings)
      }
    }

    fetchStrings()

    return () => {
      mounted = false
    }
  }, [])

  return strings
}
