import { Button } from "nhsuk-react-components"
import React, { useState } from "react"
import { ReactComponent as OpenWindowIcon } from "../../icons/openwindow.svg"
import TermsModal from "./components/termsModal/TermsModal"
import { get } from "../global/utils/api"

import "./styles.scss"

interface AcceptTermsAndConditionsProps {
  redirectUrl: string
}

const AcceptTermsAndConditions: React.FC<AcceptTermsAndConditionsProps> = ({ redirectUrl }) => {
  const [showModal, setShowModal] = useState(false)

  const handleViewTermsClick = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSubmit = () => {
    get<boolean>(`/user/acceptterms`).then(() => {
      window.location.href = redirectUrl
    })
  }

  return (
    <main>
      <section className="accept-terms-page">
        <article className="accept-terms-page__intro">
          <h1 className="accept-terms__title">
            Please accept the NHS England applications terms and conditions
          </h1>
          <div>
            <p className="accept-terms-page__intro-text">
              NHS England applications is operated by NHS England.
            </p>
            <p className="accept-terms-page__intro-text">
              Restrictions apply to the use of data in the Portal. By using this Portal, you confirm
              that you accept the terms and conditions and that you agree to comply with them. You
              also agree to the use of cookies to ensure your personal preferences are saved for
              future sessions.
            </p>
            <p className="accept-terms-page__intro-text">
              If you do not agree to these terms and conditions, you must not use the Portal. Please
              follow the link to read and make sure you understand these terms and if you agree.,
              click the continue button to enter.
            </p>
            <p className="accept-terms-page__intro-text">
              We may amend these terms and conditions from time to time. If we do, you will be asked
              to review them and provide your acceptance again.
            </p>
          </div>
          <div className="accept-terms-link-panel">
            <p className="accept-terms-viewterms-text">
              <button className="accept-terms-link-button-text" onClick={handleViewTermsClick}>
                View Terms and Conditions
              </button>
            </p>
            <OpenWindowIcon className="accept-terms-link__icon" onClick={handleViewTermsClick} />
          </div>
          <br />
          <div className="accept-terms-link-panel">
            <p className="accept-terms-link-text">
              <strong>
                By clicking on this button you are agreeing to the Terms and Conditions
              </strong>
            </p>
            <Button className="accept-terms-submit" onClick={handleSubmit}>
              Accept and continue
            </Button>
          </div>
        </article>
      </section>

      <TermsModal show={showModal} handleClose={handleCloseModal} />
    </main>
  )
}

export default AcceptTermsAndConditions
