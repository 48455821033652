import { Button, ErrorSummary, Label, BodyText } from "nhsuk-react-components"
import React from "react"
import { SetPassword, SetSecurityQuestion } from "../"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { ISecurityPasswordForm } from "../../../global/interfaces/security"
import useRegistrationHook from "../../../global/hooks/useRegistration"
import { ErrorsCollection } from "../../../global/types"
import { Routes } from "../../../global/enums"

interface ISecurityViewProps {
  form: ISecurityPasswordForm
  error: ErrorsCollection
  onChange: (e: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => void
  save: () => void
}

const SecurityView = (props: ISecurityViewProps) => {
  const { form, error, onChange, save } = props
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const registrationToken = form.registrationToken
  const existsError = error.getFirstFieldError("registrationToken") !== ""
  const { tokenExists, existCheckCompleted } = useRegistrationHook({
    registrationToken,
    existsError,
  })

  if (existsError) {
    error.removeAll()
  }

  const handleSubmit = async () => await wrapWithLoader(() => save())

  if (!existCheckCompleted) {
    return null
  }

  return (
    <>
      {error.length > 0 && (
        <ErrorSummary role="alert" tabIndex={-1} style={{ marginTop: "2.5rem" }}>
          <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
          {error.values.map(item => {
            const { key, value } = item

            return (
              <div key={key} style={{ listStyle: "none" }}>
                <ErrorSummary.Item href={`#${key}`}>{value[0].summaryError}</ErrorSummary.Item>
              </div>
            )
          })}
        </ErrorSummary>
      )}
      <Label className="complete-heading" isPageHeading>
        Set your password and security question
      </Label>
      <div id="passwordSecurityForm">
        <SetPassword form={form} error={error} onChange={onChange} isUpdate={false} />

        <SetSecurityQuestion form={form} error={error} onChange={onChange} isUpdate={false} />

        <div id="security-button-control-panel">
          <Button role="button" id="complete" onClick={handleSubmit}>
            Complete registration
          </Button>
        </div>

        {!tokenExists && (
          <div>
            <BodyText className="security-already-registered-warning">
              Your account is already active, please{" "}
              <a id="loginLink" href={Routes.AToZLandingPage}>
                Sign in here
              </a>
            </BodyText>
            <br />
          </div>
        )}
      </div>
    </>
  )
}

export default SecurityView
