import { Label } from "nhsuk-react-components"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { LoaderContext } from "../global/components/loaderProvider"
import { withTermsCheck, withWrapper } from "../global/components/HOC"
import { useStores } from "../global/hooks"
import { FilterIcon, GearIcon } from "../global/components/icons"
import { NotificationContext } from "../global/context"
import { Routes } from "../global/enums"
import { INotificationEvent } from "../global/interfaces/notification"
import { EditModeControls, FilterModeControls, NotificationEvent, Spinner } from "./components"
import "./styles.scss"

const NotificationsPage = () => {
  const {
    serverSettingsStore: {
      serverSettings: { notificationRetentionPeriod },
      getServerSettings,
    },
  } = useStores()

  const [editMode, setEditMode] = useState<boolean>(false)
  const [filtering, setFiltering] = useState<boolean>(false)
  const [filter, setFilter] = useState<(notificationEvent: INotificationEvent) => boolean>(
    () => (_: INotificationEvent) => true
  )
  const { wrapWithLoader } = useContext(LoaderContext)

  useEffect(() => {
    wrapWithLoader(async () => {
      await getServerSettings()
    })
  }, [])

  const scrollTimeout = useRef<NodeJS.Timeout>()

  const {
    notificationEvents,
    allUnReadNotificationsCount,
    selectedNotificationEventIds,
    onLoadNotificationEventsRequested,
    onDeselectAllNotificationEvents,
    onSelectAllNotificationEvents,
    loadingMoreEntities,
    onNotificationEventsDeleteRequested,
    onMarkNotificationEventsAsRead,
  } = useContext(NotificationContext)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }

      const scrollTop = document.documentElement.scrollTop
      const scrollHeight = document.documentElement.scrollHeight
      const clientHeight = window.innerHeight

      scrollTimeout.current = setTimeout(() => {
        if (scrollTop + clientHeight >= scrollHeight) {
          onLoadNotificationEventsRequested()
        }
      }, 500)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [onLoadNotificationEventsRequested])

  const FilteredNotificationEvents = useMemo(() => {
    return notificationEvents.filter(filter)
  }, [notificationEvents, filter])

  const onToggleAll = () => {
    if (selectedNotificationEventIds.length === notificationEvents.length) {
      onDeselectAllNotificationEvents()
    } else {
      onSelectAllNotificationEvents()
    }
  }

  const onCancelEditMode = () => {
    onDeselectAllNotificationEvents()
    setEditMode(false)
  }

  const onFilteringEnabled = () => {
    if (editMode) {
      onCancelEditMode()
    }

    setFiltering(true)
  }

  const onCancelFilterMode = () => {
    setFiltering(false)
    setFilter(() => (_: INotificationEvent) => true)
  }

  const onDeleteAllFiltered = () =>
    onNotificationEventsDeleteRequested(FilteredNotificationEvents.map(ne => ne.id))

  const onMarkAllFilteredAsRead = () =>
    onMarkNotificationEventsAsRead(FilteredNotificationEvents.map(ne => ne.id))

  return (
    <div className="notifications-page">
      <div className="notifications-page__heading-container">
        <div className="notifications-page__heading-container__title-container">
          <Label
            isPageHeading
            className="notifications-page__heading-container__title-container__heading no-select"
          >
            Notifications
          </Label>
          <span
            data-testid="notifications-page__heading-container__title-container__heading__count"
            className="notifications-page__heading-container__title-container__heading__count no-select"
          >
            ({allUnReadNotificationsCount})
          </span>
        </div>
        <div className="notifications-page__heading-container__controls-container">
          {notificationEvents.length > 0 && !filtering && (
            <FilterIcon onClick={onFilteringEnabled} />
          )}
          <GearIcon onClick={() => (window.location.href = Routes.NotificationPreferences)} />
        </div>
      </div>
      <span
        data-testid="notifications-page__sub-heading"
        className="notifications-page__sub-heading"
      >
        Click to see more details. Notifications older than {notificationRetentionPeriod} days are
        automatically deleted.
      </span>
      <div className="notifications-page__header-controls">
        {filtering && (
          <FilterModeControls
            onCancelFilterMode={onCancelFilterMode}
            setFilter={setFilter}
            onDeleteAll={onDeleteAllFiltered}
            onMarkAllAsRead={onMarkAllFilteredAsRead}
          />
        )}
        {editMode && (
          <EditModeControls onToggleAll={onToggleAll} onCancelEditMode={onCancelEditMode} />
        )}
        {!editMode && !filtering && notificationEvents.length > 0 && (
          <button
            data-testid="notifications-page__header-controls__button__edit-mode"
            className="notifications-page__header-controls__button"
            onClick={() => setEditMode(true)}
          >
            Edit
          </button>
        )}
      </div>
      {notificationEvents.length === 0 && !loadingMoreEntities && (
        <div className="notifications-page__no-notifications-container">
          <span
            data-testid="notifications-page__no-notifications-container__text"
            className="notifications-page__no-notifications-container__text"
          >
            You have no notifications
          </span>
        </div>
      )}
      {FilteredNotificationEvents.map(ne => (
        <NotificationEvent key={ne.id} event={ne} editMode={editMode} />
      ))}
      {loadingMoreEntities && <Spinner />}
    </div>
  )
}

export default withWrapper(withTermsCheck(NotificationsPage, Routes.Notifications))
