import React from "react"
import "./styles.scss"

interface ITextButtonProps {
  children: React.ReactNode
  className?: string
  id?: string
  disabled?: boolean
  onClick: (e: React.MouseEvent) => void
}

const TextButton = (props: ITextButtonProps) => {
  const classes = props.className ? `textbutton ${props.className}` : "textbutton"
  return (
    <button id={props.id} className={classes} onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default TextButton
