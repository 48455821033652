import React from "react"

const IconPlus = () => {
  return (
    <svg
      className="nhsuk-icon nhsuk-icon__plus"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="32"
      aria-hidden="true"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M12 8v8M8 12h8"
      ></path>
    </svg>
  )
}

export default IconPlus
