/* eslint-disable react/prop-types */
import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import { BodyText, Button, Col, ErrorSummary, Label, Radios, Select } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { ProductEditView } from "../../enums/enums"
import ShowGuidanceLink from "../../../global/components/showGuidanceLink"
import { useStores } from "../../../global/hooks"
import ProductViewRedirect from "../productViewRedirect"
import "./styles.scss"

const CoreProductInformationView = observer(() => {
  const { productStore, coreProductInformationStore } = useStores()
  const { coreProductInformation } = coreProductInformationStore.coreproductInformationData
  const { topics, coreProductCategories } = coreProductInformationStore
  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [errors, setErrors] = useState(
    coreProductInformationStore.coreproductInformationData.errors
  )
  const [savedCoreProductGrouping, setSavedCoreProductGrouping] = useState(
    coreProductCategories[coreProductInformation.coreProductGrouping]
  )
  const [isSearchable, setIsSearchable] = useState(coreProductInformation.searchable)
  const [displayTopics, setDisplayTopics] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState({
    topicName: "Not applicable to non Analytical products",
    id: 0,
  })
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [showDataCollectionsOption] = useState(false)
  const defaultSelectedTopic = "Select category"
  const informationAboutTheProductAreas = "information about the product areas"
  const [tab, setTab] = useState(defaultSelectedTopic)

  const mapBEFieldsToIds = {
    coreProductGrouping: "coreProductGrouping",
    selectTopics: "topics-select",
    searchable: "searchable",
    coreProductTopicId: "category-select",
  }

  useEffect(() => {
    coreProductInformationStore.getCoreProductInformationData(productStore.product.id)
    return () => coreProductInformationStore.resetState()
  }, [])

  useEffect(() => {
    if (topics.length !== 0 && coreProductInformation.coreProductTopicId !== null) {
      const topic = _.find(
        coreProductInformationStore.topics,
        x => coreProductInformation.coreProductTopicId == x.id
      )
      setTab(() => coreProductInformation.coreProductTopicId)
      if (topic !== null && topic !== undefined) {
        setSelectedTopic(topic)
      }
    }
    setDisplayTopics(() => coreProductInformation.coreProductGrouping === 1)
    setIsSearchable(coreProductInformation.searchable)
    const grouping = coreProductCategories[coreProductInformation.coreProductGrouping]
    setSavedCoreProductGrouping(() =>
      grouping === undefined ? "Not allocated location" : grouping
    )
  }, [topics, coreProductInformation])

  const handleGroupingChange = event => {
    const value = parseInt(event.target.value)
    resetTopic(value)
    coreProductInformation.coreProductGrouping = value
    toggleTopicQuestions(value)
  }

  const toggleTopicQuestions = value => {
    setDisplayTopics(() => value === 1)
  }

  const resetTopic = value => {
    if (value !== 1) {
      coreProductInformation.coreProductTopicId = null
    }
  }

  const handleTopicChange = event => {
    const selectedValue = event.target.value

    if (selectedValue === defaultSelectedTopic) {
      coreProductInformation.coreProductTopicId = null
      return
    }

    coreProductInformation.coreProductTopicId = event.target.value
  }

  const handleSearchableChange = event => {
    const value = event.target.value === "no" ? false : true
    setIsSearchable(value)
    coreProductInformation.searchable = value
  }

  const completeAndSave = () => {
    if (!validate()) {
      coreProductInformation.complete = false
      return
    }

    coreProductInformation.complete = true
    save()
  }

  const saveAndReturnToTaskList = () => {
    coreProductInformation.complete = false
    save()
  }

  const save = () => {
    coreProductInformationStore.saveCoreProductInformation().then(() => {
      productStore.setTaskModified(
        ProductEditView.CoreProductInformation,
        coreProductInformation.complete
      )
      setRedirectingToTaskList(true)
    })
  }

  const validate = () => {
    let allClear = true

    const newErrors = {
      coreProductGrouping: null,
      searchable: null,
      coreProductTopicId: null,
    }

    if (
      coreProductInformation.searchable === undefined ||
      coreProductInformation.searchable === null
    ) {
      newErrors.searchable = "Please select whether your product is searchable or not"
      allClear = false
    }

    if (
      coreProductInformation.coreProductGrouping === undefined ||
      coreProductInformation.coreProductGrouping === null
    ) {
      newErrors.coreProductGrouping = "Please select a grouping for your product"
      allClear = false
    }

    if (
      coreProductInformation.coreProductGrouping === 1 &&
      coreProductInformation.coreProductTopicId === null
    ) {
      newErrors.coreProductTopicId = "Please select a category for your product"
      allClear = false
    }

    setErrors(newErrors)

    return allClear
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <Col id="core-platform-setup" width="full" className="core-product-information-view">
      {isEditMode &&
        (errors.searchable || errors.coreProductGrouping || errors.coreProductTopicId) && (
          <ErrorSummary>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
            {errors.searchable && (
              <ErrorSummary.Item href={`#${mapBEFieldsToIds.searchable}`}>
                {errors.searchable}
              </ErrorSummary.Item>
            )}
            {errors.coreProductGrouping && (
              <ErrorSummary.Item href={`#${mapBEFieldsToIds.coreProductGrouping}`}>
                {errors.coreProductGrouping}
              </ErrorSummary.Item>
            )}
            {errors.coreProductTopicId && (
              <ErrorSummary.Item href={`#${mapBEFieldsToIds.coreProductTopicId}`}>
                {errors.coreProductTopicId}
              </ErrorSummary.Item>
            )}
          </ErrorSummary>
        )}

      <div className="core-product-information-view__page-heading">
        <Label isPageHeading className="core-product-information-view__heading">
          Listing your product among NHS England applications
        </Label>

        {!isEditMode && (
          <Link
            className="core-product-information-view__change-settings-button"
            id="change-settings-button"
            onClick={() => setIsEditMode(true)}
          >
            Change settings
          </Link>
        )}
      </div>
      {!isEditMode ? (
        <>
          <div className="core-product-information-view__readonly-row">
            <div className="core-product-information-view__title-text">Product location</div>
            <div>{savedCoreProductGrouping}</div>
          </div>
          <div className="core-product-information-view__readonly-row">
            <div className="core-product-information-view__title-text">Category</div>
            <div>{selectedTopic.topicName}</div>
          </div>

          <div className="core-product-information-view__readonly-row">
            <div className="core-product-information-view__title-text">
              Is this product searchable?
            </div>
            <div>{isSearchable ? "Yes" : "No"}</div>
          </div>
        </>
      ) : (
        <>
          <article className="core-product-information-view__content-wrapper">
            <Label
              htmlFor={mapBEFieldsToIds.coreProductGrouping}
              className="core-product-information-view__product-question"
            >
              Which of the following does your product fall within?
            </Label>
            <ShowGuidanceLink
              clasName="link"
              linkText={`more ${informationAboutTheProductAreas}`}
              optionalHideLinkText={informationAboutTheProductAreas}
            >
              <BodyText>
                The Analytics Hub is a collection of data tools and dashboards organised in themes.
                It makes products easier for users to discover.
              </BodyText>
              <BodyText>
                A standalone product is one that is either not a data tool, or is not structured in
                the way that makes it possible to integrate seamlessly with other data tools.
              </BodyText>
            </ShowGuidanceLink>
            <Radios
              id={mapBEFieldsToIds.coreProductGrouping}
              onChange={e => handleGroupingChange(e)}
            >
              <Radios.Radio
                id="core-select-analytical"
                value="1"
                checked={coreProductInformation.coreProductGrouping === 1}
              >
                Analytics Hub
              </Radios.Radio>

              {showDataCollectionsOption && (
                <>
                  <Radios.Radio
                    id="core-select-data-collections"
                    value="2"
                    checked={coreProductInformation.coreProductGrouping === 2}
                  >
                    Data collections
                  </Radios.Radio>
                </>
              )}

              <Radios.Radio
                id="core-select-stand-alone"
                value="0"
                checked={coreProductInformation.coreProductGrouping === 0}
              >
                It is a standalone product
              </Radios.Radio>
            </Radios>
          </article>
          {displayTopics && (
            <article className="core-product-information-view__content-wrapper">
              <Label
                htmlFor="category-select"
                className="core-product-information-view__product-question"
              >
                Which category does your product belong to?
              </Label>
              <Select
                className="core-product-information-view__select"
                hint="Select the primary category"
                id="category-select"
                onChange={e => handleTopicChange(e)}
              >
                <Select.Option
                  value={defaultSelectedTopic}
                  key="select-category-default"
                  disabled
                  selected={tab === defaultSelectedTopic}
                >
                  {defaultSelectedTopic}
                </Select.Option>
                {topics.map(topic => {
                  return (
                    <Select.Option key={topic.id} value={topic.id} selected={topic.id === tab}>
                      {topic.topicName}
                    </Select.Option>
                  )
                })}
              </Select>
            </article>
          )}
          <article className="core-product-information-view__content-wrapper">
            <Label
              htmlFor={mapBEFieldsToIds.searchable}
              className="core-product-information-view__product-question"
            >
              Should your product be searchable?
            </Label>
            <Radios
              hint="Your choice determines whether users of NHS England applications will be able to find your product by using the search tool."
              onChange={e => handleSearchableChange(e)}
              id={mapBEFieldsToIds.searchable}
            >
              <Radios.Radio
                id="core-product-searchable-yes"
                value="yes"
                checked={coreProductInformation.searchable === true}
              >
                Yes
              </Radios.Radio>
              <Radios.Radio
                id="core-product-searchable-No"
                value="no"
                checked={coreProductInformation.searchable === false}
              >
                No
              </Radios.Radio>
            </Radios>
          </article>
        </>
      )}
      {isEditMode &&
        (productStore.published ? (
          <article className="core-product-information-view__content-wrapper">
            <div id="published-save-control">
              <Label className="input-label">Publish changes</Label>
              <BodyText className="core-product-information-view__input-hint">
                By publishing you confirm that you are making these changes in line with business
                policies.
              </BodyText>
              <Button
                className="core-product-information-view__publish-button"
                id="publish-button"
                onClick={completeAndSave}
              >
                Save and publish
              </Button>
            </div>
          </article>
        ) : (
          <article className="core-product-information-view__content-wrapper">
            <div id="button-controls" className="core-product-information-view__button-controls">
              <Button
                className="core-product-information-view__publish-button"
                id="publish-button"
                onClick={completeAndSave}
              >
                Save and complete
              </Button>
              <Link
                className="core-product-information-view__return-link"
                id="return-link"
                onClick={saveAndReturnToTaskList}
              >
                Save and return to task list
              </Link>
            </div>
          </article>
        ))}
    </Col>
  )
})

export default CoreProductInformationView
