import React, { memo } from "react"
import FeedbackIcon from "../icons/feedbackIcon"
import "./styles.scss"

interface IAnaylyticalFooterProps {
  feedbackUrl?: string
  dashboardTitle?: string
  userSupportEmail?: string
}

const AnalyticalFooter: React.FC<IAnaylyticalFooterProps> = ({
  feedbackUrl,
  dashboardTitle,
  userSupportEmail,
}) => {
  return (
    <div className="analytical-footer">
      {dashboardTitle && (
        <div className="analytical-footer__dashboard-content">
          <p className="anayltical-footer__dashboard-title">{dashboardTitle}</p>
          <a className="analytical-footer__support-email" href={`mailto:${userSupportEmail}`}>
            Support
          </a>
        </div>
      )}
      {feedbackUrl && (
        <div className="analytical-footer__feedback-container">
          <FeedbackIcon />
          <div className="analytical-footer__feedback-link-container">
            <a href={feedbackUrl} className="analytical-footer__feedback-link">
              Give your feedback on analytical products
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(AnalyticalFooter)
