import { observer } from "mobx-react"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import {
  CrossIcon,
  MailIcon,
  ReloadIcon,
  TickIcon,
  ViewIcon,
} from "../../../global/components/icons"
import { LoaderContext } from "../../../global/components/loaderProvider"
import TextButton from "../../../global/components/textButton"
import { IProductInfo } from "../../../global/interfaces/productInfo/interfaces"
import { BulkInvitationJobState, BulkInvitationRecipientState } from "../../enums"
import { IBulkInvitationJob } from "../../interfaces"
import "./styles.scss"

type JobCardProps = {
  job: IBulkInvitationJob
  requeue: (jobId: string) => void
  refresh: (jobId: string) => void
  toggleModal: (job: IBulkInvitationJob) => void
}

const JobCard = observer((props: JobCardProps) => {
  const { job, refresh, requeue, toggleModal } = props

  const { wrapWithLoader } = useContext(LoaderContext)
  const [productInformation, setProductInformation] = useState<IProductInfo[]>([])
  const [loading, setLoading] = useState(true)

  const completed = job.recipients.filter(
    x => x.status === BulkInvitationRecipientState.Completed
  ).length
  const failed = job.recipients.filter(x => x.status === BulkInvitationRecipientState.Failed).length
  const date = Date.now()
  const completedOrFailed =
    job.status === BulkInvitationJobState.Completed || job.status === BulkInvitationJobState.Failed

  useEffect(() => {
    wrapWithLoader(async () => {
      setLoading(true)

      setProductInformation(job.applications)

      setLoading(false)
    })
  }, [job.applications])

  const resendInvites = (id: string) => {
    requeue(id)
    refresh(id)
  }

  return (
    <article className="job-card">
      {completedOrFailed ? (
        <p className="job-card__title">Processing invitations complete</p>
      ) : (
        <header className="job-card__title-reload">
          <p className="job-card__title job-card__title--secondary">
            <span className="job-card__black-font">Processing,</span> last updated{" "}
            <span className="job-card__normal-font">on</span> {moment(date).format("Do MMMM YYYY")}{" "}
            <span className="job-card__normal-font">at</span>{" "}
            {moment(job.createdDate).format("HH:mm")}
          </p>
          <TextButton onClick={() => refresh(job.id)}>
            <ReloadIcon />
          </TextButton>
        </header>
      )}
      <div className="job-card__info-wrapper">
        <div className="job-card__submitted-container">
          <p className="job-card__job-item">
            <span className="job-card__primary-text">Submitted by:</span> {job.adminEmailAddress}{" "}
            <span className="job-card__normal-font job-card__secondary-text">
              {moment(job.createdDate).format("Do MMMM YYYY")} at{" "}
              {moment(job.createdDate).format("HH:mm")}
            </span>
          </p>
          <p className="job-card__job-item">
            <span className="job-card__primary-text">Application:</span>{" "}
            <span className="job-card__secondary-text">
              {!loading && productInformation.map(app => app.friendlyName)}
            </span>
          </p>
          <p className="job-card__job-item">
            <span className="job-card__primary-text">Submission ID:</span>{" "}
            <span className="job-card__secondary-text">{job.id}</span>
          </p>
        </div>
        {(job.status === BulkInvitationJobState.Created ||
          job.status === BulkInvitationJobState.Processing) && (
          <div className="job-card__progress">
            <p className="job-card__progress-item job-card__progress-item--state">
              Processing: {`${completed + failed} out of ${job.recipients.length} invites`}
            </p>
            <p className="job-card__progress-item job-card__progress-item--icon">
              <TickIcon />
              Successfully sent:{" "}
              <span className="job-card__progress-figure">{completed} invites</span>
            </p>
            <p className="job-card__progress-item job-card__progress-item--icon">
              <CrossIcon />
              Unsuccessfuly sent:{" "}
              <span className="job-card__progress-figure">{failed} invites</span>
            </p>
          </div>
        )}

        {completedOrFailed && (
          <div className="job-card__progress">
            <p className="job-card__progress-item job-card__progress-item--state">
              Completed all {job.recipients.length} invites
            </p>
            <p className="job-card__progress-item job-card__progress-item--icon">
              <TickIcon />
              Successfully sent:{" "}
              <span className="job-card__progress-figure">{completed} invites</span>
            </p>
            <p className="job-card__progress-item job-card__progress-item--icon">
              <CrossIcon />
              Unsuccessfuly sent:{" "}
              <span className="job-card__progress-figure">{failed} invites</span>
            </p>
          </div>
        )}
      </div>
      {completedOrFailed && (
        <footer className="job-card__completed-buttons">
          {completedOrFailed && (
            <TextButton
              className="job-card__view-recipients-button"
              onClick={() => toggleModal(job)}
            >
              <ViewIcon /> View recipients list
            </TextButton>
          )}
          {job.status === BulkInvitationJobState.Failed && (
            <div className="job-card__resend-button-wrapper">
              <TextButton className="job-card__resend-button" onClick={() => resendInvites(job.id)}>
                <MailIcon /> Resend unsuccessful invites
              </TextButton>
            </div>
          )}
        </footer>
      )}
    </article>
  )
})

export default JobCard
