import { Select } from "nhsuk-react-components"
import React, { Fragment, memo, useEffect, useMemo, useState } from "react"
import { JobRoleType } from "../../enums/jobRole"
import { IJobRole } from "../../interfaces/jobRole/interfaces"
import { groupBy } from "../../utils/collection"

interface ICategorisedJobRoleSelectProps {
  jobRoles: IJobRole[]
  errorString?: string
  disabled?: boolean
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void
  defaultValue: string
  className?: string
}

interface IJobRoleCategory {
  name: string
  roles: IJobRole[]
}

const CategorisedJobRoleSelect = (props: ICategorisedJobRoleSelectProps) => {
  const { jobRoles, errorString, disabled, onChange, defaultValue, className } = props

  const [value, setValue] = useState("")

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const onJobRoleChangedInternal = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value
    setValue(val)
    onChange(e)
  }

  const jobRoleCategories: IJobRoleCategory[] = useMemo(() => {
    if (jobRoles.length === 0) {
      return []
    }

    const groupedJobRoleCategories = groupBy(
      [...jobRoles].sort((a, b) => a.order - b.order),
      item => item.type
    )

    return Object.values(JobRoleType).map(value => {
      return {
        name: value,
        roles: groupedJobRoleCategories.get(value) || [],
      }
    })
  }, [jobRoles])

  return (
    <Select
      id="input-select-jobRole"
      data-testid="input-select-jobRole"
      value={value}
      error={errorString}
      disabled={disabled}
      onChange={onJobRoleChangedInternal}
      className={className || ""}
    >
      <Select.Option value="" disabled key="default-disabled-select-option">
        Select job role
      </Select.Option>
      {jobRoleCategories.map(category => {
        const { name, roles } = category
        return (
          <Fragment key={`select-options-container-${category.name}`}>
            <Select.Option disabled />
            <Select.Option
              value={name}
              disabled
              data-testid="select-options-container__disabled-category"
            >
              {name}
            </Select.Option>
            {roles.map(role => (
              <Select.Option key={`${name}-${role.id}`} value={role.title}>
                {role.title}
              </Select.Option>
            ))}
          </Fragment>
        )
      })}
    </Select>
  )
}

export default memo(CategorisedJobRoleSelect)
