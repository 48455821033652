import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import { BodyText, Button, Col, ErrorSummary, Form, Label } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { AsyncSearchableSelect } from "../../../global/components/searchableSelect"
import { useStores } from "../../../global/hooks"
import ProductViewRedirect from "../productViewRedirect"
import { ProductEditView } from "../../enums/enums"
import AdditionalAdminForm from "./additionalAdminForm"
import ReadOnlyForm from "./readOnlyForm"
import { constructDropdownValue } from "./shared"
import { scrollRefIntoView } from "../../../global/utils/dom"
import "./styles.scss"

const AdminInfoView = observer(() => {
  const { productStore, adminInfoStore } = useStores()
  const { adminInformation } = adminInfoStore
  const { wrapWithLoader } = React.useContext(LoaderContext)

  const [adminLinkDisabled, setAdminLinkDisabled] = useState(false)
  const [noResultsMessage, setNoResultsMessage] = useState("You need to provide a search term.")
  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [loading, setLoading] = useState(true)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [otherAdmins, setOtherAdmins] = useState([])
  const [primaryAdmin, setPrimaryAdmin] = useState(null)
  const [regions, setRegions] = useState([])
  const [scopes, setScopes] = useState([])
  const errorContainerRef = useRef()

  useEffect(() => {
    wrapWithLoader(async () => {
      await adminInfoStore.getAdminInfo(productStore.product.id)

      setOtherAdmins(adminInfoStore.adminInformation.otherAdmins)
      setPrimaryAdmin(adminInfoStore.adminInformation.primaryAdmin)
      setRegions(adminInfoStore.adminInformation.regions)
      setScopes(adminInfoStore.adminInformation.scopes)

      setLoading(false)
    })
    return () => adminInfoStore.resetState()
  }, [])

  useEffect(() => {
    if (!loading) {
      setAdminLinkDisabled(adminInformation.otherAdmins >= 10)
    }
  }, [loading])

  const handleSearch = _.debounce((search, callback) => {
    setNoResultsMessage("No results found, try searching again with a different query.")

    adminInfoStore
      .getUserSearch(search)
      .then(result => callback(result))
      .catch(error => callback(error))
  }, 250)

  const addAdmin = () => {
    if (adminLinkDisabled) return

    if (!adminInformation.otherAdmins) {
      adminInformation.otherAdmins = []
    }

    adminInformation.otherAdmins = adminInformation.otherAdmins.concat({
      email: null,
      userId: null,
      error: {},
      scope: -1,
    })

    if (adminInformation.otherAdmins && adminInformation.otherAdmins.length >= 10) {
      setAdminLinkDisabled(true)
    }

    setOtherAdmins(adminInformation.otherAdmins)
  }

  const removeAdmin = index => {
    if (adminLinkDisabled) {
      setAdminLinkDisabled(false)
    }

    adminInformation.otherAdmins.splice(index, 1)
    setOtherAdmins(adminInformation.otherAdmins)
  }

  const handlePrimarySelection = selected => {
    let error = primaryAdmin && primaryAdmin.error ? primaryAdmin.error : {}
    adminInformation.primaryAdmin = selected ? selected.value : adminInfoStore.defaultPrimaryAdmin()

    if (selected) {
      setPrimaryAdmin(selected.value)
    }

    adminInformation.primaryAdmin.error = error
  }

  const handleOtherAdminSelection = (index, selected) => {
    const adminObj = {
      error: {},
      userId: null,
      email: null,
      fullName: null,
      scope: -1,
    }

    let currentlySelectedObject =
      adminInformation.otherAdmins.find((_, idx) => idx == index) ?? adminObj

    if (!selected || !selected.value) {
      adminInformation.otherAdmins[index] = {
        ...adminObj,
      }
      setOtherAdmins(adminInformation.otherAdmins)
      return
    }

    currentlySelectedObject.userId = selected.value.userId
    currentlySelectedObject.email = selected.value.email
    currentlySelectedObject.fullName = selected.value.fullName

    setOtherAdmins(adminInformation.otherAdmins)
  }

  const infoValidates = () => {
    const otherAdminsCopy = [...adminInformation.otherAdmins]

    const primaryAdminCopy = _.cloneDeep(primaryAdmin)

    primaryAdminCopy.error = null
    otherAdminsCopy.forEach(admin => (admin.error = {}))

    if (!primaryAdminCopy.userId || !primaryAdminCopy.email) {
      primaryAdminCopy.error = "Select the primary admin"
    }

    otherAdminsCopy
      .filter(admin => !admin.userId || !admin.email)
      .forEach(admin => (admin.error.select = "Select an admin, or delete if unnecessary."))

    otherAdminsCopy
      .filter(admin => admin.canUpdateProduct !== false && admin.canUpdateProduct !== true)
      .forEach(
        admin => (admin.error.radios = `Select whether this admin can change the product setup.`)
      )

    otherAdminsCopy
      .filter(admin => admin.scope !== undefined && admin.scope < 0)
      .forEach(
        admin =>
          (admin.error.scope = "Select the scope of this admin user's permission to grant access")
      )

    const regionalIndex = scopes.findIndex(x => x.isRegional)

    if (regionalIndex !== -1) {
      otherAdminsCopy
        .filter(
          admin =>
            admin.scope === regionalIndex &&
            (!admin.selectedRegions || admin.selectedRegions.length === 0)
        )
        .forEach(
          admin =>
            (admin.error.checkbox =
              "Select the region of this admin user's permission to grant access")
        )
    }

    setPrimaryAdmin(primaryAdminCopy)
    setOtherAdmins(otherAdminsCopy)

    return (
      _.isEmpty(primaryAdminCopy.error) && otherAdminsCopy.every(admin => _.isEmpty(admin.error))
    )
  }

  const handleSaveAndReturn = async () => {
    adminInformation.complete = false
    await save()
  }

  const handleSaveAndComplete = async () => {
    adminInformation.complete = true

    if (!infoValidates()) {
      scrollRefIntoView(errorContainerRef)
      return
    }

    deleteErrors()
    await save()
  }

  const deleteErrors = () => {
    const primaryAdminCopy = _.cloneDeep(primaryAdmin)
    delete primaryAdminCopy.error
    setPrimaryAdmin(primaryAdminCopy)

    const otherAdminsCopy = [...otherAdmins]
    otherAdminsCopy.map(admin => delete admin.error)
    setOtherAdmins(otherAdminsCopy)
  }

  const clearPrimaryAdminSelection = () => {
    setNoResultsMessage("You need to provide a search term.")
    setPrimaryAdmin({})
  }

  const save = async () => {
    adminInfoStore
      .saveAdminInfo()
      .then(_ => {
        productStore.setTaskModified(ProductEditView.Admin, adminInformation.complete)
        setRedirectingToTaskList(true)
      })
      .catch(Function.prototype)
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <Col id="admin-info-view" width="full" className="admin-info-view">
      <div className="title-modify-container">
        <Label isPageHeading>Admin information</Label>
        {!isEditMode && (
          <Link id="change-settings-button" onClick={() => setIsEditMode(true)}>
            Change settings
          </Link>
        )}
      </div>
      {isEditMode && (
        <>
          <div ref={errorContainerRef}>
            {((primaryAdmin && primaryAdmin.error) ||
              otherAdmins.some(admin => !_.isEmpty(admin.error))) && (
              <div>
                <ErrorSummary role="alert" tabIndex={-1}>
                  <ErrorSummary.Title id="error-summary-title">
                    There is a problem
                  </ErrorSummary.Title>
                  {primaryAdmin && primaryAdmin.error && (
                    <ErrorSummary.Item key={`primary-error-key`} href="#primary-admin-field">
                      {primaryAdmin.error}
                    </ErrorSummary.Item>
                  )}
                  {otherAdmins.map(
                    (admin, index) =>
                      admin.error && (
                        <>
                          {admin.error.select && (
                            <ErrorSummary.Item href={`#additional-admin-${index}`}>
                              {admin.error.select}
                            </ErrorSummary.Item>
                          )}
                          {admin.error.radios && (
                            <ErrorSummary.Item href={`#additional-admin-${index}`}>
                              {admin.error.radios}
                            </ErrorSummary.Item>
                          )}
                          {admin.error.scope && (
                            <ErrorSummary.Item href={`#additional-admin-${index}`}>
                              {admin.error.scope}
                            </ErrorSummary.Item>
                          )}
                          {admin.error.checkbox && (
                            <ErrorSummary.Item href={`#additional-admin-${index}`}>
                              {admin.error.checkbox}
                            </ErrorSummary.Item>
                          )}
                        </>
                      )
                  )}
                </ErrorSummary>
              </div>
            )}
            <BodyText className="admin-info-admin-blurb">
              The primary admin user can deal with access requests from all users, and change the
              setup of the product.
            </BodyText>
            <BodyText className="admin-info-admin-blurb">
              Any additional admin users can deal with only the type of access requests that you
              specify, and change the setup of the product only if you grant this permission.
            </BodyText>
          </div>
        </>
      )}
      <div className="admin-info-form-group">
        {!loading ? (
          !isEditMode ? (
            <ReadOnlyForm
              admin={adminInformation.primaryAdmin}
              title="Primary admin details"
              scopes={scopes}
            />
          ) : (
            <Form>
              <AsyncSearchableSelect
                id="primary-admin-field"
                name="Admin name (primary)"
                className="input-field admin-info-custom-select"
                hint="Type their name and select the correct individual from the dropdown."
                noOptionsMessage={() => noResultsMessage}
                loadOptions={handleSearch}
                error={primaryAdmin && primaryAdmin.error}
                onClear={() => clearPrimaryAdminSelection()}
                value={constructDropdownValue(primaryAdmin)}
                onChange={handlePrimarySelection}
              />
            </Form>
          )
        ) : null}
        <div className="admin-info-secondary-form-collection">
          {!loading
            ? otherAdmins.map((admin, index) =>
                !isEditMode ? (
                  <ReadOnlyForm
                    key={index}
                    title={`Additional admin details`}
                    admin={admin}
                    scopes={scopes}
                  />
                ) : (
                  <AdditionalAdminForm
                    id={`additional-admin-${index}`}
                    key={index}
                    handleSearch={handleSearch}
                    setNoResultsMessage={setNoResultsMessage}
                    noResultsMessage={noResultsMessage}
                    adminsIndex={index}
                    otherAdmins={adminInformation.otherAdmins}
                    removeAdmin={() => removeAdmin(index)}
                    handleAdminSelection={_.curry(handleOtherAdminSelection)(index)}
                    scopes={scopes}
                    regions={regions}
                  />
                )
              )
            : null}
        </div>
      </div>
      {isEditMode && (
        <>
          <Link
            secondary
            disabled={adminLinkDisabled}
            id="add-another-admin-button"
            onClick={addAdmin}
          >
            <div className="cross">
              <p>+</p>
            </div>{" "}
            Add another admin
          </Link>
          <div id="button-controls">
            <Button id="publish-button" onClick={handleSaveAndComplete}>
              {productStore.published ? "Save and publish" : "Save and complete"}
            </Button>
            {!productStore.published && (
              <Link id="return-link" onClick={handleSaveAndReturn}>
                Save and return to task list
              </Link>
            )}
          </div>
        </>
      )}
    </Col>
  )
})

export default AdminInfoView
