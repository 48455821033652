// Styleguide_defined colours

export const nhsuk_blue_1 = "#005EB8"
export const nhsuk_blue_2 = "#465968"
export const nhsuk_blue_3 = "#0058ad"
export const nhsuk_green = "#007F3B"
export const nhsuk_yellow = "#FFEB3B"
export const nhsuk_warm_yellow = "#FFB81C"
export const nhsuk_red = "#D5281B"
export const nhsuk_red_2 = "#DA291C"
export const nhsuk_dark_pink = "#7C2855"
export const nhsuk_purple = "#330072"
export const nhsuk_grey_1 = "#4C6272"
export const nhsuk_grey_2 = "#768692"
export const nhsuk_grey_3 = "#AEB7BD"
export const nhsuk_grey_4 = "#D8DDE0"
export const nhsuk_grey_5 = "#F0F4F5"
export const nhsuk_grey_6 = "#425462"
export const nhsuk_white = "#FFFFFF"
export const nhsuk_pale_yellow = "#FFF9C4"
export const nhsuk_dark_cyan = "#067A72"
export const nhsuk_dark_blue = "#425563"

// Text
export const text_primary = "#212B32"
export const text_secondary = "#4C6272"

// Links
export const link = "#005EB8"
export const link_hover = "#7C2855"
export const link_visited = "#330072"
export const link_active = "#002F5C"

// Focus
export const focus_primary = "#FFEB3B"
export const focus_text = "#212B32"

// Border
export const border_primary = "#D8DDE0"
export const border_secondary = "#4C6272"
export const warning_box = "#FFFBCE"

// Error
export const error = "#D5281B"

// Button
export const button_primary = "#007F3B"
export const button_secondary = "#4C6272"
