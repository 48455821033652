import { Options as FocusTrapOptions } from "focus-trap"
import FocusTrap from "focus-trap-react"
import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import "./styles.scss"

interface IModalProps {
  children: React.ReactNode
  className?: string
  focusTrapOptions?: FocusTrapOptions
  close: () => void
}

const Modal = (props: IModalProps) => {
  const { children, className, focusTrapOptions, close } = props

  useEffect(() => {
    const closeModal = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close()
      }
    }
    window.addEventListener("keydown", closeModal)
    return () => window.removeEventListener("keydown", closeModal)
  }, [])

  return ReactDOM.createPortal(
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div data-testid="nhsi-modal" id="nhsi-modal" className={className}>
        <div className="BG" data-testid="BG" onClick={() => close()} />
        <div className="modal--content">{children}</div>
      </div>
    </FocusTrap>,
    document.body
  )
}

export default Modal
