import React from "react"
import "./styles.scss"
import LoginButton from "../components/buttons/loginButton/loginButton"

const ManualApprovalRequired = () => {
  return (
    <>
      <span className="profile-update-result-view__body-label">
        Once approved we'll send an <b>activation email to your new address</b>.<br />
        Your <b>access</b> to some products and services <b>may change</b>.<br />
      </span>
      <LoginButton />
    </>
  )
}

export default ManualApprovalRequired
