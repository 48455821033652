import React, { useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { Button, Radios } from "nhsuk-react-components"
import { LoaderContext } from "../../../../global/components/loaderProvider"
import TextButton from "../../../../global/components/textButton"
import { useStores } from "../../../../global/hooks"
import NoDownloadsAllowedModalContent from "../noDownloadsAllowedModalContent"
import { TableauDialogType, TableauViz } from "@tableau/embedding-api"

import "./styles.scss"

interface IDownloadModalContent {
  close: () => void
}

enum TableauDownloadFormats {
  showExportFullDataDialog = 0,
  showExportCrossTabDialog = 1,
  exportCrossTabToExcel = 2,
  showExportPowerPointDialog = 3,
  showExportImageDialog = 4,
  showExportPDFDialog = 5,
}

const DownloadModalContent = observer(({ close }: IDownloadModalContent) => {
  const {
    serverSettingsStore: {
      serverSettings: { applicationHost },
      getServerSettings,
    },
    tableauReportViewerStore: { currentReport, tableauVisualization, visualizationLoading },
  } = useStores()
  const [selectedDownload, setSelectedDownload] = useState<TableauDownloadFormats | null>(null)
  const [isDownloadable, setIsDownLoadable] = useState(true)
  const { wrapWithLoader } = useContext(LoaderContext)
  const isMounted = useRef(true)

  const insightsHost = applicationHost || "https://apps.model.nhs.uk"

  useEffect(() => {
    if (isMounted.current) {
      wrapWithLoader(async () => {
        if (currentReport && tableauVisualization) {
          await getServerSettings()
          const activeTabName = tableauVisualization.workbook.activeSheet.name
          const matched = currentReport.downloads.find(x => x.dashboardName === activeTabName)
          setIsDownLoadable(!!matched)
        }
      })
    }
    return () => {
      isMounted.current = false
    }
  }, [currentReport, tableauVisualization])

  const getTableauDialogType = (option: TableauDownloadFormats): TableauDialogType | null => {
    switch (option) {
      case TableauDownloadFormats.showExportCrossTabDialog:
      case TableauDownloadFormats.exportCrossTabToExcel:
        return TableauDialogType.ExportCrossTab
      case TableauDownloadFormats.showExportFullDataDialog:
        return TableauDialogType.ExportData
      case TableauDownloadFormats.showExportPowerPointDialog:
        return TableauDialogType.ExportPowerPoint
      case TableauDownloadFormats.showExportPDFDialog:
        return TableauDialogType.ExportPDF
      default:
        return null
    }
  }

  const triggerDownload = async (
    tableauVisualization: TableauViz,
    selectedDownloadOption: TableauDownloadFormats | null
  ) => {
    if (
      !tableauVisualization ||
      !tableauVisualization.workbook ||
      selectedDownloadOption === null ||
      selectedDownloadOption === undefined
    )
      return

    if (selectedDownloadOption === TableauDownloadFormats.showExportImageDialog) {
      await tableauVisualization.exportImageAsync()
    } else {
      const selectedTableauDialogType = getTableauDialogType(selectedDownloadOption)
      //NOTE: can not use the exportCrosstabAsync for crosstabs as the workbook does not give us the current active workbook. All of them show up as isActive = false
      //This gives the user the experience they would have in tableau where a popup asks them to select the crosstab they wish to download in either csv or excel
      selectedTableauDialogType &&
        (await tableauVisualization.displayDialogAsync(selectedTableauDialogType))
      close()
    }
  }

  if (!currentReport || visualizationLoading || !tableauVisualization) {
    return <></>
  }

  return (
    <>
      {!isDownloadable ? (
        <NoDownloadsAllowedModalContent close={close} />
      ) : (
        <div className="modal-content">
          <span className="modal-title">Download data</span>
          <span className="modal-warning-box">
            Please make sure you have clicked on the visualisation you are trying to download data
            from before proceeding.
          </span>
          <span className="modal-info-box">
            Please do not share this data with people outside your organisation. Ask them to request
            access to this dashboard via
            <a href={insightsHost}> {insightsHost} </a> and share this view with them instead.
          </span>
          <div>
            <p>Select the format(s) you would like the data in</p>
            <Radios>
              {currentReport.enableFullDataCsv && (
                <Radios.Radio
                  onClick={() => {
                    setSelectedDownload(TableauDownloadFormats.showExportFullDataDialog)
                  }}
                >
                  Full Data (csv)
                </Radios.Radio>
              )}
              {currentReport.enableDownloadCSV && (
                <Radios.Radio
                  onClick={() => {
                    setSelectedDownload(TableauDownloadFormats.showExportCrossTabDialog)
                  }}
                >
                  Comma-separated (csv)
                </Radios.Radio>
              )}
              {currentReport.enableDownloadExcel && (
                <Radios.Radio
                  onClick={() => {
                    setSelectedDownload(TableauDownloadFormats.exportCrossTabToExcel)
                  }}
                >
                  Excel (xlsx)
                </Radios.Radio>
              )}
              {currentReport.enableDownloadPowerPoint && (
                <Radios.Radio
                  onClick={() =>
                    setSelectedDownload(TableauDownloadFormats.showExportPowerPointDialog)
                  }
                >
                  PowerPoint (pptx)
                </Radios.Radio>
              )}
              {currentReport.enableDownloadImage && (
                <Radios.Radio
                  onClick={() => setSelectedDownload(TableauDownloadFormats.showExportImageDialog)}
                >
                  Image (png)
                </Radios.Radio>
              )}
              {currentReport.enableDownloadPDF && (
                <Radios.Radio
                  onClick={() => setSelectedDownload(TableauDownloadFormats.showExportPDFDialog)}
                >
                  PDF (pdf)
                </Radios.Radio>
              )}
            </Radios>
          </div>
          <div className="modal-divider" />

          <div className="internal-link">
            <TextButton onClick={close}>Close</TextButton>
          </div>

          <div className="download-button">
            <Button
              onClick={async () => await triggerDownload(tableauVisualization, selectedDownload)}
            >
              Download data
            </Button>
          </div>
        </div>
      )}
    </>
  )
})

export default DownloadModalContent
