import { useLocation } from "react-router-dom"

export default function useStateFromRedirect<T>(defaultValue: T): T {
  const location = useLocation()

  if (!location.state) {
    return defaultValue
  }

  const state = location.state as T
  if (!state) {
    return defaultValue
  }

  return state
}
