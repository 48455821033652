import { makeObservable, action, observable } from "mobx"
import AdminStore from "../../global/abstract/admin/adminStore"
import { UserStatus } from "../../global/enums/user/enums"
import { IOrganisationModel } from "../../global/interfaces/organisation/interfaces"
import { IUserPage } from "../../global/interfaces/pagination/interfaces"
import { IRegistrationTotals } from "../../global/interfaces/registration"
import { IUserIdList } from "../../global/interfaces/user/interfaces"
import { get, post } from "../../global/utils/api"

export interface ISystemAdminStore {
  selectedProduct: string
  getPageData: () => Promise<IUserPage>
  reactivateList: (array: string[]) => Promise<void>
}

class SystemAdminStore extends AdminStore<UserStatus> implements ISystemAdminStore {
  //#region Constructor + setup
  constructor() {
    super({
      list: {
        [UserStatus.Pending]: {
          name: "Requests",
          status: "pending",
          value: UserStatus.Pending,
          searchValues: [UserStatus.Pending, UserStatus.UpdatedAwaitingManualApproval],
          description:
            "All the people awaiting approval to NHS England applications. \n You should aim to approve or decline access within two working days of the application.",
        },
        [UserStatus.Approved]: {
          name: "Approved accounts",
          status: "approved",
          value: UserStatus.Approved,
          searchValues: [UserStatus.Approved],
          description:
            "This is a list of all approved accounts on NHS England applications. \n If a user should no longer have access, you can deactivate their account.",
        },
        [UserStatus.Rejected]: {
          name: "Declined registrations",
          status: "rejected",
          value: UserStatus.Rejected,
          searchValues: [UserStatus.Rejected],
          description:
            "This is a list of all registrations that have been declined, either manually or \n automatically. \n \n You can activate an account that has been declined by mistake.",
        },
        [UserStatus.Suspended]: {
          name: "Deactivated accounts",
          status: "suspended",
          value: UserStatus.Suspended,
          searchValues: [UserStatus.Suspended],
          description:
            "This is a list of all accounts that have been deactivated. \n You can reactivate accounts when you are sure that the user still needs access.",
        },
      },
      selected: UserStatus.Pending,
    })

    this.availableTabs = [
      UserStatus.Pending,
      UserStatus.Approved,
      UserStatus.Suspended,
      UserStatus.Rejected,
    ]

    this.listTotals = {
      [UserStatus.Pending]: 0,
      [UserStatus.Approved]: 0,
      [UserStatus.Rejected]: 0,
      [UserStatus.Suspended]: 0,
    }
    // As this inherits from AdminStore we must use makeObservable and be explicit with properties
    // and methods, rather than using makeAutoObservable
    makeObservable(this, {
      getPageData: action,
      getTabTotals: action,
      approveList: observable,
      rejectList: observable,
      deprovisionList: observable,
      reactivateList: observable,
    })
  }
  //#endregion

  //#region Change handlers
  handleOrgChange = (search: IOrganisationModel | null): Promise<IUserPage> =>
    super.handleOrgChange(search, this.refreshData)

  handleSearch = (search: string): Promise<IUserPage> =>
    super.handleSearch(search, this.refreshData)

  handleRegionChange = (region: string): Promise<IUserPage> =>
    super.handleRegionChange(region, this.refreshData)

  handleSortMainList = (sortVal: string): Promise<IUserPage> =>
    super.handleSortMainList(sortVal, this.refreshData)

  handleFilterJobRole = (role: string): Promise<IUserPage> =>
    super.handleFilterJobRole(role, this.refreshData)

  setActiveTab = (tabValue: UserStatus): Promise<IUserPage> =>
    super.setActiveTab(tabValue, this.refreshData)

  setSelected = (arr: string[]): void => super.setSelected(arr)

  setSelectedAll = (value: boolean): void => super.setSelectedAll(value)

  toggleSelectAll = (): void => super.toggleSelectAll()

  //#endregion

  //#region Store-specific properties
  selectedProduct = ""
  //#endregion

  refreshData = (): Promise<IUserPage> => {
    this.resetPagination()
    this.getTabTotals()
    return this.getMainList()
  }

  getPageData = (): Promise<IUserPage> => {
    this.getTabTotals()
    return this.getMainList()
  }

  getMainList = (): Promise<IUserPage> => {
    const org = this.selectedOrg ? this.selectedOrg.value : ""
    let query =
      `/user/page?pageNumber=${this.pages.current}&search=${encodeURIComponent(
        this.searchString
      )}` +
      `&sortOrder=${this.sortBy}&filter=${this.jobRole}` +
      `&region=${this.selectedRegion}`

    if (org) {
      query += `&organisation=${org}`
    }

    const status = this.tab.list[this.tab.selected]
    const queryKvps =
      status && status.searchValues
        ? status.searchValues.map(code => `status=${code}`).join("&")
        : ""

    if (queryKvps) {
      query += `&${queryKvps}`
    }

    return super.getMainList(query)
  }

  getTabTotals = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<IRegistrationTotals>("/user/totals").then(res => {
        if (res.status === 200) {
          this.listTotals = {
            [UserStatus.Pending]: res.data.requestsTotal + res.data.manualApprovalUsersTotal,
            [UserStatus.Approved]: res.data.activeUsersTotal,
            [UserStatus.Rejected]: res.data.rejectedUsersTotal,
            [UserStatus.Suspended]: res.data.suspendedUsersTotal,
          }
        }
        resolve()
      })
    })
  }

  //#region Store-specific API calls
  approveList = (array: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>(`/user/approve`, {
        userIdList: [...array],
      })
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(async err => reject(err))
    })
  }

  rejectList = (array: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>(`/user/reject`, {
        userIdList: [...array],
      })
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(err => reject(err))
    })
  }

  deprovisionList = (array: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>("/user/deprovision", {
        userIdList: [...array],
      })
        .then(async () => resolve())
        .catch(err => reject(err))
        .finally(async () => this.refreshData())
    })
  }

  reactivateList = (array: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>("/user/provision", {
        userIdList: [...array],
      })
        .then(async () => resolve())
        .catch(err => reject(err))
        .finally(async () => this.refreshData())
    })
  }
  //#endregion
}

export default new SystemAdminStore()
