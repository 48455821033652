import _ from "lodash"
import { BodyText, Form, Label } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { useStores } from "../../hooks"
import {
  asyncSearchOrganisations,
  isOther,
  OrganisationKeys,
  OrganisationTypes,
  RegionalOrganisationKeys,
} from "../../constants/organisation/constants"
import { IOrganisation, IOrganisationModel } from "../../interfaces/organisation/interfaces"
import { IRegion } from "../../interfaces/region/interfaces"
import { ErrorsCollection } from "../../types"
import { LoaderContext } from "../loaderProvider"
import Modal from "../modal/index"
import RegionComponent from "../region/region"
import OrganisationSelector from "./components/organisationSelector"
import SubOrganisationSelector from "./components/subOrganisationSelector"
import { mapOrganisationModelToOrganisation, mapOrganisationToOrganisationModel } from "./helper"
import EditableOrganisationInput from "./otherOrganisationSelectionModalContent/editableOrganisationInput/editableOrganisationInput"
import OtherOrganisationSelectionModalContent from "./otherOrganisationSelectionModalContent/otherOrganisationSelectionModalContent"
import "./styles.scss"

interface IOrganisationProps {
  defaultParentOrganisation?: IOrganisation
  defaultSubOrganisation?: IOrganisationModel
  defaultRegionCode?: string
  isCategorised?: boolean
  errors?: ErrorsCollection | null
  isPrimary: boolean
  disabled: boolean
  organisationCategories: IOrganisation[]
  index?: number
  remove?: () => void
  getSubOrganisations?: (type: string) => Promise<IOrganisation[]>
  handleAsyncOrgSearch?: (orgCode: string, searchTerm: string) => Promise<IOrganisation[]>
  onSubOrganisationChanged: (model: IOrganisationModel | null) => void
  onParentOrganisationChanged: (org: IOrganisation | null, removeSubOrganisation: boolean) => void
  onRegionChanged: (region: IRegion | null) => void
  onSTPChanged?: (value: boolean) => void
  onModalCancellation?: () => void
}

const errorFields = {
  primaryOrganisation: "primaryOrganisation",
  subOrganisation: "subOrganisation",
  region: "region",
}

const Organisation = (props: IOrganisationProps) => {
  const {
    defaultParentOrganisation,
    defaultSubOrganisation,
    defaultRegionCode,
    isCategorised,
    errors,
    isPrimary,
    disabled,
    remove,
    index,
    organisationCategories,
    getSubOrganisations,
    handleAsyncOrgSearch,
    onSubOrganisationChanged,
    onParentOrganisationChanged,
    onRegionChanged,
    onSTPChanged,
    onModalCancellation,
  } = props

  const {
    organisationStore: {
      getRegions,
      getSubOrganisations: getSubOrganisationsFromStore,
      getOrganisationByCode,
    },
  } = useStores()

  const { icbOrg } = OrganisationTypes

  const { keyNHSEI, keyOldHEE, keyHEE, keyOldNHSI, keyNHSD, keyOldNHSD } = OrganisationKeys

  const [subOrganisations, setSubOrganisations] = useState<IOrganisationModel[]>([])
  const [selectedSubOrganisation, setSelectedSubOrganisation] = useState<IOrganisationModel | null>(
    defaultSubOrganisation ? defaultSubOrganisation : null
  )
  const [selectedParentOrganisation, setSelectedParentOrganisation] =
    useState<IOrganisation | null>(defaultParentOrganisation ? defaultParentOrganisation : null)
  const [organisationSetFromModal, setOrganisationSetFromModal] = useState<IOrganisation | null>(
    null
  )

  const [selectSubOrganisationLabel, setCallToActionLabel] = useState("")
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [loading, setLoading] = useState(true)
  const [organisationRegions, setOrganisationRegions] = useState<IRegion[]>([])
  const [organisationFromModalInEditMode, setOrganisationFromModalInEditMode] = useState(false)
  const [useModalToSelectOrganisation, setUseModalToSetOrganisation] = useState(false)

  const allRegionsRef = useRef<IRegion[]>([])

  const selectRef = useRef<HTMLSelectElement>(null)
  const id = isPrimary ? `primaryOrganisation` : `secondaryOrganisation_${index}`

  const regionOrg = selectedSubOrganisation
    ? selectedSubOrganisation.value
    : selectedParentOrganisation?.code
  const shouldPopulateRegion =
    regionOrg &&
    [keyNHSEI, keyOldHEE, keyHEE, keyOldNHSI, keyNHSD, keyOldNHSD].indexOf(regionOrg) !== -1
  const correctedDefaultRegionCode = shouldPopulateRegion ? defaultRegionCode : undefined

  useEffect(() => {
    wrapWithLoader(async () => {
      setLoading(true)

      if (!allRegionsRef.current || allRegionsRef.current.length === 0) {
        const fetchedRegions = await getRegions()
        allRegionsRef.current = [...fetchedRegions]
      }

      if (defaultParentOrganisation) {
        onSelectParentOrganisation(defaultParentOrganisation.code, true, false, false)
      }

      if (defaultSubOrganisation) {
        onSelectSubOrganisation(defaultSubOrganisation)

        if (isOther(defaultParentOrganisation) || defaultParentOrganisation === undefined) {
          setOrganisationSetFromModal(
            (await getOrganisationByCode(defaultSubOrganisation.value)) || null
          )
        }
      }

      setLoading(false)
    })
  }, [defaultParentOrganisation, defaultSubOrganisation])

  const onOrganisationSetViaModal = async (org: IOrganisation | null) => {
    setUseModalToSetOrganisation(false)

    if (!org) {
      setOrganisationSetFromModal(null)
      return
    }

    if (RegionalOrganisationKeys.indexOf(org.code) !== -1) {
      await wrapWithLoader(async () => {
        const fetchedOrganisation = await getOrganisationByCode(org.code)
        if (fetchedOrganisation) {
          const regions = [
            ...allRegionsRef.current
              .filter(r => fetchedOrganisation.regionCodes.some((c: string) => c === r.code))
              .sort((a, b) => a.name.localeCompare(b.name)),
          ]

          org.regionCodes = regions.map(x => x.code)
        }
      })
    }

    setOrganisationSetFromModal(org)
    const mapped = mapOrganisationToOrganisationModel(org)
    onSubOrganisationChanged(mapped)
    setSelectedSubOrganisation(mapped)
  }

  const onSelectParentOrganisation = async (
    organisationCode: string,
    defaultSelection = false,
    removeSubOrganisation = true,
    openModal = true
  ): Promise<void> => {
    setSelectedSubOrganisation(null)
    const organisationCategory = organisationCategories.filter(
      item => item.code === organisationCode
    )[0]

    const other = isOther(organisationCategory)

    if (!other || defaultSelection) {
      setCallToActionLabel(`${other ? "Other organisation" : organisationCategory.name} name`)
    } else if (openModal) {
      setUseModalToSetOrganisation(true)
    }

    errors?.removeWithPredicate(err => err.key === errorFields.primaryOrganisation)
    errors?.removeWithPredicate(err => err.key === errorFields.subOrganisation)

    let subOrgs: IOrganisationModel[] = []
    let regions: IRegion[] = []

    if (
      !other &&
      organisationCategory.isOrgType &&
      !_.includes(asyncSearchOrganisations, organisationCode)
    ) {
      await wrapWithLoader(async () => {
        const subOrganisationsArray = await (getSubOrganisations
          ? getSubOrganisations(organisationCode)
          : getSubOrganisationsFromStore(organisationCode))
        subOrgs = subOrganisationsArray.map(mapOrganisationToOrganisationModel)
      })

      if (isPrimary && onSTPChanged) {
        onSTPChanged(organisationCategory.code.toLowerCase() === icbOrg.toLowerCase())
      }
    } else if (isPrimary && organisationCategory.regionCodes.length > 0) {
      regions = [
        ...allRegionsRef.current
          .filter(r => organisationCategory.regionCodes.some(c => c === r.code))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ]
    }

    setSubOrganisations(subOrgs)
    setOrganisationRegions(regions)

    const newOrgShape = {
      name: organisationCategory.name,
      code: organisationCode,
      regionCodes: regions.map(x => x.code),
      isOrgType: organisationCategory.isOrgType,
      category: organisationCategory.name,
      organisationType: organisationCode,
    }

    setSelectedParentOrganisation(newOrgShape)
    onParentOrganisationChanged(newOrgShape, removeSubOrganisation)
    setOrganisationSetFromModal(null)
  }

  const onSelectSubOrganisation = (orgModel: IOrganisationModel | undefined) => {
    errors?.removeWithPredicate(err => err.key === errorFields.subOrganisation)

    if (!orgModel) {
      setSelectedSubOrganisation(null)
      onSubOrganisationChanged(null)
      return
    }

    const validOrganisation = subOrganisations.filter(item => item.label === orgModel.label)[0]

    if (isOther(selectedParentOrganisation ? selectedParentOrganisation : undefined)) {
      setOrganisationSetFromModal(mapOrganisationModelToOrganisation(orgModel))
    }

    setSelectedSubOrganisation(orgModel)
    onSubOrganisationChanged({
      label: !validOrganisation ? orgModel.label : validOrganisation.label,
      value: !validOrganisation ? orgModel.value : validOrganisation.value,
      regionCodes: orgModel.regionCodes,
    })
  }

  const cancelOrganisationModal = () => {
    setUseModalToSetOrganisation(false)
    onOrganisationSetViaModal(null)
    setOrganisationFromModalInEditMode(false)

    if (defaultParentOrganisation) {
      onSelectParentOrganisation(
        defaultParentOrganisation.code,
        false,
        !defaultSubOrganisation,
        false
      )

      if (defaultSubOrganisation) {
        onSelectSubOrganisation(defaultSubOrganisation)
      }
    } else {
      onSelectSubOrganisation(undefined)
    }

    if (onModalCancellation) {
      onModalCancellation()
    }

    if (selectRef && selectRef.current) {
      selectRef.current.focus()
    }
  }

  const editOrganisatonSetFromModal = () => {
    setUseModalToSetOrganisation(true)
    setOrganisationFromModalInEditMode(true)
  }

  const primaryOrgErrors = errors?.firstWithPredicate(
    x => x.key === errorFields.primaryOrganisation
  )
  const subOrganisationErrors = errors?.firstWithPredicate(
    err => err.key === errorFields.subOrganisation
  )

  const primaryError =
    primaryOrgErrors && primaryOrgErrors.value.length > 0
      ? primaryOrgErrors.value[0].fieldError
      : ""

  const selectError = isPrimary ? primaryError : ""

  const subOrganisationError =
    subOrganisationErrors && subOrganisationErrors.value.length > 0
      ? subOrganisationErrors.value[0].fieldError
      : ""

  const formClassName = disabled ? " organisationComponent--disabled" : ""

  return loading ? null : (
    <>
      {useModalToSelectOrganisation && (
        <Modal
          close={cancelOrganisationModal}
          focusTrapOptions={{
            initialFocus: "#otherorgsearch_search_results_searchinput",
          }}
        >
          <OtherOrganisationSelectionModalContent
            defaultOrganisation={organisationSetFromModal}
            errors={errors}
            cancel={cancelOrganisationModal}
            organisationFromModalInEditMode={organisationFromModalInEditMode}
            setOrganisationFromModalInEditMode={setOrganisationFromModalInEditMode}
            submit={onOrganisationSetViaModal}
          />
        </Modal>
      )}

      <Form
        onSubmit={e => e.preventDefault()}
        className={`organisationComponent${formClassName}`}
        id={id}
      >
        {isPrimary && (
          <>
            <div className="organisationComponent__input-wrapper">
              <Label htmlFor={id} className="bold-label">
                Organisation (main)
              </Label>
              <BodyText className="input-hint">
                Select the main organisation you work for. If you work for a GP practice, select
                Primary Care Network.
              </BodyText>
            </div>

            <div>
              <BodyText className="input-hint">
                If you can't find your organisation try
                <strong> Other (or unable to find organisation)</strong>.
              </BodyText>
            </div>
          </>
        )}

        <OrganisationSelector
          selectedParentOrganisation={selectedParentOrganisation}
          isCategorised={isCategorised}
          disabled={disabled}
          ref={selectRef}
          organisationCategories={organisationCategories}
          id={id}
          selectError={selectError}
          onSelectParentOrganisation={onSelectParentOrganisation}
        />

        {organisationSetFromModal && (
          <EditableOrganisationInput
            selectedOrganisation={organisationSetFromModal}
            edit={editOrganisatonSetFromModal}
          />
        )}

        {!isPrimary && (
          <a
            className="delete-link"
            href="#"
            style={{ cursor: disabled ? "default" : "pointer" }}
            onClick={() => {
              if (!disabled && remove) {
                remove()
              }
            }}
            id={`delete_link_${index}`}
          >
            Delete
          </a>
        )}

        {organisationRegions.length > 0 && (
          <RegionComponent
            id="region"
            name="organisation-main-region"
            errors={errors}
            isPrimary={isPrimary}
            disabled={disabled}
            onRegionChanged={onRegionChanged}
            defaultRegionCode={correctedDefaultRegionCode}
            regions={organisationRegions}
          />
        )}

        <SubOrganisationSelector
          selectedParentOrganisation={selectedParentOrganisation}
          selectedSubOrganisation={selectedSubOrganisation}
          onSelectSubOrganisation={onSelectSubOrganisation}
          handleAsyncOrgSearch={handleAsyncOrgSearch}
          selectSubOrganisationLabel={selectSubOrganisationLabel}
          subOrganisations={subOrganisations}
          useModalToSelectOrganisation={useModalToSelectOrganisation}
          setSubOrganisations={setSubOrganisations}
          selectError={subOrganisationError}
          disabled={disabled}
        />

        {selectedSubOrganisation &&
          selectedSubOrganisation.regionCodes &&
          selectedSubOrganisation.regionCodes.length > 0 &&
          isPrimary && (
            <RegionComponent
              id="sub-organisation-region-selection"
              name="organisation-sub-region"
              errors={errors}
              disabled={disabled}
              isPrimary={isPrimary}
              defaultRegionCode={correctedDefaultRegionCode}
              onRegionChanged={onRegionChanged}
              regions={allRegionsRef.current.filter(
                region => selectedSubOrganisation.regionCodes.indexOf(region.code) !== -1
              )}
            />
          )}
      </Form>
    </>
  )
}

export default Organisation
