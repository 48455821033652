import React from "react"

const DownloadNotificationIcon = ({
  isSuccessfulNotification,
}: {
  isSuccessfulNotification: boolean
}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5559 11.1668V1.3335H8.7781V11.1668L5.66699 8.10433L4.33366 9.41683L9.66699 14.6668L15.0003 9.41683L13.667 8.10433L10.5559 11.1668ZM17.3337 16.6668H2.66699V18.6668H17.3337V16.6668Z"
        fill={isSuccessfulNotification ? "#005EB8" : "#FF0000"}
      />
      <mask
        id="mask0_7869_45609"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="1"
        width="16"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5559 11.1668V1.3335H8.7781V11.1668L5.66699 8.10433L4.33366 9.41683L9.66699 14.6668L15.0003 9.41683L13.667 8.10433L10.5559 11.1668ZM17.3337 16.6668H2.66699V18.6668H17.3337V16.6668Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7869_45609)" />
    </svg>
  )
}

export default DownloadNotificationIcon
