import React from "react"
import { observer } from "mobx-react"
import { Navigate, Route, Routes } from "react-router-dom"
import "./App.scss"
import AccessibilityPolicy from "./app/accessibilityPolicy"
import AdminView from "./app/admin"
import AdminManagement from "./app/adminManagement"
import AnalyticsLandingPage from "./app/analyticalProducts"
import AtoZLandingPage from "./app/aToZLandingPage"
import BulkInvitations from "./app/bulkInvitations/bulkInvitations"
import CookiesPolicy from "./app/cookiesPolicy"
import CorePlatformAccessibilityPolicy from "./app/corePlatformAccessibilityPolicy"
import CorePlatformCookiesPolicy from "./app/corePlatformCookiesPolicy"
import MobileRouter from "./app/global/components/mobileRouter"
import PrivateRoute from "./app/global/components/privateRoute"
import RouteWithToggle from "./app/global/components/routeWithToggle"
import withTermsCheck from "./app/global/components/HOC/withTermsCheck"
import { useAnalytics, usePushNotifications } from "./app/global/hooks"
import { history } from "./app/global/utils/history"
import Invitation from "./app/invitePage"
import LandingPage from "./app/landingPage"
import NotificationsPage from "./app/notificationsPage"
import PageNotFound from "./app/pageNotFound"
import Privacy from "./app/privacy/"
import Product from "./app/product"
import {
  EmailVerificationView,
  ProfileUpdateResultView,
  ProfileView,
  UpdatePasswordView,
  UpdateProfileView,
  UpdateSecurityView,
} from "./app/profilePage/components"
import RegistrationForm from "./app/registrationForm/registrationForm"
import SearchResults from "./app/search"
import SecurityPasswordForm from "./app/securityPasswordForm/index"
import ServiceUnavailable from "./app/serviceUnavailable"
import StepTwoView from "./app/stepTwo/stepTwoView"
import TableauReportViewer from "./app/tableauReportViewer/report"
import TermsAndConditions from "./app/termsAndConditions"
import "./_styles.scss"

// Maybe something to think about!
//configure({enforceActions: 'strict'})

const App = observer(() => {
  useAnalytics(history.location) // Setup analytics on each app load
  usePushNotifications()

  // As Tableau requires a parameter we need to set up the HOC here as need the complete url - for forwarding
  const EnhancedTableauReportViewer = withTermsCheck(
    TableauReportViewer,
    `${history.location.pathname}${history.location.search}${history.location.hash}`
  )

  return (
    <Routes>
      <Route path="/mobile" element={<LandingPage isPwa />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/register" element={<RegistrationForm />} />
      <Route path="/register/complete" element={<SecurityPasswordForm />} />
      <Route path="/emailVerification" element={<EmailVerificationView />} />
      <Route path="/reg-cookies" element={<CookiesPolicy />} />
      <Route path="/reg-terms" element={<TermsAndConditions />} />
      <Route path="/error/*" element={<ServiceUnavailable />} />

      <Route path="/myaccount">
        <Route path="updateresult" element={<ProfileUpdateResultView />} />
        <Route path="update" element={<RouteWithToggle />}>
          <Route path="" element={<PrivateRoute />}>
            <Route path="" element={<UpdateProfileView />} />
          </Route>
        </Route>
        <Route path="notificationpreferences" element={<RouteWithToggle />}>
          <Route path="" element={<PrivateRoute />}>
            <Route path="" element={<ProfileView isPersonal={false} />} />
          </Route>
        </Route>
        <Route path="" element={<PrivateRoute />}>
          <Route path="personal" element={<ProfileView isPersonal />} />
          <Route path="password" element={<UpdatePasswordView />} />
          <Route path="securityQuestion" element={<UpdateSecurityView />} />
          <Route path="" element={<Navigate to="personal" />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="/admin" element={<AdminView view="systemAdmin" />} />
        <Route path="/appadmin" element={<AdminView view="productOwnerAdmin" />} />
        <Route path="/product/:productId/edit/*" element={<Product />} />
        <Route path="/product/:productId/steptwo" element={<StepTwoView />} />
        <Route path="/invite" element={<Invitation />}>
          <Route path=":productId" element={<Invitation />} />
        </Route>
        <Route path="/a-z" element={<AtoZLandingPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<CookiesPolicy />} />
        <Route path="/accessibility" element={<AccessibilityPolicy />} />
        <Route path="/products" element={<AtoZLandingPage />} />
        <Route path="/mobilehome" element={<MobileRouter />} />
        <Route path="/manageadmins" element={<AdminManagement />} />
      </Route>

      <Route element={<RouteWithToggle />}>
        <Route element={<PrivateRoute />}>
          <Route path="/cp-cookies" element={<CorePlatformCookiesPolicy />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/analyticshub" element={<AnalyticsLandingPage />} />
          <Route path="/invitestatus" element={<BulkInvitations />} />
          <Route path="/cp-accessibility" element={<CorePlatformAccessibilityPolicy />} />
          <Route path="/notifications" element={<NotificationsPage />} />
        </Route>
      </Route>

      <Route path="/report/:reportId" element={<RouteWithToggle catchAll />}>
        <Route path="" element={<PrivateRoute />}>
          <Route path="" element={<EnhancedTableauReportViewer />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
})

export default App
