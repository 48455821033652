import { makeAutoObservable } from "mobx"
import { IAToZApplicationTile } from "../../global/interfaces/aToZ/interfaces"
import { get, postApi } from "../../global/utils/api"

const storeUrls = {
  atozbaseUrl: "/AToZProduct",
  requestAccessForApplicationById: `/application/request?id=`,
}

export interface IAToZStore {
  allAtoZProducts: IAToZApplicationTile[]
  getAllAzProductsList: () => Promise<void>
  refreshData: () => Promise<void>
  requestAppAccess: (id: string) => Promise<void>
  toggleApplicationFavourite: (id: string) => Promise<void>
}

class AtoZStore implements IAToZStore {
  constructor() {
    makeAutoObservable(this)
  }

  allAtoZProducts: IAToZApplicationTile[] = []

  getAllAzProductsList = async (): Promise<void> => {
    const allAzProductsListResponse = await get<IAToZApplicationTile[]>(storeUrls.atozbaseUrl)
    this.allAtoZProducts = [...allAzProductsListResponse.data]
  }

  refreshData = (): Promise<void> => {
    return this.getAllAzProductsList()
  }

  requestAppAccess = (id: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      postApi(`${storeUrls.requestAccessForApplicationById}${id}`, {})
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(() => reject())
    })
  }

  toggleApplicationFavourite = async (id: string): Promise<void> => {
    await postApi(`application/favourite?id=${id}`, null)
    const indexOfApplication = this.allAtoZProducts.findIndex(x => x.id === id)
    const application = this.allAtoZProducts[indexOfApplication]
    const newApplication = { ...application, isFavourited: !application.isFavourited }
    const newArray = [...this.allAtoZProducts]
    newArray[indexOfApplication] = newApplication
    this.allAtoZProducts = newArray
  }
}

export default new AtoZStore()
