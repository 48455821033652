import { Button } from "nhsuk-react-components"
import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import {
  LeftArrowIcon as ArrowLeft,
  RightArrowIcon as ArrowRight,
} from "../../../../../global/components/icons"
import { ISearchResultsPagination } from "../../interfaces"
import "../../styles.scss"

interface ISearchResultsFooterProps {
  pagination: ISearchResultsPagination
  total: number | null
}

const SearchResultsFooter = (props: ISearchResultsFooterProps) => {
  const { pagination, total } = props

  const { search } = useLocation()

  const pageQuery = `&Page=${pagination.pageNumber}`

  const calculateTotalPages = (total: number | null) => Math.ceil((total || 0) / 10)

  const calculatePageNumbers = (total: number | null, page: number) => {
    const numberOfPages = Math.ceil((total || 0) / 10)
    const isLastPage = page === numberOfPages ? page : page + 1
    const eightCount = page > 7 ? isLastPage : 8
    const calc = numberOfPages < 11 ? numberOfPages : eightCount
    const newIndex = page > 7 && numberOfPages > 10 ? page - 6 : 1
    const res = []

    for (let index = newIndex; index <= calc; index++) {
      if (index === page) {
        res.push(
          <p key={`${index}-${page}`} className="search-results__pagination-current">
            {index}
          </p>
        )
      } else {
        res.push(
          <NavLink
            key={`${index}-${page}`}
            className="search-results__pagination-number"
            to={`/search${search.replace(pageQuery, `&Page=${index}`)}`}
          >
            {index}
          </NavLink>
        )
      }
    }

    if (newIndex > 1) {
      res.unshift(
        <p key={`${newIndex}-dots`} className="search-results__pagination-current">
          ...
        </p>
      )
      res.unshift(
        <NavLink
          key={`1-${page}`}
          className="search-results__pagination-number"
          to={`/search${search.replace(pageQuery, "&Page=1")}`}
        >
          1
        </NavLink>
      )
    }
    if (numberOfPages > 10 && numberOfPages !== page && page + 1 !== numberOfPages) {
      res.push(
        <p key={`${numberOfPages}-dots`} className="search-results__pagination-current">
          ...
        </p>
      )
      res.push(
        <NavLink
          key={`${numberOfPages}`}
          className="search-results__pagination-number"
          to={`/search${search.replace(pageQuery, `&Page=${numberOfPages}`)}`}
        >
          {numberOfPages}
        </NavLink>
      )
    }

    return res
  }

  const scrollToTop = () => {
    const element = document.getElementById("search-results")
    if (element) {
      element.scrollIntoView(true)
    }
  }

  return (
    <div className="search-results__pagination">
      <NavLink
        to={`/search${search.replace(pageQuery, `&Page=${pagination.previousPage}`)}`}
        className={`search-results__pagination-link${
          pagination.pageNumber < 2 ? " search-results__pagination-link--hidden" : ""
        }`}
      >
        <ArrowLeft />
        previous
      </NavLink>
      <nav className="search-results__pagination-numbers">
        {calculatePageNumbers(total, pagination.pageNumber)}
      </nav>
      <div>
        <NavLink
          to={`/search${search.replace(pageQuery, `&Page=${pagination.nextPage}`)}`}
          className={`search-results__pagination-link${
            pagination.pageNumber === calculateTotalPages(total)
              ? " search-results__pagination-link--hidden"
              : ""
          }`}
        >
          next
          <ArrowRight />
        </NavLink>
        <p className="search-results__pagination-totals">{`${
          pagination.pageNumber
        } of ${calculateTotalPages(total)}`}</p>
        <Button className="search-results__scroll-top" onClick={scrollToTop}>
          Back to top
        </Button>
      </div>
    </div>
  )
}

export default SearchResultsFooter
