import React from "react"
import PropTypes from "prop-types"
import "./Breadcrumbs.scss"

const Breadcrumbs = props => {
  const { list } = props
  return (
    <div className="az-page__breadcrumbs">
      {list.map(
        item =>
          item !== "" && (
            <div key={list.indexOf(item)} className="az-page__breadcrumbs__path">
              <div className="az-page__breadcrumbs__text">{item}</div>
            </div>
          )
      )}
    </div>
  )
}

Breadcrumbs.propTypes = {
  list: PropTypes.array.isRequired,
}

export default Breadcrumbs
