import React from "react"
import { CategoryPanel } from "../"
import { IApplicationTile } from "../../../global/interfaces/application/interfaces"
import { ICoreProductTopicWithTilesAndRef } from "../../interfaces"
import "./styles.scss"

interface ICategoriesContainerProps {
  categories: ICoreProductTopicWithTilesAndRef[]
  requestAppAccess: (id: string, stepTwoRequired: boolean) => Promise<void>
  tableauWebWrapperEnabled: boolean
  toggleModal: (tile: IApplicationTile) => void
  toggleFavourite: (topicId: string, tileId: string) => Promise<void>
}

const CategoriesContainer = (props: ICategoriesContainerProps) => {
  const { categories, requestAppAccess, tableauWebWrapperEnabled, toggleModal, toggleFavourite } =
    props

  return (
    <div className="categories-container" id="categories-container">
      {categories.map((category, idx) => (
        <CategoryPanel
          key={`category-panel-${category.coreProductTopic.id}`}
          category={category}
          requestAppAccess={requestAppAccess}
          tableauWebWrapperEnabled={tableauWebWrapperEnabled}
          toggleModal={toggleModal}
          toggleFavourite={toggleFavourite}
          lastPanel={idx === categories.length - 1}
        />
      ))}
    </div>
  )
}

export default CategoriesContainer
