import { observer } from "mobx-react"
import { BodyText, Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../../../global/components/HOC"
import { Routes } from "../../../global/enums"

const EmailVerificationExpiry = observer(() => {
  return (
    <>
      <Container id="page-not-found">
        <Col width="three-quarters">
          <Label isPageHeading style={{ marginTop: "1.875rem" }}>
            This verification link has expired
          </Label>
          <BodyText>
            Your account may be active, to sign in please click{" "}
            <a href={Routes.Authentication}>here</a>, alternately, you can reset your password{" "}
            <a href={Routes.ProfileUpdatePasswordUrl}>here</a>.
          </BodyText>
        </Col>
      </Container>
    </>
  )
})

export default withWrapper(EmailVerificationExpiry)
