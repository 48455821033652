import React, { createRef, useRef } from "react"
import ErrorContainer from "../../../global/components/errorContainer"
import TextArea from "../../../global/components/textArea/textArea"
import useInputCursorPosition from "../../../global/hooks/useInputCursorPosition"

interface IProcessResultReason {
  askForReason: boolean
  requestProcessErrorMessage: string
  applyRequestProcessReason: (adminSuppliedReason: string) => void
  requestProccessResultReason: string
  header: string
  showHeader: boolean
  showError: boolean
  triedToSumbit: boolean
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>
  setShowError: React.Dispatch<React.SetStateAction<boolean>>
}

const ProcessResultReason = ({
  askForReason,
  requestProcessErrorMessage,
  applyRequestProcessReason,
  requestProccessResultReason,
  header,
  showHeader,
  showError,
  triedToSumbit,
  setShowHeader,
  setShowError,
}: IProcessResultReason) => {
  const errorContainerRef = createRef<HTMLDivElement>()

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const [setCursorPosition] = useInputCursorPosition(textAreaRef)

  const onTextAreaChanged = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value

    const position = e.currentTarget?.selectionStart ?? value.length

    if (triedToSumbit) {
      setShowHeader(true)
      setShowError(false)
    }

    setCursorPosition(position)

    applyRequestProcessReason(value)
  }

  return (
    <ErrorContainer
      message={showError && askForReason ? requestProcessErrorMessage : undefined}
      inputRef={errorContainerRef}
    >
      {showHeader && <h5>{header}</h5>}

      <TextArea
        ref={textAreaRef}
        value={requestProccessResultReason}
        onChange={onTextAreaChanged}
        rows={4}
      />
    </ErrorContainer>
  )
}

export default ProcessResultReason
