import React, { useState, Fragment } from "react"
import _ from "lodash"
import { AdminModalTypePrompts } from "./adminModalTypePrompts"
import { Label, BodyText, Button } from "nhsuk-react-components"
import ProcessResultReason from "./proccessResultReason"
import { IProductOwnerApplication } from "../../global/interfaces/productOwner/interfaces"

interface IPerson {
  name: string
  organisation: string
}

interface IModelContent {
  application: IProductOwnerApplication
  people: IPerson[]
  close: () => void
  type: string
  onSubmit: () => void
  askForReason: boolean
  requestProcessErrorMessage: string
  applyRequestProcessReason: (adminSuppliedReason: string) => void
  requestProccessResultReason: string
  shouldRequestProcessingResultReason: boolean
}

const ModalContent = (props: IModelContent) => {
  const {
    application,
    people,
    close,
    type = "approve",
    onSubmit,
    askForReason,
    requestProcessErrorMessage,
    applyRequestProcessReason,
    requestProccessResultReason,
    shouldRequestProcessingResultReason,
  } = props

  const [showHeader, setShowHeader] = useState<boolean>(true)

  const [triedToSumbit, setTriedToSubmit] = useState<boolean>(false)

  const [showError, setShowError] = useState<boolean>(false)

  const header = AdminModalTypePrompts[type] ?? AdminModalTypePrompts["rejected"]

  const handleSumbit = () => {
    setTriedToSubmit(true)

    const isValid = requestProccessResultReason.length > 5

    if (!isValid) {
      setShowHeader(false)
      setShowError(true)
    } else {
      setShowHeader(true)
      setShowError(false)
    }

    onSubmit()
  }

  return (
    <>
      <Label bold>
        Are you sure you want to&nbsp;{type}&nbsp;access to
        {application && !_.isEmpty(application)
          ? ` ${application.name}`
          : ` NHS England applications`}{" "}
        for the following people?
      </Label>

      {people.map((person: IPerson, idx: number) => (
        <BodyText key={`${idx}_${person.name}`}>
          <span>
            {person.name}
            {person.organisation ? <b>{" - " + person.organisation}</b> : ""}
          </span>
        </BodyText>
      ))}

      <hr />

      {shouldRequestProcessingResultReason && (
        <Fragment>
          <ProcessResultReason
            triedToSumbit={triedToSumbit}
            showError={showError}
            setShowHeader={setShowHeader}
            setShowError={setShowError}
            showHeader={showHeader}
            header={header}
            askForReason={askForReason}
            requestProcessErrorMessage={requestProcessErrorMessage}
            applyRequestProcessReason={applyRequestProcessReason}
            requestProccessResultReason={requestProccessResultReason}
          />

          <hr />
        </Fragment>
      )}

      <div className="group--buttons">
        <BodyText onClick={close} tabIndex={1} className="cancel--button">
          No, cancel
        </BodyText>
        <Button secondary={false} onClick={handleSumbit}>
          Yes,&nbsp;{type}&nbsp;access
        </Button>
      </div>
    </>
  )
}

export default ModalContent
