import React, { useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { useParams, useLocation } from "react-router-dom"
import AnalyticalFooter from "../global/components/analyticalFooter"
import { withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import Modal from "../global/components/modal"
import { useStores } from "../global/hooks"
import FloatingHeader from "./components/floatingHeader/floatingHeader"
import CustomViewsModalContent from "./components/modalContents/customViewsModalContent/customViewsModalContent"
import DownloadModalContent from "./components/modalContents/downloadModalContent/downloadModalContent"
import ShareModalContent from "./components/modalContents/shareModalContent/shareModalContent"
import TableauToolbar from "./components/tableauToolbar/tableauToolbar"
import UnauthorisedReportView from "./components/unauthorisedReportView/unauthorisedReportView"
import { Modals } from "./store/tableauReportViewerStore"
import { CustomView, TableauViz } from "@tableau/embedding-api"
import useTableauConfiguration from "./configurationHook/useTableauConfiguration"
import { useNavigate } from "react-router-dom"

import "./styles.scss"

const Report = observer(() => {
  const {
    tableauReportViewerStore: {
      toolbarButtons: {
        undo,
        redo,
        revert,
        refresh,
        pause,
        favourite,
        share,
        download,
        customViewManagement,
      },
      getReport,
      toggleFavourite,
      currentTab,
      setCurrentView,
      getCurrentReport,
      setBaseUrl,
      authorised,
      currentView,
      currentReport,
      contactInfo,
      visualizationLoading,
      setCustomViews,
      customViews,
      tableauVisualization,
      setFrameSize,
    },
    accessPermissionsStore: { getAccessPermissionsData },
    externalRouteStore: { analyticalProductsFeedbackURL },
  } = useStores()

  const {
    None: noModal,
    Share: shareModal,
    Download: downloadModal,
    CustomViewManagement: customViewManagementModal,
  } = Modals

  const tableauVisualizationRef = useRef<HTMLDivElement>(null)
  const [minimized, setMinimized] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hasErrored, setHasErrored] = useState(false)
  const [activeModal, setActiveModal] = useState(noModal)
  const [showViewsDropdown, setShowViewsDropdown] = useState(false)
  const { reportId } = useParams()
  const { wrapWithLoader } = useContext(LoaderContext)
  const viewerRootRef = useRef<HTMLDivElement>(null)
  const floatingHeaderRef = useRef<HTMLDivElement>(null)
  const reportViewerRef = useRef<HTMLDivElement>(null)
  const viewsDropdownRef = useRef<HTMLDivElement>(null)
  const viewsButtonRef = useRef<HTMLDivElement>(null)
  useTableauConfiguration(tableauVisualizationRef)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!visualizationLoading && tableauVisualization?.workbook) {
      wrapWithLoader(async () => {
        const activateCustomView = (customView: CustomView | undefined) => {
          if (!customView) {
            return
          }
          const customViewName = customView.name,
            customViewUrl = customView.url
          tableauVisualization.workbook.showCustomViewAsync(customViewName).then(() => {
            setCurrentView(customViewName)
            setBaseUrl(customViewUrl)
          })
        }
        await setCustomViews()
        activateCustomView(
          currentView
            ? customViews.find(v => v.name === currentView)
            : customViews.find(v => v.default)
        )
      })
    }
  }, [visualizationLoading])

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeMinimized =
        floatingHeaderRef.current &&
        reportViewerRef.current &&
        floatingHeaderRef.current.offsetTop + floatingHeaderRef.current.offsetHeight >
          reportViewerRef.current.offsetTop
      if (shouldBeMinimized !== minimized) {
        setMinimized(shouldBeMinimized ?? false)
      }

      if (showViewsDropdown) {
        setShowViewsDropdown(false)
      }
    }
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    const root = document.getElementById("root")!
    root.addEventListener("scroll", handleScroll)

    return () => root.removeEventListener("scroll", handleScroll)
  }, [minimized, showViewsDropdown])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!visualizationLoading) {
        if (
          (viewsDropdownRef &&
            viewsDropdownRef.current &&
            !viewsDropdownRef.current.contains(event.target as Node)) ||
          !viewsDropdownRef.current
        ) {
          if (
            viewsButtonRef &&
            viewsButtonRef.current &&
            viewsButtonRef.current.contains(event.target as Node)
          ) {
            setShowViewsDropdown(show => !show)
          } else if (showViewsDropdown) {
            setShowViewsDropdown(false)
          }
        }
      }
    }

    const handleResize = () => {
      if (showViewsDropdown) {
        setShowViewsDropdown(false)
      }
    }
    window.addEventListener("resize", handleResize)
    window.addEventListener("mousedown", handleClick)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("mousedown", handleClick)
    }
  }, [visualizationLoading, showViewsDropdown])

  useEffect(() => {
    if (viewerRootRef.current) {
      viewerRootRef.current.style.overflowX = "hidden"
    }
  }, [viewerRootRef.current])

  useEffect(() => {
    if (!reportId) return

    wrapWithLoader(async () => {
      try {
        await getReport(reportId)
        const application = getCurrentReport()
        if (application) {
          await getAccessPermissionsData(application.applicationId)
          const searchParams = new URLSearchParams(location.search)
          const customViewName = searchParams.get("customViewName")
          if (customViewName) {
            setCurrentView(customViewName)
          }
          const customViewUrl = searchParams.get("customViewUrl")
          if (customViewUrl) {
            setBaseUrl(customViewUrl)
          }
        }
      } catch {
        setHasErrored(true)
      } finally {
        setLoading(false)
      }
    })
  }, [reportId])

  useEffect(() => {
    if (!tableauVisualization?.workbook || currentTab.length === 0) return

    wrapWithLoader(async () => {
      await tableauVisualization.workbook.activateSheetAsync(currentTab)

      setFrameSize(tableauVisualization ?? undefined, tableauVisualization.workbook)
    })
  }, [currentTab])

  const closeModal = () => onActiveModalChange(noModal)

  const onButtonClicked = (
    buttonId: number,
    tableauVisualization: TableauViz,
    visualizationLoading: boolean
  ) => {
    if (visualizationLoading) return
    let func = () => {
      alert("Unrecognized button.")
    }

    switch (buttonId) {
      case undo.id:
        func = async () => await tableauVisualization.undoAsync()
        break
      case redo.id:
        func = async () => await tableauVisualization.redoAsync()
        break
      case revert.id:
        func = async () => await tableauVisualization.revertAllAsync()
        break
      case pause.id:
        func = async () => tableauVisualization.pauseAutomaticUpdatesAsync()
        break
      case refresh.id:
        func = async () => await tableauVisualization.refreshDataAsync()
        break
      case favourite.id:
        func = async () => toggleFavourite()
        break
      case share.id:
        func = async () =>
          await new Promise(resolve => {
            onActiveModalChange(shareModal)
            resolve()
          })
        break
      case download.id:
        func = async () =>
          await new Promise(resolve => {
            onActiveModalChange(downloadModal)
            resolve()
          })
        break
      case customViewManagement.id:
        func = async () =>
          await new Promise(resolve => {
            onActiveModalChange(customViewManagementModal)
            resolve()
          })
        break
      default:
        break
    }

    wrapWithLoader(async () => func())
  }

  const onActiveModalChange = (id: Modals) => {
    setShowViewsDropdown(false)
    setActiveModal(id)
  }

  if (loading) {
    return null
  }

  if (hasErrored) {
    navigate(`/report/${reportId}/reportnotfound`)
  }

  if (!authorised) {
    return (
      <>
        <UnauthorisedReportView reportId={reportId} />
        <AnalyticalFooter feedbackUrl={analyticalProductsFeedbackURL} />
      </>
    )
  }

  return (
    <div id="report-viewer-root" ref={viewerRootRef}>
      <TableauToolbar
        viewsButtonRef={viewsButtonRef}
        viewsReference={viewsDropdownRef}
        onButtonClicked={onButtonClicked}
        showViewsDropdown={showViewsDropdown}
        minimized={minimized}
        closeModal={closeModal}
      />
      <div id="header-section" className="sticky" ref={floatingHeaderRef}>
        {activeModal !== noModal && (
          <Modal close={closeModal}>
            {activeModal === shareModal ? (
              <ShareModalContent close={closeModal} />
            ) : activeModal === downloadModal ? (
              <DownloadModalContent close={closeModal} />
            ) : (
              <CustomViewsModalContent closeModal={closeModal} />
            )}
          </Modal>
        )}
        <FloatingHeader minimized={minimized} />
      </div>

      <div id="content" className="tableau-worksheet" ref={tableauVisualizationRef}></div>

      {contactInfo?.userSupportEmail && (
        <AnalyticalFooter
          dashboardTitle={currentReport ? currentReport?.title : ""}
          userSupportEmail={contactInfo.userSupportEmail}
        />
      )}
    </div>
  )
})

export default withWrapper(Report)
