import React from "react"
import "./styles.scss"

const Spinner = () => {
  return (
    <div
      data-testid="notification-spinner__loading-container"
      className="notification-spinner__loading-container"
    >
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
      <div className="notification-spinner__loading-container__child"></div>
    </div>
  )
}

export default Spinner
