import { observer } from "mobx-react"
import { Fieldset, Form, Radios } from "nhsuk-react-components"
import React from "react"
import { useStores } from "../../../../../global/hooks"
import IndividualTabDownloadView from "../individualTabDownloadView"
import SelectionOptions from "../selectionOptions"
import "./styles.scss"

type eventTarget = {
  target: HTMLInputElement
}

const TableauHeaderDownLoadOptionGroup = observer((): React.ReactElement => {
  const {
    tableauReportViewerConfigurationStore: {
      tableauReportViewerData: { workbook },
      editWorkbookProperty,
      setSelectedWorkbookDownloadOption,
    },
    tableauReportViewerConfigurationStore,
  } = useStores()

  const onChange = (event: React.FormEvent<HTMLDivElement> & eventTarget) => {
    const value = parseInt(event.target.value)
    editWorkbookProperty(wb => (wb.dataDownloadFileTypes = value))
    setSelectedWorkbookDownloadOption(value)
  }

  return (
    <Form className="access-permissions-form-group">
      <Fieldset>
        <Fieldset.Legend className="tableauHeaderDownLoadOptionGroup__legend">
          Can users download data from the product?
        </Fieldset.Legend>
        <Radios
          onChange={(event: React.FormEvent<HTMLDivElement> & eventTarget) => onChange(event)}
          className="tableauHeaderDownLoadOptionGroup__radios"
        >
          <Radios.Radio
            id="download-yes-all-tabs"
            defaultChecked={workbook.selectedDownloadOption === 2}
            value={2}
          >
            <span className="access-permissions-form-group__boldtext">Yes</span>, Allow download for
            all tabs
          </Radios.Radio>
          {workbook.selectedDownloadOption === 2 && <SelectionOptions indent />}

          <Radios.Radio
            id="download-yes-individual"
            defaultChecked={workbook.selectedDownloadOption === 1}
            value={1}
          >
            <span className="access-permissions-form-group__boldtext">Yes</span>, Allow download for
            individual tabs
          </Radios.Radio>
          {workbook.selectedDownloadOption === 1 && <IndividualTabDownloadView />}
          <Radios.Radio
            id="download-no"
            defaultChecked={workbook.selectedDownloadOption === 0}
            value={0}
          >
            <span className="access-permissions-form-group__boldtext">No</span>, Users will still be
            able to download an image of the data
          </Radios.Radio>
        </Radios>
      </Fieldset>
    </Form>
  )
})

export default TableauHeaderDownLoadOptionGroup
