import React, { memo, useState, useRef } from "react"
import CloseIcon from "../../../../../admin/assets/close.svg"
import Text from "../../../text/text"
import "../../styles.scss"
import AccessContainer from "./components/accessContainer/accessContainer"
import MockSelectBox from "./components/mockSelectBox/mockSelectBox"
import Search from "./components/search/search"
import TagsSelectionModal from "./components/tagsSelectionModal/tagsSelectionModal"

import "./styles.scss"

const FiltersContainer = props => {
  const {
    accessSelectionList,
    setAccessSelectionList,
    appNameSearchValue,
    setAppNameSearchValue,
    tagsList,
    removeTag,
    onTagSelectStateChanged,
    selectedTags,
  } = props

  const [showTagsDialog, setShowTagsDialog] = useState(false)
  const mockSelectRef = useRef()

  const onClearAppSearch = e => {
    e.preventDefault()
    setAppNameSearchValue("")
  }

  const onAppSearchChange = e => {
    e.preventDefault()
    setAppNameSearchValue(e.target.value)
  }

  return (
    <div className="az-page__filters__container">
      <span className="az-page__filters__container__header">Filter</span>
      <div className="az-page__app-search-name">
        <form className="az-page__searchbox">
          <label htmlFor="app-name-search">
            <Text textType="label">Application name</Text>
          </label>
          <Search
            onChange={e => onAppSearchChange(e)}
            onClear={e => onClearAppSearch(e)}
            value={appNameSearchValue}
            placeholder="Enter application name"
          />
        </form>
      </div>
      <div className="az-page__app-search-tags">
        <form className="az-page__searchbox">
          <label htmlFor="app-search-tags">
            <Text textType="label">Tags</Text>
          </label>
          <MockSelectBox
            ref={mockSelectRef}
            onClick={() => setShowTagsDialog(!showTagsDialog)}
            text="Select tag(s)"
            open={showTagsDialog}
          />
          {showTagsDialog && (
            <TagsSelectionModal
              tagsList={tagsList}
              setShowTagsDialog={setShowTagsDialog}
              onTagSelectStateChanged={onTagSelectStateChanged}
              selectedTags={selectedTags}
              mockSelectRef={mockSelectRef}
            />
          )}
        </form>
      </div>
      {selectedTags.length > 0 ? (
        <ul className="az-page__tags">
          {selectedTags.map(selectedTag => {
            return (
              <li key={selectedTag} className="az-page__tag-item az-page__tag-item__text">
                {selectedTag}
                <button className="az-page__tag-item-close" onClick={() => removeTag(selectedTag)}>
                  <img className="az-page__tag-item__icon" src={CloseIcon} alt="close" />
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        ""
      )}
      <AccessContainer
        accessSelectionList={accessSelectionList}
        setAccessSelectionList={setAccessSelectionList}
      />
    </div>
  )
}

export default memo(FiltersContainer)
