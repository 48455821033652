import React from "react"
import "../styles.scss"

export const ChevronLeftIcon = () => {
  return (
    <svg
      id="chevron-left"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M4 8c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L6.4 8l4.3 4.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-5-5C4.1 8.5 4 8.3 4 8z"
      />
    </svg>
  )
}

export const RedoIcon = () => {
  return (
    <svg
      id="redo-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M11.004 8.004c.002.266-.103.521-.29.71l-5 5c-.254.254-.623.353-.97.26-.346-.093-.617-.364-.71-.71-.093-.346.006-.716.26-.97l4.3-4.29-4.3-4.29c-.392-.392-.392-1.028 0-1.42.392-.392 1.028-.392 1.42 0l5 5c.187.189.292.444.29.71z"
      />
    </svg>
  )
}

export const RevertIcon = () => {
  return (
    <svg
      id="revert-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M4 8c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L6.4 8l4.3 4.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-5-5C4.1 8.5 4 8.3 4 8z"
      />
    </svg>
  )
}

export const RefreshIcon = () => {
  return (
    <svg
      id="refresh-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.511 8l-2.84-3.11L9.879 8h1.877c0 2.071-1.684 3.756-3.755 3.756-1.015 0-1.936-.406-2.614-1.065l-1.253 1.397c1.008.955 2.369 1.545 3.867 1.545 3.112 0 5.633-2.521 5.633-5.633h1.878zM3.328 11.11L6.122 8H4.244C4.244 5.929 5.93 4.244 8 4.244c1.015 0 1.936.406 2.614 1.065l1.253-1.397C10.857 2.957 9.497 2.367 8 2.367 4.888 2.367 2.367 4.888 2.367 8H.489l2.839 3.11z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const ViewIcon = () => {
  return (
    <svg
      id="view-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.27 3.23c-2.67 0-4.994 1.632-6.193 4.04 1.199 2.406 3.522 4.038 6.192 4.038 2.67 0 4.994-1.632 6.192-4.039C12.263 4.862 9.94 3.231 7.27 3.231zm2.422 4.04c0 1.337-1.085 2.422-2.423 2.422S4.846 8.607 4.846 7.27 5.931 4.846 7.27 4.846 9.692 5.931 9.692 7.27zM7.27 8.614c.744 0 1.346-.602 1.346-1.346 0-.743-.602-1.346-1.346-1.346-.743 0-1.346.603-1.346 1.346 0 .744.603 1.346 1.346 1.346z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const DownloadIcon = () => {
  return (
    <svg
      id="download-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.389 7.817V.933H6.144v6.884L3.967 5.673l-.934.919 3.734 3.675L10.5 6.592l-.933-.92-2.178 2.145zm4.744 3.85H1.867v1.4h10.266v-1.4z"
        clipRule="evenodd"
      />
      <mask id="d8cp46ru4a" width="12" height="14" x="1" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.389 7.817V.933H6.144v6.884L3.967 5.673l-.934.919 3.734 3.675L10.5 6.592l-.933-.92-2.178 2.145zm4.744 3.85H1.867v1.4h10.266v-1.4z"
          clipRule="evenodd"
        />
      </mask>
    </svg>
  )
}

export const DownloadIconDisabledIcon = () => {
  return (
    <svg
      id="download-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#425563"
        fillRule="evenodd"
        d="M7.389 7.817V.933H6.144v6.884L3.967 5.673l-.934.919 3.734 3.675L10.5 6.592l-.933-.92-2.178 2.145zm4.744 3.85H1.867v1.4h10.266v-1.4z"
        clipRule="evenodd"
      />
      <mask id="d8cp46ru4a" width="12" height="14" x="1" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#425563"
          fillRule="evenodd"
          d="M7.389 7.817V.933H6.144v6.884L3.967 5.673l-.934.919 3.734 3.675L10.5 6.592l-.933-.92-2.178 2.145zm4.744 3.85H1.867v1.4h10.266v-1.4z"
          clipRule="evenodd"
        />
      </mask>
    </svg>
  )
}

export const PauseIcon = () => {
  return (
    <svg
      className="pause-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <rect width="2.107" height="12" x="4.879" y="2.5" fill="#fff" rx="1.053" />
      <rect width="2.107" height="12" x="9.015" y="2.5" fill="#fff" rx="1.053" />
    </svg>
  )
}

export const ShareIcon = () => {
  return (
    <svg
      id="share-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#FFFFFF"
        fillRule="evenodd"
        d="M23.333 19.167c-1.208 0-2.292.532-3.052 1.355l-7.583-4.094c.125-.459.218-.928.218-1.428 0-.53-.104-1.02-.249-1.499l7.605-4.021c.769.822 1.853 1.354 3.061 1.354 2.302 0 4.167-1.865 4.167-4.167 0-2.301-1.865-4.167-4.167-4.167-2.301 0-4.166 1.866-4.166 4.167 0 .333.052.657.124.97l-7.614 4.03c-.959-1.135-2.364-1.875-3.969-1.875C4.833 9.792 2.5 12.126 2.5 15c0 2.875 2.333 5.21 5.208 5.21 1.625 0 3.063-.761 4.021-1.938l7.562 4.084c-.072.322-.124.645-.124.978 0 2.301 1.865 4.167 4.166 4.167 2.302 0 4.167-1.866 4.167-4.167 0-2.301-1.865-4.167-4.167-4.167z"
        clipRule="evenodd"
      />
    </svg>
  )
}
