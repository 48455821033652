import { BodyText, ErrorMessage, Label } from "nhsuk-react-components"
import React from "react"
import { SelectAdditionalProps } from "../../types"
import "./styles.scss"

const SelectContainer = (props: SelectAdditionalProps) => {
  const { id, noValidation, error, name, hint, children } = props

  return (
    <div className="searchable-select-wrapper" id={id}>
      {noValidation || (
        <div className={`validation-error-bar${error ? " validation-error-bar-errored" : ""}`} />
      )}
      <div
        className={noValidation ? "validation-container--no-validation" : "validation-container"}
      >
        {name && <Label className="field-name">{name}</Label>}
        {hint && <BodyText className="field-hint">{hint}</BodyText>}
        {noValidation || (error && <ErrorMessage>{error}</ErrorMessage>)}
        <div className="searchable-select-inner-container">{children}</div>
      </div>
    </div>
  )
}

export default SelectContainer
