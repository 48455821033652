import React from "react"

const ArrowIcon = () => {
  return (
    <svg className="summary-arrow-icon" height="0.875rem" width="0.875rem">
      <defs>
        <clipPath id="arrow-icon" clipPathUnits="objectBoundingBox">
          <path d="M0,0,1,0.5,0,1" />
        </clipPath>
      </defs>
      <rect height="14" width="14" fill="#005eb8" clipPath="url('#arrow-icon')" />
    </svg>
  )
}

export default ArrowIcon
