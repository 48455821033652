import React from "react"

import { RemoteComponent } from "../../remoteComponent"
import { observer } from "mobx-react"
import { withWrapper } from "../global/components/HOC"
import { getJWT } from "../global/utils/cookies"

interface IMobilePageProps {
  token: string | undefined
}

const MobileLandingPage = observer(() => {
  const MobilePageUrl = `${process.env.REACT_APP_MHS_MOBILE_RC_URL}`
  const MobilePage = (props: IMobilePageProps) => <RemoteComponent url={MobilePageUrl} {...props} />

  return <MobilePage token={getJWT()} />
})

export default withWrapper(MobileLandingPage)
