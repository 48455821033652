import { Radios } from "nhsuk-react-components"
import React, { useState } from "react"
import { IRadiosOnChangeEvent } from "../../../global/interfaces/nhs-uk"
import { INotificationEvent } from "../../../global/interfaces/notification"
import "./styles.scss"

enum Radio {
  Urgent,
  Unread,
  All,
}

interface IFilterModeControlsProps {
  onCancelFilterMode: () => void
  setFilter: (func: () => (notificationEvent: INotificationEvent) => boolean) => void
  onMarkAllAsRead: () => void
  onDeleteAll: () => void
}

const FilterModeControls = (props: IFilterModeControlsProps) => {
  const { onCancelFilterMode, setFilter, onDeleteAll, onMarkAllAsRead } = props

  const [activeRadio, setActiveRadio] = useState<Radio>(Radio.All)

  const onChangeFilterMethod = (event: React.FormEvent<HTMLDivElement> & IRadiosOnChangeEvent) => {
    const parsedRadioId = parseInt(event.target.value)

    if (isNaN(parsedRadioId) || parsedRadioId === activeRadio) {
      return
    }

    let filter: () => (notificationEvent: INotificationEvent) => boolean =
      () => (_: INotificationEvent) =>
        true

    switch (parsedRadioId) {
      case Radio.Urgent:
        filter = () => ne => ne.urgent
        break
      case Radio.Unread:
        filter = () => ne => !ne.read
        break
    }

    setFilter(filter)
    setActiveRadio(parsedRadioId)
  }

  return (
    <div
      data-testid="notifications-page__filter-mode-controls"
      className="notifications-page__filter-mode-controls"
    >
      <div className="notifications-page__filter-mode-controls__radios-container">
        <Radios
          className="notifications-page__filter-mode-controls__radios-container__radios"
          onChange={onChangeFilterMethod}
          inline
        >
          <Radios.Radio
            className="notifications-page__filter-mode-controls__radios-container__radios__radio"
            checked={activeRadio === Radio.Urgent}
            value={Radio.Urgent}
          >
            Urgent
          </Radios.Radio>
          <Radios.Radio
            className="notifications-page__filter-mode-controls__radios-container__radios__radio"
            checked={activeRadio === Radio.Unread}
            value={Radio.Unread}
          >
            Unread
          </Radios.Radio>
          <Radios.Radio
            className="notifications-page__filter-mode-controls__radios-container__radios__radio"
            checked={activeRadio === Radio.All}
            value={Radio.All}
          >
            All
          </Radios.Radio>
        </Radios>
      </div>
      <div className="notifications-page__filter-mode-controls__controls">
        <button
          className="notifications-page__filter-mode-controls__controls__delete-all-button"
          onClick={onDeleteAll}
        >
          <span className="notifications-page__filter-mode-controls__controls__delete-all-button__text">
            Delete All
          </span>
        </button>
        <button
          className="notifications-page__filter-mode-controls__controls__mark-all-read-button"
          onClick={onMarkAllAsRead}
        >
          <span className="notifications-page__filter-mode-controls__controls__mark-all-read-button__text">
            Mark all as read
          </span>
        </button>
        <button
          className="notifications-page__filter-mode-controls__controls__cancel-button"
          onClick={onCancelFilterMode}
        >
          <span className="notifications-page__filter-mode-controls__controls__cancel-button__text">
            Cancel
          </span>
        </button>
      </div>
    </div>
  )
}

export default FilterModeControls
