import React from "react"
import PropTypes from "prop-types"
import "./RequestPending.scss"

const RequestPending = props => {
  const { date } = props
  const dateSliced = new Date(date).toString().split(" ")
  const dateRequested = `${dateSliced[2]} ${dateSliced[1]} ${dateSliced[3]}`
  return (
    <div className="az-page__request-pending__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        fill="none"
        viewBox="0 0 31 30"
      >
        <path
          fill="#AEB7BD"
          d="M4.377 12.902L25.764 3.14c1.175-.536 2.434.566 2.058 1.802l-5.785 18.986c-.28.918-1.339 1.34-2.174.868l-5.336-3.02c-.737-.417-1.672-.104-2.008.673-.977 1.479-2.732 5.605-2.732 4.06v-6.313c0-.443.196-.863.535-1.148l13.719-11.53-14.968 9.955c-.484.322-1.11.336-1.606.036L4.225 15.55c-1.032-.623-.945-2.148.152-2.649z"
        />
      </svg>{" "}
      <div className="az-page__request-pending__text">Request Sent, {dateRequested}</div>
    </div>
  )
}

RequestPending.propTypes = {
  date: PropTypes.string.isRequired,
}
export default RequestPending
