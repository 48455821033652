import React, { useState, useMemo } from "react"
import ArrowIcon from "./assets"
import "./styles.scss"

interface IShowGuidanceLinkProps {
  linkText?: string
  optionalHideLinkText?: string
  pureLinkText?: boolean
  children: React.ReactNode
}

const ShowGuidanceLink = (props: IShowGuidanceLinkProps) => {
  const { linkText = "more guidance", optionalHideLinkText, pureLinkText, children } = props

  const [open, setOpen] = useState<boolean>(false)

  const expanderText = useMemo(() => {
    const optionalHideText = optionalHideLinkText || linkText

    if (open) {
      return pureLinkText ? `${optionalHideText}.` : `Hide ${optionalHideText}.`
    }

    return pureLinkText ? linkText : `Show ${linkText}.`
  }, [open, pureLinkText, optionalHideLinkText, linkText])

  return (
    <div className="nhsuk-details">
      <button
        data-testid="show-guidance-link__expander-button"
        className={`nhsuk-details__summary${open ? " open" : ""}`}
        onClick={event => {
          event.preventDefault()
          setOpen(o => !o)
        }}
      >
        <ArrowIcon />
        <span className="nhsuk-details__summary-text">{expanderText}</span>
      </button>
      {open && <div className="nhsuk-details__text">{children}</div>}
    </div>
  )
}

export default ShowGuidanceLink
