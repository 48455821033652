import React from "react"

interface IFixedLengthSpanProps {
  className?: string
  text: string
  suffix: string
  maxLength: number
  derestrict?: boolean
}

const FixedLengthSpan = (props: IFixedLengthSpanProps) => {
  const { className, text, suffix, maxLength, derestrict = false } = props

  const correctedText =
    text.length >= maxLength && !derestrict ? `${text.slice(0, maxLength)}${suffix}` : text

  return <span className={className} dangerouslySetInnerHTML={{ __html: correctedText }} />
}

export default FixedLengthSpan
