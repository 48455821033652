import { emailRegex, urlRegex, nameRegex } from "../../constants/regex"

type StringToValidate = string | undefined | null

export const validateEmail = (email: StringToValidate) => {
  return email && emailRegex.test(email) && email.length < 257
}

export const validateUrl = (url: StringToValidate) => {
  return url && urlRegex.test(url)
}

export const validateName = (name: StringToValidate) => {
  return name && name.trim().length > 0 && name.match(nameRegex)
}
