import { observer } from "mobx-react"
import { Col } from "nhsuk-react-components"
import React, { useState } from "react"
import "url-search-params-polyfill"
import { withWrapper } from "../global/components/HOC"
import RegistrationResultView from "./components/registrationResultView"
import RegistrationView from "./components/registrationView"
import { useLocation } from "react-router-dom"
import { useStores } from "../global/hooks"
import { validateEmail } from "../global/utils/validation"

const RegistrationForm = observer(() => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const externalApplicationId = params.get("app")
  const invitationId = params.get("invite")

  const { registrationFormStore } = useStores()

  const { form, formErrors, submit, externalApplicationName } = registrationFormStore

  registrationFormStore.form.externalApplicationId = externalApplicationId
  registrationFormStore.form.invitationId = invitationId

  const [registrationStatus, setRegistrationStatus] = useState("")

  const onSubmit = () => {
    return new Promise(resolve => {
      formErrors.removeAll()
      if (validateEmail(form.email)) {
        submit(form).then(status => {
          setRegistrationStatus(status)
          resolve()
        })
      } else {
        const msg = "Please enter a valid email address"
        formErrors.add({ key: "email", value: [{ fieldError: msg, summaryError: msg }] })
        document.getElementById("root").scrollTop = 0
        resolve()
      }
    })
  }

  let viewContent
  switch (registrationStatus) {
    case "Approved":
    case "Pending":
    case "Rejected":
      viewContent = (
        <RegistrationResultView
          result={registrationStatus}
          email={registrationFormStore.form.email}
          appName={externalApplicationName}
        />
      )
      break
    default:
      viewContent = <RegistrationView onSubmit={onSubmit} />
  }

  return (
    <main className="registration-form">
      <Col className="registration-form__column" width="three-quarters">
        {viewContent}
      </Col>
    </main>
  )
})

export default withWrapper(RegistrationForm)
