import Link from "@govuk-react/link"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Col,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
  Label,
  Radios,
} from "nhsuk-react-components"
import React, { useEffect, useState, useMemo } from "react"
import { LoaderContext } from "../../../global/components/loaderProvider"
import ShowGuidanceLink from "../../../global/components/showGuidanceLink"
import { useStores } from "../../../global/hooks"
import ProductViewRedirect from "../productViewRedirect"
import { ProductEditView } from "../../enums/enums"
import TextButton from "../../../global/components/textButton"
import TableauHeaderDownLoadOptionGroup from "./components/tableauHeaderDownLoadOptionGroup"
import "./styles.scss"
import TableauServer from "./tableauServer.png"

const mapBEFieldsToIds = {
  Downloads: "download-form-group",
  EmbedPathUrl: "embed-path-url-group",
}

const TableauReportViewerView = observer(() => {
  const { productStore, tableauReportViewerConfigurationStore } = useStores()
  const { errors, workbook } = tableauReportViewerConfigurationStore.tableauReportViewerData
  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const { wrapWithLoader } = React.useContext(LoaderContext)

  useEffect(() => {
    wrapWithLoader(async () => {
      await tableauReportViewerConfigurationStore.getWorkbook(productStore.product.id)
    })
    return () => tableauReportViewerConfigurationStore.resetState()
  }, [productStore.product.id])

  useEffect(() => setIsEditMode(!productStore.published), [productStore.published])

  useEffect(() => {
    if (Object.keys(errors).length) {
      document.getElementById("root").scrollTop = 0
    }
  }, [errors])

  const setCompleteAndSave = val => {
    workbook.complete = val

    tableauReportViewerConfigurationStore.saveWorkbook().then(() => {
      productStore.setTaskModified(ProductEditView.TableauHeader, workbook.complete)
      setRedirectingToTaskList(true)
    })
  }

  const dataConfigurations = useMemo(() => {
    if (workbook.selectedDownloadOption !== 0 && workbook.downloads.length) {
      return workbook.downloads.map(x => (
        <div key={x.dashboardName}>Dashboard: {x.dashboardName}</div>
      ))
    }

    return <div>No downloads setup</div>
  }, [workbook.selectedDownloadOption, workbook.downloads])

  const permittedFileFormats = useMemo(() => {
    if (workbook.selectedDownloadOption !== 0 && workbook.selectedDownloadFormats) {
      return workbook.selectedDownloadFormats.map(df => <div key={df}>{df}</div>)
    }

    return <div>None</div>
  }, [workbook.selectedDownloadOption, workbook.selectedDownloadFormats])

  const headerType = workbook.embedEnabled ? "Customised header" : "Non-customised header"

  const dataDownloadPermitted = workbook.selectedDownloadOption === 0 ? "No" : "Yes"

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <Col className="tableau-report-viewer-view" width="full">
      {isEditMode && Object.keys(errors).length > 0 && (
        <ErrorSummary role="alert" tabIndex={-1} className="nhsuk-error-summary">
          <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
          {Object.keys(errors).map(e => (
            <ErrorSummary.Item href={`#${mapBEFieldsToIds[e]}`} key={e}>
              {errors[e]}
            </ErrorSummary.Item>
          ))}
        </ErrorSummary>
      )}
      <div className="tableau-report-viewer-view__page-heading">
        <Label isPageHeading>Additional information for Tableau</Label>
        {!isEditMode && (
          <TextButton
            className="tableau-report-viewer-view__page-heading__change-settings-button"
            onClick={() => setIsEditMode(true)}
          >
            Change settings
          </TextButton>
        )}
      </div>
      {!isEditMode ? (
        <div className="tableau-report-viewer-view__table">
          <div className="tableau-report-viewer-view__table__readonly-row">
            <p className="tableau-report-viewer-view__table__readonly-row__title-text">
              Header type
            </p>
            <p className="tableau-report-viewer-view__table__readonly-row__contact-info-text">
              {headerType}
            </p>
          </div>
          {workbook.embedEnabled && (
            <>
              <div className="tableau-report-viewer-view__table__readonly-row">
                <p className="tableau-report-viewer-view__table__readonly-row__title-text">URL</p>
                <p className="tableau-report-viewer-view__table__readonly-row__contact-info-text">
                  /report/{workbook.embedPathUrl}
                </p>
              </div>
              <div className="tableau-report-viewer-view__table__readonly-row">
                <p className="tableau-report-viewer-view__table__readonly-row__title-text">
                  Data download permitted?
                </p>
                <p className="tableau-report-viewer-view__table__readonly-row__contact-info-text">
                  {dataDownloadPermitted}
                </p>
              </div>
              {workbook.selectedDownloadFormats.length !== 0 && (
                <>
                  <div className="tableau-report-viewer-view__table__readonly-row">
                    <p className="tableau-report-viewer-view__table__readonly-row__title-text">
                      Data configurations
                    </p>
                    <p className="tableau-report-viewer-view__table__readonly-row__contact-info-text">
                      {dataConfigurations}
                    </p>
                  </div>
                  <div className="tableau-report-viewer-view__table__readonly-row">
                    <p className="tableau-report-viewer-view__table__readonly-row__title-text">
                      Permitted file formats
                    </p>
                    <p className="tableau-report-viewer-view__table__readonly-row__contact-info-text">
                      {permittedFileFormats}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <p className="tableau-report-viewer-view__info-box">
            Under most circumstances you should allow your Tableau product to be inter-linked with
            other data products, so that users can discover it more easily. Do so by giving it a
            'Customised header'. Only under exceptional circumstances might you choose not to
            inter-link it with others.
          </p>
          <Form className="tableau-report-viewer-view__embed-enabled-form-group">
            <Fieldset>
              <Fieldset.Legend>
                Select the type of header for {productStore.product.name}
              </Fieldset.Legend>
              <ShowGuidanceLink linkText="More about the types of header" pureLinkText>
                <b>Customised header:</b> selecting this option causes the product to display a
                header that has been designed to make users discover it easily, at times when it is
                most likely to be useful to them. The header also improves the user's experience of
                sharing, favouriting and downloading data from the product
                <br />
                <br />
                <b>Non-customised header:</b> selecting this option causes the product to display
                the basic header that Tableau appends to all products, as seen in the image below:
                <img
                  className="tableau-report-viewer-view__embed-enabled-form-group__tableau-server-image"
                  src={TableauServer}
                />
              </ShowGuidanceLink>
              <Radios onChange={e => (workbook.embedEnabled = e.target.value === "yes")}>
                <Radios.Radio id="embed-enabled-yes" checked={workbook.embedEnabled} value="yes">
                  Customised header (recommended)
                </Radios.Radio>
                <Radios.Radio id="embed-enabled-no" checked={!workbook.embedEnabled} value="no">
                  Non-customised header
                </Radios.Radio>
              </Radios>
            </Fieldset>
          </Form>

          {workbook.embedEnabled && (
            <>
              <Form id={mapBEFieldsToIds.EmbedPathUrl}>
                <Label className="tableau-report-viewer-view__input-label">Choose your URL</Label>
                <BodyText>
                  The product will have a different URL to the one provided by Tableau. You can
                  decide what the last part of this URL should be. We have provided a suggestion for
                  you below, which you are free to amend.
                </BodyText>
                <div className="tableau-report-viewer-view__embed-path-pre-text">
                  https://apps.model.nhs.uk/report/
                </div>
                <Input
                  className="tableau-report-viewer-view__embed-path-url"
                  value={workbook.embedPathUrl || ""}
                  onChange={e => (workbook.embedPathUrl = e.target.value)}
                  error={errors.EmbedPathUrl}
                  width={10}
                />
              </Form>

              <TableauHeaderDownLoadOptionGroup />
            </>
          )}
          {!workbook.embedEnabled && (
            <Form>
              <Label className="tableau-report-viewer-view__input-label">Your product's URL</Label>
              <BodyText className="tableau-report-viewer-view__input-hint">
                The product has been assigned a URL by Tableau. It cannot be modified.
              </BodyText>
              <BodyText className="tableau-report-viewer-view__input-hint">
                <i>{workbook.productUrl}</i>
              </BodyText>
              <br />
              <p>
                <a href={workbook.productUrl} target="_blank" rel="noopener noreferrer">
                  Preview product in the non-customised header
                </a>
              </p>
              <br />
              <br />
            </Form>
          )}
        </>
      )}
      {isEditMode && (
        <div className="tableau-report-viewer-view__button-controls">
          <Button
            className="tableau-report-viewer-view__button-controls__publish-button"
            onClick={() => setCompleteAndSave(true)}
          >
            Save and complete
          </Button>
          {!productStore.published && (
            <Link
              className="tableau-report-viewer-view__button-controls__return-link"
              onClick={() => setCompleteAndSave(false)}
            >
              Save and return to task list
            </Link>
          )}
        </div>
      )}
    </Col>
  )
})

export default TableauReportViewerView
