import { observer } from "mobx-react"
import React, { useContext, useEffect, useState } from "react"
import { withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import { useStores } from "../global/hooks"
import HeaderContainer from "./components/headerContainer"
import JobCard from "./components/jobCard"
import JobModalContent from "./components/jobModalContent"
import { IBulkInvitationJob } from "./interfaces"
import "./styles.scss"

const BulkInvitations = observer(() => {
  const {
    bulkInvitationsStore: { getIncompleteJobs, getJob, requeueJob },
  } = useStores()

  const { wrapWithLoader } = useContext(LoaderContext)
  const [jobs, setJobs] = useState<IBulkInvitationJob[]>([])
  const [selectedJob, setSelectedJob] = useState<IBulkInvitationJob | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    wrapWithLoader(async () => {
      setLoading(true)
      getIncompleteJobs().then(res => {
        setJobs(res)
        setLoading(false)
      })
    })
  }, [])

  const onModalClosed = () => setSelectedJob(null)

  const toggleModal = (job: IBulkInvitationJob) => setSelectedJob(job)

  const requeue = async (jobId: string) => wrapWithLoader(async () => requeueJob(jobId))

  const refreshIndividualJob = async (jobId: string): Promise<void> => {
    const fetchedJob = await getJob(jobId)
    if (fetchedJob !== null) {
      const jobsCopy = [...jobs]
      const jobIdx = jobsCopy.findIndex(j => j.id === jobId)
      if (jobIdx !== -1) {
        jobsCopy[jobIdx] = fetchedJob
        setJobs(jobsCopy)

        if (selectedJob && selectedJob.id === jobId) {
          setSelectedJob(fetchedJob)
        }
      }
    }
  }

  if (loading) {
    return null
  }

  return (
    <div className="bulk-invitations" id="bulk-invitations-page">
      {selectedJob && <JobModalContent job={selectedJob} close={onModalClosed} />}
      <HeaderContainer />
      {jobs.map(job => (
        <JobCard
          key={`job-card-${job.id}`}
          job={job}
          refresh={refreshIndividualJob}
          requeue={requeue}
          toggleModal={toggleModal}
        />
      ))}
    </div>
  )
})

export default withWrapper(BulkInvitations)
