import React from "react"
import { observer } from "mobx-react"
import { useStores } from "../../../global/hooks"
import FloatingBarFilter from "./floatingBarFilter/floatingBarFilter"

import "./styles.scss"

interface IFloatingHeaderProps {
  minimized: boolean
}

const FloatingHeader = observer(({ minimized }: IFloatingHeaderProps) => {
  const {
    tableauReportViewerStore: { visualizationLoading, tableauVisualization },
  } = useStores()

  if (visualizationLoading || !tableauVisualization?.workbook) {
    return <></>
  }

  return <div id="floating-bar">{!minimized && <FloatingBarFilter />}</div>
})

export default FloatingHeader
