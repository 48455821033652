import { observer } from "mobx-react"
import { Button, Container, ErrorSummary, Input, Label } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { withWrapper } from "../../../global/components/HOC"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { SetPassword } from "../../../securityPasswordForm/components"
import "./styles.scss"
import { AxiosResponse } from "axios"

const UpdatePassword = observer(() => {
  const { profileStore: store } = useStores()

  const [updated, setUpdated] = useState<boolean>(false)
  const [currentPassword, setCurrentPassword] = useState<string>(store.currentPassword)

  const change = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (target) {
      store.updatePasswordData[target.name] = target.value
    }
  }
  const { wrapWithLoader } = React.useContext(LoaderContext)

  const isErrorResponse = (response: AxiosResponse | { errors: Record<string, string> }): response is { errors: Record<string, string> } => {
    return (response as { errors?: Record<string, string> }).errors !== undefined;
  };

  const handleSubmit = () => {
    wrapWithLoader(async () => {
      const response = await store.updatePassword();
      if (!isErrorResponse(response)) {
        setUpdated(true);
      }
    });
  }

  useEffect(() => {
    return () => store.resetChanges()
  }, [])

  if (updated) {
    return (
      <Container className="update-password-view profile-update-page">
        <div>
          <Label isPageHeading className="update-password-view__page-heading-label">
            Your password has been changed
          </Label>
          <Link
            className="update-password-view__button-controls__return-link"
            to={Routes.ProfileUrl}
          >
            Go back to your account
          </Link>
        </div>
      </Container>
    )
  }

  return (
    <Container className="update-password-view profile-update-page">
      <div className="update-password-view__update-password">
        {store.error && store.error.values.length > 0 && (
          <ErrorSummary role="alert" tabIndex={-1} style={{ marginTop: "2.5rem" }}>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>

            <ErrorSummary.List>
              {store.error.values.map(err => {
                return err.value.map(inner => {
                  return (
                    <ErrorSummary.Item key={`${err.key}${inner.fieldError}`} href={`#${err.key}`}>
                      {inner.summaryError}
                    </ErrorSummary.Item>
                  )
                })
              })}
            </ErrorSummary.List>
          </ErrorSummary>
        )}
        <Label isPageHeading className="update-password-view__page-heading-label">
          Change your password
        </Label>
        <Label>Enter your current password</Label>
        <Input
          className="input-field"
          id="currentPassword"
          name="currentPassword"
          type="password"
          value={currentPassword}
          error={store.error.getFirstFieldError("currentPassword")}
          autoComplete="off"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement
            store.currentPassword = target.value
            setCurrentPassword(target.value)
          }}
        />
        <SetPassword
          form={store.updatePasswordData}
          error={store.error}
          isUpdate
          onChange={change}
        />

        <div className="update-password-view__button-controls">
          <Button
            className="update-password-view__button-controls__submit-button"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Link
            className="update-password-view__button-controls__return-link"
            to={Routes.ProfileUrl}
          >
            Go back to your account
          </Link>
        </div>
      </div>
    </Container>
  )
})

export default withWrapper(UpdatePassword)
