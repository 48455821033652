import React from "react"

const TrustIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
      <path
        fill="#425563"
        fillRule="evenodd"
        d="M11.097 4h7.806v4.258H26V26h-9.226v-4.968h-3.548V26H4V8.258h7.097V4zm-2.839 7.097H6.13v2.129h2.13v-2.13zm2.839 0h2.129v2.129h-2.13v-2.13zm7.806 0h-2.129v2.129h2.13v-2.13zm2.839 0h2.129v2.129h-2.13v-2.13zM8.258 16.064H6.13v2.13h2.13v-2.13zm2.839 0h2.129v2.13h-2.13v-2.13zm7.806 0h-2.129v2.13h2.13v-2.13zm2.839 0h2.129v2.13h-2.13v-2.13zM8.258 21.032H6.13v2.13h2.13v-2.13zm15.613 0h-2.13v2.13h2.13v-2.13z"
        clipRule="evenodd"
      />
      <mask id="hlwas7neoa" width="22" height="22" x="4" y="4" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.097 4h7.806v4.258H26V26h-9.226v-4.968h-3.548V26H4V8.258h7.097V4zm-2.839 7.097H6.13v2.129h2.13v-2.13zm2.839 0h2.129v2.129h-2.13v-2.13zm7.806 0h-2.129v2.129h2.13v-2.13zm2.839 0h2.129v2.129h-2.13v-2.13zM8.258 16.064H6.13v2.13h2.13v-2.13zm2.839 0h2.129v2.13h-2.13v-2.13zm7.806 0h-2.129v2.13h2.13v-2.13zm2.839 0h2.129v2.13h-2.13v-2.13zM8.258 21.032H6.13v2.13h2.13v-2.13zm15.613 0h-2.13v2.13h2.13v-2.13z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#hlwas7neoa)">
        <path fill="#425563" d="M4 4H26.5V26.5H4z" />
      </g>
    </svg>
  )
}

export default TrustIcon
