import { IEmailVerificationResult } from "../../../global/interfaces/verification/interfaces"
import { get } from "../../../global/utils/api"

const storeUrls = {
  verificationUrl: "/user/emailVerification?token=",
}

export interface IEmailVerificationStore {
  verifyEmail: (token: string) => Promise<IEmailVerificationResult>
}

export class EmailVerificationStore implements IEmailVerificationStore {
  verifyEmail = (token: string): Promise<IEmailVerificationResult> => {
    return new Promise<IEmailVerificationResult>((resolve, reject) => {
      get<IEmailVerificationResult>(`${storeUrls.verificationUrl}${token}`)
        .then(successfulResponse => {
          resolve(successfulResponse.data)
        })
        .catch(exception => {
          console.log(exception)
          reject(exception)
        })
    })
  }
}

export default new EmailVerificationStore()
