import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { CloseIcon } from "../../../global/components/icons"
import { getApi } from "../../../global/utils/api"
import "./styles.scss"

const AtoZModalContent = ({ title, description, tags, id, supportEmail, closeModal }) => {
  const [automaticAccessList, setAutomaticAccessList] = useState([])
  const [requestAccessList, setRequestAccessList] = useState([])
  const [loadingAPI, setLoadingAPI] = useState(true)
  useEffect(() => {
    const fetchProductAccessPermission = async () => {
      const productAccessPermissionObject = await getApi(`/accesspermissions?applicationId=${id}`)
      const { automaticAccess, requestAccess } = productAccessPermissionObject.data
      setAutomaticAccessList(automaticAccess)
      setRequestAccessList(requestAccess)
      setLoadingAPI(false)
    }
    fetchProductAccessPermission()
  }, [])

  return (
    <div className="az-modal">
      <header className="az-modal__header">
        <h2 className="az-modal__application-name">About {title}</h2>
        <button className="az-modal__close-button" onClick={closeModal}>
          <CloseIcon />
        </button>
      </header>
      <dt className="az-modal__access-term">Description</dt>
      <dd className="az-modal__copy">{description}</dd>
      <dt className="az-modal__access-term">Tags</dt>
      <dd className="az-modal__copy">
        {tags.length > 0 ? tags.join(", ") : "Product has no tags"}
      </dd>
      <dt className="az-modal__access-term">Automatic access granted to</dt>
      <dd className="az-modal__copy">
        {loadingAPI
          ? "Loading"
          : automaticAccessList.length > 0
          ? automaticAccessList.map(x => x.name).join(", ")
          : "Product has no automatic access list"}
      </dd>
      <dt className="az-modal__access-term">Request access granted to</dt>
      <dd className="az-modal__copy">
        {loadingAPI
          ? "Loading"
          : requestAccessList.length > 0
          ? requestAccessList.map(x => x.name).join(", ")
          : "Product has no request access list"}
      </dd>
      <p className="az-modal__copy az-modal__contact-email">
        <a href={`mailto:${supportEmail}`}>Contact support team</a>
      </p>
    </div>
  )
}

AtoZModalContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  automaticAccessList: PropTypes.array,
  requestAccessList: PropTypes.array,
  supportEmail: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  id: PropTypes.string.isRequired,
}

export default AtoZModalContent
