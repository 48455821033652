import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../enums/api"
import { IServerSettings } from "../interfaces/serverSettings/interfaces"
import { get } from "../utils/api"

export interface IServerSettingsStore {
  serverSettings: IServerSettings
  getServerSettings: () => Promise<IServerSettings>
}

class ServerSettingsStore implements IServerSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }
  serverSettings: IServerSettings = {
    applicationHost: "",
  }

  getServerSettings = (): Promise<IServerSettings> => {
    return new Promise<IServerSettings>((resolve, reject) =>
      get<IServerSettings>("/serverSettings/").then(res => {
        if (res.status === HttpStatusCode.OK) {
          this.serverSettings = res.data
          resolve(res.data)
        }

        reject()
      })
    )
  }
}

export default new ServerSettingsStore()
