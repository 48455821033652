import { observer } from "mobx-react"
import { Col, Container } from "nhsuk-react-components"
import React, { useEffect, useRef, useState, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import { HttpStatusCode } from "../global/enums/api"
import { useStores } from "../global/hooks"
import useRegistrationHook from "../global/hooks/useRegistration"
import { IGenericAxiosErrorResponse } from "../global/interfaces/api/interfaces"
import { IRegistrationResponse } from "../global/interfaces/registration"
import { ISecurityPasswordForm } from "../global/interfaces/security"
import { ErrorsCollection } from "../global/types"
import { convertKeyToUppercase } from "../global/utils/string"
import SecurityView from "./components/securityView"
import SuccessView from "./components/successView"
import AccountActiveView from "./components/accountActiveView"
import "./styles.scss"

const SecurityPasswordForm = observer(() => {
  const { search } = useLocation()
  const loaderContext = useContext(LoaderContext)
  const params = new URLSearchParams(search)
  const registrationToken = params.get("token")

  const navigate = useNavigate()
  const { securityPasswordFormStore: spfs } = useStores()

  const [form, setForm] = useState<ISecurityPasswordForm>({
    ...spfs.form,
    registrationToken: registrationToken || "",
  })
  const [error, setError] = useState<ErrorsCollection>(new ErrorsCollection())
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)
  const { tokenExists, existCheckCompleted } = useRegistrationHook({ registrationToken })

  const change = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement
    setForm({ ...form, [target.name]: target.value })
  }

  const mounted = useRef<boolean>(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const save = () => {
    loaderContext.startLoading()
    spfs
      .save(form)
      .then(() => {
        navigate("/register/complete", { replace: true })
        if (mounted.current) {
          setRegistrationSuccess(true)
          loaderContext.stopLoading()
        }
      })
      .catch((e: IGenericAxiosErrorResponse<IRegistrationResponse>) => {
        loaderContext.stopLoading()
        const errorsCollection = new ErrorsCollection()

        if (e.response.status === HttpStatusCode.UnprocessableEntity) {
          const { errors } = e.response.data

          const formErrors: ISecurityPasswordForm = {
            registrationToken: "",
            password: "",
            confirmPassword: "",
            recoveryQuestion: "",
            recoveryAnswer: "",
          }

          Object.keys(formErrors).forEach(formError => {
            const uppercasePropertyName = convertKeyToUppercase(formError)
            if (errors[uppercasePropertyName]) {
              errorsCollection.add({
                key: formError,
                value: [
                  {
                    fieldError: errors[uppercasePropertyName],
                    summaryError: errors[uppercasePropertyName],
                  },
                ],
              })
            }
          })

          if (mounted.current) {
            setError(errorsCollection)
          }
        } else if (e.response.status === HttpStatusCode.NotFound) {
          errorsCollection.add({
            key: "registrationToken",
            value: [
              {
                fieldError: "registrationToken",
                summaryError: "Account Already Active",
              },
            ],
          })

          if (mounted.current) {
            setError(errorsCollection)
          }
        }
      })
  }

  if (!existCheckCompleted) {
    return null
  }

  if (!tokenExists) {
    return (
      <Container className="security-password-form__registration-success">
        <Col width="three-quarters">
          <AccountActiveView />
        </Col>
      </Container>
    )
  }

  if (registrationSuccess) {
    return (
      <Container className="security-password-form__registration-success">
        <Col width="three-quarters">
          <SuccessView />
        </Col>
      </Container>
    )
  }

  return (
    <Container className="security-password-form__registration-security">
      <Col width="three-quarters">
        <SecurityView form={form} error={error} onChange={change} save={save} />
      </Col>
    </Container>
  )
})

export default withWrapper(SecurityPasswordForm)
