import { makeAutoObservable } from "mobx"
import { IApplicationTuple } from "../interfaces/application/interfaces"
import { IStringKeyedDictionary } from "../interfaces/dataTypes/interfaces"
import { get, postApi } from "../utils/api"

interface INavItem {
  title: string
  link: string
}

interface IPageSetting {
  title?: string
  hasHeader: boolean
  hasFooter: boolean
  navItems?: INavItem[]
  breadcrumbName?: string
  parent?: string
}

export interface IApplicationStore {
  appTitle: string
  selectedProductName: string
  defaultPageSettings: IPageSetting
  pageSettings: IStringKeyedDictionary<IPageSetting>
  requestApplicationAccess: (applicationId: string) => Promise<void>
  getProduct: (productId: string) => Promise<IApplicationTuple>
  getPageSettings: (path: string) => IPageSetting
}

const endPoint = "application"

const storeUrls = {
  requestApplicationAccess: `${endPoint}/request?id=`,
}

class ApplicationStore {
  constructor() {
    makeAutoObservable(this)
  }

  appTitle = "NHS England applications"

  defaultPageSettings: IPageSetting = {
    hasHeader: true,
    hasFooter: true,
    navItems: [
      {
        title: "Profile",
        link: "/myaccount",
      },
    ],
  }

  selectedProductName = ""

  pageSettings: IStringKeyedDictionary<IPageSetting> = {
    "/a-z": {
      hasHeader: true,
      hasFooter: true,
      title: "A to Z Products",
    },
    "/register": {
      hasHeader: true,
      hasFooter: false,
      title: "Register",
    },

    "/register/complete": {
      hasHeader: true,
      hasFooter: false,
    },

    "/products": {
      hasHeader: true,
      hasFooter: true,
      navItems: [
        {
          title: "Profile",
          link: "/myaccount",
        },
      ],
      breadcrumbName: "Home",
    },

    "/product": {
      hasHeader: true,
      hasFooter: true,
      title: "Edit product",
      navItems: [
        {
          title: "Profile",
          link: "/myaccount",
        },
      ],
      parent: "/appadmin",
      breadcrumbName: this.selectedProductName ? `Edit ${this.selectedProductName}` : "Edit",
    },

    "/appadmin": {
      hasHeader: true,
      hasFooter: true,
      title: "Admin",
      parent: "/products",
    },

    "/admin": {
      hasHeader: true,
      hasFooter: true,
      title: "Ops admin",
      parent: "/products",
    },

    "/myaccount": {
      hasHeader: true,
      hasFooter: true,
      title: "My account",
      parent: "/products",
      breadcrumbName: "Profile",
    },

    "/terms": {
      title: "Terms and conditions",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Terms and conditions",
    },

    "/accessibility": {
      title: "Accessibility",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Accessibility",
    },

    "/cookies": {
      title: "Cookies",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Cookies",
    },
    "/cp-accessibility": {
      title: "Accessibility",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Core Platform Accessibility",
    },

    "/cp-cookies": {
      title: "Cookies",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Core Platform Cookies",
    },

    "/invite": {
      title: "Invite user",
      hasHeader: true,
      hasFooter: true,
      parent: "/appadmin",
      breadcrumbName: "Invite user",
    },

    "/privacy": {
      title: "Privacy",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
      breadcrumbName: "Privacy",
    },

    "/report": {
      title: "Report",
      hasHeader: true,
      hasFooter: true,
      parent: "/products",
    },

    "/search": {
      title: "Search",
      hasHeader: true,
      hasFooter: true,
      breadcrumbName: "Search",
    },

    "/analyticshub": {
      title: "Analytics Hub",
      hasHeader: true,
      hasFooter: true,
      breadcrumbName: "Analytics Hub",
    },

    "/": {
      title: "Splash Page",
      hasHeader: false,
      hasFooter: false,
      breadcrumbName: "",
    },
  }

  removeTrailingSlash = (path: string): string => {
    return path.replace(/\/$/, "")
  }

  getPageSettings = (path: string): IPageSetting => {
    const sanitizedPath = this.removeTrailingSlash(path)
    const key = Object.keys(this.pageSettings).find(k => sanitizedPath.indexOf(k) !== -1)
    if (!key || !this.pageSettings[key]) {
      console.warn(`This path (${sanitizedPath}) has not been defined in the application store!`)
    }
    return this.pageSettings[path] || this.defaultPageSettings
  }

  requestApplicationAccess = (applicationId: string): Promise<void> => {
    return new Promise<void>(resolve => {
      postApi(`${storeUrls.requestApplicationAccess}${applicationId}`, {})
        .then(() => resolve())
        .catch(() => resolve())
    })
  }

  getProduct = (productId: string): Promise<IApplicationTuple> => {
    return new Promise<IApplicationTuple>((resolve, reject) => {
      get<IApplicationTuple>(`/application/${productId}/requestedProductDetails`)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          }

          reject()
        })
        .catch(err => reject(err))
    })
  }
}

export default new ApplicationStore()
