import React from "react"
import "./styles.scss"

interface ICustomCheckbox {
  checked: boolean
  id: string
  label: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const CustomCheckbox: React.FC<ICustomCheckbox> = props => {
  return (
    <div className="checkboxes__item">
      <input className="checkboxes__input" type="checkbox" {...props} />
      <label className="checkboxes__label">{props.label}</label>
    </div>
  )
}

export default CustomCheckbox
