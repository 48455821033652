import React, { useEffect, useState } from "react"

const useInputCursorPosition = (inputRef: React.MutableRefObject<HTMLTextAreaElement | null>) => {
  const [cursorPosition, setCursorPosition] = useState<number>(0)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [inputRef, cursorPosition])

  return [setCursorPosition]
}

export default useInputCursorPosition
