import { IAppApprovalQuestion } from "../interfaces/appApprovalQuestion/interfaces"
import { get } from "../utils/api"

export interface IApprovalQuestionStore {
  getApprovalQuestionsForApplication: (
    applicationId: string
  ) => Promise<IAppApprovalQuestion | null>
  getApprovalQuestionsRequirementForApplication: (applicationId: string) => Promise<boolean>
}

const endPoint = "approvalQuestion"

const storeUrls = {
  getApprovalQuestionsForApplication: `${endPoint}?applicationId=`,
  getApprovalQuestionsRequirementForApplication: `${endPoint}/required?applicationId=`,
}

class ApprovalQuestionStore implements IApprovalQuestionStore {
  getApprovalQuestionsForApplication = (
    applicationId: string
  ): Promise<IAppApprovalQuestion | null> => {
    return new Promise<IAppApprovalQuestion | null>(resolve => {
      get<IAppApprovalQuestion | null>(
        `${storeUrls.getApprovalQuestionsForApplication}${applicationId}`
      )
        .then(res => {
          if (res.status === 404) {
            resolve(null)
          }

          resolve(res.data)
        })
        .catch(() => resolve(null))
    })
  }

  getApprovalQuestionsRequirementForApplication = (applicationId: string): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      get<boolean>(`${storeUrls.getApprovalQuestionsRequirementForApplication}${applicationId}`)
        .then(res => {
          if (res.status === 404) {
            resolve(false)
          }

          resolve(res.data)
        })
        .catch(() => resolve(false))
    })
  }
}

export default new ApprovalQuestionStore()
