import { AxiosResponse } from "axios"
import { makeAutoObservable } from "mobx"
import { emailRegex } from "../global/constants/regex"
import { ICreateInvitation, IInvitationResult } from "../global/interfaces/invitation/interfaces"
import { DistinctCollection, ErrorsCollection } from "../global/types"
import { post } from "../global/utils/api"

interface IInvitation {
  recipientEmails: string[]
  products: string[]
}

export interface IInvitationStore {
  invitation: IInvitation
  errors: ErrorsCollection
  invalidEmails: string[]
  addRecipient: (index: number, email: string) => void
  validate: () => boolean
  addManyRecipients: (recipients: string[]) => void
  postInvitations: () => Promise<AxiosResponse<IInvitationResult>>
  setInvalidEmails: (emails: string[]) => void
  resetState: () => void
}

export class InvitationStore implements IInvitationStore {
  invitation: IInvitation = {
    recipientEmails: [],
    products: [],
  }
  errors = new ErrorsCollection()
  invalidEmails: string[] = []

  constructor() {
    makeAutoObservable(this)
    this.resetState()
  }

  validate = (): boolean => {
    const validationErrors = new ErrorsCollection()

    this.invitation.recipientEmails = this.invitation.recipientEmails.map(email => email.trim())

    if (this.invitation.recipientEmails.length === 0) {
      validationErrors.add({
        key: `invite-email-field-0`,
        value: [
          {
            fieldError: "Enter recipient's email address",
            summaryError: "Enter recipient's email address",
          },
        ],
      })
    }

    if (this.invitation.products.length === 0) {
      validationErrors.add({
        key: "invite-product-field-0",
        value: [
          {
            fieldError: "Select a product or service",
            summaryError: "Select a product or service",
          },
        ],
      })
    }

    const uniqueEmails = new DistinctCollection()

    this.invitation.recipientEmails.forEach((email, index) => {
      const key = `invite-email-field-${index}`
      const hasAdded = uniqueEmails.add(email)
      if (email) {
        if (!emailRegex.test(email)) {
          validationErrors.add({
            key: key,
            value: [
              {
                fieldError: "Enter an email address in the correct format, e.g. name@example.com",
                summaryError: "Enter an email address in the correct format, e.g. name@example.com",
              },
            ],
          })
        } else if (!hasAdded) {
          validationErrors.add({
            key: key,
            value: [
              {
                fieldError: "Remove duplicate email address",
                summaryError: "Remove duplicate email address",
              },
            ],
          })
        }
      } else {
        validationErrors.add({
          key: key,
          value: [
            {
              fieldError: "Enter recipient's email address",
              summaryError: "Enter recipient's email address",
            },
          ],
        })
      }
    })

    this.invitation.products.forEach((product, index) => {
      if (!product) {
        validationErrors.add({
          key: `invite-product-field-${index}`,
          value: [
            {
              fieldError: "Select a product or service",
              summaryError: "Select a product or service",
            },
          ],
        })
      }
    })

    this.errors = validationErrors

    return validationErrors.length === 0
  }

  setInvalidEmails = (emails: string[]): void => {
    this.invalidEmails = emails
  }

  resetState = (): void => {
    this.invitation = {
      recipientEmails: [""],
      products: [""],
    }

    this.errors = new ErrorsCollection()
  }

  addManyRecipients = (recipients: string[]): void => {
    this.invitation.recipientEmails = this.invitation.recipientEmails
      .concat(recipients)
      .filter(x => x)
  }

  addOneRecipient = (): void => {
    this.invitation.recipientEmails.push("")
  }

  addProduct = (id: string): void => {
    this.invitation.products.push(id)
  }

  addRecipient = (index: number, email: string): void => {
    const other = [...this.invitation.recipientEmails]
    other[index] = email
    this.invitation.recipientEmails = [...other]
  }

  postInvitations = (): Promise<AxiosResponse<IInvitationResult>> => {
    const apiShape: ICreateInvitation = {
      emailAddresses: this.invitation.recipientEmails,
      applicationIds: this.invitation.products,
    }

    return post<ICreateInvitation>(`/invitation`, apiShape)
  }
}

export default new InvitationStore()
