import React from "react"

interface IChevronRightIconProps {
  className?: string
  fill?: string
  height?: number
  width?: number
  id?: string
}

const ChevronRightIcon: React.FC<IChevronRightIconProps> = props => {
  const { fill = "#005EB8", height = 30, width = 31, id, className } = props

  return (
    <svg
      className={className}
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill={fill}
        d="M21.133 15.008c.003.498-.193.977-.544 1.33l-9.375 9.376c-.476.476-1.169.661-1.819.487-.65-.174-1.157-.681-1.33-1.331-.175-.65.01-1.343.486-1.819l8.063-8.043-8.063-8.044c-.735-.735-.735-1.927 0-2.663.736-.735 1.928-.735 2.663 0l9.375 9.375c.35.354.547.833.544 1.332z"
      />
    </svg>
  )
}

export default ChevronRightIcon
