import { Input } from "nhsuk-react-components"
import React, { memo, useEffect } from "react"
import { IOrganisation } from "../../../../interfaces/organisation/interfaces"
import { ISearchResultOrganisation } from "../../../../interfaces/searchResults/interfaces"
import "./styles.scss"

interface IEditableOrganisationInputProps {
  selectedOrganisation: IOrganisation | ISearchResultOrganisation | null
  edit: () => void
}

const EditableOrganisationInput = (props: IEditableOrganisationInputProps) => {
  const { selectedOrganisation, edit } = props

  useEffect(() => {
    const elem = document.getElementById("editableOrganisationInput__input") as HTMLInputElement
    if (elem) {
      if (selectedOrganisation) {
        let val = ""

        if ("name" in selectedOrganisation) {
          val = selectedOrganisation.name
        } else if ("organisationName" in selectedOrganisation) {
          val = selectedOrganisation.organisationName
        }

        elem.value = val
      }
    }
  }, [selectedOrganisation])

  if (!selectedOrganisation) {
    return null
  }

  return (
    <div className="editableOrganisationInput">
      <Input id="editableOrganisationInput__input" readOnly disabled />
      <button className="editableOrganisationInput__button-edit" onClick={edit}>
        Edit
      </button>
    </div>
  )
}

export default memo(EditableOrganisationInput)
