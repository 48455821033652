import { OrganisationGroupValidationFailureCode } from "../enums"
import AddToGroupListValidator from "./addToGroupListValidator"
import { Guid } from "../../../../../global/types"
import { failureMessages } from "../messages"

class PublishOrganisationGroupValidator extends AddToGroupListValidator {
  constructor() {
    super()

    this.ruleFor(x => x)
      .withAny(
        x => x.organisations,
        x => x.organisationTypes,
        x => x.regions,
        x => x.stps
      )
      .withFailureCode(OrganisationGroupValidationFailureCode.AtLeastOneField)
      .withFailureMessage(failureMessages[OrganisationGroupValidationFailureCode.AtLeastOneField])

    this.ruleFor(x => x.tableauGroupId)
      .withAttributes(x => Guid.isValid(x))
      .withFailureCode(OrganisationGroupValidationFailureCode.TableauAccessGroup)
      .withFailureMessage(
        failureMessages[OrganisationGroupValidationFailureCode.TableauAccessGroup]
      )
  }
}

export default PublishOrganisationGroupValidator
