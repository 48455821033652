import React, { memo } from "react"
import "./styles.scss"

const HeaderContainer = () => {
  return (
    <div className="bulk-invitations-header__container">
      <h1 className="bulk-invitations-header__title">Invite status</h1>
    </div>
  )
}

export default memo(HeaderContainer)
