import _ from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ApplicationRequestStatus } from "../../../global/enums/application"
import NoResultsFound from "../noResultsFound/NoResultsFound"
import AppsNav from "../appsNav/AppsNav"
import Text from "../text/text"
import FiltersContainer from "./components/filtersContainer/filtersContainer"
import ProductsList from "./components/productsList/ProductsList"
import {
  addsTagsFromProductToTagsList,
  alphabetsOfAvailableApps,
  filterProducts,
  listAllProductsByAlphabets,
  productsDataWithoutAlphabet,
} from "./helpers/helper"
import "./styles.scss"

const ProductsAtoZPage = props => {
  const { products, toggleApplicationFavourite } = props

  const [allAzProductsList, setAllAzProductsList] = useState([])
  const [allAzProductsListForTags, setAllAzProductsListForTags] = useState([])
  const [allAzProductsListRaw, setAllAzProductsListRaw] = useState([])
  const [appNameSearchValue, setAppNameSearchValue] = useState("")
  const [accessSelectionList, setAccessSelectionList] = useState([
    ApplicationRequestStatus.Approved,
    ApplicationRequestStatus.NotRequested,
    ApplicationRequestStatus.Pending,
  ])
  const [tagsList, setTagsList] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  const groupedProductsByAlphabet = _.groupBy(allAzProductsList, product => product.alphabet)
  const productParam = new URLSearchParams(useLocation().search).get("product")
  const availableAlphabets = alphabetsOfAvailableApps(allAzProductsList)
  const tagsListUnsorted = addsTagsFromProductToTagsList(
    productsDataWithoutAlphabet(allAzProductsListForTags)
  )

  const selectedCardRef = useRef(null)

  useEffect(() => {
    if (products.length > 0) {
      const listOfAZProductsObjects = listAllProductsByAlphabets(products)
      setAllAzProductsList(listOfAZProductsObjects)
      setAllAzProductsListRaw(listOfAZProductsObjects)
      setAllAzProductsListForTags(listOfAZProductsObjects)
      setLoadingData(false)
    }
  }, [products])

  useEffect(() => {
    if (!loadingData) {
      if (selectedCardRef && selectedCardRef.current) {
        selectedCardRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [loadingData])

  useEffect(() => {
    if (
      !loadingData &&
      tagsListUnsorted.length > 0 &&
      tagsList.length < 1 &&
      selectedTags.length < 1
    ) {
      const tagslist = tagsListUnsorted.sort()
      setTagsList(tagslist)
    }
  }, [loadingData, selectedTags.length, tagsList.length, tagsListUnsorted])

  useEffect(() => {
    const allProductsFiltered = filterProducts(
      allAzProductsListRaw,
      selectedTags,
      appNameSearchValue,
      accessSelectionList
    )
    setAllAzProductsList(allProductsFiltered)
  }, [selectedTags, appNameSearchValue, accessSelectionList, allAzProductsListRaw])

  const onTagSelectStateChanged = tag =>
    setSelectedTags(
      selectedTags.indexOf(tag) !== -1
        ? selectedTags.filter(t => t !== tag)
        : [...selectedTags, tag]
    )

  const removeTag = tag => setSelectedTags([...selectedTags.filter(selected => selected !== tag)])

  const Products = () => {
    if (loadingData) {
      return <Text textType="label">Loading...</Text>
    }

    if (allAzProductsList.length === 0) {
      return <NoResultsFound />
    }

    return (
      <>
        {availableAlphabets.map(letter => {
          const letterItems = groupedProductsByAlphabet[letter]
            .filter(
              tuple =>
                accessSelectionList.length === 0 ||
                accessSelectionList.indexOf(tuple.product.userApplicationMappingState) !== -1
            )
            .map(tuple => tuple.product)
          return (
            <div key={letter} className="az-page__productsList">
              <ProductsList
                alphabet={letter}
                display={letterItems.length > 0}
                listOfProducts={letterItems}
                toggleApplicationFavourite={toggleApplicationFavourite}
                productParam={productParam}
                selectedCardRef={selectedCardRef}
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className="az-page">
      <FiltersContainer
        setAppNameSearchValue={setAppNameSearchValue}
        appNameSearchValue={appNameSearchValue}
        accessSelectionList={accessSelectionList}
        setAccessSelectionList={setAccessSelectionList}
        removeTag={removeTag}
        tagsList={tagsList}
        onTagSelectStateChanged={onTagSelectStateChanged}
        selectedTags={selectedTags}
      />
      <div className="az-page__products">
        <div id="nav" className="az-page__app-nav">
          <AppsNav availableApps={availableAlphabets} />
        </div>
        <Products />
      </div>
    </div>
  )
}

ProductsAtoZPage.propTypes = {
  products: PropTypes.array.isRequired,
  toggleApplicationFavourite: PropTypes.func.isRequired,
}

export default ProductsAtoZPage
