import { ApplicationType } from "../../../global/enums"
import { ACTION, ApplicationState } from "../enums"
import { ApplicationScope } from "../../../global/enums/application"

export default class Application {
  isTableauApp = false
  isRegional = false
  originalApplicationState: ApplicationState = ApplicationState.Created
  appliedAction: ACTION = ACTION.NONE

  constructor(
    public name: string,
    public id: string,
    public applicationType: number,
    public applicationState: ApplicationState = ApplicationState.Created,
    public applicationScope: ApplicationScope = ApplicationScope.All,
    public adminCanChangeConfiguration = false,
    public regions: string[] = []
  ) {
    if (
      applicationType === ApplicationType.TableauAzure ||
      applicationType === ApplicationType.TableauViewPoint ||
      applicationType === ApplicationType.Tableau
    ) {
      this.isTableauApp = true
      this.isRegional = this.isApplicationRegional(applicationScope)
    }

    this.originalApplicationState = this.applicationState
    this.isRegional = applicationScope === ApplicationScope.Regional
  }

  adminCanChangeConfig = (canChangeConfig: boolean): void => {
    this.adminCanChangeConfiguration = canChangeConfig
    this.setTrackedApplicationsStateToModified()
  }

  set isTableauApplication(isTableau: boolean) {
    this.isTableauApp = isTableau
  }

  setScope = (applicationScope: ApplicationScope): void => {
    this.applicationScope = applicationScope
    if (applicationScope !== ApplicationScope.Regional) {
      this.removeAllRegions()
    }
    this.setTrackedApplicationsStateToModified()
  }

  setIsRegionalApp = (isRegional: boolean) => {
    this.isRegional = isRegional
    this.setTrackedApplicationsStateToModified()
  }

  addRegion = (region: string) => {
    if (!this.isRegional) return
    this.regions.push(region)
    this.setTrackedApplicationsStateToModified()
  }

  removeRegion = (region: string) => {
    const regions = this.regions.filter(r => r !== region)
    this.regions = [...regions]
    this.setTrackedApplicationsStateToModified()
  }

  setAppliedAction = (action: ACTION) => (this.appliedAction = action)

  removeAllRegions = () => (this.regions = [])

  setApplicationState = (applicationState: ApplicationState) => {
    this.applicationState = applicationState
  }

  private isApplicationRegional = (applicationScope: ApplicationScope) =>
    applicationScope === ApplicationScope.Regional

  private setTrackedApplicationsStateToModified = () =>
    (this.applicationState = ApplicationState.Modified)
}
