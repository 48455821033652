import React, { createContext, useEffect, useRef, useState } from "react"
import { useNotifications, useStores } from "../hooks"
import { observer } from "mobx-react"
import { useLocation } from "react-router-dom"
import { Routes } from "../enums"
import { INotificationEvent } from "../interfaces/notification"

interface INotificationContext {
  setOpenNotificationEventId: (notificationEventId: string) => void
  onNotificationEventSelectToggled: (notificationEventId: string) => void
  onNotificationEventRead: (notificationEventId: string) => void
  onNotificationEventsDeleteRequested: (notificationEventIds: string[]) => void
  onLoadNotificationEventsRequested: () => void
  onSelectAllNotificationEvents: () => void
  onDeselectAllNotificationEvents: () => void
  onDeleteSelectedNotificationEventsRequested: () => void
  onMarkNotificationEventsAsRead: (notificationEventIds: string[]) => void
  selectedNotificationEventIds: string[]
  notificationEvents: INotificationEvent[]
  allUnReadNotificationsCount: number
  openNotificationEventId: string
  loadingMoreEntities: boolean
}

export const NotificationContext = createContext<INotificationContext>({
  setOpenNotificationEventId: (_: string) => Function.prototype,
  onNotificationEventSelectToggled: (_: string) => Function.prototype,
  onNotificationEventRead: (_: string) => Function.prototype,
  onNotificationEventsDeleteRequested: (_: string[]) => Function.prototype,
  onLoadNotificationEventsRequested: () => Function.prototype,
  onSelectAllNotificationEvents: () => Function.prototype,
  onDeselectAllNotificationEvents: () => Function.prototype,
  onDeleteSelectedNotificationEventsRequested: () => Function.prototype,
  onMarkNotificationEventsAsRead: (_: string[]) => Function.prototype,
  selectedNotificationEventIds: [],
  notificationEvents: [],
  allUnReadNotificationsCount: 0,
  openNotificationEventId: "",
  loadingMoreEntities: false,
})

interface INotificationContextProviderProps {
  children: React.ReactNode
}

const NotificationContextProvider = (props: INotificationContextProviderProps) => {
  const { children } = props

  const [notificationEvents, setNotificationEvents] = useState<INotificationEvent[]>([])
  const [allUnReadNotificationsCount, setAllUnReadNotificationsCount] = useState<number>(0)
  const [selectedNotificationEventIds, setSelectedNotificationEventIds] = useState<string[]>([])
  const [openNotificationEventId, setOpenNotificationEventId] = useState<string>("")
  const [loadingMoreEntities, setLoadingMoreEntities] = useState<boolean>(false)
  const [nextPage, setNextPage] = useState<number>(2)
  const [numberOfPages, setNumberOfPages] = useState<number>(0)

  const { notificationHub } = useNotifications()

  const mounted = useRef<boolean>(false),
    loadingEvents = useRef<boolean>(false)

  const { notificationStore } = useStores()

  const { pathname } = useLocation()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setNotificationEvents(events =>
      notificationHub.notification !== null
        ? [notificationHub.notification, ...events]
        : [...events]
    )
    setAllUnReadNotificationsCount(currentCount =>
      notificationHub.notification !== null ? currentCount + 1 : currentCount
    )
  }, [notificationHub.notification])

  useEffect(() => {
    if (mounted.current && pathname === Routes.Notifications) {
      setLoadingMoreEntities(true)

      notificationStore.getNotificationEvents(1).then(res => {
        if (mounted.current) {
          setNumberOfPages(res.pageCount)
          setNotificationEvents(res.notificationEvents)
          setAllUnReadNotificationsCount(res.allUnReadNotificationsCount)
          setLoadingMoreEntities(false)
        }
      })
    }
  }, [notificationStore.getNotificationEvents, pathname])

  const onNotificationEventSelectToggled = (notificationEventId: string) => {
    const idx = selectedNotificationEventIds.indexOf(notificationEventId)
    if (idx !== -1) {
      const selectedNotificationEventIdsCopy = [...selectedNotificationEventIds]
      selectedNotificationEventIdsCopy.splice(idx, 1)
      setSelectedNotificationEventIds(selectedNotificationEventIdsCopy)
    } else {
      setSelectedNotificationEventIds([...selectedNotificationEventIds].concat(notificationEventId))
    }
  }

  const onNotificationEventRead = (notificationEventId: string) => {
    const notificationEventsCopy = [...notificationEvents]
    const unreadNotificationEventIdx = notificationEventsCopy.findIndex(
      ne => ne.id === notificationEventId
    )

    if (
      unreadNotificationEventIdx !== -1 &&
      !notificationEventsCopy[unreadNotificationEventIdx].read
    ) {
      notificationEventsCopy[unreadNotificationEventIdx].read = true

      notificationStore.readNotificationEvents([notificationEventId]).then(() => {
        setNotificationEvents(notificationEventsCopy)
      })
      setAllUnReadNotificationsCount(currentCount =>
        currentCount > 0 ? currentCount - 1 : currentCount
      )
    }
  }

  const onAfterNotificationEventDeletion = async () => {
    //Re-fetch pages of entities
    const pagesOfNotificationEvents = await Promise.all(
      Array.from(Array(nextPage).keys()).slice(1).map(notificationStore.getNotificationEvents)
    )
    setNotificationEvents(
      pagesOfNotificationEvents.map(x => x.notificationEvents).reduce((a, b) => a.concat(b))
    )

    setNumberOfPages(pagesOfNotificationEvents[0].pageCount)
  }

  const onNotificationEventsDeleteRequested = (notificationEventIds: string[]): void => {
    notificationStore
      .deleteNotificationEvents(notificationEventIds)
      .then(() => {
        const selectedNotificationEventIdsCopy = [...selectedNotificationEventIds]
        const notificationEventsCopy = [...notificationEvents]
        let selectedChanged = false,
          eventsChanged = false

        for (const notificationEventId of notificationEventIds) {
          const selectedIdx = selectedNotificationEventIdsCopy.indexOf(notificationEventId)

          if (selectedIdx !== -1) {
            selectedNotificationEventIdsCopy.splice(selectedIdx, 1)
            selectedChanged = true
          }

          const notificationIdx = notificationEventsCopy.findIndex(
            n => n.id === notificationEventId
          )

          if (notificationIdx !== -1 && !notificationEventsCopy[notificationIdx].read) {
            setAllUnReadNotificationsCount(currentCount =>
              currentCount > 0 ? currentCount - 1 : currentCount
            )
          }

          if (notificationIdx !== -1) {
            notificationEventsCopy.splice(notificationIdx, 1)
            eventsChanged = true
          }
        }

        if (mounted.current) {
          if (selectedChanged) {
            setSelectedNotificationEventIds(selectedNotificationEventIdsCopy)
          }
          if (eventsChanged) {
            setNotificationEvents(notificationEventsCopy)
          }
        }
      })
      .finally(onAfterNotificationEventDeletion)
  }

  const onLoadNotificationEventsRequested = () => {
    if (!loadingEvents.current && nextPage <= numberOfPages) {
      loadingEvents.current = true
      if (mounted.current) {
        setLoadingMoreEntities(true)

        notificationStore.getNotificationEvents(nextPage).then(res => {
          const newNotificationEventsArr = [...notificationEvents].concat(res.notificationEvents)

          if (mounted.current) {
            setNotificationEvents(newNotificationEventsArr)
            setAllUnReadNotificationsCount(res.allUnReadNotificationsCount)
            setNextPage(res.pageIndex + 1)
            setLoadingMoreEntities(false)
          }

          loadingEvents.current = false
        })
      }
    }
  }

  const onMarkNotificationEventsAsRead = (notificationEventIds: string[]) =>
    notificationStore.readNotificationEvents(notificationEventIds).then(() => {
      const notificationEventsCopy = [...notificationEvents]
      for (const notificationEventId of notificationEventIds) {
        const idx = notificationEventsCopy.findIndex(ne => ne.id === notificationEventId)
        if (idx !== -1) {
          notificationEventsCopy[idx].read = true
        }
      }

      if (mounted.current) {
        setNotificationEvents(notificationEventsCopy)
      }
    })

  const onDeleteSelectedNotificationEventsRequested = () =>
    onNotificationEventsDeleteRequested(selectedNotificationEventIds)

  const onSelectAllNotificationEvents = () =>
    setSelectedNotificationEventIds(notificationEvents.map(ne => ne.id))

  const onDeselectAllNotificationEvents = () => setSelectedNotificationEventIds([])

  return (
    <NotificationContext.Provider
      value={{
        setOpenNotificationEventId: setOpenNotificationEventId,
        onNotificationEventSelectToggled: onNotificationEventSelectToggled,
        onNotificationEventRead: onNotificationEventRead,
        onNotificationEventsDeleteRequested: onNotificationEventsDeleteRequested,
        onLoadNotificationEventsRequested: onLoadNotificationEventsRequested,
        onSelectAllNotificationEvents: onSelectAllNotificationEvents,
        onDeselectAllNotificationEvents: onDeselectAllNotificationEvents,
        onDeleteSelectedNotificationEventsRequested: onDeleteSelectedNotificationEventsRequested,
        onMarkNotificationEventsAsRead: onMarkNotificationEventsAsRead,
        selectedNotificationEventIds: selectedNotificationEventIds,
        notificationEvents: notificationEvents,
        allUnReadNotificationsCount: allUnReadNotificationsCount,
        openNotificationEventId: openNotificationEventId,
        loadingMoreEntities: loadingMoreEntities,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default observer(NotificationContextProvider)
