import React, { memo } from "react"
import TextButton from "../../../global/components/textButton"
import "./styles.scss"

interface ICategoryProps {
  id: string
  topicName: string
  onCategoryClicked: (category: string) => void
  linkEnabled: boolean
}

const Category = (props: ICategoryProps) => {
  const { id, topicName, onCategoryClicked, linkEnabled } = props

  return (
    <div className="category-name-container">
      <div className="category-name-container__hyphen-container">
        <div className="category-name-container__hyphen-container__hyphen" />
      </div>
      <div className="category-name-container__link-container">
        {linkEnabled ? (
          <TextButton
            className="category-name-container__link-container__category-name"
            onClick={() => onCategoryClicked(id)}
          >
            {topicName}
          </TextButton>
        ) : (
          <span className="category-name-container__link-container__category-name-selected">
            {topicName}
          </span>
        )}
      </div>
    </div>
  )
}

export default memo(Category)
