import { observer } from "mobx-react"
import { Button } from "nhsuk-react-components"
import React from "react"
import { Routes } from "../../../../../../../global/enums"
import "./styles.scss"

const LoginButton = observer(() => {
  return (
    <Button className="auth-link" href={Routes.Authentication}>
      Log in
    </Button>
  )
})

export default LoginButton
