import { observer } from "mobx-react"
import { Select } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import {
  ITableauGroup,
  ITableauAccessGroup,
} from "../../../../../global/interfaces/tableau/interfaces"
import { useStores } from "../../../../../global/hooks"
import "./styles.scss"

interface ITableauGroupDropdownProps {
  defaultValue?: string
  label?: string
  forwardedError?: string
  onChange: (event: React.FormEvent<HTMLSelectElement>) => void
}

const TableauGroupDropdown = observer((props: ITableauGroupDropdownProps) => {
  const { defaultValue, label, forwardedError, onChange } = props

  const { tableauAccessViewStore } = useStores()
  const [defaultGroupValue, setDefaultGroupValue] = useState<string>("")

  useEffect(() => setDefaultGroupValue(defaultValue ? defaultValue : ""), [defaultValue])

  return (
    <div className="tableau-group-dropdown-container">
      <Select value={defaultGroupValue} label={label} onChange={onChange} error={forwardedError}>
        <Select.Option value="" disabled>
          Select access group
        </Select.Option>
        {tableauAccessViewStore.tableauGroups.map((x: ITableauGroup) => (
          <Select.Option key={x.id} value={x.id}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
})

export default TableauGroupDropdown
