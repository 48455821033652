import { observer } from "mobx-react"
import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { withWrapper } from "../../../global/components/HOC"
import { Routes } from "../../../global/enums"
import {
  UserProfileUpdateChanges,
  UserProfileUpdateScreen,
} from "../../../global/enums/profile/enums"
import { useStateFromRedirect } from "../../../global/hooks"
import { IUserProfileUpdateResult } from "../../../global/interfaces/userProfile/interfaces"
import { anyCombinationOfFlagsExclusive, hasAnExclusiveFlag, hasAnyFlag } from "../../helper"
import DetailsSuccessfullyChanged from "../profileUpdateResultPane/children/detailsSuccessfullyChanged/detailsSuccessfullyChanged"
import ManualApprovalRequired from "../profileUpdateResultPane/children/manualApprovalRequired/manualApprovalRequired"
import RequestsSent from "../profileUpdateResultPane/children/requestsSent/requestsSent"
import VerifyNewEmailAddress from "../profileUpdateResultPane/children/verifyNewEmailAddress/verifyNewEmailAddress"
import ProfileUpdateResultPane from "../profileUpdateResultPane"

interface IProfileUpdateState {
  result: IUserProfileUpdateResult | null
}

const ProfileUpdateResultView = observer(() => {
  const state = useStateFromRedirect<IProfileUpdateState | null>(null)
  const exclusiveFlags: UserProfileUpdateChanges[] = [
    UserProfileUpdateChanges.FirstName,
    UserProfileUpdateChanges.LastName,
    UserProfileUpdateChanges.RegionCode,
    UserProfileUpdateChanges.JobRole,
    UserProfileUpdateChanges.Organisation,
  ]

  const combinationFlags: UserProfileUpdateChanges[] = [
    UserProfileUpdateChanges.JobRole,
    UserProfileUpdateChanges.FirstName,
    UserProfileUpdateChanges.LastName,
    UserProfileUpdateChanges.Organisation,
    UserProfileUpdateChanges.RegionCode,
  ]

  const [redirectToRequestsSent, setRedirectToRequestsSent] = useState(false)

  if (!state || !state.result || !state.result.isSuccess) {
    return <Navigate to={Routes.Error} />
  }

  const result = state.result
  const changes = state.result.changes

  const successfulChanges =
    hasAnExclusiveFlag(changes, exclusiveFlags) ||
    anyCombinationOfFlagsExclusive(changes, combinationFlags)

  const verifyEmailAddress = hasAnyFlag(
    changes,
    UserProfileUpdateChanges.EmailLocal,
    UserProfileUpdateChanges.EmailDomain
  )

  if (redirectToRequestsSent) {
    return (
      <ProfileUpdateResultPane screen={UserProfileUpdateScreen.RequestsSent}>
        <RequestsSent />
      </ProfileUpdateResultPane>
    )
  }

  if (result.manualApprovalRequired) {
    return (
      <ProfileUpdateResultPane screen={UserProfileUpdateScreen.ManualApprovalRequired}>
        <ManualApprovalRequired />
      </ProfileUpdateResultPane>
    )
  }

  return (
    <>
      {verifyEmailAddress && (
        <ProfileUpdateResultPane screen={UserProfileUpdateScreen.VerifyNewEmaiLAddress}>
          <VerifyNewEmailAddress result={result} />
        </ProfileUpdateResultPane>
      )}
      {successfulChanges && (
        <ProfileUpdateResultPane screen={UserProfileUpdateScreen.DetailsSuccessfullyChanged}>
          <DetailsSuccessfullyChanged
            result={result}
            onRequestsSent={() => setRedirectToRequestsSent(true)}
          />
        </ProfileUpdateResultPane>
      )}
    </>
  )
})

export default withWrapper(ProfileUpdateResultView)
