import { observer } from "mobx-react"
import { Button, ErrorSummary, Form, Label, Textarea } from "nhsuk-react-components"
import React, { useState } from "react"
import ModalChangesNotSaved from "../../../global/components/modalChangesNotSaved"
import { emailRegex } from "../../../global/constants/regex"
import { useStores } from "../../../global/hooks"
import { Views } from "../../enums"
import "../../styles.scss"

const regex = /(\s|\\n)+/gm

interface IMultiEmailViewProps {
  setView: (view: Views) => void
}

const MultiEmailView = observer((props: IMultiEmailViewProps) => {
  const { setView } = props
  const {
    invitationStore: store,
    productOwnerAdminStore: { selectedProduct },
  } = useStores()

  const [multiEmailText, setMultiEmailText] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [emailErrors, setEmailErrors] = useState<string[]>([])

  const serialiseEmails = () => {
    let msg = ""
    const strArray = multiEmailText
      .replace(regex, ",")
      .split(",")
      .filter(str => str.length > 0)

    const emailsLength = strArray.length + store.invitation.recipientEmails.filter(x => x).length

    if (strArray.length === 0) {
      msg = "Enter recipients' email addresses"
      setEmailErrors([msg])
    } else if (emailsLength > 1000) {
      msg = `You can invite a maximum of 1000 people at once. You have currently added ${emailsLength} invitees.`
      setEmailErrors([msg])
    } else if (strArray.some(x => !emailRegex.test(x))) {
      msg = "Enter email addresses in the correct format, e.g. name@example.com"

      setEmailErrors(strArray.filter(email => !emailRegex.test(email)))
    }

    if (msg) {
      setErrorMessage(msg)
      return
    }

    store.addManyRecipients(strArray)
    setView(Views.Main)
  }

  return (
    <>
      {emailErrors.length > 0 && (
        <ErrorSummary role="alert" tabIndex={-1}>
          <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
          <ErrorSummary.List className="error-list">
            {emailErrors.map((email, index) => {
              return (
                <ErrorSummary.Item
                  key={`${index + email}`}
                  onClick={() => {
                    const element = document.getElementById("text-area")
                    if (element) {
                      element.scrollIntoView()
                    }
                  }}
                >
                  {email}
                </ErrorSummary.Item>
              )
            })}
          </ErrorSummary.List>
        </ErrorSummary>
      )}
      <Label isPageHeading>
        {selectedProduct
          ? `Invite people to ${selectedProduct.name}`
          : "List people to invite to products and services"}
      </Label>
      <p>
        Quickly create a list of the people you wish to invite and add it to the page where
        invitations are sent.
      </p>
      <Form>
        <Textarea
          id="text-area"
          error={errorMessage}
          rows={6}
          label="Enter multiple email addresses"
          onChange={e =>
            setMultiEmailText(e && e.target && (e.target as HTMLTextAreaElement).value)
          }
          value={multiEmailText}
          hint="Use commas or a new line to separate addresses. You will confirm their organisations in the next step."
        />
      </Form>
      <div className="invitation-page__button-controls">
        <Button className="invitation-page__nhsuk-button" onClick={serialiseEmails}>
          Add invitees
        </Button>
        <Button
          className="invitation-page__nhsuk-button invitation-page__nhsuk-button--button-secondary"
          secondary
          onClick={() => {
            if (multiEmailText && multiEmailText.length > 0) setShowModal(true)
            else setView(Views.Main)
          }}
        >
          Back
        </Button>
      </div>
      {showModal && (
        <ModalChangesNotSaved
          onLeave={() => setView(Views.Main)}
          onStay={() => setShowModal(false)}
        />
      )}
    </>
  )
})

export default MultiEmailView
