import { MultiValue } from "react-select"

export const toTildeSeparatedString = <T extends { value: string; label: string }>(
  multiValue: MultiValue<T>,
  value = true
): string => {
  return multiValue
    .map(mv => {
      return value ? mv.value : mv.label
    })
    .join("~")
}

export const fromTildeSeparatedString = (value: string): string[] => {
  return value.split("~")
}

export const getDefaults = (
  organisationGroupValue: string,
  options: { value: string; label: string }[]
) => {
  if (!organisationGroupValue || options.length === 0) {
    return []
  }

  const split = fromTildeSeparatedString(organisationGroupValue)
  return options.filter(o => split.includes(o.value))
}
