import { Header } from "nhsuk-react-components"
import React from "react"
import { NavInternalLink } from ".."
import { Routes } from "../../../../../enums"
import { useStores } from "../../../../../hooks"
import { logout } from "../../../../../utils/dom"

interface ILocalHeaderProps {
  isRegistrationPage: boolean
  isRegistrationTermsPage: boolean
  appTitle: string
  hideNav: boolean
}

const LocalHeader = (props: ILocalHeaderProps) => {
  const { isRegistrationPage, isRegistrationTermsPage, appTitle, hideNav } = props

  const { profileStore: profile } = useStores()

  return (
    <>
      <Header id="nhsi-header">
        <Header.Container id="nhsi-header-container">
          {!isRegistrationPage && !isRegistrationTermsPage && (
            <>
              <Header.Logo href={Routes.AToZLandingPage} tabIndex={1} />
              {appTitle && <Header.ServiceName className="hide-mob" />}
            </>
          )}
          {!hideNav && (
            <>
              <Header.Content id="nhsi-header-content">
                {!isRegistrationPage && !isRegistrationTermsPage && <Header.MenuToggle />}
              </Header.Content>
              <Header.Nav className="desktop-only">
                {(!profile.user || Object.keys(profile.user).length === 0) &&
                !isRegistrationPage &&
                !isRegistrationTermsPage ? (
                  <Header.NavItem tabIndex={1} href={Routes.Authentication}>
                    Login
                  </Header.NavItem>
                ) : (
                  !isRegistrationPage &&
                  !isRegistrationTermsPage && (
                    <>
                      {profile.user?.isProductOwner && (
                        <NavInternalLink tabIndex={1} href={Routes.AppAdmin}>
                          Admin
                        </NavInternalLink>
                      )}
                      {profile.user?.isSystemAdministrator && (
                        <NavInternalLink tabIndex={2} href={Routes.Admin}>
                          Ops admin
                        </NavInternalLink>
                      )}
                      <NavInternalLink tabIndex={3} href={Routes.ProfileUrl}>
                        Profile
                      </NavInternalLink>
                      <Header.NavItem tabIndex={4} className="logout" onClick={logout}>
                        Sign out
                      </Header.NavItem>
                    </>
                  )
                )}
              </Header.Nav>
            </>
          )}
        </Header.Container>
        {!isRegistrationPage && !isRegistrationTermsPage && (
          <Header.Nav className="mobile-only">
            {(!profile.user || Object.keys(profile.user).length === 0) && !isRegistrationPage ? (
              <Header.NavItem tabIndex={1} href={Routes.Authentication}>
                Login
              </Header.NavItem>
            ) : (
              !isRegistrationPage && (
                <>
                  {profile.user?.isProductOwner && (
                    <NavInternalLink tabIndex={1} href={Routes.AppAdmin}>
                      Admin
                    </NavInternalLink>
                  )}
                  {profile.user?.isSystemAdministrator && (
                    <NavInternalLink tabIndex={2} href={Routes.Admin}>
                      Ops admin
                    </NavInternalLink>
                  )}
                  <NavInternalLink tabIndex={3} href={Routes.ProfileUrl}>
                    Profile
                  </NavInternalLink>
                  <Header.NavItem tabIndex={4} className="logout" onClick={logout}>
                    Sign out
                  </Header.NavItem>
                </>
              )
            )}
          </Header.Nav>
        )}
      </Header>
    </>
  )
}

export default LocalHeader
