import React from "react"

const ReloadIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5333 3.46667C16.6 1.53334 13.9467 0.333336 11 0.333336C5.10668 0.333336 0.34668 5.10667 0.34668 11C0.34668 16.8933 5.10668 21.6667 11 21.6667C15.9733 21.6667 20.12 18.2667 21.3067 13.6667H18.5333C17.44 16.7733 14.48 19 11 19C6.58668 19 3.00001 15.4133 3.00001 11C3.00001 6.58667 6.58668 3 11 3C13.2133 3 15.1867 3.92 16.6267 5.37334L12.3333 9.66667H21.6667V0.333336L18.5333 3.46667Z"
        fill="#005EB8"
      />
    </svg>
  )
}

export default ReloadIcon
