import { BodyText, Col, Container } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { MessageKey } from "../global/enums/messageKeys/enums"
import { useMessageKeys } from "../global/hooks"
import "./styles.scss"

const CorePlatformCookiesPolicy = () => {
  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  return (
    <Container className="cookies" id="cookies">
      <Col width="three-quarters">
        <h1>Cookies on this website</h1>
        <h3>What are cookies?</h3>
        <BodyText>
          Cookies are files saved on your phone, tablet or computer when you visit a website. They
          store information about how you use the website, such as the pages you visit. Cookies are
          not viruses or computer programs. They are very small so do not take up much space.
        </BodyText>
        <h3>Use of Cookies</h3>
        <BodyText>
          When we provide services, we want to make them easy, useful and reliable. Where services
          are delivered on the internet, this sometimes involves placing small amounts of
          information on your device, for example, computer or mobile phone. These include small
          files known as cookies. They cannot be used to identify you personally.
        </BodyText>
        <BodyText>
          These pieces of information are used to improve services for you through, for example:
        </BodyText>
        <ul>
          <li className="cookies__list-item">
            Making our website work, for example by keeping it secure
          </li>
          <li className="cookies__list-item">
            Enabling a service to recognise your device so you don’t have to give the same
            information several times during one task
          </li>
          <li className="cookies__list-item">
            Recognising that you may already have given a username and password so you don’t need to
            do it for every web page requested
          </li>
          <li className="cookies__list-item">Remembering which pop-ups you’ve seen</li>
          <li className="cookies__list-item">
            Measuring how many people are using our website, so they can be made easier to use and
            there’s enough capacity to ensure they are fast
          </li>
        </ul>
        <BodyText>You can manage these small files yourself through your browser setting.</BodyText>
        <BodyText>
          Please note that the cookie providers listed below may share the gathered cookie
          information with other third party websites for the purpose of activity tracking. You can
          find out more about the policy of each cookie provider on their respective privacy pages.
        </BodyText>
        <h3>The cookies we use to serve you NHS England applications</h3>
        <BodyText>
          The list below is all the cookies that are used in this website. Please by aware that the
          providers listed below may change their cookie names without notice.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> AspNetCore.Cookies
          <br />
          <b>Purpose:</b> These are system cookies required for authentication and authorisation to
          the platform and are assigned after a visitor is successfully logged in.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> AspNetCore.Antiforgery
          <br />
          <b>Purpose:</b> This is a system cookie used to prevent and protect against cross-site
          request forgery attacks on the applications.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> CSRF-TOKEN
          <br />
          <b>Purpose:</b> This is a system cookie used to prevent and protect against cross-site
          request forgery attacks on the applications.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> jwtcore
          <br />
          <b>Purpose:</b> These cookies are to control and maintain your access token, ensuring that
          visitors stay authenticated throughout their journey.
        </BodyText>
        <h3>The cookies we use for Model Health System</h3>
        <BodyText>
          The list below is all the cookies that are used in this website. The only cookies used on
          our website are for anonymous analysis of your visit to the site using the Google
          Analytics service and to protect against cross-site request forgery. These are third-party
          cookies and are not tracked across multiple sites.
        </BodyText>
        <BodyText>
          Third party cookies are delivered on behalf of their respective organisations and as such
          they may change their name and purpose from the cookies identified below, this is beyond
          the control of NHS England.
        </BodyText>
        <BodyText>
          Please by aware that the providers listed below may change their cookie names without
          notice.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> CSRF-TOKEN
          <br />
          <b>Purpose:</b> This is a system cookie linked to the feedback form. This cookie protects
          against cross-site request forgery. No personally identifiable information is contained
          within this cookie.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> Google Analytics
          <br />
          <b>Purpose:</b> These cookies are used to collect information about how visitors use our
          site, which we use to help improve it. The cookies collect information in an anonymous
          form, including the number of visitors to the site, where visitors have come to the site
          from, whether they have used the site before and the pages they visited. There is no
          personally identifiable information contained within this cookie, but it will use your
          computer’s IP address to determine your geographical location. We do not collect or story
          your personal information and we do not allow Google to use or share our analytics data.{" "}
          <a href="https://policies.google.com/technologies/cookies?hl=en-GB">
            More information about Google cookies.
          </a>
        </BodyText>
        <BodyText>
          <b>Cookie:</b> YouTube
          <br />
          <b>Example name:</b> PREF, VISITOR_INFO1_LIVE, use_hitbox
          <br />
          <b>Purpose:</b> To track visitor views, and to remember user preferences when viewing
          YouTube videos embedded in our website web pages.{" "}
          <a href="https://policies.google.com/privacy?hl=en&gl=uk">
            More information about YouTube cookies.
          </a>
        </BodyText>
        <BodyText>
          <b>Cookie:</b> Twitter
          <br />
          <b>Example name:</b> guest_id, remember_checked, remember_checked_on, secure_sessions,
          twll
          <br />
          <b>Purpose:</b> To track visitor information and for security authentication.{" "}
          <a href="https://help.twitter.com/en/rules-and-policies/twitter-cookies">
            More information about Twitter cookies.
          </a>
        </BodyText>
        <h3>How to control and delete cookies</h3>
        <BodyText>
          However, if you wish to restrict or block the cookies which are set by our websites, or
          indeed any other website, you can do this through your browser settings. The ‘Help’
          function within your browser should tell you how.
        </BodyText>
        <BodyText>
          Alternatively, you may wish to visit{" "}
          <a href="https://www.aboutcookies.org.uk">www.aboutcookies.org.uk</a> which contains
          comprehensive information on how to do this on a wide variety of browsers. You will also
          find details on how to delete cookies from your machine as well as more general
          information about cookies.
        </BodyText>
        <BodyText>
          Please be aware that restricting cookies may have an impact on the functionality of our
          website.
        </BodyText>
        <BodyText>
          If you wish to view your cookie code, just click on a cookie to open it. You’ll see a
          short string of text and numbers. The numbers are your identification card, which can only
          be seen by the server that gave you the cookie.
        </BodyText>
        <BodyText>
          For information on how to do this on the browser of your mobile phone you will need to
          refer to your handset manual.
        </BodyText>
        <BodyText>
          To opt-out of third-parties collecting any data regarding your interaction on our website,
          please refer to their websites for further information.
        </BodyText>
        <BodyText>Visit our privacy page for more information on our wider data policies.</BodyText>
        <h3>Contact us</h3>
        <BodyText>
          If you have any questions about our use of cookies or the practices of this website,
          please email us at <a href={itOps}>{serviceDesk}</a>.
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(CorePlatformCookiesPolicy)
