import { makeAutoObservable } from "mobx"
import { getApi, postApi } from "../../../global/utils/api"
import _ from "lodash"

const guidRegex = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
)

const primaryAdminInitObj = {
  id: null,
  email: "",
  error: null,
  scope: 0,
}

const adminInfoData = {
  id: null,
  applicationId: null,
  complete: false,
  primaryAdmin: _.cloneDeep(primaryAdminInitObj),
  otherAdmins: [],
  scopes: [],
  regions: [],
}

export class AdminInfoStore {
  adminInformation = _.cloneDeep(adminInfoData)
  adminAssignableUsers = []

  constructor() {
    makeAutoObservable(this)
  }

  getAdminInfo = productId => {
    this.resetState()
    return new Promise(resolve => {
      this.adminInformation.applicationId = productId

      getApi(`/admininfo?applicationId=${this.adminInformation.applicationId}`)
        .then(res => {
          if (res.status === 200) {
            const payload = res.data

            if (!payload.primaryAdmin) {
              payload.primaryAdmin = _.cloneDeep(primaryAdminInitObj)
            }

            if (!payload.otherAdmins) {
              payload.otherAdmins = []
            }

            payload.primaryAdmin.fullName = payload.primaryAdmin.name
            delete payload.primaryAdmin.name

            payload.otherAdmins.map(admin => (admin.fullName = admin.name))

            this.adminInformation = payload
          }

          resolve(res)
        })
        .catch(() => {
          debugger
        })
        .then(resolve)
    })
  }

  getUsersAdminInfoFoApplication = (userId, applicationId) => {
    return new Promise(resolve => {
      getApi(
        `/admininfo/getUsersAdminMappingForApplication?userId=${userId}&applicationId=${applicationId}`
      )
        .then(res => {
          const result = res.data === "" ? null : res.data
          resolve(result)
        })
        .catch(() => {
          console.log(`Error calling endpoint`)
        })
    })
  }

  resetState = () => {
    this.adminInformation = _.cloneDeep(adminInfoData)
  }

  saveAdminInfo = () => {
    return new Promise((resolve, reject) => {
      if (!this.adminInformation.complete) {
        // If this AI is not complete, remove any null values
        this.adminInformation.otherAdmins
          .filter(admin => !admin.userId || !admin.userId.match(guidRegex))
          .map((_, index) => {
            this.adminInformation.otherAdmins.splice(index, 1)
          })
      }

      postApi(`/admininfo`, this.adminInformation)
        .then(res => {
          resolve(resolve)
        })
        .catch(err => {
          if (this.adminInformation.complete) this.adminInformation.complete = false
          reject(err)
        })
    })
  }

  getUserSearch = (query, idsToFilter = []) => {
    return new Promise((resolve, reject) => {
      getApi(`/user?search=${encodeURI(query)}`).then(res => {
        if (res.status === 200) {
          const users = res.data
            .filter(user => !idsToFilter.includes(user.id))
            .map(user => {
              user.userId = user.id
              delete user.id

              return {
                label: `${user.fullName} (${user.email})`,
                value: user,
              }
            })

          this.adminAssignableUsers = users
          resolve(users)
        }

        resolve(res)
      })
    })
  }

  saveAdminsMultipleAppConfigurations = adminUserConfiguration => {
    return new Promise((resolve, reject) => {
      postApi(`/adminInfo/manageAdminInfoForUsersMultipleApplications`, adminUserConfiguration)
        .then(() => resolve(true))
        .catch(errors => {
          reject(errors)
        })
    })
  }

  defaultPrimaryAdmin = () => {
    return _.cloneDeep(primaryAdminInitObj)
  }
}

export default new AdminInfoStore()
