import React from "react"
import Text from "../text/text"
import "./NoResultsFound.scss"

const NoResultsFound = () => {
  return (
    <>
      <div className="az-page__no-results__heading">No results found</div>
      <div className="az-page__no-results__text">
        <Text textType="title-black">To improve your search:</Text>
        <div className="az-page__no-results__text-points">
          <ul>
            <Text textType="title-black">
              <li>Check that you have spelt the application name correctly</li>
            </Text>
            <Text textType="title-black">
              <li>Reduce the number of tags you have selected</li>
            </Text>
            <Text textType="title-black">
              <li>Don’t search using both application name and tags at the same time</li>
            </Text>
          </ul>
        </div>
      </div>
    </>
  )
}

export default NoResultsFound
