import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Checkboxes,
  Col,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
  Label,
  Radios,
  Select,
} from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { ProductEditView } from "../../enums/enums"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { QuestionType } from "../../../global/enums/appApprovalQuestion/enums"
import { ApplicationType } from "../../../global/enums/application"
import { MessageKey } from "../../../global/enums/messageKeys/enums"
import { useMessageKeys, useStores } from "../../../global/hooks"
import { scrollRefIntoView } from "../../../global/utils/dom"
import { IconPlus } from "../../../global/components/icons"
import ProductViewRedirect from "../productViewRedirect"
import "./styles.scss"

const ApprovalQuestionsView = observer(() => {
  const { approvalQuestionsStore, productStore } = useStores()

  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )
  const { approvalQuestionInformation } = approvalQuestionsStore.approvalQuestionsData
  const [loading, setLoading] = useState(true)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [errorState, setErrorState] = useState(approvalQuestionsStore.errors)
  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [additionalInformationRequired, setAdditionalInformationRequired] = useState(
    approvalQuestionInformation.additionalInformationRequired
  )
  const [customTermsRequired, setCustomTermsRequired] = useState(
    approvalQuestionInformation.productSpecificTerms
  )
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [multiRegionAnswerRequired, setMultiRegionAnswerRequired] = useState(
    approvalQuestionInformation.selectedQuestions.some(q => q.type === QuestionType.Region)
  )
  const errorContainerRef = useRef()

  useEffect(() => {
    wrapWithLoader(async () => {
      await approvalQuestionsStore.getApprovalQuestionInformationData(
        productStore.product.id,
        productStore.product.type
      )
      setAdditionalInformationRequired(approvalQuestionInformation.additionalInformationRequired)
      setLoading(false)
    })
    return () => approvalQuestionsStore.resetState()
  }, [])

  useEffect(() => {
    // Set all fields after loading complete
    if (!loading) {
      setAdditionalInformationRequired(approvalQuestionInformation.additionalInformationRequired)
      setCustomTermsRequired(approvalQuestionInformation.productSpecificTerms)
      setMultiRegionAnswerRequired(
        approvalQuestionInformation.selectedQuestions.some(q => q.type === QuestionType.Region)
      )
    }
  }, [loading])

  const addQuestion = () => {
    if (
      !_.isEmpty(
        approvalQuestionInformation.selectedQuestions[
          approvalQuestionInformation.selectedQuestions.length - 1
        ]
      )
    ) {
      approvalQuestionInformation.selectedQuestions.push({})
    }
  }

  const deleteQuestion = questionId => {
    const filteredQuestions = approvalQuestionInformation.selectedQuestions.filter(
      q => q.id != questionId
    )
    approvalQuestionInformation.selectedQuestions = [...filteredQuestions]
  }

  const handleSaveAndReturn = async () => {
    approvalQuestionInformation.complete = false
    await save()
  }

  const handleSaveAndComplete = async () => {
    approvalQuestionInformation.complete = true

    if (infoValidates()) {
      await save()
      return
    }
    scrollRefIntoView(errorContainerRef)
  }

  const save = () => {
    approvalQuestionsStore
      .saveApprovalQuestions(productStore.product.id)
      .then(() => {
        productStore.setTaskModified(
          ProductEditView.ApprovalQuestions,
          approvalQuestionInformation.complete
        )
        setRedirectingToTaskList(true)
      })
      .catch(err => {
        console.log("meep", err)
      })
  }

  const cloneQuestions = () => {
    if (approvalQuestionInformation.additionalInformationRequired === true) {
      if (approvalQuestionInformation.selectedQuestions.length === 0) {
        approvalQuestionsStore.questionBank.map((q, idx) => {
          approvalQuestionInformation.selectedQuestions[idx] = { ...q }
        })
      }
    }
  }

  const infoValidates = () => {
    let errors = {}

    if (approvalQuestionInformation.additionalInformationRequired == null) {
      errors.additionalInfoRadios = "Select whether you need additional information from your users"
    } else if (approvalQuestionInformation.additionalInformationRequired === true) {
      if (approvalQuestionInformation.selectedQuestions.length === 0) {
        errors.questions = "Please select at least one question"
      } else if (
        approvalQuestionInformation.selectedQuestions.filter(x => !x.description).length > 0
      ) {
        errors.questionErrors = []
        for (var i = 0; i < approvalQuestionInformation.selectedQuestions.length; i++) {
          if (!approvalQuestionInformation.selectedQuestions[i].description) {
            errors.questionErrors[i] = "Please select a question"
          }
        }
      }
    }
    if (
      approvalQuestionInformation.productSpecificTerms == true &&
      approvalQuestionInformation.productSpecificTermsLink == ""
    ) {
      errors.productSpecificTermsLink =
        "Select whether your product has specific terms and conditions"
    }

    if (approvalQuestionInformation.productSpecificTerms == null) {
      errors.customTermsRadio = "Select whether your product has specific terms and conditions"
    }
    setErrorState(errors)
    return _.isEmpty(errors)
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <>
      <Col id="approval-questions-view" width="full">
        <div className="page-heading">
          <Label isPageHeading>Approval questions</Label>
          {!isEditMode && (
            <Link id="change-settings-button" onClick={() => setIsEditMode(true)}>
              Change settings
            </Link>
          )}
        </div>
        {!isEditMode ? (
          <div className="readonly-table">
            <div className="readonly-row">
              <div className="title-text">Additional information required?</div>
              <div>
                {approvalQuestionInformation.additionalInformationRequired === true ? "Yes" : "No"}
              </div>
            </div>
            {approvalQuestionInformation.additionalInformationRequired === true && (
              <>
                <div className="readonly-row">
                  <div className="title-text">Questions selected</div>
                  <div className="summary-text">
                    {approvalQuestionInformation.selectedQuestions.map(q => (
                      <p>{q.description}</p>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="readonly-row">
              <div className="title-text">Product-specific terms and conditions</div>
              <div>
                {approvalQuestionInformation.productSpecificTerms === true ? "Yes" : "No"}
                <br />
                {approvalQuestionInformation.productSpecificTerms === true && (
                  <>
                    <Link href={approvalQuestionInformation.productSpecificTermsLink}>
                      {approvalQuestionInformation.productSpecificTermsLink}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <Col width="two-thirds">
              <div ref={errorContainerRef}>
                {Object.keys(errorState).length > 0 && (
                  <ErrorSummary role="alert" tabIndex={-1}>
                    <ErrorSummary.Title id="error-summary-title">
                      There is a problem
                    </ErrorSummary.Title>
                    {Object.keys(errorState).map(e => (
                      <ErrorSummary.Item href={"#" + e}>{errorState[e]}</ErrorSummary.Item>
                    ))}
                  </ErrorSummary>
                )}
              </div>
              <BodyText>
                Here you can create a form to request additional information, to help you determine
                whether you can grant access to a new user.
              </BodyText>
              <BodyText>
                A series of questions has been suggested, for you to include in the form. If you
                need to add a different question, please email{" "}
                <Link href={itOps}>{serviceDesk}</Link>
              </BodyText>
              <Form className="approval-questions-form">
                <Fieldset>
                  <Fieldset.Legend>
                    Do you require additional information from your users before you can grant them
                    access to {productStore.product.name}?
                  </Fieldset.Legend>
                  <Radios
                    id="additionalInfoRadios"
                    error={errorState.additionalInfoRadios}
                    onChange={e => {
                      const isYes = e.target.value === "yes"
                      setAdditionalInformationRequired(isYes)
                      approvalQuestionInformation.additionalInformationRequired = isYes
                    }}
                  >
                    <Radios.Radio
                      className="additional-info-radio"
                      checked={approvalQuestionInformation.additionalInformationRequired}
                      onChange={Function.prototype} // Function.prototype == noop() == () => {}
                      value="yes"
                    >
                      Yes
                    </Radios.Radio>
                    <Radios.Radio
                      className="additional-info-radio"
                      checked={
                        approvalQuestionInformation.additionalInformationRequired != null &&
                        !approvalQuestionInformation.additionalInformationRequired
                      }
                      onChange={Function.prototype}
                      value="no"
                    >
                      No
                    </Radios.Radio>
                  </Radios>
                </Fieldset>
                {additionalInformationRequired === true && (
                  <>
                    <div className="info-box">
                      Only users who are on your request access list will be asked to complete the
                      additional form. Any users on the automatic access list will continue to be
                      granted automatic access if they meet the conditions for automatic access.
                    </div>
                    {productStore.product.type !== ApplicationType.Foundry ? (
                      <>
                        <hr></hr>
                        <Fieldset>
                          <Fieldset.Legend>
                            Select the approval questions you would like to appear on your access
                            request form
                          </Fieldset.Legend>
                          {approvalQuestionInformation.selectedQuestions === undefined ||
                            (approvalQuestionInformation.selectedQuestions.length === 0 && (
                              <Select
                                id="questions"
                                onChange={({ target: { value } }) => {
                                  const q = approvalQuestionsStore.questionBank.find(
                                    q => q.id == value
                                  )
                                  approvalQuestionInformation.selectedQuestions[0] = { ...q }
                                  //setMultiRegionAnswerRequired(approvalQuestionInformation.selectedQuestions.some(q => q.type === QuestionType.Region))
                                }}
                                value="DEFAULT"
                                error={errorState.questions}
                              >
                                <Select.Option value="DEFAULT" disabled>
                                  Select approval question
                                </Select.Option>
                                {approvalQuestionsStore.questionBank.map(q => (
                                  <Select.Option key={q.id} value={q.id}>
                                    {q.description}
                                  </Select.Option>
                                ))}
                              </Select>
                            ))}
                          {approvalQuestionInformation.selectedQuestions.map((x, idx) => {
                            return (
                              <div key={idx} id={`${idx}-select-item`}>
                                <Select
                                  id="questions"
                                  onChange={({ target: { value } }) => {
                                    const q = approvalQuestionsStore.questionBank.find(
                                      q => q.id == value
                                    )
                                    approvalQuestionInformation.selectedQuestions[idx] = { ...q }
                                    setMultiRegionAnswerRequired(
                                      approvalQuestionInformation.selectedQuestions.some(
                                        q => q.type === QuestionType.Region
                                      )
                                    )
                                  }}
                                  value={
                                    approvalQuestionInformation.selectedQuestions[idx].id ||
                                    "DEFAULT"
                                  }
                                  error={
                                    errorState.questionErrors && errorState.questionErrors[idx]
                                      ? errorState.questionErrors[idx]
                                      : null
                                  }
                                >
                                  <Select.Option value="DEFAULT" disabled>
                                    Select approval question
                                  </Select.Option>
                                  {approvalQuestionsStore.questionBank.map(q => (
                                    <Select.Option key={q.id} value={q.id}>
                                      {q.description}
                                    </Select.Option>
                                  ))}
                                </Select>
                                {idx > 0 && (
                                  <Link
                                    id={`${idx}-question-delete`}
                                    onClick={() => deleteQuestion(x.id)}
                                  >
                                    Delete
                                  </Link>
                                )}
                                {/* {multiRegionAnswerRequired && x.type == questionTypeKey.region &&
                                                                    <div>
                                                                            <Checkboxes onChange={(e) => {
                                                                                console.log(e.target.checked)
                                                                                approvalQuestionInformation.allowMultipleRegions = e.target.checked
                                                                                console.log(approvalQuestionInformation)
                                                                            }
                                                                            }>
                                                                                <Checkboxes.Box checked={approvalQuestionInformation.allowMultipleRegions}>
                                                                                    Allow user to select more than one region
                                                                            </Checkboxes.Box>
                                                                            </Checkboxes>
                                                                        </div>
                                                                    } */}
                              </div>
                            )
                          })}
                          <div id="question-add-button" onClick={addQuestion}>
                            <IconPlus />
                            <div className="button-text">Add another question</div>
                          </div>
                        </Fieldset>
                      </>
                    ) : (
                      cloneQuestions()
                    )}
                  </>
                )}
                <hr></hr>
                <Fieldset>
                  <Fieldset.Legend>
                    Does your product have specific terms and conditions not covered by the terms
                    and conditions agreed by users when registering for access to NHS England
                    applications
                  </Fieldset.Legend>
                  <Radios
                    id="customTermsRadio"
                    error={errorState.customTermsRadio}
                    onChange={e => {
                      setCustomTermsRequired(e.target.value === "yes")
                      approvalQuestionInformation.productSpecificTerms = e.target.value === "yes"
                    }}
                  >
                    <Radios.Radio
                      className="additional-info-radio"
                      checked={customTermsRequired}
                      onChange={Function.prototype} // Function.prototype == noop() == () => {}
                      value="yes"
                    >
                      Yes
                    </Radios.Radio>
                    <Radios.Radio
                      className="additional-info-radio"
                      checked={customTermsRequired != null && !customTermsRequired}
                      onChange={Function.prototype}
                      value="no"
                    >
                      No
                    </Radios.Radio>
                  </Radios>
                </Fieldset>
                {customTermsRequired === true && (
                  <Fieldset>
                    <Input
                      id="productSpecificTermsLink"
                      label="Insert the link to your terms and conditions page"
                      value={approvalQuestionInformation.productSpecificTermsLink || ""}
                      onChange={e =>
                        (approvalQuestionInformation.productSpecificTermsLink = e.target.value)
                      }
                      error={errorState.productSpecificTermsLink}
                    />
                  </Fieldset>
                )}
              </Form>
            </Col>
          </>
        )}
        {isEditMode &&
          (productStore.published ? (
            <Col width="two-thirds">
              <div id="published-save-control">
                <Label className="input-label">Publish changes</Label>
                <BodyText className="input-hint">
                  By clicking 'Save and publish', you confirm that you are making these changes in
                  line with business policies.
                </BodyText>
                <Button id="publish-button" onClick={handleSaveAndComplete}>
                  Save and publish
                </Button>
              </div>
            </Col>
          ) : (
            <Col width="two-thirds">
              <div id="button-controls">
                <Button id="publish-button" onClick={handleSaveAndComplete}>
                  Save and complete
                </Button>
                <Link id="return-link" onClick={handleSaveAndReturn}>
                  Save and return to task list
                </Link>
              </div>
            </Col>
          ))}
        {additionalInformationRequired === true && (
          <>
            <Col width="two-thirds" className="preview-form">
              {isEditMode && <hr />}
              <Label size="m">Request access form for {productStore.product.name}</Label>
              <BodyText>
                This is what the request access form will look like for your users.
              </BodyText>
              <div className="white-bg">
                <BodyText>
                  In order to assess whether we can grant you access to {productStore.product.name},
                  we will need additional information from you.
                </BodyText>
                {approvalQuestionInformation.selectedQuestions.map((x, idx) => {
                  return (
                    <>
                      {x.type === QuestionType.Text && (
                        <Input hint={x.guidanceText} label={x.description} />
                      )}
                      {x.type === QuestionType.Region && (
                        <Select hint={x.guidanceText} label={x.description}>
                          <Select.Option value="DEFAULT" disabled selected>
                            Select region
                          </Select.Option>
                        </Select>
                      )}
                      {/* {multiRegionAnswerRequired && x.type == questionTypeKey.region &&
                                                <div id="add-region-button">
                                                    <IconPlus />
                                                    <div className="button-text">Add another region</div>
                                                </div>
                                            } */}
                      {x.type === QuestionType.UDAL && (
                        <Select hint={x.guidanceText} label={x.description}>
                          <Select.Option value="DEFAULT" disabled selected>
                            Select UDAL access
                          </Select.Option>
                        </Select>
                      )}
                      {x.type === QuestionType.ReadOnly && <label>{x.description}</label>}
                      {x.type === QuestionType.DropdownSingle && (
                        <Select hint={x.guidanceText} label={x.description}>
                          <Select.Option value="DEFAULT" selected>
                            Option 1
                          </Select.Option>
                          <Select.Option value="Option2">Option 2</Select.Option>
                        </Select>
                      )}
                      {x.type === QuestionType.DropdownMulti && (
                        <Select hint={x.guidanceText} label={x.description}>
                          <Select.Option value="DEFAULT">Option 1</Select.Option>
                          <Select.Option value="Option2">Option 2</Select.Option>
                        </Select>
                      )}
                    </>
                  )
                })}
                {customTermsRequired === true && (
                  <>
                    <Label size="s">Terms and conditions</Label>
                    <Checkboxes>
                      <Checkboxes.Box>
                        I agree to the{" "}
                        <Link
                          id="termsLink"
                          href={approvalQuestionInformation.productSpecificTermsLink}
                          target="_blank"
                          aria-label="This link will open in a new tab"
                        >
                          terms and conditions
                        </Link>{" "}
                        of using {productStore.product.name}
                      </Checkboxes.Box>
                    </Checkboxes>
                  </>
                )}
              </div>
            </Col>
          </>
        )}
      </Col>
    </>
  )
})

export default ApprovalQuestionsView
