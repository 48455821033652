import _ from "lodash"
import { ActionMeta, GroupBase, OnChangeValue, StylesConfig } from "react-select"
import { AllSelectProps, SelectOnChangeProps } from "./types"

export function setDefaults<
  OptionType,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>
>(props: AllSelectProps<OptionType, IsMulti, Group>) {
  const {
    isClearable = true,
    placeholder = "Type a name",
    openMenuOnClick = true,
    defaultOptions = [],
    components = {
      IndicatorSeparator: props.showIndicator ? undefined : () => null,
      DropdownIndicator: props.showDropdownArrow ? undefined : () => null,
    },
    styles = _.curry(defaultStyles)(props),
  } = props

  if (props.showDropdownArrow || props.showIndicator) {
    delete components.DropdownIndicator
  }

  const newProps = {
    ...props,
    isClearable: isClearable,
    placeholder: placeholder,
    openMenuOnClick: openMenuOnClick,
    defaultOptions: defaultOptions,
    components: components,
    styles: styles,
    onChange: _.curry(onChangeWrapper)(props),
  }

  delete newProps.showDropdownArrow
  delete newProps.showIndicator

  return newProps
}

export const onChangeWrapper = <OptionType>(
  props: SelectOnChangeProps<OptionType>,
  selectedOption: OnChangeValue<OptionType, boolean>,
  triggeredAction: ActionMeta<OptionType>
) => {
  if (props.onChange) {
    props.onChange(selectedOption, triggeredAction)
  }

  if (triggeredAction.action === "clear" && props.onClear) {
    props.onClear()
  } else if (triggeredAction.action === "select-option" && props.onSelect) {
    props.onSelect()
  }
}

export const defaultStyles = <OptionType>(props: {
  error: string
}): StylesConfig<OptionType, boolean, GroupBase<OptionType>> => {
  return {
    control: (base, state) => {
      return {
        ...base,
        height: "2.5rem",
        fontSize: "1.1875rem",
        lineHeight: "1.47368",
        "&:hover": {
          borderColor: "0.25rem solid #212b32",
          boxShadow: "0 0 0 0.25rem #ffeb3b",
          outline: "0.25rem solid transparent",
          outlineOffset: "0.25rem",
        },
        boxShadow: state.menuIsOpen ? "0 0 0 0.25rem #ffeb3b" : "0.125rem solid #212b32",
        border: props.error
          ? "0.25rem solid #d5281b"
          : state.menuIsOpen
          ? "0.25rem solid #212b32"
          : "0.125rem solid #4c6272",
        borderRadius: 0,
      }
    },
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? "#004b93" : "#fff",
      color: isFocused ? "#fff" : "#000",
      textTransform: "uppercase",
      borderBottom: "0.0625rem solid rgb(0, 0, 0, .1)",
    }),
    valueContainer: base => ({
      ...base,
      height: "2.1875rem",
      borderRadius: "none",
    }),
  }
}
