import { HttpStatusCode } from "../enums/api"
import {
  INotificationEventResponse,
  IUserNotificationPreferences,
} from "../interfaces/notification"
import { get, post } from "../utils/api"

export interface INotificationStore {
  getNotificationEvents: (page: number) => Promise<INotificationEventResponse>
  readNotificationEvents: (notificationEventIds: string[]) => Promise<void>
  deleteNotificationEvents: (notificationEventIds: string[]) => Promise<void>
  getNotificationPreferences: () => Promise<IUserNotificationPreferences>
  postNotificationPreferences: (preferences: IUserNotificationPreferences) => Promise<void>
}

const controllerPrefix = "notification"

const storeUrls = {
  getNotificationEvents: `${controllerPrefix}/events?page=`,
  deleteNotificationEvents: `${controllerPrefix}/events`,
  readNotificationEvents: `${controllerPrefix}/read`,
  notificationPreferences: `${controllerPrefix}/preferences`,
}

class NotificationStore implements INotificationStore {
  getNotificationEvents = (page: number): Promise<INotificationEventResponse> => {
    return new Promise<INotificationEventResponse>((resolve, reject) => {
      get<INotificationEventResponse>(`${storeUrls.getNotificationEvents}${page}`)
        .then(res => resolve(res.data))
        .catch(reject)
    })
  }

  readNotificationEvents = (notificationEventIds: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<{ data: { notificationEventId: string }[] }>(storeUrls.readNotificationEvents, {
        data: notificationEventIds.map(id => ({ notificationEventId: id })),
      })
        .then(resolve)
        .catch(reject)
    })
  }

  deleteNotificationEvents = (notificationEventIds: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<{ data: { notificationEventId: string }[] }>(storeUrls.deleteNotificationEvents, {
        data: notificationEventIds.map(id => ({ notificationEventId: id })),
      })
        .then(resolve)
        .catch(reject)
    })
  }

  getNotificationPreferences = (): Promise<IUserNotificationPreferences> => {
    return new Promise<IUserNotificationPreferences>((resolve, reject) => {
      get<IUserNotificationPreferences>(storeUrls.notificationPreferences).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  postNotificationPreferences = (preferences: IUserNotificationPreferences): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserNotificationPreferences>(storeUrls.notificationPreferences, preferences)
        .then(resolve)
        .catch(reject)
    })
  }
}

export default new NotificationStore()
