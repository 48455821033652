import { BodyText, Col, Container, Label, Table } from "nhsuk-react-components"
import React from "react"
import { NavLink } from "react-router-dom"
import { withWrapper } from "../global/components/HOC"
import "./styles.scss"

const Privacy = () => {
  const consentTableObjects = [
    {
      purposeAndActivity: "To send bulletins and email updates",
      typeofdata: " 	(a) Identity (b) Contact ",
      lawfulBasis: "Consent ",
    },
    {
      purposeAndActivity:
        "To deliver relevant website content and measure or understand the effectiveness of website or services",
      typeofdata:
        " and measure or understand the effectiveness of website or services 	(a) Identity (b) Contact",
      lawfulBasis:
        "Necessary for our legitimate interests to keep people informed of issues relating to providers.",
    },
    {
      purposeAndActivity:
        "To respond to a request under the Freedom of Information Act, enquiries, the Data Protection Act, or the General Data Protection Regulation ",
      typeofdata: "(a) Identity (b) Contact ",
      lawfulBasis: "Comply with a legal or regulatory obligation.",
    },
    {
      purposeAndActivity: "To investigate and respond to a complaint (including whistle-blowing)",
      typeofdata: "(a) Identity (b) Contact (c) Special Categories",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England. Necessary for the management of health social care systems and services - health and social care purposes.",
    },
    {
      purposeAndActivity:
        "To securely store ambulance service records which are unable to be repatriated due to closure of the service ",
      typeofdata: "(a) Identity (b) Contact (c) Special categories ",
      lawfulBasis:
        "Necessary for our legitimate interests to ensure secure storage of unrepatriated ambulance service records. Necessary for reasons of substantial public interest ",
    },
    {
      purposeAndActivity: "To recruit, appoint, appraise and develop our staff",
      typeofdata:
        "(a) Identity (b) Contact (c) Special categories (d) Other personal data related to recruitment and development ",
      lawfulBasis: "",
    },
    {
      purposeAndActivity:
        "To recruit, appoint, appraise and develop executives, chairs and non-executive directors to NHS trusts and trustees to NHS charities, and support NHS foundation trusts in recruiting executives, chairs and non-executive directors ",
      typeofdata:
        "(a) Identity (b) Contact (c) Special Categories (d) Other personal data related to recruitment and development",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England. Necessary for the management of health or social care systems and services – health and social care purposes.",
    },
    {
      purposeAndActivity:
        "To improve the leadership of NHS trust and foundation trust boards by monitoring their capacity, capability and diversity ",
      typeofdata: "(a) Identity (b) Contact (c) Special Categories ",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England. Necessary for our legitimate interests to ensure diversity in recruitment. Necessary for the management of health or social care systems and services — health and social care purposes.",
    },
    {
      purposeAndActivity:
        "To improve the leadership of NHS trust and foundation trust clinical staff through research programmes",
      typeofdata: "(a) Identity (b) Contact",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England.",
    },
    {
      purposeAndActivity:
        "To monitor independent providers’ compliance with the NHS Provider Licence ",
      typeofdata: "(a) Identity (b) Contact ",
      lawfulBasis:
        " 	Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England.",
    },
    {
      purposeAndActivity: "Information for Getting It Right First Time Programme ",
      typeofdata: "(a) Identity (b) Contact (c) Special Categories ",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England. Necessary for the management of health or social care systems and services – health and social care purposes.",
    },
    {
      purposeAndActivity: "NHS England nurses’ data",
      typeofdata: "(a) Contact",
      lawfulBasis: "Explicit consent ",
    },
    {
      purposeAndActivity: "Theatre productivity programme (clinician-level activity data) ",
      typeofdata: "(a) Identity (b) Contact ",
      lawfulBasis:
        "Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England.",
    },
    {
      purposeAndActivity: "Whistleblowers’ support scheme ",
      typeofdata: "(a) Contact ",
      lawfulBasis: "Explicit consent ",
    },
    {
      purposeAndActivity: "Healthcare Safety Investigation Branch (HSIB)",
      typeofdata: "(a) Identity (b) Special Categories",
      lawfulBasis: `Explicit consent
            Necessary for reasons for substantial public interest.
            Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in NHS England.
            Necessary for the management of health or social care systems and services — health and social care purpose. `,
    },
    {
      purposeAndActivity:
        "National Clinical Improvement Programme; to set up user accounts for consultants and create the database containing consultant level patient pseudonymised clinical activity data sourced from Hospital Episode Statistics (HES) provided by NHS Digital ",
      typeofdata: `(a) Identity (b) Contact (c) Special Categories`,
      lawfulBasis: `Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in either NHS England.
            Necessary for delivering our statutory functions.
            Necessary for the management of health or social care systems and services – health and social care purposes.`,
    },
  ]

  const hrefIds = {
    importantInfo: "important-info",
    dataWeCollect: "data-we-collect",
    personalData: "personal-data",
    purposes: "purposes",
    disclosures: "disclosures",
    international: "international",
    dataSecurity: "data-security",
    dataRentention: "data-rentention",
    legalRights: "legal-rights",
  }

  const sectionLinks = [
    {
      id: hrefIds.importantInfo,
      text: "Important information and who we are",
    },
    {
      id: hrefIds.dataWeCollect,
      text: "The data we collect about you",
    },
    {
      id: hrefIds.personalData,
      text: "How we use your personal data",
    },
    {
      id: hrefIds.purposes,
      text: "Purposes for which we will use your personal data",
    },
    {
      id: hrefIds.disclosures,
      text: "Disclosures of your personal data",
    },
    {
      id: hrefIds.international,
      text: "International transfers",
    },
    {
      id: hrefIds.dataSecurity,
      text: "Data security",
    },
    {
      id: hrefIds.dataRentention,
      text: "Data retention",
    },
    {
      id: hrefIds.legalRights,
      text: "Your legal rights",
    },
  ]

  const labels = [
    "Purpose/activity",
    "Type of data",
    "Law and basis of processing including basis of legitimate interest",
  ]

  return (
    <Container className="privacy-page">
      <Col width="three-quarters" className="privacy-page__column">
        <Label isPageHeading>Privacy</Label>
        <BodyText className="privacy-page__column__body-text">
          The NHS England applications website <strong>(‘this website’)</strong> is operated by NHS
          England. This website provides our online identity management solution (provided to us by
          a third party company called OKTA) which manages the access to products, services,
          restricted website content, dashboards or other applications provided by NHS England
          (hereafter collectively referred to ‘NHSE applications’).
        </BodyText>
        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">Contents</label>

        <ul className="privacy-page__column__contents-links">
          {sectionLinks.map(sectionLink => {
            const { id, text } = sectionLink
            return (
              <li key={id} className="privacy-page__column__contents-links__list-item">
                <a href={`#${id}`} className="nhsuk-contents-list__link">
                  {text}
                </a>
              </li>
            )
          })}
        </ul>

        <label
          id={hrefIds.importantInfo}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Important information and who we are
        </label>
        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">
          Purpose of this privacy notice
        </label>
        <BodyText className="privacy-page__column__body-text">
          This page, taken with the documents and pages it refers to, tells you the terms of use
          which you agree to when you use this website and all NHSE applications that follow on from
          registration with this website.
        </BodyText>
        <BodyText className="privacy-page__column__body-text">
          It is important that you read this privacy notice together with any other privacy notice
          or fair processing notice we may provide on specific occasions when we are collecting or
          processing personal data about you so that you are fully aware of how and why we are using
          your data. This privacy notice supplements the other notices and is not intended to
          override them.
        </BodyText>
        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">Controller</label>
        <BodyText className="privacy-page__column__body-text">
          This privacy notice is issued on behalf NHSE England so when we mention NHS England, ‘we’,
          ‘us’ or ‘our’ in this privacy notice, we are referring to the relevant legal entity in NHS
          England responsible for processing your data.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          NHS England may collect and use personal data for the functions that we exercise. Our{" "}
          <a
            href="https://www.england.nhs.uk/nhse-nhsi-privacy-notice/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="external">NHS England privacy notice</span>
          </a>{" "}
          explains how we do this.<br></br>
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          We have appointed a data protection officer (DPO) who is responsible for overseeing
          questions in relation to this privacy notice. If you have any questions about this privacy
          notice, please contact the DPO on{" "}
          <a href="mailto:england.contactus@nhs.net">england.dpo@nhs.net</a>.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          You have the right to make a complaint at any time to the{" "}
          <a href="https://ico.org.uk/" target="_blank" rel="noopener noreferrer">
            <span className="external">Information Commissioner’s Office (ICO)</span>
          </a>
          , the UK supervisory authority for data protection issues. We would, however, appreciate
          the chance to deal with your concerns before you approach the ICO, so please{" "}
          <a href="mailto:england.contactus@nhs.net">contact us</a>&nbsp;in the first instance.
        </BodyText>

        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">
          Your duty to inform us of changes{" "}
        </label>
        <BodyText className="privacy-page__column__body-text">
          It is important that the personal data we hold about you is accurate and current. Please
          keep us informed if your personal data changes (for example your email address) during
          your relationship with us.
        </BodyText>

        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">
          Third-party links{" "}
        </label>
        <BodyText className="privacy-page__column__body-text">
          This website may include links to third-party websites, plug-ins and applications.
          Clicking on those links or enabling those connections may allow third parties to collect
          or share data about you. We do not control these third-party websites and are not
          responsible for their privacy statements. When you leave our website, we encourage you to
          read the privacy notice of every website you visit.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          NHS England uses OKTA as a third-party service for their Identity Access Management
          system. Use of OKTA is subject to a separate privacy policy, which you are encouraged to
          review separately.
        </BodyText>

        <label
          id={hrefIds.dataWeCollect}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          The data we collect about you{" "}
        </label>
        <BodyText className="privacy-page__column__body-text">
          Personal data, or personal information, means any information about an individual from
          which that person can be identified. It does not include data where the identity has been
          removed (anonymous data).
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          We may collect, use, store and transfer different kinds of personal data about you which
          we have grouped together as follows:
        </BodyText>

        <ul className="privacy-page__column__data-collection">
          <li className="privacy-page__column__data-collection__list-item">
            Identity Data includes first name, maiden name, last name, username or similar
            identifier, marital status, title, date of birth and gender
          </li>
          <li className="privacy-page__column__data-collection__list-item">
            Contact Data includes email, postal address and telephone numbers
          </li>
          <li className="privacy-page__column__data-collection__list-item">
            Health Data includes information received by NHS England in the form of whistle-blowing
            and complaints received from members of the public and parliamentary questions;
            information relating to staff at NHS England; and internal investigations due to
            complaints or internal whistle-blowing. Information received relating to patient safety
            incidents recorded via the National Reporting and Learning System. Information shared by
            NHS Resolution about litigation claims as part of the Getting It Right First Time
            Programme
          </li>
          <li className="privacy-page__column__data-collection__list-item">
            Recruitment Data includes items of personal information such as your name, date of
            birth, address, qualifications, references and potentially special categories of data
            such as occupation health data (if provided) and disclosure and barring service
            self-declaration form details
          </li>
          <li className="privacy-page__column__data-collection__list-item">
            Special Categories of Personal Data (non-health) includes information about disability,
            race or ethnicity, religious or philosophical beliefs, sexual orientation or information
            about political opinions. It is collected with individual’s consent in order to fulfil
            our functions in the recruitment and appointment of chairs and non-executive directors
            of NHS trusts and charity trustees, and to improve the leadership of NHS trust and
            foundation trust boards by monitoring their capacity, capability and diversity
          </li>
          <li className="privacy-page__column__data-collection__list-item">
            other personal data which does not fall within any of these categories (such as
            employment status and contract type, criminal convictions and offences or bank details)
            is held to fulfil our functions in the recruitment, appointment, development, and
            appraisal of chairs and non-executive directors of NHS trusts, and charity trustees.
            This includes information and correspondence related to recruitment and appraisal,
            performance and development information for those who have either applied for roles that
            we advertise, have expressed an interest in applying for these roles or who have been
            appointed as chairs or non-executives of NHS trusts or as charitable trustees.{" "}
          </li>
        </ul>

        <label
          id={hrefIds.personalData}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          How we use your personal data{" "}
        </label>
        <BodyText className="privacy-page__column__body-text">
          We will only use your personal data when the law allows us to. Most commonly, we will use
          your personal data in the following circumstances:
        </BodyText>

        <ul className="privacy-page__column__personal-data">
          <li className="privacy-page__column__personal-data__list-item">
            where we need to perform the contract we are about to enter into or have entered into
            with you
          </li>
          <li className="privacy-page__column__personal-data__list-item">
            where it is necessary for the performance of a task carried out in the public interest
            or in the exercise of official authority vested in NHS England
          </li>
          <li className="privacy-page__column__personal-data__list-item">
            where we have your consent
          </li>
          <li className="privacy-page__column__personal-data__list-item">
            where we need to comply with a legal or regulatory obligation
          </li>
          <li className="privacy-page__column__personal-data__list-item">
            for the purposes of recruitment and selection and where it is necessary to enter into a
            contract with you.
          </li>
          <li className="privacy-page__column__personal-data__list-item">
            other personal data which does not fall within any of these categories (such as
            employment status and contract type, criminal convictions and offences or bank details)
            is held to fulfil our functions in the recruitment, appointment, development, and
            appraisal of chairs and non-executive directors of NHS trusts, and charity trustees.
            This includes information and correspondence related to recruitment and appraisal,
            performance and development information for those who have either applied for roles that
            we advertise, have expressed an interest in applying for these roles or who have been
            appointed as chairs or non-executives of NHS trusts or as charitable trustees.
          </li>
        </ul>

        <label
          id={hrefIds.purposes}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Purposes for which we will use your personal data{" "}
        </label>

        <Table className="privacy-page__column__purposes-table">
          <Table.Head className="privacy-page__column__purposes-table__head">
            <Table.Row className="privacy-page__column__purposes-table__head__row">
              <Table.Cell className="privacy-page__column__purposes-table__head__row__cell">
                {labels[0]}
              </Table.Cell>
              <Table.Cell className="privacy-page__column__purposes-table__head__row__cell--thirty-five">
                {labels[1]}
              </Table.Cell>
              <Table.Cell className="privacy-page__column__purposes-table__head__row__cell">
                {labels[2]}
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body className="privacy-page__column__purposes-table__body">
            {consentTableObjects.map(consentTableObject => {
              const { purposeAndActivity, typeofdata, lawfulBasis } = consentTableObject
              return (
                <Table.Row
                  key={purposeAndActivity}
                  className="privacy-page__column__purposes-table__body__row"
                >
                  <Table.Cell
                    className="privacy-page__column__purposes-table__body__row__cell"
                    label={labels[0]}
                  >
                    {purposeAndActivity}
                  </Table.Cell>
                  <Table.Cell
                    className="privacy-page__column__purposes-table__body__row__cell"
                    label={labels[1]}
                  >
                    {typeofdata}
                  </Table.Cell>
                  <Table.Cell
                    className="privacy-page__column__purposes-table__body__row__cell"
                    label={labels[2]}
                  >
                    {lawfulBasis}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">Cookies</label>

        <BodyText className="privacy-page__column__body-text">
          You can set your browser to refuse all or some browser cookies, or to alert you when
          websites set or access cookies. If you disable or refuse cookies, please note that some
          parts of this website may become inaccessible or not function properly. For more
          information about the cookies we use,{" "}
          <NavLink to="cp-cookies">please see our Cookie Policy</NavLink>.<br />
        </BodyText>

        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">
          Change of purpose
        </label>

        <BodyText className="privacy-page__column__body-text">
          We will only use your personal data for the purposes for which we collected it, unless we
          reasonably consider that we need to use it for another reason and that reason is
          compatible with the original purpose. If you wish to get an explanation as to how the
          processing for the new purpose is compatible with the original purpose, please contact us
          at <a href="mailto:england.dpo@nhs.net">england.dpo@nhs.net</a>.&nbsp;
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          If we need to use your personal data for an unrelated purpose, we will notify you and we
          will explain the legal basis which allows us to do so.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          Please note that we may process your personal data without your knowledge or consent, in
          compliance with the above rules, where this is required or permitted by law.
        </BodyText>

        <label
          id={hrefIds.disclosures}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Disclosures of your personal data
        </label>

        <BodyText className="privacy-page__column__body-text">
          We may have to share your personal data with NHS England in responding to a complaint or
          whistleblowing case about an NHS trust or NHS foundation trust.
          <a href="https://www.england.nhs.uk/ourwork/freedom-to-speak-up/">
            Please see our freedom to speak up policy for further details.
          </a>
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          We use NHS Business Services authority for the purposes of recruitment and where you have
          applied for a job with us (or NHS England) they will process your data on our behalf.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          We require them to respect the security of your personal data and only permit them to
          process your personal data for specified purposes and in accordance with our instructions.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          In relation to information to fulfil our functions to support NHS providers in the
          recruitment of chairs and non-executive directors and NHS charities in recruiting
          trustees, and in order to keep individuals who have expressed an interest in these roles
          informed of new and potential roles and development opportunities, the personal data of
          candidates and potential candidates may be shared with organisations who are recruiting,
          recruitment agencies, the Department of Health and Social Care, other Government
          Departments and Arm’s Length Bodies, stakeholder groups and the Commissioner for Public
          Appointments, as well as members of the selection panel. Where candidates are appointed to
          these roles we will share personal data about the successful candidate with the
          organisation concerned.
        </BodyText>

        <label
          id={hrefIds.international}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          International transfers
        </label>

        <BodyText className="privacy-page__column__body-text">
          As part of the services offered to you through your use of this website, the information
          which you provide to us in response to website surveys may be transferred to countries
          based outside the European Economic Area (EEA) so the processing of your personal data
          will involve a transfer of data outside the EEA.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          Where we use providers based in the US, we may transfer data to them if they are part of
          the Privacy Shield which requires them to provide similar protection to personal data
          shared between Europe and the US.
        </BodyText>

        <label
          id={hrefIds.dataSecurity}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Data security
        </label>

        <BodyText className="privacy-page__column__body-text">
          We have put in place appropriate security measures to prevent your personal data from
          being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In
          addition, we limit access to your personal data to those employees, agents, contractors
          and other third parties who have a business need to know. They will only process your
          personal data on our instructions and they are subject to a duty of confidentiality.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          We have put in place procedures to deal with any suspected personal data breach and will
          notify you and any applicable regulator of a breach where we are legally required to do
          so.
        </BodyText>

        <label
          id={hrefIds.dataRentention}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Data retention
        </label>

        <label className="nhsuk-label nhsuk-label--m privacy-page__column__label">
          How long will you use my personal data for?
        </label>

        <BodyText className="privacy-page__column__body-text">
          We will only retain your personal data for as long as necessary to fulfil the purposes we
          collected it for, including for the purposes of satisfying any legal, accounting, or
          reporting requirements.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          To determine the appropriate retention period for personal data, the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from unauthorised use or
          disclosure of your personal data, the purposes for which we process your personal data and
          whether we can achieve those purposes through other means, and the applicable legal
          requirements have been considered.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          All records held by NHS England will be kept for the duration specified by national
          guidance from the Department of Health and Social Care found in the Records management:
          NHS code of practice for health and social care 2016, supplemented by NHS England’s
          retention schedule.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          In some circumstances you can ask us to delete your data: see ‘Request erasure below for
          further information.
        </BodyText>

        <label
          id={hrefIds.legalRights}
          className="nhsuk-label nhsuk-label--m privacy-page__column__label"
        >
          Your legal rights
        </label>

        <BodyText className="privacy-page__column__body-text">
          <b>Request access</b> to your personal data (commonly known as a subject access request).
          This enables you to receive a copy of the personal data we hold about you and to check
          that we are lawfully processing it.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          <b>Request correction</b> of the personal data that we hold about you. This enables you to
          have any incomplete or inaccurate data we hold about you corrected, though we may need to
          verify the accuracy of the new data you provide to us.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          <b>Request erasure</b> of your personal data. This enables you to ask us to delete or
          remove personal data where there is no good reason for us continuing to process it. You
          also have the right to ask us to delete or remove your personal data where you have
          successfully exercised your right to object to processing (see below), where we may have
          processed your information unlawfully or where we are required to erase your personal data
          to comply with local law. Note, however, that we may not always be able to comply with
          your request of erasure for specific legal reasons which will be notified to you, if
          applicable, at the time of your request.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          <b>Object to processing</b> of your personal data where we are relying on a legitimate
          interest (or those of a third party) and there is something about your particular
          situation which makes you want to object to processing on this ground as you feel it
          impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we
          have compelling legitimate grounds to process your information which override your rights
          and freedoms.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          <b>Request restriction of processing</b> of your personal data. This enables you to ask us
          to suspend the processing of your personal data in the following scenarios: (a) if you
          want us to establish the data’s accuracy; (b) where our use of the data is unlawful but
          you do not want us to erase it; (c) where you need us to hold the data even if we no
          longer require it as you need it to establish, exercise or defend legal claims; or (d) you
          have objected to our use of your data but we need to verify whether we have overriding
          legitimate grounds to use it.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          If you wish to exercise any of the rights set out above, please contact us at{" "}
          <a href="mailto:england.dpo@nhs.net">england.dpo@nhs.net</a>.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          <b>Withdraw consent at any time</b> where we are relying on consent to process your
          personal data. However, this will not affect the lawfulness of any processing carried out
          before you withdraw your consent. If you withdraw your consent, we may not be able to
          provide certain services to you. We will advise you if this is the case at the time you
          withdraw your consent.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          If you wish to exercise this right set out above, please contact us on{" "}
          <a href="mailto:england.dpo@nhs.net">england.dpo@nhs.net</a>.
        </BodyText>

        <BodyText className="privacy-page__column__body-text">
          Please note that we don’t require you (whether under statute or contract) to provide
          personal data and we do not make use of automated decision making in relation to personal
          data. If this changes, we will let you know.
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(Privacy)
