import React from "react"

const CloseIcon = () => {
  return (
    <svg
      className="nhsuk-icon nhsuk-icon__close"
      xmlns="http://www.w3.org/2000/svg"
      fill="#005eb8"
      viewBox="0 0 24 24"
    >
      <path d="M13.41 12l5.3-5.29a1 1 0 1 0-1.42-1.42L12 10.59l-5.29-5.3a1 1 0 0 0-1.42 1.42l5.3 5.29-5.3 5.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5.29-5.3 5.29 5.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"></path>
    </svg>
  )
}

export default CloseIcon
