import { observer } from "mobx-react"
import { Col, Container } from "nhsuk-react-components"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { Navigate } from "react-router-dom"
import { BreadcrumbContext, withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import {
  AppAdmin as AppAdminCrumb,
  Invite as InviteCrumb,
  OpsAdmin as OpsAdminCrumb,
} from "../global/components/staticBreadcrumbs"
import { Routes } from "../global/enums"
import { useStores } from "../global/hooks"
import {
  BulkSentView,
  InvitationErrorsView,
  MainEditView,
  MultiEmailView,
  SentView,
  SummaryView,
} from "./components"
import { Views } from "./enums"
import "./styles.scss"

const Invitation = observer(() => {
  const { invitationStore: is, productOwnerAdminStore: poas } = useStores()

  const { productId } = useParams()
  const { wrapWithLoader } = useContext(LoaderContext)
  const { setBreadcrumbs } = useContext(BreadcrumbContext)
  const [view, setView] = useState(Views.Main)
  const [errorRedirect, setErrorRedirect] = useState(false)

  useEffect(() => {
    wrapWithLoader(async () => {
      await poas.getMyProducts()

      if (productId) {
        const product = poas.myProducts.find(p => p.id === productId)
        if (!product || !product.published) {
          setErrorRedirect(true)
        } else {
          poas.selectedProduct = product
          is.invitation.products = [productId]
        }
      }
    })

    return () => is.resetState()
  }, [productId])

  useEffect(() => {
    setNormalBreadcrumbs()
  }, [])

  const setViewInternal = (newView: Views) => {
    if (view === Views.BulkSent || view === Views.Sent) {
      if (newView !== Views.BulkSent && newView !== Views.Sent) {
        setNormalBreadcrumbs()
      }
    } else if (newView === Views.BulkSent || newView === Views.Sent) {
      setResettingBreadcrumbs()
    }

    setView(newView)
  }

  const setResettingBreadcrumbs = () => {
    const modifiedInviteCrumb = {
      ...InviteCrumb,
      onClick: () => {
        is.resetState()
        if (poas.selectedProduct) {
          is.invitation.products = [poas.selectedProduct.id]
        }
        setView(Views.Main)
      },
    }

    setBreadcrumbs([poas.selectedProduct ? AppAdminCrumb : OpsAdminCrumb, modifiedInviteCrumb])
  }

  const setNormalBreadcrumbs = () => {
    const modifiedInviteCrumb = {
      ...InviteCrumb,
      onClick: () => setView(Views.Main),
    }

    setBreadcrumbs([productId ? AppAdminCrumb : OpsAdminCrumb, modifiedInviteCrumb])
  }

  const InvitationInnerView = () => {
    switch (view) {
      case Views.Main:
        return <MainEditView setView={setViewInternal} productId={productId} />
      case Views.MultiEmail:
        return <MultiEmailView setView={setViewInternal} />
      case Views.Summary:
        return <SummaryView setView={setViewInternal} />
      case Views.Sent:
        return <SentView />
      case Views.InvitationErrors:
        return <InvitationErrorsView />
      case Views.BulkSent:
        return <BulkSentView />
    }
  }

  if (errorRedirect) {
    return <Navigate to={Routes.Error} />
  }

  return (
    <Container className="invitation-page nhsuk-width-container">
      <Col className="invite-page__wrapper" width="full">
        {view !== Views.BulkSent ? (
          <div className="inner-container">
            <InvitationInnerView />
          </div>
        ) : (
          <InvitationInnerView />
        )}
      </Col>
    </Container>
  )
})

export default withWrapper(Invitation)
