import PropTypes from "prop-types"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { LoaderContext } from "../../../../global/components/loaderProvider"
import { useStores } from "../../../../global/hooks"
import RequestPending from "../requestPending/RequestPending"
import "./RequestAccess.scss"

const RequestAccess = props => {
  const { applicationID, stepTwoRequired } = props

  const { atozStore } = useStores()

  const navigate = useNavigate()
  const [requestSent, setRequestSent] = useState(false)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [date, setDate] = useState()
  const handleRequestAccessClick = async e => {
    e.preventDefault()
    setDate(new Date())
    await wrapWithLoader(async () => {
      if (stepTwoRequired) {
        navigate(`/product/${applicationID}/steptwo`)
      } else {
        await atozStore.requestAppAccess(applicationID)
      }
    })
    setRequestSent(true)
  }
  return (
    <div className="az-page__request-access__container">
      {requestSent ? (
        ""
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            fill="#005EB8"
            d="M16.649 10.61c-.24.239-.539.418-.897.418H3.794c-.718 0-1.316-.598-1.256-1.255 0-.718.538-1.256 1.256-1.256h11.958c.717 0 1.255.538 1.255 1.256 0 .358-.12.598-.358.837z"
          />
          <path
            fill="#005EB8"
            d="M10.67 16.589c-.24.239-.538.418-.897.418-.718 0-1.256-.538-1.256-1.255V3.794c0-.718.538-1.256 1.256-1.256.717 0 1.255.538 1.255 1.256v11.958c0 .358-.12.598-.358.837z"
          />
        </svg>
      )}
      <div className="az-page__request-access__text" onClick={handleRequestAccessClick}>
        {requestSent ? <RequestPending date={date} /> : "Request Access"}
      </div>
    </div>
  )
}
RequestAccess.propTypes = {
  applicationID: PropTypes.string.isRequired,
  stepTwoRequired: PropTypes.bool.isRequired,
}

export default RequestAccess
