import React, { ComponentType, useState, useEffect } from "react"
import AcceptTermsAndConditions from "../../../../acceptTermsAndConditions"
import { get } from "../../../utils/api"

const checkTermsAPI = async () => {
  return get<boolean>(`/user/termsaccepteddatecheck`).then(res => {
    return res.data
  })
}

const withTermsCheck = <P extends object>(
  WrappedComponent: ComponentType<P>,
  redirectUrl: string
): React.ComponentType<P> => {
  const WithTermsCheck: React.ComponentType<P> = props => {
    const [termsUpdatedAfterAcceptedDate, setTermsUpdatedAfterAcceptedDate] = useState<
      boolean | null
    >(null)

    useEffect(() => {
      const fetchData = async () => {
        try {
          const hasAccepted = await checkTermsAPI()
          setTermsUpdatedAfterAcceptedDate(hasAccepted)
        } catch {
          setTermsUpdatedAfterAcceptedDate(true)
        }
      }

      fetchData()
    }, [])

    if (termsUpdatedAfterAcceptedDate === null) {
      return null
    }

    return !termsUpdatedAfterAcceptedDate ? (
      <WrappedComponent {...props} />
    ) : (
      <AcceptTermsAndConditions redirectUrl={redirectUrl} />
    )
  }

  return WithTermsCheck
}

export default withTermsCheck
