import { makeAutoObservable } from "mobx"
import React from "react"
import { IStringIndexableType } from "../../../app/global/interfaces/dataTypes/interfaces"

export interface IAnalyticalProductsStore {
  categoryDescriptions: IStringIndexableType<string | JSX.Element>
}

class AnalyticalProductsStore implements IAnalyticalProductsStore {
  constructor() {
    makeAutoObservable(this)
  }

  categoryDescriptions = {
    "covid-19":
      "Applications to support monitoring, the tracking of hot spots, early warnings and the impact of the COVID-19 pandemic. The applications provide analysis on the number of COVID-19 cases, bed occupancy, staff absences, discharges and mortality.",
    improvement:
      "Applications to support improvement initiatives, monitoring, and outcomes. The applications provide analysis on improving care, use of resources, productivity, outcomes, and patient experience.",
    "operational performance":
      "Applications to support the understanding of monthly performance against national standards; total activity; breaches and waiting times analysis for RTT; Cancer; Diagnostics; A&E; NHS 111 and Ambulance.",
    "patient costing":
      "Applications to provide standardised reporting of cost information at patient level for acute services activity, admitted patient care, outpatient appointments and accident and emergency attendances.",
    "population health":
      "Applications to support the understanding of socio-demographic characteristics within a population, the health outcomes of different groups of individuals, and the distribution of such outcomes within these groups.",
    "primary care":
      "Applications to support quality improvement in primary care through: identification of patient population health needs; access to services; and the monitoring, tracking and delivery of a range of primary care-based services and activity.",
    "quality of care":
      "Applications to support a view of the quality of care through standardised and centralised metrics, categorised into the following domains: Caring, Effective, Population Health, Safe, and Well-led.",
    "urgent and emergency care":
      "Applications to support the understanding of activity and daily situation reporting across accident and emergency departments, ambulance services, and NHS 111.",
    workforce:
      "Applications to support the understanding of the composition, turnover, sickness and equality across the NHS workforce.",
    "climate change":
      "Applications to support sustainability, to aim for a net zero health service, and to respond to climate change, thereby improving health now and for future generations.",
    recovery: (
      <span>
        Products to support the tracking and enabling the recovery of NHS to pre-pandemic levels.
        The applications provide analysis on
        <ul>
          <li key="outpatient-transformation">
            Outpatient Transformation plans i.e. roll out of out patient initiated follow-up (PIFU).
          </li>
          <li key="theatre-utilization">Theatre Utilization</li>
          <li key="impact-mental-health-and-wellbeing">
            Impact of COVID-19 on mental health and wellbeing
          </li>
          <li key="impact-children-and-young">Impact of COVID-19 on Children and Young People</li>
        </ul>
      </span>
    ),
  }
}

export default new AnalyticalProductsStore()
