import { IValidationRule, IValidationResult } from "../../interfaces/validation"
import _ from "lodash"

export class ValidationRule<T, TProp> implements IValidationRule<T> {
  private _propertySelectionRule: (source: T) => TProp
  private _message: string
  private _code: number
  private _attributeSelectors: ((target: TProp) => boolean)[] | null
  private _anySelectors: ((target: T) => unknown)[] | null

  constructor(
    propertySelectionRule: (source: T) => TProp,
    message: string,
    code: number,
    attributeSelectors: ((target: TProp) => boolean)[] | null,
    anySelectors: ((target: T) => unknown)[] | null
  ) {
    this._propertySelectionRule = propertySelectionRule
    this._message = message
    this._code = code
    this._attributeSelectors = attributeSelectors
    this._anySelectors = anySelectors
  }

  execute = (target: T): IValidationResult => {
    const targetProp = this._propertySelectionRule(target)
    let success = true

    if (this._attributeSelectors) {
      success = this._attributeSelectors.every(selector => selector(targetProp))
    }

    if (this._anySelectors && success) {
      success = this._anySelectors.some(selector => !_.isEmpty(selector(target)))
    }

    if (!success) {
      return {
        success: false,
        message: this._message,
        code: this._code,
      }
    }

    return {
      success: true,
    }
  }
}
