import { IOrganisation } from "../../interfaces/organisation/interfaces"

export const OrganisationKeys = {
  keyNHSTrust: "NHSTrust",
  keyCCG: "CCG",
  keyPCN: "PCN",
  keyGP: "GP",
  KeyICB: "ICB",
  keyALB: "ALB",
  keyNHSEI: "Z45",
  keyNHSD: "X26",
  keyNHSR: "T1150",
  keyNHSBT: "T1460",
  keyCharity: "Charity",
  keyDH: "Z02",
  keyHEE: "T1510",
  keyCQC: "Z07",
  keyNICE: "Z23",
  keyPHE: "Z48",
  keyPrivate: "ISHP",
  keyUniversity: "University",
  keyGov: "Gov",
  keyLA: "LA",
  keyCSU: "CSU",
  keyNHS: "NHS",
  KeyNI: "NI",
  aLL: "ALL",
  keyOtherNHS: "Other (NHS)",
  keyOtherNonNHS: "Other (non-NHS)",
  independentHealthSectorProviderSite: "ISHPSite",
  keyPharmacy: "Pharmacy",
  keyOldNHSI: "Z01",
  keyOldNHSD: "Z44",
  keyOldHEE: "Z46",
  keyUnusedKernowHealthCic: "AYG01",
  keyInnerDH: "XDH",
  keyOtherPrimary: "OtherPrimary",
}

export const OrganisationTypes = {
  albOrg: "ALB",
  pcnOrg: "PCN",
  gpOrg: "GP",
  pharmacyOrg: "Pharmacy",
  ccgOrg: "CCG",
  csuOrg: "CSU",
  nhsTrustOrg: "NHSTrust",
  allOrNhs: ["ALL", "NHS"],
  otherOrgs: ["Other (NHS)", "Other (non-NHS)"],
  ishpOrg: "ISHP",
  icbOrg: "ICB",
  nhsOrg: "NHS",
  allOrgs: "ALL",
  OtherPrimary: "OtherPrimary",
}

export const OrganisationCategories = {
  Nhs: "NHS",
  NonNhs: "Non-NHS",
  NI: "NI",
}

export const SubOrganisationTypes = [
  OrganisationTypes.pcnOrg,
  OrganisationTypes.ishpOrg,
  OrganisationTypes.nhsTrustOrg,
  OrganisationTypes.icbOrg,
  OrganisationTypes.csuOrg,
  OrganisationTypes.ccgOrg,
  ...OrganisationTypes.otherOrgs.flat(),
]

export const RegionalOrganisationKeys = [
  OrganisationKeys.keyNHSEI,
  OrganisationKeys.keyOldNHSI,
  OrganisationKeys.keyOldNHSD,
  OrganisationKeys.keyOldHEE,
]

export const isAllOrNhs = (type: string) => OrganisationTypes.allOrNhs.indexOf(type) !== -1

export const asyncSearchOrganisations = [OrganisationTypes.pcnOrg, OrganisationTypes.ishpOrg]

export const isOther = (org: IOrganisation | undefined) =>
  org && org.code && OrganisationTypes.otherOrgs.indexOf(org.code) !== -1
