import { observer } from "mobx-react"
import { Header } from "nhsuk-react-components"
import React, { useEffect, useMemo, useState, useContext } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { RemoteComponent } from "../../../../../remoteComponent"
import { FeatureToggle, Routes } from "../../../enums"
import { useFeatureToggles, useStores } from "../../../hooks"
import { IBreadcrumb } from "../../../interfaces/withWrapper"
import { getJWT } from "../../../utils/cookies"
import { logout } from "../../../utils/dom"
import { createBreadcrumb } from "../../staticBreadcrumbs"
import { Breadcrumbs, CookieBanner, LocalFooter, LocalHeader } from "./components"
import "./styles.scss"
import { ProgressiveWebApplicationContext } from "../../../context"

interface IPathProperties {
  isAnalyticsHubPage: boolean
  isRegistrationPage: boolean
  isRegistrationTermsPage: boolean
  isLoginPage: boolean
}

interface IBreadcrumbContext {
  createBreadcrumb: (link: string, name: string, onClick?: () => void) => IBreadcrumb
  setBreadcrumbs: (crumbs: IBreadcrumb[]) => void
}

export const BreadcrumbContext = React.createContext<IBreadcrumbContext>({
  createBreadcrumb: createBreadcrumb,
  setBreadcrumbs: (_: IBreadcrumb[]) => Function.prototype,
})

const withWrapper =
  <T extends object>(Component: React.ComponentType<T>, hideNav = false) =>
  (props: T) => {
    const { pathname } = useLocation()

    const { profileStore: profile, applicationStore: app } = useStores()

    const {
      enabledToggles: [coreApiEnabled],
      loading: featureTogglesLoading,
    } = useFeatureToggles(FeatureToggle.CoreApiEnabled)

    const { isPwa } = useContext(ProgressiveWebApplicationContext)

    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])
    const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(true)
    const [renderLocalHeader, setRenderLocalHeader] = useState<boolean>(false)
    const [renderLocalFooter, setRenderLocalFooter] = useState<boolean>(false)
    const [renderRemote, setRenderRemote] = useState<boolean>(false)

    const { hasHeader: header, title = null, hasFooter: footer } = app.getPageSettings(pathname)

    useEffect(() => {
      const root = document.getElementById("root")
      if (root) {
        root.scrollTop = 0
      }
    }, [])

    useEffect(() => {
      if (!featureTogglesLoading) {
        const renderRemote = profile.user?.emailAddress && coreApiEnabled

        if (!renderRemote) {
          setRenderLocalHeader(header && !coreApiEnabled)
          setRenderLocalFooter(footer && !coreApiEnabled)
        } else {
          setRenderRemote(renderRemote)
        }
      }
    }, [featureTogglesLoading, coreApiEnabled, profile.user, header, footer])

    const pathProperties: IPathProperties = useMemo(() => {
      const localPathProperties: IPathProperties = {
        isAnalyticsHubPage: false,
        isRegistrationPage: false,
        isRegistrationTermsPage: false,
        isLoginPage: false,
      }

      switch (pathname) {
        case Routes.AnalyticsHubLandingPage:
          return { ...localPathProperties, isAnalyticsHubPage: true }
        case Routes.Registration:
          return { ...localPathProperties, isRegistrationPage: true }
        case Routes.RegistrationTerms:
          return { ...localPathProperties, isRegistrationTermsPage: true }
        case Routes.Login:
          return { ...localPathProperties, isLoginPage: true }
      }

      return localPathProperties
    }, [pathname])

    const { isAnalyticsHubPage, isRegistrationPage, isRegistrationTermsPage, isLoginPage } =
      pathProperties

    const saveCookieAcceptance = () => {
      localStorage.setItem("cookies-accepted", "true")
      setCookiesAccepted(true)
    }

    const WrapperHeader = useMemo(() => {
      if (renderRemote) {        
        return (
          <RemoteComponent
            url={`${process.env.REACT_APP_HEADER_RC_URL}`}
            UserObject={profile.user}
            Bearer={getJWT()}
            SignOut={logout}
            UserId={profile.user?.identityProviderId}
            isPwa={isPwa}
            loginUrl={Routes.Login}
          />
        )
      } else if (renderLocalHeader) {
        return (
          <LocalHeader
            isRegistrationPage={isRegistrationPage}
            isRegistrationTermsPage={isRegistrationTermsPage}
            appTitle={app.appTitle}
            hideNav={hideNav}
          />
        )
      }

      return null
    }, [
      renderRemote,
      profile.user,
      getJWT,
      logout,
      renderLocalHeader,
      isRegistrationPage,
      isRegistrationTermsPage,
      app.appTitle,
      hideNav,
      isPwa,
    ])

    const WrapperFooter = useMemo(() => {
      if (renderRemote) {
        return (
          <RemoteComponent
            url={`${process.env.REACT_APP_FOOTER_RC_URL}`}
            UserId={profile.user?.identityProviderId}
            Bearer={getJWT()}
            Green={isAnalyticsHubPage}
          />
        )
      } else if (renderLocalFooter) {
        return <LocalFooter />
      }

      return null
    }, [renderRemote, profile.user, getJWT, isAnalyticsHubPage, renderLocalFooter])

    return (
      <BreadcrumbContext.Provider value={{ setBreadcrumbs, createBreadcrumb }}>
        <Helmet>
          <title>
            {title ? `${title} | ` : ""}
            {app.appTitle}
          </title>
        </Helmet>
        {!cookiesAccepted && (
          <CookieBanner
            isRegistrationPage={isRegistrationPage}
            saveCookieAcceptance={saveCookieAcceptance}
          />
        )}
        {(isRegistrationPage || isRegistrationTermsPage || isLoginPage) && (
          <Header id="nhsi-header" white>
            <Header.Container id="nhsi-header-container" className="header--left-logo">
              <Header.Logo />
            </Header.Container>
          </Header>
        )}

        {WrapperHeader}

        {breadcrumbs.length > 0 && <Breadcrumbs crumbs={breadcrumbs} />}

        <Component {...props} />

        {WrapperFooter}
      </BreadcrumbContext.Provider>
    )
  }

export default <T extends object>(Component: React.ComponentType<T>, hideNav = false) =>
  observer(withWrapper(Component, hideNav))
