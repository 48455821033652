import React from "react"
import { Chevron } from "./assets"
import "./styles.scss"

const MockSelectBox = React.forwardRef((props, ref) => {
  const { onClick, text, open } = props

  return (
    <div ref={ref} className="nhsuk-select" onClick={onClick}>
      <span className="mock-select">{text}</span>
      <Chevron invert={open} />
    </div>
  )
})

export default MockSelectBox
