import { Button, CloseIcon, SearchIcon } from "nhsuk-react-components"
import React, { useState } from "react"
import { getApi } from "../../../../global/utils/api"
import { MessageKey } from "../../../enums/messageKeys/enums"
import { useMessageKeys } from "../../../hooks"
import { IOrganisation } from "../../../interfaces/organisation"
import { ErrorsCollection } from "../../../types"
import OrganisationSearchForm from "./organisationSearchForm"
import SearchResults from "./searchResults"
import "./styles.scss"

interface IOtherOrganisationSelectionModalContent {
  cancel: () => void
  submit: (organisation: IOrganisation | null) => void
  defaultOrganisation: IOrganisation | null
  organisationFromModalInEditMode: boolean
  setOrganisationFromModalInEditMode: (inEditMode: boolean) => void
  errors: ErrorsCollection | null | undefined
}

const OtherOrganisationSelectionModalContent = (props: IOtherOrganisationSelectionModalContent) => {
  const {
    cancel,
    submit,
    defaultOrganisation,
    organisationFromModalInEditMode,
    setOrganisationFromModalInEditMode,
    errors,
  } = props

  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  const searchSelection = `select=Id&select=OrganisationTypeId&select=OrganisationName&select=RegionId&select=FriendlyName&select=Code&select=STPId&top=100&fuzzy=true&excludeTrustSites=true`
  const [organisations, setOrganisations] = useState([])
  const [selectedOrganisation, setSelectedOrganisation] = useState<IOrganisation | null>(
    defaultOrganisation
  )

  const onSearch = async (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const search = (event.target as HTMLInputElement).value

    if (search.length >= 3 && !organisationFromModalInEditMode) {
      const result = await getApi(
        `organisation/indexsearch/searchIndex?searchText=${search}&${searchSelection}`
      )
      setOrganisations(result.data)
    }

    setOrganisationFromModalInEditMode(false)
  }

  const validateAndSubmitSelectedOrganisation = (organisation: IOrganisation | null) => {
    if (!organisation) {
      return
    }

    submit(organisation)
  }

  return (
    <div id="otherorganisationSelectionModal">
      <div id="otherorganisationSelectionModal_label_and_close">
        <h3>Search organisations</h3>
        <button className="otherorganisationSelectionModal__close-icon" onClick={cancel}>
          <CloseIcon />
        </button>
      </div>
      <div id="otherorganisationSelectionModal_form_and_searchIcon">
        <OrganisationSearchForm onSearch={onSearch} selectedOrganisation={selectedOrganisation} />
        <SearchIcon id="otherorganisationSelectionModal_form_and_searchIcon_searchIcon" />
      </div>
      <hr />
      <div id="otherorganisationSelectionModal_search_results">
        <SearchResults
          organisations={organisations}
          selectedOrganisation={selectedOrganisation}
          setSelectedOrganisation={setSelectedOrganisation}
          errors={errors}
        />
      </div>
      <hr />

      <div id="button-controls">
        <Button
          disabled={!selectedOrganisation}
          className="otherorganisationSelectionModal__continue-button"
          onClick={() => validateAndSubmitSelectedOrganisation(selectedOrganisation)}
        >
          Continue
        </Button>

        <p className="otherorganisationSelectionModal__contact-text">
          If your organisation is not listed, contact
          <br />
          <a
            href={`${itOps}?subject=Registration - Unable to find organisation&body=Please include the email and organisation name you are using to register. Also mention any specific application or dashboard you are trying to get access to.`}
          >
            {serviceDesk}.
          </a>
        </p>
      </div>
    </div>
  )
}

export default OtherOrganisationSelectionModalContent
