export const findDomain = (email: string) => {
  let emailType = "NON-NHS"
  const emailDomain = email.substring(email.lastIndexOf("@") + 1).toLowerCase()
  if (
    emailDomain === "nhs.net" ||
    emailDomain === "nhs.uk" ||
    emailDomain.endsWith(".nhs.net") ||
    emailDomain.endsWith(".nhs.uk")
  ) {
    emailType = "NHS"
  }
  return emailType
}

export const convertKeyToLowercase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const convertKeyToUppercase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
