import { BodyText, Col, Container } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { MessageKey } from "../global/enums/messageKeys/enums"
import { useMessageKeys } from "../global/hooks"
import "./styles.scss"

const CorePlatformAccessibilityPolicy = () => {
  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  return (
    <Container className="accessibility" id="accessibility">
      <Col width="three-quarters">
        <h1>Accessibility statement for NHS England applications</h1>
        <BodyText>
          This website and/or embedded application are run by NHS England. We are committed to
          achieving excellence in accessible design, because we want as many colleagues as possible
          in the NHS and wider healthcare services to be able to use them.
        </BodyText>
        <BodyText>Users should be able to:</BodyText>
        <ul>
          <li className="accessibility__list-item">
            zoom in up to 300% without the text spilling off the screen{" "}
          </li>
          <li className="accessibility__list-item">
            navigate most of the website and applications using just a keyboard{" "}
          </li>
          <li className="accessibility__list-item">
            navigate most of the website and applications using speech recognition software
          </li>
          <li className="accessibility__list-item">
            navigate most of the website and applications using a screen reader{" "}
          </li>
        </ul>
        <BodyText>We’ve also made the text as simple as possible to understand.</BodyText>
        <BodyText>
          <a href="https://mcmw.abilitynet.org.uk/" target="_blank" rel="noopener noreferrer">
            AbilityNet
          </a>{" "}
          has advice on making your device easier to use if you have a disability.
        </BodyText>
        <h3>How accessible are the website and embedded applications?</h3>
        <BodyText>
          This accessibility statement applies to Model Health System, and to a large collection of
          other analytical tools, many of which have been created using data visualization tools
          from Tableau Software. It also applies to the gateway pages and registration forms that
          allow access to Model Health System and the other analytical tools.
        </BodyText>
        <BodyText>
          We know some pages and embedded applications are not fully accessible. The list below
          describes some examples of features that we intend to improve in future iterations of the
          design:
        </BodyText>
        <ul>
          <li className="accessibility__list-item">
            Some content is difficult to navigate with a screen-reader, most notably the analytical
            tools and Model Health System’s interactive charts{" "}
          </li>
          <li className="accessibility__list-item">
            Some content is difficult to navigate using the keyboard only, most notably the
            analytical tools and Model Health System’s interactive charts{" "}
          </li>
          <li className="accessibility__list-item">
            Magnification can cause difficulty: zooming in to above 300% normal display size causes
            the header to occupy most of the screen area
          </li>
          <li className="accessibility__list-item">
            It is not possible for users to employ browser settings or extensions to choose their
            own text/background colour combinations{" "}
          </li>
          <li className="accessibility__list-item">
            Some pages containing data in a table-like structure are difficult to read with a
            screen-reader{" "}
          </li>
          <li className="accessibility__list-item">
            Some pages do not offer an easy way to skip to the main content when using a screen
            reader{" "}
          </li>
        </ul>
        <BodyText>
          Excluding the features mentioned above, this website is compliant with the Web Content
          Accessibility Guidelines version 2.1 AA standard.
        </BodyText>
        <BodyText>
          If you need information on this website in a different format like accessible PDF or large
          print, email: <a href={itOps}>{serviceDesk}</a>
        </BodyText>
        <h3>Reporting accessibility problems with this website</h3>
        <BodyText>
          We’re always looking for ways to improve the accessibility of the website and associated
          applications.
        </BodyText>
        <BodyText>
          If you find any problems, please tell us. We would like to know. It will be helpful if you
          take note of whether the problem was found while searching for an application on a list
          page, while registering for an application, or while using the application itself.
        </BodyText>
        <BodyText>
          If you find problems while you browse the lists of applications, or as you attempt to
          register for one of them, please contact: {serviceDesk}
        </BodyText>
        <BodyText>
          If you find problems while you use any of the analytical applications, you may contact the
          application’s own support team. You will find their contact details quoted in the
          application itself.
        </BodyText>
        <BodyText>
          If you are unable to find a contact address for any application, or you are unhappy with
          the response you get, send your comments to: {serviceDesk}.
        </BodyText>
        <h3>Enforcement procedure</h3>
        <BodyText>
          The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public
          Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018
          (the ‘accessibility regulations’). If you’re not happy with how we respond to your
          complaint, contact the Equality Advisory and Support Service (EASS).
        </BodyText>
        <h3>Technical information about this website’s accessibility</h3>
        <BodyText>
          NHS England is committed to making its website and applications accessible, in accordance
          with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
          Regulations 2018.
        </BodyText>
        <h3>How we tested this website</h3>
        <BodyText>
          This website was last tested on April 1 2021. An expert review was carried out by the
          Digital Products and Services team of NHS England, in partnership with a private sector
          design agency.
        </BodyText>
        <BodyText>This statement was prepared on April 7 2021.</BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(CorePlatformAccessibilityPolicy)
