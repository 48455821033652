import { observer } from "mobx-react"
import { Select } from "nhsuk-react-components"
import React from "react"
import TextButton from "../../../../../global/components/textButton"
import "./styles.scss"
import { useStores } from "../../../../../global/hooks"

const Downloads = observer((): JSX.Element => {
  const {
    tableauReportViewerConfigurationStore: {
      tableauReportViewerData: { errors, workbook },
    },
  } = useStores()

  return (
    <>
      {workbook.downloads.map((x, idx) => (
        <div className="downloads" key={`${idx}-${x.dashboardName}`}>
          <Select
            id="download-dashboard"
            className="downloads__select input-field"
            defaultValue={x.dashboardName}
            error={errors.Downloads}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              e.stopPropagation()
              workbook.downloads[idx].dashboardName = e.target.value
              workbook.downloads[idx].title = "all data from this tab"
            }}
          >
            <Select.Option key="" value="">
              Select a view
            </Select.Option>
            {x.dashboardName && (
              <Select.Option key={x.dashboardName}>{x.dashboardName}</Select.Option>
            )}
            {workbook.views
              .filter(v => workbook.downloads.filter(d => d.dashboardName === v).length === 0)
              .map(view => {
                return (
                  <Select.Option key={view} value={view}>
                    {view}
                  </Select.Option>
                )
              })}
          </Select>

          {idx > 0 && (
            <TextButton
              className="downloads__deleteButton"
              onClick={() => workbook.downloads.splice(idx, 1)}
            >
              Delete
            </TextButton>
          )}
        </div>
      ))}
    </>
  )
})

export default Downloads
