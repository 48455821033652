import { AxiosResponse } from "axios"
import _ from "lodash"
import { makeAutoObservable } from "mobx"
import React from "react"
import { IUserProfileUpdateResult } from "../../profilePage/interfaces"
import { OrganisationKeys, SubOrganisationTypes } from "../constants/organisation/constants"
import { HttpStatusCode } from "../enums/api"
import { MessageKey } from "../enums/messageKeys/enums"
import { IJobRole } from "../interfaces/jobRole/interfaces"
import { IOrganisation, IOrganisationModel } from "../interfaces/organisation"
import { IRegion } from "../interfaces/region"
import { ICurrentUser, IUserSearchResponse } from "../interfaces/user/interfaces"
import MessageKeyStore from "../stores/messageKeyStore"
import { ErrorsCollection } from "../types"
import { get, post, postApi } from "../utils/api"
import { validateEmail, validateName } from "../utils/validation"
import { IStringIndexableType } from "../interfaces/dataTypes/interfaces"

//#region Constants
export const profileUpdateFields = {
  firstName: "firstName",
  lastName: "lastName",
  emailAddress: "emailAddress",
  jobRole: "jobRole",
  parentOrganisation: "parentOrganisation",
  subOrganisation: "subOrganisation",
  organisation: "organisation",
  region: "region",
  emailAddressConfirmation: "emailAddressConfirmation",
}

const simpleFields = [
  profileUpdateFields.firstName,
  profileUpdateFields.lastName,
  profileUpdateFields.emailAddress,
]

const complexFields = {
  [profileUpdateFields.organisation]: {
    comparisonFields: ["code", "organisationType"],
  },
  [profileUpdateFields.region]: {
    comparisonFields: ["code"],
  },
  [profileUpdateFields.jobRole]: {
    comparisonFields: ["title"],
  },
}

const displayNames = {
  [profileUpdateFields.firstName]: "First name",
  [profileUpdateFields.lastName]: "Last name",
  [profileUpdateFields.emailAddress]: "Email address",
  [profileUpdateFields.jobRole]: "Job role (main)",
  [profileUpdateFields.organisation]: "Organisation (main)",
  [profileUpdateFields.region]: "Region",
}

const controllerPrefix = "/user/"

const storeUrls = {
  currentUser: `${controllerPrefix}current`,
  updatePassword: `${controllerPrefix}updatepassword`,
  updateRecovery: `${controllerPrefix}updaterecovery`,
  updateProfile: `${controllerPrefix}updateProfile`,
  userExists: `${controllerPrefix}exists?emailAddress=`,
  hasmhsaccess: `${controllerPrefix}hasmhsaccess`,
  searchUserByPartialTerm: `${controllerPrefix}getUserByPartialSearchTerm?search=`,
}

//#endregion

type PayloadValue = string | IRegion | IJobRole | IOrganisation | undefined

export interface IUpdatePasswordData extends IStringIndexableType<string> {
  password: string
  confirmPassword: string
}

export interface IUpdateSecurityData extends IStringIndexableType<string> {
  recoveryQuestion: string
  recoveryAnswer: string
}

interface IProfileUpdateFieldsDisplayProperties extends Record<string, string> {
  jobRole: string
}

interface INewOrganisation {
  name: string
  code: string
  isOrgType?: boolean
  organisationType?: string
  regionCodes?: string[]
  category?: string
}

// Refactor to not use indexing
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface IProfileChangesShape extends Record<string, any> {
  firstName?: string
  lastName?: string
  emailAddress?: string
  emailAddressConfirmation?: string
  region?: { code: string }
  jobRole?: IJobRole
  parentOrganisation?: IOrganisation
  subOrganisation?: IOrganisationModel | null
  organisation?: INewOrganisation
}

export interface IProfileStore {
  user: ICurrentUser | null
  isAdmin: boolean
  isUserAuthenticated: boolean
  error: ErrorsCollection
  currentPassword: string
  updatePasswordData: IUpdatePasswordData
  updateSecurityData: IUpdateSecurityData
  getUserExists: (emailAddress: string) => Promise<boolean>
  profileUpdateFieldsDisplayProperties: IProfileUpdateFieldsDisplayProperties
  isPayloadValueInvalid: (str: string) => boolean
  resetChanges: () => void
  getDisplayName: (key: string) => string
  initialProfileData: IProfileChangesShape
  profileUpdateData: IProfileChangesShape
  getDisplayValue: (key: string, payload: IProfileChangesShape) => string
  postProfileUpdatePayload: (payload: IProfileChangesShape) => Promise<IUserProfileUpdateResult>
  initializeProfile: (data: ICurrentUser) => void
  userHasMhsAccess: () => Promise<boolean>
  getChangesShape: () => IProfileChangesShape
  updatePassword: () => Promise<AxiosResponse | { errors: Record<string, string> }>
  getUser: () => Promise<AxiosResponse<ICurrentUser>>
  getPayload: (changes: IProfileChangesShape) => IProfileChangesShape
  validateChanges: (changes: IProfileChangesShape) => Promise<ErrorsCollection | null>
  searchForUser: (searchTerm: string) => Promise<AxiosResponse<IUserSearchResponse[]>>
  updateSecurity: () => Promise<void>
}

class ProfileStore implements IProfileStore {
  user: ICurrentUser | null = null
  isAdmin = false
  isUserAuthenticated = false
  error = new ErrorsCollection()
  currentPassword = ""

  updatePasswordData = {
    password: "",
    confirmPassword: "",
  }

  updateSecurityData = {
    recoveryQuestion: "",
    recoveryAnswer: "",
  }

  profileUpdateFieldsDisplayProperties = {
    jobRole: "title",
  }

  initialProfileData: IProfileChangesShape = {}

  profileUpdateData: IProfileChangesShape = {}

  constructor() {
    makeAutoObservable(this)
  }

  isPayloadValueInvalid = (val: PayloadValue) => !val && val !== ""

  getPayload = (changes: IProfileChangesShape): IProfileChangesShape => {
    if (changes.subOrganisation) {
      changes.organisation = {
        code: changes.subOrganisation.value,
        name: changes.subOrganisation.label,
      }
      if (changes.subOrganisation.regionCodes.length === 0) {
        delete changes.region
      }
    } else if (changes.parentOrganisation && !changes.subOrganisation) {
      changes.organisation = changes.parentOrganisation
      if (changes.parentOrganisation.regionCodes.length === 0) {
        delete changes.region
      }
    }

    if (changes.organisation) {
      delete changes.organisation.regionCodes
    }

    delete changes.emailAddressConfirmation
    delete changes.subOrganisation
    delete changes.parentOrganisation

    if (
      changes.organisation &&
      (changes.organisation.code ===
        this.initialProfileData[profileUpdateFields.parentOrganisation]?.code ||
        changes.organisation.code ===
          this.initialProfileData[profileUpdateFields.subOrganisation]?.value)
    ) {
      delete changes.organisation
    }

    return changes
  }

  validateChanges = async (changes: IProfileChangesShape): Promise<ErrorsCollection | null> => {
    const errors = new ErrorsCollection()

    if (!changes || Object.values(changes).every(this.isPayloadValueInvalid)) {
      errors.add({
        key: "general",
        value: [
          {
            fieldError: "No changes have been made",
            summaryError: "No changes have been made",
          },
        ],
      })
      return errors
    }

    if (changes.firstName !== undefined) {
      if (changes.firstName === "") {
        errors.add({
          key: profileUpdateFields.firstName,
          value: [
            {
              fieldError: "This field is mandatory",
              summaryError: "Please complete both name fields before continuing.",
            },
          ],
        })
      } else if (!validateName(changes.firstName)) {
        errors.add({
          key: profileUpdateFields.firstName,
          value: [
            {
              fieldError: "Invalid characters used",
              summaryError: '"First name" field includes invalid characters',
            },
          ],
        })
      }
    }

    if (changes.lastName !== undefined) {
      if (changes.lastName === "") {
        errors.add({
          key: profileUpdateFields.lastName,
          value: [
            {
              fieldError: "This field is mandatory",
              summaryError: "Please complete both name fields before continuing.",
            },
          ],
        })
      } else if (!validateName(changes.lastName)) {
        errors.add({
          key: profileUpdateFields.lastName,
          value: [
            {
              fieldError: "Invalid characters used",
              summaryError: '"Last name" field includes invalid characters',
            },
          ],
        })
      }
    }

    if (!this.isPayloadValueInvalid(changes.emailAddress) && !validateEmail(changes.emailAddress)) {
      errors.add({
        key: profileUpdateFields.emailAddress,
        value: [
          {
            fieldError: "Email address is not valid",
            summaryError: "The email address you entered is not valid.",
          },
        ],
      })
    }

    if (
      !this.isPayloadValueInvalid(changes.emailAddress) &&
      validateEmail(changes.emailAddress) &&
      changes.emailAddress !== changes.emailAddressConfirmation
    ) {
      errors.add({
        key: profileUpdateFields.emailAddressConfirmation,
        value: [
          {
            fieldError: "Email addresses do not match",
            summaryError: "The email addresses you entered do not match.",
          },
        ],
      })
    }

    if (
      changes.parentOrganisation &&
      SubOrganisationTypes.indexOf(changes.parentOrganisation.code) !== -1 &&
      !changes.subOrganisation
    ) {
      errors.add({
        key: profileUpdateFields.subOrganisation,
        value: [
          {
            fieldError: "This field is mandatory",
            summaryError: "Please complete both organisation fields before continuing.",
          },
        ],
      })
    }

    if (
      ((changes.parentOrganisation?.regionCodes &&
        changes.parentOrganisation.regionCodes.length > 0) ||
        (changes.subOrganisation && changes.subOrganisation.regionCodes.length > 0)) &&
      !changes.region &&
      !this.profileUpdateData?.region?.code
    ) {
      errors.add({
        key: profileUpdateFields.region,
        value: [
          {
            fieldError: "Please select a region",
            summaryError: "Please select a region",
          },
        ],
      })
    }

    if (
      !errors.hasWithPredicate(err => err.key === profileUpdateFields.emailAddress) &&
      !this.isPayloadValueInvalid(changes.emailAddress) &&
      validateEmail(changes.emailAddress) &&
      changes.emailAddress
    ) {
      if (await this.getUserExists(encodeURI(changes.emailAddress))) {
        const [itOps, serviceDesk] = await MessageKeyStore.getKeyValues(
          MessageKey.SupportEmailAddressLink,
          MessageKey.SupportEmailAddress
        )
        errors.add({
          key: profileUpdateFields.emailAddress,
          value: [
            {
              fieldError: "Email is already in use",
              summaryError: (
                <span>
                  The email address you entered is already in use. Please contact{" "}
                  <a href={itOps}>{serviceDesk}</a> to consolidate your accounts.
                </span>
              ),
            },
          ],
        })
      }
    }

    return errors.length > 0 ? errors : null
  }

  getChangesShape = (): IProfileChangesShape => {
    const changes = { ...this.profileUpdateData }

    const entries = Object.entries(changes)
    for (const [key, value] of entries) {
      if (this.isPayloadValueInvalid(value)) {
        //If payload property doesn't have a value for an entry, delete
        delete changes[key]
        continue
      }

      const initialValue = this.initialProfileData[key]

      if (!initialValue) {
        //If payload has a value associated with a key and no value previously existed for that key, continue
        continue
      }

      if (simpleFields.includes(key) && _.isEqual(initialValue, value)) {
        //Payload value is equal to initial value, delete
        delete changes[key]
        continue
      }

      const complexField = complexFields[key]

      if (complexField) {
        const allEqual = complexField.comparisonFields.every(field =>
          _.isEqual(initialValue[field], value[field])
        )

        if (allEqual) {
          delete changes[key]
        }
      }
    }

    if (changes.parentOrganisation) {
      const initialValue = this.initialProfileData[profileUpdateFields.organisation]
      if (initialValue) {
        if (
          initialValue.code === OrganisationKeys.keyOldNHSI &&
          changes.parentOrganisation.code === OrganisationKeys.keyNHSEI
        ) {
          delete changes[profileUpdateFields.subOrganisation]
          delete changes[profileUpdateFields.parentOrganisation]
          delete changes[profileUpdateFields.organisation]
        }
      }
    }

    return changes
  }

  resetChanges = () => (this.profileUpdateData = _.clone(this.initialProfileData))

  initializeProfile = (data: ICurrentUser): void => {
    this.user = { ...data }

    const init: IProfileChangesShape = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      emailAddress: this.user.emailAddress,
      organisation: {
        code: this.user.primaryOrganisationCode,
        name: this.user.primaryOrganisationName,
      },
      region: {
        code: this.user.regionCode,
      },
    }

    this.initialProfileData = init
    this.profileUpdateData = {}
  }

  getUserExists = (emailAddress: string): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      get<boolean>(`${storeUrls.userExists}${emailAddress}`).then(res => resolve(res.data))
    })
  }

  getUser = (): Promise<AxiosResponse<ICurrentUser>> => {
    return new Promise<AxiosResponse<ICurrentUser>>((resolve, reject) => {
      get<ICurrentUser>(storeUrls.currentUser)
        .then(res => {
          this.initializeProfile(res.data)
          if (this.user) {
            this.isAdmin = this.user.isSystemAdministrator
            this.isUserAuthenticated = Object.keys(this.user).length > 0 && !this.user.emailVerificationToken
          }
          resolve(res)
        })
        .catch(err => {
          this.setErrors(err)
          reject(err)
        })
    })
  }

  searchForUser = (searchTerm: string): Promise<AxiosResponse<IUserSearchResponse[]>> => {
    return new Promise<AxiosResponse<IUserSearchResponse[]>>((resolve, reject) => {
      get<IUserSearchResponse[]>(`${storeUrls.searchUserByPartialTerm}${searchTerm}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updatePassword = (): Promise<AxiosResponse | { errors: Record<string, string> }> => {
    return new Promise<AxiosResponse | { errors: Record<string, string> }>((resolve, reject) => {
      post<
        { password: string; newPassword: string; newPasswordConfirm: string },
        AxiosResponse | { errors: Record<string, string> }
      >(storeUrls.updatePassword, {
        password: this.currentPassword,
        newPassword: this.updatePasswordData.password,
        newPasswordConfirm: this.updatePasswordData.confirmPassword,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          if (err.response && err.response.status === HttpStatusCode.UnprocessableEntity) {
            this.setErrors(err.response.data.errors);
            resolve({ errors: err.response.data.errors });
          } else {
            reject(err);
          }
        });
    });
  };

  userHasMhsAccess = (): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      get<boolean>(storeUrls.hasmhsaccess)
        .then(res => {
          resolve(res.data)
        })
        .catch(() => {
          reject(false)
        })
    })

  updateSecurity = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      postApi(storeUrls.updateRecovery, {
        password: this.currentPassword,
        recoveryQuestion: this.updateSecurityData.recoveryQuestion,
        recoveryAnswer: this.updateSecurityData.recoveryAnswer,
      })
        .then(() => resolve())
        .catch(err => {
          if (err.response.status === 422) {
            this.setErrors(err.response.data.errors)
          }
          reject()
        })
    })
  }

  postProfileUpdatePayload = (payload: IProfileChangesShape): Promise<IUserProfileUpdateResult> => {
    return new Promise<IUserProfileUpdateResult>((resolve, reject) => {
      post<IProfileChangesShape, AxiosResponse<IUserProfileUpdateResult>>(
        storeUrls.updateProfile,
        payload
      )
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          if (err.response && err.response.status >= HttpStatusCode.BadRequest) {
            this.setErrors(err.response.data.errors)
          }
          reject()
        })
    })
  }

  setErrors = (errors: Record<string, string>) => {
    this.error.removeAll()
    Object.keys(errors).forEach(k => {
      const val = errors[k]
      if (val) {
        const error = { key: k, value: [{ fieldError: val, summaryError: val }] }

        this.error.addOrUpdateWithPredicate(
          kvp => kvp.key === k,
          kvp => kvp.value.concat(error.value),
          error
        )
      }
    })
  }

  resetState = () => {
    this.error = new ErrorsCollection()
    this.updatePasswordData = {
      password: "",
      confirmPassword: "",
    }
    this.updateSecurityData = {
      recoveryQuestion: "",
      recoveryAnswer: "",
    }
    this.currentPassword = ""

    this.profileUpdateData = _.cloneDeep(this.initialProfileData)
  }

  getDisplayValue = (key: string, payload: IProfileChangesShape): string => {
    if (!key) {
      return "-"
    }

    if (simpleFields.indexOf(key) !== -1) {
      return payload[key]
    }

    for (const [entryKey, entryValue] of Object.entries(payload)) {
      if (entryKey === key) {
        if (!entryValue) {
          return "-"
        }
        if (
          entryKey === profileUpdateFields.organisation ||
          entryKey === profileUpdateFields.region
        ) {
          return entryValue.name
        } else if (entryKey === profileUpdateFields.jobRole) {
          return entryValue.title
        }
      }
    }

    return "-"
  }

  getDisplayName = (key: string): string => {
    const name = displayNames[key]
    return name ? name : "-"
  }
}

export default new ProfileStore()
