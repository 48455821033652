import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import AtoZLandingPage from "../../../aToZLandingPage"
import MobileLandingPage from "../../../mobileLandingPage"
import { FeatureToggle } from "../../enums"
import { useFeatureToggles, useStores } from "../../hooks"

const MobileRouter = observer(() => {
  const [userHasMhsAccess, setUserHasMhsAccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const { profileStore } = useStores()

  const {
    enabledToggles: [mobileLandingPageEnabled],
    loading: featureTogglesLoading,
  } = useFeatureToggles(FeatureToggle.MobileLandingPage)

  useEffect(() => {
    profileStore
      .userHasMhsAccess()
      .then(res => setUserHasMhsAccess(res))
      .catch(() => setUserHasMhsAccess(false))
      .finally(() => setLoading(false))
  }, [])

  if (loading || featureTogglesLoading) {
    return null
  }

  if (userHasMhsAccess && mobileLandingPageEnabled) {
    return <MobileLandingPage />
  }

  return <AtoZLandingPage />
})

export default MobileRouter
