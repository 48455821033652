import { BodyText, Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { MessageKey } from "../global/enums/messageKeys/enums"
import { useMessageKeys } from "../global/hooks"
import "./styles.scss"

const CookiesPolicy = () => {
  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  return (
    <Container className="cookies" id="cookies">
      <Col width="three-quarters">
        <Label isPageHeading>Cookies</Label>
        <Label size="m">How we use cookies to optimise your website experience</Label>
        <BodyText>
          We use cookies to ensure you get the best experience while using this website. By
          continuing without changing your settings, we will accept your consent to receive these
          cookies. However, if you wish to, you can change your browser settings to block or remove
          cookies at any time.
        </BodyText>
        <BodyText>
          Cookies are small information files that are placed on your device. When we provide
          services, we want to make them easy, useful and reliable. Cookies help us to do this. The
          cookies we use identify you only as a number and cannot be used to identify you
          personally.
        </BodyText>
        <BodyText>We use cookies to:</BodyText>
        <ul>
          <li className="cookies__list-item">
            measure how many people are using our website, so we can make it easier and faster to
            use.{" "}
          </li>
          <li className="cookies__list-item">
            analyse data, anonymously, to help us understand how people interact with our website.
          </li>
        </ul>
        <Label size="m">The cookies we use</Label>
        <BodyText>
          The list below is all the cookies that are used in this website. Please be aware that the
          providers listed below may change their cookie names without notice.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> AspNetCore.Cookies
          <br />
          <b>Purpose:</b> system cookies required for authentication and authorisation to the
          platform.
        </BodyText>
        <BodyText>
          <b>Cookie:</b> AspNetCore.Antiforgery &amp; CSRF-TOKEN
          <br />
          <b>Purpose:</b> system cookies used to prevent cross-site request forgery attacks on the
          application.
        </BodyText>
        <Label size="m">How to control and delete cookies</Label>
        <BodyText>
          If you wish to restrict or block the cookies which are set by our website, or any other
          website, you can do this through your browser settings. The ‘Help’ function within your
          browser should tell you how.
        </BodyText>
        <BodyText>
          Alternatively, you may wish to visit{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://www.aboutcookies.org/">
            About Cookies
          </a>
          , which contains comprehensive information on how to do this on a wide variety of
          browsers. You will also find details on how to delete cookies from your machine as well as
          more general information about cookies.
        </BodyText>
        <BodyText>
          If you do not wish for us to anonymously track your visit through Google Analytics, Google
          publishes a{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tools.google.com/dlpage/gaoptout"
          >
            browser add-on
          </a>{" "}
          that allows you to choose for this information to not be recorded.
        </BodyText>
        <BodyText>Visit our privacy page for more information on our wider data policies.</BodyText>
        <Label size="m">Contact us</Label>
        <BodyText>
          If you have any questions about our use of cookies or the practices of this website,
          please email us at{" "}
          <a data-testid="cookies__support-email" href={itOps}>
            {serviceDesk}
          </a>
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(CookiesPolicy)
