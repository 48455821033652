import React, { useState, memo } from "react"
import "./styles.scss"

interface IVerticalNavigationProps {
  onChange: (index: number) => void
  items: string[]
  selectedIndex: number
}

const VerticalNavigation = (props: IVerticalNavigationProps) => {
  const { onChange, items, selectedIndex } = props

  const onItemClicked = (index: number) => {
    if (index === selectedIndex) {
      return
    }

    onChange(index)
  }

  return (
    <div className="vertical-navigation__container">
      {items.map((item, idx) => {
        return (
          <div className="vertical-navigation__container__item" key={item}>
            <div className="vertical-navigation__container__item__separator" />
            <button
              disabled={selectedIndex === idx}
              className="vertical-navigation__container__item__display"
              onClick={() => onItemClicked(idx)}
            >
              {item}
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default memo(VerticalNavigation)
