import React from "react"
import "../styles.scss"

const ToolbarButton = props => {
  const { onClickFunction, children, text, disabled, reference } = props

  const onClickOccurred = () => {
    if (!disabled && onClickFunction) {
      onClickFunction()
    }
  }

  return (
    <div
      className={`toolbar-button${disabled ? "-disabled" : ""}`}
      onClick={onClickOccurred}
      ref={reference}
    >
      {children}
      <span className="toolbar-button-text">{text}</span>
    </div>
  )
}

export default ToolbarButton
