import { Button, Checkboxes, Fieldset } from "nhsuk-react-components"
import React, { useState } from "react"
import { CollapsableItems } from ".."
import { CloseIcon, IconPlus, MinusIcon } from "../../../global/components/icons"
import "./styles.scss"

interface IFiltersProps {
  onClose: () => void
  selectedFilters: Record<string, boolean>
  viewFilters: boolean
  selectToggle: (event: React.FormEvent<HTMLInputElement>) => void
  facets: Record<string, Record<string, number>>
  clearFilters: (filters: Record<string, boolean>) => void
}

const Filters = (props: IFiltersProps) => {
  const { onClose, facets, selectedFilters, clearFilters, selectToggle, viewFilters } = props

  const [typeFiltersOpen, setTypeFiltersOpen] = useState<boolean>(true)
  const [categoryFiltersOpen, setCategoryFiltersOpen] = useState<boolean>(true)

  const isChecked = (checkbox: string) => {
    let checked = false

    if (selectedFilters) {
      const matchingCheckbox = Object.keys(selectedFilters).find(sf => sf === checkbox)
      if (matchingCheckbox) {
        checked = selectedFilters[`${matchingCheckbox}`]
      }
    }

    return checked
  }

  const showFilters = (facetType: Record<string, number>, name: string) => {
    return Object.keys(facetType).map(facet => (
      <Checkboxes.Box
        key={`${facetType}-${facet}`}
        value={`${name} eq '${facet}'`}
        onChange={selectToggle}
        checked={isChecked(`${name} eq '${facet}'`)}
        disabled={facetType[facet] === 0}
      >
        {facet} <span className="search-results__facet-amount">({facetType[facet]})</span>
      </Checkboxes.Box>
    ))
  }

  const showTypes = (facetType: Record<string, number>, name: string) => {
    const newFacetOrder: Record<string, number> = {
      Dashboard: 0,
      "Opportunities for improvement": 0,
      Topics: 0,
      "Sub-topics": 0,
      Metrics: 0,
      ...facetType,
    }

    return Object.keys(newFacetOrder).map(facet => (
      <Checkboxes.Box
        key={`${newFacetOrder}-${facet}`}
        value={`${name} eq '${facet}'`}
        onChange={selectToggle}
        checked={isChecked(`${name} eq '${facet}'`)}
        disabled={newFacetOrder[facet] === 0}
      >
        {facet} <span className="search-results__facet-amount">({newFacetOrder[facet]})</span>
      </Checkboxes.Box>
    ))
  }

  return (
    <aside
      className={`search-results__filters${viewFilters ? " search-results__filters--open" : ""}`}
    >
      <p className="search-results__filters-title">Filters</p>
      <p className="search-results__title-close-container">
        <span>Filter results</span>
        <button className="search-results__close-filters" onClick={onClose}>
          <CloseIcon />
        </button>
      </p>
      {facets.Product && (
        <div className="search-results__checkboxes-wrapper">
          <Fieldset>
            <Fieldset.Legend className="search-results__product-legend">Product</Fieldset.Legend>
            <Checkboxes
              name="products"
              id="products"
              className="search-results__checkboxes search-results__checkboxes--product"
            >
              {showFilters(facets.Product, "Product")}
            </Checkboxes>
          </Fieldset>
        </div>
      )}
      {facets.Type && (
        <div className="search-results__checkboxes-wrapper">
          <button
            className={`search-results__fieldset-toggle${
              !typeFiltersOpen ? " search-results__fieldset-toggle--closed" : ""
            }`}
            onClick={() => setTypeFiltersOpen(!typeFiltersOpen)}
          >
            {typeFiltersOpen ? <MinusIcon /> : <IconPlus />}
            <span className="search-results__fieldset-toggle-legend">
              Type of search result ({Object.keys(facets.Type).length})
            </span>
          </button>
          <Fieldset
            className={`search-results__fieldset${
              !typeFiltersOpen ? " search-results__fieldset--hide" : ""
            }`}
          >
            <Fieldset.Legend className="search-results__fieldset-legend">
              Type of search result
            </Fieldset.Legend>
            <Checkboxes name="type" id="type" className="search-results__checkboxes">
              {showTypes(facets.Type, "Type")}
            </Checkboxes>
          </Fieldset>
        </div>
      )}
      {facets["Hierarchy/Lvl1"] && (
        <div className="search-results__checkboxes-wrapper search-results__checkboxes-wrapper--last">
          <button
            className="search-results__fieldset-toggle"
            onClick={() => setCategoryFiltersOpen(!categoryFiltersOpen)}
          >
            {categoryFiltersOpen ? <MinusIcon /> : <IconPlus />}
            <span className="search-results__fieldset-toggle-legend">
              Categories ({Object.keys(facets["Hierarchy/Lvl1"]).length})
            </span>
          </button>
          <Fieldset
            className={`search-results__fieldset${
              !categoryFiltersOpen ? " search-results__fieldset--hide" : ""
            }`}
          >
            <Fieldset.Legend className="search-results__fieldset-legend">
              Categories
            </Fieldset.Legend>
            <Checkboxes name="categories" id="categories" className="search-results__checkboxes">
              <CollapsableItems limit={5}>
                {showFilters(facets["Hierarchy/Lvl1"], "Hierarchy/Lvl1")}
              </CollapsableItems>
            </Checkboxes>
          </Fieldset>
        </div>
      )}
      <footer className="search-results__clear-apply">
        <Button
          onClick={() => {
            clearFilters(selectedFilters)
            onClose()
          }}
          secondary
        >
          Clear filters
        </Button>
        <Button onClick={onClose}>Apply filters</Button>
      </footer>
    </aside>
  )
}

export default Filters
