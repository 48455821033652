import React from "react"
import { observer } from "mobx-react"
import { useStores } from "../../../../global/hooks"
import { ChevronLeftIcon, PauseIcon, RedoIcon, RefreshIcon, RevertIcon } from "./assets"
import ToolbarButton from "./toolbarButton"
import { TableauViz } from "@tableau/embedding-api"

import "../styles.scss"

interface ILeftContainer {
  onButtonClicked: (
    id: number,
    tableauVisualization: TableauViz,
    visualizationLoading: boolean
  ) => void
}

const LeftContainer = observer(({ onButtonClicked }: ILeftContainer) => {
  const {
    tableauReportViewerStore: {
      toolbarButtons: { undo, redo, revert, refresh, pause },
      visualizationLoading,
      tableauVisualization,
    },
  } = useStores()

  return (
    <div id="left-container" className="tableau-toolbar__left-container">
      <ToolbarButton
        text={undo.text}
        disabled={visualizationLoading || !tableauVisualization}
        onClickFunction={() =>
          tableauVisualization &&
          onButtonClicked(undo.id, tableauVisualization, visualizationLoading)
        }
      >
        <ChevronLeftIcon />
      </ToolbarButton>
      <ToolbarButton
        text={redo.text}
        disabled={visualizationLoading || !tableauVisualization}
        onClickFunction={() =>
          tableauVisualization &&
          onButtonClicked(redo.id, tableauVisualization, visualizationLoading)
        }
      >
        <RedoIcon />
      </ToolbarButton>
      <ToolbarButton
        text={revert.text}
        disabled={visualizationLoading || !tableauVisualization}
        onClickFunction={() =>
          tableauVisualization &&
          onButtonClicked(revert.id, tableauVisualization, visualizationLoading)
        }
      >
        <RevertIcon />
      </ToolbarButton>
      <ToolbarButton
        text={refresh.text}
        disabled={visualizationLoading || !tableauVisualization}
        onClickFunction={() =>
          tableauVisualization &&
          onButtonClicked(refresh.id, tableauVisualization, visualizationLoading)
        }
      >
        <RefreshIcon />
      </ToolbarButton>
      <ToolbarButton
        text={pause.text}
        disabled={visualizationLoading}
        onClickFunction={() =>
          tableauVisualization &&
          onButtonClicked(pause.id, tableauVisualization, visualizationLoading)
        }
      >
        <PauseIcon />
      </ToolbarButton>
    </div>
  )
})

export default LeftContainer
