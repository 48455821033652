import { CollapsedView, ExpanderCell, OrganisationTable } from "./components"
import React, { useState } from "react"
import CheckBox from "../../../global/components/customCheckbox"
import ExpanderContent from "./components/expanderContent"

import "./styles.scss"

const ListItem = props => {
  const {
    index,
    expandedView,
    productView,
    user: {
      id,
      emailAddress,
      jobRole,
      firstName,
      lastName,
      region,
      odsCode,
      organisationName,
      isFreeTypedOrg,
      product,
      applicationMapping,
    } = {},
    date,
    time,
    onSelect,
    selected,
    view,
    toggleModal,
    mode,
  } = props

  const [expanded, setExpanded] = useState(false)

  const toggleSelect = e => {
    const { checked } = e.target
    onSelect(id, checked)
  }

  const immutableRegions = ["National", "Wales", "Scotland", "Channel Islands", "Northern Ireland"]

  const additionalLabel = immutableRegions.includes(region) ? "" : " region"

  const userRegion = !region ? "(Region not listed)" : `(${region}${additionalLabel})`

  const userODSCode = odsCode ? `${organisationName} (${odsCode})` : organisationName

  const canExpandToShowDecisionByAdmin =
    applicationMapping &&
    mode === "productOwnerAdmin" &&
    (view.status === "rejected" || view.status === "approved") &&
    applicationMapping.requestProcessResultReason?.length > 0

  const canExpandDueToStepTwoInformationAvailability =
    applicationMapping &&
    applicationMapping.stepTwoInformation &&
    applicationMapping.stepTwoInformation.length > 0

  const allowExpansion =
    canExpandDueToStepTwoInformationAvailability || canExpandToShowDecisionByAdmin

  return (
    <div className="list-item">
      <div className="list-item__header-container">
        <dd className="list-item__header-item list-item__header-item--checkbox">
          <CheckBox
            name={`select_${id}`}
            id={`${index}-${view.status}-checkbox`}
            value={id}
            checked={selected}
            onChange={e => toggleSelect(e)}
          />
        </dd>
        <dd className="list-item__header-item">
          <span className="list-item__user-name">
            {firstName} {lastName}
          </span>
          {!expanded && <CollapsedView jobRole={jobRole} emailAddress={emailAddress} />}
        </dd>
        <dd className="list-item__header-item list-item__header-item--date-time">
          {date} | {time}
        </dd>
        <dd className="list-item__header-item">
          <OrganisationTable
            userODSCode={userODSCode}
            userRegion={userRegion}
            isFreeTypedOrg={isFreeTypedOrg}
          />
        </dd>
        <dd className="list-item__header-item list-item__header-item--expander">
          <ExpanderCell
            expandedView={expandedView}
            allowExpansion={allowExpansion}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </dd>
      </div>
      {expandedView && expanded && (
        <ExpanderContent
          canExpandDueToStepTwoInformationAvailability={
            canExpandDueToStepTwoInformationAvailability
          }
          canExpandToShowDecisionByAdmin={canExpandToShowDecisionByAdmin}
          view={view}
          emailAddress={emailAddress}
          jobRole={jobRole}
          applicationMapping={applicationMapping}
          product={product}
          productView={productView}
          toggleModal={toggleModal}
        />
      )}
    </div>
  )
}

export default ListItem
