import { observer } from "mobx-react"
import moment from "moment"
import { BodyText, Label } from "nhsuk-react-components"
import React from "react"
import { MessageKey } from "../../../global/enums/messageKeys/enums"
import { useMessageKeys, useStores } from "../../../global/hooks"

const InvitationErrorsView = observer(() => {
  const {
    invitationStore: store,
    productOwnerAdminStore: { myProducts: productList },
  } = useStores()

  const [itOps] = useMessageKeys(MessageKey.SupportEmailAddressLink)

  const currentDateTime = moment().format("DD/MM/YYYY HH:mm:ss")

  return (
    <>
      <Label isPageHeading>Sorry, there is a problem with the service</Label>

      <BodyText>
        {"The following users could not be invited to "}
        {store.invitation.products &&
          store.invitation.products.map((productId, i) => {
            const product = productList.find(x => x.id === productId)
            return (
              <span key={(product && product.id) || i} className="bold">
                {(product && product.name) || ""}
                {i + 1 === store.invitation.products.length ? "" : ", "}
              </span>
            )
          })}
        :
      </BodyText>

      <ul>
        {store.invalidEmails && store.invalidEmails.length > 0
          ? store.invalidEmails.map((email, idx) => {
              return (
                <li key={idx} className="bold">
                  {email}
                  {idx + 1 === store.invalidEmails.length ? "" : ", "}
                </li>
              )
            })
          : store.invitation.recipientEmails.map((email, idx) => {
              return <li key={idx}>{email}</li>
            })}
      </ul>

      <BodyText>This may be because:</BodyText>

      <ul>
        <li>
          The user's organisation is not on the list of approved organisation for this product
        </li>
        <li>The user's account does not have an organisation</li>
      </ul>

      <BodyText>
        Please address the potential issues above. If this problem persists, please contact the{" "}
        <a href={itOps}>support desk</a> and quote <strong>NHS England applications</strong> and the
        following date and time <strong>{currentDateTime}</strong>.
      </BodyText>
    </>
  )
})

export default InvitationErrorsView
