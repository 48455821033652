import React from "react"

const NoFavourites = () => (
  <div className="az-page__products-list__card__container" style={{ minHeight: "62.5rem" }}>
    <p className="nhsuk-hint">It looks like you haven’t saved any favourites yet. </p>
    <p className="nhsuk-hint">
      Click the star on any application and you will find it here next time.
    </p>
  </div>
)

export default NoFavourites
