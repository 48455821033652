import React from "react"
import { Navigate } from "react-router-dom"
import { useStores } from "../../../global/hooks"
import { ProductEditView } from "../../enums/enums"

interface IProductViewRedirectProps {
  view: ProductEditView
}

export const ProductViewRedirect = (props: IProductViewRedirectProps) => {
  const { view } = props

  const { productStore } = useStores()

  if (!productStore.product || !productStore.product.id) {
    throw new Error("Product Id not set")
  }

  const tasksPath = `/product/${productStore.product.id}/edit`

  const root = document.getElementById("root")
  if (root) {
    root.scrollTop = 0
  }

  let path = tasksPath
  if (view !== ProductEditView.Tasks) {
    path += `/${view}`
  }

  return <Navigate to={path} />
}

export default ProductViewRedirect
