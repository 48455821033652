import Collection from "../../abstract/collection"

class GenericCollection<T> extends Collection<T> {
  constructor(...collection: T[]) {
    super()

    if (collection.length > 0) {
      this.addMany(collection)
    }
  }
}

export default GenericCollection
