import { useEffect, useMemo, useState } from "react"
import { useStores } from "."
import { FeatureToggle } from "../enums"
import { HttpStatusCode } from "../enums/api"
import { IJSendResponse } from "../interfaces/api/interfaces"
import { IFeatureToggle } from "../interfaces/featureToggle"
import { get } from "../utils/api"

const useFeatureToggles = (
  ...toggles: FeatureToggle[]
): { enabledToggles: boolean[]; loading: boolean } => {
  const { profileStore } = useStores()

  const [featureToggles, setFeatureToggles] = useState<Map<FeatureToggle, boolean>>(
    new Map<FeatureToggle, boolean>()
  )

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    let mounted = true

    function loadFeatureToggles() {
      setLoading(true)

      get<IJSendResponse<IFeatureToggle[]>>("/featuretoggle/")
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            const map = new Map<FeatureToggle, boolean>(
              res.data.data.map(({ id, enabledForAll }) => [id, enabledForAll])
            )
            if (mounted) {
              setFeatureToggles(map)
            }
          }
        })
        .catch(console.error)
        .finally(() => {
          if (mounted) {
            setLoading(false)
          }
        })
    }

    loadFeatureToggles()

    return () => {
      mounted = false
    }
  }, [])

  const enabledFeatureToggles = useMemo(() => {
    return toggles.map(toggle => {
      if (featureToggles.get(toggle)) {
        return true
      }

      if (profileStore.user) {
        return profileStore.user.featureToggleMappings.some(ftm => ftm.featureToggleId === toggle)
      }

      return false
    })
  }, [toggles, featureToggles, profileStore.user])

  return { enabledToggles: enabledFeatureToggles, loading: loading }
}

export default useFeatureToggles
