import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { ReactComponent as FavouritedIcon } from "../../../admin/assets/favourited.svg"
import InfoIcon from "../../../admin/assets/info.svg"
import { ReactComponent as UnFavouritedIcon } from "../../../admin/assets/unfavourited.svg"
import { LoaderContext } from "../../../global/components/loaderProvider"
import Modal from "../../../global/components/modal"
import ApprovedAccess from "../accessStatusCards/approvedAccess/ApprovedAccess"
import DeniedAccess from "../accessStatusCards/deniedAccess/DeniedAccess"
import RequestAccess from "../accessStatusCards/requestAccess/RequestAccess"
import RequestPending from "../accessStatusCards/requestPending/RequestPending"
import AtoZModalContent from "../atoZModalContent"
import Breadcrumbs from "../breadcrumbs/Breadcrumbs"
import ProductCardInfo from "../productCardInfo/productCardInfo"
import Text from "../text/text"
import "./ProductCard.scss"

const ProductCard = props => {
  const { data, toggleApplicationFavourite, productParam, selectedCardRef } = props

  const {
    id,
    accessGrantedDate,
    appUrl,
    coreProductTopic,
    groupingName,
    userApplicationMappingState,
    title,
    description,
    tags,
    userSupportEmail,
    stepTwoInformationRequired,
    embedPathName,
    isFavourited,
  } = data

  const checkProductAccessStatus = status => {
    switch (status) {
      case 0:
        return "approved"
      case 1:
        return "pending"
      case 2:
        return "not requested"
      case 3:
        return "rejected"
      case 4:
        return "removed"
      default:
        return "not requested"
    }
  }

  const breadCrumbsList = [groupingName, coreProductTopic]
  const [showModal, setShowModal] = useState(false)
  const [wasFavourited, setWasFavourited] = useState(isFavourited)
  const [accessStatus, setAccessStatus] = useState(
    checkProductAccessStatus(userApplicationMappingState)
  )
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const productCardRef = useRef(null)

  useEffect(() => {
    if (embedPathName !== null && embedPathName === productParam) {
      selectedCardRef.current = productCardRef.current
    }
  }, [])

  useEffect(() => {
    setAccessStatus(checkProductAccessStatus(userApplicationMappingState))
  }, [userApplicationMappingState])

  const handleFavouritingClick = async id => {
    await wrapWithLoader(async () => {
      await toggleApplicationFavourite(id)
      setWasFavourited(!wasFavourited)
    })
  }

  const handleProductInfoClick = async () => {
    setShowModal(true)
  }

  const closeModal = () => setShowModal(false)

  return (
    <>
      {showModal && (
        <Modal close={closeModal}>
          <AtoZModalContent
            title={title}
            description={description}
            tags={tags}
            id={id}
            supportEmail={userSupportEmail}
            closeModal={closeModal}
          />
        </Modal>
      )}
      <div className="az-page__product-card__container" ref={productCardRef}>
        <div className="az-page__product-card__text">
          {accessStatus === "approved" ? (
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <Text textType="title-blue">{title}</Text>
            </a>
          ) : (
            <Text textType="title-black">{title}</Text>
          )}
          {breadCrumbsList.length > 0 && <Breadcrumbs list={breadCrumbsList} />}
        </div>
        <div className="az-page__product-card__info">
          <div className="az-page__product-card__access-status">
            {accessStatus === "approved" ? (
              <ApprovedAccess date={accessGrantedDate} />
            ) : accessStatus === "pending" ? (
              <RequestPending date={accessGrantedDate} />
            ) : accessStatus === "not requested" ? (
              <RequestAccess
                applicationID={id}
                stepTwoRequired={stepTwoInformationRequired}
                date={accessGrantedDate}
              />
            ) : accessStatus === "rejected" ? (
              <DeniedAccess date={accessGrantedDate} />
            ) : accessStatus === "removed" ? (
              <RequestAccess
                applicationID={id}
                stepTwoRequired={stepTwoInformationRequired}
                date={accessGrantedDate}
              />
            ) : (
              <RequestAccess
                applicationID={id}
                stepTwoRequired={stepTwoInformationRequired}
                date={accessGrantedDate}
              />
            )}
          </div>

          {accessStatus === "approved" && (
            <ProductCardInfo text="Favourite" onClick={() => handleFavouritingClick(id)}>
              {wasFavourited === true ? <FavouritedIcon /> : <UnFavouritedIcon />}
            </ProductCardInfo>
          )}

          <ProductCardInfo text="Info" onClick={handleProductInfoClick}>
            <img src={InfoIcon} />
          </ProductCardInfo>
        </div>
      </div>
    </>
  )
}

ProductCard.propTypes = {
  data: PropTypes.object.isRequired,
  linkedProductReference: PropTypes.string,
  toggleApplicationFavourite: PropTypes.func.isRequired,
}

export default ProductCard
