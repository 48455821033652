import { Col, Container, Label } from "nhsuk-react-components"
import React, { ReactNode } from "react"
import { UserProfileUpdateScreen } from "../../../global/enums/profile/enums"
import "./styles.scss"

interface IProfileUpdateResultPaneProps {
  screen: UserProfileUpdateScreen
  children: ReactNode
}

const ProfileUpdateResultPane = (props: IProfileUpdateResultPaneProps) => {
  const { screen, children } = props

  const HeaderLabel = () => {
    let headerText = ""

    switch (screen) {
      case UserProfileUpdateScreen.ManualApprovalRequired:
        headerText = "Your account has been deactivated whilst we approve your changes"
        break
      case UserProfileUpdateScreen.DetailsSuccessfullyChanged:
        headerText = "Your details have been successfully changed"
        break
      case UserProfileUpdateScreen.VerifyNewEmaiLAddress:
        headerText = "Verify your new email address"
        break
      case UserProfileUpdateScreen.RequestsSent:
        headerText = "Request sent"
        break
    }

    return (
      <Label isPageHeading className="profile-update-result-view__header-label">
        {headerText}
      </Label>
    )
  }

  return (
    <div className="profile-update-result-view">
      <Container>
        <Col width="three-quarters">
          <HeaderLabel />
          {children}
        </Col>
      </Container>
    </div>
  )
}

export default ProfileUpdateResultPane
