import { IDistinctCollection } from "../../interfaces/dataTypes/interfaces"

class DistinctCollection<T> implements IDistinctCollection<T> {
  values: T[]

  constructor() {
    this.values = []
  }

  add = (value: T): boolean => {
    if (this.values.indexOf(value) === -1) {
      this.values.push(value)
      return true
    }
    return false
  }

  remove = (value: T): boolean => {
    const idx = this.values.indexOf(value)
    if (idx !== -1) {
      this.values.splice(idx, 1)
      return true
    }
    return false
  }

  get length(): number {
    return this.values.length
  }
}

export default DistinctCollection
