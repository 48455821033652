import _ from "lodash"
import { makeAutoObservable } from "mobx"
import { IFluentValidationErrorResponse } from "../../../global/interfaces/api/interfaces"
import { ICategory } from "../../../global/interfaces/category/interfaces"
import { IProductInfo } from "../../../global/interfaces/productInfo/interfaces"
import { get, post } from "../../../global/utils/api"
import { IProductInfoData } from "./interfaces"
import { HttpStatusCode } from "../../../global/enums/api"
import ProductInfoValidator from "./productInfoValidator"
import { ProductInfoErrorCode } from "./enums"

const productInfoData: IProductInfoData = {
  errors: {},
  productInformation: {
    applicationId: "",
    categories: [],
    complete: false,
    description: "",
    friendlyName: "",
    id: null,
    url: "",
  },
}

export interface IProductInfoStore {
  productInformationData: IProductInfoData
  categories: ICategory[]
  getProductInformationData: (productId: string) => Promise<void[]>
  resetState: () => void
  getProductInformation: (productId: string) => Promise<void>
  getCategories: () => Promise<void>
  saveProductInformation: (productId: string) => Promise<void>
}

const productInfoValidator = new ProductInfoValidator()

const errorCodeFieldNameMappings: Record<string, string> = {
  [ProductInfoErrorCode.FriendlyName]: "FriendlyName",
}

export class ProductInfoStore implements IProductInfoStore {
  productInformationData = _.cloneDeep(productInfoData)
  categories: ICategory[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getProductInformationData = (productId: string): Promise<void[]> => {
    const promises = [this.getProductInformation(productId), this.getCategories()]
    return Promise.all(promises)
  }

  resetState = (): void => {
    this.productInformationData = _.cloneDeep(productInfoData)
  }

  getProductInformation = (productId: string): Promise<void> => {
    this.resetState()
    return new Promise(resolve => {
      get<IProductInfo>(`/productinfo/application/${productId}`)
        .then(res => {
          if (res.status === 200) {
            this.productInformationData.productInformation = { ...res.data }
          }
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          if (this.productInformationData.productInformation.categories.length === 0) {
            this.productInformationData.productInformation.categories.push({
              id: "",
              name: "",
            })
          }
          resolve()
        })
    })
  }

  getCategories = (): Promise<void> => {
    return new Promise(resolve => {
      get<ICategory[]>("/category")
        .then(res => {
          this.categories = [...res.data]
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          resolve()
        })
    })
  }

  saveProductInformation = (productId: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const productInfoPayload: IProductInfo = this.productInformationData.productInformation
      productInfoPayload.categories = [...productInfoPayload.categories.filter(c => c.id)]
      productInfoPayload.applicationId = productId

      const validationResults = productInfoValidator.run(productInfoPayload).filter(x => !x.success)

      if (validationResults.length > 0) {
        const errors: Record<string, string> = {}
        for (const validationResult of validationResults) {
          if (
            (!validationResult.code && validationResult.code !== 0) ||
            !validationResult.message ||
            validationResult.success
          ) {
            continue
          }

          const fieldName = errorCodeFieldNameMappings[validationResult.code]
          if (!fieldName) {
            continue
          }

          errors[fieldName] = validationResult.message
        }

        this.productInformationData.errors = errors
        reject(new Error("Validation failure"))
      }

      post<IProductInfo>(`/productinfo/`, productInfoPayload)
        .then(() => resolve())
        .catch((res: IFluentValidationErrorResponse) => {
          productInfoPayload.complete = false
          if (productInfoPayload.categories.length === 0)
            productInfoPayload.categories.push({ id: "", name: "" })
          if (res.response.status >= HttpStatusCode.BadRequest) {
            this.productInformationData.errors = res.response.data.errors
          }
          reject()
        })
    })
  }
}

export default new ProductInfoStore()
