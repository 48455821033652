import React from "react"
import { Link } from "react-router-dom"

interface IFooterInternalLinkProps {
  href: string
  children: React.ReactNode
}

const FooterInternalLink = (props: IFooterInternalLinkProps) => {
  const { href, children } = props

  return (
    <li className="nhsuk-footer__list-item">
      <Link to={href} className="nhsuk-footer__list-item-link">
        {children}
      </Link>
    </li>
  )
}

export default FooterInternalLink
