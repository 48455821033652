import React from "react"
import FixedLengthSpan from "../../../global/components/fixedLengthSpan"
import { DownloadNotificationMessage } from "./DownloadNotificationMessage"
import { NotificationStatus } from "../../../global/enums/notification"

interface INotificationMessage {
  setIsSuccessfulNotification: React.Dispatch<React.SetStateAction<boolean>>
  isDownloadNotification: boolean
  messageClassName: string
  messageSuffix: string
  message: string
  open: boolean
  read: boolean
  status: NotificationStatus
}

export const NotificationMessage = ({
  setIsSuccessfulNotification,
  isDownloadNotification,
  messageClassName,
  messageSuffix,
  message,
  open,
  read,
  status,
}: INotificationMessage): JSX.Element => {
  if (!isDownloadNotification) {
    return (
      <FixedLengthSpan
        className={messageClassName}
        maxLength={190}
        suffix={messageSuffix}
        text={message}
        derestrict={open}
      />
    )
  }

  return (
    <DownloadNotificationMessage
      setIsSuccessfulNotification={setIsSuccessfulNotification}
      message={message}
      read={read}
      maxLength={999}
      messageSplitCharacter="#"
      open={open}
      suffix={messageSuffix}
      className={messageClassName}
      status={status}
    />
  )
}
