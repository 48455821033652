import React from "react"
import Select, { ActionMeta, MultiValue, GroupBase } from "react-select"
import { ISelectOption } from "../../global/interfaces/select"
import "../styles.scss"
import Application from "../types/classes/application"
import { ACTION } from "../types/enums"
import _Select from "react-select/dist/declarations/src/Select"

type SelectForwardedRef = _Select<
  { label: string; value: string },
  true,
  GroupBase<{ label: string; value: string }>
>

interface IRegionSelectProps {
  setCurrentApplication: React.Dispatch<React.SetStateAction<Application>>
  application: Application
  isRegional: boolean
  regionOptions: ISelectOption[]
  handleApplicationChange: (application: Application, action: ACTION) => void
}

export const RegionalSelect = React.forwardRef<SelectForwardedRef, IRegionSelectProps>(
  (props: IRegionSelectProps, ref: React.ForwardedRef<SelectForwardedRef>) => {
    const {
      setCurrentApplication,
      application,
      isRegional,
      regionOptions,
      handleApplicationChange,
    } = props

    const customStyles = {
      IndicatorSeparator: () => null,
    }

    const defaultOptions = application.regions
      .map(region => {
        const currentRegion = regionOptions.find(
          r => r.label.toLowerCase() === region.toLowerCase()
        )
        return currentRegion
          ? { label: currentRegion.label, value: currentRegion.value }
          : { label: "", value: "" }
      })
      .filter(r => r.label !== "")

    const onSelectionChanged = (
      _: MultiValue<ISelectOption>,
      actionMeta: ActionMeta<ISelectOption>
    ) => {
      switch (actionMeta.action) {
        case "select-option":
          // eslint-disable-next-line no-case-declarations
          const value = actionMeta.option?.value
          if (value) {
            application.addRegion(value)
          }
          break
        case "deselect-option":
        case "remove-value":
        case "pop-value":
          // eslint-disable-next-line no-case-declarations
          if (actionMeta.removedValue) {
            application.removeRegion(actionMeta.removedValue.value)
          }
          break
        case "clear":
          application.removeAllRegions()
          break
      }

      setCurrentApplication({ ...application } as Application)
      handleApplicationChange(application, ACTION.MODIFIED)
    }

    return (
      <Select
        ref={ref}
        className="adminmanagement__multiselect__regions"
        isDisabled={!isRegional}
        id="select_regions"
        classNamePrefix="adminmanagement-multiselect"
        placeholder={isRegional ? "Select Regions" : "Not Available"}
        isClearable
        components={{ ...customStyles, DropdownIndicator: () => null }}
        options={regionOptions}
        defaultValue={defaultOptions}
        onChange={onSelectionChanged}
        isMulti
        isSearchable
      />
    )
  }
)
