class Guid {
  private static _guidRegex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
  static EmptyString = "00000000-0000-0000-0000-000000000000"
  private value: string = Guid.EmptyString

  static isValid = (value: string | null) => {
    if (!value) {
      return false
    }

    return Guid._guidRegex.test(value)
  }

  constructor(value: string | null) {
    if (!value || !Guid.isValid(value)) {
      throw new Error("Invalid Guid value")
    }
    this.value = value
  }

  get toString(): string {
    return this.value
  }
}

export default Guid
