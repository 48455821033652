import React from "react"
import { IRegion } from "../../global/interfaces/region"
import Application from "../types/classes/application"
import { ACTION, ApplicationState } from "../types/enums"
import { SingleAppConfiguration } from "./singleAppConfiguration"
import "../styles.scss"

export const AppsConfiguration = ({
  applications,
  nhsRegions,
  handleApplicationChange,
}: {
  applications: Application[]
  nhsRegions: IRegion[]
  handleApplicationChange: (application: Application, action: ACTION) => void
}) => {
  return (
    <>
      {applications.length > 0 &&
        applications
          .filter(app => app.applicationState !== ApplicationState.Deleted)
          .map((application, index) => (
            <SingleAppConfiguration
              key={`${application.id}-${index}`}
              application={application}
              nhsRegions={nhsRegions}
              handleApplicationChange={handleApplicationChange}
            />
          ))}
    </>
  )
}
