import _ from "lodash"
import { makeAutoObservable, toJS } from "mobx"
import { getApi, postApi } from "../../../global/utils/api"
import { IApprovalQuestions, IApprovalQuestionsStore } from "./interfaces"
import { ApplicationType } from "../../../global/enums/application"

const approvalQuestions: IApprovalQuestions = {
  errors: {},
  approvalQuestionInformation: {
    id: null,
    applicationId: "",
    additionalInformationRequired: false,
    selectedQuestions: [],
    complete: false,
    productSpecificTerms: false,
    productSpecificTermsLink: "",
    allowMultipleRegions: false,
  },
}

export type { IApprovalQuestionsStore }

export class ApprovalQuestionsStore implements IApprovalQuestionsStore {
  approvalQuestionsData = _.cloneDeep(approvalQuestions)
  questionBank = [""]
  errors = {}

  constructor() {
    makeAutoObservable(this)
  }

  getApprovalQuestionInformationData = (productId: string, appType: number) => {
    const promises = [
      this.getApprovalQuestionInformation(productId),
      appType && appType === ApplicationType.Foundry
        ? this.getFoundryApprovalQuestions(productId)
        : this.getApprovalQuestions(productId),
    ]
    return Promise.all(promises)
  }

  getApprovalQuestionInformation = (productId: string) => {
    this.resetState()
    return new Promise<void>(resolve => {
      getApi(`/approvalquestion?applicationId=${productId}`)
        .then(res => {
          if (res.status === 200) {
            this.approvalQuestionsData.approvalQuestionInformation = { ...res.data }
          }
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          resolve()
        })
    })
  }

  resetState = () => {
    this.approvalQuestionsData = _.cloneDeep(approvalQuestions)
  }

  getApprovalQuestions = (productId: string) => {
    return new Promise<void>(resolve => {
      getApi(`/approvalquestion/list/${productId}`)
        .then(res => {
          this.questionBank = [...res.data]
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          resolve()
        })
    })
  }

  getFoundryApprovalQuestions = (productId: string) => {
    return new Promise<void>(resolve => {
      getApi(`/approvalquestion/foundry/${productId}`)
        .then(res => {
          this.questionBank = [...res.data]
        })
        .catch(() => {
          debugger
        })
        .then(() => {
          resolve()
        })
    })
  }

  saveApprovalQuestions = (productId: string) => {
    this.approvalQuestionsData.approvalQuestionInformation.applicationId = productId
    const payload = toJS(this.approvalQuestionsData.approvalQuestionInformation)

    return new Promise<void>(resolve => {
      postApi(`/approvalquestion`, payload)
        .then(res => {
          debugger
          if (res.status === 422) {
            this.errors = res.data
          }
        })
        .catch(() => {
          this.approvalQuestionsData.approvalQuestionInformation.complete = false
          debugger
        })
      resolve()
    })
  }
}

export default new ApprovalQuestionsStore()
