import React from "react"

export const Chevron = props => {
  const { invert } = props

  return (
    <svg
      className="mock-select__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      transform={invert ? "rotate(180)" : ""}
      transformorigin="center"
    >
      <path
        fill="#212B32"
        stroke="#000"
        strokeWidth=".5"
        d="M5.997 8.253c-.2.001-.391-.077-.533-.217l-3.75-3.75c-.19-.19-.264-.468-.194-.728.07-.26.272-.463.532-.532.26-.07.537.004.727.195l3.218 3.225L9.214 3.22c.295-.295.771-.295 1.065 0 .294.294.294.77 0 1.065l-3.75 3.75c-.141.14-.333.218-.532.217z"
      />
    </svg>
  )
}
