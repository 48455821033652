import React, { useState } from "react"
import { Label, BodyText } from "nhsuk-react-components"
import moment from "moment"
import ListItem from "../listItem/index"
import CheckBox from "../../../global/components/customCheckbox"
import ApprovalControls from "../approvalControls"
import Modal from "../../../global/components/modal"
import ModalContent from "../modalContent"
import PaginationControl from "../../../global/components/paginationControl"
import NoResult from "../noResult"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { observer } from "mobx-react"

import "./styles.scss"
import { useStores } from "../../../global/hooks"

const ListContainer = props => {
  const {
    store: {
      mainList: list,
      seenUsers: users,
      approveList: approve,
      rejectList: reject,
      deprovisionList: deprovision,
      reactivateList: reactivate,
      filteredList: filterTotal,
      myProducts,
      selectedProductId,
      pages,
      selectedAll,
      setSelectedAll,
      toggleSelectAll,
      selectedItems,
      setSelected,
    },
    listTotal,
    mode,
    view,
    paginationControls,
    noResult,
    application,
  } = props

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("approve")
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [requestProccessResultReason, setRequestProcessResultReason] = useState("")
  const [requestProccesseErrorMessage, setRequestProccessingErrorMessage] = useState("")
  const askAdminForProcessResultReason = true
  const { profileStore } = useStores()

  const toggleSelect = (id, checked) => {
    let newArray = []
    if (selectedItems.some(x => x === id)) {
      if (!checked) {
        let temp = selectedItems.slice()
        temp.splice(selectedItems.indexOf(id), 1)
        newArray = [...temp]
      } else {
        let temp = selectedItems.slice()
        temp.push(id)
        newArray = [...temp]
      }
    } else {
      newArray = [...selectedItems, id]
    }
    newArray.sort((a, b) => a - b)
    setSelected([...newArray])

    listTotal !== 0 && newArray.length < listTotal ? setSelectedAll(false) : setSelectedAll(true)
  }

  const isProductOwnerView = mode === "productOwnerAdmin"

  const applyRequestProcessReason = adminSuppliedReason => {
    setRequestProcessResultReason(adminSuppliedReason)
  }

  const toggleModal = (show, type) => {
    setShowModal(show)
    if (show) setModalType(type)
  }

  const handleApprovalMessageVerification = providedRequestResultReason => {
    if (modalType === "approve" && !providedRequestResultReason) {
      setRequestProccessingErrorMessage("Please provide reason for approving the user(s)")
    } else if (modalType === "reactivate" && !providedRequestResultReason) {
      setRequestProccessingErrorMessage("Please provide reason for reactivating the user(s)")
    } else {
      if (view.status === "approved" && !providedRequestResultReason) {
        setRequestProccessingErrorMessage("Please provide reason for deprovisioning the user(s)")
      } else {
        !providedRequestResultReason &&
          setRequestProccessingErrorMessage("Please provide reason for rejecting the request(s)")
      }
    }
    setShowModal(true)
  }

  const handleRequestProcessing = async awaitableFunc => {
    const providedRequestResultReason =
      !isProductOwnerView ||
      !askAdminForProcessResultReason ||
      (askAdminForProcessResultReason && requestProccessResultReason.length > 5)

    providedRequestResultReason && (await awaitableFunc())

    providedRequestResultReason && resetControls()

    !providedRequestResultReason && handleApprovalMessageVerification()
  }

  const resetControls = () => {
    setRequestProcessResultReason("")
    setSelectedAll(false)
    setSelected([])
    setShowModal(false)
  }

  const handleModalAction = async () => {
    await wrapWithLoader(async () => {
      if (modalType === "approve") {
        await handleRequestProcessing(async () => {
          return await approve(
            selectedItems,
            `${requestProccessResultReason} approved by: ${profileStore.user.emailAddress}`
          )
        })
      } else if (modalType === "reactivate") {
        await handleRequestProcessing(async () => {
          return await reactivate(
            selectedItems,
            `${requestProccessResultReason} reactivated by: ${profileStore.user.emailAddress}`
          )
        })
      } else {
        if (view.status === "approved") {
          await handleRequestProcessing(async () => {
            return await deprovision(
              selectedItems,
              `${requestProccessResultReason} deprovisioned by: ${profileStore.user.emailAddress}`
            )
          })
        } else {
          await handleRequestProcessing(async () => {
            return await reject(
              selectedItems,
              `${requestProccessResultReason} rejected by: ${profileStore.user.emailAddress}`
            )
          })
        }
      }
    })
  }

  const getTimeDescription = () => {
    switch (view.status) {
      case "approved":
        return "approval"
      case "pending":
        return "request"
      default:
        return "request"
    }
  }

  return (
    <div className="list-container">
      {showModal && (
        <Modal close={() => toggleModal(false)}>
          <ModalContent
            close={() => toggleModal(false)}
            application={
              isProductOwnerView
                ? Object.assign(
                    {},
                    myProducts.find(p => p.id === selectedProductId)
                  )
                : {}
            }
            type={modalType || "approve"}
            people={selectedItems
              .map(guid => {
                if (!users.has(guid)) return null
                const user = users.get(guid)
                return {
                  name: `${user.firstName} ${user.lastName}`,
                  organisation: user.organisationName,
                }
              })
              .filter(el => el !== null)}
            onSubmit={handleModalAction}
            askForReason={askAdminForProcessResultReason}
            requestProcessErrorMessage={requestProccesseErrorMessage}
            requestProccessResultReason={requestProccessResultReason}
            applyRequestProcessReason={applyRequestProcessReason}
            shouldRequestProcessingResultReason={isProductOwnerView}
          />
        </Modal>
      )}

      <Label bold style={{ fontSize: "2rem", marginBottom: "0" }}>
        {view.name}
      </Label>
      <br />
      <BodyText style={{ whiteSpace: "pre-line" }}>{view.description}</BodyText>
      <div className="list-container__approval-wrapper">
        <div className="list-container__approval-pagecount">
          {list.length ? (
            <BodyText style={{ marginTop: "0" }}>
              {`
                                Showing ${(pages.current - 1) * 15 + 1} -
                                ${
                                  15 * pages.current > filterTotal
                                    ? filterTotal
                                    : 15 * pages.current
                                }
                                of ${filterTotal}
                            `}
            </BodyText>
          ) : (
            <BodyText style={{ marginTop: "0" }}>Showing 0</BodyText>
          )}
        </div>
        <ApprovalControls
          showLabel
          index="0"
          selectedItems={selectedItems}
          toggleModal={toggleModal}
          view={view}
          mode={mode}
          selectedProduct={application}
        />
      </div>
      <div className="list-container__header">
        <dt className="list-container__header-item list-container__header-item--checkbox">
          {listTotal && (
            <CheckBox id="select-all" checked={selectedAll} onChange={toggleSelectAll} />
          )}
        </dt>
        <dt className="list-container__header-item">User details</dt>
        <dt className="list-container__header-item">Date | Time of {getTimeDescription()}</dt>
        <dt className="list-container__header-item">Organisation</dt>
      </div>

      {list.map((x, idx) => {
        let registerDate = x.created

        if (selectedProductId) {
          registerDate = x.applications.find(x => x.applicationId === selectedProductId).date
        }

        const date = registerDate ? moment(registerDate).format("DD MMM YYYY") : ""
        const time = registerDate
          ? moment(registerDate).format("mm") === "00"
            ? moment(registerDate).format("ha")
            : moment(registerDate).format("h.mma")
          : ""
        return (
          <ListItem
            key={x.id}
            index={idx}
            user={x}
            productView={window.location.href.includes("/admin")}
            expandedView={mode === "productOwnerAdmin" || mode === "systemAdmin"}
            onSelect={toggleSelect}
            selected={selectedItems.includes(x.id)}
            date={date}
            time={time}
            view={view}
            approve={approve}
            reject={reject}
            deprovision={deprovision}
            reactivate={reactivate}
            toggleModal={toggleModal}
            mode={mode}
          />
        )
      })}

      {(noResult || list.length === 0) && <NoResult />}

      <PaginationControl
        current={pages.current}
        hasPreviousPage={pages.hasPreviousPage}
        hasNextPage={pages.hasNextPage}
        total={pages.total}
        handlePageNavigation={paginationControls}
      />
      <ApprovalControls
        showLabel
        index="1"
        selectedItems={selectedItems}
        toggleModal={toggleModal}
        view={view}
        mode={mode}
        selectedProduct={application}
      />
    </div>
  )
}

export default observer(ListContainer)
