import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { IEmailVerificationResult } from "../../../global/interfaces/verification/interfaces"
import EmailVerificationExpiry from "../emailVerificationExpiry"

const EmailVerificationView = observer(() => {
  const { search } = useLocation()
  const { emailVerificationStore } = useStores()

  const { verifyEmail } = emailVerificationStore

  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [emailVerificationResult, setVerificationResult] =
    useState<IEmailVerificationResult | null>(null)

  useEffect(() => {
    wrapWithLoader(async () => {
      const verificationToken = new URLSearchParams(search).get("token")
      setVerificationResult(
        verificationToken ? await verifyEmail(verificationToken) : { isSuccess: false }
      )
    })
  }, [])

  if (!emailVerificationResult) {
    return null
  }

  if (emailVerificationResult.isSuccess) {
    return <Navigate to={Routes.AToZLandingPage} />
  }

  return <EmailVerificationExpiry />
})

export default EmailVerificationView
