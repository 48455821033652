import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr"
import { makeObservable, observable } from "mobx"
import { getCookie } from "../../utils/cookies"
import { INotificationEvent } from "../../interfaces/notification"

export interface INotificationHub {
  connect: () => Promise<void>
  disconnect: () => Promise<void>
  readonly notification: INotificationEvent | null
}

class NotificationHub implements INotificationHub {
  public notification: INotificationEvent | null = null

  private hubConnection: HubConnection | undefined

  public disposed = true

  constructor() {
    makeObservable(this, {
      notification: observable,
    })
  }

  private buildHubConnection(): HubConnectionBuilder {
    return new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_NOTIFICATION_URL}`, {
        accessTokenFactory: () => getCookie("jwtcore"),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
  }

  connect = async (): Promise<void> => {
    if (!this.hubConnection) {
      this.hubConnection = this.buildHubConnection().build()
      this.disposed = false
      this.hubConnection.on("RecieveNotificationAsync", this.recieveNotification)
      this.hubConnection.onclose(this.disconnect)
      await this.hubConnection.start()
    }
  }

  disconnect = async (): Promise<void> => {
    if (!this.disposed && this.hubConnection) {
      this.hubConnection.stop().then(() => {
        this.hubConnection = undefined
        this.disposed = true
      })
    }
  }

  recieveNotification = (notification: INotificationEvent): void => {
    console.log(`LOGGING THE NEW NOTIFICATION`)
    this.notification = { ...notification } as INotificationEvent
  }
}

export default new NotificationHub()
