import { observer } from "mobx-react"
import React, { createContext, useEffect } from "react"
import { useFeatureToggles, useStores } from "../hooks"
import { INotificationHub, NotificationHub } from "../types/notifications"

export interface INotificationHubContext {
  notificationHub: INotificationHub
}

export const NotificationHubContext = createContext<INotificationHubContext>({
  notificationHub: NotificationHub,
})

export interface INotificationHubContextProviderProps {
  children: React.ReactNode
}

const NotificationHubContextProvider = observer((props: INotificationHubContextProviderProps) => {
  const { children } = props
  const {
    profileStore: { user },
    featureToggledRouteStore,
  } = useStores()

  const featureToggleRoute = featureToggledRouteStore.getRoute("/notificationCentre", true)

  if (!featureToggleRoute) {
    return null
  }

  const {
    enabledToggles: [featureToggledRouteToggle],
    loading,
  } = useFeatureToggles(featureToggleRoute.flag)

  useEffect(() => {
    if (!loading && featureToggledRouteToggle) {
      if (user) {
        NotificationHub.connect().then(() => console.log(`Connected to hub`))
      } else {
        NotificationHub.disconnect().then(() => console.log(`Disconnected from hub`))
      }
    }
  }, [user, loading, featureToggledRouteToggle])

  return (
    <NotificationHubContext.Provider value={{ notificationHub: NotificationHub }}>
      {children}
    </NotificationHubContext.Provider>
  )
})

export default NotificationHubContextProvider
