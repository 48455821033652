import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Checkboxes,
  Col,
  Container,
  ErrorSummary,
  Input,
  Label,
  Select,
} from "nhsuk-react-components"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { withWrapper } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import { Routes } from "../global/enums"
import { QuestionType } from "../global/enums/appApprovalQuestion/enums"
import { useStores } from "../global/hooks"
import { IApplicationTuple } from "../global/interfaces/application/interfaces"
import { IRegion } from "../global/interfaces/region/interfaces"
import { ErrorsCollection } from "../global/types"
import { ApplicationType } from "../global/enums/application"
import "./styles.scss"

const StepTwoView = observer(() => {
  const { stepTwoStore, organisationStore, applicationStore } = useStores()
  const { stepTwoRequestData, stepTwoQuestionData } = stepTwoStore

  const { AToZLandingPage } = Routes

  const { productId } = useParams<{ productId: string }>()

  const { wrapWithLoader } = useContext(LoaderContext)
  const [errorState, setErrorState] = useState<ErrorsCollection>(stepTwoStore.errors)
  const [requestSuccessful, setRequestSuccessful] = useState(false)
  const [regions, setRegions] = useState<IRegion[]>([])
  const [product, setProduct] = useState<IApplicationTuple | null>(null)

  useEffect(() => {
    wrapWithLoader(async () => {
      setProduct(await applicationStore.getProduct(productId || ""))
      await stepTwoStore.getStepTwoQuestionsToAsk(productId || "")

      if (
        stepTwoStore.stepTwoQuestionData.selectedQuestions.some(q => q.type === QuestionType.Region)
      ) {
        setRegions(await organisationStore.getRegions())
      }
    })
  }, [productId])

  const handleAnswerInput = (id: string, answer: string): void => {
    if (!stepTwoRequestData.answers.some(a => a.id === id)) {
      stepTwoRequestData.answers.push({
        id: id,
        answer: answer,
      })
    } else {
      const idx = stepTwoRequestData.answers.findIndex(a => a.id === id)
      if (idx !== -1) {
        stepTwoRequestData.answers[idx].answer = answer
      }
    }
  }

  const multiCheckboxChangeHelper = (
    questionKey: string,
    oldValue: string,
    newValue: string,
    checked: boolean
  ) => {
    let answer = oldValue.trim()
    const commaSeparator = ","
    const andSeparator = "&"

    answer =
      checked === false
        ? answer.replace(new RegExp(newValue, "g"), "")
        : [answer, newValue].join(commaSeparator)

    const lastCharacter = answer.length > 0 ? answer.charAt(answer.length - 1) : andSeparator
    const firstCharacter = answer.length > 0 ? answer.charAt(0) : andSeparator
    answer = lastCharacter === commaSeparator ? answer.slice(0, answer.length - 1) : answer
    answer = firstCharacter === commaSeparator ? answer.slice(1, answer.length) : answer

    stepTwoRequestData.answers =
      answer.length < 1
        ? [...stepTwoRequestData.answers.filter(a => a.id !== questionKey)]
        : [
            ...stepTwoRequestData.answers.filter(a => a.id !== questionKey),
            {
              id: questionKey,
              answer: answer,
            },
          ]
  }

  const handleCheckboxChange = (questionKey: string, value: string, checked: boolean) => {
    const questionAnswer = stepTwoRequestData.answers.find(a => a.id === questionKey)
    const questionType = stepTwoQuestionData.selectedQuestions.find(q => q.id === questionKey)?.type
    const cleanedUpValue = value.trim()

    if (questionType === QuestionType.DropdownMulti) {
      multiCheckboxChangeHelper(
        questionKey,
        (questionAnswer?.answer ?? "").trim(),
        cleanedUpValue,
        checked
      )
      return
    }

    stepTwoRequestData.answers = questionAnswer
      ? [...stepTwoRequestData.answers.filter(a => a.id !== questionKey)]
      : [
          ...stepTwoRequestData.answers,
          {
            id: questionKey,
            answer: cleanedUpValue,
          },
        ]
  }

  const confirmRequest = async (): Promise<void> => {
    if (validate()) {
      await wrapWithLoader(async () => {
        await stepTwoStore.requestAppAccessWithStepTwo(productId || "").then(() => {
          if (stepTwoStore.errors.length > 0) {
            setErrorState(stepTwoStore.errors)
          } else {
            setRequestSuccessful(true)
          }
        })
      })
    }
  }

  const validateTextFields = (
    value: string,
    key: string,
    validationRules: {
      required?: boolean
      minLength?: number
      maxLength?: number
    },
    errorsCollection: ErrorsCollection
  ) => {
    if (validationRules.required && value.length < 1) {
      const question = stepTwoQuestionData.selectedQuestions.find(q => q.id === key)
      const missingAnswerErrorMsg = `Answer the question ${question?.description}`
      errorsCollection.addOrUpdate({
        key: key,
        value: [
          {
            fieldError: missingAnswerErrorMsg,
            summaryError: missingAnswerErrorMsg,
          },
        ],
      })
    } else if (validationRules.minLength && value.length < validationRules.minLength) {
      const minLengthErrorMsg = `Answer requires length of at least ${validationRules.minLength} characters`
      errorsCollection.addOrUpdate({
        key: key,
        value: [
          {
            fieldError: minLengthErrorMsg,
            summaryError: minLengthErrorMsg,
          },
        ],
      })
    } else if (validationRules.maxLength && value.length < validationRules.maxLength) {
      const maxLengthErrorMsg = `Answer should not be longer than ${validationRules.maxLength} characters`
      errorsCollection.addOrUpdate({
        key: key,
        value: [
          {
            fieldError: maxLengthErrorMsg,
            summaryError: maxLengthErrorMsg,
          },
        ],
      })
    }
  }

  const validate = () => {
    const errors = new ErrorsCollection()
    const acceptConditionsErrorMessage = `You must agree to the terms and conditions, in order to gain access to ${product?.name}`
    stepTwoQuestionData.selectedQuestions.forEach(q => {
      const missingAnswerErrorMsg = `Answer the question '${q.description}'`

      const questionAnswer = stepTwoRequestData.answers.find(a => q.id === a.id)

      const isTextType = q.type === QuestionType.Text

      if (!questionAnswer) {
        errors.addOrUpdate({
          key: q.id,
          value: [{ fieldError: missingAnswerErrorMsg, summaryError: missingAnswerErrorMsg }],
        })
      } else if (isTextType) {
        validateTextFields(
          questionAnswer.answer,
          q.id,
          {
            required: true,
            minLength: product && product.type == ApplicationType.Foundry ? 10 : 1,
          },
          errors
        )
      }
    })

    if (stepTwoQuestionData.productSpecificTerms && !stepTwoRequestData.customTermsAccepted) {
      errors.addOrUpdate({
        key: "customTerms",
        value: [
          {
            fieldError: acceptConditionsErrorMessage,
            summaryError: acceptConditionsErrorMessage,
          },
        ],
      })
    }

    setErrorState(errors)

    return errors.length === 0
  }

  return (
    <Container className="step-two-view">
      <Col className="step-two-view__column" width="two-thirds">
        <>
          {requestSuccessful && (
            <>
              <Label isPageHeading>Thank you for submitting your access request</Label>
              <BodyText>Your request has been submitted to {product?.name}</BodyText>
              <Label bold>What happens next?</Label>
              <BodyText>
                You will receive an email in the next 48 hours with the outcome of you access
                request.
              </BodyText>
              <Link to={{ pathname: AToZLandingPage }}>Return to home page</Link>
            </>
          )}
          {!requestSuccessful && (
            <>
              {errorState.length > 0 && (
                <ErrorSummary role="alert" tabIndex={-1}>
                  <ErrorSummary.Title id="error-summary-title">
                    There is a problem
                  </ErrorSummary.Title>
                  {errorState.map(kvp => (
                    <ErrorSummary.Item
                      href={`#input-field-${kvp.key}`}
                      key={`${kvp.key}_${kvp.value}`}
                    >
                      {errorState.getFirstSummaryError(kvp.key)}
                    </ErrorSummary.Item>
                  ))}
                </ErrorSummary>
              )}
              <Label size="l">Request access form for {product?.name}</Label>
              <BodyText>
                In order to assess whether we can grant you access to {product?.name}, we will need
                additional information from you.
              </BodyText>
              {stepTwoQuestionData.selectedQuestions.map(question => {
                const err = errorState.getFirstFieldError(question.id)
                return (
                  <>
                    {question.type === QuestionType.Text && (
                      <Input
                        key={`inputtexttype_${question.id}`}
                        hint={question.guidanceText}
                        id={`input-field-${question.id}`}
                        label={question.description}
                        required
                        onChange={e =>
                          handleAnswerInput(question.id, (e.target as HTMLSelectElement).value)
                        }
                        error={err}
                      />
                    )}
                    {question.type === QuestionType.Region && (
                      <Select
                        key={`selectregiontype_${question.id}`}
                        id={`input-field-${question.id}`}
                        label={question.description}
                        onChange={e =>
                          handleAnswerInput(question.id, (e.target as HTMLSelectElement).value)
                        }
                        error={err}
                      >
                        <Select.Option
                          key={`selectregiontypedefaultoption_${question.id}`}
                          value="DEFAULT"
                          disabled
                          selected
                        >
                          Select region
                        </Select.Option>
                        {regions.map((r, idx) => (
                          <Select.Option key={`${r.name}_${idx}`} value={r.name}>
                            {r.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                    {question.type === QuestionType.UDAL && (
                      <Select
                        key={`selectudaltype_${question.id}`}
                        id={`input-field-${question.id}`}
                        label={question.description}
                        onChange={e =>
                          handleAnswerInput(question.id, (e.target as HTMLSelectElement).value)
                        }
                        error={err}
                      >
                        <Select.Option
                          key={`selectudaltypedefaultoption_${question.id}`}
                          value="DEFAULT"
                          disabled
                        >
                          Select UDAL access
                        </Select.Option>
                        <Select.Option
                          key={`selectudaltypeoptionone_${question.id}`}
                          value="Developer"
                        >
                          Developer
                        </Select.Option>
                        <Select.Option
                          key={`selectudaltypeoptiontwo_${question.id}`}
                          value="Analyst"
                        >
                          Analyst
                        </Select.Option>
                      </Select>
                    )}
                    {question.type === QuestionType.DropdownSingle && (
                      <Select
                        key={`selectdropdownsingletype_${question.id}`}
                        id={`input-field-${question.id}`}
                        label={question.description}
                        onChange={e =>
                          handleAnswerInput(question.id, (e.target as HTMLSelectElement).value)
                        }
                        error={err}
                      >
                        <Select.Option
                          key={`selectdropdownsingletypedefaultoption_${question.id}`}
                          value="DEFAULT"
                          disabled
                          selected
                        >
                          Please select preferred option
                        </Select.Option>
                        {Object.entries(question.answers)
                          .sort((a, b) => a[1].localeCompare(b[1]))
                          .map((entry, idx) => (
                            <Select.Option key={`${entry[0]}_${idx}`} value={entry[1]}>
                              {entry[1]}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                    {question.type === QuestionType.DropdownMulti && (
                      <>
                        <Label
                          key={`labeldropdownmulti_${question.id}`}
                          size="s"
                          htmlFor={`input-field-${question.id}`}
                        >
                          {question.description}
                        </Label>
                        <Checkboxes
                          key={`checkboxesdropdownmulti_${question.id}`}
                          id={`input-field-${question.id}`}
                          error={err}
                        >
                          {Object.entries(question.answers)
                            .sort((a, b) => a[1].localeCompare(b[1]))
                            .map((entry, idx) => (
                              <Checkboxes.Box
                                key={`checkboxesdropdownmulti_${idx}_${question.id}`}
                                onChange={e =>
                                  handleCheckboxChange(
                                    question.id,
                                    entry[1],
                                    e.currentTarget.checked
                                  )
                                }
                                selected={stepTwoRequestData.answers.some(a => a.id == question.id)}
                              >
                                {entry[1]}
                              </Checkboxes.Box>
                            ))}
                        </Checkboxes>
                      </>
                    )}
                  </>
                )
              })}
              {stepTwoQuestionData.productSpecificTerms && (
                <>
                  <Label size="s" htmlFor="input-field-customTerms">
                    Terms and conditions
                  </Label>
                  <Checkboxes
                    id="input-field-customTerms"
                    onChange={e => {
                      /* eslint-disable @typescript-eslint/no-explicit-any */
                      stepTwoRequestData.customTermsAccepted = (e.target as any).checked
                      /* eslint-enable @typescript-eslint/no-explicit-any */
                    }}
                    error={errorState.getFirstFieldError("customTerms")}
                  >
                    <Checkboxes.Box key={`terms_and_conditions_checkbox_option`}>
                      I agree to the{" "}
                      <a
                        href={stepTwoQuestionData.productSpecificTermsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="This link will open in a new tab"
                      >
                        terms and conditions
                      </a>{" "}
                      of using {product?.name}
                    </Checkboxes.Box>
                  </Checkboxes>
                </>
              )}
              <div className="step-two-view__button-controls">
                <Button
                  className="step-two-view__button"
                  id="publish-button"
                  onClick={confirmRequest}
                >
                  Request access
                </Button>
                <Link to={{ pathname: AToZLandingPage }}>Cancel</Link>
              </div>
            </>
          )}
        </>
      </Col>
    </Container>
  )
})

export default withWrapper(StepTwoView)
