import React from "react"

const UrgentIcon = () => {
  return (
    <svg
      data-testid="urgent-icon"
      className="urgent-icon"
      width="6"
      height="14"
      viewBox="0 0 6 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.501 0.738L1.242 9.098H4.852L5.593 0.738H0.501ZM0.976 10.466V14H5.118V10.466H0.976Z"
        fill="#D5281B"
      />
    </svg>
  )
}

export default UrgentIcon
