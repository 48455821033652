import Details from "@govuk-react/details"
import { Label, Select } from "nhsuk-react-components"
import React from "react"
import "./styles.scss"

interface IJobSortContainerProps {
  handleJobRole: (role: string) => void
}

const JobSortContainer = (props: IJobSortContainerProps) => {
  const { handleJobRole } = props

  return (
    <div className="job-sort-container">
      <Label className="job-sort-container__label">Job role</Label>
      <Select
        data-testid="job-sort-container__select"
        className="job-sort-container__select"
        name="jobRole"
        onChange={e => handleJobRole((e.target as HTMLSelectElement).value)}
      >
        <Select.Option data-testid="job-sort-container__select__option" value="A">
          All
        </Select.Option>
        <Select.Option data-testid="job-sort-container__select__option" value="B">
          Board level
        </Select.Option>
        <Select.Option data-testid="job-sort-container__select__option" value="C">
          Clinical or operational management
        </Select.Option>
        <Select.Option data-testid="job-sort-container__select__option" value="S">
          Services and corporate
        </Select.Option>
      </Select>
      <div className="job-sort-container__details">
        <Details
          data-testid="job-sort-container__details"
          summary="What are the different job roles?"
        >
          <Label className="job-sort-container__details__label">Board level</Label>
          <ul className="job-sort-container__details__ul">
            <li className="job-sort-container__details__ul__li">Chairperson</li>
            <li className="job-sort-container__details__ul__li">Chief executive</li>
            <li className="job-sort-container__details__ul__li">Finance director</li>
            <li className="job-sort-container__details__ul__li">Medical director</li>
            <li className="job-sort-container__details__ul__li">Other executive director</li>
            <li className="job-sort-container__details__ul__li">Non-executive director</li>
          </ul>

          <Label className="job-sort-container__details__label">
            Clinical or operational management
          </Label>
          <ul className="job-sort-container__details__ul">
            <li className="job-sort-container__details__ul__li">
              Clinical director or chief of division
            </li>
            <li className="job-sort-container__details__ul__li">Consultant (doctor)</li>
            <li className="job-sort-container__details__ul__li">General practitioner</li>
            <li className="job-sort-container__details__ul__li">Doctor, non-consultant</li>
            <li className="job-sort-container__details__ul__li">Dentist or dental nurse</li>
            <li className="job-sort-container__details__ul__li">Nurse or midwife</li>
            <li className="job-sort-container__details__ul__li">Pharmacist</li>
            <li className="job-sort-container__details__ul__li">Psychologist</li>
            <li className="job-sort-container__details__ul__li">Allied health professional</li>
            <li className="job-sort-container__details__ul__li">Clinical service manager</li>
            <li className="job-sort-container__details__ul__li">Clinical research roles</li>
            <li className="job-sort-container__details__ul__li">Other clinical profession</li>
          </ul>

          <Label className="job-sort-container__details__label">Services and corporate</Label>
          <ul className="job-sort-container__details__ul">
            <li className="job-sort-container__details__ul__li">
              Information analyst, coder or auditor
            </li>
            <li className="job-sort-container__details__ul__li">
              Accountant or finance professional
            </li>
            <li className="job-sort-container__details__ul__li">Legal services professional</li>
            <li className="job-sort-container__details__ul__li">Human resources professional</li>
            <li className="job-sort-container__details__ul__li">
              Administration or clerical professional
            </li>
            <li className="job-sort-container__details__ul__li">
              Media and communications officer
            </li>
            <li className="job-sort-container__details__ul__li">
              Estates and facilities team member
            </li>
            <li className="job-sort-container__details__ul__li">
              Project management office team member
            </li>
            <li className="job-sort-container__details__ul__li">Other service profession</li>
          </ul>
        </Details>
      </div>
    </div>
  )
}

export default JobSortContainer
