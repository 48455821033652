export enum BulkInvitationRecipientState {
  Pending = 0,
  Completed = 1,
  Failed = 2,
}

export enum BulkInvitationJobState {
  Created = 0,
  Processing = 1,
  Failed = 2,
  Completed = 3,
}
