import { BodyText, Select } from "nhsuk-react-components"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ISearchResultsPagination } from "../../interfaces"
import "../../styles.scss"

interface ISearchResultsHeaderProps {
  total: number | null
  searchText: string | null
  params: URLSearchParams
  setViewFilters: (viewFilters: boolean) => void
  setDataUpdated: (dataUpdated: boolean) => void
  page: number
  pagination: ISearchResultsPagination
}

const SearchResultsHeader = (props: ISearchResultsHeaderProps) => {
  const { total, searchText, params, setViewFilters, setDataUpdated, page, pagination } = props

  const [sortBy, setSortBy] = useState<string | null>(params.get("OrderBy"))

  const navigate = useNavigate()
  const { search } = useLocation()

  const showingResults = (page: number, total: number) => {
    const resultFrom = page > 1 ? page * 10 - 9 : page
    const resultTo = page * 10 > total ? total : page * 10
    return `${resultFrom}-${resultTo}`
  }

  const onSortBy = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLSelectElement
    setSortBy(target.value === "relevance" ? null : target.value)
    const formattedParams = search
      .replace(`&Page=${pagination.pageNumber}`, "&Page=1")
      .replace(/ /g, "%20")

    const includesOrderBy = formattedParams.includes("&OrderBy=")

    if (includesOrderBy) {
      let sort = ""
      if (target.value === "relevance") {
        const replaceExp = formattedParams.includes("&OrderBy=Name%20asc")
          ? "&OrderBy=Name%20asc"
          : "&OrderBy=Name%20desc"
        sort = `/search${formattedParams.replace(replaceExp, "")}`
      } else {
        const replaceExp = formattedParams.includes("Name%20asc") ? "Name%20asc" : "Name%20desc"
        sort = `/search${formattedParams.replace(replaceExp, target.value)}`
      }

      navigate(sort)
    } else {
      navigate(`${formattedParams}&OrderBy=${target.value}`)
    }

    setDataUpdated(true)
  }

  return (
    <div className="search-results__header-container">
      <div className="search-results__results-container">
        <BodyText className="search-results__total">
          {total} {total !== null && total > 1 ? "results" : "result"} found for{" "}
          <span className="search-results__bold">{searchText}</span>
        </BodyText>
        <BodyText className="search-results__showing-total">
          Showing results {showingResults(page, total || 0)}
        </BodyText>
      </div>
      <div className="search-results__sorting-container">
        <Select
          className="search-results__sortBy"
          id="sort-by"
          label="Sort by"
          onChange={onSortBy}
          defaultValue={sortBy || undefined}
        >
          <Select.Option value="relevance">Relevance</Select.Option>
          <Select.Option value="Name asc">A to Z</Select.Option>
          <Select.Option value="Name desc">Z to A</Select.Option>
        </Select>
        <button className="search-results__open-filter-button" onClick={() => setViewFilters(true)}>
          Filter
        </button>
      </div>
    </div>
  )
}

export default SearchResultsHeader
