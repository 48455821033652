import React from "react"
import { CloseIcon } from "../../../../../../../global/components/icons"
import "./styles.scss"

const Search = props => {
  const { onChange, onClear, value, placeholder } = props

  return (
    <div className="searchbox__container">
      <input
        className="nhsuk-input searchbox__input"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
      {value && (
        <button className="searchbox__clear" onClick={onClear}>
          <CloseIcon />
        </button>
      )}
    </div>
  )
}

export default Search
