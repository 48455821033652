import React, { createContext, useEffect, useState } from "react"
import { OrganisationKeys } from "../../../../../global/constants/organisation"
import { useStores } from "../../../../../global/hooks"
import { IOrganisation, IOrganisationType } from "../../../../../global/interfaces/organisation"
import { IRegion } from "../../../../../global/interfaces/region/interfaces"
import { Guid } from "../../../../../global/types"

interface ICustomAccessViewContext {
  regions: IRegion[]
  organisationTypes: IOrganisationType[]
  icbs: IOrganisation[]
  removeAccessGroup: (accessGroupId: string | null) => void
  onExistingGroupSelected: (accessGroupId: string | null, existingAccessGroupId: string) => void
}

export const CustomAccessViewContext = createContext<ICustomAccessViewContext>({
  regions: [],
  organisationTypes: [],
  icbs: [],
  removeAccessGroup: (_: string | null) => Function.prototype,
  onExistingGroupSelected: (_: string | null, __: string) => Function.prototype,
})

interface ICustomAccessViewContextComponentProps {
  children: React.ReactNode
}

const CustomAccessViewContextComponent = (props: ICustomAccessViewContextComponentProps) => {
  const { children } = props

  const { organisationGroupStore, organisationStore, tableauAccessViewStore } = useStores()

  const [regions, setAllRegions] = useState<IRegion[]>([])
  const [organisationTypes, setOrganisationTypes] = useState<IOrganisationType[]>([])
  const [icbs, setICBs] = useState<IOrganisation[]>([])

  useEffect(() => {
    let mounted = true

    Promise.all([
      organisationGroupStore.getAllOrganisationGroups(),
      organisationStore.getOrganisationTypes(),
      organisationStore.getRegions(),
      organisationStore.getOrganisationsByType(OrganisationKeys.KeyICB),
    ]).then(([_, fetchedOrganisationTypes, fetchedRegions, fetchedICBs]) => {
      if (mounted) {
        setOrganisationTypes(fetchedOrganisationTypes)
        setAllRegions(fetchedRegions)
        setICBs(fetchedICBs)
      }
    })
    return () => {
      organisationGroupStore.resetState()
      mounted = false
    }
  }, [])

  const removeAccessGroup = (accessGroupId: string | null): void => {
    if (!accessGroupId) {
      return
    }

    const organisationGroupsForApplicationCopy = [
      ...organisationGroupStore.organisationGroupsForApplication,
    ]
    const idx = organisationGroupsForApplicationCopy.findIndex(og => og.id === accessGroupId)
    if (idx !== -1) {
      organisationGroupsForApplicationCopy.splice(idx, 1)
      organisationGroupStore.setOrganisationGroupsForApplication(
        organisationGroupsForApplicationCopy
      )
      organisationGroupStore.deleteErrorsByAccessGroupId(accessGroupId)
    }

    const tableauAccessGroupsCopy = [...tableauAccessViewStore.tableauAccess.tableauAccessGroups]
    const tIdx = tableauAccessGroupsCopy.findIndex(
      tag => tag.tableauOrganisationGroupId === accessGroupId
    )
    if (tIdx !== -1) {
      tableauAccessGroupsCopy[tIdx].tableauOrganisationGroupId = Guid.EmptyString
      tableauAccessViewStore.tableauAccess.tableauAccessGroups = tableauAccessGroupsCopy
    }
  }

  const onExistingGroupSelected = (accessGroupId: string | null, existingAccessGroupId: string) => {
    if (!accessGroupId) {
      return
    }

    const organisationGroupsForApplicationCopy = [
      ...organisationGroupStore.organisationGroupsForApplication,
    ]
    const allOrganisationGroupsCopy = [...organisationGroupStore.allOrganisationGroups]
    const [accessGroupIdx, existingAccessGroupIdx] = [
      organisationGroupsForApplicationCopy.findIndex(og => og.id === accessGroupId),
      allOrganisationGroupsCopy.findIndex(og => og.id === existingAccessGroupId),
    ]

    if (accessGroupIdx !== -1 && existingAccessGroupIdx !== -1) {
      organisationGroupsForApplicationCopy[accessGroupIdx] =
        allOrganisationGroupsCopy[existingAccessGroupIdx]

      organisationGroupStore.setOrganisationGroupsForApplication(
        organisationGroupsForApplicationCopy
      )
      organisationGroupStore.deleteErrorsByAccessGroupId(accessGroupId)
    }
  }

  return (
    <CustomAccessViewContext.Provider
      value={{
        regions: regions,
        organisationTypes: organisationTypes,
        icbs: icbs,
        removeAccessGroup: removeAccessGroup,
        onExistingGroupSelected: onExistingGroupSelected,
      }}
    >
      {children}
    </CustomAccessViewContext.Provider>
  )
}

export default CustomAccessViewContextComponent
