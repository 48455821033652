import _ from "lodash"
import { observer } from "mobx-react"
import { Label, Select } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import {
  AsyncSearchableSelect,
  SearchableSelect,
} from "../../../global/components/searchableSelect"
import {
  isAllOrNhs,
  isOther,
  OrganisationTypes,
} from "../../../global/constants/organisation/constants"
import { useStores } from "../../../global/hooks"

const constructChildDropdownSelection = item => {
  const name =
    item.innerCode && item.innerName.includes(`(${item.innerCode})`)
      ? item.innerName
      : `${item.innerName} (${item.innerCode})`
  return item.innerCode
    ? {
        label: name,
        value: item.innerCode,
      }
    : null
}

export default observer(
  ({
    identifier,
    section,
    item,
    selectOrganisation,
    selectChildOrganisation,
    handleAsyncOrgSearch,
    isFaulted,
    children,
  }) => {
    const { accessPermissionsStore: store } = useStores()
    const { organisations, getChildOrganisationsAsync } = store

    const { albOrg, pcnOrg, gpOrg, nhsTrustOrg, pharmacyOrg } = OrganisationTypes

    const [childOrganisations, setChildOrganisations] = useState([])
    const [noResultsMessage, setNoResultsMessage] = useState("Set an organisation (optional)")
    const [orgDropdownValue, setOrgDropdownValue] = useState((item && item.code) || "")
    const [isOrgType, setIsOrgType] = useState(false)
    const [currentOrg, setOrg] = useState("")
    const mandatorySelectionMessage = "Please select an organisation"
    const delayedSearchOrganisationLabel = `Organisation name (type at least 3 characters).`
    const asyncSearchableOrganisations = [pcnOrg, pharmacyOrg, gpOrg]
    const [previousSelectedOption, setPreviousOption] = useState(undefined)
    const blackList = "black-list"

    useEffect(() => {
      updateSubOrgsList(item, section)
      if (asyncSearchableOrganisations.includes(item.code)) {
        const codeToUse = item.code === gpOrg || item.code === pcnOrg ? pcnOrg : pharmacyOrg
        setOrgDropdownValue(codeToUse)
      } else {
        setOrgDropdownValue(item.code)
      }
    }, [item])

    const mapSubOrganisation = org => ({
      label: `${org.name} (${org.code})`,
      value: org.code,
    })

    const handleOrganisationSearch = _.debounce(async (search, callback) => {
      if (search === "" || search === undefined || search.length < 3) {
        setNoResultsMessage("Set an organisation (optional).")
        return
      }

      handleAsyncOrgSearch(currentOrg, search)
        .then(data => {
          var mappedSubOrganisations = data.map(mapSubOrganisation)
          setChildOrganisations(mappedSubOrganisations)
          callback(mappedSubOrganisations)
        })
        .catch(error => callback(error))
    }, 250)

    const onClear = () => setNoResultsMessage("You need to provide a search term.")

    const updateSubOrgsList = async (orgParam, section) => {
      if (!orgParam || (!orgParam.organisationType && !orgParam.code)) {
        return
      }

      setOrg(orgParam.code)
      let org = {}
      if (
        !orgParam.isOrgType &&
        orgParam.organisationType &&
        orgParam.organisationType !== albOrg
      ) {
        org = organisations.find(o => o.code === orgParam.organisationType)
      } else if (orgParam.code === gpOrg) {
        org = organisations.find(o => o.code === pcnOrg)
      } else {
        org = organisations.find(o => o.code === orgParam.code)
      }

      setChildOrganisations([])

      if (!isAllOrNhs(org.code) && org.isOrgType) {
        setIsOrgType(true)
        setNoResultsMessage(
          isOther(org) && section !== blackList ? mandatorySelectionMessage : noResultsMessage
        )
        setOrg(org.code)
        const childOrgs = await getChildOrganisationsAsync(org.code)
        let data = []
        if (childOrgs.status === 200) {
          data = childOrgs.data
        }
        setChildOrganisations(data)
      } else {
        setIsOrgType(false)
      }
    }

    return (
      <div className="access-permissions-page__access-permissions-row">
        <div className="access-permissions-page__access-permissions-organisation access-permissions-page__access-permissions-column">
          <Select
            error={isFaulted ? mandatorySelectionMessage : ""}
            id={identifier}
            label={isFaulted ? "" : "Organisation"}
            onChange={e => {
              setOrgDropdownValue(
                selectOrganisation(
                  section,
                  e.currentTarget.value,
                  previousSelectedOption,
                  identifier
                )
              )
              setOrg(e.currentTarget.value)
            }}
            onFocus={e => {
              setPreviousOption(e.target.value)
            }}
            value={orgDropdownValue}
          >
            <Select.Option value="" disabled>
              Select organisation
            </Select.Option>
            {organisations
              .filter(o => isAllOrNhs(o.code))
              .map(o => (
                <Select.Option key={o.code} value={o.code}>
                  {o.name}
                </Select.Option>
              ))}
            <Select.Option disabled></Select.Option>
            {organisations
              .filter(o => o.code === nhsTrustOrg)
              .map(o => (
                <Select.Option key={o.code} value={o.code}>
                  {o.name}
                </Select.Option>
              ))}
            {organisations
              .filter(o => !isAllOrNhs(o.code) && !isOther(o) && o.code !== nhsTrustOrg)
              .map(o => (
                <Select.Option key={o.code} value={o.code}>
                  {o.name}
                </Select.Option>
              ))}
            {organisations.filter(isOther).map(o => (
              <Select.Option key={o.code} value={o.code}>
                {o.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {asyncSearchableOrganisations.includes(currentOrg) ? (
          <>
            {" "}
            <div className="access-permissions-page__access-permissions-child-organisation">
              <Label>{delayedSearchOrganisationLabel}</Label>
              <AsyncSearchableSelect
                className="input-field admin-info-custom-select"
                placeholder={noResultsMessage}
                noOptionsMessage={() => noResultsMessage}
                loadOptions={handleOrganisationSearch}
                onClear={onClear}
                onChange={e => selectChildOrganisation(e, identifier, section)}
                isClearable
                onInputChange={handleOrganisationSearch}
                value={constructChildDropdownSelection(item)}
              />
            </div>
          </>
        ) : isOrgType ? (
          <>
            {" "}
            <div className="access-permissions-page__access-permissions-child-organisation">
              <SearchableSelect
                error={isFaulted ? mandatorySelectionMessage : ""}
                isClearable
                noValidation={false}
                placeholder={noResultsMessage}
                name={isFaulted ? "" : noResultsMessage}
                className="input-field admin-info-custom-select"
                noOptionsMessage={() => noResultsMessage}
                options={childOrganisations.map(x => ({
                  label: `${x.name} (${x.code})`,
                  value: x.code,
                }))}
                onClear={() => selectChildOrganisation(false, identifier, section)}
                value={constructChildDropdownSelection(item)}
                onChange={e => selectChildOrganisation(e, identifier, section)}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {children}
      </div>
    )
  }
)
