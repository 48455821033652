import React from "react"
import { GroupBase } from "react-select"
import CreatableSelect from "react-select/creatable"
import { SelectContainer } from ".."
import { setDefaults } from "../../helpers"
import { UserOptionsSearchableSelectProps } from "../../types"

const UserOptionsSearchableSelect = <
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  props: UserOptionsSearchableSelectProps<OptionType, IsMulti, Group>
) => {
  const newProps = setDefaults(props)

  return (
    <SelectContainer
      id={newProps.id}
      name={newProps.name}
      hint={newProps.hint}
      error={newProps.error}
      noValidation={newProps.noValidation}
    >
      <CreatableSelect {...newProps} />
      {newProps.children}
    </SelectContainer>
  )
}

export default UserOptionsSearchableSelect
