import { Form, Input } from "nhsuk-react-components"
import React, { useEffect } from "react"
import { IOrganisation } from "../../../interfaces/organisation/interfaces"
import { ISearchResultOrganisation } from "../../../interfaces/searchResults/interfaces"
import "./styles.scss"

interface IOrganisationSearchFormProps {
  onSearch: (e: React.FormEvent<HTMLInputElement>) => void
  selectedOrganisation: IOrganisation | ISearchResultOrganisation | null
}

const OrganisationSearchForm = (props: IOrganisationSearchFormProps) => {
  const { onSearch, selectedOrganisation } = props

  useEffect(() => {
    if (selectedOrganisation) {
      const elem = document.getElementById(
        "otherorgsearch_search_results_searchinput"
      ) as HTMLInputElement
      if (elem) {
        let val = ""

        if ("name" in selectedOrganisation) {
          val = selectedOrganisation.name
        } else if ("organisationName" in selectedOrganisation) {
          val = selectedOrganisation.organisationName
        }
        elem.value = val
      }
    }
  }, [selectedOrganisation])

  return (
    <Form className="otherorgsearch_search_results">
      <Input
        id="otherorgsearch_search_results_searchinput"
        onChange={onSearch}
        placeholder="E.g. NHS Digital or “Department of Health and Social Care”"
      ></Input>
    </Form>
  )
}

export default OrganisationSearchForm
