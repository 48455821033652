import React from "react"
import { Link } from "react-router-dom"
import { IBreadcrumb } from "../../../../../interfaces/withWrapper"
import TextButton from "../../../../textButton"
import "./styles.scss"

interface IBreadcrumbProps {
  crumbs: IBreadcrumb[]
}

const Breadcrumbs = (props: IBreadcrumbProps) => {
  const { crumbs } = props

  return (
    <nav className="nhsuk-breadcrumb">
      <div className="nhsuk-width-container">
        <ol className="nhsuk-breadcrumb__list">
          {crumbs.map(crumb => {
            if (!crumb.link && !crumb.onClick) {
              throw new Error("'link' or 'onClick' properties must be defined for a breadcrumb")
            }

            const breadCrumbItemElement = crumb.onClick ? (
              <TextButton className="nhsuk-breadcrumb__link" onClick={crumb.onClick}>
                {crumb.name}
              </TextButton>
            ) : (
              <Link className="nhsuk-breadcrumb__link" to={crumb.link}>
                {crumb.name}
              </Link>
            )

            return (
              <li className="nhsuk-breadcrumb__item" key={crumb.link}>
                {breadCrumbItemElement}
              </li>
            )
          })}
        </ol>
      </div>
    </nav>
  )
}

export default Breadcrumbs
