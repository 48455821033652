export const Regions = {
  Wales: "WALES",
  London: "Y56",
  Scotland: "SCOTLAND",
  ChannelIslands: "CHANNEL ISLANDS",
  NorthWest: "Y62",
  SouthEast: "Y59",
  SouthWest: "Y58",
  Midlands: "Y60",
  NorthEastAndYorkshire: "Y63",
  EastOfEngland: "Y61",
  National: "ENGLAND",
  NorthernIreland: "NORTHERN IRELAND",
}
