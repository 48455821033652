import _ from "lodash"
import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../../../global/enums/api"
import { ApplicationType, TableauSecurityModel } from "../../../global/enums"
import { IRegion } from "../../../global/interfaces/region/interfaces"
import { ITableauAccessData, ITableauGroup } from "../../../global/interfaces/tableau/interfaces"
import { get, post } from "../../../global/utils/api"

const tableauAccessData: ITableauAccessData = {
  applicationId: "",
  complete: false,
  securityModel: TableauSecurityModel.Standard,
  tableauAccessGroups: [],
}

export interface ITableauAccessViewErrorShape {
  standard?: string
  regional?: Record<string, string>
  securityModel?: string
}

export interface ITableauAccessViewStore {
  tableauGroups: ITableauGroup[]
  regions: IRegion[]
  tableauAccess: ITableauAccessData
  errors: ITableauAccessViewErrorShape
  getTableauGroups: (applicationType: ApplicationType) => Promise<void>
  getRegions: () => Promise<void>
  getTableauAccessGroups: (productId: string) => Promise<void>
  saveTableauAccessGroups: (productId: string) => Promise<void>
  resetState: () => void
}

export class TableauAccessViewStore implements ITableauAccessViewStore {
  tableauGroups: ITableauGroup[] = []
  regions: IRegion[] = []
  tableauAccess = _.cloneDeep(tableauAccessData)
  errors: ITableauAccessViewErrorShape = {}

  constructor() {
    makeAutoObservable(this)
  }

  resetState = () => {
    this.tableauAccess = _.cloneDeep(tableauAccessData)
  }

  getTableauGroups = (applicationType: ApplicationType): Promise<void> => {
    return new Promise<void>(resolve => {
      get<ITableauGroup[]>(`/application/tableaugroups?tableauType=${applicationType}`)
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.tableauGroups = [...res.data]
          }
        })
        .then(() => resolve())
    })
  }

  getRegions = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<IRegion[]>(`/organisation/region`)
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.regions = [
              ...res.data.filter(
                region =>
                  !["WALES", "SCOTLAND", "CHANNEL ISLANDS", "NORTHERN IRELAND"].includes(
                    region.code
                  )
              ),
            ]
          }
        })
        .then(() => resolve())
    })
  }

  getTableauAccessGroups = (productId: string): Promise<void> => {
    this.resetState()
    return new Promise<void>(resolve => {
      get<ITableauAccessData>(`/tableaugroup?applicationId=${productId}`)
        .then(res => {
          this.tableauAccess = res.data
        })
        .catch(() => {
          debugger
        })
        .then(() => resolve())
    })
  }

  saveTableauAccessGroups = (productId: string): Promise<void> => {
    this.tableauAccess.applicationId = productId

    return new Promise<void>(resolve => {
      post<ITableauAccessData>(`/tableaugroup`, this.tableauAccess)
        .then(() => resolve())
        .catch(({ response }) => {
          this.tableauAccess.complete = false

          if (response.status === HttpStatusCode.UnprocessableEntity) {
            debugger
          }
        })
    })
  }
}

export default new TableauAccessViewStore()
