import React from "react"
import { IIconProps } from "../../interfaces/icon/interfaces"

const UserIcon = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#425563"
          fillRule="evenodd"
          transform="translate(2 3)"
          d="M9.6 0c5.302 0 9.6 4.36 9.6 9.74 0 3.272-1.591 6.167-4.031 7.933-.058-.568-.19-1.087-.485-1.562-.887-1.424-2.458-2.087-4.946-2.087-2.514 0-4.091.662-4.965 2.085-.337.548-.469 1.007-.519 1.719C1.688 16.08 0 13.11 0 9.739 0 4.36 4.298 0 9.6 0zm.02 6.233c-1.917 0-3.476 1.543-3.476 3.442 0 1.896 1.56 3.44 3.477 3.44 1.918 0 3.477-1.544 3.477-3.44 0-1.899-1.56-3.442-3.477-3.442z"
        />
      </g>
    </svg>
  )
}

export default UserIcon
