import React from "react"
import PropTypes from "prop-types"
import "./NavitemCard.scss"

const NavitemCard = props => {
  const { cssClass, item, link } = props
  return (
    <div className="az-page__nav-item__card">
      <a href={link} className={cssClass}>
        {item}
      </a>
    </div>
  )
}

NavitemCard.propTypes = {
  cssClass: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  link: PropTypes.string,
}

NavitemCard.defaultProps = {
  link: "#",
}

export default NavitemCard
