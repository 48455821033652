import { observer } from "mobx-react"
import { BodyText, Button, Input } from "nhsuk-react-components"
import React, { createRef, useContext, useMemo, useState, useEffect } from "react"
import Select, { MultiValue, SingleValue } from "react-select"
import Creatable from "react-select/creatable"
import ErrorContainer from "../../../../../../../global/components/errorContainer"
import { ChevronRight } from "../../../../../../../global/components/icons"
import ValidationErrorsSummary from "../../../../../../../global/components/validationErrorsSummary/validationErrorsSummary"
import { nhsuk_dark_blue } from "../../../../../../../global/constants/colours"
import { useDebounce, useStores } from "../../../../../../../global/hooks"
import {
  IOrganisation,
  IOrganisationGroup,
} from "../../../../../../../global/interfaces/organisation/interfaces"
import { IValidationError } from "../../../../../../../global/interfaces/validation"
import { Guid } from "../../../../../../../global/types"
import { groupBy } from "../../../../../../../global/utils/collection"
import { OrganisationGroupValidationFailureCode } from "../../../../validators/enums"
import { CustomAccessViewContext } from "../../context"
import { fromTildeSeparatedString, getDefaults, toTildeSeparatedString } from "./helpers"
import "./styles.scss"

interface IAccessGroupProps {
  id: number
  organisationGroup: IOrganisationGroup
}

interface ISelectedOrganisation {
  label: string
  value: string
  organisationType: string
}

const AccessGroup = observer((props: IAccessGroupProps) => {
  const { id, organisationGroup } = props

  const { organisationTypes, regions, icbs, removeAccessGroup, onExistingGroupSelected } =
    useContext(CustomAccessViewContext)

  const { organisationGroupStore, organisationStore, tableauAccessViewStore } = useStores()

  const [organisationSearchTerm, setOrganisationSearchTerm] = useState<string>("")
  const [organisations, setOrganisations] = useState<IOrganisation[]>([])
  const [selectedOrganisationOptions, setSelectedOrganisationOptions] = useState<
    ISelectedOrganisation[]
  >([])

  useDebounce(
    organisationSearchTerm,
    async () => {
      setOrganisations(
        organisationSearchTerm.length > 0
          ? await organisationStore.getAllOrganisationsByName(organisationSearchTerm)
          : []
      )
    },
    500
  )

  const tableauGroups = tableauAccessViewStore.tableauGroups

  const errors: IValidationError[] =
    organisationGroup.id && organisationGroupStore.errorsByAccessGroupId[organisationGroup.id]
      ? organisationGroupStore.errorsByAccessGroupId[organisationGroup.id]
      : []

  const errorRefCodeMappings: Record<number, React.RefObject<HTMLDivElement>> = {
    [OrganisationGroupValidationFailureCode.ShortName]: createRef<HTMLDivElement>(),
    [OrganisationGroupValidationFailureCode.Description]: createRef<HTMLDivElement>(),
    [OrganisationGroupValidationFailureCode.AtLeastOneField]: createRef<HTMLDivElement>(),
    [OrganisationGroupValidationFailureCode.TableauAccessGroup]: createRef<HTMLDivElement>(),
  }

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const selectPlaceholder = "Please select..."

  const customStyles = {
    IndicatorSeparator: () => null,
  }

  useEffect(() => {
    const loadDefaultOrganisations = async () => {
      if (!organisationGroup.organisations) {
        return
      }

      const split = fromTildeSeparatedString(organisationGroup.organisations)
      Promise.all(split.map(s => organisationStore.getOrganisationByCode(s))).then(res => {
        const options: {
          label: string
          value: string
          organisationType: string
        }[] = []

        for (const o of res) {
          if (o) {
            options.push({
              label: o.name,
              value: o.code,
              organisationType: o.organisationType,
            })
          }
        }
        setSelectedOrganisationOptions([...selectedOrganisationOptions].concat(options))
      })
    }

    loadDefaultOrganisations()
  }, [])

  const defaultTableauGroup = useMemo(() => {
    if (!organisationGroup.tableauGroupId) {
      return undefined
    }

    const tableauGroup = tableauAccessViewStore.tableauGroups.find(
      tg => tg.id === organisationGroup.tableauGroupId
    )

    if (!tableauGroup) {
      return undefined
    }

    if (tableauGroup) {
      const { id, name } = tableauGroup
      return {
        value: id,
        label: name,
      }
    }

    return undefined
  }, [organisationGroup.tableauGroupId, tableauAccessViewStore.tableauGroups])

  //#region Options
  const regionOptions = useMemo(() => {
    return regions.map(r => {
      return { value: r.code, label: r.name }
    })
  }, [regions])

  const organisationOptions = useMemo(() => {
    let filteredOrganisations = organisations
    if (organisationGroup.organisationTypes) {
      const arrayOfApplicableOrganisationTypes = fromTildeSeparatedString(
        organisationGroup.organisationTypes
      )
      filteredOrganisations = organisations.filter(o =>
        arrayOfApplicableOrganisationTypes.includes(o.organisationType)
      )
    }

    const groupedFilteredOrganisations = groupBy(
      filteredOrganisations.map(fo => {
        return {
          label: fo.name,
          value: fo.code,
          organisationType: fo.organisationType,
        }
      }),
      x => x.organisationType
    )

    for (const selectedOrganisationOption of selectedOrganisationOptions) {
      const value = groupedFilteredOrganisations.get(selectedOrganisationOption.organisationType)

      if (value) {
        const existingIndex = value.findIndex(v => v.value === selectedOrganisationOption.value)
        if (existingIndex === -1) {
          groupedFilteredOrganisations.set(
            selectedOrganisationOption.organisationType,
            value.concat(selectedOrganisationOption)
          )
        }
      } else {
        groupedFilteredOrganisations.set(selectedOrganisationOption.organisationType, [
          selectedOrganisationOption,
        ])
      }
    }

    const options: { label: string; options: { label: string; value: string }[] }[] = []
    for (const [key, value] of Array.from(groupedFilteredOrganisations)) {
      const organisationTypeDescription = organisationTypes.find(x => x.shortDescription === key)
      options.push({
        label: organisationTypeDescription?.longDescription || key,
        options: value.map(({ label, value }) => {
          return {
            label: label,
            value: value,
          }
        }),
      })
    }

    return options
  }, [
    organisations,
    selectedOrganisationOptions,
    organisationTypes,
    fromTildeSeparatedString,
    organisationGroup.organisationTypes,
  ])

  const organisationGroupOptions = (
    organisationGroupId: string | null
  ): { label: string; value: string }[] => {
    if (!organisationGroupId) {
      return []
    }

    const alreadyTakenOrganisationGroupIds = organisationGroupStore.organisationGroupsForApplication
      .map(x => x.id)
      .filter(Guid.isValid)

    const takenTableauGroups = organisationGroupStore.organisationGroupsForApplication
      .map(x => x.tableauGroupId)
      .filter(Guid.isValid)

    const currentOrganisationGroup = organisationGroupStore.organisationGroupsForApplication.find(
      x => x.id === organisationGroupId
    )

    return organisationGroupStore.allOrganisationGroups
      .filter(og => {
        if (!og.shortName || !og.description) {
          return false
        }

        if (alreadyTakenOrganisationGroupIds.includes(og.id)) {
          return false
        }

        if (
          currentOrganisationGroup &&
          og.tableauGroupId === currentOrganisationGroup.tableauGroupId
        ) {
          return true
        }

        if (takenTableauGroups.includes(og.tableauGroupId)) {
          return false
        }

        return true
      })
      .map(og => {
        return {
          value: og.id || "",
          label: og.shortName,
        }
      })
  }

  const organisationTypeOptions = useMemo(() => {
    return organisationTypes.map(x => {
      return { value: x.shortDescription, label: x.longDescription }
    })
  }, [organisationTypes])

  const tableauGroupOptions = useMemo(() => {
    const takenTableauGroups = organisationGroupStore.organisationGroupsForApplication
      .map(x => x.tableauGroupId)
      .concat(organisationGroupStore.allOrganisationGroups.map(x => x.tableauGroupId))
      .filter(Guid.isValid)

    const tableauGroupOptions = tableauGroups
      .filter(x => x.id && !takenTableauGroups.includes(x.id))
      .map(tag => {
        return { value: tag.id, label: tag.name }
      })

    return defaultTableauGroup ? [defaultTableauGroup, ...tableauGroupOptions] : tableauGroupOptions
  }, [organisationGroupStore.organisationGroupsForApplication, tableauGroups, defaultTableauGroup])

  const icbOptions = useMemo(() => {
    return icbs.map(x => {
      return { value: x.code, label: x.name }
    })
  }, [icbs])
  //#endregion

  //#region Default Values
  const defaultOrganisationTypes = useMemo(
    () => getDefaults(organisationGroup.organisationTypes, organisationTypeOptions),
    [organisationTypeOptions, organisationGroup.organisationTypes, getDefaults]
  )

  const defaultOrganisations = useMemo(() => {
    if (!organisationGroup.organisations || organisationOptions.length === 0) {
      return []
    }
    const split = fromTildeSeparatedString(organisationGroup.organisations)
    return organisationOptions
      .map(o => o.options)
      .reduce((a, b) => a.concat(b))
      .filter(o => split.includes(o.value))
  }, [organisationOptions, organisationGroup.organisations, getDefaults])

  const defaultRegions = useMemo(
    () => getDefaults(organisationGroup.regions, regionOptions),
    [regionOptions, organisationGroup.regions, getDefaults]
  )

  const defaultICBs = useMemo(
    () => getDefaults(organisationGroup.stps, icbOptions),
    [icbOptions, organisationGroup.stps, getDefaults]
  )
  //#endregion

  const addToOrganisationGroupList = (accessGroupId: string | null) => {
    if (!accessGroupId) {
      return
    }

    const organisationGroup = organisationGroupStore.organisationGroupsForApplication.find(
      x => x.id === accessGroupId
    )
    if (!organisationGroup) {
      return
    }

    if (organisationGroupStore.validateOrganisationGroupForList(organisationGroup)) {
      organisationGroupStore
        .saveOrganisationGroup(organisationGroup)
        .then(savedOrganisationGroup => {
          const organisationGroupsForApplicationCopy = [
            ...organisationGroupStore.organisationGroupsForApplication,
          ]
          const idx = organisationGroupsForApplicationCopy.findIndex(og => og.id === accessGroupId)
          if (idx !== -1) {
            organisationGroupsForApplicationCopy[idx].id = savedOrganisationGroup.id
          }
          organisationGroupStore.setAllOrganisationGroups(
            [...organisationGroupStore.allOrganisationGroups].concat(savedOrganisationGroup)
          )
          return true
        })
    }
  }

  const updateNewOrganisationGroup = (
    updateFn: (newOrganisationGroup: IOrganisationGroup) => void
  ) => {
    const organisationGroupsForApplicationCopy = [
      ...organisationGroupStore.organisationGroupsForApplication,
    ]
    const idx = organisationGroupsForApplicationCopy.findIndex(x => x.id === organisationGroup.id)
    if (idx !== -1) {
      updateFn(organisationGroupsForApplicationCopy[idx])
      organisationGroupStore.setOrganisationGroupsForApplication(
        organisationGroupsForApplicationCopy
      )
    }
  }

  const onOrganisationGroupNameChanged = (
    option: SingleValue<{ label: string; value: string | null }>
  ) => {
    if (!option || !option.value) {
      return
    }

    if (Guid.isValid(option.value)) {
      onExistingGroupSelected(organisationGroup.id, option.value)
    } else {
      updateNewOrganisationGroup(og => (og.shortName = option.label))
    }
  }

  const displayName = useMemo(() => {
    const name = `Access Group ${id}`
    if (!collapsed || !organisationGroup.shortName) {
      return name
    }

    return `${name} - ${organisationGroup.shortName}`
  }, [id, collapsed, organisationGroup.shortName])

  const onOrganisationChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const filteredOptions = selectedOrganisationOptions.filter(
      so => e.findIndex(eo => eo.value === so.value) !== -1
    )

    for (const { label, value } of e.filter(
      eo => filteredOptions.findIndex(fo => fo.value === eo.value) === -1
    )) {
      const organisation = organisations.find(o => o.code === value)

      if (!organisation) {
        continue
      }

      const o = {
        label: label,
        value: value,
        organisationType: organisation.organisationType,
      }

      filteredOptions.push(o)
    }

    setSelectedOrganisationOptions(filteredOptions)
    updateNewOrganisationGroup(og => (og.organisations = toTildeSeparatedString(e)))
  }

  const onOrganisationTypesChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const organisationTypeShortDescriptions = e.map(x => x.value)
    if (
      selectedOrganisationOptions.some(
        so => !organisationTypeShortDescriptions.includes(so.organisationType)
      )
    ) {
      const newSelectedOrganisationOptions = selectedOrganisationOptions.filter(x =>
        organisationTypeShortDescriptions.includes(x.organisationType)
      )
      setSelectedOrganisationOptions(newSelectedOrganisationOptions)
      updateNewOrganisationGroup(
        x =>
          (x.organisations = toTildeSeparatedString(
            newSelectedOrganisationOptions.map(x => {
              return { label: x.label, value: x.value }
            })
          ))
      )
    }

    updateNewOrganisationGroup(og => (og.organisationTypes = toTildeSeparatedString(e)))
  }

  return (
    <div className="access-group">
      <div className="access-group__collapsable-header" onClick={() => setCollapsed(c => !c)}>
        <hr className="access-group__collapsable-header__horizontal-rule" />
        <div className="access-group__collapsable-header__container">
          <span
            className={`access-group__collapsable-header__container__title${
              errors.length > 0 && collapsed ? "--error" : ""
            } no-select`}
          >
            {displayName}
          </span>
          <ChevronRight
            className={`access-group__collapsable-header__container__chevron${
              !collapsed ? "--open" : ""
            }`}
            fill={nhsuk_dark_blue}
          />
        </div>
        <hr
          className={`access-group__collapsable-header__horizontal-rule${
            collapsed ? "--collapsed" : ""
          }`}
        />
      </div>
      {!collapsed && (
        <>
          <ValidationErrorsSummary errors={errors} errorRefCodeMappings={errorRefCodeMappings} />
          <span className="access-group__field-title">
            {organisationGroup.shortName || "Organisation group name"}
          </span>
          <ErrorContainer
            message={
              errors.find(e => e.code === OrganisationGroupValidationFailureCode.ShortName)?.message
            }
            inputRef={errorRefCodeMappings[OrganisationGroupValidationFailureCode.ShortName]}
          >
            <Creatable
              classNamePrefix="access-group__select"
              placeholder="Please select existing or enter new group name"
              components={customStyles}
              options={organisationGroupOptions(organisationGroup.id)}
              defaultInputValue={organisationGroup.shortName}
              onChange={e => onOrganisationGroupNameChanged(e)}
              isValidNewOption={e =>
                !organisationGroupStore.organisationGroupsForApplication.some(
                  og => og.shortName === e
                ) && e.length > 0
              }
              isSearchable
            />
          </ErrorContainer>
          <span className="access-group__field-title">
            {organisationGroup.description || "Organisation group description"}
          </span>
          <ErrorContainer
            message={
              errors.find(e => e.code === OrganisationGroupValidationFailureCode.Description)
                ?.message
            }
            inputRef={errorRefCodeMappings[OrganisationGroupValidationFailureCode.Description]}
          >
            <Input
              className="access-group__input"
              placeholder="Please enter a description for your group"
              defaultValue={organisationGroup.description}
              onChange={e =>
                updateNewOrganisationGroup(
                  og => (og.description = (e.target as HTMLInputElement).value)
                )
              }
            />
          </ErrorContainer>
          <ErrorContainer
            message={
              errors.find(e => e.code === OrganisationGroupValidationFailureCode.AtLeastOneField)
                ?.message
            }
            inputRef={errorRefCodeMappings[OrganisationGroupValidationFailureCode.AtLeastOneField]}
          >
            <div className="access-group__multi-select-container">
              <div className="access-group__multi-select-container__inline-field-container">
                <div className="access-group__multi-select-container__inline-field-container__field">
                  <span className="access-group__multi-select-container__inline-field-container__field__title">
                    Select organisation types
                  </span>
                  <Select
                    classNamePrefix="access-group__select"
                    placeholder={selectPlaceholder}
                    components={customStyles}
                    options={organisationTypeOptions}
                    value={defaultOrganisationTypes}
                    onChange={onOrganisationTypesChanged}
                    isMulti
                    isSearchable
                  />
                </div>
                <div className="access-group__multi-select-container__inline-field-container__field">
                  <span className="access-group__multi-select-container__inline-field-container__field__title">
                    Select organisations
                  </span>
                  <Select
                    classNamePrefix="access-group__select"
                    placeholder="Please search..."
                    components={{
                      ...customStyles,
                      DropdownIndicator: () => null,
                    }}
                    options={organisationOptions}
                    value={defaultOrganisations}
                    onInputChange={setOrganisationSearchTerm}
                    onChange={onOrganisationChanged}
                    isMulti
                    isSearchable
                  />
                </div>
              </div>
              <div className="access-group__multi-select-container__inline-field-container">
                <div className="access-group__multi-select-container__inline-field-container__field">
                  <span className="access-group__multi-select-container__inline-field-container__field__title">
                    Select regions
                  </span>
                  <Select
                    classNamePrefix="access-group__select"
                    placeholder={selectPlaceholder}
                    components={customStyles}
                    options={regionOptions}
                    value={defaultRegions}
                    onChange={e =>
                      updateNewOrganisationGroup(og => (og.regions = toTildeSeparatedString(e)))
                    }
                    isMulti
                    isSearchable
                  />
                </div>
                <div className="access-group__multi-select-container__inline-field-container__field">
                  <span className="access-group__multi-select-container__inline-field-container__field__title">
                    Select System (ICS footprint)
                  </span>
                  <Select
                    classNamePrefix="access-group__select"
                    placeholder={selectPlaceholder}
                    components={customStyles}
                    options={icbOptions}
                    value={defaultICBs}
                    onChange={e =>
                      updateNewOrganisationGroup(og => (og.stps = toTildeSeparatedString(e)))
                    }
                    isMulti
                    isSearchable
                  />
                </div>
              </div>
            </div>
          </ErrorContainer>
          {organisationGroup.id && !Guid.isValid(organisationGroup.id) && (
            <Button
              className="access-group__save-button"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault()
                addToOrganisationGroupList(organisationGroup.id)
              }}
            >
              Add to organisation group list
            </Button>
          )}
          <hr className="access-group__horizontal-rule--tableau-group" />
          <ErrorContainer
            message={
              errors.find(e => e.code === OrganisationGroupValidationFailureCode.TableauAccessGroup)
                ?.message
            }
            inputRef={
              errorRefCodeMappings[OrganisationGroupValidationFailureCode.TableauAccessGroup]
            }
          >
            <BodyText className="access-group__select-tableau-group">
              Select the tableau access group:
            </BodyText>
            <span className="access-group__field-title">Select tableau access group</span>
            <Select
              classNamePrefix="access-group__select"
              placeholder={selectPlaceholder}
              components={customStyles}
              value={defaultTableauGroup}
              options={tableauGroupOptions}
              onChange={e =>
                e !== null &&
                e.value !== null &&
                updateNewOrganisationGroup(og => (og.tableauGroupId = e.value))
              }
              isSearchable
            />
          </ErrorContainer>
          <Button
            className="access-group__remove-button"
            onClick={() => removeAccessGroup(organisationGroup.id)}
          >
            Remove
          </Button>
        </>
      )}
    </div>
  )
})

export default AccessGroup
