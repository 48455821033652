import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import { BodyText, Col, Label } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useStores } from "../../../global/hooks"
import { TaskName } from "../../../global/enums"
import ProductViewRedirect from "../productViewRedirect"
import { ProductEditView } from "../../enums/enums"
import { copyTextToClipboard } from "../../../global/utils/clipboard"
import "./styles.scss"

const TaskListView = observer(() => {
  const { productStore } = useStores()
  const { taskList, modifiedTasks } = productStore
  const [redirectingToPublishPage, setRedirectingToPublishPage] = useState(false)
  const [linkActive, setLinkActive] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  useEffect(() => {
    productStore.getExternalAppLink()
  }, [])

  const handleCopyLink = async () => {
    await copyTextToClipboard(productStore.externalAppLink).then(() => setLinkCopied(true))
  }

  const indexOfFirstSecuritySetupTask = TaskName.AccessPermissions

  const infoText = productStore.published
    ? "Any changes you make will be available for users to see as soon as you save those changes."
    : "You must publish this product in order to make it available to registered users."
  const continueGuidance = productStore.published
    ? "If you wish to stop the product being visible to users in NHS England applications, you must 'unpublish' it. Click 'Continue' to go to the unpublish page."
    : "When you have completed both Product setup and Security setup, your product will be ready for publishing. Click ‘Continue’ to go to the publishing page."
  const TaskRow = props => {
    const { id, left = { content: {} }, right = { content: {} } } = props
    return (
      <div id={id} className="tasklistview__task-row">
        <div className="tasklistview__task-row__left-column">
          {left.content.type === "title" ? (
            <Label isPageHeading>{left.content.text}</Label>
          ) : (
            <>
              <Link as={RouterLink} to={left.content.action}>
                {left.content.text}
              </Link>
            </>
          )}
        </div>
        <div className="tasklistview__task-row__right-column">
          {_.isEmpty(right.content) ? null : (
            <div
              className={`tasklistview__status-indicator${
                right.content.class ? " " + right.content.class : ""
              }`}
            >
              {right.icon && (
                <span className="tasklistview__icon">
                  {productStore.published ? (
                    <i className="fas fa-check" />
                  ) : (
                    <i className="fas fa-times"></i>
                  )}
                </span>
              )}
              <span className="text">{right.content.text}</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  if (redirectingToPublishPage) {
    return <ProductViewRedirect view={ProductEditView.Publish} />
  }

  return (
    <Col className="tasklistview" width="full">
      <TaskRow
        left={{ content: { type: "title", text: productStore.product.name } }}
        right={{
          icon: true,
          content: {
            class: productStore.published ? "tasklistview__published" : "tasklistview__unpublished",
            text: productStore.published ? "published" : "not published",
          },
        }}
      />
      <div className="tasklistview__info-box">{infoText}</div>
      <Label isPageHeading className="tasklistview__sub-heading">
        Product setup
      </Label>
      {taskList.length > 0 ? (
        <div className="tasklistview__task-list">
          {taskList
            .filter(t => t.taskName < indexOfFirstSecuritySetupTask)
            .map(x => {
              const keyName = x.name.replace(/\s+/g, "-").toLowerCase()
              return (
                <TaskRow
                  key={keyName}
                  id={`${keyName}-task-row`}
                  left={{ content: { action: keyName, text: x.name } }}
                  right={{
                    icon: productStore.published,
                    content: productStore.published
                      ? modifiedTasks[keyName] && {
                          class: "tasklistview__task-published",
                          text: "changes published",
                        }
                      : {
                          class: x.complete ? "tasklistview__complete" : "tasklistview__incomplete",
                          text: x.complete ? "completed" : "incomplete",
                        },
                  }}
                />
              )
            })}
        </div>
      ) : (
        <hr />
      )}
      <Label isPageHeading className="tasklistview__sub-heading">
        Security setup
      </Label>
      {taskList.length > 0 ? (
        <div className="tasklistview__task-list">
          {taskList
            .filter(t => t.taskName >= indexOfFirstSecuritySetupTask)
            .map(x => {
              const keyName = x.name.replace(/\s+/g, "-").toLowerCase()
              return (
                <TaskRow
                  key={keyName}
                  id={`${keyName}-task-row`}
                  left={{ content: { action: keyName, text: x.name } }}
                  right={{
                    icon: productStore.published,
                    content: productStore.published
                      ? modifiedTasks[keyName] && {
                          class: "tasklistview__task-published",
                          text: "changes published",
                        }
                      : {
                          class: x.complete ? "tasklistview__complete" : "tasklistview__incomplete",
                          text: x.complete ? "completed" : "incomplete",
                        },
                  }}
                />
              )
            })}
        </div>
      ) : (
        <hr />
      )}

      <Label isPageHeading className="tasklistview__sub-heading">
        Registration link
      </Label>
      <BodyText className="tasklistview__publishlinktext">
        If you have a landing page for users to register directly to{" "}
        <span>{productStore.product.name}</span>, you need to direct users to this link when they
        click 'Register'.
      </BodyText>
      <BodyText className="tasklistview__publish-link-controls">
        <span
          className={`tasklistview__publishlink${
            linkActive ? "tasklistview__publishlink--active" : ""
          }`}
        >
          {productStore.externalAppLink.substring(
            productStore.externalAppLink.indexOf("https://") + 8,
            productStore.externalAppLink.length
          ).length > 24
            ? `${productStore.externalAppLink
                .substring(
                  productStore.externalAppLink.indexOf("https://") + 8,
                  productStore.externalAppLink.length
                )
                .substring(0, 22)}...`
            : productStore.externalAppLink.substring(
                productStore.externalAppLink.indexOf("https://") + 8,
                productStore.externalAppLink.length
              )}
        </span>
        <span
          className="tasklistview__copy-control"
          onClick={handleCopyLink}
          onMouseDown={() => setLinkActive(true)}
          onMouseUp={() => setLinkActive(false)}
          onMouseLeave={() => setLinkActive(false)}
        >
          <svg
            className="tasklistview__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#005EB8"
              fillRule="evenodd"
              d="M20.375 3.75H3.75v16.625H2V2h18.375v1.75zm-10.5 7h8.75v-.875h-8.75v.875zm0 2.625h8.75V12.5h-8.75v.875zm0 2.625h4.375v-.875H9.875V16zM23 14.588V5.5H5.5V23h8.915C17.183 23 23 16.68 23 14.588zM7.25 21.25v-14h14v6.6C21.25 17.444 16 16 16 16s1.328 5.25-2.308 5.25H7.25z"
            />
          </svg>
          <span>Copy to clipboard</span>
        </span>
        {linkCopied && (
          <span className="tasklistview__copied-flag">
            <svg
              className="nhsuk-icon nhsuk-icon__tick tasklistview__copiedflag__svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              fill="none"
            >
              <path
                strokeWidth="4"
                strokeLinecap="round"
                stroke="#007f3b"
                d="M18.4 7.8l-8.5 8.4L5.6 12"
              ></path>
            </svg>
            <span>Copied</span>
          </span>
        )}
      </BodyText>

      <Label isPageHeading className="sub-heading">
        {productStore.published ? "Unpublish" : "Publish"}
      </Label>
      <div className="tasklistview__task-list">
        <p className="publish-text">{continueGuidance}</p>
        <button
          className="nhsuk-button"
          style={{ width: "15.3125rem", height: "3.5625rem", marginBottom: "8.1875rem" }}
          onClick={() => setRedirectingToPublishPage(true)}
          disabled={
            !productStore.published && productStore.taskList.filter(x => !x.complete).length > 0
          }
        >
          Continue
        </button>
      </div>
      <br />
    </Col>
  )
})

export default TaskListView
