import React, { useContext } from "react"
import { NotificationContext } from "../../../global/context"
import "./styles.scss"

interface IEditModeControlsProps {
  onCancelEditMode: () => void
  onToggleAll: () => void
}

const EditModeControls = (props: IEditModeControlsProps) => {
  const { onCancelEditMode, onToggleAll } = props

  const {
    selectedNotificationEventIds,
    notificationEvents,
    onDeleteSelectedNotificationEventsRequested,
  } = useContext(NotificationContext)

  return (
    <div
      data-testid="notifications-page__edit-mode-controls"
      className="notifications-page__edit-mode-controls"
    >
      <button
        data-testid="notifications-page__edit-mode-controls__button__toggle-all"
        className="notifications-page__edit-mode-controls__button"
        onClick={onToggleAll}
      >
        {selectedNotificationEventIds.length === notificationEvents.length ? "Deselect" : "Select"}{" "}
        All
      </button>
      <button
        data-testid="notifications-page__edit-mode-controls__button__delete"
        className="notifications-page__edit-mode-controls__button"
        onClick={onDeleteSelectedNotificationEventsRequested}
        disabled={selectedNotificationEventIds.length === 0}
      >
        Delete
      </button>
      <button
        data-testid="notifications-page__edit-mode-controls__button__cancel"
        className="notifications-page__edit-mode-controls__button"
        onClick={onCancelEditMode}
      >
        Cancel
      </button>
    </div>
  )
}

export default EditModeControls
