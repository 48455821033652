import { AdminType } from "../enums"
import Application from "./application"
import { ApplicationScope } from "../../../global/enums/application"

export default class Admin {
  constructor(
    public name: string,
    public id: string,
    public email: string,
    public AdminsManagedApplications: Application[] = [],
    public adminType: AdminType = AdminType.Primary
  ) {
    this.setAdminType(adminType)
  }

  isAlreadyManagingApp = (id: string): [boolean, number] => {
    const appIndex = this.AdminsManagedApplications.findIndex(app => app.id === id)
    return [appIndex > -1, appIndex]
  }

  addManagedApplication = (application: Application) => {
    if (!this.AdminsManagedApplications.some(app => app.id === application.id)) {
      this.AdminsManagedApplications.push(application)
    }
  }

  removeApplication = (applicationId: string) => {
    const [isAlreadyManagingApp, appIndex] = this.isAlreadyManagingApp(applicationId)
    if (isAlreadyManagingApp) {
      this.AdminsManagedApplications.splice(appIndex, 1)
    }
  }

  removeAllApplications = () => {
    this.AdminsManagedApplications = []
  }

  setAdminType = (adminType: AdminType) => {
    switch (adminType) {
      case AdminType.Primary:
        this.AdminsManagedApplications.forEach(app => {
          app.setScope(ApplicationScope.All)
          app.removeAllRegions()
          app.adminCanChangeConfig(true)
        })
        break

      case AdminType.Secondary:
        this.AdminsManagedApplications.forEach(app => {
          app.setScope(ApplicationScope.Organisational)
        })
        break
    }
    this.adminType = adminType
  }
}
