import React from "react"
import { Col, BodyText, Container, Label } from "nhsuk-react-components"
import { NavLink } from "react-router-dom"

const UnauthorisedReportView = props => {
  const { reportId } = props

  return (
    <>
      <Container id="unauthorised-view-container">
        <Col width="three-quarters">
          <Label isPageHeading style={{ marginTop: "1.875rem" }}>
            You do not have permission to view this result.
          </Label>
          <BodyText>
            Please go to the{" "}
            <NavLink to={"/a-z?product=" + reportId}>NHS England applications A to Z</NavLink> to
            request access.
          </BodyText>
        </Col>
      </Container>
    </>
  )
}

export default UnauthorisedReportView
