import React from "react"

const SelectedNotificationIcon = () => {
  return (
    <svg
      data-testid="selected-notification-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#005EB8" />
      <mask
        id="mask0_6332_46746"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <circle cx="12" cy="12" r="12" fill="white" />
      </mask>
      <g mask="url(#mask0_6332_46746)">
        <rect width="24" height="24" fill="#005EB8" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15005 11.7002L9.75005 15.3002L17.85 7.2002L19.2 8.5502L9.75005 18.0002L4.80005 13.0502L6.15005 11.7002Z"
        fill="#606060"
      />
      <mask
        id="mask1_6332_46746"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="7"
        width="16"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.15005 11.7002L9.75005 15.3002L17.85 7.2002L19.2 8.5502L9.75005 18.0002L4.80005 13.0502L6.15005 11.7002Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_6332_46746)">
        <rect x="-1.19995" y="-1.19971" width="27.6" height="27.6" fill="white" />
      </g>
    </svg>
  )
}

export default SelectedNotificationIcon
