import { observer } from "mobx-react"
import { BodyText, Label } from "nhsuk-react-components"
import React, { createRef, useEffect, useMemo, useRef, useState } from "react"
import Select from "react-select"
import { Continuation } from ".."
import ErrorContainer from "../../../../../global/components/errorContainer"
import { AddNewIcon } from "../../../../../global/components/icons"
import ShowGuidanceLink from "../../../../../global/components/showGuidanceLink"
import ValidationErrorsSummary from "../../../../../global/components/validationErrorsSummary/validationErrorsSummary"
import { useStores } from "../../../../../global/hooks"
import { IValidationError } from "../../../../../global/interfaces/validation"
import { Guid } from "../../../../../global/types"
import { OrganisationGroupValidationFailureCode } from "../../validators/enums"
import { failureMessages } from "../../validators/messages"
import { AccessGroup } from "./components"
import CustomAccessViewContextComponent from "./context"

interface ICustomAccessViewProps {
  save: () => void
}

const CustomAccessView = observer((props: ICustomAccessViewProps) => {
  const { save } = props

  const { tableauAccessViewStore, organisationGroupStore } = useStores()

  const accessGroupIdRef = useRef(0)

  const [fallbackTableauAccessGroupId, setFallbackTableauAccessGroupId] = useState<string>(() => {
    const fallbackAccessGroupForApplication =
      tableauAccessViewStore.tableauAccess.tableauAccessGroups.find(
        x => !x.regionCode && x.tableauOrganisationGroupId === Guid.EmptyString
      )

    if (fallbackAccessGroupForApplication) {
      const foundGlobalTableauGroup = tableauAccessViewStore.tableauGroups.find(
        x => x.id === fallbackAccessGroupForApplication.tableauGroupId
      )

      if (foundGlobalTableauGroup) {
        return foundGlobalTableauGroup.id
      }
    }

    return ""
  })

  const [errors, setErrors] = useState<IValidationError[]>([])

  const errorRefCodeMappings: Record<number, React.RefObject<HTMLDivElement>> = {
    [OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup]:
      createRef<HTMLDivElement>(),
  }

  const addNewAccessGroup = () => {
    organisationGroupStore.setOrganisationGroupsForApplication(
      [...organisationGroupStore.organisationGroupsForApplication].concat({
        id: accessGroupIdRef.current.toString(),
        description: "",
        shortName: "",
        organisationTypes: "",
        organisations: "",
        regions: "",
        stps: "",
        tableauGroupId: "",
      })
    )
    accessGroupIdRef.current++
  }

  useEffect(() => {
    const organisationGroupIdsForApplication =
      tableauAccessViewStore.tableauAccess.tableauAccessGroups
        .filter(tag => tag.tableauOrganisationGroupId !== Guid.EmptyString)
        .map(x => x.tableauOrganisationGroupId)

    const existingOrganisationGroupsForApplication =
      organisationGroupStore.organisationGroupsForApplication.map(x => x.id)

    const missingOrganisationGroupIds = organisationGroupIdsForApplication.filter(
      x => !existingOrganisationGroupsForApplication.includes(x)
    )

    Promise.all(missingOrganisationGroupIds.map(organisationGroupStore.getOrganisationGroup)).then(
      groups => {
        organisationGroupStore.setOrganisationGroupsForApplication(
          groups.map(g => {
            const tableauAccessGroupId =
              tableauAccessViewStore.tableauAccess.tableauAccessGroups.find(
                tag => tag.tableauOrganisationGroupId === g.id
              )?.id || ""
            return {
              ...g,
              tableauAccessGroupId: tableauAccessGroupId,
            }
          })
        )
      }
    )
  }, [])

  const tableauAccessGroupsOptions = useMemo(() => {
    return tableauAccessViewStore.tableauGroups.map(tg => {
      return { value: tg.id, label: tg.name }
    })
  }, [tableauAccessViewStore.tableauGroups])

  const saveAndReturnToTaskList = () => {
    const results = organisationGroupStore.organisationGroupsForApplication.map(
      organisationGroupStore.validateOrganisationGroupsForSaveAndReturn
    )

    if (!results.some(success => !success)) {
      saveInternal()
    }
  }

  const completeAndSave = async () => {
    if (!fallbackTableauAccessGroupId) {
      setErrors([
        {
          code: OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup,
          message:
            failureMessages[OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup],
        },
      ])
      return
    }

    if (organisationGroupStore.organisationGroupsForApplication.length === 0) {
      setErrors([
        {
          code: OrganisationGroupValidationFailureCode.NoOrganisationGroups,
          message: failureMessages[OrganisationGroupValidationFailureCode.NoOrganisationGroups],
        },
      ])
      return
    }

    if (await organisationGroupStore.validateOrganisationGroupsForPublish()) {
      saveInternal()
    }
  }

  const saveInternal = () => {
    Promise.all(
      organisationGroupStore.organisationGroupsForApplication.map(og => {
        return new Promise<{ organisationGroupId: string; tableauGroupId: string }>(
          (resolve, reject) => {
            organisationGroupStore.saveOrganisationGroup(og).then(savedOrganisationGroup => {
              if (!savedOrganisationGroup.id) {
                reject()
                return
              }

              resolve({
                organisationGroupId: savedOrganisationGroup.id,
                tableauGroupId: og.tableauGroupId,
              })
            })
          }
        )
      })
    )
      .then(tuples => {
        let tableauAccessGroupsCopy = [...tableauAccessViewStore.tableauAccess.tableauAccessGroups]

        const fallbackTableauGroup = tableauAccessViewStore.tableauGroups.find(
          tg => tg.id === fallbackTableauAccessGroupId
        )
        if (fallbackTableauGroup) {
          const fallbackObject = {
            id: null,
            tableauGroupId: fallbackTableauGroup.id,
            tableauGroupName: fallbackTableauGroup.name,
            regionCode: null,
            tableauOrganisationGroupId: Guid.EmptyString,
          }

          const existingFallbackTableauAccessGroupIdx = tableauAccessGroupsCopy.findIndex(
            x => !x.regionCode && x.tableauOrganisationGroupId === Guid.EmptyString
          )

          if (existingFallbackTableauAccessGroupIdx !== -1) {
            tableauAccessGroupsCopy.splice(existingFallbackTableauAccessGroupIdx)
          }

          tableauAccessGroupsCopy = tableauAccessGroupsCopy.concat(fallbackObject)
        }

        for (const { organisationGroupId, tableauGroupId } of tuples) {
          const foundTableauGroup = tableauAccessViewStore.tableauGroups.find(
            tg => tg.id === tableauGroupId
          )

          if (!foundTableauGroup) {
            continue
          }

          tableauAccessGroupsCopy = tableauAccessGroupsCopy.concat({
            id: null,
            tableauGroupId: foundTableauGroup.id,
            tableauGroupName: foundTableauGroup.name,
            regionCode: null,
            tableauOrganisationGroupId: organisationGroupId,
          })
        }

        tableauAccessViewStore.tableauAccess.tableauAccessGroups = tableauAccessGroupsCopy
      })
      .finally(() => {
        save()
      })
  }

  const onTableauAccessGroupChanged = (accessGroupId: string) => {
    setFallbackTableauAccessGroupId(accessGroupId)
    setErrors([])
  }

  const defaultTableauAccessGroup = useMemo(() => {
    const foundGlobalTableauGroup = tableauAccessViewStore.tableauGroups.find(
      x => x.id === fallbackTableauAccessGroupId
    )

    if (foundGlobalTableauGroup) {
      const { id, name } = foundGlobalTableauGroup
      return {
        label: name,
        value: id,
      }
    }

    return undefined
  }, [fallbackTableauAccessGroupId, tableauAccessViewStore.tableauGroups])

  return (
    <>
      <hr className="tableau-access-view__access-form__horizontal-rule--dark" />
      <Label className="tableau-access-view__access-form__custom-model-title">
        Custom Tableau access groups
      </Label>
      <BodyText className="tableau-access-view__access-form__custom-model-instructions">
        You can create a custom Tableau access group using any combination of: Organisation Type,
        Organisation, Region and System. Note that selecting an Organisation Type will restrict the
        list of organisations eligible for access to the organisation type chosen. You can save the
        Access Group for future use by giving it a name and a description and clicking 'Add to
        organisation group list'.
      </BodyText>
      <ValidationErrorsSummary errors={errors} errorRefCodeMappings={errorRefCodeMappings} />
      <div className="tableau-access-view__access-form__access-forms">
        <CustomAccessViewContextComponent>
          {organisationGroupStore.organisationGroupsForApplication.map((organisationGroup, idx) => {
            return (
              <AccessGroup
                key={organisationGroup.id}
                id={idx + 1}
                organisationGroup={organisationGroup}
              />
            )
          })}
        </CustomAccessViewContextComponent>
      </div>
      <hr className="tableau-access-view__access-form__horizontal-rule--add-new-organisation-group-before" />
      <div className="tableau-access-view__access-form__add-new-organisation-group">
        <AddNewIcon />
        <a
          className="tableau-access-view__access-form__add-new-organisation-group__button"
          onClick={addNewAccessGroup}
        >
          {`Add ${
            organisationGroupStore.organisationGroupsForApplication.length > 0 ? "another" : "an"
          } access group`}
        </a>
      </div>
      <hr className="tableau-access-view__access-form__horizontal-rule--add-new-organisation-group" />
      <BodyText className="tableau-access-view__access-form__select-tableau-group-not-mentioned">
        Tableau access group for exceptions
      </BodyText>
      <ShowGuidanceLink linkText="more guidance">
        <span>
          Once you have selected the application access group characteristics, or selected an
          existing access group, then you can choose a Tableau access group to related to this
          access group using the dropdown. Any user matching these characteristics will then be
          assigned to that specific Tableau access Group.
        </span>
      </ShowGuidanceLink>
      <ErrorContainer
        message={
          errors.find(
            e => e.code === OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup
          )?.message || null
        }
        inputRef={
          errorRefCodeMappings[OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup]
        }
      >
        <span className="tableau-access-view__access-form__field-title">Select access group</span>
        <Select
          classNamePrefix="tableau-access-view__access-form__select"
          placeholder="Please select..."
          options={tableauAccessGroupsOptions}
          defaultValue={defaultTableauAccessGroup}
          onChange={e => e && onTableauAccessGroupChanged(e.value)}
        />
      </ErrorContainer>
      <hr className="tableau-access-view__access-form__horizontal-rule--final" />
      <Continuation
        isEditMode
        completeAndSave={completeAndSave}
        saveAndReturn={saveAndReturnToTaskList}
      />
    </>
  )
})

export default CustomAccessView
