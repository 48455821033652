import React from "react"
import ProductCard from "../../../productCard/ProductCard"
import PropTypes from "prop-types"
import "./ProductsList.scss"

const ProductsList = props => {
  const {
    alphabet,
    listOfProducts,
    toggleApplicationFavourite,
    display,
    productParam,
    selectedCardRef,
  } = props

  return (
    display && (
      <div className="az-page__products-list__container" id={alphabet}>
        <div className="az-page__products-list__letter__container">{alphabet}</div>
        <div className="az-page__products-list__card__container">
          {listOfProducts &&
            listOfProducts.map(productData => (
              <ProductCard
                key={productData.id}
                data={productData}
                toggleApplicationFavourite={toggleApplicationFavourite}
                productParam={productParam}
                selectedCardRef={selectedCardRef}
              />
            ))}
        </div>
      </div>
    )
  )
}
ProductsList.propTypes = {
  alphabet: PropTypes.string.isRequired,
  listOfProducts: PropTypes.array.isRequired,
  toggleApplicationFavourite: PropTypes.func,
  display: PropTypes.bool.isRequired,
}
export default ProductsList
