import { AxiosResponse } from "axios"
import { makeAutoObservable } from "mobx"
import { IRegistrationResponse } from "../../global/interfaces/registration"
import { ISecurityPasswordForm } from "../../global/interfaces/security"
import { post } from "../../global/utils/api"

export interface ISecurityPasswordFormStore {
  form: ISecurityPasswordForm
  save: (formData: ISecurityPasswordForm) => Promise<AxiosResponse<IRegistrationResponse>>
}

export class SecurityPasswordFormStore implements ISecurityPasswordFormStore {
  form: ISecurityPasswordForm = {
    registrationToken: "",
    password: "",
    confirmPassword: "",
    recoveryQuestion: "",
    recoveryAnswer: "",
  }

  constructor() {
    makeAutoObservable(this)
  }

  save = (formData: ISecurityPasswordForm): Promise<AxiosResponse<IRegistrationResponse>> => {
    return post<ISecurityPasswordForm>("/registration/complete", formData)
  }
}

export default new SecurityPasswordFormStore()
