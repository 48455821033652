import { observer } from "mobx-react"
import { BodyText, Button, Col, ErrorSummary, Form, Input, Label } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { ProductEditView } from "../../enums/enums"
import { LoaderContext } from "../../../global/components/loaderProvider"
import TextButton from "../../../global/components/textButton"
import { useStores } from "../../../global/hooks"
import { IContactInfo } from "../../../global/interfaces/contactInfo/interfaces"
import { IStringIndexableType } from "../../../global/interfaces/dataTypes/interfaces"
import ProductViewRedirect from "../productViewRedirect"
import "./styles.scss"

const mapBEFieldsToIds: IStringIndexableType<string> = {
  ProductOwnerName: "form-product-owner-name",
  ProductOwnerEmail: "form-product-owner-email",
  TechnicalSupportName: "form-technical-support-name",
  TechnicalSupportEmail: "form-technical-support-email",
  UserSupportEmail: "form-user-support-email",
}

const ContactInformationView = observer(() => {
  const { productStore, contactInfoStore } = useStores()

  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [contactInformation, setContactInformation] = useState<IContactInfo | null>(null)

  const { getContactInformation, saveContactInformation, errors, resetState } = contactInfoStore

  useEffect(() => {
    wrapWithLoader(async () => {
      setContactInformation(await getContactInformation(productStore.product.id || ""))
    })

    return () => {
      resetState()
    }
  }, [])

  useEffect(() => setIsEditMode(!productStore.published), [productStore.published])

  const completeAndSave = () => {
    if (contactInformation) {
      contactInformation.complete = true
      save()
    }
  }

  const returnAndSave = () => {
    if (contactInformation) {
      contactInformation.complete = false
      save()
    }
  }

  const save = () => {
    if (contactInformation) {
      const payload: IContactInfo = { ...contactInformation }

      saveContactInformation(payload).then(() => {
        productStore.setTaskModified(ProductEditView.Contact, payload.complete)
        setRedirectingToTaskList(true)
      })
    }
  }

  const updateContactInformation = (func: (contactInfo: IContactInfo) => void) => {
    if (contactInformation) {
      const contactInfoCopy: IContactInfo = { ...contactInformation }
      func(contactInfoCopy)
      setContactInformation(contactInfoCopy)
    }
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <>
      <Col id="contact-information-view" width="full">
        {isEditMode && errors.length > 0 && (
          <ErrorSummary role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
            {errors.map((kvp, idx) => (
              <ErrorSummary.Item key={`${kvp.key}-${idx}`} href={`#${mapBEFieldsToIds[kvp.key]}`}>
                {errors.getFirstSummaryError(kvp.key)}
              </ErrorSummary.Item>
            ))}
          </ErrorSummary>
        )}
        <div className="page-heading">
          <Label isPageHeading>Contact information</Label>
          {!isEditMode && (
            <TextButton id="change-settings-button" onClick={() => setIsEditMode(true)}>
              Change settings
            </TextButton>
          )}
        </div>
        {!isEditMode ? (
          <>
            <Label className="readonly-row-section-title">Product Owner details</Label>
            <div className="readonly-row">
              <div className="title-text">Name</div>
              <div className="contact-info-text">{contactInformation?.productOwnerName}</div>
            </div>
            <div className="readonly-row">
              <div className="title-text">Email address</div>
              <div className="contact-information-view__email">
                {contactInformation?.productOwnerEmail}
              </div>
            </div>
            <br />
            <br />
            <Label className="readonly-row-section-title">Technical support details</Label>
            <div className="readonly-row">
              <div className="title-text">Name</div>
              <div className="contact-info-text">{contactInformation?.technicalSupportName}</div>
            </div>
            <div className="readonly-row">
              <div className="title-text">Email address</div>
              <div className="contact-information-view__email">
                {contactInformation?.technicalSupportEmail}
              </div>
            </div>
            <br />
            <br />
            <Label className="readonly-row-section-title">User support details</Label>
            <div className="readonly-row">
              <div className="title-text">Email address</div>
              <div className="contact-information-view__email">
                {contactInformation?.userSupportEmail}
              </div>
            </div>
          </>
        ) : (
          <>
            <Form className="contact-info-form-group" id={mapBEFieldsToIds.ProductOwnerName}>
              <Input
                id="contact-information-productownername"
                className="input-field"
                label="Product Owner name"
                hint="The person ultimately responsible for the product."
                value={contactInformation?.productOwnerName || ""}
                onChange={e =>
                  updateContactInformation(
                    c => (c.productOwnerName = (e.target as HTMLInputElement).value)
                  )
                }
                error={errors.getFirstFieldError("ProductOwnerName")}
              />
            </Form>
            <Form className="contact-info-form-group" id={mapBEFieldsToIds.ProductOwnerEmail}>
              <Input
                id="contact-information-productowneremail"
                className="input-field"
                label="Product Owner email address"
                value={contactInformation?.productOwnerEmail || ""}
                onChange={e =>
                  updateContactInformation(
                    c => (c.productOwnerEmail = (e.target as HTMLInputElement).value)
                  )
                }
                error={errors.getFirstFieldError("ProductOwnerEmail")}
              />
            </Form>
            <Form className="contact-info-form-group" id={mapBEFieldsToIds.TechnicalSupportName}>
              <Input
                id="contact-information-technicalsupportname"
                className="input-field"
                label="Technical support contact name"
                hint="The person who can answer questions about the product setup."
                value={contactInformation?.technicalSupportName || ""}
                onChange={e =>
                  updateContactInformation(
                    c => (c.technicalSupportName = (e.target as HTMLInputElement).value)
                  )
                }
                error={errors.getFirstFieldError("TechnicalSupportName")}
              />
            </Form>
            <Form className="contact-info-form-group" id={mapBEFieldsToIds.TechnicalSupportEmail}>
              <Input
                id="contact-information-technicalsupportemail"
                className="input-field"
                label="Technical support email address"
                value={contactInformation?.technicalSupportEmail || ""}
                onChange={e =>
                  updateContactInformation(
                    c => (c.technicalSupportEmail = (e.target as HTMLInputElement).value)
                  )
                }
                error={errors.getFirstFieldError("TechnicalSupportEmail")}
              />
            </Form>
            <Form className="contact-info-form-group" id={mapBEFieldsToIds.UserSupportEmail}>
              <Input
                id="contact-information-usersupportemail"
                className="input-field"
                label="User support email address"
                hint="An email address given to users seeking support for queries other than technical problems. It may be the address of a help desk, or a specific individual."
                value={contactInformation?.userSupportEmail || ""}
                onChange={e =>
                  updateContactInformation(
                    c => (c.userSupportEmail = (e.target as HTMLInputElement).value)
                  )
                }
                error={errors.getFirstFieldError("UserSupportEmail")}
              />
            </Form>
          </>
        )}
        {isEditMode &&
          (productStore.published ? (
            <div id="published-save-control">
              <Label className="input-label">Publish changes</Label>
              <BodyText className="input-hint">
                By clicking 'Save and publish', you confirm that you are making these changes in
                line with business policies.
              </BodyText>
              <Button id="publish-button" onClick={completeAndSave}>
                Save and publish
              </Button>
            </div>
          ) : (
            <div id="button-controls">
              <Button id="publish-button" onClick={completeAndSave}>
                Save and complete
              </Button>
              <TextButton className="return-link" onClick={returnAndSave}>
                Save and return to task list
              </TextButton>
            </div>
          ))}
      </Col>
    </>
  )
})

export default ContactInformationView
