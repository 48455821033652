import { Label } from "nhsuk-react-components"
import React from "react"
import { TableauGroupDropdown } from "../"
import { useStores } from "../../../../../global/hooks"
import { ITableauAccessGroup } from "../../../../../global/interfaces/tableau/interfaces"

interface IStandardAccessViewProps {
  setAccessGroup: (val: string, id: string | null) => void
  accessGroup: ITableauAccessGroup | null
}

const StandardAccessView = (props: IStandardAccessViewProps) => {
  const { setAccessGroup, accessGroup } = props

  const { tableauAccessViewStore } = useStores()

  return (
    <>
      <Label className="tableau-access-view__access-form__section-title">
        Standard Tableau access group
      </Label>
      <TableauGroupDropdown
        forwardedError={tableauAccessViewStore.errors.standard}
        label="Access group"
        defaultValue={accessGroup?.tableauGroupId}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          setAccessGroup((e.target as HTMLSelectElement).value, accessGroup ? accessGroup.id : null)
        }
      />
    </>
  )
}

export default StandardAccessView
