import React from "react"
import PropTypes from "prop-types"
import "./DeniedAccess.scss"

const DeniedAccess = props => {
  const { date } = props
  const dateSliced = new Date(date).toString().split(" ")
  const dateDenied = `${dateSliced[2]} ${dateSliced[1]} ${dateSliced[3]}`
  return (
    <div className="az-page__denied-access__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="#da291c"
          d="M17 18.5c-.4 0-.8-.1-1.1-.4l-10-10c-.6-.6-.6-1.6 0-2.1.6-.6 1.5-.6 2.1 0l10 10c.6.6.6 1.5 0 2.1-.3.3-.6.4-1 .4z"
        />
        <path
          fill="#da291c"
          d="M7 18.5c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l10-10c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-10 10c-.3.3-.6.4-1 .4z"
        />
      </svg>
      <div className="az-page__denied-access__text">Request Declined, {dateDenied}</div>
    </div>
  )
}

DeniedAccess.propTypes = {
  date: PropTypes.string.isRequired,
}

export default DeniedAccess
