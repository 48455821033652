import { AxiosResponse, CancelTokenSource } from "axios"
import { HttpStatusCode } from "../enums/api"
import { IJSendResponse } from "../interfaces/api/interfaces"
import { ISearchResults } from "../interfaces/searchResults/interfaces"
import { post } from "../utils/api"

export interface ISearchStore {
  getSearchResults: (
    searchParams: string,
    cancelTokenSource: CancelTokenSource
  ) => Promise<ISearchResults>
}

class SearchStore implements ISearchStore {
  getSearchResults = (searchParams: string, cancelTokenSource: CancelTokenSource) => {
    return new Promise<ISearchResults>((resolve, reject) => {
      post<null, AxiosResponse<IJSendResponse<ISearchResults>>>(
        `/Search${searchParams}`,
        null,
        { cancelToken: cancelTokenSource.token },
        true
      )
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            resolve(res.data.data)
          }

          reject()
        })
        .catch(reject)
    })
  }
}

export default new SearchStore()
