import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../enums/api"
import { IOrganisationGroup } from "../interfaces/organisation"
import { deleteApi, get, post } from "../utils/api"
import { AxiosResponse } from "axios"
import {
  AddToGroupListValidator,
  PublishOrganisationGroupValidator,
  SaveAndReturnValidator,
} from "../../product/components/tableauAccessView/validators"
import { IValidationError } from "../interfaces/validation"
import { Guid } from "../types"

export interface IOrganisationGroupStore {
  errorsByAccessGroupId: Record<string, IValidationError[]>
  allOrganisationGroups: IOrganisationGroup[]
  organisationGroupsForApplication: IOrganisationGroup[]
  setAllOrganisationGroups: (organisationGroups: IOrganisationGroup[]) => void
  setOrganisationGroupsForApplication: (organisationGroups: IOrganisationGroup[]) => void
  getAllOrganisationGroups: () => Promise<IOrganisationGroup[]>
  getOrganisationGroup: (organisationGroupId: string) => Promise<IOrganisationGroup>
  saveOrganisationGroup: (organisationGroup: IOrganisationGroup) => Promise<IOrganisationGroup>
  deleteOrganisationGroup: (organisationGroupId: string) => Promise<void>
  validateOrganisationGroupForList: (organisationGroup: IOrganisationGroup) => boolean
  validateOrganisationGroupsForPublish: () => boolean
  deleteErrorsByAccessGroupId: (accessGroupId: string | null) => void
  validateOrganisationGroupsForSaveAndReturn: () => boolean
  resetState: () => void
}

const controllerPrefix = "/organisationgroup/"

const addToGroupListValidator = new AddToGroupListValidator(),
  publishOrganisationGroupValidator = new PublishOrganisationGroupValidator(),
  saveAndReturnValidator = new SaveAndReturnValidator()

class OrganisationGroupStore implements IOrganisationGroupStore {
  constructor() {
    makeAutoObservable(this)
  }

  errorsByAccessGroupId: Record<string, IValidationError[]> = {}

  allOrganisationGroups: IOrganisationGroup[] = []

  organisationGroupsForApplication: IOrganisationGroup[] = []

  setAllOrganisationGroups = (organisationGroups: IOrganisationGroup[]) => {
    this.allOrganisationGroups = organisationGroups
  }

  setOrganisationGroupsForApplication = (organisationGroups: IOrganisationGroup[]) => {
    this.organisationGroupsForApplication = organisationGroups
  }

  getAllOrganisationGroups = (): Promise<IOrganisationGroup[]> => {
    return new Promise<IOrganisationGroup[]>((resolve, reject) => {
      get<IOrganisationGroup[]>(`${controllerPrefix}all`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          this.allOrganisationGroups = res.data
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getOrganisationGroup = (organisationGroupId: string): Promise<IOrganisationGroup> => {
    return new Promise<IOrganisationGroup>((resolve, reject) => {
      get<IOrganisationGroup>(`${controllerPrefix}${organisationGroupId}`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  saveOrganisationGroup = (organisationGroup: IOrganisationGroup): Promise<IOrganisationGroup> => {
    const organisationGroupWithCorrectedId = {
      ...organisationGroup,
      id: Guid.isValid(organisationGroup.id) ? organisationGroup.id : null,
    }
    return new Promise<IOrganisationGroup>((resolve, reject) => {
      post<IOrganisationGroup, AxiosResponse<IOrganisationGroup>>(
        controllerPrefix,
        organisationGroupWithCorrectedId
      ).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  deleteOrganisationGroup = (organisationGroupId: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      deleteApi(`${controllerPrefix}${organisationGroupId}`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve()
        }

        reject()
      })
    })
  }

  validateOrganisationGroupsForSaveAndReturn = (): boolean => {
    const errors: Record<string, IValidationError[]> = {}
    const results = this.organisationGroupsForApplication.map(og => {
      const validationFailures = saveAndReturnValidator.run(og).filter(x => !x.success)

      if (validationFailures.length > 0 && og.id) {
        const errorsArr = []

        for (const vf of validationFailures) {
          if (vf.code !== undefined && vf.message) {
            errorsArr.push({
              code: vf.code,
              message: vf.message,
            })
          }
        }

        errors[og.id] = errorsArr
      }

      return validationFailures
    })

    const hasErrors = results.reduce((a, b) => a.concat(b)).length > 0
    if (hasErrors) {
      this.setErrors(errors)
    }

    return !hasErrors
  }

  validateOrganisationGroupForList = (organisationGroup: IOrganisationGroup): boolean => {
    const validationFailures = addToGroupListValidator
      .run(organisationGroup)
      .filter(vr => !vr.success)
    if (validationFailures.length > 0) {
      const mappedErrors: {
        code: number
        message: string
      }[] = []
      for (const validationError of validationFailures) {
        if (validationError.code !== undefined && validationError.message) {
          mappedErrors.push({
            code: validationError.code,
            message: validationError.message,
          })
        }
      }
      this.setErrorsByAccessGroupId(organisationGroup.id, mappedErrors)
      return false
    }

    this.deleteErrorsByAccessGroupId(organisationGroup.id)
    return true
  }

  validateOrganisationGroupsForPublish = (): boolean => {
    const errors: Record<string, IValidationError[]> = {}
    const validationResults = this.organisationGroupsForApplication.map(o => {
      const validationErrors = publishOrganisationGroupValidator.run(o).filter(r => !r.success)

      if (validationErrors.length > 0 && o.id) {
        const errorArr: {
          message: string
          code: number
        }[] = []

        for (const e of validationErrors) {
          if (e.code !== undefined && e.message) {
            errorArr.push({
              message: e.message,
              code: e.code,
            })
          }
        }

        errors[o.id] = errorArr
      }

      return validationErrors
    })

    const hasNoErrors = validationResults.reduce((a, b) => a.concat(b)).length === 0
    if (!hasNoErrors) {
      this.setErrors(errors)
    }

    return hasNoErrors
  }

  deleteErrorsByAccessGroupId = (accessGroupId: string | null) => {
    if (!accessGroupId) {
      return
    }

    const errorsCopy = { ...this.errorsByAccessGroupId }
    delete errorsCopy[accessGroupId]
    this.setErrors(errorsCopy)
  }

  private setErrorsByAccessGroupId = (accessGroupId: string | null, errors: IValidationError[]) => {
    if (!accessGroupId) {
      return
    }

    const errorsCopy = { ...this.errorsByAccessGroupId }
    errorsCopy[accessGroupId] = errors
    this.setErrors(errorsCopy)
  }

  private setErrors = (errors: Record<string, IValidationError[]>) => {
    this.errorsByAccessGroupId = errors
  }

  resetState = () => {
    this.organisationGroupsForApplication = []
    this.allOrganisationGroups = []
    this.errorsByAccessGroupId = {}
  }
}

export default new OrganisationGroupStore()
