import Axios, { AxiosResponse, Canceler } from "axios"
import { makeAutoObservable } from "mobx"
import {
  IApplicationTile,
  IApplicationTilePage,
  IApplicationTotals,
  IAzureAdPreFlight,
} from "../../global/interfaces/application/interfaces"
import { ICategory } from "../../global/interfaces/category/interfaces"
import { get, postApi } from "../../global/utils/api"
import { HttpStatusCode } from "../enums/api"

const CancelToken = Axios.CancelToken
let cancel: Canceler

interface ITab {
  name: string
  key: string
}

interface IPage {
  current: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  total: number
}

interface ITotals {
  Recommended: number
  Favourites: number
  All: number
}

export interface IAppGalleryStore {
  tabs: ITab[]
  totals: ITotals
  pages: IPage
  categories: ICategory[]
  applications: IApplicationTile[]
  quicklist: IApplicationTile[]
  selectedCategory: string
  selectedTab: string
  getPage: number
  userAlreadyExists: IAzureAdPreFlight | null
  pwValue: string
  udalError: string
  requestAppAccess: (id: string) => Promise<void>
  toggleFavourite: (id: string, refresh?: boolean) => Promise<void>
}

class AppGalleryStore implements IAppGalleryStore {
  constructor() {
    makeAutoObservable(this)
  }

  quicklist: IApplicationTile[] = []
  applications: IApplicationTile[] = []

  tabs = [
    { name: "Recommended", key: "R" },
    { name: "Favourites", key: "F" },
    { name: "All", key: "A" },
  ]

  totals = {
    Recommended: 0,
    Favourites: 0,
    All: 0,
  }

  pages = {
    current: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    total: 1,
  }

  categories: ICategory[] = []
  filteredCategories = []
  selectedCategory = ""
  selectedTab = "A"
  getPage = 1
  userAlreadyExists: IAzureAdPreFlight | null = null
  pwValue = ""
  udalError = ""

  resetPagination = (): void => {
    this.getPage = 1
    this.pages = {
      current: 1,
      total: 1,
      hasNextPage: true,
      hasPreviousPage: false,
    }
  }

  refreshData = (): Promise<boolean> => {
    this.resetPagination()
    this.getTotals()
    return this.getApplications()
  }

  getUDALPreFlight = (): Promise<boolean> => {
    return new Promise<boolean>(resolve =>
      get<IAzureAdPreFlight>("/application/azuread/preflight").then(res => {
        this.userAlreadyExists = res.data
        resolve(true)
      })
    )
  }

  createUdalIdentity = (appId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) =>
      postApi("/application/azuread/preauth", {
        applicationId: appId,
        password: this.pwValue,
      })
        .then(res => {
          if (res.status === 200) {
            resolve(true)
          }
          reject("Status code not equal to 200")
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.udalError = err.response.data
            reject()
          }

          reject("Unhandled error occurred when accessing product")
        })
    )
  }

  getQuicklist = (): void => {
    const url =
      this.totals.Favourites > 0
        ? "/application/page?category=&tabfilter=F&pagenumber=1"
        : "/application/page?category=&tabfilter=R&pagenumber=1" //this needs to change to show recommended instead of all
    get<IApplicationTilePage>(url).then(res => {
      this.quicklist = [...res.data.applicationTiles.filter((_, idx) => idx < 3)]
    })
  }

  setActiveTab = (tab: string): Promise<boolean> => {
    this.applications = []
    this.selectedTab = tab
    this.selectedCategory = ""
    this.getPage = 1
    cancel("Operation cancelled by refresh")
    this.getTotals()
    return this.getApplications()
  }

  handleCategoryChange = (id: string): Promise<boolean> => {
    this.selectedCategory = id
    this.getPage = 1
    cancel("Operation cancelled by refresh")
    return this.getApplications()
  }

  applicationsApiCall = (): Promise<AxiosResponse<IApplicationTilePage>> => {
    return get<IApplicationTilePage>(
      `/application/page?category=${this.selectedCategory}&tabfilter=${this.selectedTab}&pagenumber=${this.getPage}&ignorepagination=true`,
      {
        cancelToken: new CancelToken(c => (cancel = c)),
      }
    )
  }

  getApplications = (): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      this.applicationsApiCall()
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.applications = [...res.data.applicationTiles]
            this.pages = {
              current: res.data.pageNumber,
              hasNextPage: res.data.hasNextPage,
              hasPreviousPage: res.data.hasPreviousPage,
              total: res.data.totalPages,
            }
          }
        })
        .finally(() => resolve(true))
    })
  }

  loadMore = (): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      if (!this.pages.hasNextPage) {
        resolve(true)
        return
      }
      this.getPage++
      cancel("Operation cancelled by refresh")
      this.applicationsApiCall()
        .then(res => {
          if (res.status === HttpStatusCode.OK) {
            this.applications = [...this.applications, ...res.data.applicationTiles]
            this.pages = {
              current: res.data.pageNumber,
              hasNextPage: res.data.hasNextPage,
              hasPreviousPage: res.data.hasPreviousPage,
              total: res.data.totalPages,
            }
          }
        })
        .finally(() => resolve(true))
    })
  }

  getTotals = (): void => {
    get<IApplicationTotals>("/application/totals").then(res => {
      this.totals = {
        Recommended: res.data.recommendedTotal,
        Favourites: res.data.favouriteTotal,
        All: res.data.allTotal,
      }
      this.getQuicklist()
    })
  }

  getCategories = (): Promise<void> => {
    return new Promise<void>(resolve =>
      get<ICategory[]>("/category").then(res => {
        if (res.status === 200) {
          this.categories = [...res.data]
        }
        resolve()
      })
    )
  }

  toggleFavourite = (id: string, refresh = true): Promise<void> => {
    return new Promise<void>(resolve => {
      postApi(`/application/favourite?id=${id}`, null)
        .then(async () => {
          if (refresh) {
            await this.refreshData()
          }
          resolve()
        })
        .catch(() => resolve())
    })
  }

  requestAppAccess = (id: string): Promise<void> => {
    return new Promise<void>(resolve => {
      postApi(`/application/request?id=${id}`, {})
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(() => resolve())
    })
  }
}

export default new AppGalleryStore()
