import React from "react"

interface IPlusIconProps {
  height?: string
  width?: string
}

const PlusIcon: React.FC<IPlusIconProps> = props => {
  const { height = "1.5rem", width = "1.5rem" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="#005EB8"
        d="M25.473 15.915c-.359.358-.807.627-1.345.627H6.19c-1.077 0-1.974-.896-1.884-1.883 0-1.076.807-1.883 1.884-1.883h17.937c1.076 0 1.883.807 1.883 1.883 0 .538-.18.897-.538 1.256z"
      />
      <path
        fill="#005EB8"
        d="M16.504 24.883c-.358.359-.807.628-1.345.628-1.076 0-1.883-.807-1.883-1.883V5.69c0-1.077.807-1.884 1.883-1.884s1.883.807 1.883 1.884v17.937c0 .538-.179.896-.538 1.255z"
      />
    </svg>
  )
}

export default PlusIcon
