import React from "react"

import "./styles.scss"

interface IPaginationControl {
  current: number
  handlePageNavigation: (previous: boolean) => void
  hasNextPage: boolean
  hasPreviousPage: boolean
  total: number
}

const PaginationControl: React.FC<IPaginationControl> = props => {
  const { handlePageNavigation, hasPreviousPage, current, total, hasNextPage } = props

  return (
    <div className="pagination-control">
      <div className="pagination-control__previous">
        {hasPreviousPage && (
          <button
            data-testid="pagination__previous-button"
            className="pagination-control__button"
            onClick={() => handlePageNavigation(true)}
          >
            <i className="fas fa-long-arrow-alt-left pagination-control__icon"></i>
            &nbsp;
            <span>Previous</span>
          </button>
        )}
      </div>
      <div className="pagination-control__label">
        Page&nbsp;{current}&nbsp;of&nbsp;{total}
      </div>
      <div className="pagination-control__next">
        {hasNextPage && (
          <button
            data-testid="pagination__next-button"
            className="pagination-control__button"
            onClick={() => handlePageNavigation(false)}
          >
            <span>Next</span>
            &nbsp;
            <i className="fas fa-long-arrow-alt-right pagination-control__icon"></i>
          </button>
        )}
      </div>
    </div>
  )
}

export default PaginationControl
