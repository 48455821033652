import React from "react"

import "./productCardInfo.scss"

const ProductCardInfo = ({ onClick, children, text }) => {
  return (
    <div className="az-page__product-card__product-info" onClick={onClick}>
      <div className="az-page__product-card__product-info__icon">{children}</div>
      <div className="az-page__product-card__product-info__text">{text}</div>
    </div>
  )
}

export default ProductCardInfo
