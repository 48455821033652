import { ErrorSummary } from "nhsuk-react-components"
import React, { memo } from "react"
import { ICollection, IKeyValuePair } from "../../interfaces/dataTypes/interfaces"
import { IErrorBucket } from "../../interfaces/error"

interface IErrorsSummaryProps {
  errors: ICollection<IKeyValuePair<string, IErrorBucket[]>>
}

const ErrorsSummary = (props: IErrorsSummaryProps) => {
  const { errors } = props

  if (!errors || errors.length === 0) {
    return null
  }

  return (
    <ErrorSummary data-testid="error-summary" role="alert" tabIndex={-1}>
      <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
      <p className="error-summary-body" id="accountupdate--errors">
        Please correct the following errors
      </p>
      <ErrorSummary.List>
        {errors.map(err => {
          return err.value.map(inner => {
            return (
              <ErrorSummary.Item key={`${err.key}${inner.fieldError}`} href={`#${err.key}`}>
                {inner.summaryError}
              </ErrorSummary.Item>
            )
          })
        })}
      </ErrorSummary.List>
    </ErrorSummary>
  )
}

export default memo(ErrorsSummary)
