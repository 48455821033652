import { Label } from "nhsuk-react-components"
import React from "react"
import { Regions } from "../../../../../global/constants/region/constants"
import { useStores } from "../../../../../global/hooks"
import { IRegion } from "../../../../../global/interfaces/region/interfaces"
import { ITableauAccessGroup } from "../../../../../global/interfaces/tableau/interfaces"
import { TableauGroupDropdown } from "../"

interface IRegionalAccessViewProps {
  setRegionalAccessGroup: (region: string, val: string, id: string | null) => void
  accessByCode: Record<string, ITableauAccessGroup>
}

const RegionalAccessView = (props: IRegionalAccessViewProps) => {
  const { setRegionalAccessGroup, accessByCode } = props

  const nationalCode = Regions.National

  const { tableauAccessViewStore } = useStores()

  return (
    <>
      <Label>National Tableau access group</Label>
      <TableauGroupDropdown
        forwardedError={
          tableauAccessViewStore.errors.regional &&
          tableauAccessViewStore.errors.regional[nationalCode]
        }
        defaultValue={accessByCode[nationalCode] && accessByCode[nationalCode].tableauGroupId}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          setRegionalAccessGroup(
            nationalCode,
            (e.target as HTMLSelectElement).value,
            accessByCode[nationalCode] && accessByCode[nationalCode].id
          )
        }
      />
      <Label className="tableau-access-view__access-form__section-title">
        Regional Tableau access groups
      </Label>
      <div className="tableau-access-view__access-form__edit-row legend">
        <div className="tableau-access-view__access-form__edit-row__title-text">Region</div>
        <div className="tableau-access-view__access-form__edit-row__title-text">Access group</div>
      </div>
      {tableauAccessViewStore.regions
        .filter((region: IRegion) => region.code !== nationalCode)
        .map((region: IRegion) => (
          <div className="tableau-access-view__access-form__edit-row" key={region.code}>
            <div className="tableau-access-view__access-form__edit-row__title-text">
              {region.name} ({region.code})
            </div>
            <TableauGroupDropdown
              forwardedError={
                tableauAccessViewStore.errors.regional &&
                tableauAccessViewStore.errors.regional[region.code]
              }
              defaultValue={accessByCode[region.code] && accessByCode[region.code].tableauGroupId}
              onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                setRegionalAccessGroup(
                  region.code,
                  (e.target as HTMLSelectElement).value,
                  accessByCode[region.code] && accessByCode[region.code].id
                )
              }
            />
          </div>
        ))}
    </>
  )
}

export default RegionalAccessView
