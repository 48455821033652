import { observer } from "mobx-react"
import { BodyText, Input, Label } from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { IUpdatePasswordData } from "../../../global/stores/profileStore"
import { ErrorsCollection } from "../../../global/types"

interface ISetPasswordProps {
  form: IUpdatePasswordData
  error: ErrorsCollection
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  isUpdate: boolean
}

const SetPassword = observer((props: ISetPasswordProps) => {
  const { form, error, onChange, isUpdate } = props
  const [password, setPassword] = useState((form && form.password) || "")
  const [confirmedPassword, setConfirmedPassword] = useState((form && form.confirmPassword) || "")

  useEffect(() => {
    if (form.password !== password) {
      setPassword(form.password || "")
    }
  }, [form.password])

  useEffect(() => {
    if (form.confirmPassword !== confirmedPassword) {
      setConfirmedPassword(form.confirmPassword || "")
    }
  }, [form.confirmPassword])

  return (
    <>
      <Helmet>
        <title>Registration Set Password | NHS England applications</title>
      </Helmet>
      <Label htmlFor="password">{isUpdate ? "Set your new password" : "Set your password"}</Label>
      <BodyText>
        Your password must be at least 8 characters in length and contain each of the following: one
        lower case letter, one upper case letter, one number.
      </BodyText>
      <Input
        id="password"
        className="input-field"
        name="password"
        type="password"
        value={password}
        error={error.getFirstFieldError("password")}
        onChange={onChange}
        autoComplete="off"
      />

      <Label htmlFor="confirmPassword">
        {isUpdate ? "Repeat your new password" : "Repeat your password"}
      </Label>
      {!isUpdate && <BodyText>Enter your selected password again.</BodyText>}
      <Input
        id="confirmPassword"
        className="input-field"
        name="confirmPassword"
        type="password"
        value={confirmedPassword}
        error={error.getFirstFieldError("confirmPassword")}
        onChange={onChange}
        autoComplete="off"
      />
    </>
  )
})

export default SetPassword
