export const groupBy = <T, TProp>(
  collection: T[],
  predicate: (target: T) => TProp
): Map<TProp, T[]> => {
  const map: Map<TProp, T[]> = new Map<TProp, T[]>()

  for (const elem of collection) {
    const key = predicate(elem)

    const values = map.get(key)
    if (values) {
      map.set(key, values.concat(elem))
    } else {
      map.set(key, [elem])
    }
  }

  return map
}
