import React from "react"

const NotificationNavigationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6001_45755"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="white" />
      </mask>
      <g mask="url(#mask0_6001_45755)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 13.4665L16.6 13.4665L12.925 17.1998L14.5 18.7998L20.8 12.3998L14.5 5.9998L12.925 7.5998L16.6 11.3331L4 11.3331L4 13.4665Z"
          fill="#005EB8"
        />
      </g>
    </svg>
  )
}

export default NotificationNavigationIcon
