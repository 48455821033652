import React, { createContext, useState } from "react"

interface IProgressiveWebApplicationContext {
  isPwa: boolean
  setIsPwa: (isPwa: boolean) => void
}

export const ProgressiveWebApplicationContext = createContext<IProgressiveWebApplicationContext>({
  isPwa: false,
  setIsPwa: (_: boolean) => Function.prototype,
})

export interface IProgressiveWebApplicationProviderProps {
  children: React.ReactNode
}

const ProgressiveWebApplicationProvider = (props: IProgressiveWebApplicationProviderProps) => {
  const { children } = props

  const [isPwa, setIsPwa] = useState<boolean>(false)

  return (
    <ProgressiveWebApplicationContext.Provider
      value={{
        isPwa: isPwa,
        setIsPwa: setIsPwa,
      }}
    >
      {children}
    </ProgressiveWebApplicationContext.Provider>
  )
}

export default ProgressiveWebApplicationProvider
