import AdminStore from "../abstract/admin/adminStore"
import { makeObservable, action, observable } from "mobx"
import { ApplicationRequestStatus, ApplicationType } from "../enums/application"
import { IAdminStore } from "../interfaces/admin"
import { ICanModify, IManageApplicationUserTotals } from "../interfaces/application/interfaces"
import { IContactInfo } from "../interfaces/contactInfo/interfaces"
import { IOrganisationModel } from "../interfaces/organisation/interfaces"
import { IUserPage } from "../interfaces/pagination/interfaces"
import { IProductOwnerApplication } from "../interfaces/productOwner/interfaces"
import { IUserIdList } from "../interfaces/user/interfaces"
import { get, post, postApi } from "../utils/api"
import ContactInfoStore from "./contactInfoStore"

export interface IProductOwnerAdminStore extends IAdminStore<ApplicationRequestStatus> {
  selectedProductContactInfo: IContactInfo | null
  myProducts: IProductOwnerApplication[]
  selectedProductId: string | null
  selectedProductPermissions: ICanModify
  selectedProduct: IProductOwnerApplication | null
  getPageData: () => Promise<void>
  getMyProducts: () => Promise<IProductOwnerApplication[]>
}

class ProductOwnerAdminStore
  extends AdminStore<ApplicationRequestStatus>
  implements IProductOwnerAdminStore
{
  //#region Constructor + setup
  constructor() {
    super({
      list: {
        [ApplicationRequestStatus.Pending]: {
          name: "Requests",
          status: "pending",
          value: ApplicationRequestStatus.Pending,
          description: "",
        },
        [ApplicationRequestStatus.Approved]: {
          name: "Approved users",
          status: "approved",
          value: ApplicationRequestStatus.Approved,
          description: "",
        },
        [ApplicationRequestStatus.Rejected]: {
          name: "Declined requests",
          status: "rejected",
          value: ApplicationRequestStatus.Rejected,
          description: "",
        },
      },
      selected: ApplicationRequestStatus.Pending,
    })

    this.availableTabs = [
      ApplicationRequestStatus.Pending,
      ApplicationRequestStatus.Approved,
      ApplicationRequestStatus.Rejected,
    ]

    this.listTotals = {
      [ApplicationRequestStatus.Approved]: 0,
      [ApplicationRequestStatus.Pending]: 0,
      [ApplicationRequestStatus.Rejected]: 0,
    }
    // As this inherits from AdminStore we must use makeObservable and be explicit with properties
    // and methods, rather than using makeAutoObservable
    makeObservable(this, {
      getPageData: action,
      getTabTotals: action,
      approveList: observable,
      rejectList: observable,
      deprovisionList: observable,
      myProducts: observable,
      selectedProductId: observable,
    })
  }
  //#endregion

  //#region Change handlers
  handleOrgChange = (search: IOrganisationModel | null): Promise<IUserPage> =>
    super.handleOrgChange(search, this.refreshData)

  handleSearch = (search: string): Promise<IUserPage> =>
    super.handleSearch(search, this.refreshData)

  handleRegionChange = (region: string): Promise<IUserPage> =>
    super.handleRegionChange(region, this.refreshData)

  handleSortMainList = (sortVal: string): Promise<IUserPage> =>
    super.handleSortMainList(sortVal, this.refreshData)

  handleFilterJobRole = (role: string): Promise<IUserPage> =>
    super.handleFilterJobRole(role, this.refreshData)

  setActiveTab = (tabValue: ApplicationRequestStatus): Promise<IUserPage> =>
    super.setActiveTab(tabValue, this.refreshData)

  setSelected = (arr: string[]): void => super.setSelected(arr)

  setSelectedAll = (value: boolean): void => super.setSelectedAll(value)

  toggleSelectAll = (): void => super.toggleSelectAll()
  //#endregion

  //#region Store-specific properties
  selectedProductId: string | null = null
  selectedProductPermissions: ICanModify = { canModifyApplication: false }
  selectedProductContactInfo: IContactInfo | null = null
  myProducts: IProductOwnerApplication[] = []
  selectedProduct = null
  //#endregion

  refreshData = (): Promise<IUserPage> => {
    this.getProductPermissions()
    this.resetPagination()
    this.getTabTotals()
    this.getContactInfo()
    this.setFormattedDescriptions()
    return this.getMainList()
  }

  getPageData = async (): Promise<void> => {
    await this.getMyProducts()
    if (this.selectedProductId) {
      await Promise.all([this.getTabTotals(), this.getMainList(), this.getContactInfo()])
    }
  }

  getMainList = (): Promise<IUserPage> => {
    const org = this.selectedOrg ? this.selectedOrg.value : ""
    let query =
      `/user/page?applicationId=${this.selectedProductId}&pageNumber=${
        this.pages.current
      }&search=${encodeURIComponent(this.searchString)}` +
      `&sortOrder=${this.sortBy}&filter=${this.jobRole}&applicationRequestStatus=${this.tab.selected}` +
      `&region=${this.selectedRegion}`

    if (org) {
      query += `&organisation=${org}`
    }

    return super.getMainList(query)
  }

  getTabTotals = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<IManageApplicationUserTotals>(`/application/${this.selectedProductId}/totals`).then(
        res => {
          if (res.status === 200) {
            this.listTotals = {
              [ApplicationRequestStatus.Pending]: res.data.pendingUsers,
              [ApplicationRequestStatus.Approved]: res.data.activeUsers,
              [ApplicationRequestStatus.Rejected]: res.data.rejectedUsers,
            }
          }
          resolve()
        }
      )
    })
  }

  setFormattedDescriptions = (): void => {
    const defaultDisplay = "the application"
    const selectedProduct = this.myProducts.find(p => p.id === this.selectedProductId)
    const selectedProductName = this.myProducts.find(p => p.id === this.selectedProductId)
      ? this.myProducts.find(p => p.id === this.selectedProductId)?.name ?? defaultDisplay
      : defaultDisplay
    this.tab.list[
      ApplicationRequestStatus.Pending
    ].description = `This is a list of all the people awaiting approval to use ${selectedProductName}. \n You should aim to approve or decline access within two working days of the application. ${
      selectedProduct?.applicationType === ApplicationType.Foundry
        ? "\nAll requests for access to this Application will be actioned within Foundry"
        : ""
    }`
    this.tab.list[
      ApplicationRequestStatus.Approved
    ].description = `This is the list of all active users of ${selectedProductName}. \n If a user should no longer have access, you can remove their access.`
    this.tab.list[
      ApplicationRequestStatus.Rejected
    ].description = `This is a list of all requests that have been declined for ${selectedProductName} either manually or automatically. \n You can approve an account that has been declined by mistake.`
  }

  setActiveProduct = (id: string | null): Promise<IUserPage> => {
    this.clearUserSelections()
    this.selectedProductId = id
    return this.refreshData()
  }

  //#region Store-specific API calls
  refreshApplicationData = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      postApi("/application/refresh", null)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  getMyProducts = (): Promise<IProductOwnerApplication[]> => {
    return new Promise<IProductOwnerApplication[]>(resolve =>
      get<IProductOwnerApplication[]>("/application/productowner").then(res => {
        if (res.status === 200) {
          this.myProducts = res.data
          if (!this.selectedProductId && this.myProducts.length > 0) {
            const firstPublished = this.myProducts.find(x => x.published)
            this.selectedProductId = (firstPublished && firstPublished.id) || this.myProducts[0].id

            this.getProductPermissions()
            this.setFormattedDescriptions()
          }
        }
        resolve(res.data)
      })
    )
  }

  getProductPermissions = (): Promise<void> => {
    return new Promise<void>(resolve => {
      get<ICanModify>(`/application/${this.selectedProductId}/canmodify`)
        .then(res => {
          if (res.status === 200) {
            this.selectedProductPermissions = res.data
          } else {
            this.selectedProductPermissions = { canModifyApplication: false }
          }
          resolve()
        })
        .catch(() => (this.selectedProductPermissions = { canModifyApplication: false }))
    })
  }

  approveList = (array: string[], requestProcessReason: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>(`/application/${this.selectedProductId}/approve`, {
        userIdList: [...array],
        requestProcessReason: requestProcessReason,
      })
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(async err => reject(err))
    })
  }

  rejectList = (array: string[], requestProcessReason: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>(`/application/${this.selectedProductId}/reject`, {
        userIdList: [...array],
        requestProcessReason: requestProcessReason,
      })
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(err => reject(err))
    })
  }

  deprovisionList = (array: string[], requestProcessReason: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      post<IUserIdList>(`/application/${this.selectedProductId}/remove`, {
        userIdList: [...array],
        requestProcessReason: requestProcessReason,
      })
        .then(async () => {
          await this.refreshData()
          resolve()
        })
        .catch(err => reject(err))
    })
  }

  private getContactInfo = (): Promise<IContactInfo> => {
    return new Promise<IContactInfo>((resolve, reject) => {
      if (!this.selectedProductId) {
        return null
      } else {
        ContactInfoStore.getContactInformation(this.selectedProductId)
          .then(contactInfo => {
            this.selectedProductContactInfo = contactInfo
            resolve(contactInfo)
          })
          .catch(err => {
            this.selectedProductContactInfo = null
            reject(err)
          })
      }
    })
  }
  //#endregion
}

export default new ProductOwnerAdminStore()
