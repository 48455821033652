import { Checkboxes } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import Search from "../search/search"
import "./styles.scss"

const TagsSelectionModal = props => {
  const { tagsList, setShowTagsDialog, onTagSelectStateChanged, selectedTags, mockSelectRef } =
    props

  const [filter, setFilter] = useState("")
  const modalRef = useRef()

  useEffect(() => {
    const handleClick = event => {
      if (
        modalRef &&
        !modalRef.current.contains(event.target) &&
        mockSelectRef &&
        !mockSelectRef.current.contains(event.target)
      ) {
        setShowTagsDialog(false)
      }
    }

    window.addEventListener("click", handleClick)

    return () => window.removeEventListener("click", handleClick)
  }, [])

  const handleTagsSearch = event =>
    setFilter(event.target.value.length >= 2 ? event.target.value : "")

  const isFilterActive = filter && filter.trim().length > 0

  const matchesFilter = tag => {
    if (!isFilterActive) {
      return true
    }

    return tag.toUpperCase().indexOf(filter.toUpperCase()) > -1
  }

  return (
    <div className="tags-selection-modal" ref={modalRef}>
      <div className="tags-selection-modal__upper-container">
        <Search onChange={handleTagsSearch} placeholder="Search tags" />
      </div>
      <div className="tags-selection-modal__lower-container">
        <Checkboxes>
          {tagsList
            .filter(t => (isFilterActive && matchesFilter(t)) || !isFilterActive)
            .map((tag, index) => {
              return (
                <Checkboxes.Box
                  key={`${tag}-${index}`}
                  onChange={() => onTagSelectStateChanged(tag)}
                  defaultChecked={selectedTags.indexOf(tag) !== -1}
                >
                  {tag}
                </Checkboxes.Box>
              )
            })}
        </Checkboxes>
      </div>
    </div>
  )
}

export default TagsSelectionModal
