import React from "react"
import "./styles.scss"

interface IErrorContainerProps {
  message: string | null | undefined
  inputRef?: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}

const ErrorContainer = (props: IErrorContainerProps) => {
  const { message, inputRef, children } = props

  if (!message) {
    return <>{children}</>
  }

  return (
    <div data-testid="error-container" className="error-container" ref={inputRef}>
      <span className="error-container__title">
        <span className="error-container__title__hidden-span">Error:</span>
        {message}
      </span>
      {children}
    </div>
  )
}

export default ErrorContainer
