import { makeAutoObservable } from "mobx"
import { get, postApi } from "../global/utils/api"
import { IBulkInvitationJob, IBulkInvitationsStore } from "./interfaces"
import { HttpStatusCode } from "../global/enums/api"

const controller = "bulkinvitation"

const storeUrls = {
  getJob: `${controller}/get`,
  getIncompleteJobs: `${controller}/all`,
  requeueJob: `${controller}/requeue`,
}

export type { IBulkInvitationsStore }

export class BulkInvitationsStore implements IBulkInvitationsStore {
  constructor() {
    makeAutoObservable(this)
  }

  getIncompleteJobs = (): Promise<IBulkInvitationJob[]> => {
    return new Promise((resolve, reject) => {
      get<IBulkInvitationJob[]>(storeUrls.getIncompleteJobs).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  getJob = (jobId: string): Promise<IBulkInvitationJob> => {
    return new Promise((resolve, reject) => {
      get<IBulkInvitationJob>(`${storeUrls.getJob}/${jobId}`).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve(res.data)
        }

        reject()
      })
    })
  }

  requeueJob = (jobId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      postApi(`${storeUrls.requeueJob}/${jobId}`, {}).then(res => {
        if (res.status === HttpStatusCode.OK) {
          resolve()
        }

        reject()
      })
    })
  }
}

export default new BulkInvitationsStore()
