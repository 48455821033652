import React from "react"

const StarIcon = () => {
  return (
    <svg
      className="icons__star-icon"
      width="20"
      height="20"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2583 18.4803C16.1305 18.6169 15.9484 18.6925 15.7593 18.6873C15.6718 18.684 15.5859 18.6636 15.5066 18.6271L10 16.1574L4.49337 18.6271C4.27979 18.7225 4.03032 18.6981 3.84025 18.5632C3.65019 18.4283 3.54889 18.2038 3.57505 17.9754L4.27488 12.0834L0.171383 7.71872C0.0128957 7.54949 -0.0405122 7.30996 0.0315262 7.09148C0.103565 6.87299 0.289944 6.70923 0.519589 6.66264L6.4632 5.49293L9.43331 0.322808C9.54916 0.123264 9.76556 0 10 0C10.2345 0 10.4509 0.123264 10.5667 0.322808L13.5368 5.49293L19.4804 6.66264C19.7101 6.70923 19.8964 6.87299 19.9685 7.09148C20.0405 7.30996 19.9871 7.54949 19.8286 7.71872L15.7251 12.0834L16.4249 17.9754C16.4469 18.1594 16.3861 18.3437 16.2583 18.4803ZM10.2731 14.874L14.9979 16.9962L14.3971 11.9397C14.3754 11.7557 14.4365 11.5715 14.5644 11.4351L18.0875 7.68864L12.9872 6.68938C12.8018 6.65174 12.6424 6.53709 12.5502 6.37523L10 1.93701L7.44981 6.37523C7.35765 6.53709 7.19817 6.65174 7.01283 6.68938L1.91247 7.68864L5.43561 11.4351C5.56354 11.5715 5.62458 11.7557 5.60289 11.9397L5.00205 16.9962L9.72689 14.874C9.90018 14.7957 10.0998 14.7957 10.2731 14.874Z"
        fill="#212B32"
      />
    </svg>
  )
}

export default StarIcon
