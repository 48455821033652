import { AxiosError, AxiosResponse } from "axios"
import _ from "lodash"
import { observer } from "mobx-react"
import { BodyText, Button, Container, Form, Label, Radios } from "nhsuk-react-components"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select, {
  ActionMeta,
  MultiValue,
  SingleValue,
  OptionsOrGroups,
  GroupBase,
} from "react-select"
import _Select from "react-select/dist/declarations/src/Select"
import ErrorsSummary from "../../global/components/errorsSummary"
import { BreadcrumbContext, withWrapper } from "../../global/components/HOC"
import { AsyncSearchableSelect } from "../../global/components/searchableSelect"
import { AdminManagementCrumb } from "../../global/components/staticBreadcrumbs"
import TextButton from "../../global/components/textButton"
import { Routes } from "../../global/enums"
import { useStores } from "../../global/hooks"
import { IAdminInfo } from "../../global/interfaces/admin"
import { IProductOwnerApplication } from "../../global/interfaces/productOwner/interfaces"
import { IRegion } from "../../global/interfaces/region"
import { ISelectOption } from "../../global/interfaces/select"
import { IUserSearchResponse } from "../../global/interfaces/user/interfaces"
import { ErrorsCollection } from "../../global/types"
import "../styles.scss"
import Admin from "../types/classes/admin"
import Application from "../types/classes/application"
import { ACTION, ApplicationState, SearchRadios } from "../types/enums"
import { IApplicationGroup, ISelectedUserValue } from "../types"
import { MultiAppConfiguration } from "./multiAppConfiguration"

const NORESULTMESSAGE = "Please search for an users email (enter at least 3 characters)"
const APPLICATIONSEARCHMESSAGE = "Please search for application"
const APPLICATIONSEARCHMESSAGENOADMINSELECTED = "Please select admin first"

const AdminManagement = observer(() => {
  const {
    productOwnerAdminStore: adminStore,
    profileStore,
    organisationStore,
    adminInfoStore,
  } = useStores()

  const [applicationRepository, setApplicationRepository] = useState<IProductOwnerApplication[]>([])
  const [nhsRegions, setNhsRegions] = useState<IRegion[]>([])
  const { setBreadcrumbs } = React.useContext(BreadcrumbContext)
  const [adminUser, setAdminUser] = useState<Admin | null>(null)
  const [adminsManagedApplications, setAdminsManagedApplications] = useState<Application[]>([])
  const [activeRadio, setActiveRadio] = useState(SearchRadios.Primary)
  const [loadingApps, setLoadingApps] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const navigate = useNavigate()
  const [noResultMessage, setNoResultsMessage] = useState<string>(NORESULTMESSAGE)
  const [applicationSearchMessage, setApplicationSearchMessage] =
    useState<string>(APPLICATIONSEARCHMESSAGE)
  const selectInputRef = useRef<_Select<ISelectOption, true, IApplicationGroup> | null>(null)
  const groupedOptions: IApplicationGroup[] = useMemo(() => [], [])

  groupedOptions.push({ label: "Published", options: [] })
  groupedOptions.push({ label: "Unpublished", options: [] })

  useEffect(() => {
    let mounted = true

    setBreadcrumbs([AdminManagementCrumb])

    setLoadingApps(true)

    adminStore
      .getMyProducts()
      .then(productOwnerApplications => {
        if (mounted) {
          setApplicationRepository(productOwnerApplications)
        }
      })
      .finally(() => {
        if (mounted) {
          setLoadingApps(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [adminStore.getMyProducts, setBreadcrumbs])

  useEffect(() => {
    let mounted = true
    organisationStore
      .getRegions()
      .then((regions: IRegion[]) => {
        mounted && setNhsRegions(regions)
      })
      .catch(() => console.log(`REGION CALL FAILED`))

    return () => {
      mounted = false
    }
  }, [organisationStore])

  const handleUserNameSearchDebouncedFunction = _.debounce(
    (
      searchTerm: string,
      callback: (
        options: OptionsOrGroups<ISelectedUserValue, GroupBase<ISelectedUserValue>>
      ) => void
    ) => {
      profileStore
        .searchForUser(encodeURIComponent(searchTerm))
        .then((response: AxiosResponse<IUserSearchResponse[]>) =>
          callback(
            response.data.map(user => ({
              label: user.email,
              value: [user.id, user.fullName, user.email],
            }))
          )
        )
        .catch(callback)
    },
    250
  )

  const loadUserNameSearchOptions = (
    searchTerm: string,
    callback: (options: OptionsOrGroups<ISelectedUserValue, GroupBase<ISelectedUserValue>>) => void
  ) => {
    if (searchTerm.length >= 3) handleUserNameSearchDebouncedFunction(searchTerm, callback)
  }

  useEffect(() => {
    adminUser && setApplicationSearchMessage(APPLICATIONSEARCHMESSAGE)
    !adminUser && setApplicationSearchMessage(APPLICATIONSEARCHMESSAGENOADMINSELECTED)
  }, [adminUser])

  const reset = () => {
    setNoResultsMessage(NORESULTMESSAGE)
    setApplicationSearchMessage(APPLICATIONSEARCHMESSAGENOADMINSELECTED)
    setAdminsManagedApplications([])
    setAdminUser(null)
    setActiveRadio(SearchRadios.Primary)
    selectInputRef?.current?.clearValue()
  }

  const handleApplicationChange = (application: Application, action: ACTION) => {
    const applicationsIndex = adminsManagedApplications.findIndex(app => app.id === application.id)

    const apps = [...adminsManagedApplications]

    switch (action) {
      case ACTION.DELETE_TRACKED_FROM_DB:
        selectInputRef.current?.removeValue({
          value: JSON.stringify({
            id: application.id,
            name: application.name,
            applicationType: application.applicationType,
          }),
          label: "",
        })

        apps.splice(applicationsIndex, 1)
        setAdminsManagedApplications([...apps])
        break
      case ACTION.MODIFIED:
        apps.splice(applicationsIndex, 1, { ...application } as Application)
        setAdminsManagedApplications([...apps])
        break
    }
  }

  const removeApplicationFromSelection = (applicationId: string, adminUser: Admin) => {
    const application = adminsManagedApplications.find(app => app.id === applicationId)

    application &&
      application.appliedAction !== ACTION.DELETE_TRACKED_FROM_DB &&
      adminUser.removeApplication(applicationId)

    application &&
      setAdminsManagedApplications([
        ...adminsManagedApplications.filter(app => app.id !== application.id),
      ])
  }

  const mapApplicatonUserResultToApplication = (
    appName: string,
    appId: string,
    appType: number,
    app: IAdminInfo | null
  ) => {
    const application = !app
      ? new Application(appName, appId, appType)
      : new Application(
          appName,
          appId,
          appType,
          ApplicationState.Tracked,
          app.scope,
          app.canUpdateProduct,
          app.selectedRegions || []
        )

    return application
  }

  const customStyles = {
    IndicatorSeparator: () => null,
  }

  const saveConfiguration = () => {
    adminInfoStore
      .saveAdminsMultipleAppConfigurations(adminUser)
      .then(() => {
        reset()
        navigate(Routes.AppAdmin)
      })
      .catch((errors: AxiosError) => {
        if (errors.response) {
          setErrors(errors.response.data as string[])
        }
      })
  }

  const resolveAdminInfoResult = (
    app: IAdminInfo | null,
    id: string,
    name: string,
    applicationType: number
  ) => {
    if (adminUser) {
      adminUser.addManagedApplication(
        mapApplicatonUserResultToApplication(name, id, applicationType, app)
      )

      setAdminsManagedApplications([...adminUser.AdminsManagedApplications])
    }
  }

  const onSelectionChanged = (
    _: MultiValue<ISelectOption>,
    actionMeta: ActionMeta<ISelectOption>
  ) => {
    if (adminUser) {
      switch (actionMeta.action) {
        case "select-option":
          {
            if (actionMeta.option) {
              const { id, name, applicationType } = JSON.parse(actionMeta.option.value)
              adminInfoStore
                .getUsersAdminInfoFoApplication(adminUser.id, id)
                .then((app: IAdminInfo) => resolveAdminInfoResult(app, id, name, applicationType))
              setAdminsManagedApplications([...adminUser.AdminsManagedApplications])
            }
          }
          break
        case "deselect-option":
        case "remove-value":
        case "pop-value":
          // eslint-disable-next-line no-case-declarations
          if (actionMeta.removedValue) {
            const { id } = JSON.parse(actionMeta.removedValue.value)
            removeApplicationFromSelection(id, adminUser)
          }
          break
        case "clear":
          adminUser.removeAllApplications()
          setAdminsManagedApplications([...adminUser.AdminsManagedApplications])
          break
      }
    }
  }

  const applicationOptions = useMemo(() => {
    applicationRepository.forEach(app => {
      const group = groupedOptions.find(
        grp => grp.label === (app.published ? "Published" : "Unpublished")
      )

      group &&
        group["options"].push({
          label: `${app.name} (${app.originalName})`,
          value: JSON.stringify({
            id: app.id,
            name: app.name,
            applicationType: app.applicationType,
          }),
        })
    })

    return groupedOptions
  }, [applicationRepository, groupedOptions])

  const handleAdminTypeChange = (e: React.FormEvent<HTMLDivElement>) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const target = e.target as any
    const value = parseInt(target.value)
    setActiveRadio(value)

    if (adminUser) {
      adminUser.setAdminType(value)
      setAdminsManagedApplications([...adminUser.AdminsManagedApplications])
    }
  }

  return (
    <Container id="adminmanagement__errors_container" className="adminmanagement_errors">
      {errors.length > 0 && (
        <div>
          <ErrorsSummary
            errors={
              new ErrorsCollection(
                errors.map(er => ({
                  key: er,
                  value: [
                    {
                      summaryError: er,
                      fieldError: er,
                    },
                  ],
                }))
              )
            }
          />
        </div>
      )}

      <Form className="adminmanagement__section adminmanagement__separator">
        <h1 className="adminmanagement__section__header">Manage Product Admins</h1>
        <BodyText className="nhsuk-hint">
          Please select a User from the below dropdown by searching for them. Once you have the
          admin selected then you can decide whether you want to make them a Primary Admin or
          Additional Admin and then select the applications with which you want to apply this to.
        </BodyText>
      </Form>

      <div className="adminmanagement__section">
        <div className="nhsuk-form-group">
          <Label size="m" htmlFor="adminmanagement_adminUserlookup">
            User selection
          </Label>
          <AsyncSearchableSelect
            id="adminmanagement_adminUserlookup"
            className="input-field admin-info-custom-select"
            noOptionsMessage={() => noResultMessage}
            loadOptions={loadUserNameSearchOptions}
            placeholder={noResultMessage}
            onClear={reset}
            onChange={(
              selectedValue: SingleValue<ISelectedUserValue> | MultiValue<ISelectedUserValue>,
              _: ActionMeta<ISelectedUserValue>
            ) => {
              const singleValue = selectedValue as SingleValue<ISelectedUserValue>
              if (singleValue) {
                const [id, fullName, email] = singleValue.value
                setAdminUser(new Admin(fullName, id, email))
              }
            }}
          />
        </div>

        <Label size="m" htmlFor="adminmanagement__radios">
          Choose:
        </Label>

        <Radios
          inline={false}
          id="adminmanagement__radios"
          className="adminmanagement__admintype__radios"
          hint="Search by:"
          value={activeRadio}
          onChange={handleAdminTypeChange}
        >
          <Radios.Radio
            disabled={adminUser === null}
            className="adminmanagement__primaryadmintype"
            value={SearchRadios.Primary}
            checked={activeRadio == SearchRadios.Primary}
          >
            Primary admin
          </Radios.Radio>

          <Radios.Radio
            disabled={adminUser === null}
            className="adminmanagement_secondaryadmintype"
            value={SearchRadios.Secondary}
            checked={activeRadio == SearchRadios.Secondary}
          >
            Additional admins
          </Radios.Radio>
        </Radios>

        <Label size="m" htmlFor="select_applications" className="adminmanagement__separator">
          Product selection
        </Label>

        <Select
          ref={selectInputRef}
          className="adminmanagement-multiselect input-field"
          isDisabled={adminUser === null}
          id="select_applications"
          classNamePrefix="adminmanagement-multiselect"
          placeholder={applicationSearchMessage}
          isClearable
          isLoading={loadingApps}
          components={{ ...customStyles, DropdownIndicator: () => null }}
          options={applicationOptions}
          onChange={onSelectionChanged}
          isMulti
          isSearchable
        />

        <div className="adminmanagement__section--secondaryadmin_multiappconfiguration">
          {activeRadio == SearchRadios.Secondary && adminsManagedApplications.length > 0 && (
            <MultiAppConfiguration
              applications={adminsManagedApplications}
              nhsRegions={nhsRegions}
              handleApplicationChange={handleApplicationChange}
            />
          )}
        </div>
      </div>

      <div className="adminmanagement__save--section">
        <Label size="s">Publish changes</Label>
        <BodyText>
          By clicking 'Save and publish', you will save the admin details selected above.
        </BodyText>
        <Button
          disabled={
            adminUser === null || (adminUser && adminUser.AdminsManagedApplications.length === 0)
          }
          className="adminmanagement__save-button"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            saveConfiguration()
          }}
        >
          Save and Publish
        </Button>
        <TextButton
          className="adminmanagement__return-link"
          onClick={() => navigate(Routes.AppAdmin)}
        >
          Return to Admin
        </TextButton>
      </div>
    </Container>
  )
})

export default withWrapper(AdminManagement)
