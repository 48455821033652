import React from "react"
import { IUserProfileUpdateResult } from "../../../../../global/interfaces/userProfile/interfaces"
import "./styles.scss"
import LoginButton from "../components/buttons/loginButton/loginButton"

interface IVerifyNewEmailAddressProps {
  result: IUserProfileUpdateResult
}

const VerifyNewEmailAddress = (props: IVerifyNewEmailAddressProps) => {
  const {
    result: {
      response: { emailAddress },
    },
  } = props

  return (
    <>
      <span className="profile-update-result-view__body-label">
        We’ve sent an activation email to your new address <b>{emailAddress}</b>.<br />
        Your <b>access</b> to some products and services <b>may change</b>.<br />
      </span>
      <div className="profile-update-result-view__options-bottom-container">
        <LoginButton />
      </div>
    </>
  )
}

export default VerifyNewEmailAddress
