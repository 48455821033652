import React from "react"
import { CardComponent } from "../"
import { useStores } from "../../../global/hooks"
import "./styles.scss"
import { ICoreProductTopicWithTilesAndRef } from "../../interfaces"
import { IApplicationTile } from "../../../global/interfaces/application/interfaces"

interface ICategoryPanelProps {
  requestAppAccess: (id: string, stepTwoRequired: boolean) => Promise<void>
  category: ICoreProductTopicWithTilesAndRef
  tableauWebWrapperEnabled: boolean
  toggleModal: (tile: IApplicationTile) => void
  toggleFavourite: (topicId: string, tileId: string) => Promise<void>
  lastPanel: boolean
}

const CategoryPanel = (props: ICategoryPanelProps) => {
  const {
    category: {
      reference,
      coreProductTopic: { id, topicName },
      applicationTiles,
    },
    requestAppAccess,
    tableauWebWrapperEnabled,
    toggleModal,
    toggleFavourite,
    lastPanel,
  } = props

  const {
    analyticalProductsStore: { categoryDescriptions },
  } = useStores()

  return (
    <div id={`category-panel-${id}`} className="category-panel">
      <div className="category-panel__header" ref={reference}>
        <h2 className="category-panel__header__title">{topicName}</h2>
        <p className="category-panel__header__description">
          {categoryDescriptions[topicName.toLowerCase()]}
        </p>
      </div>
      <div className="category-panel__card-wrapper">
        {applicationTiles.map(tile => {
          return (
            <CardComponent
              {...tile}
              key={`category-panel__card-wrapper__card-${tile.id}`}
              requestAppAccess={requestAppAccess}
              toggleModal={toggleModal}
              toggleFavourite={toggleFavourite}
              tableauWebWrapperEnabled={tableauWebWrapperEnabled}
              topicId={id}
              tile={tile}
            />
          )
        })}
      </div>
      {lastPanel ? (
        <hr className="category-panel__divider--invisible" />
      ) : (
        <hr className="category-panel__divider" />
      )}
    </div>
  )
}

export default CategoryPanel
