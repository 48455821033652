import _ from "lodash"
import { Select } from "nhsuk-react-components"
import React, { forwardRef, memo, useEffect, useState } from "react"
import { OrganisationTypes } from "../../../constants/organisation/constants"
import { IOrganisation } from "../../../interfaces/organisation/interfaces"

interface ICategory {
  category: string
  title: string
  items: ICategoryItem[]
}

interface ICategoryItem {
  code: string
  name: string
  originalIndex: number
}

interface ICategorisedOrganisationViewProps {
  onOrganisationChange: (organisationCode: string, defaultSelection: boolean) => Promise<void>
  selectError: string
  id: string
  organisationCategories: IOrganisation[]
  selectedOrganisation: IOrganisation | null
  disabled: boolean
}

const CategorisedOrganisationView = forwardRef(
  (props: ICategorisedOrganisationViewProps, selectRef: React.ForwardedRef<HTMLSelectElement>) => {
    const {
      onOrganisationChange,
      selectError,
      id,
      organisationCategories,
      selectedOrganisation,
      disabled,
    } = props

    const [categories, setCategories] = useState<ICategory[]>([
      {
        category: OrganisationTypes.nhsOrg,
        title: "NHS organisations",
        items: [],
      },
    ])

    useEffect(() => {
      const cats: ICategory[] = [...categories]

      for (let i = 0; i < organisationCategories.length; i++) {
        const category = _.find(
          cats,
          x =>
            x.category.toLowerCase() === organisationCategories[i].category.toLowerCase() &&
            !categories.flatMap(x => x.items).find(c => c.name == organisationCategories[i].name)
        )

        if (category) {
          category.items.push(createCategoryItem(organisationCategories[i], i))
        }
      }

      setCategories(cats)
    }, [organisationCategories])

    const createCategoryItem = (org: IOrganisation, index: number) => {
      return {
        code: org.code,
        name: org.name,
        originalIndex: index,
      }
    }

    const checkOrgCodeExistsinCategory = (orgCode: string) => {
      return categories
        .filter(c => c.items.length > 0)
        .flatMap(x => x.items)
        .some(item => item.code == orgCode)
    }

    return (
      <Select
        selectRef={selectRef as React.MutableRefObject<HTMLSelectElement>}
        name={id}
        value={
          selectedOrganisation?.code
            ? checkOrgCodeExistsinCategory(selectedOrganisation.code)
              ? selectedOrganisation.code
              : "Other (NHS)"
            : -1
        }
        error={selectError}
        disabled={disabled}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          onOrganisationChange((e.target as HTMLSelectElement).value, false)
        }
      >
        <Select.Option value={-1} disabled>
          Select organisation
        </Select.Option>
        <Select.Option value="" disabled></Select.Option>
        {categories
          .filter(c => c.items.length > 0)
          .flatMap(x => x.items)
          .map((item, index) => {
            return (
              <Select.Option value={item.code} key={`${item.code}${index}`} id={item.name}>
                {item.name === "Other NHS organisation"
                  ? "Other (or unable to find organisation)"
                  : item.name}
              </Select.Option>
            )
          })}
      </Select>
    )
  }
)

export default memo(CategorisedOrganisationView)
