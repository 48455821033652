import { observer } from "mobx-react"
import { Button, Label } from "nhsuk-react-components"
import React from "react"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { useStores } from "../../../global/hooks"
import { Views } from "../../enums"
import "../../styles.scss"

interface ISummaryViewProps {
  setView: (view: Views) => void
}

const SummaryView = observer((props: ISummaryViewProps) => {
  const { setView } = props

  const {
    invitationStore: store,
    productOwnerAdminStore: { myProducts: productList, selectedProduct },
  } = useStores()

  const { wrapWithLoader } = React.useContext(LoaderContext)

  const sendInvites = async () => {
    await wrapWithLoader(async () => {
      await store.postInvitations().then(res => {
        if (res.data.bulkInvitationResult) {
          setView(Views.BulkSent)
        } else if (res.data.success) {
          setView(Views.Sent)
        } else {
          store.setInvalidEmails(res.data.emailAddresses)
          setView(Views.InvitationErrors)
        }
      })
    })
  }

  const SummaryParagraph = () => {
    return (
      <>
        {!selectedProduct && <p>Review the email addresses, then send the invitations.</p>}

        <p>
          You are inviting the following people to {selectedProduct && "join"}{" "}
          {store.invitation.products &&
            store.invitation.products.map((productId, i) => {
              const product = productList.find(x => x.id === productId)
              return (
                <span key={product ? product.id : i} className={!selectedProduct ? "bold" : ""}>
                  {product ? product.name : ""}
                  {i + 1 === store.invitation.products.length ? "." : ", "}
                </span>
              )
            })}
        </p>
        <ul>
          {store.invitation.recipientEmails.map(email => (
            <li key={email}>{email}</li>
          ))}
        </ul>
        {!selectedProduct && (
          <p>
            The same user(s) will also be granted access automatically to any other products that
            people from their organisation are entitled to see.
          </p>
        )}
      </>
    )
  }

  return (
    <>
      <Label isPageHeading>Summary</Label>
      <SummaryParagraph />
      <div className="invitation-page__button-controls">
        <Button className="invitation-page__nhsuk-button" onClick={sendInvites}>
          Send invites
        </Button>
        <Button
          className="invitation-page__nhsuk-button invitation-page__nhsuk-button--button-secondary"
          secondary
          onClick={() => setView(Views.Main)}
        >
          Back
        </Button>
      </div>
    </>
  )
})

export default SummaryView
