import React, { createContext, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import Spinner from "../spinner"
import "./styles.scss"

const activeLoaders: boolean[] = []
const transitionTime = 800

interface IProviderInterface {
  loading: boolean
  wrapWithLoader: (awaitableCallback: () => Promise<void> | void) => Promise<void>
  setGlobalLoadState: React.Dispatch<React.SetStateAction<boolean>>
  startLoading: () => void
  stopLoading: () => void
}

interface ILoaderProviderProps {
  children: React.ReactNode
}

export const LoaderContext = createContext<IProviderInterface>({} as IProviderInterface)

export default ({ children }: ILoaderProviderProps) => {
  const [globalLoading, setGlobalLoading] = useState(false)
  const [opacityTransitioning, setOpacityTransitioning] = useState(false)

  const setLoading = (state: boolean) => {
    // Why do we expose setLoading instead of setGlobalLoading?
    //
    // Assume that components A & B are loading something - A is really slow, B is quick.
    // A & B both set loading to true, but B finishes first.
    //
    // If one variable was used (instead of the activeLoaders array)
    // B would override the entire global loading state,
    // even though A was still churning away.

    if (!state) {
      activeLoaders.pop()
      if (activeLoaders.length === 0) {
        setOpacityTransitioning(true)
        setGlobalLoading(false)
      }
    } else {
      activeLoaders.push(true)
      setGlobalLoading(true)
    }
  }

  useEffect(() => {
    if (!globalLoading) {
      setTimeout(() => {
        setOpacityTransitioning(false)
      }, transitionTime)
    }
  }, [globalLoading])

  const providerInterface = {
    loading: globalLoading,
    wrapWithLoader: async (awaitableCallback: () => Promise<void> | void) => {
      try {
        setLoading(true)
        await awaitableCallback()
      } finally {
        setLoading(false)
      }
    },
    setGlobalLoadState: setGlobalLoading,
    startLoading: () => setLoading(true),
    stopLoading: () => setLoading(false),
  }

  return (
    <LoaderContext.Provider value={providerInterface}>
      {(opacityTransitioning || globalLoading) &&
        ReactDOM.createPortal(
          <>
            <div className={`${opacityTransitioning ? "fade-out " : ""}loader-container`}>
              <Spinner />
            </div>
          </>,
          document.body
        )}
      {children}
    </LoaderContext.Provider>
  )
}
