import moment from "moment"
import { BodyText } from "nhsuk-react-components"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ChevronRight, PlusIcon, SentIcon } from "../../../../../global/components/icons"
import { LoaderContext } from "../../../../../global/components/loaderProvider"
import { ApplicationRequestStatus } from "../../../../../global/enums/application"
import { useStores } from "../../../../../global/hooks"
import {
  IIndexItem,
  ISearchResult,
} from "../../../../../global/interfaces/searchResults/interfaces"
import "../../styles.scss"

interface ISearchResultsContainerProps {
  results: ISearchResult<IIndexItem>[]
  setDataUpdated: (dataUpdated: boolean) => void
}

const SearchResultsContainer = (props: ISearchResultsContainerProps) => {
  const { results, setDataUpdated } = props

  const {
    appGalleryStore: { requestAppAccess: requestAccess },
  } = useStores()

  const { wrapWithLoader } = React.useContext(LoaderContext)

  const navigate = useNavigate()

  const onRequestAccess = (id: string, stepTwoRequired: boolean) =>
    wrapWithLoader(async () => {
      if (stepTwoRequired) {
        navigate(`/product/${id}/steptwo`)
      } else {
        await requestAccess(id)
        setDataUpdated(true)
      }
    })

  return (
    <section className="search-results__results">
      {results &&
        results.map(result => (
          <article key={result.document.id} className="search-results__result">
            {result.document.product && (
              <header className="search-results__breadcrumbs">
                {result.document.product && (
                  <>
                    <BodyText className="search-results__breadcrumb">
                      {result.document.product}
                    </BodyText>
                    {result.document.hierarchy && result.document.hierarchy.lvl1 && (
                      <ChevronRight
                        className="search-results__breadcrumb-chevron"
                        height={16}
                        fill="#4c6272"
                        width={12}
                      />
                    )}
                  </>
                )}
                {result.document.hierarchy && result.document.hierarchy.lvl1 && (
                  <>
                    <BodyText className="search-results__breadcrumb">
                      {result.document.hierarchy.lvl1}
                    </BodyText>
                    {result.document.hierarchy && result.document.hierarchy.lvl2 && (
                      <ChevronRight
                        className="search-results__breadcrumb-chevron"
                        height={16}
                        fill="#4c6272"
                        width={12}
                      />
                    )}
                  </>
                )}
                {result.document.hierarchy && result.document.hierarchy.lvl2 && (
                  <>
                    <BodyText className="search-results__breadcrumb">
                      {result.document.hierarchy.lvl2}
                    </BodyText>
                    {result.document.hierarchy && result.document.hierarchy.lvl3 && (
                      <ChevronRight
                        className="search-results__breadcrumb-chevron"
                        height={16}
                        fill="#4c6272"
                        width={12}
                      />
                    )}
                  </>
                )}
                {result.document.hierarchy && result.document.hierarchy.lvl3 && (
                  <>
                    <BodyText className="search-results__breadcrumb">
                      {result.document.hierarchy.lvl3}
                    </BodyText>
                    {result.document.hierarchy && result.document.hierarchy.lvl4 && (
                      <ChevronRight
                        className="search-results__breadcrumb-chevron"
                        height={16}
                        fill="#4c6272"
                        width={12}
                      />
                    )}
                  </>
                )}
                {result.document.hierarchy && result.document.hierarchy.lvl4 && (
                  <BodyText className="search-results__breadcrumb">
                    {result.document.hierarchy.lvl4}
                  </BodyText>
                )}
              </header>
            )}
            <BodyText className="search-results__result-title">
              {result.document.accessType === ApplicationRequestStatus.Approved ? (
                <a href={result.document.url}>{result.document.name}</a>
              ) : (
                result.document.name
              )}
            </BodyText>
            <BodyText className="search-results__description">
              {result.document.description}
            </BodyText>
            {result.document.accessType === ApplicationRequestStatus.Pending && (
              <BodyText className="search-results__access search-results__access--requested">
                <SentIcon className="search-results__sentIcon" /> Request sent,{" "}
                {moment(result.document.stateChangedDate).format("DD MMM YYYY")}
              </BodyText>
            )}
            {result.document.accessType === ApplicationRequestStatus.NotRequested && (
              <BodyText className="search-results__access search-results__access">
                <PlusIcon />
                <button
                  className="search-results__request-access"
                  onClick={() =>
                    onRequestAccess(result.document.applicationId, result.document.stepTwoRequired)
                  }
                >
                  Request access
                </button>
              </BodyText>
            )}
          </article>
        ))}
    </section>
  )
}

export default SearchResultsContainer
