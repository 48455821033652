import { Checkboxes, Fieldset } from "nhsuk-react-components"
import React from "react"
import { ApplicationRequestStatus } from "../../../../../../../global/enums/application"
import Text from "../../../../../text/text"

import "./styles.scss"

const AccessContainer = props => {
  const { accessSelectionList, setAccessSelectionList } = props

  const handleChecked = accessArray => {
    var accessList = [...accessSelectionList]

    for (var access of accessArray) {
      var idx = accessList.indexOf(access)
      if (idx === -1) {
        accessList.push(access)
      } else {
        accessList = accessList.filter((x, i) => i !== idx)
      }
    }

    setAccessSelectionList(accessList)
  }

  const showChecked = accessArray => accessArray.every(x => accessSelectionList.indexOf(x) !== -1)

  return (
    <div className="az-page__app-access">
      <Text textType="label">Show applications for which:</Text>
      <Fieldset>
        <Checkboxes>
          <Checkboxes.Box
            title="have-access"
            value="have-access"
            onChange={() => handleChecked([ApplicationRequestStatus.Approved])}
            checked={showChecked([ApplicationRequestStatus.Approved])}
          >
            I have access
          </Checkboxes.Box>
          <Checkboxes.Box
            title="request-access"
            value="request-access"
            onChange={() =>
              handleChecked([
                ApplicationRequestStatus.NotRequested,
                ApplicationRequestStatus.Pending,
              ])
            }
            checked={showChecked([
              ApplicationRequestStatus.NotRequested,
              ApplicationRequestStatus.Pending,
            ])}
          >
            I do not have access but can request it
          </Checkboxes.Box>
          <Checkboxes.Box
            title="denied-access"
            value="denied-access"
            onChange={() => handleChecked([ApplicationRequestStatus.Rejected])}
            checked={showChecked([ApplicationRequestStatus.Rejected])}
          >
            Access has been declined
          </Checkboxes.Box>
        </Checkboxes>
      </Fieldset>
    </div>
  )
}

export default AccessContainer
