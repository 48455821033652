import React from "react"

import "./styles.scss"

type compProps = {
  id?: string
  style?: React.CSSProperties
  children: React.ReactNode
  className?: string
}

type selectableProps = {
  selected: boolean
}

type clickableProps = {
  onClick: () => void
}

const Tabs = (props: compProps) => {
  const elementId = props.id ? `tab-container ${props.id}` : "tab-container"
  return (
    <div id={elementId} className={props.className} style={{ ...props.style }}>
      {props.children}
    </div>
  )
}

const List = (props: compProps) => {
  const elementId = props.id ? `tab-list ${props.id}` : "tab-list"
  const elementClass = props.className ? `tab-list ${props.className}` : "tab-list"
  return (
    <ul id={elementId} className={elementClass} style={{ ...props.style }}>
      {props.children}
    </ul>
  )
}

const Tab = (props: compProps & selectableProps & clickableProps) => {
  const elementId = props.id ? `tab-item ${props.id}` : "tab-item"
  const elementClass = props.className
    ? props.selected
      ? `${props.className} selected`
      : props.className
    : props.selected
    ? "selected"
    : ""
  return (
    <li id={elementId} className={elementClass} style={{ ...props.style }} {...props}>
      <a href="#">{props.children}</a>
    </li>
  )
}

const Panel = (props: compProps & selectableProps) => {
  const elementClass = props.className
    ? props.selected
      ? `tab-panel ${props.className} selected`
      : `tab-panel ${props.className}`
    : props.selected
    ? "tab-panel selected"
    : "tab-panel"
  return props.selected ? (
    <section className={elementClass} style={{ ...props.style }}>
      {props.children}
    </section>
  ) : null
}

Tabs.List = List
Tabs.Tab = Tab
Tabs.Panel = Panel

export default Tabs
