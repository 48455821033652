import Link from "@govuk-react/link"
import _ from "lodash"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  Col,
  ErrorSummary,
  Fieldset,
  Form,
  Hint,
  Label,
  Radios,
  WarningCallout,
} from "nhsuk-react-components"
import React, { useEffect, useState } from "react"
import { ProductEditView } from "../../enums/enums"
import { LoaderContext } from "../../../global/components/loaderProvider"
import ShowGuidanceLink from "../../../global/components/showGuidanceLink"
import { isAllOrNhs, OrganisationTypes } from "../../../global/constants/organisation/constants"
import { useStores } from "../../../global/hooks"
import ProductViewRedirect from "../productViewRedirect"
import OrganisationControl from "./organisationControl"
import "./styles.scss"

const AccessPermissionsView = observer(() => {
  const { productStore, accessPermissionsStore } = useStores()
  const { accessPermissions, organisations } = accessPermissionsStore

  const { allOrgs, nhsOrg, nhsTrustOrg } = OrganisationTypes

  const [isEditMode, setIsEditMode] = useState(!productStore.published)
  const [hasBeenValidated, setHasBeenValidated] = useState(false)
  const [inviteOnlyState, setInviteOnlyState] = useState(true)
  const [allowInvitesFromUnapprovedOrgs, setAllowInvitesFromUnapprovedOrgs] = useState(undefined)
  const [errors, setErrors] = useState([])
  const [seenErrors, setSeenErrors] = useState(false)
  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [loading, setLoading] = useState(true)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [referenceExceptions, setReferenceExceptions] = useState([])

  useEffect(() => {
    wrapWithLoader(async () => {
      await accessPermissionsStore.getNHSOrgCodes()
      await accessPermissionsStore.getOrganisations()
      await accessPermissionsStore.getAccessPermissionsData(productStore.product.id, true)
      setLoading(false)
    })

    return () => accessPermissionsStore.resetState()
  }, [])

  useEffect(() => setIsEditMode(!productStore.published), [productStore.published])

  useEffect(() => {
    if (!loading) {
      setInviteOnlyState(accessPermissions.inviteOnly)
      setAllowInvitesFromUnapprovedOrgs(accessPermissions.canNonWhiteListUsersBeInvited)
    }
  }, [loading])

  const getSelectedCodesForList = (list, name) => {
    return list.map(x => ({
      organisationType: x.innerCode || x.organisationType || null,
      code: x.code || null,
      list: name,
      name: x.innerName === undefined || _.isEmpty(x.innerName) ? x.name : x.innerName,
    }))
  }

  const generateErrorObject = (errorPosition, errorMessage, reference) => {
    return {
      errorPosition: errorPosition,
      errorMessage: errorMessage,
      reference: reference,
    }
  }

  const setReferenceErrors = errors => {
    setReferenceExceptions(errors)
    setErrors([])
  }

  const editableSections = [
    {
      name: "Automatic access",
      friendlyname: "Automatic access",
      identity: "auto-access",
      checklist: accessPermissions.automaticAccess,
    },
    {
      name: "Request access",
      friendlyname: "Request access",
      identity: "request-access",
      checklist: accessPermissions.requestAccess,
    },
    {
      name: "Exceptions",
      friendlyname: "Exceptions",
      identity: "black-list",
      checklist: accessPermissions.blackList,
    },
  ]

  const createErrorsForParentsWithChildrenInOtherSections = (
    orgTypes,
    listsToTest,
    listOfParent
  ) => {
    const errors = []

    if (orgTypes.length === 0) return []
    for (const entry of listsToTest) {
      entry.list.forEach(x => {
        const matchingParent = orgTypes.find(
          org =>
            org.code === allOrgs ||
            (org.code === nhsOrg && x.isNHSOrg) ||
            (org.code === nhsOrg && x.code === nhsTrustOrg) ||
            x.code === org.code
        )

        const itemName = x.innerName === undefined || _.isEmpty(x.innerName) ? x.name : x.innerName

        if (matchingParent) {
          let matchingParentName = matchingParent && matchingParent.name
          if (matchingParent.code === nhsOrg) matchingParentName = "All NHS organisations"
          else if (matchingParent.code === allOrgs)
            matchingParentName = "All approved organisations"

          const entrySection = editableSections.find(x => x.name === entry.name)?.identity ?? ""

          const result =
            entry.message instanceof Function && entry.message(matchingParentName, itemName)

          const errorMessage = result
            ? generateErrorObject(entrySection, result)
            : generateErrorObject(
                entrySection,
                `You have said that ${matchingParentName} should be ${listOfParent}.
                                                    This means that ${itemName} would also be ${listOfParent}.
                                                    You must either change your setting for ${matchingParentName} or change your setting for ${itemName}.`
              )
          errors.push(errorMessage)
        }
      })
    }

    return errors
  }

  const checkSetProperly = (list, friendlyName, errorRegionIdentifier, errors) => {
    if (list.length === 0) return
    list
      .filter(item => _.isEmpty(item) || !item.code)
      .forEach(() =>
        errors.push(
          generateErrorObject(
            errorRegionIdentifier,
            `You need to complete or remove items in the ${friendlyName} list`
          )
        )
      )
  }

  const validate = () => {
    let newErrors = []

    if (!inviteOnlyState) {
      editableSections.forEach(x =>
        checkSetProperly(x.checklist, x.friendlyname, x.identity, newErrors)
      )
    }

    const codes = [
      ...getSelectedCodesForList(accessPermissions.automaticAccess, "automaticAccess"),
      ...getSelectedCodesForList(accessPermissions.requestAccess, "requestAccess"),
      ...getSelectedCodesForList(accessPermissions.blackList, "blackList"),
    ]

    const codeDictionary = {}
    codes.forEach(x => {
      const key = `${x.code}${x.organisationType}`
      if (!codeDictionary[key]) {
        codeDictionary[key] = {
          count: 1,
          code: x.code,
          organisationType: x.organisationType,
          name: x.innerName === undefined || _.isEmpty(x.innerName) ? x.name : x.innerName,
          presentInLists: new Set([x.list]),
        }
        return
      }

      codeDictionary[key].count++
      codeDictionary[key].presentInLists.add(x.list)
    })

    Object.values(codeDictionary)
      .filter(x => x.count > 1 && x.name)
      .map(x => {
        newErrors.push(
          generateErrorObject(
            "",
            `
                Organisation(s) can only belong to one of the following groups: automatic access, requesting access, and exceptions.
                Unfortunately you have selected ${x.name} for more than one of these groups.
                `
          )
        )
      })

    const orgTypeOrNHSAll = x => x.isOrgType || isAllOrNhs(x.code)

    const autoAccessOrgTypes = accessPermissions.automaticAccess.filter(orgTypeOrNHSAll)
    const requestAccessOrgTypes = accessPermissions.requestAccess.filter(orgTypeOrNHSAll)
    const blackListOrgTypes = accessPermissions.blackList.filter(orgTypeOrNHSAll)
    newErrors = [
      ...newErrors,
      ...createErrorsForParentsWithChildrenInOtherSections(
        requestAccessOrgTypes,
        [
          {
            name: "Automatic access",
            list: accessPermissions.automaticAccess,
          },
        ],
        "Request access"
      ),
    ]
    newErrors = [
      ...newErrors,
      ...createErrorsForParentsWithChildrenInOtherSections(
        blackListOrgTypes,
        [
          {
            name: "Automatic access",
            list: accessPermissions.automaticAccess,
            message: (parentName, itemName) =>
              ` You have said that ${parentName} should be an exception. This means that ${itemName} would also be an exception.
                          Unfortunately you have also chosen that ${itemName} should get automatic access, and this is not possible.
                          You must either change your setting for ${parentName} or change your setting for ${itemName}.
                       `,
          },
          {
            name: "Request access",
            list: accessPermissions.requestAccess,
            message: (parentName, itemName) =>
              ` You have said that ${parentName} should be an exception. This means that ${itemName} would also be an exception.
                          Unfortunately you have also chosen that ${itemName} should get request access, and this is not possible.
                          You must either change your setting for ${parentName} or change your setting for ${itemName}.
                        `,
          },
        ],
        "Exceptions"
      ),
    ]

    newErrors = [
      ...newErrors,
      ...referenceExceptions.map(x =>
        generateErrorObject(x.section, "Please select an organisation", x.identifier)
      ),
    ]
    setErrors(newErrors)
    setSeenErrors(false)
    setHasBeenValidated(true)

    return newErrors.length === 0
  }

  const scrollTo = (event, errorPosition) => {
    event.preventDefault()
    if (_.isEmpty(errorPosition)) return

    document.getElementById(errorPosition).scrollIntoView({
      block: "end",
      behavior: "smooth",
    })
  }

  const completeAndSave = async () => {
    if (!validate()) return

    accessPermissions.complete = true
    await save()
  }

  const saveAndReturn = async () => {
    accessPermissions.complete = false
    await save()
  }

  const save = async () => {
    accessPermissions.inviteOnly = inviteOnlyState
    accessPermissions.canNonWhiteListUsersBeInvited = allowInvitesFromUnapprovedOrgs

    await accessPermissionsStore.saveAccessPermissions(productStore.product.id)
    productStore.setTaskModified(ProductEditView.AccessPermissions, accessPermissions.complete)
    setRedirectingToTaskList(true)
  }

  const getPluralisedOrgType = org => {
    const code = org.innerCode === undefined ? "" : `(${org.innerCode})`
    return org.isOrgType && !org.name.endsWith("s")
      ? org.name.endsWith("y")
        ? `${org.name.substring(0, org.name.length - 1)}ies ${code}`
        : `${org.name}s ${code}`
      : `${org.name} ${code}`
  }
  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <>
      <Col id="access-permissions-view" className="access-permissions-page" width="full">
        {isEditMode && errors.length > 0 && (
          <div
            ref={errorSection => {
              if (seenErrors) return
              errorSection &&
                errorSection.scrollIntoView({
                  block: "end",
                  behavior: "smooth",
                })
              setSeenErrors(true)
            }}
          >
            <ErrorSummary role="alert" tabIndex={-1}>
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.List className="error-list">
                {errors.map((x, index) => {
                  const id = x.errorPosition ? `#${x.errorPosition}` : ""
                  return (
                    <ErrorSummary.Item
                      onClick={event => scrollTo(event, x.errorPosition)}
                      key={`${index + id}`}
                      href={id}
                    >
                      {x.errorMessage}
                    </ErrorSummary.Item>
                  )
                })}
              </ErrorSummary.List>
            </ErrorSummary>
          </div>
        )}
        <div className="page-heading">
          <Label isPageHeading>Access permissions</Label>
          {!isEditMode && (
            <Link id="change-settings-button" onClick={() => setIsEditMode(true)}>
              Change settings
            </Link>
          )}
        </div>
        {!isEditMode ? (
          <>
            <div className="readonly-row">
              <div className="title-text">Invite only?</div>
              <div className="summary-text">
                {accessPermissions.inviteOnly && "Yes"}
                {!accessPermissions.inviteOnly && "No"}
              </div>
            </div>
            <div className="readonly-row">
              <div className="title-text">Automatic access list</div>
              <div className="summary-text">
                {accessPermissions.automaticAccess.map((x, i) => (
                  <p>- {getPluralisedOrgType(x)}</p>
                ))}
              </div>
            </div>
            <div className="readonly-row">
              <div className="title-text">Request access list</div>
              <div className="summary-text">
                {accessPermissions.requestAccess.map((x, i) => (
                  <p>- {getPluralisedOrgType(x)}</p>
                ))}
              </div>
            </div>
            <div className="readonly-row">
              <div className="title-text">Exceptions</div>
              <div className="summary-text">
                {accessPermissions.blackList.map((x, i) => (
                  <p>- {getPluralisedOrgType(x)}</p>
                ))}
              </div>
            </div>
            <div className="readonly-row">
              <div className="title-text">
                Allow invites to organisations not in the request access or automatic access lists?
              </div>
              <div className="summary-text">
                {accessPermissions.canNonWhiteListUsersBeInvited && "Yes"}
                {!accessPermissions.canNonWhiteListUsersBeInvited && "No"}
              </div>
            </div>
            <div className="info-box">
              There is a list of approved organisations and organisation types which can have access
              to one or more NHS England applications. Only on rare occasions are people invited who
              do not work for those.
            </div>
          </>
        ) : (
          <>
            <Form className="access-permissions-form-group">
              <Fieldset>
                <Fieldset.Legend>Is this product invite-only?</Fieldset.Legend>
                <Hint>
                  Invite-only products will only be shown to users who have been granted access
                  through invitation. No other users will be able to see it or request access to it.
                </Hint>
                <Radios onChange={e => setInviteOnlyState(e.target.value === "yes")}>
                  <Radios.Radio
                    id="invite-only-yes"
                    checked={inviteOnlyState}
                    onChange={Function.prototype} // Function.prototype == noop() == () => {}
                    value="yes"
                  >
                    Yes, this product is invite-only
                  </Radios.Radio>
                  <Radios.Radio
                    id="invite-only-no"
                    checked={!inviteOnlyState}
                    onChange={Function.prototype}
                    value="no"
                  >
                    No, this product is not invite-only
                  </Radios.Radio>
                </Radios>
              </Fieldset>
            </Form>
            {!inviteOnlyState && (
              <>
                <hr />
                <Form>
                  <AccessListSection
                    organisations={organisations}
                    hint="Select the users that can have automatic access to your product"
                    id="auto-access"
                    listName="automaticAccess"
                    guidance="When you select a specific organisation to receive automatic access, and a prospective user has an email address associated with that organisation, then they will be granted access automatically.
                                            If, however, a prospective user says they work for the organisation, but we do not recognise the email address given, then they will not be granted access automatically. Instead they will be able to request access.
                                            'All approved organisations' means that prospective users from any organisation listed in the dropdown menu below can have access.
                                            'All NHS organisations' means that prospective users from any organisation that is officially within the NHS can have access, which currently include:
                                            NHS trusts, NHS England, CCGs, PCNs, GP practices, ICBs, CSUs and those listed under 'Other NHS organisation'."
                    deleteAvailable
                    hasBeenValidated={hasBeenValidated}
                    errors={referenceExceptions}
                    setReferenceErrors={setReferenceErrors}
                  />

                  <AccessListSection
                    organisations={organisations}
                    hint="Select the users who can request to be considered for access to your product"
                    id="request-access"
                    listName="requestAccess"
                    guidance="Users who say they work for the selected organisation(s) will be able to request access to your product, regardless of the email address with which they have registered."
                    callout="The only users who will be granted access, either automatically or after making a request, are those who work for one of the organisations you specify above. People working for other organisations will not be able to see your product at all."
                    deleteAvailable
                    hasBeenValidated={hasBeenValidated}
                    errors={referenceExceptions}
                    setReferenceErrors={setReferenceErrors}
                  />

                  <AccessListSection
                    organisations={organisations}
                    hint="Exceptions to your rules for automatic or requested access (optional)"
                    id="black-list"
                    listName="blackList"
                    guidance="The exceptions list should only be used to make exceptions to the rules above.
                                            For example, you can select 'NHS trusts' to be given automatic access, and then name some specific trusts as exceptions. This will ensure that all NHS trusts get automatic access, except for those on your exceptions list."
                    deleteAvailable
                    hasBeenValidated={hasBeenValidated}
                    errors={referenceExceptions}
                    setReferenceErrors={setReferenceErrors}
                  />
                </Form>

                <Form className="access-permissions-form-group">
                  <Fieldset>
                    <Label>
                      Can users from organisations you have not approved, be invited to the product?
                    </Label>
                    <ShowGuidanceLink>
                      Choose 'Yes' if you want to be able to invite any prospective user to use your
                      product, who is not on your approved list of users. Choose 'No' if you want to
                      ensure that prospective users can only be invited to use your product if they
                      work for organisations in your approved or request access lists.
                    </ShowGuidanceLink>
                    <Radios
                      onChange={e => setAllowInvitesFromUnapprovedOrgs(e.target.value === "yes")}
                    >
                      <Radios.Radio
                        id="whitelist-only-yes"
                        checked={allowInvitesFromUnapprovedOrgs}
                        onChange={Function.prototype} // Function.prototype == noop() == () => {}
                        value="yes"
                      >
                        Yes
                      </Radios.Radio>
                      <Radios.Radio
                        id="whitelist-only-no"
                        checked={!allowInvitesFromUnapprovedOrgs}
                        onChange={Function.prototype}
                        value="no"
                      >
                        No
                      </Radios.Radio>
                    </Radios>
                  </Fieldset>
                </Form>
              </>
            )}
            {isEditMode &&
              (productStore.published ? (
                <div id="published-save-control">
                  <Label className="input-label">Publish changes</Label>
                  <BodyText className="input-hint">
                    By clicking 'Save and publish', you confirm that you are making these changes in
                    line with business policies.
                  </BodyText>
                  <RemoveOrganisationsWarning />
                  <Button id="publish-button" onClick={completeAndSave}>
                    Save and publish
                  </Button>
                </div>
              ) : (
                <div id="button-controls">
                  <Button id="publish-button" onClick={completeAndSave}>
                    Save and complete
                  </Button>
                  <Link id="return-link" onClick={saveAndReturn}>
                    Save and return to task list
                  </Link>
                </div>
              ))}
          </>
        )}
      </Col>
    </>
  )
})

const AccessListSection = props => {
  const {
    hint,
    organisations,
    guidance,
    id,
    listName,
    deleteAvailable,
    hasBeenValidated,
    errors,
    callout,
    setReferenceErrors,
  } = props
  return (
    <>
      <Label className="section-title">{hint}</Label>
      {guidance && <ShowGuidanceLink>{guidance}</ShowGuidanceLink>}
      <OrganisationControl
        organisationReference={organisations}
        section={id}
        listName={listName}
        deleteAvailable={deleteAvailable}
        hasBeenValidated={hasBeenValidated}
        errors={errors}
        setReferenceErrors={setReferenceErrors}
      />
      {callout && (
        <WarningCallout>
          <p>{callout}</p>
        </WarningCallout>
      )}
      <hr />
    </>
  )
}

const RemoveOrganisationsWarning = () => {
  const warningText = (
    <>
      <b>Important</b>
      <div>
        Removing an organisation from the Request or Automatic access list does not automatically
        remove access from users who are already registered through that organisation.
      </div>
    </>
  )

  return (
    <WarningCallout>
      <p>{warningText}</p>
    </WarningCallout>
  )
}

export default AccessPermissionsView
