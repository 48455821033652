import moment from "moment"

export const dayNumberStringMappings: Record<number, string> = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  0: "Sunday",
}

export const getUTCDateSuffix = (date: Date): string => {
  const dayOfMonth = date.getUTCDate()

  if (dayOfMonth >= 11 && dayOfMonth <= 13) {
    return "th"
  }

  const dayOfMonthString = dayOfMonth.toString()

  if (dayOfMonthString.endsWith("1")) {
    return "st"
  } else if (dayOfMonthString.endsWith("2")) {
    return "nd"
  } else if (dayOfMonthString.endsWith("3")) {
    return "rd"
  }

  return "th"
}

export const getNotificationDate = (date: Date) => {
  const nowDate = new Date(Date.now())

  const parseddate = moment(date).toDate()

  const [dateDate, dateMonth, dateYear] = [
    parseddate.getDate(),
    parseddate.getMonth(),
    parseddate.getFullYear(),
  ]

  if (
    dateDate === nowDate.getDate() &&
    dateYear === nowDate.getFullYear() &&
    dateMonth === nowDate.getMonth()
  ) {
    return "Today"
  }

  const daysGone = Math.floor((nowDate.getTime() - parseddate.getTime()) / 86400000)

  if (daysGone >= 6) {
    return `${moment(parseddate).format("ddd MMM D")}${getUTCDateSuffix(parseddate)}`
  }

  return dayNumberStringMappings[parseddate.getDay()]
}
