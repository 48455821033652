import { makeAutoObservable } from "mobx"
import { FeatureToggle } from "../enums/featureToggle/enums"

export interface IFeatureToggledRouteStore {
  flaggedRoutes: Record<string, IToggledRoute>
  getRoute: (path: string, catchAll: boolean) => IToggledRoute | undefined
}

export interface IToggledRoute {
  alternateRoute: string
  flag: FeatureToggle
}

class FeatureToggledRouteStore implements IFeatureToggledRouteStore {
  constructor() {
    makeAutoObservable(this)
  }

  flaggedRoutes: Record<string, IToggledRoute> = {
    "/search": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.CorePlatformSearchEnabled,
    },
    "/invitestatus": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.BulkInvitationsEnabled,
    },
    "/analyticshub": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.TableauWebWrapperEnabled,
    },
    "/cp-cookies": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.CoreApiEnabled,
    },
    "/cp-accessibility": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.CoreApiEnabled,
    },
    "/myaccount/update": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.ProfileUpdatingEnabled,
    },
    "/report": {
      alternateRoute: "/pageNotFound",
      flag: FeatureToggle.TableauWebWrapperEnabled,
    },
    "/notificationCentre": {
      alternateRoute: "pageNotFound",
      flag: FeatureToggle.NotificationCentre,
    },
    "/notifications": {
      alternateRoute: "pageNotFound",
      flag: FeatureToggle.NotificationCentre,
    },
    "/myaccount/notificationpreferences": {
      alternateRoute: "pageNotFound",
      flag: FeatureToggle.NotificationCentre,
    },
  }

  public getRoute = (path: string, catchAll: boolean): IToggledRoute | undefined => {
    return !catchAll
      ? this.flaggedRoutes[path]
      : this.flaggedRoutes[
          Object.keys(this.flaggedRoutes).find(key => path.startsWith(key)) ?? "Bad Route"
        ]
  }
}

export default new FeatureToggledRouteStore()
