import { Button } from "nhsuk-react-components"
import React from "react"
import { IconPlus, MinusIcon } from "../../../../../global/components/icons"
import "./styles.scss"

interface IExpanderCellProps {
  expandedView: boolean
  allowExpansion: boolean
  expanded: boolean
  setExpanded: (expanded: boolean) => void
}

const ExpanderCell = (props: IExpanderCellProps) => {
  const { expandedView, allowExpansion, expanded, setExpanded } = props

  if (!expandedView || !allowExpansion) {
    return null
  }

  return (
    <Button className="expander-cell" onClick={() => setExpanded(!expanded)}>
      {expanded ? <MinusIcon /> : <IconPlus />}
    </Button>
  )
}

export default ExpanderCell
