import _ from "lodash"
import { observer } from "mobx-react"
import { Button, Col, Container, Input, Label } from "nhsuk-react-components"
import { Panel } from "nhsuk-react-components/dist/deprecated"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import CategorisedJobRoleSelect from "../../../global/components/categorisedJobRoleSelect"
import ErrorsSummary from "../../../global/components/errorsSummary"
import { withWrapper } from "../../../global/components/HOC"
import { LoaderContext } from "../../../global/components/loaderProvider"
import {
  getCorrectedOrgCode,
  mapOrganisationToOrganisationModel,
} from "../../../global/components/organisation/helper"
import Organisation from "../../../global/components/organisation/organisation"
import WarningBox from "../../../global/components/warningBox"
import { OrganisationTypes } from "../../../global/constants/organisation/constants"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { IJobRole } from "../../../global/interfaces/jobRole/interfaces"
import {
  IOrganisation,
  IOrganisationModel,
} from "../../../global/interfaces/organisation/interfaces"
import { IRegion } from "../../../global/interfaces/region/interfaces"
import { IProfileChangesShape, profileUpdateFields } from "../../../global/stores/profileStore"
import { ErrorsCollection } from "../../../global/types"
import ProfileUpdateConfirmationView from "../profileUpdateConfirmationView"
import "./styles.scss"

const UpdateProfileView = observer(() => {
  const { profileStore: ps, jobRoleStore: jrs, organisationStore: os } = useStores()

  const [jobRoles, setJobRoles] = useState<IJobRole[]>([])
  const [organisationCategories, setOrganisationCategories] = useState<IOrganisation[]>([])

  const fallbackParentOrganisation = useRef<IOrganisation | undefined>(undefined)
  const fallbackSubOrganisation = useRef<IOrganisationModel | undefined>(undefined)

  const [submitRedirect, setSubmitRedirect] = useState(false)
  const [loading, setLoading] = useState(true)
  const [payload, setPayload] = useState<IProfileChangesShape | null>(null)
  const [errors, setErrors] = useState<ErrorsCollection | null>(null)
  const { wrapWithLoader } = useContext(LoaderContext)
  const errorsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    wrapWithLoader(async () => {
      setLoading(true)

      const roles: IJobRole[] = await jrs.getJobRoles()
      setJobRoles(roles)

      setOrganisationCategories(await os.getOrganisationsAndTypes("All"))

      const regions = await os.getRegions()

      const userRegion = regions.find((r: IRegion) => r.code === ps.user?.regionCode)

      ps.initialProfileData = {
        ...ps.initialProfileData,
        jobRole: roles.find((r: IJobRole) => r.title === ps.user?.jobRole),
        region: userRegion || (ps.user ? { code: ps.user?.regionCode } : undefined),
      }

      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (
      organisationCategories.length > 0 &&
      !fallbackParentOrganisation.current &&
      !fallbackSubOrganisation.current
    ) {
      wrapWithLoader(async () => {
        setLoading(true)

        if (ps.user) {
          await setFallbacks(
            getCorrectedOrgCode(ps.user.primaryOrganisationCode),
            ps.user.primaryOrganisationCode,
            true
          )
        }

        ps.initialProfileData = {
          ...ps.initialProfileData,
          parentOrganisation: fallbackParentOrganisation.current,
          subOrganisation: fallbackSubOrganisation.current,
        }

        setLoading(false)
      })
    }
  }, [organisationCategories])

  const scrollToErrorSummary = () => {
    if (errorsRef.current) {
      errorsRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      })
    }
  }

  const setFallbacks = async (
    categoryCode: string,
    orgSearchCode: string,
    excludeSites: boolean
  ) => {
    const category = organisationCategories.find(x => x.code === categoryCode)

    if (!category) {
      const userOrganisation = await os.getOrganisationByCode(orgSearchCode, excludeSites)

      if (userOrganisation) {
        const correctedOrgType =
          userOrganisation.organisationType === OrganisationTypes.gpOrg
            ? OrganisationTypes.pcnOrg
            : userOrganisation.organisationType

        const cat = organisationCategories.find(x => x.code === correctedOrgType)

        if (cat) {
          fallbackParentOrganisation.current = cat
        }
        fallbackSubOrganisation.current = mapOrganisationToOrganisationModel(userOrganisation)
      }
    } else {
      fallbackParentOrganisation.current = category
    }
  }

  const onSubmit = () => {
    wrapWithLoader(async () => {
      const changes = ps.getChangesShape()
      const validationErrors = await ps.validateChanges(changes)

      if (validationErrors && validationErrors.length > 0) {
        setErrors(validationErrors)
        scrollToErrorSummary()
      } else {
        setErrors(null)
        const load = ps.getPayload(changes)
        if (!_.isEmpty(load)) {
          setPayload(load)
          if (load.organisation) {
            await setFallbacks(load.organisation.code, load.organisation.code, true)
          }
          setSubmitRedirect(true)
        } else {
          setErrors(
            new ErrorsCollection([
              {
                key: "general",
                value: [
                  {
                    fieldError: "No changes have been made",
                    summaryError: "No changes have been made",
                  },
                ],
              },
            ])
          )
          scrollToErrorSummary()
        }
      }
    })
  }

  const onJobRoleChanged = (jobRoleTitle: string) => {
    const newRole = jobRoles.find(x => x.title === jobRoleTitle)
    if (newRole) {
      ps.profileUpdateData.jobRole = newRole
    }
  }

  const onParentOrganisationChanged = (org: IOrganisation | null, removeSubOrganisation = true) => {
    ps.profileUpdateData.parentOrganisation = org || undefined
    if (removeSubOrganisation) {
      delete ps.profileUpdateData.subOrganisation
    }
  }

  const onSubOrganisationChanged = (model: IOrganisationModel | null) =>
    (ps.profileUpdateData.subOrganisation = model)

  const onRegionChanged = (region: IRegion | null) =>
    (ps.profileUpdateData.region = region || undefined)

  const onModalCancellation = () => {
    ps.profileUpdateData.parentOrganisation = fallbackParentOrganisation.current
    ps.profileUpdateData.subOrganisation = fallbackSubOrganisation.current
  }

  const getDefaultValue = useCallback(
    (property: string) => {
      const updatedValue = ps.profileUpdateData[property]
      const displayProperty = ps.profileUpdateFieldsDisplayProperties[property]

      if (!ps.isPayloadValueInvalid(updatedValue)) {
        return displayProperty ? updatedValue[displayProperty] : updatedValue
      }

      const initialValue = ps.initialProfileData[property]
      if (ps.isPayloadValueInvalid(initialValue)) {
        return ""
      }

      return displayProperty ? initialValue[displayProperty] : initialValue
    },
    [ps.profileUpdateData, ps.profileUpdateFieldsDisplayProperties, ps.initialProfileData]
  )

  const getRegionCode = () => {
    const key = profileUpdateFields.region
    const updatedValue = ps.profileUpdateData[key]
    if (!ps.isPayloadValueInvalid(updatedValue)) {
      return updatedValue.code
    }

    return ps.initialProfileData[key]?.code
  }

  if (submitRedirect) {
    return (
      <ProfileUpdateConfirmationView payload={payload} onCancel={() => setSubmitRedirect(false)} />
    )
  }

  return (
    <Container>
      <Panel className="update-profile-view">
        <Container>
          {errors && errors.length > 0 && (
            <div id="update-profile-view__errors" ref={errorsRef}>
              <ErrorsSummary errors={errors} />
            </div>
          )}
          <Col width="full">
            <h1 className="nhsuk-label-wrapper">
              <label className="nhsuk-label nhsuk-label--xl page-heading">
                Change your personal details
              </label>
            </h1>
          </Col>

          <WarningBox className="update-profile-view__warning-box">
            Please note that changes to your email and organisation will be stored for auditing
            purposes
          </WarningBox>

          <WarningBox className="update-profile-view__warning-box">
            If your organisation went through a merger then you do not need to update your
            organisation, this will be done for you. If you update your organisation here you may
            lose permissions and have to manually re-apply.
          </WarningBox>

          <div className="update-profile-view__profile-property update-profile-view__first-name-field">
            <Label className="input-field-description" htmlFor="input-field-firstName">
              First name
            </Label>
            <Input
              id="input-field-firstName"
              error={errors?.getFirstFieldError(profileUpdateFields.firstName)}
              className="input-field"
              defaultValue={getDefaultValue(profileUpdateFields.firstName)}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (ps.profileUpdateData.firstName = (e.target as HTMLInputElement).value)
              }
            />
          </div>
          <div className="update-profile-view__profile-property">
            <Label className="input-field-description" htmlFor="input-field-lastName">
              Last name
            </Label>
            <Input
              id="input-field-lastName"
              error={errors?.getFirstFieldError(profileUpdateFields.lastName)}
              className="input-field"
              defaultValue={getDefaultValue(profileUpdateFields.lastName)}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (ps.profileUpdateData.lastName = (e.target as HTMLInputElement).value)
              }
            />
          </div>
          <div className="update-profile-view__profile-property-extra-padding">
            <Label className="input-field-description" htmlFor="input-field-emailAddress">
              Email address
            </Label>
            <span className="update-profile-view__profile-property-extra-padding__input-field-sub-description">
              Enter your main work email address. If possible, this should be the email address
              provided to you by the main organisation you work for.
            </span>
            <Input
              id="input-field-emailAddress"
              placeholder="Please enter your email address"
              error={errors?.getFirstFieldError(profileUpdateFields.emailAddress)}
              className="input-field"
              defaultValue={getDefaultValue(profileUpdateFields.emailAddress)}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (ps.profileUpdateData.emailAddress = (e.target as HTMLInputElement).value)
              }
            />
          </div>
          <div className="update-profile-view__profile-property-extra-padding">
            <Label
              className="input-field-description"
              htmlFor="input-field-emailAddressConfirmation"
            >
              Confirm email address
            </Label>
            <Input
              id="input-field-emailAddressConfirmation"
              placeholder="Please confirm your email address"
              error={errors?.getFirstFieldError(profileUpdateFields.emailAddressConfirmation)}
              defaultValue={getDefaultValue(profileUpdateFields.emailAddressConfirmation)}
              className="input-field"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (ps.profileUpdateData.emailAddressConfirmation = (
                  e.target as HTMLInputElement
                ).value)
              }
            />
          </div>
          <div className="update-profile-view__profile-property-extra-padding">
            <Label className="input-field-description" htmlFor="input-select-jobRole">
              Job role (main)
            </Label>
            <span className="update-profile-view__profile-property-extra-padding__input-field-sub-description">
              Select the role you have at the main organisation you work for.
            </span>
            {jobRoles.length > 0 && (
              <CategorisedJobRoleSelect
                jobRoles={jobRoles}
                defaultValue={getDefaultValue(profileUpdateFields.jobRole)}
                onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                  onJobRoleChanged((e.target as HTMLSelectElement).value)
                }
              />
            )}
          </div>

          {!loading && (
            <Organisation
              defaultParentOrganisation={fallbackParentOrganisation.current}
              defaultSubOrganisation={fallbackSubOrganisation.current}
              defaultRegionCode={getRegionCode()}
              isCategorised
              isPrimary
              disabled={false}
              organisationCategories={organisationCategories}
              errors={errors}
              onParentOrganisationChanged={onParentOrganisationChanged}
              onSubOrganisationChanged={onSubOrganisationChanged}
              onRegionChanged={onRegionChanged}
              handleAsyncOrgSearch={os.getSubOrganisationForTypeWithSearchTerm}
              onModalCancellation={onModalCancellation}
            />
          )}

          <div className="update-profile-view__button-controls">
            <Button
              className="update-profile-view__button-controls__submit-button"
              onClick={onSubmit}
            >
              Submit
            </Button>
            <Link
              className="update-profile-view__button-controls__return-link"
              to={Routes.ProfileUrl}
            >
              Go back to your account
            </Link>
          </div>
        </Container>
      </Panel>
    </Container>
  )
})

export default withWrapper(UpdateProfileView)
