import _ from "lodash"
import { BodyText, Label } from "nhsuk-react-components"
import React, { useState } from "react"
import { ActionMeta, OnChangeValue, SingleValue } from "react-select"
import { asyncSearchOrganisations } from "../../../constants/organisation/constants"
import { IOrganisation, IOrganisationModel } from "../../../interfaces/organisation/interfaces"
import { LoaderContext } from "../../loaderProvider"
import { AsyncSearchableSelect, UserOptionsSearchableSelect } from "../../searchableSelect"
import { mapOrganisationToOrganisationModel } from "../helper"

interface ISubOrganisationSelectorProps {
  selectedParentOrganisation: IOrganisation | null
  selectedSubOrganisation: IOrganisationModel | null
  onSelectSubOrganisation: (model: IOrganisationModel | undefined) => void
  handleAsyncOrgSearch:
    | ((orgCode: string, searchTerm: string) => Promise<IOrganisation[]>)
    | undefined
  selectSubOrganisationLabel: string
  subOrganisations: IOrganisationModel[]
  useModalToSelectOrganisation: boolean
  setSubOrganisations: (orgs: IOrganisationModel[]) => void
  selectError: string
  disabled: boolean
}

const SubOrganisationSelector = (props: ISubOrganisationSelectorProps) => {
  const {
    selectedParentOrganisation,
    selectedSubOrganisation,
    onSelectSubOrganisation,
    handleAsyncOrgSearch,
    selectSubOrganisationLabel,
    subOrganisations,
    useModalToSelectOrganisation,
    setSubOrganisations,
    selectError,
    disabled,
  } = props

  const { wrapWithLoader } = React.useContext(LoaderContext)

  const organisationName =
    selectedParentOrganisation && selectedParentOrganisation.name
      ? selectedParentOrganisation.name
      : ""

  const organisationCode =
    selectedParentOrganisation && selectedParentOrganisation.code
      ? selectedParentOrganisation.code
      : ""

  const immediateOrganisationSearchMessage = `Begin typing your ${organisationName} name and select from the suggestions below.`
  const delayedOrganisationSearchMessage = `Please type at least 3 characters of your ${organisationName} name and select from the suggestions below.`
  const [noResultsMessage, setNoResultsMessage] = useState("You need to provide a search term.")

  const handleOrganisationSearch = _.debounce((searchTerm, callback) => {
    setNoResultsMessage("No results found, try searching again with a different query.")
    if (!searchTerm || searchTerm.length < 3) {
      setNoResultsMessage("Please type at least 3 characters to search.")
      callback([], null)
    } else if (handleAsyncOrgSearch) {
      handleAsyncOrgSearch(organisationCode, searchTerm)
        .then(result => {
          const orgs = result.map(mapOrganisationToOrganisationModel)
          setSubOrganisations(orgs)
          callback(orgs, null)
        })
        .catch(error => callback(null, error))
    }
  }, 250)

  const onClear = () => setNoResultsMessage("You need to provide a search term.")

  const handleOrganisationSelection = async <IsMulti extends boolean>(
    newValue: OnChangeValue<IOrganisationModel | null, IsMulti>,
    _: ActionMeta<IOrganisationModel | null>
  ) => {
    const organisationModel = newValue as SingleValue<IOrganisationModel | null>

    wrapWithLoader(async () => onSelectSubOrganisation(organisationModel || undefined))
  }

  if (asyncSearchOrganisations.indexOf(organisationCode) !== -1) {
    return (
      <AsyncSearchableSelect
        id="subOrganisation"
        className="input-field admin-info-custom-select"
        name={selectSubOrganisationLabel}
        noOptionsMessage={() => noResultsMessage}
        loadOptions={handleOrganisationSearch}
        error={selectError}
        onClear={onClear}
        onChange={handleOrganisationSelection}
        isClearable
        hint={delayedOrganisationSearchMessage}
        value={selectedSubOrganisation}
      />
    )
  }

  if (subOrganisations.length > 0 && !useModalToSelectOrganisation) {
    return (
      <>
        <Label>{selectSubOrganisationLabel}</Label>
        <BodyText className="input-hint">{immediateOrganisationSearchMessage}</BodyText>
        <UserOptionsSearchableSelect
          id="subOrganisation"
          className="organisationComponent__searchable-select"
          getNewOptionData={() => ({
            label: "No results please preview previous options",
            value: "",
            regionCodes: [],
          })}
          error={selectError}
          noOptionsMessage={() => "No results please preview previous options"}
          options={subOrganisations}
          disabled={disabled}
          defaultInputValue={selectedSubOrganisation?.label}
          onChange={handleOrganisationSelection}
          value={selectedSubOrganisation}
        />
      </>
    )
  }

  return null
}

export default SubOrganisationSelector
