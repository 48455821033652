import { makeAutoObservable } from "mobx"
import { HttpStatusCode } from "../../global/enums/api"
import { ITableauReportViewer } from "../../global/interfaces/tableau/interfaces"
import { get, postApi } from "../../global/utils/api"
import { IContactInfo } from "../../global/interfaces/contactInfo/interfaces"
import { TableauViz, Workbook, Sheet, CustomView } from "@tableau/embedding-api"

// Remove once fully converted to TypeScript
/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ITableauReportViewerStore {
  reportViewChanged: boolean
  currentReport: ITableauReportViewer | null
  toolbarButtons: IToolbarButtons
  filtersApplied: any[]
  metadata: any[]
  authorised: boolean
  currentTab: string
  currentView: string
  isFindingFilters: boolean
  workbookUrl: string
  baseUrl: string
  contactInfo: IContactInfo | null
  visualizationLoading: boolean
  getReport: (reportName: string) => Promise<void>
  toggleFavourite: () => Promise<void>
  setCurrentTab: (tabName: string) => void
  setCurrentView: (viewName: string) => void
  getCurrentReport: () => ITableauReportViewer | null
  setBaseUrl: (url: string) => void
  activeSheet: Sheet | null
  tableauVisualization: TableauViz | null
  setTableauVisualization: (tableaViz: TableauViz) => void
  setActiveSheet: (activeSheet: Sheet) => void
  setFilters: (filters: any[]) => void
  setMetadata: (metadata: any) => void
  setReportViewChanged: (hasChanged: boolean) => void
  loadingCustomViews: boolean
  customViews: CustomView[]
  setCustomViews: () => Promise<void>
  refreshCustomViews: boolean
  setRefreshCustomViews: (refresh: boolean) => void
  setFrameSize: (tableauVisualization?: TableauViz, workbook?: Workbook) => void
}

export enum Modals {
  None = -1,
  Share = 0,
  Download = 1,
  CustomViewManagement = 2,
}

export interface IToolbarButton {
  id: number
  text?: string
}

export interface IToolbarButtons {
  undo: IToolbarButton
  redo: IToolbarButton
  revert: IToolbarButton
  refresh: IToolbarButton
  pause: IToolbarButton
  favourite: IToolbarButton
  view: IToolbarButton
  share: IToolbarButton
  download: IToolbarButton
  customViewManagement: IToolbarButton
}

export class TableauReportViewerStore implements ITableauReportViewerStore {
  reportViewChanged = false
  currentReport: ITableauReportViewer | null = null
  filtersApplied: any[] = []
  metadata: any[] = []
  authorised = false
  currentTab = ""
  currentView = ""
  isFindingFilters = false
  workbookUrl = ""
  baseUrl = ""
  contactInfo: IContactInfo | null = null
  tableauVisualization: TableauViz | null = null
  activeSheet: Sheet | null = null
  visualizationLoading = true
  customViews: CustomView[] = []
  loadingCustomViews = false
  refreshCustomViews = false

  toolbarButtons: IToolbarButtons = {
    undo: {
      id: 0,
      text: "Undo",
    },
    redo: {
      id: 1,
      text: "Redo",
    },
    revert: {
      id: 2,
      text: "Revert",
    },
    refresh: {
      id: 3,
      text: "Refresh",
    },
    pause: {
      id: 4,
      text: "Pause",
    },
    favourite: {
      id: 5,
      text: "Favourite",
    },
    view: {
      id: 6,
      text: "View",
    },
    share: {
      id: 7,
      text: "Share",
    },
    download: {
      id: 8,
      text: "Download",
    },
    customViewManagement: {
      id: 9,
    },
  }

  constructor() {
    makeAutoObservable(this)
  }

  getReport = (reportName: string): Promise<void> => {
    this.currentReport = null
    this.filtersApplied = []
    this.metadata = []
    this.currentTab = ""
    this.isFindingFilters = false

    return new Promise<void>((resolve, reject) => {
      get<ITableauReportViewer>(`/tableaureportviewer/get?reportName=` + reportName)
        .then(res => {
          if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.Forbidden) {
            this.currentReport = res.data
            this.baseUrl = res.data.url
            this.workbookUrl = res.data.url
            this.setInitialTab()
            this.contactInfo = res.data.contactInfo
          }

          this.authorised = res.status === HttpStatusCode.OK
          resolve()
        })
        .catch(() => reject())
    })
  }

  toggleFavourite = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (this.currentReport) {
        postApi(`/application/favourite?id=${this.currentReport.applicationId}`, null)
          .then(() => {
            if (this.currentReport) {
              this.currentReport.isUserFavourite = !this.currentReport.isUserFavourite
            }
            resolve()
          })
          .catch(() => reject())
      }
    })
  }

  setInitialTab = (): void => {
    if (this.currentReport && this.currentReport.dashboards.length > 0) {
      this.setCurrentTab(this.currentReport.dashboards[0].dashboardName)
    }
  }

  setCurrentTab = (tabName: string): void => {
    this.currentTab = tabName
  }

  setCurrentView = (viewName: string): void => {
    if (this.baseUrl !== this.workbookUrl) {
      this.setBaseUrl(this.workbookUrl)
    }
    this.currentView = viewName
  }

  setFilters = (filters: any[]): void => {
    this.filtersApplied = filters
  }

  setMetadata = (metadata: any): void => {
    this.metadata = metadata
  }

  setBaseUrl = (url: string): void => {
    this.baseUrl = url
  }

  setTableauVisualization = (tableaViz: TableauViz) => {
    this.tableauVisualization = tableaViz
    this.visualizationLoading = false
  }

  setActiveSheet = (activeSheet: Sheet) => {
    this.activeSheet = activeSheet
  }

  setReportViewChanged = (hasChanged: boolean): void => {
    this.reportViewChanged = hasChanged
  }

  getCurrentReport = (): ITableauReportViewer | null => {
    return this.currentReport
  }

  setCustomViews = async (): Promise<void> => {
    if (!this.tableauVisualization?.workbook) return
    this.loadingCustomViews = true
    this.customViews = []
    const customViews = await this.tableauVisualization.workbook.getCustomViewsAsync()
    this.customViews = customViews
    if (customViews.some(customview => customview.default)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const defaultView = customViews.find(customview => customview.default)!
      this.setCurrentView(defaultView.name)
    }
    this.loadingCustomViews = false
  }

  setRefreshCustomViews = (refresh: boolean) => {
    this.refreshCustomViews = refresh
  }

  setFrameSize = (tableauVisualization?: TableauViz) => {
    if (!tableauVisualization || !tableauVisualization.workbook) return

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const maxSize = tableauVisualization.workbook.activeSheet.size.maxSize!
    if (tableauVisualization) {
      tableauVisualization.height = `${maxSize.height + 50}`
      tableauVisualization.width = `100vw`
    }
  }
}

export default new TableauReportViewerStore()
