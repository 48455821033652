import React from "react"
import "./styles.scss"

interface ICollapsedViewProps {
  jobRole: string
  emailAddress: string
}

const CollapsedView = (props: ICollapsedViewProps) => {
  const { jobRole, emailAddress } = props

  return (
    <>
      <span className="collapsed-view__cell">
        <b>Job:&nbsp;</b>
        {jobRole}
      </span>
      <span className="collapsed-view__cell">
        <b>Email:&nbsp;</b>
        {emailAddress}
      </span>
    </>
  )
}

export default CollapsedView
