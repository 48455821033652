import Collection from "../../abstract/collection"
import {
  IFluentValidationErrorResponse,
  IAxiosErrorResponse,
} from "../../interfaces/api/interfaces"
import { IKeyValuePair } from "../../interfaces/dataTypes/interfaces"
import { IErrorBucket } from "../../interfaces/error"

class ErrorsCollection extends Collection<IKeyValuePair<string, IErrorBucket[]>> {
  constructor(initialCollection?: IKeyValuePair<string, IErrorBucket[]>[]) {
    super()

    if (initialCollection) {
      this.addMany(initialCollection)
    }
  }

  static fromAxiosErrorResponse = (err: IAxiosErrorResponse): ErrorsCollection | null => {
    const { response } = err
    if (!response || !response.data) {
      return null
    } else {
      const { title, status } = response.data
      return new ErrorsCollection([
        {
          key: title,
          value: [
            {
              fieldError: title,
              summaryError: `${title}: ${status}`,
            },
          ],
        },
      ])
    }
  }

  static fromFluentValidationErrorResponse = (
    err: IFluentValidationErrorResponse
  ): ErrorsCollection => {
    const vals: IKeyValuePair<string, IErrorBucket[]>[] = Object.keys(err.response.data.errors).map(
      key => {
        const value = err.response.data.errors[key]
        return {
          key: key,
          value: [
            {
              fieldError: value,
              summaryError: value,
            },
          ],
        }
      }
    )

    return new ErrorsCollection(vals)
  }

  getFirstFieldError = (key: string): string => {
    const val = this.getFirstErrorBucketByKey(key)
    if (!val) {
      return ""
    }
    return val.fieldError
  }

  getFirstSummaryError = (key: string): string | JSX.Element => {
    const val = this.getFirstErrorBucketByKey(key)
    if (!val) {
      return ""
    }
    return val.summaryError
  }

  private getFirstErrorBucketByKey = (key: string): IErrorBucket | null => {
    if (!this.values) {
      return null
    }

    const pair = this.values.find(kvp => kvp.key === key)
    if (!pair || pair.value.length === 0) {
      return null
    }

    return pair.value[0]
  }
}

export default ErrorsCollection
