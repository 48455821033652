export enum ApplicationRequestStatus {
  Approved,
  Pending,
  NotRequested,
  Rejected,
  Removed,
}

export enum ApplicationType {
  Okta,
  Tableau,
  DCF,
  TableauAzure,
  TableauViewPoint,
  AzureAd,
  DCF2,
  Foundry,
}

export enum TaskName {
  ProductInformation,
  TableauReportViewerConfiguration,
  AdminInformation,
  ContactInformation,
  CoreProductInformation,
  AccessPermissions,
  ApprovalQuestions,
  TableauAccessGroups,
}

export enum ApplicationScope {
  All,
  Regional,
  Organisational,
}
