import { BodyText, Button, Label } from "nhsuk-react-components"
import React from "react"
import Modal from "../modal"
import TextButton from "../textButton"
import "./styles.scss"

interface IModalChangesNotSavedProps {
  headText?: string
  bodyText?: string
  leaveButtonText?: string
  stayButtonText?: string
  onStay: () => void
  onLeave: () => void
}

const ModalChangesNotSaved = (props: IModalChangesNotSavedProps) => {
  const { headText, bodyText, onStay, onLeave, stayButtonText, leaveButtonText } = props

  return (
    <Modal close={onStay}>
      <div className="modal-unsaved">
        <div className="modal-unsaved__body">
          <Label isPageHeading>{headText || "Are you sure you want to leave this page?"}</Label>
          <BodyText>{bodyText || "You will lose any changes that you have made."}</BodyText>
        </div>
        <footer className="modal-unsaved__footer">
          <TextButton
            className="modal-unsaved__button modal-unsaved__button--stay"
            onClick={onStay}
          >
            {stayButtonText || "No, stay on this page"}
          </TextButton>
          <Button className="modal-unsaved__button" onClick={onLeave}>
            {leaveButtonText || "Yes, leave the page"}
          </Button>
        </footer>
      </div>
    </Modal>
  )
}

export default ModalChangesNotSaved
