import React from "react"
import { observer } from "mobx-react"
import AnalyticalHeader from "../../../global/components/analyticalHeader"
import { useStores } from "../../../global/hooks"
import LeftContainer from "./components/leftContainer"
import RightContainer from "./components/rightContainer"
import { TableauViz } from "@tableau/embedding-api"

import "./styles.scss"

interface ITableauToolbar {
  onButtonClicked: (
    buttonId: number,
    tableauVisualization: TableauViz,
    visualizationLoading: boolean
  ) => void
  viewsReference: React.RefObject<HTMLDivElement>
  viewsButtonRef: React.RefObject<HTMLDivElement>
  showViewsDropdown: boolean
  minimized: boolean
  closeModal: () => void
}

const TableauToolbar = observer(
  ({
    onButtonClicked,
    viewsReference,
    viewsButtonRef,
    showViewsDropdown,
    minimized,
    closeModal,
  }: ITableauToolbar): JSX.Element => {
    const {
      tableauReportViewerStore: { visualizationLoading },
    } = useStores()

    if (visualizationLoading) {
      return <></>
    }

    return (
      <>
        {!minimized && <AnalyticalHeader sticky={true} />}
        <div id="tableau-toolbar" className="tableau-toolbar">
          <LeftContainer onButtonClicked={onButtonClicked} />
          <RightContainer
            viewsButtonRef={viewsButtonRef}
            viewsReference={viewsReference}
            onButtonClicked={onButtonClicked}
            showViewsDropdown={showViewsDropdown}
            closeModal={closeModal}
          />
        </div>
      </>
    )
  }
)

export default TableauToolbar
