import { BodyText, Select } from "nhsuk-react-components"
import React, { memo } from "react"
import "../../styles.scss"

const RegionSearch = props => {
  const { handleRegionChange, selectedRegion, regions } = props

  return (
    <>
      <BodyText>
        <span>Select the region from the dropdown</span>
      </BodyText>
      <Select
        className="input-field admin-info-custom-select"
        onChange={e => handleRegionChange(e.target.value)}
        value={selectedRegion}
      >
        <Select.Option key="All" value="All">
          All
        </Select.Option>
        {regions.map(region => {
          return (
            <Select.Option key={region.code} value={region.name}>
              {region.name}
            </Select.Option>
          )
        })}
        <Select.Option key="NotSpecified" value="Region not specified">
          Region not specified
        </Select.Option>
      </Select>
    </>
  )
}

export default memo(RegionSearch)
