import _ from "lodash"
import { observer } from "mobx-react"
import { Form, Hint, Label, Select } from "nhsuk-react-components"
import React from "react"
import TextButton from "../../../global/components/textButton"
import { IKeyValuePair } from "../../../global/interfaces/dataTypes/interfaces"
import { IErrorBucket } from "../../../global/interfaces/error"
import { IProductOwnerApplication } from "../../../global/interfaces/productOwner/interfaces"
import { useStores } from "../../../global/hooks"
import "../../styles.scss"

interface IProductSelectionProps {
  product: string
  index: number
  productList: IProductOwnerApplication[]
  onDeletion: (e: React.MouseEvent<Element, MouseEvent> | undefined, index: number) => void
}

const ProductSelection = observer((props: IProductSelectionProps) => {
  const { product, index, productList, onDeletion } = props

  const { invitationStore } = useStores()

  const errorField = `invite-product-field-${index}`

  const publishedApps = productList.filter(x => x.published)
  const moreProductsToSelect = _.difference(
    publishedApps.map(x => x.id),
    invitationStore.invitation.products
  )
  const appsNotSelected = publishedApps.filter(
    publishedApp => product === publishedApp.id || moreProductsToSelect.includes(publishedApp.id)
  )

  const handleInputChange = (e: React.FormEvent<HTMLSelectElement>) => {
    if (invitationStore.errors) {
      invitationStore.errors.removeWithPredicate(
        (kvp: IKeyValuePair<string, IErrorBucket[]>) => kvp.key === errorField
      )
    }

    if (e && e.target) {
      invitationStore.invitation.products[index] = (e.target as HTMLSelectElement).value
    }
  }

  return (
    <Form className="invite-form-group" key={product || index}>
      {index === 0 && (
        <>
          <Label>Product or service</Label>
          <Hint>
            <p>Select the product or service to which you are inviting the user(s).</p>
            <p>
              The same user(s) will also be granted access automatically to any other products that
              people from their organisation are entitled to see.
            </p>
          </Hint>
        </>
      )}
      <Select
        id={errorField}
        className="input-field invite-product-field invitation-page__input-field"
        defaultValue={product || "-1"}
        error={invitationStore.errors.getFirstFieldError(errorField)}
        disabled={appsNotSelected.length === 0}
        onChange={handleInputChange}
      >
        <Select.Option disabled value="-1">
          Select product or service
        </Select.Option>
        {appsNotSelected.map(p => (
          <Select.Option key={p.id} value={p.id}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
      {invitationStore.invitation.products.length > 1 && (
        <TextButton onClick={e => onDeletion(e, index)}>Delete</TextButton>
      )}
    </Form>
  )
})

export default ProductSelection
