import { Cookies } from "react-cookie"

export const getJWT = () => new Cookies().get("jwtcore")

export const getCookie = (cname: string) => {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")

  for (const c of ca) {
    const s = c.trimStart()
    if (s.indexOf(name) === 0) {
      return s.substring(name.length, s.length)
    }
  }

  return ""
}
