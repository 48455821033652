import { observer } from "mobx-react"
import React, { useMemo } from "react"
import { Regions } from "../../../../../global/constants/region/constants"
import { TableauSecurityModel } from "../../../../../global/enums"
import { useStores } from "../../../../../global/hooks"
import { IRegion } from "../../../../../global/interfaces/region/interfaces"
import { ITableauAccessGroup } from "../../../../../global/interfaces/tableau/interfaces"
import "./styles.scss"

interface IReadOnlyAccessViewProps {
  accessGroup: ITableauAccessGroup | null
  accessByCode: Record<string, ITableauAccessGroup>
}

const ReadOnlyAccessView = observer((props: IReadOnlyAccessViewProps) => {
  const { accessGroup, accessByCode } = props

  const { tableauAccessViewStore } = useStores()
  const securityModel = tableauAccessViewStore.tableauAccess.securityModel
  const accessText = TableauSecurityModel[securityModel]
  const nationalCode = Regions.National

  const AccessRow = useMemo(() => {
    if (securityModel === TableauSecurityModel.Custom) {
      return null
    }

    if (securityModel === TableauSecurityModel.Regional) {
      return (
        <div className="readonly-row-container__readonly-row" key={nationalCode}>
          <div className="readonly-row-container__readonly-row__title-text">
            National access group
          </div>
          <div className="readonly-row-container__readonly-row__tableau-access-text">
            {(accessByCode[nationalCode] && accessByCode[nationalCode].tableauGroupName) || "-"}
          </div>
        </div>
      )
    }

    return (
      <div className="readonly-row-container__readonly-row">
        <div className="readonly-row-container__readonly-row__title-text">Access group</div>
        <div className="readonly-row-container__readonly-row__tableau-access-text">
          {(accessGroup && accessGroup.tableauGroupName) || "-"}
        </div>
      </div>
    )
  }, [securityModel, nationalCode, accessByCode, accessGroup])

  return (
    <div className="readonly-row-container">
      <div className="readonly-row-container__readonly-row">
        <div className="readonly-row-container__readonly-row__title-text">Security model</div>
        <div className="readonly-row-container__readonly-row__tableau-access-text">
          {accessText}
        </div>
      </div>
      {AccessRow}
      {securityModel === TableauSecurityModel.Regional &&
        tableauAccessViewStore.regions
          .filter((region: IRegion) => region.code !== nationalCode)
          .map((region: IRegion) => (
            <div className="readonly-row-container__readonly-row" key={region.code}>
              <div className="readonly-row-container__readonly-row__title-text">{region.name}</div>
              <div className="readonly-row-container__readonly-row__tableau-access-text">
                {(accessByCode[region.code] && accessByCode[region.code].tableauGroupName) || "-"}
              </div>
            </div>
          ))}
    </div>
  )
})

export default ReadOnlyAccessView
