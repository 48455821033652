import axios from "axios"
import _ from "lodash"
import { makeAutoObservable } from "mobx"
import { ErrorsCollection } from "../../global/types"
import { getApi, postApi } from "../../global/utils/api"

const CancelToken = axios.CancelToken

export class RegistrationFormStore {
  externalApplicationName = ""
  form = {
    firstName: "",
    lastName: "",
    email: "",
    isSTP: false,
    primaryOrganisationCode: "",
    primaryOrganisationName: "",
    job: "",
    region: "",
    terms: false,
    secondaryOrganisations: [],
    externalApplicationId: "",
    invitationId: "",
    isOther: false,
    organisationType: "",
    userExists: false,
  }

  formErrors = new ErrorsCollection()

  source = null

  constructor() {
    makeAutoObservable(this)
  }

  getInvitationData = async id => {
    return new Promise(resolve => {
      getApi(`/invitation/${id}`)
        .then(res => {
          resolve(res.data)
        })
        .catch(() => {
          resolve()
        })
    })
  }

  getSubOrganisations = async type => {
    if (type.indexOf("Other") !== -1) {
      this.form.isOther = true
    }

    const result = await getApi(`/organisation/${type}`)
    return result.data
  }

  getSubOrganisationForTypeWithSearchTerm = (type, searchTerm) => {
    if (type.indexOf("Other") !== -1) {
      this.form.isOther = true
    }

    if (this.source) {
      this.source.cancel("Cancelled due to new request")
    }

    // Save cancel token for current request
    this.source = CancelToken.source()

    return new Promise((resolve, reject) => {
      getApi(
        `/organisation/nameTypeSearch?type=${encodeURI(type)}&searchTerm=${encodeURI(searchTerm)}`,
        {
          cancelToken: this.source.token,
        }
      )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          }
          resolve(res)
        })
        .catch(error => reject(error))
    })
  }

  getApplicationData = async id => {
    const result = await getApi(`/application/${id}`)
    return result.data.name
  }

  handleFormChange = (name, value, index) => {
    if (index !== null && index !== undefined) {
      if (value === null || value === undefined) {
        const copy = [...this.form[name]]
        copy.splice(index, 1)
        this.form[name] = copy
      } else {
        this.form[name][index] = value
      }
    } else {
      this.form[name] = value
    }
  }

  assignApplicationName = name => {
    this.externalApplicationName = name
  }

  submit = form => {
    const req = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      isSTP: form.isSTP,
      primaryOrganisationCode: form.primaryOrganisationCode,
      primaryOrganisationName: form.primaryOrganisationName,
      jobRole: form.job,
      region: form.region.code,
      termsAccepted: form.terms,
      invitationId: form.invitationId,
      secondaryOrganisations: form.secondaryOrganisations,
      externalApplicationId: form.externalApplicationId,
      isOther: form.isOther,
      organisationType: form.organisationType,
    }

    if (req.secondaryOrganisations.length === 0) {
      delete req.secondaryOrganisations
    }

    return new Promise(resolve => {
      postApi("/registration/submit", { ...req })
        .then(res => resolve(res.data.registrationStatus))
        .catch(e => {
          if (e.response.status === 422) {
            const { errors, userExists } = e.response.data
            form.userExists = userExists

            Object.keys(errors).forEach(e => {
              var errorKey = _.camelCase(e)
              if (errors[e]) {
                const prop = errors[e]

                const val = {
                  key: errorKey,
                  value: [
                    {
                      fieldError: prop,
                      summaryError: prop,
                    },
                  ],
                }

                this.formErrors.addOrUpdateWithPredicate(
                  err => err.key === errorKey,
                  err => (err.value = val),
                  val
                )
              } else {
                this.formErrors.removeWithPredicate(x => x.key === errorKey)
              }
            })
          }
          document.getElementById("root").scrollTop = 0
          resolve()
        })
    })
  }
}

export default new RegistrationFormStore()
