import { ICollection } from "../interfaces/dataTypes/interfaces"

abstract class Collection<T> implements ICollection<T> {
  values: T[]

  constructor() {
    this.values = []
  }

  addMany = (values: T[]) => {
    for (const val of values) {
      this.values.push(val)
    }
  }

  addRange = (...values: T[]) => {
    for (const val of values) {
      this.values.push(val)
    }
  }

  removeAll = () => (this.values = [])

  removeWithPredicate = (matchfn: (value: T) => boolean) => {
    this.values = this.values.filter(v => !matchfn(v))
  }

  addOrUpdateWithPredicate = (
    matchfn: (value: T) => boolean,
    updatefn: (value: T) => void,
    ...values: T[]
  ) => {
    for (const val of values) {
      const matchingIdx = this.values.findIndex(matchfn)
      if (matchingIdx !== -1) {
        updatefn(this.values[matchingIdx])
      } else {
        this.values.push(val)
      }
    }
  }

  hasWithPredicate = (callbackfn: (value: T) => boolean) => {
    for (const val of this.values) {
      if (callbackfn(val)) {
        return true
      }
    }
    return false
  }

  update = (matchfn: (value: T) => boolean, updatefn: (value: T) => void) => {
    for (const val of this.values) {
      if (matchfn(val)) {
        updatefn(val)
      }
    }
  }

  firstWithPredicate = (callbackfn: (value: T) => boolean) => {
    for (const val of this.values) {
      if (callbackfn(val)) {
        return val
      }
    }
    return undefined
  }

  addOrUpdate = (...values: T[]) => {
    for (const pair of values) {
      const idx = this.values.findIndex(p => p === pair)
      if (idx !== -1) {
        this.values[idx] = pair
      } else {
        this.values.push(pair)
      }
    }
  }

  add = (value: T) => this.values.push(value)

  remove = (value: T) => {
    const idx = this.values.findIndex(p => p === value)
    if (idx !== -1) {
      this.values.splice(idx, 1)
    }
  }

  map = <TReturn>(callbackfn: (value: T, index: number) => TReturn) => {
    return this.values.map(callbackfn)
  }

  forEach = (callbackfn: (value: T) => void) => {
    for (const pair of this.values) {
      callbackfn(pair)
    }
  }

  has = (value: T) => this.values.findIndex(p => p === value) !== -1

  get = (value: T) => this.values.find(p => p === value)

  get length(): number {
    return this.values.length
  }
}

export default Collection
