import Link from "@govuk-react/link"
import React, { useContext, useEffect, useState } from "react"
import { CloseIcon } from "../../../global/components/icons"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { useStores } from "../../../global/hooks"
import { IAccessPermissions } from "../../../global/interfaces/accessPermissions"
import { IApplicationTile } from "../../../global/interfaces/application/interfaces"
import "./styles.scss"

interface IProductModalContentProps {
  product: IApplicationTile
  close: () => void
}

const ProductModalContent = (props: IProductModalContentProps) => {
  const {
    product: { id, title: productTitle, description, applicationUserSupportEmail, categories },
    close,
  } = props

  const {
    accessPermissionsStore: { getAllAccessPermissions },
  } = useStores()

  const [permissions, setPermissions] = useState<IAccessPermissions | null>(null)
  const [loading, setLoading] = useState(true)

  const { wrapWithLoader } = useContext(LoaderContext)

  useEffect(() => {
    wrapWithLoader(async () => {
      setPermissions(await getAllAccessPermissions(id))
      setLoading(false)
    })
  }, [])

  const AccessDescriptor = (props: {
    collection: { name: string }[]
    title: string
    noResultsMessage: string
  }) => {
    const { collection, title, noResultsMessage } = props

    return (
      <>
        <h5 className="product-modal-content__access-title">{title}</h5>
        <p className="product-modal-content__list-paragraph">
          {collection.length === 0 ? noResultsMessage : collection.map(a => a.name).join(", ")}
        </p>
      </>
    )
  }

  return (
    <div id="modal-content">
      <div className="product-modal-content__modal-header-container">
        <h3 className="product-name">{`About ${productTitle}`}</h3>
        <button className="product-modal-content__close-icon-container" onClick={close}>
          <CloseIcon />
        </button>
      </div>
      <h5 className="product-modal-content__access-title">Description</h5>
      <p className="product-modal-content__description">{description}</p>

      <AccessDescriptor
        collection={categories.map(x => ({ name: x }))}
        title="Tags"
        noResultsMessage="Product has no tags"
      />

      {!loading && (
        <>
          <AccessDescriptor
            collection={permissions?.automaticAccess || []}
            title="Automatic access granted to"
            noResultsMessage="Product has no automatic access list"
          />
          <AccessDescriptor
            collection={permissions?.requestAccess || []}
            title="Request access granted to"
            noResultsMessage="Product has no request access list"
          />
        </>
      )}

      <p className="product-modal-content__support-link-container">
        <Link
          className="product-modal-content__support-link"
          href={`mailto:${applicationUserSupportEmail}`}
        >
          Contact support team
        </Link>
      </p>
    </div>
  )
}

export default ProductModalContent
