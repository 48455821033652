import React, { useContext, useEffect, useState } from "react"
import { withWrapper, withTermsCheck } from "../global/components/HOC"
import { LoaderContext } from "../global/components/loaderProvider"
import Tabs from "../global/components/tabPanel"
import { useStores } from "../global/hooks"
import { Routes } from "../global/enums"
import Favourites from "./components/favouritesComponent/favourites"
import ProductsAToZPage from "./components/productsAToZPage"
import Text from "./components/text/text"

import "./styles.scss"

const AtoZLandingPage = () => {
  const {
    atozStore: { allAtoZProducts, toggleApplicationFavourite, getAllAzProductsList },
  } = useStores()

  const { wrapWithLoader } = useContext(LoaderContext)
  const pageTabs = [
    {
      name: "A-Z index",
      control: (
        <ProductsAToZPage
          products={allAtoZProducts}
          toggleApplicationFavourite={toggleApplicationFavourite}
        />
      ),
    },
    {
      name: "My favourites",
      control: (
        <Favourites
          products={allAtoZProducts}
          toggleApplicationFavourite={toggleApplicationFavourite}
        />
      ),
    },
  ]

  const [selectedTab, setSelectedTab] = useState(pageTabs[0].name)

  useEffect(() => {
    wrapWithLoader(async () => {
      await getAllAzProductsList()
    })
  }, [selectedTab])

  return (
    <div className="az-page" id="azpage-main-container">
      <div className="az-page__container">
        <div className="az-page__header">
          <h1>NHS England applications</h1>
          <Text textType="description">
            Discover the analytical and digital applications published by NHS England which are
            available to you, based on the organisation where you work.
          </Text>
        </div>
        <div id="lower-container-col">
          <Tabs className="az-page__tabs-container">
            <Tabs.List>
              <div className="az-page__list-margined-container">
                {pageTabs.map(t => {
                  return (
                    <Tabs.Tab
                      key={t.name}
                      className={`az-page__tabs-item${
                        selectedTab === t.name ? " az-page__tabs-item--selected" : ""
                      }`}
                      onClick={() => setSelectedTab(t.name)}
                      selected={selectedTab === t.name}
                    >
                      <div
                        id={t.name.toLowerCase()}
                        className={
                          selectedTab === t.name
                            ? "az-page__tabs-item-inner az-page__tabs-item-inner--selected"
                            : "az-page__tabs-item-inner"
                        }
                      >
                        {t.name}
                      </div>
                    </Tabs.Tab>
                  )
                })}
              </div>
            </Tabs.List>
            {pageTabs.map(t => {
              return (
                <Tabs.Panel key={t.name} selected={selectedTab === t.name}>
                  {<div className="az-page__tab-grid">{t.control}</div>}
                </Tabs.Panel>
              )
            })}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default withWrapper(withTermsCheck(AtoZLandingPage, Routes.AToZLandingPage))
