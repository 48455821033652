import { observer } from "mobx-react"
import React from "react"
import TextButton from "../../../../../global/components/textButton"
import { useStores } from "../../../../../global/hooks"
import Downloads from "../../components/downloads/downloads"
import SelectionOptions from "../selectionOptions"
import "./styles.scss"

const IndividualTabDownloadView = observer((): React.ReactElement => {
  const mapBEFieldsToIds = {
    Downloads: "download-form-group",
    EmbedPathUrl: "embed-path-url-group",
  }

  const {
    tableauReportViewerConfigurationStore: {
      tableauReportViewerData: { workbook },
      addDownload,
    },
  } = useStores()

  const addBlankDownload = (event?: React.MouseEvent) => {
    event?.preventDefault()
    event?.stopPropagation()
    addDownload({
      id: null,
      dashboardName: "",
      worksheetName: "",
      title: "",
    })
  }

  return (
    <>
      <div className="download-form-group" id={mapBEFieldsToIds.Downloads}>
        <h5>Configure downloadable datasets</h5>
        <h5>Tab/Dashboard name</h5>
        <p className="download-form-group__infoText">
          'Tabs' are the user interface objects that divide your product into sections. In Tableau
          they are referred to as ‘Dashboards’.
        </p>
        <p className="download-form-group__infoText">
          Tab names are case sensitive. For example, Table View.
        </p>

        <Downloads />
      </div>

      <div className="data-download-form-group">
        <legend className="individualTabDownloadView__heading">
          Select available download format(s)
        </legend>

        <SelectionOptions />

        {workbook.downloads.length < workbook.views.length && (
          <TextButton
            className="downloads__plus"
            onClick={(event?: React.MouseEvent) => addBlankDownload(event)}
          >
            Add another download option
          </TextButton>
        )}
      </div>
    </>
  )
})

export default IndividualTabDownloadView
