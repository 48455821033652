import React from "react"
import TextButton from "../../../../global/components/textButton"

interface INoDownloadsAllowedModalContent {
  close: () => void
}

const NoDownloadsAllowedModalContent: React.FC<INoDownloadsAllowedModalContent> = ({ close }) => {
  return (
    <>
      <div className="modal-content">
        <span className="modal-title">No data to download.</span>
      </div>
      <p>Please contact support via the link in the footer.</p>
      <div className="modal-divider" />
      <TextButton onClick={close}>Close</TextButton>
    </>
  )
}

export default NoDownloadsAllowedModalContent
