import React from "react"
import "./styles.scss"

interface IOrganisationTableProps {
  userODSCode: string
  userRegion: string
  isFreeTypedOrg: boolean
}

const OrganisationTable = (props: IOrganisationTableProps) => {
  const { userODSCode, userRegion, isFreeTypedOrg } = props

  return (
    <div className="organisation-table">
      <div className="organisation-table__code">{userODSCode}</div>
      {isFreeTypedOrg && (
        <div className="organisation-table__free-typed-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
              fill="#DA291C"
              d="M9.175 2.041c-.127-.254-.333-.46-.588-.587-.649-.325-1.438-.062-1.763.587L1.453 12.784c-.091.182-.139.384-.139.588 0 .725.589 1.314 1.314 1.314h10.743c.204 0 .405-.048.588-.139.649-.325.912-1.114.587-1.763L9.176 2.04zm0-1.763c.509.254.921.667 1.176 1.176l5.37 10.742c.65 1.298.124 2.877-1.175 3.527-.365.182-.767.277-1.175.277H2.628C1.177 16 0 14.823 0 13.372c0-.409.095-.811.277-1.176L5.65 1.454C6.298.155 7.877-.371 9.175.278zM8 10.086c-.726 0-1.314.588-1.314 1.314 0 .726.588 1.314 1.314 1.314.726 0 1.314-.588 1.314-1.314 0-.726-.588-1.314-1.314-1.314zm1.314-5.257H6.686l.657 3.943h1.314l.657-3.943z"
            />
          </svg>
          <span>This organisation name has been free-typed</span>
        </div>
      )}
      <div className="organisation-table__region">{userRegion}</div>
    </div>
  )
}

export default OrganisationTable
