import React, { useContext } from "react"
import { observer } from "mobx-react"
import { LoaderContext } from "../../../../global/components/loaderProvider"
import { useStores } from "../../../../global/hooks"
import { TableauViz, Workbook } from "@tableau/embedding-api"

import "./styles.scss"

interface IViewsDropdownProps {
  viewsReference: React.RefObject<HTMLDivElement>
  onButtonClicked: (
    id: number,
    tableauVisualization: TableauViz,
    visualizationLoading: boolean
  ) => void
  closeModal: () => void
}

const ViewsDropdown = observer(
  ({ viewsReference, onButtonClicked, closeModal }: IViewsDropdownProps): JSX.Element => {
    const {
      tableauReportViewerStore: {
        toolbarButtons: { customViewManagement },
        setCurrentView,
        currentView,
        tableauVisualization,
        visualizationLoading,
        loadingCustomViews,
        customViews,
      },
    } = useStores()

    const { wrapWithLoader } = useContext(LoaderContext)

    const onViewSelected = async (viewName: string, workbook: Workbook) => {
      await wrapWithLoader(async () => {
        const customViewName = viewName.length === 0 ? (null as unknown as string) : viewName
        try {
          const customView = await workbook.showCustomViewAsync(customViewName)
          setCurrentView(customView.name)
        } catch {
          setCurrentView("")
        }
      })
      closeModal()
    }

    if (loadingCustomViews || visualizationLoading || !tableauVisualization) return <></>

    return (
      <div id="views-modal" ref={viewsReference}>
        {customViews.length > 0 ? (
          <>
            {customViews.map(view => {
              const name = view.name
              return (
                <button
                  key={name}
                  className="modal-option"
                  onClick={() => onViewSelected(name, tableauVisualization.workbook)}
                >
                  <span className={`modal-option-text${name === currentView ? "-selected" : ""}`}>
                    {name}
                  </span>
                </button>
              )
            })}

            <button
              className="modal-option"
              onClick={() => onViewSelected("", tableauVisualization.workbook)}
            >
              <span className={`modal-option-text${!currentView ? "-selected" : ""}`}>
                Original
              </span>
            </button>
          </>
        ) : (
          <div className="modal-option-noselect">
            <span className="modal-option-text">
              Create a custom view to quickly access the data you require in future
            </span>
          </div>
        )}
        <div className="modal-divider" />

        <div
          className="modal-option-management"
          onClick={() =>
            onButtonClicked(customViewManagement.id, tableauVisualization, visualizationLoading)
          }
        >
          <span className="modal-option-text">
            {customViews.length > 0 ? "Create new and manage" : "Create new"}
          </span>
        </div>
      </div>
    )
  }
)

export default ViewsDropdown
