import {
  AppGalleryStore,
  ApplicationStore,
  ApprovalQuestionStore,
  ContactInfoStore,
  CoreProductTopicStore,
  ExternalRouteStore,
  FeatureToggledRouteStore,
  IFeatureToggledRouteStore,
  IAppGalleryStore,
  IApplicationStore,
  IApprovalQuestionStore,
  IContactInfoStore,
  ICoreProductTopicStore,
  IExternalRouteStore,
  IJobRoleStore,
  IMessageKeyStore,
  IOrganisationStore,
  IProductOwnerAdminStore,
  JobRoleStore,
  MessageKeyStore,
  OrganisationStore,
  ProductOwnerAdminStore,
} from "."
import SystemAdminStore, { ISystemAdminStore } from "../../admin/store/systemAdminStore"
import AnalyticalProductsStore, {
  IAnalyticalProductsStore,
} from "../../analyticalProducts/store/analyticalProductsStore"
import AtoZStore, { IAToZStore } from "../../aToZLandingPage/store/aToZStore"
import BulkInvitationsStore, { IBulkInvitationsStore } from "../../bulkInvitations/store"
import InvitationStore, { IInvitationStore } from "../../invitePage/invitationStore"
import AdminInfoStore from "../../product/components/adminInfoView/store"
import ApprovalQuestionsStore, {
  IApprovalQuestionsStore,
} from "../../product/components/approvalQuestionsView/store"
import CoreProductInformationStore from "../../product/components/coreProductInformationView/store"
import ProductInfoStore, {
  IProductInfoStore,
} from "../../product/components/productInformationView/store"
import TableauAccessViewStore, {
  ITableauAccessViewStore,
} from "../../product/components/tableauAccessView/tableauAccessViewStore"
import TableauReportViewerConfigurationStore, {
  ITableauReportViewerConfigurationStore,
} from "../../product/components/tableauReportViewerView/store"
import ProductStore, { IProductStore } from "../../product/store/productStore"
import EmailVerificationStore, {
  IEmailVerificationStore,
} from "../../profilePage/components/emailVerificationView/store"
import RegistrationFormStore from "../../registrationForm/store/registrationFormStore"
import SecurityPasswordFormStore, {
  ISecurityPasswordFormStore,
} from "../../securityPasswordForm/store"
import StepTwoStore, { IStepTwoStore } from "../../stepTwo/stepTwoStore"
import TableauReportViewerStore, {
  ITableauReportViewerStore,
} from "../../tableauReportViewer/store/tableauReportViewerStore"
import AccessPermissionsStore, { IAccessPermissionsStore } from "./accessPermissionsStore"
import OrganisationGroupStore, { IOrganisationGroupStore } from "./organisationGroupStore"
import ProfileStore, { IProfileStore } from "./profileStore"
import UserStore, { IUserStore } from "./userStore"
import ServerSettingsStore, { IServerSettingsStore } from "./serverSettingsStore"
import SearchStore, { ISearchStore } from "./searchStore"
import NotificationStore, { INotificationStore } from "./notificationStore"

// Remove once all stores are TypeScript
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IRegistrationRootStore {
  notificationStore: INotificationStore
  profileStore: IProfileStore
  searchStore: ISearchStore
  applicationStore: IApplicationStore
  registrationFormStore: any
  securityPasswordFormStore: ISecurityPasswordFormStore
  systemAdminStore: ISystemAdminStore
  productOwnerAdminStore: IProductOwnerAdminStore
  appGalleryStore: IAppGalleryStore
  productStore: IProductStore
  invitationStore: IInvitationStore
  stepTwoStore: IStepTwoStore
  organisationStore: IOrganisationStore
  messageKeyStore: IMessageKeyStore
  tableauReportViewerStore: ITableauReportViewerStore
  analyticalProductsStore: IAnalyticalProductsStore
  jobRoleStore: IJobRoleStore
  serverSettingsStore: IServerSettingsStore
  approvalQuestionStore: IApprovalQuestionStore
  externalRouteStore: IExternalRouteStore
  contactInfoStore: IContactInfoStore
  featureToggledRouteStore: IFeatureToggledRouteStore
  bulkInvitationsStore: IBulkInvitationsStore
  accessPermissionsStore: IAccessPermissionsStore
  adminInfoStore: any
  approvalQuestionsStore: IApprovalQuestionsStore
  productInfoStore: IProductInfoStore
  tableauAccessViewStore: ITableauAccessViewStore
  tableauReportViewerConfigurationStore: ITableauReportViewerConfigurationStore
  coreProductInformationStore: any
  coreProductTopicStore: ICoreProductTopicStore
  atozStore: IAToZStore
  emailVerificationStore: IEmailVerificationStore
  organisationGroupStore: IOrganisationGroupStore
  userStore: IUserStore
}
/* eslint-enable @typescript-eslint/no-explicit-any */

class RegistrationRootStore implements IRegistrationRootStore {
  notificationStore: INotificationStore = NotificationStore
  profileStore: IProfileStore = ProfileStore
  searchStore: ISearchStore = SearchStore
  applicationStore: IApplicationStore = ApplicationStore
  registrationFormStore = RegistrationFormStore
  securityPasswordFormStore: ISecurityPasswordFormStore = SecurityPasswordFormStore
  systemAdminStore: ISystemAdminStore = SystemAdminStore
  productOwnerAdminStore: IProductOwnerAdminStore = ProductOwnerAdminStore
  appGalleryStore: IAppGalleryStore = AppGalleryStore
  productStore: IProductStore = ProductStore
  invitationStore: IInvitationStore = InvitationStore
  stepTwoStore: IStepTwoStore = StepTwoStore
  organisationStore: IOrganisationStore = OrganisationStore
  messageKeyStore: IMessageKeyStore = MessageKeyStore
  tableauReportViewerStore: ITableauReportViewerStore = TableauReportViewerStore
  analyticalProductsStore: IAnalyticalProductsStore = AnalyticalProductsStore
  jobRoleStore: IJobRoleStore = JobRoleStore
  serverSettingsStore: IServerSettingsStore = ServerSettingsStore
  approvalQuestionStore: IApprovalQuestionStore = ApprovalQuestionStore
  externalRouteStore: IExternalRouteStore = ExternalRouteStore
  contactInfoStore: IContactInfoStore = ContactInfoStore
  featureToggledRouteStore: IFeatureToggledRouteStore = FeatureToggledRouteStore
  bulkInvitationsStore: IBulkInvitationsStore = BulkInvitationsStore
  accessPermissionsStore: IAccessPermissionsStore = AccessPermissionsStore
  adminInfoStore = AdminInfoStore
  approvalQuestionsStore: IApprovalQuestionsStore = ApprovalQuestionsStore
  productInfoStore: IProductInfoStore = ProductInfoStore
  tableauAccessViewStore: ITableauAccessViewStore = TableauAccessViewStore
  tableauReportViewerConfigurationStore: ITableauReportViewerConfigurationStore =
    TableauReportViewerConfigurationStore
  coreProductInformationStore = CoreProductInformationStore
  coreProductTopicStore: ICoreProductTopicStore = CoreProductTopicStore
  atozStore: IAToZStore = AtoZStore
  emailVerificationStore: IEmailVerificationStore = EmailVerificationStore
  organisationGroupStore: IOrganisationGroupStore = OrganisationGroupStore
  userStore: IUserStore = UserStore
}

export default new RegistrationRootStore()
