import _ from "lodash"
import { observer } from "mobx-react"
import {
  BodyText,
  Button,
  ErrorSummary,
  Form,
  Input,
  Label,
  Select,
  Textarea,
} from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { IconPlus } from "../../../global/components/icons"
import TextButton from "../../../global/components/textButton"
import { useStores } from "../../../global/hooks"
import { IStringKeyedDictionary } from "../../../global/interfaces/dataTypes/interfaces"
import { scrollRefIntoView } from "../../../global/utils/dom"
import { ProductEditView } from "../../enums/enums"
import ProductViewRedirect from "../productViewRedirect"
import "./styles.scss"

const mapBEFields: IStringKeyedDictionary<string> = {
  FriendlyName: "product-information-name",
  URL: "product-information-url",
  Description: "product-information-description",
  Categories: "product-information-category",
}

const ProductInformationView = observer(() => {
  const { productStore: metadataStore, productInfoStore } = useStores()

  const { errors, productInformation } = productInfoStore.productInformationData
  const [isEditMode, setIsEditMode] = useState(!metadataStore.published)
  const [redirectingToTaskList, setRedirectingToTaskList] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const errorContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    productInfoStore.getProductInformationData(metadataStore.product.id || "")
    return () => productInfoStore.resetState()
  }, [])

  useEffect(() => setIsEditMode(!metadataStore.published), [metadataStore.published])

  const addNewCategory = (): void => {
    if (!_.isEmpty(productInformation.categories[productInformation.categories.length - 1])) {
      productInformation.categories.push({ id: "", name: "" })
    }
  }
  const deleteCategory = (catId: string): void => {
    const filteredCategories = productInformation.categories.filter(c => c.id != catId)
    productInformation.categories = [...filteredCategories]
  }

  const completeAndSave = async () => {
    productInformation.complete = true
    save()
  }

  const saveAndReturn = async () => {
    productInformation.complete = false
    save()
  }

  const save = (): void => {
    productInfoStore
      .saveProductInformation(metadataStore.product.id ?? "")
      .then(async () => {
        await metadataStore.getTaskList()
        setHasErrors(false)
        metadataStore.setTaskModified(ProductEditView.Product, productInformation.complete)
        setRedirectingToTaskList(true)
      })
      .catch(() => {
        setHasErrors(true)
        scrollRefIntoView(errorContainerRef)
      })
  }

  if (redirectingToTaskList) {
    return <ProductViewRedirect view={ProductEditView.Tasks} />
  }

  return (
    <div className="productInformationView">
      <div ref={errorContainerRef}>
        {hasErrors && (
          <ErrorSummary role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
            {Object.keys(errors).map((item, index) =>
              !errors[item] ? null : (
                <div key={index} style={{ listStyle: "none" }}>
                  <ErrorSummary.Item href={`#${mapBEFields[item]}`}>
                    {errors[item]}
                  </ErrorSummary.Item>
                </div>
              )
            )}
          </ErrorSummary>
        )}
      </div>
      <div className="productInformationView__page-heading">
        <Label className="productInformationView__page-heading_label" isPageHeading>
          {" "}
          Product information
        </Label>
        {!isEditMode && (
          <TextButton
            className="productInformationView__change-settings-button"
            onClick={() => setIsEditMode(true)}
          >
            Change settings
          </TextButton>
        )}
      </div>
      {!isEditMode ? (
        <div className="productInformationView__readonly-table">
          <div className="productInformationView__readonly-table__readonly-row">
            <div className="productInformationView__readonly-table__readonly-row__title-text">
              Name of product
            </div>
            <div className="productInformationView__readonly-table__readonly-row__product-info-text">
              {productInformation.friendlyName}
            </div>
          </div>
          <div className="productInformationView__readonly-table__readonly-row">
            <div className="productInformationView__readonly-table__readonly-row__title-text">
              Description of product
            </div>
            <div className="productInformationView__readonly-table__readonly-row__product-info-text">
              {productInformation.description}
            </div>
          </div>
          <div className="productInformationView__readonly-table__readonly-row">
            <div className="productInformationView__readonly-table__readonly-row__title-text">
              Product URL
            </div>
            <div className="productInformationView__readonly-table__readonly-row__product-info-text">
              {productInformation.url}
            </div>
          </div>
          <div className="productInformationView__readonly-table__readonly-row">
            <div className="productInformationView__readonly-table__readonly-row__title-text">
              Product categories
            </div>
            <div className="productInformationView__readonly-table__readonly-row__product-info-text">
              {productInformation.categories.map(c => c.name).join(", ")}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Form className="productInformationView__product-info-form-group">
            <Input
              id="product-information-name"
              className="input-field"
              label="Name of product"
              hint="This is the name the users will see."
              value={productInformation.friendlyName || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (productInformation.friendlyName = (e.target as HTMLInputElement).value)
              }
              error={errors.FriendlyName}
            />
          </Form>
          <Form className="productInformationView__product-info-form-group">
            <Textarea
              id="product-information-description"
              label="Description of product"
              hint="This is the description the users will see.
                                      Don't repeat the product name here, and do try to place keywords close to the beginning."
              rows={5}
              value={productInformation.description || ""}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                (productInformation.description = (e.target as HTMLTextAreaElement).value)
              }
              error={errors.Description}
            />
          </Form>
          <Form className="productInformationView__product-info-form-group">
            <Input
              id="product-information-url"
              className="input-field"
              label="Product URL"
              hint="The full URL the user can access the product directly."
              value={productInformation.url || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                (productInformation.url = (e.target as HTMLInputElement).value)
              }
              error={errors.URL}
            />
          </Form>
          <Form className="productInformationView__product-info-form-group">
            <Label
              htmlFor="productInformationView__product-information-category"
              className="input-label"
            >
              Categories of product
            </Label>
            <BodyText className="productInformationView__input-hint">
              You can select a maximum of three categories.
            </BodyText>
            <div
              id="product-information-category"
              className="productInformationView__category-select-container"
            >
              {productInformation.categories.map((x, idx) => {
                return (
                  <div key={idx} id={`${idx}-select-item`}>
                    <Select
                      id="productInformationView__product-information-category"
                      className="productInformationView__product-information-category"
                      onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                        const val = (e.target as HTMLSelectElement).value
                        const cat = productInfoStore.categories.find(c => c.id === val)
                        if (cat) {
                          productInformation.categories[idx] = { ...cat }
                        }
                      }}
                      value={productInformation.categories[idx].id || "DEFAULT"}
                      error={errors.Categories}
                    >
                      <Select.Option value="DEFAULT" disabled>
                        Select category
                      </Select.Option>
                      {productInfoStore.categories.map(c => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                    {idx > 0 && (
                      <TextButton
                        className={`${idx}-category-delete productInformationView__deletecategory-textbutton`}
                        onClick={() => deleteCategory(x.id)}
                      >
                        Delete
                      </TextButton>
                    )}
                  </div>
                )
              })}
              {productInformation.categories.length < 3 && (
                <div
                  className="productInformationView__category-add-button"
                  onClick={addNewCategory}
                >
                  <IconPlus />
                  <div className="productInformationView__category-add-button__button_text">
                    Add another category
                  </div>
                </div>
              )}
            </div>
          </Form>
        </>
      )}
      {isEditMode &&
        (metadataStore.published ? (
          <div className="productInformationView__published-save-control">
            <Label className="input-label">Publish changes</Label>
            <BodyText className="productInformationView__input-hint">
              By clicking 'Save and publish', you confirm that you are making these changes in line
              with business policies.
            </BodyText>
            <Button
              id="productInformationView__button-controls__publish-button"
              onClick={completeAndSave}
            >
              Save and publish
            </Button>
          </div>
        ) : (
          <div className="productInformationView__button-controls">
            <Button
              id="productInformationView__button-controls__publish-button"
              onClick={completeAndSave}
            >
              Save and complete
            </Button>
            <TextButton
              className="productInformationView__button-controls__return-link"
              onClick={saveAndReturn}
            >
              Save and return to task list
            </TextButton>
          </div>
        ))}
    </div>
  )
})

export default ProductInformationView
