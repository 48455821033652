import { createBrowserHistory } from "history"
import ReactGA from "react-ga"

const history = createBrowserHistory()

// Tracks the internal redirects/traffic on the website
history.listen(update => {
  const pathname = update.location.pathname

  ReactGA.set({ page: pathname })
  ReactGA.pageview(pathname)
})

export default history
