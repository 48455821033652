import { makeAutoObservable } from "mobx"
import { IJobRole } from "../interfaces/jobRole/interfaces"
import { get } from "../utils/api"

export interface IJobRoleStore {
  getJobRoles: () => Promise<IJobRole[]>
}

const storeUrls = {
  getAllJobRoles: "/jobrole/",
}

class JobRoleStore implements IJobRoleStore {
  constructor() {
    makeAutoObservable(this)
  }
  getJobRoles = (): Promise<IJobRole[]> => {
    return new Promise<IJobRole[]>((resolve, reject) => {
      get<IJobRole[]>(storeUrls.getAllJobRoles).then(res => {
        if (res.status === 200) {
          resolve(res.data)
        }

        reject()
      })
    })
  }
}

export default new JobRoleStore()
