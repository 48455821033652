import moment from "moment"
import { BodyText, Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { Routes } from "../global/enums"
import { MessageKey } from "../global/enums/messageKeys/enums"
import { useMessageKeys } from "../global/hooks"
import "./styles.scss"
import { useLocation } from "react-router-dom"

const ServiceUnavailable = () => {
  const [itOps] = useMessageKeys(MessageKey.SupportEmailAddressLink)

  const currentDateTime = moment().format("DD/MM/YYYY HH:mm:ss")

  const location = useLocation()

  const unhandledMessage = (
    <>
      <Label className="service-unavailable__message" isPageHeading>
        Sorry, there is a problem with the service
      </Label>
      <BodyText>
        Please try again later, or go back to the <a href={Routes.AToZLandingPage}>homepage</a>
      </BodyText>
    </>
  )

  const forbiddenMessage = (
    <>
      <Label className="service-unavailable__message" isPageHeading>
        Sorry, there is a problem
      </Label>
      <BodyText>This user is not yet authorised to make this request</BodyText>
    </>
  )

  return (
    <Container className="service-unavailable">
      <Col width="three-quarters">
        {location.pathname.includes("forbidden") ? forbiddenMessage : unhandledMessage}
        <BodyText>
          If this problem persists, please contact the <a href={itOps}>support desk</a> and quote{" "}
          <strong>NHS England applications</strong> and the following date and time{" "}
          <strong>{currentDateTime}</strong>.
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(ServiceUnavailable)
