import { BodyText, Col, Container, Label } from "nhsuk-react-components"
import React from "react"
import { withWrapper } from "../global/components/HOC"
import { MessageKey } from "../global/enums/messageKeys/enums"
import { useMessageKeys } from "../global/hooks"
import "./styles.scss"

const AccessibilityPolicy = () => {
  const [itOps, serviceDesk] = useMessageKeys(
    MessageKey.SupportEmailAddressLink,
    MessageKey.SupportEmailAddress
  )

  return (
    <Container className="accessibility-policy">
      <Col width="three-quarters" className="accessibility-policy__container">
        <Label isPageHeading>Accessibility statement for NHS England applications</Label>
        <BodyText className="accessibility-policy__container__body-text">
          This website is run by NHS England. We want as many people as possible to be able to use
          this website. For example, that means you should be able to:
        </BodyText>
        <ul>
          <li className="accessibility-policy__container__list-item">
            change colours, contrast levels and fonts{" "}
          </li>
          <li className="accessibility-policy__container__list-item">
            zoom in up to 300% without the text spilling off the screen{" "}
          </li>
          <li className="accessibility-policy__container__list-item">
            navigate most of the website using just a keyboard{" "}
          </li>
          <li className="accessibility-policy__container__list-item">
            navigate most of the website using speech recognition software{" "}
          </li>
          <li className="accessibility-policy__container__list-item">
            listen to most of the website using a screen reader (including the most recent versions
            of JAWS, NVDA and VoiceOver)
          </li>
        </ul>
        <BodyText className="accessibility-policy__container__body-text">
          We’ve also made the website text as simple as possible to understand.
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">
          <a href="https://mcmw.abilitynet.org.uk/" rel="noreferrer" target="_blank">
            AbilityNet
          </a>
          has advice on making your device easier to use if you have a disability.
        </BodyText>
        <Label size="m">What to do if you cannot access parts of this website</Label>
        <BodyText className="accessibility-policy__container__body-text">
          If you need information on this website in a different format like accessible PDF or large
          print:
        </BodyText>
        <ul>
          <li className="accessibility-policy__container__list-item">
            email <a href={itOps}>{serviceDesk}</a>
          </li>
        </ul>
        <Label size="m">
          Reporting accessibility problems with this website or any of the data products accessed
          from this website
        </Label>
        <BodyText className="accessibility-policy__container__body-text">
          The NHS England applications website is a gateway service, giving users access to many
          products and tools provided by NHS England.
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">
          This statement refers to the design of the NHS England applications website, and not to
          the design of the data products and tools to which it gives access.
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">
          We’re always looking to improve the accessibility of the NHS England applications website.
          If you find any problems or think we’re not meeting accessibility requirements, contact:{" "}
          <a href={serviceDesk}>{serviceDesk}</a>
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">
          If you find problems with any of the data products and tools to which it gives access,
          contact the product’s own support team. You will find their contact details quoted in the
          product itself. If you are unable to find a contact address, or you are unhappy with the
          response you get, send your comments to: <a href={serviceDesk}>{serviceDesk}</a>.
        </BodyText>
        <Label size="m">Enforcement procedure</Label>
        <BodyText className="accessibility-policy__container__body-text">
          The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public
          Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018
          (the ‘accessibility regulations’). If you’re not happy with how we respond to your
          complaint,
          <a href="https://www.equalityadvisoryservice.com/">
            contact the Equality Advisory and Support Service (EASS)
          </a>
          .
        </BodyText>
        <Label size="m">Technical information about this website’s accessibility</Label>
        <BodyText className="accessibility-policy__container__body-text">
          NHS England is committed to making its website accessible, in accordance with the Public
          Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">
          This website is fully compliant with the Web Content Accessibility Guidelines version
          2.1 AA standard.
        </BodyText>
        <Label size="m">How we tested this website</Label>
        <BodyText className="accessibility-policy__container__body-text">
          This website was last tested on April 1 2020. An expert review was carried out by the
          Digital Products and Services team of NHS England, in partnership with a private sector
          design agency.
        </BodyText>
        <BodyText className="accessibility-policy__container__body-text">We tested:</BodyText>
        <ul>
          <li className="accessibility-policy__container__list-item">our main website platform </li>
          <li className="accessibility-policy__container__list-item">our registration forms </li>
          <li className="accessibility-policy__container__list-item">
            our user profile management forms
          </li>
        </ul>
        <BodyText className="accessibility-policy__container__body-text">
          This statement was prepared on April 8 2020.
        </BodyText>
      </Col>
    </Container>
  )
}

export default withWrapper(AccessibilityPolicy)
