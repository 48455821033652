export enum EmailPreferences {
  Immediately,
  Daily,
  Weekly,
}

export enum NotificationType {
  Standard,
  Download,
}

export enum NotificationStatus {
  Succeeded,
  Failed,
}
