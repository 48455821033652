import React from "react"
import { BodyText, Button } from "nhsuk-react-components"
import { ApplicationType } from "../../../global/enums/application"

import "./styles.scss"

const ApprovalControls = props => {
  const { showLabel, selectedItems = [], toggleModal, view, mode, selectedProduct } = props
  return (
    <div
      className="approval-controls"
      id={`approval-controls${props.index ? "-" + props.index : ""}`}
    >
      {showLabel ? (
        <BodyText className="approval-controls__selected-total">
          <strong id="selected-total">{selectedItems.length}</strong> selected
        </BodyText>
      ) : null}

      {(() => {
        switch (view.status) {
          case "approved":
            return (
              <>
                {mode === "productOwnerAdmin" ? (
                  <Button
                    className="approval-controls__button"
                    id="approval-controls-button-remove"
                    secondary={false}
                    disabled={selectedItems.length === 0}
                    onClick={() => toggleModal(true, "remove")}
                  >
                    Remove access
                  </Button>
                ) : (
                  <Button
                    className="approval-controls__button"
                    id="approval-controls-button-remove"
                    secondary={false}
                    disabled={selectedItems.length === 0}
                    onClick={() => toggleModal(true, "remove")}
                  >
                    Deactivate account
                  </Button>
                )}
              </>
            )
          case "suspended":
            return (
              <Button
                className="approval-controls__button"
                id="approval-controls-button-reactivate"
                secondary={false}
                disabled={selectedItems.length === 0}
                onClick={() => toggleModal(true, "reactivate")}
              >
                Reactivate account
              </Button>
            )
          case "rejected":
            return (
              <>
                {mode === "productOwnerAdmin" ? (
                  <Button
                    className="approval-controls__button"
                    id="approval-controls-button-approve"
                    secondary={false}
                    disabled={
                      selectedItems.length === 0 ||
                      (selectedProduct &&
                        selectedProduct.applicationType === ApplicationType.Foundry)
                    }
                    onClick={() => toggleModal(true, "approve")}
                  >
                    Approve request
                  </Button>
                ) : (
                  <Button
                    className="approval-controls__button"
                    id="approval-controls-button-approve"
                    secondary={false}
                    disabled={selectedItems.length === 0}
                    onClick={() => toggleModal(true, "approve")}
                  >
                    Approve registration
                  </Button>
                )}
              </>
            )
          default:
            return (
              <>
                <Button
                  className="approval-controls__button"
                  id="approval-controls-button-approve"
                  secondary={false}
                  disabled={
                    selectedItems.length === 0 ||
                    (selectedProduct && selectedProduct.applicationType === ApplicationType.Foundry)
                  }
                  onClick={() => toggleModal(true, "approve")}
                >
                  Approve
                </Button>
                <Button
                  className="approval-controls__button"
                  id="approval-controls-button-decline"
                  secondary
                  disabled={
                    selectedItems.length === 0 ||
                    (selectedProduct && selectedProduct.applicationType === ApplicationType.Foundry)
                  }
                  onClick={() => toggleModal(true, "decline")}
                >
                  Decline
                </Button>
              </>
            )
        }
      })()}
    </div>
  )
}

export default ApprovalControls
