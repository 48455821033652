import React from "react"
import Dialog from "../../../global/components/dialog"
import TermsAndConditionsText from "../termsAndConditionsText/termsAndConditionsText"

const TermsModal = ({ show, handleClose }: { show: boolean; handleClose: () => void }) => {
  if (!show) return null

  return (
    <Dialog title="Terms and Conditions" onClose={handleClose}>
      <TermsAndConditionsText />
    </Dialog>
  )
}

export default TermsModal
