import { BodyText, Col, Label, Row } from "nhsuk-react-components"
import React, { useEffect, useRef, useState } from "react"
import { Link as ReactLink } from "react-router-dom"
import { LoaderContext } from "../../../../../global/components/loaderProvider"
import { OrganisationKeys } from "../../../../../global/constants/organisation"
import { Routes } from "../../../../../global/enums"
import { useStores } from "../../../../../global/hooks"
import { IRegion } from "../../../../../global/interfaces/region"
import "./styles.scss"

interface IPersonalDetailsProps {
  profileUpdatingEnabled: boolean
}

const PersonalDetails = (props: IPersonalDetailsProps) => {
  const { profileUpdatingEnabled } = props

  const { wrapWithLoader } = React.useContext(LoaderContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [regionName, setRegionName] = useState<string>("")
  const { keyNHSEI, keyOldHEE, keyHEE, keyOldNHSI, keyNHSD, keyOldNHSD } = OrganisationKeys

  const mounted = useRef<boolean>(false)

  const {
    profileStore: store,
    organisationStore: { getRegions },
  } = useStores()

  const displayRegion =
    store.user !== null &&
    [keyNHSEI, keyOldHEE, keyHEE, keyOldNHSI, keyNHSD, keyOldNHSD].indexOf(
      store.user.primaryOrganisationCode
    ) !== -1

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    wrapWithLoader(async () => {
      if (mounted.current) {
        setLoading(true)
      }

      if (store.user && store.user.regionCode && displayRegion) {
        const regions: IRegion[] = await getRegions()
        const foundRegion: IRegion | undefined = regions.find(
          x => x.code === store.user?.regionCode
        )
        if (foundRegion && mounted.current) {
          setRegionName(foundRegion.name)
        }
      }

      if (mounted.current) {
        setLoading(false)
      }
    })
  }, [store.user, displayRegion, getRegions])

  return (
    <div className="personal-details">
      <Row>
        <Label className="personal-details__personal-details-label nhsuk-grid-column-one-third">
          Personal details
        </Label>
        {profileUpdatingEnabled && (
          <div className="personal-details__personal-details-label-col__personal-details-change-link nhsuk-grid-column-one-third">
            <ReactLink to={Routes.ProfileUpdateUrl}>Change</ReactLink>
          </div>
        )}
      </Row>

      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Name</Label>
        </Col>
        <Col width="one-third">
          <BodyText>{store.user?.id && `${store.user.firstName} ${store.user.lastName}`}</BodyText>
        </Col>
        <Col width="one-third"></Col>
      </Row>
      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Email address</Label>
        </Col>
        <Col width="one-third">
          <BodyText>{store.user?.emailAddress}</BodyText>
        </Col>
        <Col width="one-third"></Col>
      </Row>

      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Job role</Label>
        </Col>
        <Col width="one-third">
          <BodyText>{store.user?.jobRole}</BodyText>
        </Col>
        <Col width="one-third"></Col>
      </Row>
      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Organisation</Label>
        </Col>
        <Col width="one-third">
          <BodyText>{`${store.user?.primaryOrganisationName} (${store.user?.primaryOrganisationCode})`}</BodyText>
        </Col>
        <Col width="one-third"></Col>
      </Row>

      {!loading && displayRegion && (
        <>
          <hr />
          <Row>
            <Col width="one-third">
              <Label className="personal-details__profile-label">Region</Label>
            </Col>
            <Col width="one-third">
              <BodyText>{regionName || "-"}</BodyText>
            </Col>
            <Col width="one-third"></Col>
          </Row>
        </>
      )}

      <Label className="personal-details__security-settings-label">Security settings</Label>

      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Password</Label>
        </Col>
        <Col width="one-third">
          <ReactLink to={Routes.ProfileUpdatePasswordUrl}>Change</ReactLink>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col width="one-third">
          <Label className="personal-details__profile-label">Security question</Label>
        </Col>
        <Col width="one-third">
          <ReactLink to={Routes.ProfileUpdateSecurityQuestionUrl}>Change</ReactLink>
        </Col>
      </Row>
    </div>
  )
}

export default PersonalDetails
