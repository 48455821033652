import React from "react"

const StarSolidIcon = () => {
  return (
    <svg
      className="icons__star-solid-icon"
      width="20"
      height="20"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7593 18.6873C15.9484 18.6925 16.1305 18.6169 16.2583 18.4803C16.3861 18.3437 16.4469 18.1594 16.4249 17.9754L15.7251 12.0834L19.8286 7.71872C19.9871 7.54949 20.0405 7.30996 19.9685 7.09148C19.8964 6.87299 19.7101 6.70923 19.4804 6.66264L13.5368 5.49293L10.5667 0.322808C10.4509 0.123264 10.2345 0 10 0C9.76556 0 9.54916 0.123264 9.43331 0.322808L6.4632 5.49293L0.519589 6.66264C0.289944 6.70923 0.103565 6.87299 0.0315262 7.09148C-0.0405122 7.30996 0.0128957 7.54949 0.171383 7.71872L4.27488 12.0834L3.57505 17.9754C3.54889 18.2038 3.65019 18.4283 3.84025 18.5632C4.03032 18.6981 4.27979 18.7225 4.49337 18.6271L10 16.1574L15.5066 18.6271C15.5859 18.6636 15.6718 18.684 15.7593 18.6873Z"
        fill="#FFB81C"
      />
    </svg>
  )
}

export default StarSolidIcon
