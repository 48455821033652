import React from "react"
import "./styles.scss"

type searchProps = {
  onSearch: () => void
  onClear: () => void
  value: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Search = (props: searchProps) => {
  const { onSearch, onClear, handleChange, value } = props

  const enterCode = 13

  return (
    <div className="nhs-custom-search">
      <label className="nhs-custom-search__label" htmlFor="nhs-custom-search">
        Search
      </label>
      <input
        className="nhsuk-input"
        data-testid="input"
        type="text"
        id="nhs-custom-search"
        onKeyDown={e => (e.which === enterCode ? onSearch() : null)}
        onChange={e => handleChange(e)}
        value={value}
      />
      <svg
        version="1.1"
        fill="#005eb8"
        viewBox="0 0 57 57"
        data-testid="search-icon"
        onClick={onSearch}
        className="nhs-custom-search__icon"
      >
        <title>Search</title>
        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z"></path>
      </svg>
      <svg
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-6q0nyr-Svg nhs-custom-search__icon"
        data-testid="clear-icon"
        onClick={onClear}
      >
        <title>Clear</title>
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
      </svg>
    </div>
  )
}

export default Search
