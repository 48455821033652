import { useEffect, useState } from "react"
import { ITableauReportViewerFilter } from "../../global/interfaces/tableau/interfaces"
import { useStores } from "../../global/hooks"
import { TODO } from "../../global/utils/types/todo"
import {
  TableauViz,
  TableauEventType,
  Toolbar,
  Worksheet,
  Workbook,
  Dashboard,
  CategoricalFilter,
  DataValue,
  TabSwitchedEvent,
} from "@tableau/embedding-api"

type FilterValue = {
  FieldName: string
  Value: string | number
  Rank: number
}

//https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_data.html#get-data-from-a-data-source

//https://github.com/tableau/embedding-api-v3-guide/issues/27

//https://community.tableau.com/s/question/0D54T00000C63j8SAB/get-current-active-worksheet-details-in-dashboard-using-javascript

const useTableauConfiguration = (tableauVisualizationRef: React.RefObject<HTMLDivElement>) => {
  const [loadedConfiguration, setLoadedConfiguration] = useState(false)
  const Null = "Null"
  const {
    tableauReportViewerStore: {
      setFilters,
      setMetadata,
      setCurrentTab,
      setReportViewChanged,
      currentReport,
      baseUrl,
      setTableauVisualization,
      tableauVisualization,
      setFrameSize,
    },
  } = useStores()

  const tableauTabSwitchHandler = (event: TODO, workbook: Workbook | null) => {
    if (workbook == null) return
    const innerEvent = event.detail as TabSwitchedEvent
    setCurrentTab(innerEvent.newSheetName)
  }

  useEffect(() => {
    let workbook: Workbook | null = null
    let currentTableauVisualization: TableauViz | null = null
    if (tableauVisualizationRef.current && !loadedConfiguration) {
      currentTableauVisualization = new TableauViz()
      currentTableauVisualization.src = baseUrl
      configureTableauVisualizationOption(currentTableauVisualization)
      currentTableauVisualization.addEventListener(TableauEventType.FirstInteractive, async () => {
        if (currentTableauVisualization) {
          workbook = currentTableauVisualization.workbook
          setFrameSize(currentTableauVisualization, currentTableauVisualization.workbook)
          setTableauVisualization(currentTableauVisualization)
        }
        setLoadedConfiguration(true)
      })
      const tableauVisualizationElement = tableauVisualizationRef.current
      tableauVisualizationElement.appendChild(currentTableauVisualization)
    }
    return () => {
      if (workbook && currentTableauVisualization) {
        currentTableauVisualization &&
          removeVisualizationEventListeners(currentTableauVisualization)
        setLoadedConfiguration(false)
      }
    }
  }, [tableauVisualizationRef.current])

  useEffect(() => {
    if (tableauVisualization) {
      configureTableauVisualizationEvents()
      getMetadata(tableauVisualization.workbook).then(metadata => setMetadata(metadata))
      tableauVisualization.addEventListener(TableauEventType.TabSwitched, (event: TODO) =>
        tableauTabSwitchHandler(event, tableauVisualization?.workbook)
      )
    }
    return () => {
      tableauVisualization &&
        tableauVisualization.workbook &&
        removeVisualizationEventListeners(tableauVisualization)
    }
  }, [tableauVisualization])

  const getMetadata = async (workbook: Workbook) => {
    const dashboard = workbook.activeSheet as Dashboard

    if (!dashboard) return

    const worksheet = dashboard.worksheets.find(worksheet => worksheet.name === "Metadata")

    if (!worksheet) return

    const dataTableReader = await worksheet.getSummaryDataReaderAsync()

    const dataTable = await dataTableReader.getAllPagesAsync()

    const columns = dataTable.columns

    const data = dataTable.data

    const fieldNameMap = columns.map(col => col.fieldName.replace(/\s/g, ""))

    return data.map((d: DataValue[]) =>
      d.reduce((memo, value, idx) => {
        memo[fieldNameMap[idx]] = value.formattedValue
        return memo
      }, {} as Record<string, TODO>)
    )
  }

  const getFilterValues = async (
    reportFilters: ITableauReportViewerFilter[],
    workbook: Workbook
  ): Promise<FilterValue[]> => {
    const result: FilterValue[] = []

    for (const filter of reportFilters) {
      const activeSheet = workbook.activeSheet as Dashboard
      if (!activeSheet) continue

      const sheet = activeSheet.worksheets.find(
        worksheet => worksheet.name === filter.tableauWorksheetName
      )

      const sheetFilters = await sheet?.getFiltersAsync()

      if (!sheetFilters) continue

      for (const sheetfilter of sheetFilters) {
        const fieldName = sheetfilter.fieldName
        if (fieldName === filter.tableauFieldName) {
          const categoricalFilter = sheetfilter as CategoricalFilter
          const values = categoricalFilter.appliedValues
          if (values.length === 1) {
            const value = values[0].formattedValue
            if (value && value !== Null) {
              result.push({
                FieldName: filter.filterTitle,
                Value: value,
                Rank: filter.rank,
              })
            }
          } else {
            console.log(
              filter.filterTitle + " not shown as " + values.length + " values were returned."
            )
          }
        }
      }
    }
    return result
  }

  const getParameterValues = async (
    reportFilters: TODO,
    workbook: Workbook
  ): Promise<FilterValue[]> => {
    const result: FilterValue[] = []

    for (const filter of reportFilters) {
      const parameters = await workbook.getParametersAsync()

      for (const parameter of parameters) {
        const fieldName = parameter.name

        if (fieldName === filter.tableauFieldName) {
          const value = parameter.currentValue.formattedValue

          if (value && value !== Null) {
            result.push({
              FieldName: filter.filterTitle,
              Value: value,
              Rank: filter.rank,
            })
          }
        }
      }
    }
    return result
  }

  const getDataValues = async (reportFilters: TODO, workbook: Workbook): Promise<FilterValue[]> => {
    const result: FilterValue[] = []

    for (const reportFilter of reportFilters) {
      const dashboard = workbook.activeSheet as Dashboard

      if (!dashboard) continue

      const worksheets = dashboard.worksheets

      const workSheet = worksheets.find(
        (ws: Worksheet) => ws.name === reportFilter.tableauDashboardChildWorksheetName
      )

      if (!workSheet) continue

      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      const dataTable = await (await workSheet!.getSummaryDataReaderAsync()).getAllPagesAsync()

      const value = dataTable.data[0][0].formattedValue

      if (value && value !== Null) {
        result.push({
          FieldName: reportFilter.filterTitle,
          Value: value,
          Rank: reportFilter.rank,
        })
      }
    }
    return result
  }

  const rebuildFilterOutput = async () => {
    if (!tableauVisualization) return
    setFrameSize(tableauVisualization)

    removeVisualizationEventListeners(tableauVisualization)
    //This trick causes this function to pause till the page has completely loaded to make sure the correct filters are returned.
    await tableauVisualization.workbook.getCustomViewsAsync()

    const dashboardName = tableauVisualization.workbook.activeSheet.name
    const reportFilters = currentReport?.filters.filter(
      (filter: ITableauReportViewerFilter) => filter.tableauDashboardName === dashboardName
    )

    if (reportFilters && reportFilters.length > 0) {
      const filterValueResult = getFilterValues(
        reportFilters.filter((filter: TODO) => filter.reportFilterType === "Filter"),
        tableauVisualization.workbook
      )
      const parameterValueResult = getParameterValues(
        reportFilters.filter((filter: TODO) => filter.reportFilterType === "Parameter"),
        tableauVisualization.workbook
      )
      const dataValueResult = getDataValues(
        reportFilters.filter((filter: TODO) => filter.reportFilterType === "DataValue"),
        tableauVisualization.workbook
      )

      const result = await Promise.all([filterValueResult, parameterValueResult, dataValueResult])

      const allResults = result.reduce((x, y) => [...x, ...y], [])

      setFilters(allResults)
    }

    setTimeout(() => {
      addVisualizationEventListeners()
    }, 200)
  }

  const onReportViewChange = async () => {
    if (!tableauVisualization) return
    await rebuildFilterOutput()
    setReportViewChanged(true)
  }

  const addVisualizationEventListeners = () => {
    if (!tableauVisualization) return
    tableauVisualization.addEventListener(TableauEventType.FilterChanged, onReportViewChange)
    tableauVisualization.addEventListener(
      TableauEventType.MarkSelectionChanged,
      rebuildFilterOutput
    )
    tableauVisualization.addEventListener(
      TableauEventType.ParameterChanged,
      () => onReportViewChange
    )
    tableauVisualization.addEventListener(TableauEventType.CustomViewLoaded, rebuildFilterOutput)
  }

  const removeVisualizationEventListeners = (tableauVisualization: TableauViz) => {
    if (!tableauVisualization) return
    tableauVisualization.removeEventListener(TableauEventType.FilterChanged, onReportViewChange)
    tableauVisualization.removeEventListener(
      TableauEventType.MarkSelectionChanged,
      rebuildFilterOutput
    )
    tableauVisualization.removeEventListener(TableauEventType.ParameterChanged, onReportViewChange)
    tableauVisualization.removeEventListener(TableauEventType.CustomViewLoaded, rebuildFilterOutput)
  }

  const configureTableauVisualizationOption = (tableauVisualization: TableauViz) => {
    tableauVisualization.hideTabs = true
    tableauVisualization.toolbar = Toolbar.Hidden
  }

  const configureTableauVisualizationEvents = () => {
    if (!tableauVisualization) return
    addVisualizationEventListeners()
  }
}

export default useTableauConfiguration
