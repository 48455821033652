import React from "react"
import { IIconProps } from "../global/interfaces/icon/interfaces"
import { nhsuk_dark_blue } from "../global/constants/colours"

export const EditProductIcon = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={nhsuk_dark_blue}
        fillRule="evenodd"
        d="M12 16.231c-2.337 0-4.23-1.894-4.23-4.23 0-2.338 1.893-4.232 4.23-4.232 2.336 0 4.23 1.894 4.23 4.231 0 2.337-1.894 4.231-4.23 4.231m10.154-6.346h-1.97c-.2-.775-.504-1.505-.901-2.176l1.393-1.393c.33-.33.33-.866 0-1.197L18.88 3.324c-.33-.331-.866-.331-1.196 0l-1.392 1.392c-.672-.396-1.402-.701-2.177-.9v-1.97c0-.468-.379-.846-.846-.846h-2.538c-.468 0-.846.378-.846.846v1.97c-.775.199-1.505.504-2.177.9L6.316 3.324c-.33-.331-.866-.331-1.197 0L3.324 5.119c-.33.331-.33.867 0 1.197l1.392 1.393c-.397.671-.701 1.4-.9 2.176h-1.97c-.468 0-.846.378-.846.846v2.539c0 .467.378.846.846.846h1.97c.199.774.503 1.504.9 2.175l-1.392 1.393c-.33.33-.33.866 0 1.197l1.795 1.795c.33.331.867.331 1.197 0l1.392-1.392c.672.396 1.402.701 2.177.9v1.97c0 .468.378.846.846.846h2.538c.467 0 .846-.378.846-.846v-1.97c.775-.199 1.505-.504 2.177-.9l1.392 1.392c.33.331.866.331 1.196 0l1.796-1.795c.33-.331.33-.867 0-1.197l-1.393-1.393c.397-.671.701-1.4.901-2.175h1.97c.467 0 .846-.38.846-.846V10.73c0-.468-.38-.846-.846-.846"
      />
    </svg>
  )
}

export const ProductRefreshIcon = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      className={className}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a" fill="#fff">
        <path d="m0 0h24v24h-24z" fill="#fff" fillRule="evenodd" />
      </mask>
      <path
        d="m21.3333333 11.99968-3.528-3.864-3.472 3.864h2.3333334c0 2.5733333-2.0933334 4.6666667-4.6666667 4.6666667-1.2613333 0-2.40533333-.504-3.248-1.3226667l-1.55733333 1.736c1.25333333 1.1866667 2.94400003 1.92 4.80533333 1.92 3.8666667 0 7-3.1333333 7-7zm-15.13866663 3.864 3.472-3.864h-2.33333334c0-2.57333333 2.09333334-4.66666667 4.66666667-4.66666667 1.2613333 0 2.4053333.504 3.248 1.32266667l1.5573333-1.736c-1.256-1.18666667-2.944-1.92-4.8053333-1.92-3.86666667 0-7 3.13333333-7 7h-2.33333333zm17.80533333-3.864c0 6.6266667-5.3786667 12-12 12-6.62133333 0-12-5.3733333-12-12 0-6.62666667 5.37866667-12 12-12 6.6213333 0 12 5.37333333 12 12z"
        fill="#007f3b"
        fillRule="evenodd"
        mask="url(#a)"
      />
    </svg>
  )
}

export const InviteIcon = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" transform="translate(2 3)">
        <path
          fill="#425563"
          d="M9.6 0c5.302 0 9.6 4.36 9.6 9.74 0 3.272-1.591 6.167-4.031 7.933-.058-.568-.19-1.087-.485-1.562-.887-1.424-2.458-2.087-4.946-2.087-2.514 0-4.091.662-4.965 2.085-.337.548-.469 1.007-.519 1.719C1.688 16.08 0 13.11 0 9.739 0 4.36 4.298 0 9.6 0zm.02 6.233c-1.917 0-3.476 1.543-3.476 3.442 0 1.896 1.56 3.44 3.477 3.44 1.918 0 3.477-1.544 3.477-3.44 0-1.899-1.56-3.442-3.477-3.442z"
        />
      </g>
    </svg>
  )
}
