import React from "react"
import { SVGIconProps } from "."

const GearIcon = (props: SVGIconProps) => {
  return (
    <svg
      {...props}
      data-testid="gear-icon"
      className="gear-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.616C9.45046 16.616 7.38462 14.5492 7.38462 12.0005C7.38462 9.45082 9.45046 7.3849 12 7.3849C14.5486 7.3849 16.6154 9.45082 16.6154 12.0005C16.6154 14.5492 14.5486 16.616 12 16.616ZM23.0769 9.69268H20.928C20.7102 8.84711 20.3778 8.05139 19.9449 7.31844L21.4643 5.79899C21.8252 5.43898 21.8252 4.85465 21.4643 4.49371L19.5055 2.53487C19.1455 2.17393 18.5612 2.17393 18.2003 2.53487L16.6818 4.05431C15.9489 3.62137 15.1523 3.28905 14.3077 3.07119V0.923112C14.3077 0.412631 13.8942 0 13.3846 0H10.6154C10.1049 0 9.69231 0.412631 9.69231 0.923112V3.07119C8.84677 3.28905 8.05108 3.62137 7.31815 4.05431L5.79877 2.53487C5.43877 2.17393 4.85446 2.17393 4.49354 2.53487L2.53569 4.49371C2.17477 4.85465 2.17477 5.43898 2.53569 5.79899L4.05415 7.31844C3.62123 8.05139 3.28892 8.84711 3.072 9.69268H0.923077C0.412615 9.69268 0 10.1053 0 10.6158V13.3851C0 13.8947 0.412615 14.3082 0.923077 14.3082H3.072C3.28892 15.1529 3.62123 15.9486 4.05415 16.6816L2.53569 18.201C2.17477 18.561 2.17477 19.1454 2.53569 19.5063L4.49354 21.4651C4.85446 21.8261 5.43877 21.8261 5.79877 21.4651L7.31815 19.9457C8.05108 20.3786 8.84677 20.711 9.69231 20.9288V23.0769C9.69231 23.5874 10.1049 24 10.6154 24H13.3846C13.8942 24 14.3077 23.5874 14.3077 23.0769V20.9288C15.1523 20.711 15.9489 20.3786 16.6818 19.9457L18.2003 21.4651C18.5612 21.8261 19.1455 21.8261 19.5055 21.4651L21.4643 19.5063C21.8252 19.1454 21.8252 18.561 21.4643 18.201L19.9449 16.6816C20.3778 15.9486 20.7102 15.1529 20.928 14.3082H23.0769C23.5865 14.3082 24 13.8947 24 13.3851V10.6158C24 10.1053 23.5865 9.69268 23.0769 9.69268Z"
        fill="#4C6272"
      />
    </svg>
  )
}

export default GearIcon
