import { AxiosResponse } from "axios"
import { Location } from "history"
import { useEffect, useState } from "react"
import ReactGA from "react-ga"
import { get } from "../utils/api"

const useAnalytics = (location: Location) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    // Get the analytics key
    get<string>("/analytic/key").then((response: AxiosResponse<string>) => {
      // Initialise analytics using the key
      ReactGA.initialize(response.data)
      // Tracks the page loads and direct/deep links to the website
      ReactGA.pageview(location.pathname + location.search)
      setInitialized(true)
    })
  }, [location.pathname, location.search])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])
}

export default useAnalytics
