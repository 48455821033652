import React, { useRef, useState } from "react"
import { useStores } from "../../../../../global/hooks"
import { Workbook } from "@tableau/embedding-api"

import "../styles.scss"

interface IViewRow {
  viewName: string
  viewValue: string
  isDefault: boolean
  last: boolean
  canDelete: boolean
  renamingEnabled: boolean
  setDefaultView: (viewName: string, workbook: Workbook) => Promise<void>
  deleteView: (viewName: string, workbook: Workbook) => Promise<void>
  renameCustomView: (viewName: string, newName: string, workbook: Workbook) => void
}

const ViewRow = ({
  viewName,
  viewValue,
  isDefault,
  last,
  canDelete,
  renamingEnabled,
  setDefaultView,
  deleteView,
  renameCustomView,
}: IViewRow) => {
  const [isRenaming, setIsRenaming] = useState(false)
  const [newName, setNewName] = useState<string>(viewName)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    tableauReportViewerStore: { tableauVisualization },
  } = useStores()

  const onRenameClicked = () => {
    setIsRenaming(true)
    inputRef.current?.focus()
  }

  const onConfirmClicked = (workbook: Workbook) => {
    const trimmed = newName.trim()
    if (trimmed.length === 0 || trimmed === viewName) {
      setNewName(viewName)
      setIsRenaming(false)
    } else {
      renameCustomView(viewName, newName, workbook)
    }
  }

  const renameText = isRenaming ? "CONFIRM" : "Rename"

  const renameConfirmSuffix = isRenaming ? "-confirm" : ""

  if (!tableauVisualization) return <></>

  return (
    <>
      <div className="views-modal-divider" />
      <div className="custom-view-row">
        <div className="rename-container">
          <input
            className="nhsuk-input"
            value={newName}
            readOnly={!isRenaming}
            onChange={e => setNewName(e.target.value)}
            ref={inputRef}
          />
          {isRenaming && (
            <svg
              viewBox="0 0 20 20"
              aria-hidden="true"
              className="css-6q0nyr-Svg"
              onClick={() => setNewName("")}
            >
              <title>Clear</title>
              <path
                fill="#005EB8"
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
              ></path>
            </svg>
          )}
        </div>
        <div className="custom-view-row-button-container">
          <div className="rename-container">
            {renamingEnabled ? (
              <button
                className={`custom-view-rename${renameConfirmSuffix}`}
                onClick={() =>
                  isRenaming ? onConfirmClicked(tableauVisualization.workbook) : onRenameClicked()
                }
              >
                {renameText}
              </button>
            ) : (
              <div className="option-spacer" />
            )}
          </div>
          <div className="default-container">
            {isDefault ? (
              <span className="custom-view-default">Default</span>
            ) : (
              <button
                className="custom-view-make-default"
                onClick={async () => await setDefaultView(viewValue, tableauVisualization.workbook)}
              >
                Make default
              </button>
            )}
          </div>
          <div className="delete-container">
            {canDelete ? (
              <button
                className="custom-view-delete"
                onClick={async () => await deleteView(viewValue, tableauVisualization.workbook)}
              >
                Delete
              </button>
            ) : (
              <div className="option-spacer" />
            )}
          </div>
        </div>
      </div>
      {last && <div className="views-modal-divider" />}
    </>
  )
}

export default ViewRow
