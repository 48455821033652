import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Label, Form, Select as NHSSelect, Radios, Checkboxes } from "nhsuk-react-components"
import { AsyncSearchableSelect } from "../../../global/components/searchableSelect"
import { constructDropdownValue } from "./shared"
import Link from "@govuk-react/link"

const AdditionalAdminForm = observer(props => {
  const {
    setNoResultsMessage,
    noResultsMessage,
    handleSearch,
    removeAdmin,
    id,
    handleAdminSelection,
    scopes,
    regions,
    adminsIndex,
    otherAdmins,
  } = props

  const currentAdmin = otherAdmins.find((_, idx) => idx == adminsIndex)
  const [allSelected, setAllSelected] = useState(false)
  const [currentAdminsSelectedRegions, setCurrentAdminsSelectedRegions] = useState([])
  const [loading, setLoading] = useState(true)
  const [adminScopeIsRegional, setAdminScopeIsRegional] = useState(false)

  useEffect(() => {
    let selectedRegions = []
    if (currentAdmin.selectedRegions && currentAdmin.selectedRegions.length > 0) {
      selectedRegions = [
        ...regions.filter(region => currentAdmin.selectedRegions.indexOf(region) !== -1),
      ]
    } else {
      selectedRegions = []
    }

    setCurrentAdminsSelectedRegions(selectedRegions)

    if (
      selectedRegions &&
      selectedRegions.length > 0 &&
      regions.every(region => selectedRegions.indexOf(region) !== -1)
    ) {
      setAllSelected(true)
    }

    setAdminScopeIsRegional(isRegional(currentAdmin.scope))

    setLoading(false)
  }, [])

  const handleCanUpdateProductRadio = val => {
    currentAdmin.canUpdateProduct = val === "true"
  }

  const toggleRegion = idx => {
    const region = regions[idx]

    let copy = [...currentAdminsSelectedRegions]

    const index = copy.indexOf(region)
    if (index === -1) {
      copy = copy.concat(region)
    } else {
      copy.splice(index, 1)
    }

    currentAdmin.selectedRegions = [...copy]
    setCurrentAdminsSelectedRegions([...copy])
    setAllSelected(regions.every(region => copy.indexOf(region) !== -1))
  }

  const toggleAllRegions = value => {
    let selectedRegions = []
    if (value) {
      selectedRegions = [...regions]
    }

    currentAdmin.selectedRegions = selectedRegions
    setCurrentAdminsSelectedRegions([...selectedRegions])
    setAllSelected(value)
  }

  const onScopeChanged = event => {
    currentAdmin.scope = parseInt(event.target.value)
    if (!isRegional(currentAdmin.scope)) {
      toggleAllRegions(false)
      setAdminScopeIsRegional(false)
    } else {
      setAdminScopeIsRegional(true)
    }

    if (currentAdmin.error) {
      delete currentAdmin.error.scope
      delete currentAdmin.error.checkbox
    }
  }

  const isRegional = scope => {
    if (!scopes || scope < 0) {
      return false
    }

    var foundScope = scopes[scope]
    if (!foundScope) {
      return false
    }

    return foundScope.isRegional
  }

  const errors = currentAdmin.error || {}

  return (
    <>
      <div className="admin-info-secondary-form" id={id}>
        <hr />
        <div className="admin-info-secondary-form-wrapper">
          <div className="admin-info-secondary-field">
            <AsyncSearchableSelect
              className="input-field admin-info-custom-select"
              name="Additional admin name"
              noOptionsMessage={() => noResultsMessage}
              loadOptions={handleSearch}
              hint="Type their name and select the correct individual from the dropdown."
              onClear={() => setNoResultsMessage("You need to provide a search term.")}
              error={errors.select}
              onChange={handleAdminSelection}
              value={constructDropdownValue(currentAdmin)}
            >
              <Link className="admin-info-remove-link" onClick={removeAdmin}>
                Delete
              </Link>
            </AsyncSearchableSelect>
          </div>

          <Form className="admin-info-secondary-field">
            <Label>Scope of admin user's permission to grant access</Label>
            <NHSSelect
              className="input-field"
              error={errors.scope}
              hint={
                "Admin users can be restricted to dealing with access requests from specific places. Select this admin user's restrictions."
              }
              onChange={e => onScopeChanged(e)}
              defaultValue={currentAdmin.scope > -1 ? currentAdmin.scope : -1}
            >
              {
                <NHSSelect.Option key={-1} value={-1}>
                  Select scope
                </NHSSelect.Option>
              }
              {scopes.map((scope, idx) => (
                <NHSSelect.Option key={idx} value={idx}>
                  {scope.scope}
                </NHSSelect.Option>
              ))}
            </NHSSelect>
          </Form>

          {!loading && adminScopeIsRegional && (
            <>
              <Form>
                <Checkboxes error={errors.checkbox}>
                  {
                    <Checkboxes.Box
                      key={-1}
                      value={-1}
                      checked={allSelected}
                      onClick={() => toggleAllRegions(!allSelected)}
                    >
                      Select All
                    </Checkboxes.Box>
                  }
                  {regions.map((region, idx) => {
                    return (
                      <Checkboxes.Box
                        key={idx}
                        checked={currentAdminsSelectedRegions.indexOf(region) !== -1}
                        onClick={() => toggleRegion(idx)}
                      >
                        {idx === regions.length - 1 ? (
                          <>
                            {region}
                            <br />
                            <span className="guidance-text">
                              Admin user will see requests from users who do not have a region
                              associated with their organisation, e.g. universities.
                            </span>
                          </>
                        ) : (
                          region
                        )}
                      </Checkboxes.Box>
                    )
                  })}
                </Checkboxes>
                {currentAdminsSelectedRegions.length > 0 && (
                  <div className="info-box">
                    If you are asking users to select the region they want access to as part of the
                    approval process, then the admin user will see requests from users who have
                    selected the region that they are responsible for. If you are not asking the
                    user to select the region they want access to, then the admin user will be able
                    to see requests from users whose primary organisation is associated with the
                    region(s) selected.
                  </div>
                )}
              </Form>
            </>
          )}

          <Form className="admin-info-secondary-field">
            <Label>Does this admin user have permission to change product setup?</Label>
            <Radios
              hint="Some admin users can change setup details, such as its name, or the groups of users who may access it."
              inline
              error={errors.radios}
              onChange={e => handleCanUpdateProductRadio(e.target.value)}
            >
              <Radios.Radio defaultChecked={currentAdmin.canUpdateProduct === true} value="true">
                Yes, this user may change product setup
              </Radios.Radio>
              <Radios.Radio defaultChecked={currentAdmin.canUpdateProduct === false} value="false">
                No, this user may not change product setup
              </Radios.Radio>
            </Radios>
          </Form>
        </div>
      </div>
    </>
  )
})

export default AdditionalAdminForm
