import React from "react"
import PropTypes from "prop-types"
import "./ApprovedAccess.scss"

const ApprovedAccess = props => {
  const { date } = props
  const dateSliced = new Date(date).toString().split(" ")
  const dateApproved = `${dateSliced[2]} ${dateSliced[1]} ${dateSliced[3]}`
  return (
    <div className="az-page__approved-access__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        aria-hidden="true"
        fill="none"
      >
        <path
          strokeWidth="4"
          strokeLinecap="round"
          stroke="#007f3b"
          d="M18.4 7.8l-8.5 8.4L5.6 12"
        ></path>
      </svg>
      <div className="az-page__approved-access__text">Access approved, {dateApproved}</div>
    </div>
  )
}

ApprovedAccess.propTypes = {
  date: PropTypes.string.isRequired,
}

export default ApprovedAccess
