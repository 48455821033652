import { observer } from "mobx-react"
import { Button, Checkboxes, Container, ErrorSummary, Label } from "nhsuk-react-components"
import React, { useState } from "react"
import { Link as ReactLink, Navigate } from "react-router-dom"
import { LoaderContext } from "../../../global/components/loaderProvider"
import { Routes } from "../../../global/enums"
import { useStores } from "../../../global/hooks"
import { IKeyValuePair } from "../../../global/interfaces/dataTypes/interfaces"
import { IErrorBucket } from "../../../global/interfaces/error"
import { IProfileChangesShape } from "../../../global/stores/profileStore"
import { ErrorsCollection } from "../../../global/types"
import { IUserProfileUpdateResult } from "../../interfaces"
import DifferenceRows from "./components/differenceRows/differenceRows"
import Warnings from "./components/warnings/warnings"
import "./styles.scss"

interface IProfileUpdateConfirmationViewProps {
  payload: IProfileChangesShape | null
  onCancel: () => void
}

const ProfileUpdateConfirmationView = observer((props: IProfileUpdateConfirmationViewProps) => {
  const { payload, onCancel } = props
  const { profileStore: ps } = useStores()

  const [updateResult, setUpdateResult] = useState<IUserProfileUpdateResult | null>(null)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { wrapWithLoader } = React.useContext(LoaderContext)

  const onSubmit = async (): Promise<void> => {
    ps.error = new ErrorsCollection()
    if (termsAccepted) {
      wrapWithLoader(async () => {
        if (payload) {
          await ps.postProfileUpdatePayload(payload).then(result => setUpdateResult(result))
        }
      })
    } else {
      ps.error.add({
        key: "termsAccepted",
        value: [
          {
            fieldError: "Please confirm you agree to the Terms and conditions",
            summaryError: "Please confirm you agree to the Terms and conditions",
          },
        ],
      })
    }
  }

  const onCancellationRequested = (): void => {
    ps.error = new ErrorsCollection()
    onCancel()
  }

  if (updateResult) {
    return <Navigate to={Routes.ProfileUpdateResultUrl} state={{ result: updateResult }} />
  }

  if (!payload) {
    return null
  }

  return (
    <div id="profile-update-confirmation-view">
      <Container>
        {ps.error.length > 0 && (
          <ErrorSummary role="alert" tabIndex={-1}>
            <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
            <ErrorSummary.List className="error-list">
              {ps.error.map((entry: IKeyValuePair<string, IErrorBucket[]>) => {
                return entry.value.map(err => {
                  return (
                    <ErrorSummary.Item key={`${entry.key}-${err.fieldError}`}>
                      {err.summaryError}
                    </ErrorSummary.Item>
                  )
                })
              })}
            </ErrorSummary.List>
          </ErrorSummary>
        )}
        <div className="profile-update-confirmation-view__wrapper">
          <Warnings payload={payload} onCancellationRequested={onCancellationRequested} />
          <Label isPageHeading id="confirm-label">
            Confirm your changes
          </Label>

          <Label id="previous-details-label">Previous details</Label>

          <DifferenceRows
            initialProfileData={ps.initialProfileData}
            showInitialDetails
            profileUpdateData={payload}
          />

          <Label id="new-details-label">Updated details</Label>

          <DifferenceRows showInitialDetails={false} profileUpdateData={payload} />

          <Checkboxes.Box onChange={() => setTermsAccepted(!termsAccepted)}>
            I confirm that the account information I have provided is correct and in line with the{" "}
            <div className="inline-link">
              <ReactLink to={Routes.TermsAndConditions} target={`_blank`}>
                terms and conditions
              </ReactLink>
            </div>{" "}
            of using this service
          </Checkboxes.Box>

          <div className="options-bottom-container">
            <Button secondary={false} onClick={onSubmit} className="submit-button">
              Confirm changes
            </Button>
            <Button onClick={onCancellationRequested} className="back-link" secondary>
              Back to previous page
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
})

export default ProfileUpdateConfirmationView
