import { IValidationRuleBuilder, IValidationRule } from "../../interfaces/validation"
import { ValidationRule } from "."

export class ValidationRuleBuilder<T, TProp> implements IValidationRuleBuilder<T, TProp> {
  private _propertySelectionRule: (source: T) => TProp
  private _message: string | null = null
  private _code: number | null = null
  private _attributeSelectors: ((target: TProp) => boolean)[] | null = null
  private _anySelectors: ((target: T) => unknown)[] | null = null

  constructor(propertySelectionRule: (source: T) => TProp) {
    this._propertySelectionRule = propertySelectionRule
  }

  withAttributes = (
    ...attributeSelectors: ((target: TProp) => boolean)[]
  ): IValidationRuleBuilder<T, TProp> => {
    this._attributeSelectors = attributeSelectors
    return this
  }

  withFailureMessage = (message: string): IValidationRuleBuilder<T, TProp> => {
    this._message = message
    return this
  }

  withFailureCode = (code: number): IValidationRuleBuilder<T, TProp> => {
    this._code = code
    return this
  }

  withAny = (...anySelectors: ((target: T) => unknown)[]): IValidationRuleBuilder<T, TProp> => {
    this._anySelectors = anySelectors
    return this
  }

  build = (): IValidationRule<T> => {
    if (!this._message) {
      throw new Error("Validation rule message is undefined")
    }

    if (this._code === null) {
      throw new Error("Validation rule code is undefined")
    }

    if (this._attributeSelectors === null && this._anySelectors === null) {
      throw new Error("Validation rule attribute selectors or 'any' selectors must be defined")
    }

    return new ValidationRule<T, TProp>(
      this._propertySelectionRule,
      this._message,
      this._code,
      this._attributeSelectors,
      this._anySelectors
    )
  }
}
