import React from "react"
import "./styles.scss"

interface IDisabledContainerProps {
  children: React.ReactNode
  disabled: boolean
}

const DisabledContainer = (props: IDisabledContainerProps) => {
  const { children, disabled } = props

  return (
    <div className={`disabled-container${disabled ? "--disabled no-select" : ""}`}>{children}</div>
  )
}

export default DisabledContainer
