import React from "react"

interface ITickIconProps {
  height?: string
  width?: string
}

const TickIcon: React.FC<ITickIconProps> = props => {
  const { height = "1.5rem", width = "1.5rem" } = props

  return (
    <svg
      className="nhsuk-icon nhsuk-icon__tick"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      fill="none"
    >
      <path
        strokeWidth="4"
        strokeLinecap="round"
        stroke="#007f3b"
        d="M18.4 7.8l-8.5 8.4L5.6 12"
      ></path>
    </svg>
  )
}

export default TickIcon
