import React from "react"

const AdminFeedbackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
      <path
        fill="#425563"
        fillRule="evenodd"
        d="M11 5.867l1.467 1.466H6.6v8.8h8.8v-5.866l1.467 1.466V17.6H5.133V5.867H11zm6.6-.734v4.4l-1.467-1.466v-.49L10.267 13.2 9.23 12.163l5.804-5.564-.367.001L13.2 5.133h4.4z"
      />
    </svg>
  )
}

export default AdminFeedbackIcon
