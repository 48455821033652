import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useStores } from "../../hooks"
import { AxiosError } from "axios"

const PrivateRoute = observer(() => {
  const { profileStore } = useStores()
  const [loading, setLoading] = useState(true)

  const { search, pathname } = useLocation()

  const returnUrl = search.split("").reduce((a, v) => a + v, pathname ? pathname : "")

  const navTo = useNavigate()

  useEffect(() => {
    const getUser = async () => {
      setLoading(true)
      try {
        await profileStore.getUser()
      } catch (error) {
        const responseCode = (error as AxiosError).response?.status
        if (responseCode === 403) {
          navTo("/error/forbidden")
        }
      }

      setLoading(false)
    }

    getUser()
  }, [])

  if (loading) {
    return null
  }

  if (profileStore.isUserAuthenticated) {
    return <Outlet />
  } else {
    window.location.href = `/api/auth/login?returnUrl=${
      returnUrl ? encodeURIComponent(returnUrl) : ""
    }`
    return null
  }
})

export default PrivateRoute
