import React from "react"

const MailIcon = () => {
  return (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.6665 0.333313H3.33317C1.8665 0.333313 0.679837 1.53331 0.679837 2.99998L0.666504 19C0.666504 20.4666 1.8665 21.6666 3.33317 21.6666H24.6665C26.1332 21.6666 27.3332 20.4666 27.3332 19V2.99998C27.3332 1.53331 26.1332 0.333313 24.6665 0.333313ZM24.6665 5.66665L13.9998 12.3333L3.33317 5.66665V2.99998L13.9998 9.66665L24.6665 2.99998V5.66665Z"
        fill="#005EB8"
      />
    </svg>
  )
}

export default MailIcon
