import React, { useContext, useMemo, useState } from "react"
import {
  BinIcon,
  DeselectedNotificationIcon,
  DownloadNotificationIcon,
  NotificationNavigationIcon,
  SelectedNotificationIcon,
  UnreadNotificationIcon,
  UrgentIcon,
} from "../../../global/components/icons"
import { NotificationContext } from "../../../global/context"
import { NotificationStatus, NotificationType } from "../../../global/enums/notification"
import { INotificationEvent } from "../../../global/interfaces/notification"
import { getNotificationDate } from "../../../global/utils/date"
import { NotificationMessage } from "./NotificationMessage"

import "./styles.scss"

interface INotificationEventProps {
  event: INotificationEvent
  editMode: boolean
}

const NotificationEvent = (props: INotificationEventProps) => {
  const {
    event: {
      id,
      message,
      urgent,
      eventDate,
      url,
      read,
      sourceApplication,
      type,
      notificationStatus,
    },
    editMode,
  } = props

  const [deletionImminent, setDeletionImminent] = useState<boolean>(false)
  const [isSuccessfulNotification, setIsSuccessfulNotification] = useState<boolean>(
    notificationStatus === NotificationStatus.Succeeded
  )

  const {
    onNotificationEventRead,
    onNotificationEventSelectToggled,
    onNotificationEventsDeleteRequested,
    selectedNotificationEventIds,
    setOpenNotificationEventId,
    openNotificationEventId,
  } = useContext(NotificationContext)

  const open = openNotificationEventId === id

  const isDownloadNotification = type === NotificationType.Download

  const onNotificationEventTextClicked = () => {
    if (!open && !read) {
      onNotificationEventRead(id)
    }

    setOpenNotificationEventId(open ? "" : id)
  }

  const messageClassName = `notification-event__inner-container__upper-container__text ${
    !open ? "closed" : ""
  } ${!read ? "unread" : ""} no-select`

  const messageSuffix = "  ..."

  const urlText: string = useMemo(() => {
    if (!url) {
      return ""
    }

    if (!isDownloadNotification) {
      return "Click here to follow link"
    }

    if (!isSuccessfulNotification) {
      return "Contact support team by email"
    }

    return "Click here to download your file"
  }, [url, isDownloadNotification, isSuccessfulNotification])

  const Icon: JSX.Element = useMemo(() => {
    if (isDownloadNotification) {
      return <DownloadNotificationIcon isSuccessfulNotification={isSuccessfulNotification} />
    }

    if (urgent) {
      return <UrgentIcon />
    }

    if (!read) {
      return <UnreadNotificationIcon />
    }

    return <></>
  }, [isDownloadNotification, isSuccessfulNotification, urgent, read])

  return (
    <div data-testid="notification-event" className="notification-event">
      {editMode && (
        <div className="notification-event__upper-container__selection-container">
          <button
            data-testid="notification-event__upper-container__selection-container__button"
            className="notification-event__upper-container__selection-container__button"
            onClick={() => onNotificationEventSelectToggled(id)}
          >
            {selectedNotificationEventIds.indexOf(id) !== -1 ? (
              <SelectedNotificationIcon />
            ) : (
              <DeselectedNotificationIcon />
            )}
          </button>
        </div>
      )}
      <div className="notification-event__inner-container">
        <div
          className="notification-event__inner-container__upper-container"
          data-testid="notification-event__inner-container__upper-container"
          onClick={onNotificationEventTextClicked}
        >
          <NotificationMessage
            message={message}
            messageClassName={messageClassName}
            messageSuffix={messageSuffix}
            open={open}
            isDownloadNotification={isDownloadNotification}
            setIsSuccessfulNotification={setIsSuccessfulNotification}
            read={read}
            status={notificationStatus}
          />
          <div className="notification-event__inner-container__upper-container__icon-container">
            {Icon}
          </div>
        </div>
        <div className="notification-event__inner-container__middle-container">
          <span
            className="notification-event__inner-container__middle-container__domain"
            data-testid="notification-event__inner-container__middle-container__domain"
          >
            {sourceApplication}
          </span>
          <span
            data-testid="notification-event__inner-container__middle-container__event-date"
            className="notification-event__inner-container__middle-container__event-date"
          >
            {getNotificationDate(eventDate)}
          </span>
        </div>
        {open && (
          <>
            <hr className="notification-event__inner-container__horizontal-rule" />
            <div className="notification-event__inner-container__lower-container">
              <div className="notification-event__inner-container__lower-container__navigation-container">
                {url && (
                  <>
                    <a
                      data-testid="notification-event__inner-container__lower-container__navigation-container__navigate"
                      className="notification-event__inner-container__lower-container__navigation-container__navigate"
                      href={url}
                    >
                      {urlText}
                    </a>
                    <NotificationNavigationIcon />
                  </>
                )}
              </div>
              <button
                className="notification-event__inner-container__lower-container__delete-button"
                onClick={() => setDeletionImminent(true)}
              >
                <BinIcon />
              </button>
            </div>
          </>
        )}
        {deletionImminent && open && (
          <>
            <hr className="notification-event__inner-container__horizontal-rule" />
            <div className="notification-event__inner-container__deletion-container">
              <span
                data-testid="notification-event__inner-container__deletion-container__text"
                className="notification-event__inner-container__deletion-container__text"
              >
                Are you sure you want to delete?
              </span>
              <div className="notification-event__inner-container__deletion-container__buttons">
                <button
                  data-testid="notification-event__inner-container__deletion-container__buttons__accept-button"
                  className="notification-event__inner-container__deletion-container__buttons__button"
                  onClick={() => onNotificationEventsDeleteRequested([id])}
                >
                  Yes
                </button>
                <span className="notification-event__inner-container__deletion-container__buttons__separator">
                  |
                </span>
                <button
                  data-testid="notification-event__inner-container__deletion-container__buttons__decline-button"
                  className="notification-event__inner-container__deletion-container__buttons__button"
                  onClick={() => setDeletionImminent(false)}
                >
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NotificationEvent
