import { OrganisationGroupValidationFailureCode } from "./enums"

export const failureMessages: Record<number, string> = {
  [OrganisationGroupValidationFailureCode.ShortName]:
    "The Organisation Group name cannot be undefined.",
  [OrganisationGroupValidationFailureCode.Description]:
    "The Organisation Group description cannot be undefined.",
  [OrganisationGroupValidationFailureCode.AtLeastOneField]:
    "At least one of the following must be defined: Organisations, Organisation Types, Regions, ICBs.",
  [OrganisationGroupValidationFailureCode.TableauAccessGroup]:
    "The Organisation Group Tableau Access Group cannot be undefined.",
  [OrganisationGroupValidationFailureCode.FallbackTableauAccessGroup]:
    "A fallback Tableau Access Group must be selected",
  [OrganisationGroupValidationFailureCode.NoOrganisationGroups]:
    "No Organisation Groups have been selected.",
}
