import AddToGroupListValidator from "./addToGroupListValidator"
import { Guid } from "../../../../../global/types"
import { OrganisationGroupValidationFailureCode } from "../enums"
import { failureMessages } from "../messages"

class SaveAndReturnValidator extends AddToGroupListValidator {
  constructor() {
    super()

    this.ruleFor(x => x.tableauGroupId)
      .withAttributes(Guid.isValid)
      .withFailureCode(OrganisationGroupValidationFailureCode.TableauAccessGroup)
      .withFailureMessage(
        failureMessages[OrganisationGroupValidationFailureCode.TableauAccessGroup]
      )
  }
}

export default SaveAndReturnValidator
